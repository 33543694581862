import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { App, Table, Dropdown, Tabs, Modal, Button, Typography, Row, Col, Tooltip, Tag, TabsProps } from 'antd'
import { EllipsisOutlined, ExclamationCircleOutlined, LoadingOutlined, CloseCircleOutlined, PlusCircleOutlined, MailOutlined } from '@ant-design/icons'
import { sortBy, take, isEqual, filter, find } from 'lodash'
import { useSelector } from 'react-redux'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import {
  getUsersFiltered,
  getLocationsTeamsAndLabelsShort,
  getLocationsApproverTeamsAndLabels,
  getGoogleScopes,
  getInvitations,
  getNumberOfActiveUsers
} from '../../../graphql/custom-queries'
import * as logger from '../../../services/logger'

import { MicrosoftAuth } from '../../../services/auth/microsoft/microsoft'
import { SlackAuth } from '../../../services/auth/slack/slack'
import { GoogleAuth } from '../../../services/auth/google/google'
import { useAppSelector } from '../../../store/hooks'
import { isMicrosoftPayment, selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { roleAdmin, roleApprover, selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectDateFormatSlice } from '../../../store/date-format-slice'
import { useFeatureFlagEnabled as posthogFeatureFlagEnabled } from 'posthog-js/react'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import { notificationStore } from '../../../context/notificationsContext/store'
import { invertHexColor } from '../../../util/invert-color-wrapper'

import { ITeamShort } from '@vacationtracker/shared/types/team'
import { ILocationShort } from '@vacationtracker/shared/types/location'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'

import IntlMessages from '../../../util/IntlMessages'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'
import ChangeTeamForm from '../../../components/change-team-form'
import ChangeLocationForm from '../../../components/change-location-form'
import CircularProgress from '../../../components/circular-progress'
import { Search } from '../../../components/search'
import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import { InviteAndManageUsersWithEmail } from '../../../components/invite-and-manage-users-with-email'
import { addMinutes, isAfter, isBefore } from 'date-fns'

import { IGetUsersFiltered, IUserFilteredInfo, IUserStatusChangedBody } from '../../../types/users'
import { IGetTeamsShort } from '../../../types/teams'
import {
  IGetGoogleScopes,
  IGetInvitations,
  IGetLocationsApproverTeamsAndLabelsShort,
  IGetLocationsTeamsAndLabelsShort
} from '../../../types/custom-queries'
import { IGetLabelsShort } from '../../../types/labels'
import { ISelected } from '@vacationtracker/shared/components/filter-advanced/types'
import { ColumnsType } from 'antd/lib/table/interface'
import { IGetLocationsShort } from '../../../types/locations'
import { IEmailInvite } from '@vacationtracker/shared/types/user'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { IUsersFilter, ActiveTabEnum, IUserInvitedEventParams } from './types'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { GOOGLE_READ_USER_PERMISSION, GHOST_USERS_DATE_LIMIT } from '../../../constants'
import { PlatformEnum } from '@vacationtracker/shared/data/platforms'

const { Paragraph } = Typography
const supportLink = 'https://vacationtracker.crisp.help/en/article/google-workspace-editing-permissions-15pdkie/'

if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID || !process.env.REACT_APP_SLACK_CLIENT_ID || !process.env.REACT_APP_GOOGLE_CLIENT_ID) {
  throw new Error('Client ID are required')
}

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)
const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID)

const UsersPage = (): React.ReactElement => {
  const location = useLocation()
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { modal, notification } = App.useApp()
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser: {
    id,
    platform,
    email,
    locale,
  } } = useAppSelector(selectAuthUserSlice)
  const { dateFormat } = useAppSelector(selectDateFormatSlice)
  const { actionNotifications, setActionNotifications, eventsNotifications } = useContext(notificationStore)
  const isMicrosoftBillingPayment = useSelector(isMicrosoftPayment)
  const amIAdmin = useSelector(roleAdmin)
  const amIApprover = useSelector(roleApprover)

  if (!authCompany?.platform) {
    throw new Error('Company is required!')
  }

  const [loadingUsers, setLoadingUsers] = useState(true)
  const [loading, setLoading] = useState(false)
  const [userList, setUserList] = useState<IUserFilteredInfo[]>([])
  const [userCount, setUserCount] = useState(0)
  const [showTeamModal, setShowTeamModal] = useState(false)
  const [showLocationsModal, setShowLocationsModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState<IUserFilteredInfo>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [teams, setTeams] = useState<ITeamShort[]>([])
  const [locations, setLocations] = useState<ILocationShort[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [showSyncExistingUsersModal, setShowSyncExistingUsersModal] = useState(false)
  const [isSubmittingSyncExistingUsersRequest, setIsSubmittingSyncExistingUsersRequest] = useState(false)
  const [disablePrev, setDisablePrev] = useState(true)
  const [disableNext, setDisableNext] = useState(true)
  const [usersPages, setUsersPages] = useState<string[]>([])
  const [showMsLoginRequiredModal, setShowMsLoginRequiredModal] = useState(false)
  const [showSlackLoginRequiredModal, setShowSlackLoginRequiredModal] = useState(false)
  const [showEnableGoogleApiModal, setShowEnableGoogleApiModal] = useState(false)
  const [showInviteUsersModal, setShowInviteUsersModal] = useState(false)
  const [showReInviteUsersModal, setShowReInviteUsersModal] = useState(false)
  const [invites, setInvites] = useState<any[]>([])
  const [invitationLocale, setInvitationLocale] = useState<LocaleEnum>(locale || LocaleEnum.en)
  const [invited, setInvited] = useState<IEmailInvite[]>([])
  const [sent, setSent] = useState<IEmailInvite[]>([])
  const [areInvitesLoading, setAreInvitesLoading] = useState(true)
  const [correlationId, setCorrelationId] = useState('')
  const [correlationExist, setCorrelationExist] = useState(false)
  const [labels, setLabels] = useState<IGetLabelsShort[]>([])
  const [filters, setFilters] = useState<IUsersFilter>({
    status: 'ACTIVE',
    name: '',
    locationIds: [],
    teamIds: [],
    labelIds: [],
    limit: 100,
    nextToken: '',
  })
  const [activeTab, setActiveTab] = useState<ActiveTabEnum>(ActiveTabEnum.active)
  const [initialFilterValues, setInitialFilterValues] = useState([{}])
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [showGoogleLoginRequiredModal, setShowGoogleLoginRequiredModal] = useState(false)
  const [msTenantId, setMsTenantId] = useState('')
  const [isMsAdminConsentRequired, setMsAdminConsentRequired] = useState(false)
  const [adminConsentUrl, setAdminConsentUrl] = useState<null | string>(null)
  const [showMsLoginAdditionalScopesRequiredModal, setShowMsLoginAdditionalScopesRequiredModal] = useState(false)

  const [getInvitationsQuery] = useManualQuery<IGetInvitations>(getInvitations)
  const [getLocationsTeamsAndLabelsShortQuery] = useManualQuery<IGetLocationsTeamsAndLabelsShort>(getLocationsTeamsAndLabelsShort)
  const [getLocationsApproverTeamsAndLabelsQuery] = useManualQuery<IGetLocationsApproverTeamsAndLabelsShort>(getLocationsApproverTeamsAndLabels)
  const [getGoogleScopesQuery] = useManualQuery<IGetGoogleScopes, { accessToken: string }>(getGoogleScopes)
  const [getUsersFilteredQuery] = useManualQuery<IGetUsersFiltered, IUsersFilter>(getUsersFiltered)
  const [getNumberOfActiveUsersQuery] = useManualQuery<{ getCompany: { numberOfActiveUsers: number } }>(getNumberOfActiveUsers)

  const importEmailUsersEnabled = posthogFeatureFlagEnabled(FeatureFlagEnum.emailUserImport)
  const shouldEnableFeatures = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)
  const saveFilters = new URLSearchParams(location.search).get('saveFilters') !== 'false'

  useEffect(() => {
    if (isInitialLoad) {
      const [locationIds = [], teamIds = [], labelIds = []] = ['locations', 'departments', 'labels'].map(name => new URLSearchParams(location.search).get(name)?.split(','))

      setFilters(prevValues => {
        return {
          ...prevValues,
          locationIds,
          teamIds,
          labelIds,
        }
      })

      setInitialFilterValues(() => {
        const data: ISelected[] = []

        if (locationIds?.length) {
          data.push({ type: 'Locations', values: locationIds as string[] })
        }
        if (teamIds?.length) {
          data.push({ type: 'Departments', values: teamIds as string[] })
        }
        if (labelIds?.length) {
          data.push({ type: 'Labels', values: labelIds as string[] })
        }

        if (data.length === 0) {
          return [{}]
        }
        return data
      })

      fetchUserList({
        ...filters,
        locationIds,
        teamIds,
        labelIds,
      })
    }
  }, [])

  useEffect(() => {
    if (platform === 'email' || activeTab === ActiveTabEnum.invites) {
      fetchInvitations()
    }
  }, [actionNotifications, eventsNotifications, activeTab])

  useEffect(() => {
    if (!isInitialLoad) {
      fetchUserList(filters)
    }
  }, [filters])

  useEffect(() => {
    if (isLoading) {
      fetchData()
    }
  }, [isLoading])

  useEffect(() => {
    if (isInitialLoad) {
      return
    }

    fetchUserList(filters)
    if (eventsNotifications[correlationId]) {
      setCorrelationExist(true)
    } else if (!eventsNotifications[correlationId] && correlationExist) {
      setCorrelationExist(false)
      setCorrelationId('')
    }
  }, [eventsNotifications])

  useEffect(() => {
    if (location.search.includes('openImportUsers=true')) {
      importNewUsers()
    }
  }, [location.search])

  useEffect(() => {
    fetchUserCount()
  }, [])

  const fetchInvitations = async () => {
    setAreInvitesLoading(true)
    const response = await getInvitationsQuery()
    if (!response.data || response.error) throw response.error
    const invited = response.data.getInvitations.filter(invitation => invitation.status !== 'accepted')
    const sent = response.data.getInvitations.filter(invitation => invitation.status === 'accepted' || invitation.status === 'pending')
    setInvited(invited)
    setSent(sent)
    setAreInvitesLoading(false)
  }

  useEffect(() => {
    const alreadyInvited = filter(userList, user => user.id !== id && !sent.some(invite =>
      (invite.email === user.email) ||
      (invite.updatedEmail === user.email) &&
      (!isBefore(new Date(invite.timestamp), new Date(GHOST_USERS_DATE_LIMIT)))))
    if (userList.length > 0 && importEmailUsersEnabled) {
      setInvites(alreadyInvited)
    }
  }, [userList, sent, invited])

  const addInvite = (data) => {
    const invited = find(invites, invite => invite.email === data.email)
    setInvites(invited ? invites : [...invites, data])
  }

  const removeInvite = (data) => {
    const invited = find(invites, invite => invite.email === data.email)
    setInvites(invited ? filter(invites, invite => invite.email !== invited.email) : invites)
  }

  const checkIsUninvited = (userId: string) => {
    return importEmailUsersEnabled && invites.find(invite => invite.id === userId)
  }

  const changeLocale = (newLocale: LocaleEnum) => {
    setInvitationLocale(newLocale)
  }

  const errorNotificationHandler = (error, platform, onClickHandler) => {
    const isTokenError = error.message === 'no_tokens'
    const errorMessageDescription = isTokenError ? formatMessage({ id: 'error.token.description' }, { platform }) : error.message
    notification.error({
      message: formatMessage({ id: 'error.generic' }),
      description: errorMessageDescription,
      key: 'error-notification-handler',
      btn: isTokenError &&
        <Button onClick={() => {
          onClickHandler()
          notification.destroy('error-notification-handler')
        }}>
          {formatMessage({ id: 'error.token.button' }, { platform })}
        </Button>,
      duration: 0,
    })
  }

  const fetchData = async () => {
    try {
      let response
      if (amIAdmin) {
        response = await getLocationsTeamsAndLabelsShortQuery()
        setTeams(response.data.getTeamListV2 as IGetTeamsShort[])
      }
      if (amIApprover) {
        response = await getLocationsApproverTeamsAndLabelsQuery({ variables: { id } })
        setTeams(response.data.getUser.approverToTeams as ITeamShort[])
      }
      if (authCompany.platform === 'google') {
        try {
          const googleScopesResponse = await getGoogleScopesQuery({ variables: { accessToken: googleAuth.getAccessToken() } })
          if (!googleScopesResponse.data || googleScopesResponse.error) throw googleScopesResponse.error
          const googleApiScopes = googleScopesResponse.data.getGoogleTokenInfo.scopes as string[]
          const vtGooogleIntegrationScopes = googleScopesResponse.data.getGoogleIntegrationSettings?.scopes
          if (googleApiScopes?.includes(GOOGLE_READ_USER_PERMISSION) && !vtGooogleIntegrationScopes?.includes(GOOGLE_READ_USER_PERMISSION)) {
            const userInfo = googleAuth.getSignedInUser()
            await Api.post('/core/event', {
              eventType: 'GOOGLE_INTEGRATION',
              eventGroup: 'INTEGRATION',
              scope: googleApiScopes.join(' '),
              domainName: userInfo.hd,
            })
          }
        } catch (error) {
          logger.warning(error)
        }
      }

      setLocations(response.data.getLocationList as IGetLocationsShort[])
      setLabels(response.data.getLabels as IGetLabelsShort[])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      logger.warning({
        errorType: 'ERROR FETCH DATA',
        error,
      })
    }
  }

  const fetchUserList = async (filters: IUsersFilter) => {
    try {
      setLoadingUsers(true)
      const response = await getUsersFilteredQuery({ variables: filters })
      if (!response.data || response.error) throw response.error

      if (response.data.getUsersFiltered.nextToken && response.data.getUsersFiltered.users.length === filters.limit) {
        setDisableNext(false)
      } else {
        setDisableNext(true)
      }

      if (response.data.getUsersFiltered.nextToken) {
        setUsersPages(prevState => {
          const page = [...prevState]
          if (response.data?.getUsersFiltered.nextToken) {
            page.push(response.data.getUsersFiltered.nextToken)
          }
          return page
        })
      }

      if (filters.nextToken === '') {
        setDisablePrev(true)
      } else {
        setDisablePrev(false)
      }

      const filteredUsers = response.data.getUsersFiltered.users
        .filter(user => user.location && user.team && user.name)

      setUserList(sortBy(filteredUsers, [(user) => user.name.toUpperCase()]))
      setLoadingUsers(false)
      setLoading(false)
      setIsInitialLoad(false)
    } catch (error) {
      logger.warning({
        errorType: 'ERROR FETCH USER LIST',
        error,
      })
    }
  }

  const fetchUserCount = async () => {
    try {
      const response = await getNumberOfActiveUsersQuery()
      if (!response.data || response.error) throw response.error
      setUserCount(response.data.getCompany.numberOfActiveUsers)
    } catch (error) {
      logger.warning({
        errorType: 'ERROR FETCH USER COUNT',
        error,
      })
      setUserCount(0)
    }
  }

  const syncFromMsApiByTeamBase = async (tenantId: string, accessToken: string) => {
    setIsSubmittingSyncExistingUsersRequest(true)
    try {
      const scopes = msAuth.getPermissions(accessToken)
      if (!scopes.toLowerCase().includes('group.read.all')) {
        return setShowMsLoginAdditionalScopesRequiredModal(true)
      }
      const refreshToken = sessionStorage.getItem('msWebAppTokensRefreshToken') || sessionStorage.getItem('msTokensRefreshToken') as string
      setShowMsLoginAdditionalScopesRequiredModal(false)
      await syncUsers(accessToken, refreshToken)
    } catch (error) {
      setShowMsLoginAdditionalScopesRequiredModal(false)
      setMsAdminConsentRequired(true)
    }
  }


  const syncFromMsApi = () => {
    setIsSubmittingSyncExistingUsersRequest(true)
    try {
      msAuth.getTokens()
      msAuth.refreshTokens()
        .then(async (token) => {
          await syncUsers(token.accessToken)
        })
        .catch(error => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if (JSON.parse(error)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const err = JSON.parse(error)
            if (err.error === 'invalid_grant' || err.error === 'Authorization_RequestDenied') {
              setShowMsLoginRequiredModal(true)
              return
            }
          }
        })
    } catch (error) {
      setShowMsLoginRequiredModal(true)
    }
  }

  const syncFromGoogleApi = async (): Promise<void> => {
    setIsSubmittingSyncExistingUsersRequest(true)
    const googleTokenExpiration = sessionStorage.getItem('googleTokenExpiration')
    if (!googleTokenExpiration || isAfter(new Date(), new Date(googleTokenExpiration))) {
      setShowEnableGoogleApiModal(true)
    } else {
      setShowEnableGoogleApiModal(false)
      try {
        await googleAuth.tokenInfo()
        syncUsers(googleAuth.getAccessToken())
      } catch (error) {
        logger.warning(error)
        setShowEnableGoogleApiModal(true)

        errorNotificationHandler(error, 'Google', getGoogleTokens)
      }
    }
  }

  const syncFromSlackApi = () => {
    try {
      setIsSubmittingSyncExistingUsersRequest(true)

      syncUsers(slackAuth.getUserToken())
    } catch (error) {
      logger.warning({
        errorType: 'ERROR FETCH FROM SLACK API',
        error,
      })
      setShowSyncExistingUsersModal(false)
      errorNotificationHandler(error, 'Slack', getSlackTokens)
    }
  }

  const syncMsUsersWithWebAppTokens = async (tenantId: string) => {
    try {
      if (
        (
          !sessionStorage.getItem('msWebAppTokensExpiration') ||
          isAfter(new Date(), new Date(sessionStorage.getItem('msWebAppTokensExpiration') as string))
        ) &&
        !showMsLoginAdditionalScopesRequiredModal
      ) {
        setShowMsLoginAdditionalScopesRequiredModal(true)
      } else {
        const { accessToken } = await msAuth.getMSWebAppTokens(tenantId, addMinutes(new Date(), 15).toString(), 'offline_access user.read group.read.all')
        syncFromMsApiByTeamBase(tenantId, accessToken)
        setShowMsLoginAdditionalScopesRequiredModal(false)
      }
    } catch (error) {
      logger.warning(error)
      setShowMsLoginAdditionalScopesRequiredModal(false)
      setMsAdminConsentRequired(true)
    }
  }

  const getBasicMsToken = () => {
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all'])
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(([tokens, msUser, tenantId]) => {
        if (tenantId !== authCompany.organizationId || `microsoft-${msUser.id}` !== id) {
          wrongMicrosoftAccount()
          return
        }
        setShowMsLoginRequiredModal(false)
        return syncFromMsApi()
      })
      .catch((error) => {
        logger.warning(error)
        setShowMsLoginRequiredModal(false)
        setShowSyncExistingUsersModal(false)
        setIsSubmittingSyncExistingUsersRequest(false)
        errorNotificationHandler(error, 'Microsoft', getBasicMsToken)
      })

  }

  const wrongMicrosoftAccount = () => {
    msAuth.removeTokens()
    notification.error({
      message: formatMessage({ id: 'error.microsoft.wrongAccount' }),
      description: <div>
        <Paragraph>{formatMessage({ id: 'errors.microsoft.wrongMicrosoftAccountDescription' }, { email })}</Paragraph>
        <Button onClick={() => getBasicMsToken()}>{formatMessage({ id: 'error.microsoft.logInWithDifferentAccount' })}</Button>
      </div>,
      duration: 0,
    })
  }

  const wrongSlackAccount = () => {
    slackAuth.removeTokens()
    notification.error({
      message: formatMessage({ id: 'error.slack.wrongAccount' }),
      description: <div>
        <Paragraph>{formatMessage({ id: 'errors.slack.wrongSlackAccountDescription' })}</Paragraph>
        <Button onClick={() => getSlackTokens()}>{formatMessage({ id: 'error.slack.logInWithDifferentAccount' })}</Button>
      </div>,
      duration: 0,
    })
    setShowSlackLoginRequiredModal(false)
  }

  const getSlackTokens = () => {
    slackAuth.signin(
      ['chat:write', 'commands', 'team:read', 'users:read', 'users:read.email'],
      ['channels:write', 'groups:write', 'users:read', 'team:read', 'channels:read', 'groups:read', 'users.profile:write']
    ).then(async (response) => {
      if ((!response.enterprise && response.team.id !== authCompany.organizationId) || (response.enterprise && response.enterprise?.id !== authCompany.organizationId)) {
        logger.error('WRONG SLACK ACCOUNT', JSON.stringify(response))
        wrongSlackAccount()
        return
      }
      slackAuth.setUserToken(response.authed_user.access_token as string)
      try {
        await Api.post('/slack/update-tokens', {
          token: response.authed_user.access_token,
          botToken: response.access_token,
        })
      } catch (error) {
        logger.warning({
          errorType: 'UPDATE TOKEN ERROR',
          error: error.response,
        })
      }
      setShowSlackLoginRequiredModal(false)
      syncFromSlackApi()
    }).catch(error => {
      logger.warning({
        errorType: 'SLACK SIGNIN ERROR',
        error,
      })
      handleErrorNotification({ message: 'To proceed, please login to your Slack account' }, undefined, 'Missing Slack Scopes')
    })
  }

  const getGoogleTokens = () => {
    googleAuth.signin(true).then(() => {
      syncFromGoogleApi()
    })
  }

  const syncUsers = async (token: string, refreshToken?: string) => {
    setIsSubmittingSyncExistingUsersRequest(true)
    const body = {
      eventType: 'SYNC_EXISTING_USERS',
      eventGroup: 'BULK_ACTIONS',
      organizationId: authCompany.organizationId,
      token,
      ...(refreshToken && { refreshToken }),
    }


    const response = await Api.post('/core/event', body)
    setIsSubmittingSyncExistingUsersRequest(false)
    setShowSyncExistingUsersModal(false)
    notification.open({
      key: response.correlationId,
      message: formatMessage({ id: 'users.syncExistingUsers.InProgress' }),
      icon: (<LoadingOutlined />),
      duration: 0,
    })
    setActionNotifications([...actionNotifications, response.correlationId])
  }

  const importNewUsers = () => {
    if (isMicrosoftBillingPayment) {
      return history.push('/app/users/manage-licences')
    } else if (platform === 'email') {
      if (importEmailUsersEnabled) {
        return history.push('/app/users/import-email-users')
      }
      setShowInviteUsersModal(true)
      return
    } else {
      return history.push('/app/users/import')
    }
  }

  const updateUser = async (userId, data) => {
    try {
      setLoading(true)
      const response = await Api.post('/core/event', {
        eventType: 'USER_STATUS_CHANGED',
        eventGroup: 'USER',
        userId,
        ...data,
      })

      setUserList(userList.filter(user => user.id !== userId))
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'user.status.updateInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([...actionNotifications, response.correlationId])
      setLoading(false)
    } catch (err) {
      logger.warning({
        errorType: 'ERROR UPDATE USER',
        error: err,
      })
      setLoading(false)
    } finally {
      setSelectedRowKeys([])
    }
  }

  const changeTeam = async (teamId) => {
    try {
      setLoading(true)
      const response = await Api.post('/core/event', {
        eventType: 'TEAM_USER_MOVED',
        eventGroup: 'USER',
        teamId,
        userId: selectedUser?.id,
      })

      const teamName = (teams.find((team) => team.id === teamId) as ITeamShort).name
      setUserList(userList.map(user => {
        if (selectedUser?.id === user.id) {
          return {
            ...user,
            team: {
              id: teamId,
              name: teamName,
            },
          }
        } else {
          return user
        }
      }))
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'user.team.moveInProgress' }, { name: teamName }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([...actionNotifications, response.correlationId])
      setLoading(false)
      setShowTeamModal(false)
    } catch (error) {
      logger.warning({
        errorType: 'ERROR CHANGE TEAM',
        error,
      })
      setLoading(false)
      setShowTeamModal(false)
    }
  }

  const changeLocation = async (locationId) => {
    try {
      setLoading(true)
      const response = await Api.post('/core/event', {
        eventType: 'LOCATION_USER_MOVED',
        eventGroup: 'USER',
        locationId,
        userId: selectedUser?.id,
      })
      const locationName = (locations.find(location => location.id === locationId) as ILocationShort).name

      setUserList(userList.map(user => {
        if (selectedUser?.id === user.id) {
          return {
            ...user,
            location: {
              id: locationId,
              name: locationName,
            },
          }
        } else {
          return user
        }
      }))

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'user.location.moveInProgress' }, { name: locationName }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([...actionNotifications, response.correlationId])
      setLoading(false)
      setShowLocationsModal(false)
    } catch (error) {
      logger.warning({
        errorType: 'ERROR CHANGE LOCATION',
        error,
      })
      setLoading(false)
      setShowLocationsModal(false)
    }
  }

  const changeUsersStatus = async (status) => {
    try {
      setLoading(true)
      const response = await Api.post('/core/event', {
        eventType: 'BULK_USER_STATUS',
        eventGroup: 'BULK_ACTIONS',
        users: selectedRowKeys.map(userId => {
          return ({
            userId,
            status,
          })
        }),
      })

      const newUsersData: IUserFilteredInfo[] = []
      userList.forEach(user => {
        if (!selectedRowKeys.find(userId => userId === user.id)) {
          newUsersData.push(user)
        }
      })

      setUserList(newUsersData)

      setSelectedRowKeys([])
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'user.bulkChangeStatusInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([...actionNotifications, response.correlationId])
      setLoading(false)
    } catch (error) {
      logger.warning({
        errorType: 'ERROR CHANGE USERS STATUS',
        error,
      })
      setLoading(false)
    }
  }

  const changePage = (type) => {
    setLoading(true)
    let nextToken
    if (type === 'next') {
      nextToken = usersPages[usersPages.length - 1]
    }
    if (type === 'prev') {
      if (disableNext) {
        nextToken = usersPages[usersPages.length - 2]
        const newPagination = [...usersPages]
        newPagination.splice(-1)
        setUsersPages(newPagination)
      } else {
        nextToken = usersPages[usersPages.length - 3]
        const newPagination = [...usersPages]
        newPagination.splice(-2)
        setUsersPages(newPagination)
      }
    }

    setFilters(prevState => {
      return {
        ...prevState,
        nextToken: nextToken ? nextToken : '',
      }
    })
  }

  const handleMenuClick = (event, userId, user: IUserFilteredInfo) => {
    const userData: IUserStatusChangedBody = {
      status: 'ACTIVE',
      platform: user.platform,
    }

    // handle invite user
    if (event.key === 'inviteUser') {
      modal.confirm({
        title: formatMessage({ id: 'app.invite' }),
        content: formatMessage({ id: 'users.inviteUserConfirm' }, { name: user.name }),
        icon: <MailOutlined />,
        okText: formatMessage({ id: 'app.invite' }),
        onOk() { sendInvite(user.email) },
      })
    }

    if (event.key === 'deactivateUser') {
      userData.status = 'INACTIVE'
      modal.confirm({
        title: formatMessage({ id: 'users.deactivate' }),
        content: formatMessage({ id: 'users.deactivateUserConfirm' }, { name: user.name }),
        icon: <ExclamationCircleOutlined />,
        okText: formatMessage({ id: 'users.deactivate' }),
        onOk() { updateUser(userId, userData) },
      })
    }
    if (event.key === 'deleteUser') {
      userData.status = 'DELETED'
      modal.confirm({
        title: formatMessage({ id: 'app.deleteUser' }),
        content: formatMessage({ id: 'users.deleteUserUserConfirm' }, { name: user.name }),
        icon: <ExclamationCircleOutlined />,
        okText: formatMessage({ id: 'components.modal.confirm' }),
        onOk() { updateUser(userId, userData) },
      })
    }
    if (event.key === 'activeUser') {
      updateUser(userId, userData)
    }
    if (event.key === 'changeUserTeam') {
      setShowTeamModal(true)
      setSelectedUser(user)
    }
    if (event.key === 'changeUserLocation') {
      setShowLocationsModal(true)
      setSelectedUser(user)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleErrorNotification = (error: any, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'notifications.handleSubmitError' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'error.generic' }),
      description,
      duration: 0,
    })
  }

  const handleChangeTab = (status: ActiveTabEnum) => {
    setActiveTab(status)
    setUsersPages([])
    setSelectedRowKeys([])
    setDisablePrev(true)
    setDisableNext(true)

    setFilters(prevState => {
      return {
        ...prevState,
        nextToken: '',
        status,
      }
    })
  }

  const syncExistingUsers = async () => {
    if (authCompany.platform === 'microsoft') {
      const tenantId = await Api.get<string>('/microsoft/get-tenant')
      setMsTenantId(tenantId)
      const teamId = authCompany.msTeamId && authCompany.msTeamId !== tenantId ? authCompany.msTeamId : null
      if (teamId) {
        setAdminConsentUrl(`https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`)
        syncMsUsersWithWebAppTokens(tenantId)
      } else {
        syncFromMsApi()
      }

    }
    if (authCompany.platform === 'slack') {
      syncFromSlackApi()
    }
    if (authCompany.platform === 'google') {
      getGoogleTokens()
    }
  }

  const sendInvites = async (reinvite = false) => {
    try {

      const body: Partial<IUserInvitedEventParams> = {
        eventType: 'USERS_INVITED',
        eventGroup: 'USER',
        userId: id,
        emails: invites.map(invite => invite.email),
        locale: invitationLocale,
      }

      if (reinvite) {
        body.reinvite = true
      }
      const response = await Api.post('/core/event', body)
      setActionNotifications([...actionNotifications, response.correlationId])
      handleChangeTab(ActiveTabEnum.invites)
      setShowInviteUsersModal(false)
      setShowReInviteUsersModal(false)
      setInvites([])
    } catch (error) {
      logger.error(error)
    }
  }

  const sendInvite = async (email: string) => {
    try {
      const response = await Api.post('/core/event', {
        eventType: 'USERS_INVITED',
        eventGroup: 'USER',
        emails: [email],
        userId: id,
        locale: invitationLocale,
      })
      setActionNotifications([...actionNotifications, response.correlationId])
    } catch (error) {
      logger.error(error)
    }
  }

  const deleteInvite = async (email: string) => {
    try {
      const response = await Api.post('/core/event', {
        eventType: 'USER_INVITATION_DELETED',
        eventGroup: 'USER',
        email,
        userId: id,
      })
      setActionNotifications([...actionNotifications, response.correlationId])
    } catch (error) {
      logger.error(error)
    }
  }

  const showConfirmDeleteInvite = (email: string) => {
    modal.confirm({
      title: formatMessage({ id: 'users.deleteInvite' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: 'users.deleteInviteConfirm' }, { email }),
      okText: formatMessage({ id: 'users.deleteInvite' }),
      okType: 'danger',
      maskClosable: true,
      onOk() {
        deleteInvite(email)
      },
    })
  }

  const columns = [{
    title: <IntlMessages id="app.name" />,
    dataIndex: 'name',
    key: 'name',
    // eslint-disable-next-line react/display-name
    render: (name, row) => (
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <UserAvatar id={row.id} avatar={row.imageUrl} name={name} isShowingName={true} isLinkToPage={true} shape="circle" />
        {authCompany?.platform === PlatformEnum.Email && 'INACTIVE' && row.role !== 'Admin' && checkIsUninvited(row.id as string) &&
          <Tag color='orange'>{formatMessage({ id: 'users.uninvited' })}</Tag>
        }
      </div>
    ),
  }, {
    title: <IntlMessages id="app.department" />,
    dataIndex: 'team',
    className: 'team',
    key: 'team',
    render: (team) => (
      <Link to={`/app/settings/departments/${team.id}`}>{team.name}</Link>
    ),
  }, {
    title: <IntlMessages id="app.location" />,
    dataIndex: 'location',
    key: 'location',
    render: (location) => (
      <Link to={`/app/settings/locations/${location.id}/general`}>{location.name}</Link>
    ),
  }, {
    title: <IntlMessages id="app.users.role" />,
    dataIndex: 'role',
    key: 'role',
    render: (role) => {
      if (role === 'User') {
        return <IntlMessages id='app.user' />
      } else if (role === 'Admin') {
        return <IntlMessages id='app.role.administrator' />
      } else {
        return <IntlMessages id='app.role.approver' />
      }
    },
  },
  shouldEnableFeatures && {
    title: <IntlMessages id="app.labels" />,
    dataIndex: 'labels',
    key: 'labels',
    // eslint-disable-next-line react/display-name
    render: (labels: IGetLabelsShort[], { id }) => {
      const userLabelsStringified = labels.length > 3 ? labels.map(l => l.name).join(', ') : ''
      return <Tooltip placement="topLeft" title={userLabelsStringified}>
        {take(labels, 3).map((label) => {
          return <Tag key={label.id} color={label.color}>
            <span style={{ color: invertHexColor(label.color, true) }}>
              {label.name}
            </span>
          </Tag>
        })}
        {labels.length > 3 &&
          <Link to={`/app/users/${id}`}>
            {`+${labels.length - 3}`}
          </Link>
        }
      </Tooltip>
    },
  },
  {
    title: '',
    className: 'action',
    width: 54,
    dataIndex: 'id',
    key: 'id',
    render: (id: string, row: IUserFilteredInfo) => (
      <Dropdown
        menu={{
          items: [
            {
              key: 'view',
              label: <Link to={`/app/users/${id}`}><IntlMessages id="users.viewUser" /></Link>,
            },
            (amIAdmin && teams.length > 1) ? {
              key: 'changeUserTeam',
              label: <IntlMessages id="users.changeUserTeam" />,
            } : null,
            (amIAdmin && locations.length > 1) ? {
              key: 'changeUserLocation',
              label: <IntlMessages id="users.changeUserLocation" />,
            } : null,
            (amIAdmin && !isMicrosoftBillingPayment && row.status === 'INACTIVE' && row.role !== 'Admin') ? {
              key: 'activeUser',
              label: <IntlMessages id="app.activate" />,
            } : null,
            (amIAdmin && !isMicrosoftBillingPayment && row.status === 'INACTIVE' && row.role !== 'Admin' && platform === 'email') ? {
              key: 'deleteUser',
              label: <IntlMessages id="app.deleteUser" />,
            } : null,
            (amIAdmin && !isMicrosoftBillingPayment && row.status === 'ACTIVE' && row.role !== 'Admin') ? {
              key: 'deactivateUser',
              label: <IntlMessages id="users.deactivate" />,
            } : null,
            (amIAdmin && !isMicrosoftBillingPayment && authCompany?.platform === PlatformEnum.Email && row.status === 'ACTIVE' && row.role !== 'Admin' && checkIsUninvited(id)) ? {
              key: 'inviteUser',
              label: <IntlMessages id="app.invite" />,
            } : null,
          ].filter(item => item !== null),
          onClick: (event) => handleMenuClick(event, id, row),
        }}
        trigger={['click']}
        placement="bottomRight"
      >
        <EllipsisOutlined />
      </Dropdown>
    ),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }].filter(Boolean) as ColumnsType<any>

  const columnInvites = [{
    title: <IntlMessages id="app.email" />,
    dataIndex: 'email',
    width: '20%',
    key: 'email',
    // eslint-disable-next-line react/display-name
    render: (email: string) => email,
  },
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
    // eslint-disable-next-line react/display-name
    render: (status: string) => {
      return status === 'pending' || status === 'expired' ?
        <Tag color={status === 'pending' ? 'orange' : status === 'expired' ? 'red' : ''}>{
          (status === 'pending' ? formatMessage({ id: 'app.pending' }) : status === 'expired' ? formatMessage({ id: 'app.expired' }) : status).toLowerCase()
        }</Tag> : null
    },
  },
  {
    title: '',
    className: 'action',
    width: 54,
    dataIndex: 'id',
    key: 'id',
    // eslint-disable-next-line react/display-name
    render: (data, row) => (
      <Button type='link' onClick={() => {
        setInvites([row])
        setShowReInviteUsersModal(true)
      }}>
        <IntlMessages id="users.reinvite" />
      </Button>
    ),
  },
  {
    title: '',
    className: 'action',
    width: 54,
    dataIndex: 'i',
    key: 'id',
    render: (data, row) => (
      <Button type='link' onClick={() => showConfirmDeleteInvite(row.email as string)}>
        <IntlMessages id="app.delete" />
      </Button>
    ),
  }]

  const columnsDeleted = [{
    title: <IntlMessages id="app.name" />,
    dataIndex: 'name',
    key: 'name',
    // eslint-disable-next-line react/display-name
    render: (name, row) => (
      <UserAvatar id={row.id} avatar={row.imageUrl} name={name} isShowingName={true} isLinkToPage={true} shape="circle" />
    ),
  }, {
    title: <IntlMessages id="app.department" />,
    dataIndex: 'team',
    className: 'team',
    key: 'team',
    render: (team) => (
      team.name
    ),
  }, {
    title: <IntlMessages id="app.location" />,
    dataIndex: 'location',
    key: 'location',
    render: (location) => (
      location.name
    ),
  }, {
    title: <IntlMessages id="app.users.role" />,
    dataIndex: 'role',
    key: 'role',
  }, {
    title: <IntlMessages id="users.startDate" />,
    dataIndex: 'startDate',
    key: 'startDate',
    // eslint-disable-next-line react/display-name
    render: (startDate) => (
      <FormattedDate value={startDate} format={dateFormat} />
    ),
  }, {
    title: '',
    className: 'action',
    width: 54,
    dataIndex: 'id',
    key: 'id',
    // eslint-disable-next-line react/display-name
    render: (id) => (
      <Link to={`/app/users/${id}`}><IntlMessages id="users.viewUser" /></Link>
    ),
  }]

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRows) => {
      setSelectedRowKeys(selectedRows as string[])
    },
    getCheckboxProps: (record) => ({
      disabled: record.role === 'Admin', // Column configuration not to be checked
      name: record.name,
    }),
  }

  const filterChanged = (data) => {
    let reject = true
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    for (const [key] of Object.entries(data)) {
      if (!isEqual(filters[key], data[key])) {
        reject = false
      }
    }
    if (reject) return

    const { locationIds, teamIds, labelIds } = data
    let url = location.pathname
    let searchParams = ''
    if (!saveFilters) {
      searchParams = '&saveFilters=false'
    }
    if (locationIds?.length) {
      searchParams += `&locations=${locationIds.join(',')}`
    }
    if (teamIds?.length) {
      searchParams += `&departments=${teamIds.join(',')}`
    }
    if (labelIds?.length) {
      searchParams += `&labels=${labelIds.join(',')}`
    }
    url += searchParams.replace('&', '?')
    window.history.pushState({}, '', url)

    setUsersPages([])
    setFilters(prevState => {
      return {
        ...prevState,
        nextToken: '',
        ...data,
      }
    })
  }

  const onSearch = (name: string) => {
    setUsersPages([])
    setFilters((prevState) => {
      return {
        ...prevState,
        nextToken: '',
        name,
      }
    })
  }
  const getCTAButtonTranslation = () => {
    if (isMicrosoftBillingPayment) {
      return 'app.microsoft.manageLicenses'
    } else if ((platform === 'email' && importEmailUsersEnabled)) {
      return 'users.createUsers'
    } else if (platform !== 'email') {
      return 'users.importNewUsers'
    } else {
      return 'users.inviteUsers'
    }
  }
  const adminTable = amIAdmin && !isMicrosoftBillingPayment ? { rowSelection } : {}

  const usersTabs: TabsProps['items'] = [
    {
      key: 'ACTIVE',
      label: <><IntlMessages id="app.active" /> <Tag color="default">{amIAdmin ? userCount : userList.length}</Tag></>,
      children: <Table
        loading={loadingUsers || loading}
        {...adminTable}
        dataSource={userList}
        columns={columns}
        rowKey={record => record.id}
        showSorterTooltip={false}
        pagination={false}
      />,
    },
    (amIAdmin && platform === 'email') ? {
      key: 'INVITES',
      label: <IntlMessages id="users.invitations" />,
      children: <Table
        loading={areInvitesLoading || loadingUsers || loading}
        dataSource={invited}
        columns={columnInvites}
        rowKey={record => record.email}
        showSorterTooltip={false}
        pagination={false}
      />,
    } : null,
    amIAdmin ? {
      key: 'INACTIVE',
      label: <IntlMessages id="users.inactiveUsers" />,
      children: <Table
        loading={loadingUsers || loading}
        {...adminTable}
        dataSource={userList}
        columns={columns}
        rowKey={record => record.id}
        showSorterTooltip={false}
        pagination={false}
      />,
    } : null,
    amIAdmin ? {
      key: 'DELETED',
      label: <IntlMessages id="users.deletedUsers" />,
      children: <Table
        loading={loadingUsers || loading}
        dataSource={userList}
        columns={columnsDeleted}
        rowKey={record => record.id}
        showSorterTooltip={false}
        pagination={false}
      />,
    } : null,
  ].filter(item => item !== null) as TabsProps['items']

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className='main-content'>
          <div className='main-content-header'>
            <div className='main-content-header-title'>
              <IntlMessages id='app.users' />
            </div>
            <div className='main-content-header-breadcrumb'>
              <Search
                displaySpinner={true}
                onLoad={loadingUsers}
                onSearch={onSearch}
                placeholder={formatMessage({ id: 'users.search' })}
              />
            </div>
          </div>
          <div className='main-content-body'>
            <Row justify="start" gutter={[8, 16]}>
              <Col>
                {amIAdmin && (
                  <Tooltip
                    title={(platform === 'email' && importEmailUsersEnabled) ? <IntlMessages id="users.createUsersDescriptionShort" /> : null}
                  >
                    <Button onClick={importNewUsers} >
                      <PlusCircleOutlined style={{ marginRight: 5 }} />
                      <IntlMessages
                        id={getCTAButtonTranslation()}
                      />
                    </Button>
                  </Tooltip>
                )}
              </Col>
              {(amIAdmin && platform == 'email' && importEmailUsersEnabled) && (
                <Col>
                  <Tooltip
                    title={(platform === 'email' && importEmailUsersEnabled) ? <IntlMessages id="users.inviteUsersDescriptionShort" /> : null}
                  >
                    <Button
                      onClick={() => setShowInviteUsersModal(true)}
                    >

                      <MailOutlined style={{ marginRight: 5 }} />
                      <IntlMessages id='users.inviteUsers' />
                    </Button>
                  </Tooltip>
                </Col>
              )}
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col flex="auto" >
                <Row>
                  {selectedRowKeys.length > 0 && filters.status === 'ACTIVE' && (
                    <Button
                      danger
                      onClick={() => {
                        changeUsersStatus('INACTIVE')
                      }}
                    >
                      <IntlMessages id='users.deactivate' />
                    </Button>
                  )}
                  {selectedRowKeys.length > 0 &&
                    filters.status === 'INACTIVE' && (
                    <Button
                      type='primary'
                      onClick={() => {
                        changeUsersStatus('ACTIVE')
                      }}
                    >
                      <IntlMessages id='app.activate' />
                    </Button>
                  )}
                </Row>
              </Col>
              <Col className='vt-col-sm-24'>
                {!isInitialLoad && (
                  <FilterAdvanced
                    page='users'
                    data={{
                      Locations: locations,
                      Departments: teams,
                      Labels: labels,
                    }}
                    onChangeFilter={filterChanged}
                    showLabels={shouldEnableFeatures}
                    initialValues={initialFilterValues}
                    saveFilters={saveFilters}
                    currentUserId={id}
                  />
                )}
              </Col>
            </Row>
            <Tabs
              activeKey={activeTab}
              onChange={(tab) => handleChangeTab(tab as ActiveTabEnum)}
              style={{ marginBottom: 10 }}
              items={usersTabs}
            />
            <Row>
              <Col flex={1}>
                <Button
                  onClick={() => changePage('prev')}
                  style={{ marginRight: 5 }}
                  disabled={disablePrev}
                >
                  <IntlMessages id='app.previous' />
                </Button>
                <Button
                  onClick={() => changePage('next')}
                  disabled={disableNext}
                >
                  <IntlMessages id='app.next' />
                </Button>
              </Col>
              {amIAdmin && platform !== 'email' && (
                <Col>
                  <Button
                    onClick={() =>
                      setShowSyncExistingUsersModal(!showSyncExistingUsersModal)
                    }
                    style={{ marginRight: 5 }}
                  >
                    <IntlMessages id='users.syncExistingUsers' />
                  </Button>
                </Col>
              )}
            </Row>
            {showSyncExistingUsersModal && (
              <Modal
                title={<IntlMessages id='users.syncExistingUsers' />}
                open={showSyncExistingUsersModal}
                closeIcon={<CloseCircleOutlined />}
                footer={null}
                onCancel={() => setShowSyncExistingUsersModal(false)}
              >
                <p>
                  <IntlMessages id='users.aboutToSync' />
                </p>
                <p style={{ textAlign: 'right', marginTop: 20, marginBottom: 0 }}>
                  {authCompany.platform === 'google' ? (
                    <Button
                      onClick={syncExistingUsers}
                      style={{ marginRight: 5 }}
                    >
                      <IntlMessages id='users.syncWithoutGoogleConsent' />
                    </Button>
                  ) : (
                    <Button
                      type='primary'
                      loading={isSubmittingSyncExistingUsersRequest}
                      onClick={syncExistingUsers}
                      style={{ marginRight: 5 }}
                    >
                      <IntlMessages id='app.ok' />
                    </Button>
                  )}
                  <Button
                    onClick={() => setShowSyncExistingUsersModal(false)}
                    style={{ marginRight: 5 }}
                  >
                    <IntlMessages id='app.cancel' />
                  </Button>
                </p>
              </Modal>
            )}
            {showInviteUsersModal && (
              <Modal
                title={formatMessage({ id: 'users.inviteUsers' })}
                open={showInviteUsersModal}
                onOk={() => {
                  sendInvites()
                }}
                okButtonProps={{ disabled: invites.length === 0 }}
                onCancel={() => {
                  setShowInviteUsersModal(false)
                }}
                okText={formatMessage({ id: 'app.sendInvites' })}
              >
                <InviteAndManageUsersWithEmail
                  addInvite={addInvite}
                  selectedLocale={invitationLocale}
                  removeInvite={removeInvite}
                  changeLocale={changeLocale}
                  authUserEmail={email}
                  invitedUsers={invites}
                  showInputField={!importEmailUsersEnabled}
                />
              </Modal>
            )}
            {showReInviteUsersModal && (
              <Modal
                title={formatMessage({ id: 'users.inviteUsers' })}
                open={showReInviteUsersModal}
                onOk={() => {
                  sendInvites(true)
                }}
                onCancel={() => {
                  setShowReInviteUsersModal(false)
                  setInvites([])
                }}
              >
                <IntlMessages
                  id='users.reInviteConfirm'
                  values={{ email: invites[0]?.email }}
                />
              </Modal>
            )}
            {showTeamModal && (
              <ChangeTeamForm
                teams={teams}
                visibleModal={showTeamModal}
                handleCancel={() => setShowTeamModal(false)}
                onSave={(teamId: string) => {
                  changeTeam(teamId)
                }}
                selectedUser={selectedUser}
              />
            )}
            {showLocationsModal && (
              <ChangeLocationForm
                locations={locations}
                visibleModal={showLocationsModal}
                handleCancel={() => setShowLocationsModal(false)}
                onSave={(locationId) => changeLocation(locationId)}
                selectedUser={selectedUser}
              />
            )}
            {showMsLoginRequiredModal && (
              <Modal
                title={formatMessage({
                  id: 'error.microsoft.notificationsLoginRequiredTitle',
                })}
                open={showMsLoginRequiredModal}
                onOk={() => getBasicMsToken()}
                onCancel={() => {
                  setIsSubmittingSyncExistingUsersRequest(false)
                  setShowMsLoginRequiredModal(false)
                  setShowSyncExistingUsersModal(false)
                }}
              >
                <p>
                  <IntlMessages id='microsoft.usersLoginRequiredDescription1' />
                </p>
                <p>
                  <IntlMessages id='microsoft.usersLoginRequiredDescription2' />
                </p>
              </Modal>
            )}
            {showSlackLoginRequiredModal && (
              <Modal
                title={formatMessage({
                  id: 'error.slack.usersLoginRequiredTitle',
                })}
                open={showSlackLoginRequiredModal}
                onOk={getSlackTokens}
                onCancel={() => {
                  setShowSlackLoginRequiredModal(false)
                }}
              >
                <p>
                  <IntlMessages id='slack.usersLoginRequiredDescription1' />
                </p>
                <p>
                  <IntlMessages id='slack.usersLoginRequiredDescription2' />
                </p>
              </Modal>
            )}
            {showGoogleLoginRequiredModal && (
              <Modal
                title={formatMessage({ id: 'error.google.loginRequiredTitle' })}
                open={showGoogleLoginRequiredModal}
                onOk={() => getGoogleTokens()}
                onCancel={() => {
                  setShowGoogleLoginRequiredModal(false)
                  history.push('/app/dashboard')
                }}
              >
                <p>
                  <IntlMessages id='error.google.usersLoginRequiredDescription1' />
                </p>
                <p>
                  <IntlMessages id='error.google.usersLoginRequiredDescription2' />
                </p>
              </Modal>
            )}
            {showEnableGoogleApiModal && (
              <Modal
                title={formatMessage({
                  id: 'error.google.directoryApiDisabledTitle',
                })}
                open={showEnableGoogleApiModal}
                footer={null}
                onCancel={() => {
                  setShowEnableGoogleApiModal(false)
                }}
              >
                <Paragraph>
                  <IntlMessages id='error.google.directoryApiDisabledP1' />
                </Paragraph>
                <Paragraph>
                  <IntlMessages id='error.google.directoryApiDisabledP2' />
                </Paragraph>
                <Paragraph copyable={{ text: supportLink }}>
                  <a
                    href={supportLink}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {supportLink}
                  </a>
                </Paragraph>
                <Paragraph>
                  <IntlMessages id='error.google.directoryApiDisabledP4' />
                </Paragraph>
              </Modal>
            )}
            {showMsLoginAdditionalScopesRequiredModal && (
              <Modal
                title={formatMessage({
                  id: 'error.microsoft.notificationsLoginRequiredTitle',
                })}
                open={showMsLoginAdditionalScopesRequiredModal}
                onOk={() => {
                  syncMsUsersWithWebAppTokens(msTenantId)
                }}
                onCancel={() => {
                  setShowMsLoginAdditionalScopesRequiredModal(false)
                }}
              >
                <p>
                  <IntlMessages id='microsoft.usersLoginRequiredDescription1' />
                </p>
                <p>
                  <IntlMessages id='microsoft.usersLoginRequiredDescription2' />
                </p>
              </Modal>
            )}
            <Modal
              title={formatMessage({
                id: 'microsoft.notificationsLoginRequiredTitle',
              })}
              open={Boolean(
                authCompany?.platform === 'microsoft' &&
                isMsAdminConsentRequired &&
                adminConsentUrl
              )}
              onCancel={() => {
                setMsAdminConsentRequired(false)
              }}
              footer={null}
            >
              <Paragraph>
                <IntlMessages id='components.importUsersForm.consent.line1' />
              </Paragraph>
              <Paragraph copyable={{ text: adminConsentUrl as string }}>
                <a href={adminConsentUrl as string}>{adminConsentUrl}</a>
              </Paragraph>
            </Modal>
          </div>
        </div>
      )}
    </>
  )
}

export default UsersPage
