import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { App, Button, Col, Form, Input, InputRef, Modal, Row, Spin, Typography } from 'antd'
import Icon, { GoogleOutlined, SlackOutlined, WindowsFilled, MailOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import qs from 'qs'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { ClientContext, useManualQuery } from 'graphql-hooks'
import { getCompanyAndUserInfo } from '../../graphql/custom-queries'

import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft'
import { SlackAuth } from '../../services/auth/slack/slack'
import { getUserId as getSlackUserId, updateSlackToken } from '../../services/api/slack'
import { getUserId as getMsUserId } from '../../services/api/microsoft'
import { getUserId as getGoogleUserId } from '../../services/api/google'
import { GoogleAuth } from '../../services/auth/google/google'
import { track } from '../../services/analytics/analytics'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setUserId } from '../../store/user-id-slice'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { selectAuthStateSlice, setAuthState } from '../../store/auth-state-slice'
import { SLACK_REQUIRED_BOT_SCOPES, SLACK_REQUIRED_USER_SCOPES } from '@vacationtracker/shared/data/slack'

import IntlMessages from '../../util/IntlMessages'
import CompanyAlreadySignedUpModal from '../../components/company-already-signed-up-modal'
import { SeoTags } from '../../components/seo-tags'
import VtLogo from '../../assets/images/logo-purple.svg'

import { IGetCompanyAndUserInfo } from '../../types/custom-queries'
import { ICheckUserId } from '@vacationtracker/shared/types/company'

import { ISignin } from './types'
import { FrontendUrls } from '../../types/urls'
import { FieldData } from 'rc-field-form/lib/interface'
import { Platform } from '@vacationtracker/shared/types/core-event'
import DontHaveAnAccountModal from '../../components/dont-have-an-account-modal'
import { setCrispSessionInfo } from '../../util/set-crisp-session-info'
import { CrispSegmentsEnum } from '../../types/crisp'
import { IContactAdminData } from '../../types/auth'
import CompanyExistsModal from '../../components/modal-company-exists'
import ForgotPasswordModal from '../../components/modal-forgot-password'
import { GoogleLogo } from '../../components/custom-svg-icons'
import MFACodeModal from '../../components/mfa-code-modal'
import { getCognitoErrors } from '../../components/mfa-code/errors'
import { ISlackOpenidTokenResponse } from '@vacationtracker/shared/types/repository/slack-api-repository'
import { confirmSigninWithCustomChallengeAnswer, getAuthTokens, IAuthNextStep, IAuthUser, signin } from '@vacationtracker/shared/services/auth'
import { AuthStateEnum } from '@vacationtracker/shared/types/auth-state'
import { getCompanyAndUser } from '../../services/api/companies'
import * as logger from '../../services/logger'

const { Paragraph, Title, Link } = Typography

if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID || !process.env.REACT_APP_SLACK_CLIENT_ID || !process.env.REACT_APP_GOOGLE_CLIENT_ID) {
  throw new Error('Client ID are required')
}

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)
const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID)

const Signin = ({ location }: ISignin): React.ReactElement => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const gqlClient = useContext(ClientContext)
  const { notification } = App.useApp()

  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authState } = useAppSelector(selectAuthStateSlice)
  const [redirectUrl, setRedirectUrl] = useState<string | FrontendUrls>(FrontendUrls.dashboard)
  const [platform, setPlatform] = useState<Platform | null>(null)
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(null)
  const [userEmail, setUserEmail] = useState<string | null>(null)
  const [userWorkspaceName, setUserWorkspaceName] = useState('')
  const [signinInProgress, setSigninInProgress] = useState(false)
  const [showSlackLoginRequiredModal, setShowSlackLoginRequiredModal] = useState(false)
  const [companyExists, setCompanyExists] = useState(false)
  const [contactAdminModal, setContactAdminModal] = useState<IContactAdminData | null>(null)
  const [createAccountModalVisible, showCreateAccountModal] = useState(false)
  const [existsOnAnotherPlatform, setExistsOnAnotherPlatform] = useState('')
  const [companyAlreadySignedUpShowModal, setCompanyAlreadySignedUpShowModal] = useState(false)
  const [username, setUsername] = useState<string | null>(null)
  const [forgotPasswordModalVisible, showForgotPasswordModal] = useState(false)
  const [authInfoAbortController, setAuthInfoAbortController] = useState<AbortController | null>(null)
  const [isCheckingEmail, setIsCheckingEmail] = useState(false)
  const [passwordRequired, setPasswordRequired] = useState(false)
  const [showMFAModal, setShowMFAModal] = useState<boolean>(false)
  const [slackTeamId, setSlackTeamId] = useState<string | null>(null)

  const [getCompanyAndUserInfoQuery] = useManualQuery<IGetCompanyAndUserInfo, { userId: string }>(getCompanyAndUserInfo)

  const emailInputRef = useRef<InputRef | null>(null)
  const passwordInputRef = useRef<InputRef | null>(null)

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    if (authState === AuthStateEnum.signedIn) {
      goToDashboard(redirectUrl)
    }
  }, [authState])

  // AUTOFOCUS EMAIL OR PASSWORD INPUT
  // This useEffect auto-focuses the email or password input field based on the selected platform,
  // and if a valid email is entered.
  //
  // IF email is not entered or `selectedPlatform` is not 'email' (which is set only when we know we have an email in the system),
  // we focus on the email input field.
  // ELSE IF `selectedPlatform` is 'email' (account exists), we focus on the password input field.
  //
  // TIMEOUT: We use a timeout to focus on the input field after a delay of 500ms to ensure that we do not focus the email field
  // and switch to password field immediately (after the data is loaded and the `selectedPlatform` is set).
  useEffect(() => {
    let timeout
    if (emailInputRef.current && (!form.getFieldsValue().email || selectedPlatform !== 'email')) {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        if (emailInputRef.current) {
          emailInputRef.current.focus()
        }
      }, 500)
    } else if (selectedPlatform === 'email' && passwordInputRef.current) {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        if (passwordInputRef.current) {
          passwordInputRef.current.focus()
        }
      }, 500)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [platform, selectedPlatform, emailInputRef.current?.input?.id, passwordInputRef.current?.input?.id])

  useEffect(() => {
    if (userEmail) {
      getUsersByEmail(userEmail)
      sessionStorage.setItem('vtUserEmail', userEmail)
    }
  }, [userEmail])

  useEffect(() => {
    if (queryParams?.redirect) {
      setRedirectUrl(queryParams.redirect as string)
    }
    if (queryParams?.platform) {
      setPlatform(queryParams.platform as Platform)
    }

    if (queryParams?.subscriptionPlan && (queryParams.subscriptionPlan === 'Core' || queryParams.subscriptionPlan === 'Complete')) {
      localStorage.setItem('vtSubscriptionPlan', queryParams.subscriptionPlan)
    }

    if (queryParams?.forgotpassword) {
      showForgotPasswordModal(true)
    }

    if (authUser.id && authState === AuthStateEnum.signedIn) {
      history.push(queryParams?.redirect as string || redirectUrl)
    } else {
      history.push(FrontendUrls.signin + location.search)

    }
  }, [authState, authUser])

  useEffect(() => {
    track('CONNECT_PAGE_VIEWED', {})
    document.body.classList.remove('flow-b-theme')
    localStorage.removeItem('vtCreateUser')
    localStorage.removeItem('vtCreateCompany')
    localStorage.removeItem('vtSelectedUsers')
    if (queryParams?.error === 'subscription_expired') {
      // Subscription expired
      notification.error({
        message: formatMessage({ id: 'connect.subscriptionExpiredTitle' }),
        description: formatMessage({ id: 'error.subscriptionExpired' }),
        duration: 0,
      })
    }
    setCrispSessionInfo(
      [CrispSegmentsEnum.chat, CrispSegmentsEnum.support, CrispSegmentsEnum.dashboard, CrispSegmentsEnum.onLoginPage],
      { loginScreen: ''}
    )
    const emailFromSessionStorage = sessionStorage.getItem('vtUserEmail')
    if (emailFromSessionStorage) {
      form.setFieldsValue({ email: emailFromSessionStorage })
      getUsersByEmail(emailFromSessionStorage)
    }
  }, [])

  const getUsersByEmail = async (email: string) => {
    setIsCheckingEmail(true)
    if (authInfoAbortController) {
      authInfoAbortController.abort()
    }
    try {
      await form.validateFields()
      const controller = new AbortController()
      setAuthInfoAbortController(controller)
      notification.destroy('USER_INACTIVE')
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/core/auth-info`, {
        email,
      }, {
        signal: controller.signal,
      })
      setUsername(res.data.username)
      const platform = res.data.platform
      setSelectedPlatform(platform)
      if (platform !== 'email') {
        setExistsOnAnotherPlatform(platform)
      }
      setAuthInfoAbortController(null)
      setIsCheckingEmail(false)
    } catch(err) {
      if (err.errorFields?.length === 0 && err.outOfDate === true) {
        setIsCheckingEmail(false)
        return
      }
      if (err?.response?.data?.code === 'EmailPlatformUserInactive') {
        notification.error({
          message: formatMessage({ id: 'error.signin.inactiveUser' }),
          key: 'USER_INACTIVE',
          description: formatMessage({ id: 'error.auth.companyExists.line1' }),
          duration: 0,
        })
        return
      }
      if (err.message !== 'canceled') {
        setIsCheckingEmail(false)
      }
      setUsername(null)
      setSelectedPlatform(null)
      setAuthInfoAbortController(null)
    }
  }

  const getSlackTokens = () => {
    setSigninInProgress(true)
    slackAuth.signin(
      SLACK_REQUIRED_BOT_SCOPES,
      SLACK_REQUIRED_USER_SCOPES,
      slackTeamId
    ).then(async (response) => {
      setUserWorkspaceName(response.team.name)
      
      let userData: ICheckUserId | undefined = undefined
      const slackUserToken = response.authed_user.access_token as string
      const botToken: string | undefined = response.access_token
      try {
        slackAuth.setBotToken(botToken)
        slackAuth.setUserToken(slackUserToken)
        if (authUser.id) {
          await updateSlackToken(slackUserToken, botToken)
        }
        setShowSlackLoginRequiredModal(false)
        let userId = localStorage.getItem('userId') as string
        if (!userId) {
          userData = await getSlackUserId(response.authed_user.id, slackUserToken)
          userId = userData.vacationTrackerId as string
        }
        dispatch(setUserId(userId))
        if (!authUser.id) {
          await authSigin(userId, slackUserToken, 'slack', botToken)
        } else {
          dispatch(setAuthState(AuthStateEnum.signedIn))
        }
        setSigninInProgress(false)
      } catch(err) {
        if (userData) {
          handleUserData(userData, platform, {
            teamName: response.team.name,
            slackBotToken: botToken,
            slackUserToken: slackUserToken,
            slackOrgTeamId: response.enterprise?.id,
            slackBotId: response.bot_user_id,
            isOwner: userData?.slackUser?.isOwner,
            isAdmin: userData?.slackUser?.isAdmin,
            slackTeamId: userData?.slackUser?.slackTeamId,
            imageUrl: userData?.slackUser?.imageUrl,
            slackUserId: userData?.slackUser?.slackUserId,
            timezone: userData?.slackUser?.timezone,
          })
          setSigninInProgress(false)
        }

        if (err?.code === 'NotAuthorizedException' && (userData?.contactEmail || userData?.adminContacts)) {
          setCompanyExists(true)
          setContactAdminModal({
            platform: 'slack',
            organizationName: userData?.organizationName || '',
            contactEmail: userData?.contactEmail || '',
            adminContacts: userData?.adminContacts || [],
          })
          track('ORG_ALREADY_EXISTS_PAGE_VIEWED', { platform, organizationName: userData?.organizationName })
          setSigninInProgress(false)
          return
        }
      }
    }).catch(error => {
      setSigninInProgress(false)
      notification.error({
        message: formatMessage({ id: 'error.notificationGeneral' }),
        description: `Slack error: ${error?.error}${error.needed ? ` ${error.needed}` : ''}`,
        duration: 0,
      })
    })
  }

  const authSigin = async (username: string, token: string, platform: string, botToken?: string) => {
    const signInResponse = await signin(username) as IAuthNextStep
    if (signInResponse.challengeName === 'CUSTOM_CHALLENGE' && signInResponse.challengeParam?.question === 'token') {
      const cognitoResponse = await confirmSigninWithCustomChallengeAnswer(token, { loginType: platform })
      localStorage.setItem('userId', cognitoResponse.username)

      try {
        const authToken = await getAuthTokens()
        if (gqlClient && authToken.idToken) {
          gqlClient.setHeader('Authorization', authToken.idToken)
        }
      } catch (error) {
        // Swallow the error
      }

      if (platform === 'slack') {
        try {
          await updateSlackToken(token, botToken)
        } catch (error) {
          if(error?.response?.data?.error && (error?.response.data.error === 'error.botTokenNotExist' || error?.response.data.error === 'error.botTokenNotValid')) {
            setSigninInProgress(false)
            setShowSlackLoginRequiredModal(true)
            return
          }
        }
      }
      dispatch(setUserId(cognitoResponse.username))
      await getCompanyAndUser(cognitoResponse.username, getCompanyAndUserInfoQuery, dispatch, history)
      track('LOGIN_COMPLETED', {
        platform,
        timestamp: new Date().toDateString(),
        source: 'app',
        status: 'completed',
      })
      dispatch(setAuthState(AuthStateEnum.signedIn))
    }
  }

  const goToSignup = () => {
    setSigninInProgress(false)
    dispatch(setAuthState(AuthStateEnum.signUp))
    history.push(FrontendUrls.signup)
  }

  const goToDashboard = (redirectUrl: string | FrontendUrls) => {
    setSigninInProgress(false)
    history.push(redirectUrl)
  }

  const handleUserData = (userData: ICheckUserId, platform, otherCreateUserData) => {
    setUserEmail(userData.mail || '')
    setSelectedPlatform(platform)
    if(userData.type === 'USER_NOT_FOUND' && userData.subscriptionStatus === 'canceled') {
      notification.error({
        message: formatMessage({ id: 'connect.subscriptionExpiredTitle' }),
        description: formatMessage({ id: 'error.subscriptionExpired' }),
        duration: 0,
      })
      return
    }

    if(userData.type === 'USER_NOT_FOUND') {
      setContactAdminModal({
        platform,
        organizationName: userData.organizationName || '',
        contactEmail: userData.contactEmail || '',
        adminContacts: userData.adminContacts || [],
      })
      track('ORG_ALREADY_EXISTS_PAGE_VIEWED', { platform, organizationName: userData.organizationName })
      setCompanyExists(true)
      setSigninInProgress(false)
      return
    }

    if (userData.type === 'COMPANY_NOT_FOUND') {
      localStorage.setItem('vtCreateUser', JSON.stringify({
        name: userData.name,
        id: `${platform}-${userData.id}`,
        mail: userData.mail,
        platform,
        ...otherCreateUserData,
      }))

      if (userData.existsOnAnotherPlatform && userData.existingOrganizationName) {
        track('ORG_EXISTS_ON_ANOTHER_PLATFORM_OPEN_MODAL', { platform, existingOrganizationName: userData.existingOrganizationName })
        setExistsOnAnotherPlatform(userData.existsOnAnotherPlatform)
        setCompanyAlreadySignedUpShowModal(true)
        return
      }
      showCreateAccountModal(true)
    }
  }

  const handleSigninError = (error, platform) => {
    setSigninInProgress(false)
    const errorMessage = (typeof error === 'object' && !(error instanceof Error)) ? (
      error.error ? error.error : JSON.stringify(error, null, 2)
    ) : (
      typeof error === 'string' ? error : error.toString()
    )
    let platformText
    if (platform === 'microsoft') {
      track('CONNECT_WITH_MICROSOFT_ERROR', { errorMessage, platform })
      platformText = 'Microsoft Teams'
    } else if (platform === 'google') {
      track('CONNECT_WITH_GOOGLE_ERROR', { errorMessage, platform })
      platformText = 'Google'
    } else if (platform === 'slack') {
      track('CONNECT_WITH_SLACK_ERROR', { errorMessage, platform })
      platformText = 'Slack'
    }

    // This can turn out to be a switch statement if we encounter more error cases
    // that require different actions/descriptions
    if (errorMessage.includes('interaction_required') || errorMessage.includes('invalid_grant')) {
      notification.error({
        message: formatMessage({ id: 'connect.interactionRequiredMSErrorTitle' }),
        description: <div>
          <Paragraph>{ formatMessage({ id: 'connect.interactionRequiredMSErrorDescription1' }) }</Paragraph>
          <Paragraph>{ formatMessage({ id: 'connect.interactionRequiredMSErrorDescription2' }) }</Paragraph>
          <Paragraph keyboard copyable>{ errorMessage }</Paragraph>
        </div>,
        duration: 0,
        btn: <Button onClick={() => connectWithMicrosoft(true)}>{ formatMessage({ id: 'connect.login' }) }</Button>,
      })
    } else {
      notification.error({
        message: formatMessage({ id: 'connect.platformErrorTitle' }),
        description: <div>
          <Paragraph>{ formatMessage({ id: 'connect.platformErrorDescription1' }) }</Paragraph>
          <Paragraph keyboard copyable>{ errorMessage }</Paragraph>
          <Paragraph>{ formatMessage({ id: 'connect.platformErrorDescription2' }, { platform: platformText }) }</Paragraph>
        </div>,
        duration: 0,
      })
      // Do not track Slack "access_denied" in Sentry, as it's handled above
      if (!errorMessage.includes('access_denied')) {
        Sentry.captureException(error)
      }
    }
  }

  const connectWithSlack = () => {
    const platform = 'slack'
    setSigninInProgress(true)
    track('CONNECT_WITH_SLACK_BUTTON_CLICKED', { platform })
    track('LOGIN_STARTED', {
      platform,
      timestamp: new Date().toISOString(),
      source: 'app',
      status: 'started',
    })
    slackAuth.connect()
      .then(async (response: ISlackOpenidTokenResponse) => {
        const slackUserToken: string | undefined = response.authed_user.access_token
        if (!slackUserToken) {
          throw new Error('Slack user token is required')
        }
        setSlackTeamId(response.team.id)
        slackAuth.setUserToken(slackUserToken)
        let userData: ICheckUserId | undefined = undefined
        try {
          userData = await getSlackUserId(response.authed_user.id, slackUserToken)
          if (userData.vacationTrackerId) {
            localStorage.setItem('userId', userData.vacationTrackerId)
            let botToken: string | undefined
            try {
              botToken = slackAuth.getBotToken()
            } catch (error) {
              // Bot token is not available, but we can still proceed
            }
            const areScopesValid = await slackAuth.areScopesValid({
              userToken: slackUserToken,
              requiredUserScopes: SLACK_REQUIRED_USER_SCOPES,
              botToken: botToken,
              requiredBotScopes: SLACK_REQUIRED_BOT_SCOPES,
            })
            if (!areScopesValid) {
              throw 'missing_scope'
            }
            await authSigin(userData.vacationTrackerId, slackUserToken, platform, botToken)
          } else {
            throw 'Handle user data'
          }
        } catch (error) {
          if (error === 'missing_scope' || error?.response?.data === 'missing_scope') {
            setShowSlackLoginRequiredModal(true)
            setSigninInProgress(false)
            return
          }

          // TODO: not sure how to handle this at the moment
          if (userData) {
            handleUserData(userData, platform, {
              teamName: null,
              slackBotToken: null,
              slackUserToken: slackUserToken,
              slackOrgTeamId: response.enterprise?.id,
              slackBotId: null,
              isOwner: userData?.slackUser?.isOwner,
              isAdmin: userData?.slackUser?.isAdmin,
              slackTeamId: userData?.slackUser?.slackTeamId,
              imageUrl: userData?.slackUser?.imageUrl,
              slackUserId: userData?.slackUser?.slackUserId,
              timezone: userData?.slackUser?.timezone,
            })
          }

          if (error?.code === 'NotAuthorizedException' && (userData?.contactEmail || userData?.adminContacts)) {
            setContactAdminModal({
              platform,
              organizationName: userData?.organizationName || '',
              contactEmail: userData?.contactEmail || '',
              adminContacts: userData?.adminContacts || [],
            })
            track('ORG_ALREADY_EXISTS_PAGE_VIEWED', { platform, organizationName: userData?.organizationName })
            setCompanyExists(true)
            setSigninInProgress(false)
            return
          }
        }
      })
      .catch(e => handleSigninError(e, 'slack'))
  }

  const connectWithMicrosoft = (interactionRequired = false) => {
    const platform = 'microsoft'
    setSigninInProgress(true)
    track('CONNECT_WITH_MICROSOFT_BUTTON_CLICKED', { platform })
    track('LOGIN_STARTED', {
      platform,
      timestamp: new Date().toISOString(),
      source: 'app',
      status: 'started',
    })
    // This requires promises, not async/await because window.open requires sync not async flow
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all'], interactionRequired)
      .then(async ([tokens, msUser, tenantId]) => {
        const userData: ICheckUserId = await getMsUserId(tenantId, tokens.accessToken)
        logger.info('userData', userData)
        try {
          await authSigin(userData.id as string, tokens.accessToken, platform)
        } catch (error) {
          msAuth.setTokens(tokens)
          handleUserData(userData, platform, { tenantId, msUserId: userData.id })
        }
      })
      .catch(e => handleSigninError(e, 'microsoft'))
  }

  const connectWithGoogle = () => {
    const platform = 'google'
    track('LOGIN_PAGE_LOGIN_WITH_GOOGLE_BUTTON_CLICKED', { platform })
    track('LOGIN_STARTED', {
      platform,
      timestamp: new Date().toISOString(),
      source: 'app',
      status: 'started',
    })
    setSigninInProgress(true)
    googleAuth.signin()
      .then(async () => {
        const googleUser = googleAuth.getSignedInUser()
        if (!googleUser.hd) {
          notification.error({
            message: formatMessage({ id: 'connect.google.notWorkspaceUserTitle' }),
            description: formatMessage({ id: 'connect.google.notWorkspaceUserDescription' }),
            duration: 0,
          })
          return
        }
        const userData: ICheckUserId = await getGoogleUserId(googleAuth.getIdToken())
        logger.info('userData', userData)
        try {
          await authSigin(userData.vacationTrackerId as string, googleAuth.getAccessToken() , platform)
        } catch (error) {
          handleUserData(userData, platform, {
            googleDomain: googleUser.hd,
            imageUrl: googleUser.picture,
            googleUserId: googleUser.sub,
          })
        }
      })
      .catch(e => handleSigninError(e, 'google'))
  }

  const connectWithEmail = () => {
    track('LOGIN_STARTED', {
      platform: 'email',
      timestamp: new Date().toISOString(),
      source: 'app',
      status: 'started',
    })
    history.push(FrontendUrls.signin + '?platform=email')
    setPlatform('email')
  }

  const backToLogin = () => {
    history.push(FrontendUrls.signin)
    setPlatform(null)
  }

  const onEnterMFACode = async (code: string) => {
    try {
      const cognitoResponse = await confirmSigninWithCustomChallengeAnswer(code)

      localStorage.setItem('userId', cognitoResponse.username)
      dispatch(setUserId(cognitoResponse.username))
      await getCompanyAndUser(cognitoResponse.username, getCompanyAndUserInfoQuery, dispatch, history)
      track('LOGIN_COMPLETED', {
        platform,
        timestamp: new Date().toDateString(),
        source: 'app',
        status: 'completed',
      })
      dispatch(setAuthState(AuthStateEnum.signedIn))
    } catch (e) {
      notification.error({ 
        message: formatMessage({ id: 'app.mfaLoginFailed' }),
        description: formatMessage({ id: getCognitoErrors(e.code as string) }),
        duration: 10,
      })
      setShowMFAModal(false)
      Sentry.captureException(e)
    }
  }

  const connectWithMail = async () => {
    track('LOGIN_PAGE_LOGIN_WITH_EMAIL_AND_PASSWORD_BUTTON_CLICKED', { platform })

    if (!selectedPlatform) {
      showCreateAccountModal(true)
      return
    }

    if (selectedPlatform && selectedPlatform !== 'email') {
      track('ORG_EXISTS_ON_ANOTHER_PLATFORM_CLOSE_MODAL', {
        platform: selectedPlatform,
      })
      setCompanyAlreadySignedUpShowModal(true)
      return
    }

    setSigninInProgress(true)
    try {
      const values = await form.validateFields()
      const cognitoResponse = await signin(username || '', values.password)
      if (['SMS_MFA', 'SOFTWARE_TOKEN_MFA'].includes((cognitoResponse as IAuthNextStep).challengeName as string)) {
        setShowMFAModal(true)
        return
      } else if ((cognitoResponse as IAuthNextStep).challengeName === 'NEW_PASSWORD_REQUIRED') {
        // Ask for the new password, then do this:
        // const loggedUser = await Auth.completeNewPassword(
        //   cognitoResponse, // the Cognito User Object
        //   newPassword, // the new password
        // )
      } else if ((cognitoResponse as IAuthNextStep).challengeName === 'MFA_SETUP') {
        // This happens when the MFA method is TOTP
        // The user needs to setup the TOTP before using it
        // More info please check the Enabling MFA part
        // Auth.setupTOTP(cognitoResponse)
      }
      localStorage.setItem('userId', (cognitoResponse as IAuthUser).username)

      dispatch(setUserId((cognitoResponse as IAuthUser).username))
      await getCompanyAndUser((cognitoResponse as IAuthUser).username, getCompanyAndUserInfoQuery, dispatch, history)
      track('LOGIN_COMPLETED', {
        platform,
        timestamp: new Date().toDateString(),
        source: 'app',
        status: 'completed',
      })
      dispatch(setAuthState(AuthStateEnum.signedIn))
    } catch(err) {
      setSigninInProgress(false)
      if (err.name === 'NotAuthorizedException') {
        notification.error({
          message: formatMessage({ id: 'error.incorrectPassword' }),
          key: 'INCORRECT_PASSWORD',
          description: formatMessage({ id: 'error.incorrectPasswordMessage' }),
          btn: (<Button onClick={() => {
            showForgotPasswordModal(true)
            notification.destroy('INCORRECT_PASSWORD')
          }}>{ formatMessage({ id: 'app.resetPassword' }) }</Button>),
          duration: 0,
        })
        return
      } else if (err.name === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.name === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.name === 'UserNotFoundException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      }
      Sentry.captureException(err)
    }
  }

  const connectWithPlatform = (platform: string) => {
    track('ORG_EXISTS_ON_ANOTHER_PLATFORM_CHOOSE_EXISTING', { platform })
    localStorage.removeItem('createUser')
    setCompanyAlreadySignedUpShowModal(true)
    if (platform === 'slack') {
      connectWithSlack()
    } else if (platform === 'microsoft') {
      connectWithMicrosoft()
    } else if (platform === 'google') {
      connectWithGoogle()
    }
  }

  // This happens when the validation is not finished and the form is submitted
  // Or in case there are errors in the form
  const onFormFinishFailed = async ({ errorFields, outOfDate }) => {
    try {
      // If there are no errors, but the form is out of date, we need to validate the fields
      // and resubmit the form (to avoid the double-click)
      if (errorFields.length === 0 && outOfDate === true) {
        await form.validateFields()
        form.submit()
      }
      // Otherwise, we need to show the error message (we'll do that in the catch block)
      throw new Error('Validation failed')
    } catch (err) {
      // If the validation fails, we need to show the error message
      // and focus the first field with an error
      if (err.errorFields && err.errorFields.length > 0) {
        const firstError = err.errorFields[0]
        const errorField = firstError.name[0]
        const field = form.getFieldInstance(errorField)
        if (field) {
          field.focus()
        }
      }
    }
  }

  const onFormFieldsChange = (changedFields: FieldData[]) => {
    const emailField = changedFields && changedFields.find(field => Array.isArray(field.name) && field.name.includes('email'))
    if (!emailField?.errors || emailField.errors.length === 0) {
      setUserEmail(emailField?.value as string)
    }
    const password = changedFields && changedFields.find(field => Array.isArray(field.name) && field.name.includes('password'))
    setPasswordRequired(changedFields.length === 0 || password?.value === '')
  }

  return (
    <div className="auth-wrap">
      <SeoTags
        title='connect.meta.title.signin'
        description='connect.meta.description'
      />
      <div className="auth-container">
        <Row align="middle" justify="space-around" gutter={[{ xs: 16, lg: 48},{ xs: 16, lg: 48}]}>
          <Col span={24}>
            <div className="auth-sidebar-logo">
              <VtLogo />
            </div>
          </Col>
          { (platform && platform !== 'email') &&
            <Col span={16}>
              <Title level={4} style={{ textAlign: 'center' }} >
                <IntlMessages id='connect.pleaseLogIn' />
              </Title>
              { platform === 'slack' &&
                <Button icon={<SlackOutlined />} size="large" className="vt-auth" type="primary" block onClick={() => connectWithSlack()}>
                  <IntlMessages id="connect.signInWithSlack" />
                </Button>
              }
              {platform === 'microsoft' &&
                <Button icon={<WindowsFilled />} size="large" className="vt-auth" type="primary" block onClick={() => connectWithMicrosoft()}>
                  <IntlMessages id="connect.signInWithMS" />
                </Button>
              }
              {platform === 'google' && process.env.REACT_APP_GOOGLE_VERIFICATION !== 'true' &&
                <Button icon={<GoogleOutlined />} size="large" className="vt-auth" type="primary" block onClick={() => connectWithGoogle()}>
                  <IntlMessages id="connect.signInWithGoogle" />
                </Button>
              }
              {platform === 'google' && process.env.REACT_APP_GOOGLE_VERIFICATION === 'true' &&
                <Button
                  icon={<Icon component={GoogleLogo} />}
                  size="large"
                  type="primary"
                  block
                  onClick={() => connectWithGoogle()}
                  className='google-btn vt-auth'
                >
                  <IntlMessages id="connect.signInWithGoogleVerification" />
                </Button>
              }
              {(!platform) &&
                <Button icon={<MailOutlined />} size='large' className="vt-auth" type="primary" block onClick={() => connectWithEmail()}>
                  <IntlMessages id="connect.signUpWithEmail" />
                </Button>
              }
            </Col>
          }
          {!platform &&
            <>
              <Col xs={{ span: 20 }} lg={{ span: 12, order: 2 }} >
                <Title level={4} style={{ textAlign: 'center' }} >
                  <IntlMessages id='connect.pleaseLogIn' />
                </Title>
                <Spin spinning={signinInProgress}>
                  <div className="auth-sidebar-content-button">
                    <Button icon={<SlackOutlined />} size="large" data-qa="slack-connect" className="vt-auth" type="primary" block onClick={() => connectWithSlack()}>
                      <IntlMessages id="connect.signInWithSlack" />
                    </Button>
                    <Button icon={<WindowsFilled />} size="large" data-qa="microsoft-connect" className="vt-auth" type="primary" block onClick={() => connectWithMicrosoft()}>
                      <IntlMessages id="connect.signInWithMS" />
                    </Button>
                    {process.env.REACT_APP_GOOGLE_VERIFICATION === 'true' ? <Button
                      icon={<Icon component={GoogleLogo} />}
                      size="large"
                      type="primary"
                      block
                      onClick={() => connectWithGoogle()}
                      className="google-btn vt-auth"
                    >
                      <IntlMessages id="connect.signInWithGoogleVerification" />
                    </Button> : <Button
                      icon={<GoogleOutlined />}
                      size='large'
                      data-qa='google-connect'
                      type="primary"
                      className="vt-auth"
                      block
                      onClick={() => connectWithGoogle()}
                    >
                      <IntlMessages id="connect.signInWithGoogle" />
                    </Button>
                    }
                    <Button icon={<MailOutlined />} size='large' data-qa="email-connect" className="vt-auth" type="primary" block onClick={() => connectWithEmail()}>
                      <IntlMessages id="connect.signInWithEmail" />
                    </Button>
                  </div>
                </Spin>
              </Col>
              <Col xs={{ span: 20 }} lg={{ span: 12, order: 1 }}>
                <Title level={2} className="create-account-title">
                  <IntlMessages id='connect.dontHaveAnAccount' />
                </Title>
                <Button size="large" className="create-account-btn" type="primary" onClick={() => history.push(FrontendUrls.signup)}>
                  <IntlMessages id="connect.createAnOrganization" />
                </Button>
              </Col>
            </>
          }
          {platform && platform === 'email' &&
            <Col xs={{ span: 20 }} lg={{ span: 12, order: 2 }} >
              <Paragraph>
                <Title style={{ textAlign: 'center', fontWeight: 400 }} level={5}><IntlMessages id="connect.connectWithYourWorkEmail" /></Title>
                <Form
                  name="connect-with-email"
                  form={form}
                  layout="horizontal"
                  onFinish={connectWithMail}
                  onFinishFailed={onFormFinishFailed}
                  onFieldsChange={onFormFieldsChange}
                >
                  <Form.Item
                    name="email"
                    style={{ width: '100%' }}
                    rules={[{ required: true, type: 'email', message: (<IntlMessages id="connect.pleaseInputValidEmail" />) }]}
                  >
                    <Input
                      className="email-input data-hj-allow"
                      size="large"
                      data-qa='email-login-mail'
                      placeholder={formatMessage({ id: 'app.enterWorkEmail' })}
                      ref={emailInputRef}
                    />
                  </Form.Item>
                  { selectedPlatform === 'email' &&
                    <Form.Item
                      name="password"
                      style={{ width: '100%' }}
                      rules={[{ required: true }]}
                    >
                      <Input.Password
                        size="large"
                        data-qa='email-login-password'
                        ref={passwordInputRef}
                        placeholder={formatMessage({ id: 'connect.inputPasswordPlaceholder' })}
                        autoComplete='current-password'
                      />
                    </Form.Item>
                  }
                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      disabled={isCheckingEmail || passwordRequired || signinInProgress}
                      data-qa='email-login-submit'
                      block
                      loading={signinInProgress}
                      style={{ wordWrap: 'break-word', whiteSpace: 'normal', height:'auto'}}
                    >
                      <IntlMessages id="connect.connectWithYourWorkEmail" />
                    </Button>
                  </Form.Item>
                </Form>
                <Link onClick={() => showForgotPasswordModal(true)}>
                  <IntlMessages id="connect.forgotYourPassword" />
                </Link>
                <br/>
                <br/>
                <Link onClick={() => backToLogin()}>
                  <IntlMessages id="connect.backToLoginWithOtherPlatofrm" />
                </Link>
              </Paragraph>
            </Col>
          }
        </Row>
      </div>

      {companyExists && contactAdminModal && userEmail &&
        <CompanyExistsModal
          companyExists={companyExists}
          setCompanyExists={setCompanyExists}
          contactAdminModal={contactAdminModal}
          userEmail={userEmail}
          userWorkspaceName={userWorkspaceName}
        />
      }

      { showSlackLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'error.slack.usersLoginRequiredTitle' })}
          open={showSlackLoginRequiredModal}
          onOk={getSlackTokens}
          cancelText={formatMessage({ id: 'app.cancel'})}
          okText={formatMessage({ id: 'connect.slack.usersLoginRequiredButton'})}
          onCancel={() => {
            dispatch(setUserId(localStorage.getItem('userId') as string))
            setShowSlackLoginRequiredModal(false)
          }}
        >
          <p><IntlMessages id="connect.slack.usersLoginRequiredDescription1" /></p>
        </Modal>
      }

      { companyAlreadySignedUpShowModal &&
        <CompanyAlreadySignedUpModal
          showModal={companyAlreadySignedUpShowModal}
          existsOnAnotherPlatform={existsOnAnotherPlatform}
          connectWithPlatform={connectWithPlatform}
          handleCloseCompanyAlreadySignedUpModal={() => setCompanyAlreadySignedUpShowModal(false)}
        />
      }

      { createAccountModalVisible &&
        <DontHaveAnAccountModal
          showCreateAccountModal={showCreateAccountModal}
          createAccountModalVisible={createAccountModalVisible}
          goToSignup={goToSignup}
        />
      }

      { forgotPasswordModalVisible &&
        <ForgotPasswordModal
          forgotPasswordModalVisible={forgotPasswordModalVisible}
          showForgotPasswordModal={showForgotPasswordModal}
        />
      }

      { showMFAModal && <MFACodeModal onSave={onEnterMFACode} />}
    </div>
  )
}

export default Signin