import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft'
import { IMicrosoftUser } from '../../types/microsoft'
import { ISlackUser } from '../../types/slack'
import { IGoogleUser } from '../../types/google'
import { SlackAuth } from '../../services/auth/slack/slack'
import { GoogleAuth } from '../../services/auth/google/google'
import * as logger from '../../services/logger'

export type PlatformUser = IMicrosoftUser | ISlackUser | IGoogleUser
export type Platform = 'microsoft' | 'slack' | 'google'

export interface IUserMapping {
  id: string
  displayName?: string
  mail?: string
  isOwner?: boolean
  isAdmin?: boolean
  initialUser?: boolean
}

export interface LocationState {
  tenantId: string
  organizationId: string
  googleDomain: string
}

export type UserMappings = Record<string, IUserMapping | null>

interface StorageWithTTL {
  value: UserMappings
  timestamp: number
  ttl: number
}

interface PlatformTokens {
  msTokenAccessToken?: string
  slackUserToken?: string
  slackBotToken?: string
  googleAccessToken?: string
  googleRefreshToken?: string
}

const STORAGE_TTL = 24 * 60 * 60 * 1000

export const saveUserMappingsToStorage = (mappings: UserMappings): void => {
  const storageObject: StorageWithTTL = {
    value: mappings,
    timestamp: new Date().getTime(),
    ttl: STORAGE_TTL,
  }
  localStorage.setItem('userMappings', JSON.stringify(storageObject))
}

export const loadUserMappingsFromStorage = (): UserMappings => {
  const savedData = localStorage.getItem('userMappings')
  if (!savedData) return {}

  try {
    const storageObject: StorageWithTTL = JSON.parse(savedData)
    const now = new Date().getTime()

    if (now - storageObject.timestamp > storageObject.ttl) {
      localStorage.removeItem('userMappings')
      return {}
    }

    return storageObject.value
  } catch (error) {
    localStorage.removeItem('userMappings')
    return {}
  }
}

export const normalizeFullName = (name: string): string => 
  name.toLowerCase().replace(/\s+/g, '').trim()

export const isMicrosoftUser = (user: PlatformUser): user is IMicrosoftUser => {
  return 'userPrincipalName' in user
}

export const isSlackUser = (user: PlatformUser): user is ISlackUser => {
  return 'profile' in user
}

export const isGoogleUser = (user: PlatformUser): user is IGoogleUser => {
  return 'email' in user && 'name' in user && !('userPrincipalName' in user) && !('profile' in user)
}

export const getTokensForPlatform = (platform: Platform): PlatformTokens => {
  switch (platform) {
    case 'microsoft':
      return {
        msTokenAccessToken: sessionStorage.getItem('msTokensAccessToken') as string,
      }
    case 'slack':
      return {
        slackUserToken: localStorage.getItem('slackTokensUserToken') as string,
        slackBotToken: localStorage.getItem('slackTokensBotToken') as string,
      }
    case 'google':
      return {
        googleAccessToken: sessionStorage.getItem('googleAccessToken') as string,
        googleRefreshToken: sessionStorage.getItem('googleRefreshToken') as string,
      }
    default:
      throw new Error(`Unsupported platform: ${platform}`)
  }
}

export const getOrganizationId = (platform: Platform, state: LocationState): string => {
  console.log('state', state)
  switch (platform) {
    case 'microsoft':
      return state.tenantId
    case 'slack':
      return state.organizationId
    case 'google':
      return state.googleDomain
    default:
      throw new Error(`Unsupported platform: ${platform}`)
  }
}

type FetchUsersCallback<T> = (users: T[]) => void
type ErrorCallback = (error: Error) => void

export const fetchMicrosoftUsers = async (
  msAuth: MicrosoftAuth,
  token: string,
  onSuccess: FetchUsersCallback<IMicrosoftUser>,
  onError: ErrorCallback
): Promise<void> => {
  try {
    const users = await msAuth.getUsers(token)
    onSuccess(users)
  } catch (error: unknown) {
    logger.error('Error object:', error instanceof Error ? error.message : String(error))

    let errorCode = ''
    let errorMessage = ''

    if (error && typeof error === 'object' && 'response' in error && error.response && 
        typeof error.response === 'object' && 'data' in error.response) {
      const errorData = error.response.data as { error?: { code: string; message: string } }
      errorCode = errorData.error?.code || 'UnknownError'
      errorMessage = errorData.error?.message || 'An unknown error occurred'
    } else if (error instanceof Error) {
      errorCode = 'UnknownError'
      errorMessage = error.message
    } else {
      errorCode = 'UnknownError'
      errorMessage = 'An unknown error occurred'
    }

    logger.error('Error code:', errorCode)
    logger.error('Error message:', errorMessage)

    if (errorCode === 'InvalidAuthenticationToken') {
      logger.info('Token expired, attempting to sign in again')
      try {
        await msAuth.signin(['user.readbasic.all', 'team.readbasic.all'], true)
        logger.info('Successfully signed in again')
        await fetchMicrosoftUsers(msAuth, token, onSuccess, onError)
      } catch (signinError) {
        logger.error('Error signing in:', signinError)
        onError(signinError instanceof Error ? signinError : new Error('Unknown signin error'))
      }
    } else {
      logger.error(`Unexpected error: ${errorCode} - ${errorMessage}`)
      onError(new Error(`${errorCode} - ${errorMessage}`))
    }
  }
}

export const fetchSlackUsers = async (
  slackAuth: SlackAuth,
  token: string,
  onSuccess: FetchUsersCallback<ISlackUser>,
  onError: ErrorCallback
): Promise<void> => {
  try {
    slackAuth.setUserToken(token)
    const users = await slackAuth.getUsersList()
    const filteredUsers = users.filter(user => 
      user && 
      user.id !== 'USLACKBOT' && 
      !user.is_bot && 
      !user.deleted && 
      !user.is_ultra_restricted && 
      (user.profile.real_name || user.name)
    )
    onSuccess(filteredUsers)
  } catch (error) {
    logger.error('Failed to fetch Slack users:', error)
    onError(error instanceof Error ? error : new Error('Failed to fetch Slack users'))
  }
}

export const fetchGoogleUsers = async (
  googleAuth: GoogleAuth,
  token: string,
  onSuccess: FetchUsersCallback<IGoogleUser>,
  onError: ErrorCallback
): Promise<void> => {
  try {
    googleAuth.setAccessToken(token)
    const users = await googleAuth.getUserListForImportFull()
    const filteredUsers = users.filter(user => user && user.email && user.name)
    onSuccess(filteredUsers)
  } catch (error) {
    logger.error('Failed to fetch Google users:', error)
    onError(error instanceof Error ? error : new Error('Failed to fetch Google users'))
  }
}
