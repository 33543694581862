import { LoadingOutlined } from '@ant-design/icons'
import Api from '@vacationtracker/shared/services/api'
import { signout } from '@vacationtracker/shared/services/auth'
import { Alert, App, Button, Col, Input, Modal, Radio, Row, Select, Space, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { RadioChangeEvent } from 'antd/lib/radio'
import Paragraph from 'antd/lib/typography/Paragraph'
import { useManualQuery } from 'graphql-hooks'
import { capitalize } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Redirect, useHistory, useLocation } from 'react-router'
import CircularProgress from '../../components/circular-progress'
import { notificationStore } from '../../context/notificationsContext/store'
import { getAllUserListForMigrationPaginated } from '../../graphql/custom-queries'
import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft'
import { SlackAuth } from '../../services/auth/slack/slack'
import { GoogleAuth } from '../../services/auth/google/google'
import * as logger from '../../services/logger'
import { logoutAction } from '../../store/configure'
import { useAppDispatch } from '../../store/hooks'
import { FrontendUrls } from '../../types/urls'
import { IGetAllUserListForMigrationPaginatedData, UserStatus } from '../../types/users'
import IntlMessages from '../../util/IntlMessages'
import { ICompanyPlatformChangedEventBody } from './types'
import { 
  fetchMicrosoftUsers,
  fetchSlackUsers,
  fetchGoogleUsers,
  getTokensForPlatform, 
  isGoogleUser, 
  isMicrosoftUser, 
  isSlackUser, 
  loadUserMappingsFromStorage,
  normalizeFullName,
  saveUserMappingsToStorage,
  Platform,
  PlatformUser,
  UserMappings,
  IUserMapping,
  LocationState,
  getOrganizationId
} from './util'

const { Title, Text } = Typography

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID as string)
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)
const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID as string)

interface IGetUserListForMigration {
  id: string
  name: string
  status: UserStatus
  email: string
  isAdmin: boolean
  initialUser: boolean
}

const MigrationPage: React.FC = () => {
  const { state } = useLocation<LocationState>()
  const history = useHistory()
  const reduxDispatch = useAppDispatch()
  const { modal, notification } = App.useApp()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const [correlationId, setCorrelationId] = useState<string | null>()
  const { formatMessage } = useIntl()
  const [hideInactive, setHideInactive] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [markUnmappedAsDeleted, setMarkUnmappedAsDeleted] = useState(false)
  const [companyUsers, setCompanyUsers] = useState<IGetUserListForMigration[]>([])
  const [companyUsersLoading, setCompanyUsersLoading] = useState(false)
  const [platformUsers, setPlatformUsers] = useState<PlatformUser[]>([])
  const [userMappings, setUserMappings] = useState<UserMappings>(() => loadUserMappingsFromStorage())
  const [searchPlatformUsersText, setSearchPlatformUsersText] = useState('')
  const [, setActiveSelectId] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [unmappedUsers, setUnmappedUsers] = useState(0)
  const [getAllUserListForMigrationPaginatedQuery] = useManualQuery<IGetAllUserListForMigrationPaginatedData, {
    limit: number
    nextToken?: string
  }>(getAllUserListForMigrationPaginated)

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false)
  const [countdown, setCountdown] = useState(15)

  useEffect(() => {
    if (correlationId && !actionNotifications.includes(correlationId) && isSaving) {
      setIsSaving(false)
      setCorrelationId(null)
      setIsLogoutModalVisible(true)
    }
  }, [actionNotifications])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isLogoutModalVisible && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000)
    } else if (countdown === 0) {
      handleLogout()
    }
    return () => clearTimeout(timer)
  }, [isLogoutModalVisible, countdown])

  const handleLogout = async () => {
    await signout(true)
    reduxDispatch(logoutAction)
    history.push(FrontendUrls.signin)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        await Promise.all([
          fetchAllUsersInSystem(),
          fetchUsersFromNewPlatform(),
        ])
      } catch (error) {
        logger.error('Error fetching data:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    console.log('userMappings', userMappings)
    setUnmappedUsers(Object.values(userMappings).filter(value => value === null).length)
  }, [userMappings])

  useEffect(() => {
    if (platformUsers.length > 0 && companyUsers.length > 0) {
      const newMappings: Record<
      string,
      {
        id: string
        displayName?: string
        mail?: string
        isOwner?: boolean
        isAdmin?: boolean
        initialUser?: boolean
      } | null
      > = {}
      companyUsers.forEach(companyUser => {
        const matchedUser = platformUsers.find(user => {
          if (isMicrosoftUser(user)) {
            return user.mail?.toLowerCase() === companyUser.email.toLowerCase() ||
              user.userPrincipalName?.toLowerCase() === companyUser.email.toLowerCase() ||
              normalizeFullName(user.displayName || '') === normalizeFullName(companyUser.name || '')
          } else if (isSlackUser(user)) {
            return user.profile.email?.toLowerCase() === companyUser.email.toLowerCase() ||
              normalizeFullName(user.profile.real_name_normalized || '') === normalizeFullName(companyUser.name || '')
          } else if (isGoogleUser(user)) {
            return user.email.toLowerCase() === companyUser.email.toLowerCase() ||
              normalizeFullName(user.name || '') === normalizeFullName(companyUser.name || '')
          }
          return false
        })
        if (matchedUser) {
          newMappings[companyUser.id] = {
            id: matchedUser.id,
            displayName: isMicrosoftUser(matchedUser) 
              ? matchedUser.displayName 
              : isSlackUser(matchedUser)
                ? matchedUser.profile.real_name_normalized
                : matchedUser.name,
            mail: isMicrosoftUser(matchedUser) 
              ? matchedUser.mail 
              : isSlackUser(matchedUser)
                ? matchedUser.profile.email
                : matchedUser.email,
            isOwner: isSlackUser(matchedUser) ? matchedUser.is_primary_owner : undefined,
            isAdmin: isMicrosoftUser(matchedUser) || isGoogleUser(matchedUser) ? companyUser.isAdmin : undefined,
            initialUser: isMicrosoftUser(matchedUser) || isGoogleUser(matchedUser) ? companyUser.initialUser : undefined,
          }
        } else {
          newMappings[companyUser.id] = null
        }
      })

      setUserMappings(prevMappings => {
        return {
          ...newMappings,
          ...prevMappings,
        }
      })
      setUnmappedUsers(Object.values({
        ...newMappings,
        ...userMappings,
      }).filter(value => value === null).length)
    }
  }, [platformUsers, companyUsers])

  useEffect(() => {
    saveUserMappingsToStorage(userMappings)
  }, [userMappings])

  const filteredPlatformUsers = useMemo(() => {
    return platformUsers.filter(user => {
      if (isMicrosoftUser(user)) {
        return user.displayName?.toLowerCase().includes(searchPlatformUsersText.toLowerCase()) ||
          user.mail?.toLowerCase().includes(searchPlatformUsersText.toLowerCase()) ||
          user.userPrincipalName?.toLowerCase().includes(searchPlatformUsersText.toLowerCase())
      } else if (isSlackUser(user)) {
        return user.profile.real_name_normalized?.toLowerCase().includes(searchPlatformUsersText.toLowerCase()) ||
          user.profile.email?.toLowerCase().includes(searchPlatformUsersText.toLowerCase())
      } else if (isGoogleUser(user)) {
        return user.name?.toLowerCase().includes(searchPlatformUsersText.toLowerCase()) ||
          user.email?.toLowerCase().includes(searchPlatformUsersText.toLowerCase())
      }
      return false
    })
  }, [platformUsers, searchPlatformUsersText])

  const { 
    newPlatform, 
    oldPlatform, 
    tenantId, 
    slackOrgTeamId, 
    slackBotId, 
    organizationId,
    googleDomain,
  } = state as { 
    newPlatform: Platform 
    oldPlatform: Platform 
    tenantId: string
    slackOrgTeamId: string
    slackBotId: string
    organizationId: string
    googleDomain: string
  }

  // this needs to reset every time i open select
  const userOptions = useMemo(() => [
    { value: 'unmapped', label: formatMessage({ id: 'company.platformChanged.unmatched' }, { platform: capitalize(newPlatform) }) },
    ...filteredPlatformUsers.map(user => ({
      value: user.id,
      label: isMicrosoftUser(user) ? user.displayName : isSlackUser(user) ? user.profile.real_name_normalized : user.name,
    })),
  ], [filteredPlatformUsers, formatMessage])

  //prevent landing on this page without state
  if (!state) {
    console.log('no state redirecting to general')
    return <Redirect to="/app/settings/general" />
  }


  const fetchAllUsersInSystem = async (nextToken?: string, limit = 200, shortUsers: IGetUserListForMigration[] = []): Promise<void> => {
    setCompanyUsersLoading(true)
    const usersInSystem = await getAllUserListForMigrationPaginatedQuery({ variables: { nextToken, limit } })
    if (!usersInSystem.data || usersInSystem.error) throw usersInSystem.error

    const users: IGetUserListForMigration[] = shortUsers.concat(usersInSystem.data.getAllUserListPaginated.users as IGetUserListForMigration[])

    if (usersInSystem.data.getAllUserListPaginated.nextToken) {
      return await fetchAllUsersInSystem(usersInSystem.data.getAllUserListPaginated.nextToken, limit, users)
    }

    setCompanyUsers(users.filter(user => user.status !== 'DELETED'))
    setCompanyUsersLoading(false)
  }

  const fetchUsersFromNewPlatform = () => {
    const handleError = (error: Error) => {
      logger.error(`Failed to fetch ${newPlatform} users:`, error)
      history.push('/app/settings/general')
    }

    switch (newPlatform) {
      case 'microsoft': {
        const token = sessionStorage.getItem('msTokensAccessToken')
        if (!token) {
          history.push('/app/settings/general')
          return
        }
        fetchMicrosoftUsers(msAuth, token, setPlatformUsers, handleError)
        break
      }
      case 'slack': {
        const token = localStorage.getItem('slackTokensBotToken')
        if (!token) {
          history.push('/app/settings/general')
          return
        }
        fetchSlackUsers(slackAuth, token, setPlatformUsers, handleError)
        break
      }
      case 'google': {
        const token = googleAuth.getAccessToken()
        if (!token) {
          console.log('no token')
          history.push('/app/settings/general')
          return
        }
        console.log('fetching google users')
        fetchGoogleUsers(googleAuth, token, setPlatformUsers, handleError)
        break
      }
      default:
        logger.error(`Unsupported platform: ${newPlatform}`)
        history.push('/app/settings/general')
    }
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const handleHideInactive = (e: RadioChangeEvent) => {
    setHideInactive(e.target.value === 'yes')
  }

  const handleMarkUnmappedAsDeleted = (e: RadioChangeEvent) => {
    setMarkUnmappedAsDeleted(e.target.value === 'yes')
  }

  const handleSelectChange = (value: string, vtUserId: string): void => {
    const matchedUser = platformUsers.find(user => user.id === value)
    const newMapping: IUserMapping | null = value === 'unmapped' ? null : matchedUser ? {
      id: value,
      displayName: isMicrosoftUser(matchedUser) ? matchedUser.displayName : isSlackUser(matchedUser) ? matchedUser.profile.real_name_normalized : matchedUser.name,
      mail: isMicrosoftUser(matchedUser) ? matchedUser.mail : isSlackUser(matchedUser) ? matchedUser.profile.email : matchedUser.email,
      isOwner: isSlackUser(matchedUser) ? matchedUser.is_primary_owner : undefined,
      isAdmin: isMicrosoftUser(matchedUser) || isGoogleUser(matchedUser) ? companyUsers.find(user => user.id === vtUserId)?.isAdmin : undefined,
      initialUser: isMicrosoftUser(matchedUser) || isGoogleUser(matchedUser) ? companyUsers.find(user => user.id === vtUserId)?.initialUser : undefined,
    } : null

    const newMappings: UserMappings = {
      ...userMappings,
      [vtUserId]: newMapping,
    }
    setUserMappings(newMappings)
    saveUserMappingsToStorage(newMappings)
  }

  const handleSelectSearch = (value: string) => {
    setSearchPlatformUsersText(value)
  }

  const handleCancel = () => {
    history.push('/app/settings/general')
  }

  const showConfirmationDialog = () => {
    modal.confirm({
      title: formatMessage({ id: 'company.platformChanged.confirmTitle' }),
      content: (
        <>
          <Paragraph>
            {formatMessage({ id: 'company.platformChanged.confirmDescription' })}
          </Paragraph>
          {markUnmappedAsDeleted && unmappedUsers > 0 && (
            <Alert
              message={formatMessage({ id: 'company.platformChanged.confirmDescription.unmappedUsers.deleted' }, { unmappedUsers, newPlatform: capitalize(newPlatform) })}
              type="warning"
              showIcon
              style={{ marginBottom: 16 }}
            />
          )}
          {!markUnmappedAsDeleted && unmappedUsers > 0 && (
            <Alert
              message={formatMessage({ id: 'company.platformChanged.confirmDescription.unmappedUsers.unmatched' }, { unmappedUsers, newPlatform: capitalize(newPlatform) })}
              type="warning"
              showIcon
              style={{ marginBottom: 16 }}
            />
          )}
          <Paragraph strong>
            {formatMessage({ id: 'company.platformChanged.confirmQuestion' })}
          </Paragraph>
        </>
      ),
      onOk: () => startMigrationProcess(),
      okText: formatMessage({ id: 'app.proceed' }),
      cancelText: formatMessage({ id: 'app.cancel' }),
    })
  }

  const startMigrationProcess = () => {
    (async () => {
      setIsSaving(true)
      try {
        const event: ICompanyPlatformChangedEventBody = {
          eventType: 'COMPANY_PLATFORM_CHANGED',
          eventGroup: 'COMPANY',
          newPlatform: newPlatform,
          oldPlatform: oldPlatform,
          userMappings: userMappings,
          organizationId: getOrganizationId(newPlatform, state), 
          slackOrgTeamId: newPlatform === 'slack' ? slackOrgTeamId : undefined,
          slackBotId: newPlatform === 'slack' ? slackBotId : undefined,
          deleteUnmapped: markUnmappedAsDeleted,
          token: getTokensForPlatform(newPlatform),
        }
        console.log('event', event)
        
        const response = await Api.post('/core/event', {
          ...event,
        })

        notification.open({
          key: response.correlationId,
          message: formatMessage({ id: 'company.platformChanged' }),
          icon: (<LoadingOutlined />),
          duration: 0,
        })
        setActionNotifications([...actionNotifications, response.correlationId])
        setCorrelationId(response.correlationId as string)
        localStorage.removeItem('userMappings')
      } catch (error) {
        logger.error('Error creating event:', error)
        notification.error({
          message: formatMessage({ id: 'error.generic' }),
          description: error.response.data.message,
          duration: 0,
        })
        setIsSaving(false)
      }
    })()
  }

  const handleSave = () => {
    showConfirmationDialog()
  }

  const filteredData = companyUsers.filter(user => {
    const matchesSearch = user.name.toLowerCase().includes(searchText.toLowerCase())
    const matchesStatus = hideInactive ? user.status === 'ACTIVE' : true
    return matchesSearch && matchesStatus
  })

  const columns: ColumnsType<IGetUserListForMigration> = [
    {
      title: <IntlMessages id="company.platformChanged.vacationTrackerUser" />,
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Text>{text}</Text>
      ),
    },
    {
      title: <IntlMessages id="app.email" />,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: <IntlMessages id="app.status" />,
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: <IntlMessages id={`company.platformChanged.${newPlatform}Account`} />,
      dataIndex: `${newPlatform}Account`,
      key: `${newPlatform}Account`,
      render: (text, record) => (
        <Select
          style={{ width: '100%' }}
          value={userMappings[record.id]?.displayName || 'unmapped'}
          options={userOptions}
          onChange={(value) => handleSelectChange(value, record.id)}
          filterOption={false}
          showSearch
          onDropdownVisibleChange={(open) => {
            if (open) {
              setActiveSelectId(record.id)
              setSearchPlatformUsersText('')
            } else {
              setActiveSelectId(null)
            }
          }}
          onSearch={handleSelectSearch}
          key={record.id}
          loading={companyUsersLoading}
        />
      ),
    },
  ]


  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <div style={{ padding: '20px' }}>
      <Title level={3}>
        {
          newPlatform === oldPlatform ?
            <IntlMessages id="company.platformChangedTenant.title" values={{ newPlatform: capitalize(newPlatform) }} /> :
            <IntlMessages id="company.platformChanged.title" values={{ oldPlatform: capitalize(oldPlatform), newPlatform: capitalize(newPlatform) }} />
        }

      </Title>
      <Text><IntlMessages id="company.platformChanged.description" values={{ newPlatform: capitalize(newPlatform) }} /></Text>
      <Row style={{ margin: '20px 0' }} justify="end">
        <Space>
          <Text><IntlMessages id="company.platformChanged.hideInactive" /></Text>
          <Radio.Group onChange={handleHideInactive} value={hideInactive ? 'yes' : 'no'}>
            <Radio.Button value="yes"><IntlMessages id="app.yes" /></Radio.Button>
            <Radio.Button value="no"><IntlMessages id="app.no" /></Radio.Button>
          </Radio.Group>
          <Input placeholder="User name" value={searchText} onChange={handleSearch} style={{ width: 200 }} />
        </Space>
      </Row>
      <Table columns={columns} dataSource={filteredData} pagination={{ pageSize: 20 }} rowKey="id" />
      <Row style={{ marginTop: '20px' }}>
        <Col flex={1}>
          <Space>
            <Text><IntlMessages id="company.platformChanged.markUnmappedAsDeleted" /></Text>
            <Radio.Group onChange={handleMarkUnmappedAsDeleted} value={markUnmappedAsDeleted ? 'yes' : 'no'}>
              <Radio.Button value="yes"><IntlMessages id="app.yes" /></Radio.Button>
              <Radio.Button value="no"><IntlMessages id="app.no" /></Radio.Button>
            </Radio.Group>
          </Space>
        </Col>
        <Col>
          <Space>
            <Button onClick={handleCancel}><IntlMessages id="app.cancel" /></Button>
            <Button type="primary" onClick={handleSave} loading={isSaving}>
              <IntlMessages id="app.save" />
            </Button>
          </Space>
        </Col>
      </Row>
      <Modal
        title="Migration Successful"
        open={isLogoutModalVisible}
        closable={false}
        footer={null}
      >
        <p>You will be logged out in {countdown} seconds.</p>
        <Button onClick={() => void handleLogout()}>{formatMessage({ id: 'app.logoutNow' })}</Button>
      </Modal>
    </div>
  )
}

export default MigrationPage
