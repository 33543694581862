{
  "accountSettings.connectYourPersonalCalendar": "Anslut din personliga kalender",
  "accountSettings.connectYourPersonalCalendar.description": "Anslut din personliga kalender till ledigheter automatiskt. När du begär ledighet kan du ställa in om du vill att den begärda ledigheten ska visas som tillgänglig eller upptagen i din kalender. Besök vår <helpdesk>Helpdesk</helpdesk> för mer information.",
  "actionNotifications.companyAnnouncement.success": "Meddelande skickat",
  "actionNotifications.leave.editedRequestDenied": "Redigering av ledighetsbegäran nekad.",
  "actionNotifications.leave.editedRequestDeniedDescription": "Din redigerade ledighetsbegäran har nekats, men din ursprungliga begäran är fortfarande aktiv.",
  "actionNotifications.leave.requestApproved": "Ledighetsbegäran är godkänd",
  "actionNotifications.leave.requestDenied": "Ledighetsbegäran har nekats.",
  "actionNotifications.leaveType.created": "Ledighetstypen {leaveTypeName} har skapats",
  "actionNotifications.leaveType.deleted": "Ledighetstypen har raderats",
  "actionNotifications.leaveType.updated": "Ledighetstypen {leaveTypeName} har uppdaterats",
  "actionNotifications.location.createdTitle": "Platsen {locationName} har skapats",
  "actionNotifications.location.deletedTitle": "Platsen har raderats",
  "actionNotifications.location.updatedTitle": "Platsen {locationName} har uppdaterats",
  "actionNotifications.location.view": "Visa plats",
  "actionNotifications.notification.created": "Notifiering har skapats",
  "actionNotifications.notification.deleted": "Notifiering har raderats",
  "actionNotifications.notification.updated": "Notifiering har uppdaterats",
  "actionNotifications.outOfOfficeSetting.success": "Din frånvaroinställning har uppdaterats",
  "actionNotifications.scheduledReport.createdTitle": "Den schemalagda rapporten {scheduledReportName} har skapats",
  "actionNotifications.scheduledReport.deletedTitle": "Den schemalagda rapporten har raderats",
  "actionNotifications.scheduledReport.updatedTitle": "Den schemalagda rapporten {scheduledReportName} har uppdaterats",
  "actionNotifications.scheduledReport.viewScheduledReport": "Visa schemalagd rapport",
  "actionNotifications.team.createdTitle": "Avdelningen {teamName} har skapats",
  "actionNotifications.team.deletedTitle": "Avdelningen har raderats",
  "actionNotifications.team.updatedTitle": "Avdelningen {teamName} har uppdaterats",
  "actionNotifications.team.view": "Visa avdelning",
  "addLeave.addLeave": "Lägg till ledighet",
  "addLeave.handleSubmitErrorTitle": "Fel vid inlämning av ledighet",
  "addLeave.inProgress": "Lägger till ledighet",
  "addLeave.noApproverUsers": "Om du inte tillhör en avdelning kommer du inte att kunna lägga till ledighet för dig själv. För att begära ledighet även om du inte tillhör en avdelning du har godkännanderättigheter för, öppna formuläret \"Begär ledighet\" och skicka in en ledighetsbegäran.",
  "addLeave.title": "Ledighet tillagd",
  "addons.openApi.apiKeyName": "API-nyckelnamn",
  "addons.openApi.apiKeyNamePlaceholder": "API-nyckelnamn",
  "addons.openApi.apiKeySecret": "API-nyckelhemlighet",
  "addons.openApi.apiKeysInfo": "Generera och hantera dina API-nycklar.",
  "addons.openApi.apiKeysTitle": "API-nycklar",
  "addons.openApi.buyAppiAddon": "Köp API-tillägg",
  "addons.openApi.buyOpenApiInfoPrice": "API-tillägget är ett betalt tillägg som kostar ${price}/månad/aktiv användare. Att köpa det kommer att öka din månadsfaktura med ${totalPaid} för dina {userCount} aktiva {userCount, plural, =1 {användare} other {användare}} (vi ger dig den exakta uppskattningen när du klickar på \"Köp\"-knappen).",
  "addons.openApi.buyOpenApiInfoPrice2line": "Efter att du har köpt tillägget kan du eller någon annan administratör i din organisation generera och hantera din API-nyckel.",
  "addons.openApi.buyOpenApiTitle": "Köp API-tillägg för att generera API-nycklar",
  "addons.openApi.createInProgress": "Skapar API-nyckel pågår",
  "addons.openApi.createInProgressCompleted": "Skapande av API-nyckel slutfört",
  "addons.openApi.createNewApiKey": "Skapa ny API-nyckel",
  "addons.openApi.currentMonthUsage": "Aktuell månadens användning",
  "addons.openApi.documentation": "API-dokumentation",
  "addons.openApi.documentationInfo": "API-dokumentationen beskriver hur vårt API fungerar och vilka metoder som för närvarande är tillgängliga.",
  "addons.openApi.dontHaveApiKeys": "Du har inga API-nycklar, klicka på knappen nedan för att skapa en",
  "addons.openApi.dpenDocumentation": "Öppna dokumentation",
  "addons.openApi.feedback": "Om du har feedback eller behöver data som vårt API inte exponerar, <link>klicka här för att låta oss veta</link>.",
  "addons.openApi.limitRequestPerMonth": "Du kan skicka upp till 30000 förfrågningar per månad",
  "addons.openApi.limitRequestPerSecound": "Förfrågningar är begränsade till 10 per sekund med ett tillfälligt läge på 50 förfrågningar/sekund",
  "addons.openApi.notificationDeleteConfirm": "Vänligen bekräfta att du vill radera API-nyckeln <strong>{name}</strong>.",
  "addons.openApi.notificationDeleteTitle": "Radera API-nyckel",
  "addons.openApi.openApiDeleted": "Open API-nyckel raderad",
  "addons.openApi.quotasAndRateLimitsInfo": "Här är våra API-kvoter och hastighetsbegränsningar per API-nyckel:",
  "addons.openApi.quotasAndRateLimitsTitle": "Kvoter och hastighetsbegränsningar",
  "addons.openApiInfo": "Vårt API-tillägg låter dig programmässigt ansluta Vacation Tracker till andra applikationer. För närvarande ger vårt API dig skrivskyddad åtkomst till dina användare, deras ledigheter, platser, avdelningar, ledighetstyper och etiketter.",
  "addonsPaymentStatus.activateForFree": "Aktivera gratis",
  "addonsPaymentStatus.activeUntilBillingPeriodEnds": "Aktiv tills faktureringsperioden slutar",
  "addonsPaymentStatus.canceled": "Avbruten",
  "addonsPaymentStatus.grantPeriod": "Beviljningsperiod",
  "addonsPaymentStatus.paymentActive": "Betalning aktiv",
  "ai.assistant.completedConversationTooltip": "Den här konversationen är avslutad. Du kan inte skicka nya meddelanden, men du kan se den befintliga konversationen.",
  "ai.assistant.defaultMessage": "Vad kan jag hjälpa dig med?",
  "ai.assistant.disclaimer": "AI-assistenten är i Beta. Den kan göra misstag. Vid fel eller förvirrande svar, vänligen <support>kontakta vår support</support>.",
  "ai.assistant.inputLockedTooltip": "Detta är en tidig version av vår AI-assistent. För närvarande hjälper den dig bara med onboarding. Snart kommer du dock att kunna starta nya konversationer och prata med Vacation Tracker AI-assistenten. Tack för ditt tålamod!",
  "ai.assistant.lastMessageTimestampTooltip": "Det senaste meddelandet skickades den: {date}",
  "ai.assisted.onboarding.companyLogUserNameDecorator": "(via AI-assistent)",
  "ai.assisted.onboarding.finalAssistantMessage": "Din onboarding-process är klar. Vacation Tracker kommer nu att ställa in ditt företagskonto med denna konfiguration. Kom ihåg att du alltid kan göra ändringar i din inställning senare om det behövs.",
  "ai.assisted.onboarding.initialAssistantMessage": "🎯 Förifyllt för dig:\n\nPlatser ({locations}), \nAvdelningar ({departments}), och \nLedighetstyper ({leaveTypes}).\n\nSnabb fråga: Arbetar alla i ditt företag på denna plats?",
  "ai.assisted.onboarding.initialUserMessage": "👋 Hej där! Välkommen till Vacation Tracker.\n\nJag är här för att göra din inställning <b>snabb och enkel</b>. Låt mig hjälpa dig komma igång på några minuter eller så kan du hantera det manuellt i Inställningar.",
  "ai.assisted.onboarding.noTopics": "Hej, det verkar som att du just hittat ett påskägg! 🐣 Vi arbetar på något nytt som borde spara dig mycket tid, men det är inte redo än. Så, låt oss hålla detta som vår lilla hemlighet. Tack!",
  "ai.assisted.onboarding.openSetupNotification": "Se vad som är gjort hittills",
  "ai.assisted.onboarding.openSetupNotificationFetchingSetup": "Tack för att du tillhandahållit all nödvändig information. Jag börjar nu skapa din Vacation Tracker-inställning. Du kommer att börja se live-uppdateringar när som helst nu...",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigned": "{leaveTypeName} ledighetstyp tilldelad till {locationName}",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigning": "Tilldelar {leaveTypeName} ledighetstyp till {locationName}",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicySuccessAction": "{entityName} {entity} har framgångsrikt {action}",
  "ai.assisted.onboarding.openSetupNotificationNoData": "Kan inte visa inställningsdata",
  "ai.assisted.onboarding.openSetupNotificationNoDataSubtitle": "Jag kan inte visa inställningsdata just nu. Detta kan hända på grund av ett kort nätverksavbrott eller för att systemet fortfarande inte har slutfört bearbetningen av data. Vänligen ladda om den här sidan eller kontrollera inställningarna i avsnittet Inställningar i menyn.",
  "ai.assisted.onboarding.openSetupNotificationTitle": "Status för onboarding-inställning",
  "ai.assisted.onboarding.sendMessageError": "Det verkar som att AI-assistentens svar tar för lång tid. Vänligen ladda om sidan.",
  "ai.assisted.onboarding.summary.intro": "Du är nu helt klar med inställningarna. Du kan nu öppna instrumentpanelen och börja använda appen.",
  "ai.assisted.onboarding.summary.introSummary": "Här är en sammanfattning av dina inställningar:",
  "ai.assisted.onboarding.summary.leavePoliciesInfo": "Ledighetspolicyer är konfigurationer för ledighetstyper specifika för en plats. För att se de skapade ledighetspolicyerna, gå till platssidan, välj plats och öppna sedan fliken för ledighetspolicyer.",
  "ai.assisted.onboarding.summary.view.departments": "Visa Avdelningar",
  "ai.assisted.onboarding.summary.view.leaveTypes": "Visa Ledighetstyper",
  "ai.assisted.onboarding.summary.view.locations": "Visa Platser",
  "app.12h": "12 tim",
  "app.24h": "24 tim",
  "app.aboutMe": "Om mig:",
  "app.accountPreferences": "Visa Inställningar",
  "app.accountSettings": "Kontoinställningar",
  "app.accruals": "Upplupna dagar",
  "app.actions": "Åtgärder",
  "app.activate": "Aktivera",
  "app.active": "Aktiv",
  "app.add": "Lägg till",
  "app.addAvatar": "Lägg till Avatar",
  "app.addLeave": "Lägg till Ledighet",
  "app.addNewEmail": "Lägg till Mottagare",
  "app.addons": "Tillägg",
  "app.addToGoogleCalendar": "Google Kalender",
  "app.addToMicrosoft365Calendar": "Microsoft 365 Kalender",
  "app.AIAssistant": "AI-assistent",
  "app.all": "Alla",
  "app.allDay": "Hela dagen",
  "app.allowHalfDays": "Tillåt Halvdagar",
  "app.allowHourlyIncrements": "Tillåt Timvis Ökning",
  "app.amount": "Belopp",
  "app.and": "och",
  "app.announcementsHaveBeenPaused": "Dina meddelanden har pausats i 2 månader.",
  "app.apply": "Tillämpa",
  "app.applyTo": "Tillämpa på",
  "app.approvalReason": "Godkännandeorsak",
  "app.approvalRequired": "Godkännande Krävs",
  "app.approve": "Godkänn",
  "app.approved": "Godkänd",
  "app.approvedByFristLevel": "Du är den andra nivån av godkännande för denna begäran. Den har redan godkänts av {approverName}.",
  "app.approveLeaveText": "Vänligen bekräfta att du vill godkänna ledighetsbegäran.",
  "app.approveLeaveTitle": "Godkänn Ledighetsbegäran",
  "app.april": "April",
  "app.areYouSure": "Är du säker?",
  "app.askMeLater": "Fråga mig senare",
  "app.assignLeavePolicy": "Tilldela ledighetspolicy",
  "app.august": "Augusti",
  "app.autoApproved": "Automatiskt godkänd",
  "app.autoDeleted": "<Automatiskt raderad>",
  "app.autoImport": "<Automatisk import>",
  "app.automations": "Automatiseringar",
  "app.avatar": "Profilbild",
  "app.back": "Tillbaka",
  "app.backToMenu": "Tillbaka till menyn",
  "app.bi-weekly": "Varannan vecka",
  "app.billing.periodAnnual": "Årlig",
  "app.billingStatus": "Faktureringsstatus",
  "app.billingStatus.ACTIVE": "Betalning aktiv",
  "app.billingStatus.CANCEL_AT_PERIOD_END": "Aktiv tills faktureringsperioden avslutas",
  "app.billingStatus.CANCELED": "Betalning avbruten",
  "app.billingStatus.GRANT_PERIOD": "Bidragsperiod",
  "app.broughtForward": "Överfört",
  "app.by": "av",
  "app.calendar": "Kalender",
  "app.cancel": "Avbryt",
  "app.cancelLeave": "Avbryt ledighet",
  "app.cancelLeaveConfirmText": "Är du säker på att du vill avbryta denna planerade ledighet?",
  "app.cancelled": "Avbruten",
  "app.change": "Ändra",
  "app.changePassword": "Ändra lösenord",
  "app.clearFilters": "Rensa filter",
  "app.close": "Stäng",
  "app.code": "Kod",
  "app.color": "Färg",
  "app.coming-soon": "(kommer snart)",
  "app.comingSoon": "Kommer snart",
  "app.company": "Företag",
  "app.companyLogs": "Företagsloggar",
  "app.companyNotExist": "Oj! Det verkar som att du inte slutförde registreringen hos Vacation Tracker. Vänligen besök: app.vacationtracker.io för att slutföra registreringen.",
  "app.companyNotExist.description1": "Du har nu lagt till Vacation Tracker-boten och fliken i din Microsoft Teams-arbetsyta.",
  "app.companyNotExist.description2": "Vänligen gå till vår webbplats för att slutföra din registrering och konfigurera dina organisationsinställningar.",
  "app.companyNotExist.getStarted": "Slutför registrering",
  "app.completed": "Slutfört",
  "app.completeRegistration": "Slutför registrering",
  "app.configuration.moreInfo": "För mer information, besök vår hjälpcentral: <link>https://vacationtracker.crisp.help/en/</link>",
  "app.configuration.option.overview": "Översikt",
  "app.configuration.options.info": "Välj fliktyp: ",
  "app.configuration.welcome": "Välkommen till Vacation Tracker! Denna flik låter dig enkelt begära ledighet utan att lämna Microsoft Teams, hantera dina ledigheter, kontrollera status på dina ledighetsansökningar, se vem som är borta från kontoret och se kommande helgdagar för ditt team.",
  "app.configure": "Konfigurera",
  "app.confirm": "Bekräfta",
  "app.confirmNewPassword": "Bekräfta nytt lösenord",
  "app.confirmPassword": "Bekräfta lösenord",
  "app.consentRequired": "Microsoft Teams administratörsmedgivande krävs. Det verkar som att du inte är administratör för din Microsoft Teams-organisation. För att få åtkomst till Vacation Tracker-fliken behöver du administratörsmedgivande. Vänligen skicka följande länk till administratören för din organisation för att få administratörsmedgivande: {link}",
  "app.consentRequired.title": "Microsoft Teams administratörsmedgivande krävs",
  "app.contactSupport": "Kontakta support",
  "app.continue": "Fortsätt",
  "app.copyright": "Copyright VacationTracker",
  "app.correlationIdError": "Ett fel inträffade. Vänligen kontakta vår kundsupport på hello@vacationtracker.io med följande ID: {correlationId}",
  "app.country": "Land",
  "app.create": "Skapa",
  "app.created": "Skapad",
  "app.createdAt": "Skapad",
  "app.createdBy": "Skapad av",
  "app.createdSuccessfully": "Begäran skickad",
  "app.creating": "Skapar",
  "app.creditCard": "Kreditkort",
  "app.currentYear": "Innevarande år",
  "app.customDays": "Anpassade dagar",
  "app.customWorkHours": "Anpassade arbetstimmar",
  "app.daily": "Dagligen",
  "app.dashboard": "Dashboard",
  "app.dateFormat": "Datumformat",
  "app.dateRange": "Start- och slutdatum",
  "app.day": "dag",
  "app.days": "dagar",
  "app.december": "December",
  "app.delete": "Radera",
  "app.deleted": "Raderad",
  "app.deleteFailed": "Radering misslyckades",
  "app.deleteUser": "Radera användare",
  "app.deleting": "Raderar",
  "app.deliveryFailed": "Leverans misslyckades",
  "app.denialReason": "Avslagsanledning",
  "app.denied": "Nekad",
  "app.deny": "Neka",
  "app.denyLeaveRequest": "Neka ledighetsansökan",
  "app.denyLeaveRequestText": "Vänligen bekräfta att du vill neka ledighetsansökan.",
  "app.denyWithReason": "Neka med anledning",
  "app.department": "Avdelning",
  "app.departments": "Avdelningar",
  "app.departmentsSub": "Underavdelningar",
  "app.deselectAll": "Avmarkera alla",
  "app.disable": "Inaktivera",
  "app.display": "Visa",
  "app.displayDaysHoursTooltip.days": "Det är {value} dagar",
  "app.displayDaysHoursTooltip.hours": "Det är {value} timmar",
  "app.documentation": "Dokumentation",
  "app.done": "Klar",
  "app.dontSend": "Skicka inte",
  "app.downgradeWithoutUnsubscribe": "Nedgradera utan att avsluta prenumerationen",
  "app.downgradeWithSubscribe": "Nedgradera och avsluta prenumerationen",
  "app.download": "Ladda ner",
  "app.downloadProgressReport": "Skapar din fil, detta kan ta en stund...",
  "app.downloadReady": "Din fil är klar. Klicka här för att ladda ner den.",
  "app.downloadTemplate": "Ladda ner mall",
  "app.downloadTemplateAs": "Ladda ner mall som",
  "app.edit": "Redigera",
  "app.edited": "Redigerad",
  "app.editLeave": "Redigera ledighet",
  "app.email": "E-post",
  "app.emailAddress": "E-postadress",
  "app.emailDeleted": "E-post borttagen",
  "app.employeeEndDate": "Anställningens slutdatum",
  "app.enable": "Aktivera",
  "app.enabled": "Aktiverad",
  "app.enterWorkEmail": "Ange din jobbmejl",
  "app.error": "Fel",
  "app.every": "varje",
  "app.expired": "Utgången",
  "app.expires": "Går ut",
  "app.export": "Exportera",
  "app.export.comingSoon": "Vårt team finputsar denna funktion. Du bör förvänta dig den snart.",
  "app.feature.access": "Tillgängligt exklusivt i Complete-planen.",
  "app.february": "Februari",
  "app.firstAndLastName": "För- och efternamn",
  "app.friday": "Fredag",
  "app.friday.short": "Fre",
  "app.from": "Från",
  "app.full-day": "Heldag",
  "app.full-name": "Fullständigt namn",
  "app.general": "Allmänt",
  "app.goodAfternoon": "God eftermiddag",
  "app.goodEvening": "God kväll",
  "app.goodMorning": "God morgon",
  "app.gotIt": "Uppfattat",
  "app.half-day": "Halvdag",
  "app.halfDay": "Halvdag",
  "app.halfDayInParenthesis": " (Halvdag)",
  "app.happyVacationing": "Trevlig semester 🌴",
  "app.helpdesk": "Kundtjänst",
  "app.holidays": "Helgdagar",
  "app.home": "Hem",
  "app.hour": "timme",
  "app.hours": "timmar",
  "app.import": "Importera",
  "app.importFile": "Starta import",
  "app.importFormat": "Importformat",
  "app.importFormatTitle": "Välj mallformat",
  "app.importLeaves.alerts.confirmClose.bodyText": "Är du säker? Din nuvarande information kommer inte att sparas.",
  "app.importLeaves.alerts.confirmClose.cancelButtonTitle": "Avbryt",
  "app.importLeaves.alerts.confirmClose.exitButtonTitle": "Avsluta",
  "app.importLeaves.alerts.confirmClose.headerTitle": "Avsluta importflöde",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyText": "Det finns fortfarande några rader som innehåller fel. Rader med fel kommer att ignoreras vid inlämning.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyTextSubmitForbidden": "Det finns fortfarande några rader som innehåller fel. Ta bort ogiltiga rader eller åtgärda felen och försök igen.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.cancelButtonTitle": "Avbryt",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.finishButtonTitle": "Skicka in",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.headerTitle": "Fel upptäckta",
  "app.importLeaves.alerts.toast": "Fel",
  "app.importLeaves.alerts.unmatchedRequiredFields.bodyText": "Det finns obligatoriska kolumner som inte är matchade eller ignorerade. Du måste matcha alla obligatoriska kolumner för att fortsätta.",
  "app.importLeaves.alerts.unmatchedRequiredFields.cancelButtonTitle": "Avbryt",
  "app.importLeaves.alerts.unmatchedRequiredFields.continueButtonTitle": "Fortsätt",
  "app.importLeaves.alerts.unmatchedRequiredFields.headerTitle": "Inte alla kolumner matchade",
  "app.importLeaves.alerts.unmatchedRequiredFields.listTitle": "Obligatoriska kolumner som inte matchats:",
  "app.importLeaves.downloadTemplateFileTooltip": "Här kan du ladda ner vår importmallsfil, som fungerar som ett exempel för att fylla i de data du vill importera. När du skapar din importfil, se till att den innehåller en rubrikkolumn för att systemet ska känna igen dina data korrekt.<br></br><br></br>Kontrollera att dina data matchar mallen för att säkerställa en lyckad import. För ytterligare instruktioner, tveka inte att kontakta vårt supportteam.",
  "app.importLeaves.dropzone.activeDropzoneTitle": "Släpp filen här",
  "app.importLeaves.dropzone.buttonTitle": "Välj fil",
  "app.importLeaves.dropzone.errorToastDescription": "Ladda upp .xlsx, .xls eller .csv-fil",
  "app.importLeaves.dropzone.loadingTitle": "Bearbetar...",
  "app.importLeaves.dropzone.title": "Ladda upp .xlsx, .xls eller .csv-fil",
  "app.importLeaves.errors.atLeastOne": "Se till att du lägger till minst ett fält.",
  "app.importLeaves.errors.dateFromAfterDateTo": "Från-datum är efter Till-datum",
  "app.importLeaves.errors.dateFromInFuture": "TOIL-datum kan inte vara i framtiden",
  "app.importLeaves.errors.dateFromNotValid": "Från-datum är inte giltigt",
  "app.importLeaves.errors.dateFromRequired": "Från-datum krävs",
  "app.importLeaves.errors.dateToNotValid": "Till-datum är inte giltigt",
  "app.importLeaves.errors.dateToRequired": "Till-datum krävs",
  "app.importLeaves.errors.departmentNotFound": "Avdelning hittades inte",
  "app.importLeaves.errors.endDateNotEnabled": "Användarens slutdatum är inaktiverat i företagsinställningarna",
  "app.importLeaves.errors.halfDay": "Ledighet är längre än en dag",
  "app.importLeaves.errors.hourFromAfterHourTo": "Från-timme är efter Till-timme",
  "app.importLeaves.errors.hourFromNotValid": "Från-timme är inte giltig, ange ett värde mellan 0 och 24",
  "app.importLeaves.errors.hourFromRequired": "Från-timme krävs",
  "app.importLeaves.errors.hourRange": "Valda timmar överskrider denna användares arbetsdagslängd ({hours} timmar). Vänligen justera ditt val.",
  "app.importLeaves.errors.hourToNotValid": "Till-timme är inte giltig, ange ett värde mellan 0 och 24",
  "app.importLeaves.errors.hourToRequired": "Till-timme krävs",
  "app.importLeaves.errors.leavePolicyError": "Ledighetspolicyn tillåter endast hela dagar",
  "app.importLeaves.errors.leavePolicyToilError": "Ledighetspolicyn har inte TOIL aktiverat",
  "app.importLeaves.errors.leaveTypeNotFound": "Ledighetstyp hittades inte",
  "app.importLeaves.errors.leaveTypeNotInUserLocation": "Ledighetstyp finns inte på användarens plats",
  "app.importLeaves.errors.locationNotFound": "Plats hittades inte",
  "app.importLeaves.errors.startDateRequired": "Startdatum krävs",
  "app.importLeaves.errors.toilAlreadyExist": "TOIL finns redan under denna period",
  "app.importLeaves.helpdesk": "För detaljerad vägledning om hur du importerar dina filer, vänligen se steg-för-steg-instruktionerna som finns på <helpdesk>hjälpcenterets sida.</helpdesk>",
  "app.importLeaves.importUsersDataWarning": "Denna import kommer endast att gälla för aktiva användare.",
  "app.importLeaves.leaveStatusWarning": "Ledighetsstatus kommer att sättas till \"Godkänd\" för alla ledigheter du importerar.",
  "app.importLeaves.manifestDescription": "(Du kommer att ha möjlighet att byta namn på eller ta bort kolumner i nästa steg)",
  "app.importLeaves.manifestTitle": "Data som vi förväntar oss:",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningDescription": "Kolumner kan inte dupliceras",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningTitle": "En annan kolumn avmarkerad",
  "app.importLeaves.matchColumnsStep.ignoredColumnText": "Kolumn ignorerad",
  "app.importLeaves.matchColumnsStep.matchDropdownTitle": "Matcha",
  "app.importLeaves.matchColumnsStep.nextButtonTitle": "Nästa",
  "app.importLeaves.matchColumnsStep.selectPlaceholder": "Välj kolumn...",
  "app.importLeaves.matchColumnsStep.subSelectPlaceholder": "Välj...",
  "app.importLeaves.matchColumnsStep.templateTitle": "Kommer att bli",
  "app.importLeaves.matchColumnsStep.title": "Matcha kolumner",
  "app.importLeaves.matchColumnsStep.unmatched": "Omatchade",
  "app.importLeaves.matchColumnsStep.userTableTitle": "Din tabell",
  "app.importLeaves.maxRecordsExceeded": "För många poster. Upp till {maxRecords} tillåtna",
  "app.importLeaves.nextButtonTitle": "Nästa",
  "app.importLeaves.selectHeaderStep.title": "Välj rubrikrad",
  "app.importLeaves.selectSheet.title": "Välj blad att använda",
  "app.importLeaves.validationStep.discardButtonTitle": "Kasta valda rader",
  "app.importLeaves.validationStep.filterSwitchTitle": "Visa endast rader med fel",
  "app.importLeaves.validationStep.nextButtonTitle": "Bekräfta",
  "app.importLeaves.validationStep.noRowsMessage": "Ingen data hittades",
  "app.importLeaves.validationStep.noRowsMessageWhenFiltered": "Ingen data innehåller fel",
  "app.importLeaves.validationStep.title": "Validera data",
  "app.imports": "Importer",
  "app.importType": "Importtyp",
  "app.integrations": "Integrationer",
  "app.invite": "Bjud in",
  "app.january": "Januari",
  "app.july": "Juli",
  "app.june": "Juni",
  "app.label": "Etikett",
  "app.labels": "Etiketter",
  "app.lastMonth": "Förra månaden",
  "app.learnMore": "Lär dig mer",
  "app.leave": "Ledighet",
  "app.leaveBalanceReport": "Rapport över ledighetssaldo",
  "app.leaveCanceled": "Ledighet avbokad",
  "app.leaveHistory": "Ledighetshistorik",
  "app.leaveHistoryReport": "Rapport över ledighetsförfrågningar",
  "app.leavePolicies": "Ledighetspolicyer",
  "app.leaveRequestCalendarStatusLabel": "Visa i din kalender som ",
  "app.leaveRequestCreated": "Ledighetsförfrågan skapad",
  "app.leaveRequests": "Ledighetsförfrågningar",
  "app.leaveType": "Ledighetstyp",
  "app.leaveTypes": "Ledighetstyper",
  "app.link": "Länk",
  "app.load": "Ladda",
  "app.loading": "Laddar",
  "app.loadMore": "Ladda mer",
  "app.location": "Plats",
  "app.locations": "Platser",
  "app.login": "Logga in",
  "app.logInWithMicrosoft": "Logga in med Microsoft",
  "app.logout": "Logga ut",
  "app.logoutNow": "Logga ut nu",
  "app.logs": "Loggar",
  "app.manageLabels": "Hantera etiketter",
  "app.march": "Mars",
  "app.may": "Maj",
  "app.maybe": "Kanske",
  "app.message": "meddelande",
  "app.meta.description": "Älskat av över 1 000 företag världen över, Vacation Tracker gör det otroligt enkelt att hantera din ledighet. Tillgängligt för Slack, Microsoft Teams och Google Workspace.",
  "app.meta.title": "Vacation Tracker - Distansvänlig lösning för ledighetshantering",
  "app.mfa": "Multifaktorautentisering",
  "app.mfaCode": "Kod",
  "app.mfaCodeErrorGeneric": "Det gick inte att validera koden",
  "app.mfaCodeErrorMissmatch": "Koden stämmer inte överens",
  "app.mfaCodeErrorNetwork": "Nätverksfel",
  "app.mfaCodePlaceholder": "Vänligen ange koden som visas i din autentiseringsapp",
  "app.mfaDisableAnswerNo": "Nej, behåll MFA",
  "app.mfaDisableAnswerYes": "Ja, inaktivera MFA",
  "app.mfaDisableError": "Det gick inte att inaktivera MFA. Försök igen senare.",
  "app.mfaDisableSuccess": "Du har framgångsrikt inaktiverat MFA.",
  "app.mfaDisableWarning": "Är du säker på att du vill inaktivera MFA?",
  "app.mfaDisableWarningTitle": "Inaktivera multifaktorautentisering",
  "app.mfaEnableError": "Det gick inte att aktivera MFA. Försök igen senare.",
  "app.mfaEnableSuccess": "Du har framgångsrikt konfigurerat MFA.",
  "app.mfaEnterCode": "Ange kod",
  "app.mfaLabel": "Aktivera MFA",
  "app.mfaLoginFailed": "Inloggningen misslyckades",
  "app.mfaSecretKey": "Eller ange den hemliga nyckeln manuellt:",
  "app.mfaSubTitle": "Skanna följande QR-kod i din MFA-applikation (t.ex. Authy eller Google Authenticator):",
  "app.mfaTitle": "Aktivera multifaktorautentisering",
  "app.mfaTooltip": "När du har lagt till Vacation Tracker i din MFA-applikation, vänta tills den genererar koden och ange den nedan:",
  "app.microsoft.manageLicenses": "Hantera licenser",
  "app.microsoftTeams": "Microsoft Teams",
  "app.monday": "Måndag",
  "app.monday.short": "Mån",
  "app.monthly": "Månadsvis",
  "app.monthlyLeaveBalanceReport": "Månadsvis ledighetsbalansrapport",
  "app.moreDetails": "Mer detaljer",
  "app.myProfile": "Min profil",
  "app.myStatus": "Min status",
  "app.name": "Namn",
  "app.negativeBroughtForward": "Aktivera negativt överfört saldo",
  "app.negativeBroughtForwardInfo": "Om detta är inställt på Ja, kommer ett negativt saldo att överföras till den nya ledighetsperioden, vilket minskar den anställdes återstående ledighetsberättigande i enlighet därmed.",
  "app.newPassword": "Nytt lösenord",
  "app.next": "Nästa",
  "app.nextInvoice": " Din nästa faktura kommer att vara {amount} (exklusive moms) den {startDate}",
  "app.nextMonth": "Nästa månad",
  "app.nextYear": "Nästa år",
  "app.no": "Nej",
  "app.noLeaves": "Inga ledigheter",
  "app.none": "Ingen",
  "app.nonWorkingDay": "Icke-arbetsdag",
  "app.notDone": "Inte klar",
  "app.note": "Anteckning",
  "app.notificationError": "Åh nej! 😕 Något gick fel. ",
  "app.notifications": "Aviseringar",
  "app.notSet": "Inte inställt",
  "app.notValidEmail": "Vänligen ange en giltig e-postadress.",
  "app.november": "November",
  "app.october": "Oktober",
  "app.of": "av",
  "app.ofThat": "av det",
  "app.ok": "Ok",
  "app.oldPassword": "Gammalt lösenord",
  "app.one-hour": "1 timme",
  "app.openCalendar": "Öppna kalender",
  "app.openDashboard": "Öppna instrumentpanel",
  "app.openWebDashboard": "Öppna webb-instrumentpanel",
  "app.or": "Eller",
  "app.password": "Lösenord",
  "app.passwordAndSecurity": "Lösenord och säkerhet",
  "app.pastDue": "På grund av en misslyckad betalning kommer din åtkomst till Vacation Tracker snart att stängas. Om du vill fortsätta använda Vacation Tracker, vänligen <a>ange ett giltigt kort</a>.",
  "app.pending": "Väntande",
  "app.pendingLeaveRequests": "Väntande ledighetsansökningar",
  "app.pleaseSelect": "Vänligen välj",
  "app.prev": "Föreg",
  "app.previous": "Föregående",
  "app.proceed": "Fortsätt",
  "app.processingRequest": "Bearbetar förfrågan...",
  "app.profilePicture": "Profilbild",
  "app.reason": "Anledning",
  "app.recipients": "Mottagare",
  "app.recommended": "rekommenderad",
  "app.reconnect": "Återanslut",
  "app.reload": "Ladda om",
  "app.removed": "borttagen",
  "app.rename": "Byt namn",
  "app.reportType": "Rapporttyp",
  "app.requestLeave": "Begär ledighet",
  "app.requestToil": "Begär kompensationsledighet",
  "app.requestType": "Typ av begäran",
  "app.required": "Obligatorisk",
  "app.resendCode": "Skicka kod igen",
  "app.resent": "Skickad igen",
  "app.reset": "Återställ",
  "app.resetPassword": "Återställ lösenord",
  "app.resultsLeaves": "Totalt hittade: {total} {total, plural, =1 {ledighetsförfrågan} other {ledighetsförfrågningar}}",
  "app.resultsUsers": "Totalt hittade: {total} {total, plural, =1 {användare} other {användare}}",
  "app.reviewAndConfirm": "Granska & Bekräfta",
  "app.role.administrator": "Administratör",
  "app.role.approver": "Godkännare",
  "app.role.approvers": "Godkännare",
  "app.role.approversLevelFrist": "Nivå 1",
  "app.role.approversLevelSecound": "Nivå 2",
  "app.saturday": "Lördag",
  "app.saturday.short": "Lör",
  "app.save": "Spara",
  "app.scheduledLeaves": "Schemalagda ledigheter",
  "app.scheduledReports": "Schemalagda rapporter",
  "app.seeInCalendar": "🗓️ Se i kalender",
  "app.seeMoreOptions": "Se fler alternativ",
  "app.select": "Välj",
  "app.selectAll": "Välj alla",
  "app.selected": "Vald",
  "app.semi-monthly": "Halvmånadsvis",
  "app.send": "Skicka",
  "app.sendInvites": "Skicka inbjudningar",
  "app.sendNow": "Skicka nu",
  "app.september": "September",
  "app.set": "ställ in",
  "app.setOn": "ställ in på",
  "app.shortestLeaveDuration": "Kortaste ledighetsperiod",
  "app.show": "Visa",
  "app.showLess": "Visa mindre",
  "app.showMore": "Visa mer",
  "app.skip": "Hoppa över",
  "app.slack": "Slack",
  "app.snooze": "Snooza",
  "app.snoozeForMonths": "Snooza i 2 månader",
  "app.startFreeTrial": "Starta din 7-dagars gratis provperiod",
  "app.state": "Delstat",
  "app.status": "Status",
  "app.submit": "Skicka in",
  "app.substituteApprover": "Ersättande godkännare",
  "app.substituteApproverSet": "Du har blivit utsedd till ersättande godkännare.",
  "app.sunday": "Söndag",
  "app.sunday.short": "Sön",
  "app.switch": "Byt",
  "app.sync": "Synkronisera",
  "app.takeQuickTour": "Ta en snabb rundtur",
  "app.text": "Text",
  "app.thisMonth": "Denna månad",
  "app.thisWeek": "Denna vecka",
  "app.thursday": "Torsdag",
  "app.thursday.short": "Tor",
  "app.time": "Tid",
  "app.timeOffInLieu": "Kompensationsledighet",
  "app.timeOffInLieuTooltip": "Vill du redigera kompensationsledighet? <helpDesk>Lär dig hur du gör det</helpDesk>",
  "app.timezone": "Tidszon",
  "app.to": "Till",
  "app.today": "Idag",
  "app.toil": "TOIL",
  "app.trialExpired": "Provversionen går ut om {days} {daysInfo}. Om du gillar oss, vänligen <a>prenumerera</a> för att fortsätta använda Vacation Tracker efter att provperioden avslutas.",
  "app.trialExtend": "Behöver du förlänga provperioden?",
  "app.trialPeriod": "Provperiod",
  "app.tryAgain": "Försök igen",
  "app.tryLateOrContactSupport": "Något gick fel. Vänligen försök senare eller kontakta vår kundsupport på hello@vacationtracker.io med följande ID: {code}",
  "app.tuesday": "Tisdag",
  "app.tuesday.short": "Tis",
  "app.type": "Typ",
  "app.unknown": "<Okänd>",
  "app.unlimited": "Obegränsad",
  "app.update": "Uppdatera",
  "app.updated": "uppdaterad",
  "app.updatedInProgress": "Bearbetar svar",
  "app.updatedSuccessfully": "Förfrågan skickad.",
  "app.updating": "Uppdaterar",
  "app.upload": "Ladda upp",
  "app.user": "Användare",
  "app.users": "Användare",
  "app.users.role": "Roll",
  "app.userStatus": "Användarstatus",
  "app.via": "via",
  "app.view": "Visa",
  "app.viewAll": "Visa alla",
  "app.visitHelpdesk": "Besök hjälpcenter",
  "app.wallchart": "Väggdiagram",
  "app.was": "var",
  "app.watchVideo": "Se video",
  "app.wednesday": "Onsdag",
  "app.wednesday.short": "Ons",
  "app.weekly": "Veckovis",
  "app.willBe": "kommer att vara",
  "app.workday": "Arbetsdagens längd",
  "app.workdayWarning": "Halvdagsledighet är endast tillgängligt för en 8-timmars arbetsdag. Att ändra standardarbetsdagen från 8 timmar kommer att inaktivera detta alternativ.",
  "app.workweek": "Arbetsvecka",
  "app.year": "År",
  "app.years": "År",
  "app.yes": "Ja",
  "automation.feedback.thankYou": "Tack för att du delar dina tankar med oss. Din input driver vår ständiga tillväxt.",
  "automations.activateInProgress": "Pågående - Aktivering",
  "automations.activateInProgressDescription": "Din begäran om att aktivera automatiseringen är för närvarande under behandling. Vi arbetar med att aktivera denna funktion för dig.",
  "automations.add": "Skapa automatisering",
  "automations.addAddonsDescirptionForAdmin": "Vill du fortsätta med köpet av det valda tillägget? Beloppet kommer att proportioneras till ${prorateAmount}, och din nästa faktura kommer att uppgå till totalt ${nextInvoiceAmount}. Klicka på 'Bekräfta' för att slutföra.",
  "automations.addAddonsDescirptionForAdminInTrial": "Detta tillägg kommer att faktureras på din nästa faktura: ${total} baserat på {userCount} användare till ${addonsPrice} per tillägg. Under provperioden kan du aktivera och avaktivera det fritt utan avgifter om inga tillägg är aktiva i slutet.",
  "automations.addAddonsDescirptionForApprover": "Endast användare på administratörsnivå har möjlighet att köpa eller avsluta prenumerationer på tillägg. Om du behöver justera dina tilläggsprenumerationer, vänligen kontakta din administratör.",
  "automations.addFreeAddonsDescirptionForAdmin": "Detta tillägg ingår utan extra kostnad i din nuvarande plan. Du kan fritt aktivera och avaktivera det under din nuvarande planprenumeration utan några extra avgifter.",
  "automations.addpnSuccessfullyAdded": "Slutfört - Aktivering",
  "automations.addpnSuccessfullyAddedDescription": "Automatiseringen har aktiverats framgångsrikt. Du kan nu använda alla dess funktioner.",
  "automations.addpnSuccessfullyDeactivated": "Slutfört - Avaktivering",
  "automations.addpnSuccessfullyDeactivatedDescription": "Automatiseringen har avaktiverats framgångsrikt. Den kommer inte längre att vara aktiv i ditt system.",
  "automations.ADP_INTEGRATION": "ADP-integration",
  "automations.anythingMissing": "Saknas något?",
  "automations.automationBlackoutPeriodDeleted": "Spärperiod borttagen",
  "automations.automationEntitlementByRoleDeleted": "Berättigande efter roll borttaget",
  "automations.automationProbationPeriodDeleted": "Provanställningsperiod borttagen",
  "automations.automationSeniorityEntitlementDeleted": "Senioritetberättigande borttaget",
  "automations.automationType": "Automatiseringstyp",
  "automations.autoRejectResponse": "Automatiskt avvisningssvar",
  "automations.BAMBOOHR_INTEGRATION": "BambooHR-integration",
  "automations.BLACKOUT_PERIOD": "Spärperiod",
  "automations.blackoutPeriod.autoRejectReasonDefault": "Under vår nuvarande period med hög belastning är hela vårt team viktigt för att upprätthålla verksamheten. Tyvärr kan vi inte godkänna ledighet eftersom det skulle påverka våra affärsmål och åtaganden gentemot kunder.",
  "automations.blackoutPeriod.createInProgress": "Skapar spärperiod pågår",
  "automations.blackoutPeriod.createInProgressCompleted": "Skapande av spärperiod slutfört",
  "automations.blackoutPeriod.updateInProgress": "Uppdatering av spärperiod pågår",
  "automations.blackoutPeriod.updateInProgressCompleted": "Uppdatering av spärperiod slutförd",
  "automations.blackoutPeriodFromTo": "Spärperiod {fromDate} <endDate>till {toDate}</endDate>",
  "automations.cantOpenAutomations": "Denna automatisering skapades av en annan användare och du kan inte redigera den.",
  "automations.createAutomation": "Skapa automatisering",
  "automations.createPolicy": "Skapa policy",
  "automations.datePeriod": "{fromDate} <endDate>och {toDate}</endDate><recurring> varje år</recurring>",
  "automations.deactivateInPgoress": "Pågår - Avaktivering",
  "automations.deactivateInPgoressDescription": "Din begäran om att avaktivera automatiseringen behandlas nu. Vänligen vänta medan vi uppdaterar dina inställningar",
  "automations.deactivateVisibilityWarn": "Att inaktivera en automatisering avbryter inte prenumerationen. Om du vill stänga av prenumerationen måste du klicka på knappen \"Avsluta prenumeration\".",
  "automations.deleteInProgress": "Raderar automatiseringen {automationName}",
  "automations.departmentVisibilityLevelInfo": "Användare kommer att se ledighetsinformation endast inom sin egen avdelning. Godkännare kommer att se ledighetsinformation inom sin egen avdelning och alla avdelningar de är godkännare för, även om de inte är medlemmar. Administratörer kommer alltid att se allt.",
  "automations.discoveryMoreAddons": "Upptäck fler tillägg",
  "automations.EARLY_EMPLOYMENT_LEAVE_RESTRICTION": "Begränsning av ledighet i tidig anställning",
  "automations.emptyViewButton": "Se tillgängliga tillägg",
  "automations.emptyViewMessage": "Lägg till PTO-dagar baserat på senioritet, skapa spärperioder, automatisera godkännande- eller avslåprocessen, och mycket mer med våra automatiseringar, tillägg och integrationer.",
  "automations.emptyViewTitle": "Automatisera dina tidskrävande uppgifter och integrera med externa system",
  "automations.ENTITLEMENT_BY_ROLE": "Berättigande efter roll",
  "automations.entitlementByRole.createInProgress": "Skapar berättigande efter roll pågår",
  "automations.entitlementByRole.createInProgressCompleted": "Skapande av berättigande efter roll slutfört",
  "automations.entitlementByRole.updateInProgress": "Uppdatering av berättigande efter roll pågår",
  "automations.entitlementByRole.updateInProgressCompleted": "Uppdatering av berättigande efter roll slutförd",
  "automations.filterDescription": "Du kan välja om du vill tillämpa denna automatisering universellt för alla användare eller anpassa den baserat på specifika avdelningar, platser eller etiketter.",
  "automations.LEAVE_DURATION_LIMIT": "Gräns för ledighetsperiod",
  "automations.leaveDurationLimit.autoRejectDescription": "Systemet kommer automatiskt att tillämpa dessa gränser när användare skickar in ledighetsansökningar och ge en tydlig förklaring om deras förfrågan inte uppfyller de uppsatta reglerna.",
  "automations.leaveDurationLimit.autoRejectResponsePlaceholder": "Din ledighetsansökan kunde inte godkännas eftersom den inte uppfyller den definierade policyn för ledighetsperioder. Vänligen granska kraven för ledighetsperioder och justera din ansökan därefter. För ytterligare hjälp, kontakta din administratör.",
  "automations.leaveDurationLimit.createInProgress": "Skapar policy för gräns av ledighetsperiod",
  "automations.leaveDurationLimit.createInProgressCompleted": "Gräns för ledighetsperiod skapad",
  "automations.leaveDurationLimit.deleted": "Gräns för ledighetsperiod borttagen",
  "automations.leaveDurationLimit.duration": "Min {minimumDuration, select, null {Ej satt} undefined {Ej satt} other {{minimumDuration} {units}}} / Max {maximumDuration, select, null {Ej satt} undefined {Ej satt} other {{maximumDuration} {units}}}",
  "automations.leaveDurationLimit.durationDescription": "Ange önskat intervall för ledighetsperioder (i dagar eller timmar) för att säkerställa att ledighetsansökningar överensstämmer med företagets policyer och operativa krav.",
  "automations.leaveDurationLimit.policyDescription": "Detta avsnitt låter dig definiera minimi- och/eller maximigränser för ledighetsperioder för varje policy.",
  "automations.leaveDurationLimit.summary": "Denna policy tillämpar de minimi- och maximigränser för ledighetsperioder som är satta för {leaveTypes} ledighetstyper. Dessa gränser gäller från {startDate} till {endDate}. Denna regel tillämpas på {allUsers, plural, =1 {specifika platser / avdelningar / etiketter} other {alla användare}}. Ledighetsansökningar som faller utanför detta intervall kommer automatiskt att avslås med det angivna meddelandet.",
  "automations.leaveDurationLimit.updateInProgress": "Uppdaterar policy för gräns av ledighetsperiod",
  "automations.leaveDurationLimit.updateInProgressCompleted": "Gräns för ledighetsperiod uppdaterad",
  "automations.leaveDurationLimitEmptyViewTitle": "Ställ in och tillämpa minimi- och maximigränser för ledighetsperioder för användare inom en avdelning, plats eller någon grupp definierad av en etikett.",
  "automations.leaveTypesDescription": "Du kan också välja en eller flera ledighetstyper som du vill tillämpa denna automatisering på.",
  "automations.MAXIMUM_USERS_AWAY": "Maximalt antal frånvarande användare",
  "automations.maximumUsersAway.autoRejectDescription": "Användare kommer att få ett automatiskt meddelande som förklarar varför deras ledighetsansökan avslogs.",
  "automations.maximumUsersAway.createInProgress": "Skapar policy för maximalt antal frånvarande användare",
  "automations.maximumUsersAway.createInProgressCompleted": "Skapande av maximalt antal frånvarande användare slutfört",
  "automations.maximumUsersAway.definePolicyDescription": "Detta avsnitt låter dig ställa in en policy som begränsar antalet användare som kan vara lediga samtidigt.",
  "automations.maximumUsersAway.maxUsersDescription": "Ange det maximala antalet användare som tillåts ta ledigt samtidigt, och vi kommer att se till att systemet automatiskt begränsar förfrågningar som överstiger denna gräns.",
  "automations.maximumUsersAway.updateInProgress": "Uppdaterar policy för maximalt antal frånvarande användare",
  "automations.maximumUsersAway.updateInProgressCompleted": "Uppdatering av maximalt antal frånvarande användare slutförd",
  "automations.maximumUsersAwayEmptyViewTitle": "Kontrollera procentandelen användare som kan vara lediga samtidigt inom en avdelning, plats eller någon grupp definierad av en etikett.",
  "automations.maxUsersAway.percentage": "{value}%",
  "automations.maxUsersAway.plural": "{value} användare",
  "automations.maxUsersAway.singular": "{value} användare",
  "automations.maxUsersAwayType": "Begränsa efter",
  "automations.maxUsersAwayTypeAbsolute": "Antal",
  "automations.maxUsersAwayTypePercentage": "Procent",
  "automations.maxUsersAwayValue": "Maximalt antal frånvarande användare",
  "automations.message": "Meddelande",
  "automations.messagePlaceholder": "Ditt meddelande",
  "automations.myAddons": "Mina tillägg",
  "automations.OPEN_API": "Öppet API",
  "automations.OPEN_API_DOCUMENTATION": "Dokumentation för öppet API",
  "automations.period": "Period",
  "automations.PROBATION_PERIOD": "Provanställningsperiod",
  "automations.probationPeriod.autoRejectReasonDefault": "Din ledighetsansökan har automatiskt avslagits eftersom du är i din tidiga anställningsfas, en period då ledighet inte är tillåten. Denna policy hjälper nyanställda att helt fördjupa sig och anpassa sig till sina nya roller och teamdynamik.",
  "automations.probationPeriod.createInProgress": "Skapar provanställningsperiod pågår",
  "automations.probationPeriod.createInProgressCompleted": "Skapande av provanställningsperiod slutfört",
  "automations.probationPeriod.lengthDays": "Längd (dagar)",
  "automations.probationPeriod.name": "Namn på provanställningspolicy",
  "automations.probationPeriod.namePlaceholder": "Ange namn på provanställningspolicy",
  "automations.probationPeriod.settings.applyToMDescription": "Du kan välja om du vill tillämpa denna automation universellt för alla användare eller anpassa den baserat på specifika avdelningar, platser eller etiketter.",
  "automations.probationPeriod.settings.applyToMDescriptionTwo": "Du kan också välja en eller flera ledighetstyper som du vill tillämpa denna automation på.",
  "automations.probationPeriod.settings.lengthDaysDescription": "Ange antalet dagar under vilka nya anställda kommer att begränsas från att ta specifika typer av ledighet, räknat från deras anställningsstartdatum.",
  "automations.probationPeriod.settings.nameDescription": "Detta avsnitt låter dig ställa in en policy som begränsar nyanställda från att ta ledighet under deras inledande anställningsperiod.",
  "automations.probationPeriod.settings.nameDescriptionThree": "Se till att användare får ett automatiskt meddelande som förklarar varför deras ledighetsansökan avslogs.",
  "automations.probationPeriod.settings.nameDescriptionTwo": "Ange antalet dagar, så beräknar vi provanställningsperioden baserat på varje användares anställningsdatum.",
  "automations.probationPeriod.settings.nameTitle": "Definiera policy",
  "automations.probationPeriod.updateInProgress": "Uppdatering av provanställningsperiod pågår",
  "automations.probationPeriod.updateInProgressCompleted": "Uppdatering av provanställningsperiod slutförd",
  "automations.probationPeriodMessages": "Din provanställningsperiod varar till {date}",
  "automations.removeAddons": "Ta bort tillägg",
  "automations.removeAddonsDescirptionForAdmin": "Är du säker på att du vill avsluta prenumerationen på detta tillägg? Du behåller tillgång till alla dess funktioner fram till slutet av din nuvarande faktureringsperiod. Efter det kommer det att avaktiveras, vilket påverkar din ledighetshanterings-setup i enlighet med detta.",
  "automations.removeAddonsDescirptionForApprover": "Endast användare på administratörsnivå har möjlighet att köpa eller avsluta prenumerationer på tillägg. Om justeringar av dina tilläggsprenumerationer behövs, vänligen kontakta din administratör.",
  "automations.requestAnAutomation": "Begär ett tillägg",
  "automations.requestAutomationTitle": "Kan du inte hitta ett tillägg du behöver?",
  "automations.reSubscribeAddons": "Återaktivera tillägg",
  "automations.reSubscribeAddonsDescirptionForAdmin": "Är du säker på att du vill återaktivera detta tillägg? Du kommer att faktureras ${addonsPrice} per användare per månad från och med din nästa faktura. Den första månadens avgift kommer att beräknas proportionellt baserat på antalet dagar tillägget används.",
  "automations.reSubscribeAddonsDescirptionForAdminInTrial": "Är du säker på att du vill återaktivera detta tillägg? Det förblir gratis till slutet av testperioden. Efter det kommer en avgift på ${addonsPrice} per användare per månad att tillämpas.",
  "automations.reSubscribeInfo": "Förnya din tilläggsprenumeration och fortsätt använda våra avancerade funktioner. Klicka på Återaktivera för att fortsätta.",
  "automations.SENIORITY_ENTITLEMENT": "Senioritetsbehörighet",
  "automations.seniorityEntitlement.createInProgress": "Skapar senioritetsbehörighet pågår",
  "automations.seniorityEntitlement.createInProgressCompleted": "Skapande av senioritetsbehörighet slutfört",
  "automations.seniorityEntitlement.periodsViewTableDays": "{amount} {amount, plural, =1 {dag} other {dagar}} efter {years} år",
  "automations.seniorityEntitlement.periodsViewTableHours": "{amount} {amount, plural, =1 {timme} other {timmar}} efter {years} år",
  "automations.seniorityEntitlement.updateInProgress": "Uppdatering av senioritetsbehörighet pågår",
  "automations.seniorityEntitlement.updateInProgressCompleted": "Uppdatering av senioritetsbehörighet slutförd",
  "automations.showCaneledAddons": "Visa avbrutna tillägg",
  "automations.stepByStepHelpDesk": "För detaljerad vägledning om hur du ställer in din <automationName></automationName> Automation, vänligen se steg-för-steg-instruktionerna tillgängliga på <link>hjälpcenterssidan</link>.",
  "automations.subscripteToAddons": "Prenumerera på ett tillägg",
  "automations.TEAM_LEAVE_BALANCING": "Maximalt antal frånvarande användare",
  "automations.unsubscribeInfo": "Du prenumererar för närvarande på detta tillägg för ${addonsPrice} per användare. Om du vill avsluta prenumerationen, klicka på avsluta prenumeration-knappen till höger.",
  "automations.unsubscripteAddons": "Avsluta prenumeration på tillägg?",
  "automations.update": "Uppdatera automationer",
  "automations.VISIBILITY": "Visibilitetshanterare",
  "automations.visibility.createInProgress": "Begränsar synlighet pågår",
  "automations.visibility.createInProgressCompleted": "Begränsning av synlighet slutförd",
  "automations.visibility.updateInProgress": "Uppdaterar synlighets-tillägg",
  "automations.visibility.updateInProgressCompleted": "Uppdatering av synlighets-tillägg slutförd",
  "automations.visibilityForm.DEPARTMENT": "Deras avdelning",
  "automations.visibilityForm.limitVisibility": "Begränsa synlighet för användare",
  "automations.visibilityForm.visibilityLevel": "Användare kan se",
  "automations.visibilityForm.visibilityTypeValidationRequired": "Du måste välja minst en synlighetstyp",
  "automations.visibilityInfo.settings.nameTitle": "Synlighetsinställningar",
  "automations.visibilityManagerNotificationsInfo": "Delade kalendrar och notiser som skapats innan ändringen av synlighetsinställningarna måste raderas manuellt om de inte följer de nya reglerna.",
  "automationsForm.advancedFilter.tooltipInfo": "Du kan filtrera användarna som denna automation kommer att tillämpas på baserat på avdelningar, platser eller etiketter.",
  "automationsForm.applyToAllUsers": "Tillämpa på alla användare?",
  "automationsForm.applyToAllUsersTooltip": "Om inställd på Ja kommer ledighetsgränsinställningarna att gälla för hela organisationen. Om inställd på Nej kan du specificera gränsen för specifika avdelningar, platser eller grupper för mer riktad hantering.",
  "automationsForm.autoRejectReason": "Automatiskt avvisningssvar",
  "automationsForm.autoRejectReasonTooltip": "Ge användaren ett automatiskt svar om varför deras ledighetsansökan har avvisats under denna period.",
  "automationsForm.autoRejectResponseRequired": "Automatiskt avvisningssvar krävs",
  "automationsForm.blackoutPeriodShortText": "En tidsperiod då anställda är förbjudna att ta ledigt",
  "automationsForm.maximumLeaveDuration": "Maximal ledighetslängd {units}",
  "automationsForm.maxmimumUsersAway.autoRejectResponsePlaceholder": "Tyvärr har din ledighetsansökan avslagits. Vi har nått vår maximalt tillåtna kapacitet för teamledighet under denna period, enligt vår policy för procentgräns för teamledighet.",
  "automationsForm.maxmimumUsersAway.maxUsersAwayTypeTooltip": "Välj hur du vill definiera det maximalt tillåtna antalet frånvarande. Välj 'Procent' för att ange procentandelen användare som kan vara frånvarande samtidigt, eller välj 'Antal' för att ange det exakta antalet användare som tillåts vara frånvarande samtidigt.",
  "automationsForm.maxmimumUsersAway.maxUsersAwayValueAbsoluteError": "Du angav {entered} användare. Det maximala antalet användare kan inte överstiga {max}. Vänligen ange ett lägre värde.",
  "automationsForm.maxmimumUsersAway.maxUsersAwayValuePercentageError": "Du angav {entered}%. Procentsatsen kan inte överstiga 90%. Vänligen ange ett lägre värde.",
  "automationsForm.maxmimumUsersAway.nameRequired": "Namnet är obligatoriskt",
  "automationsForm.maxUsersAwayValueRequired": "Värde för maximalt antal frånvarande användare krävs",
  "automationsForm.minimumLeaveDuration": "Minsta ledighetslängd {units}",
  "automationsForm.period": "Period",
  "automationsForm.periodRequired": "Period krävs",
  "automationsForm.policy.name": "Ange policynamn",
  "automationsForm.recurring": "Återkom årligen",
  "automationsForm.recurringTooltip": "Aktivera detta alternativ för att ledighetsgränsinställningarna automatiskt ska förnyas varje år, vilket säkerställer konsekvent tillämpning av dessa parametrar årligen utan manuell omkonfigurering.",
  "automationsForm.seniorityEntitlement.applyToAllUsersTooltip": "Vill du tillämpa denna anställningstidsberättigande på alla användare i ditt företag eller användare på en specifik plats? Om du har olika regler för anställningstidsberättigande på olika platser måste du skapa flera automationsregler för anställningstidsberättigande, en för varje plats.",
  "automationsForm.seniorityEntitlement.beginningOfTheFiscalYear": "Början av räkenskapsåret",
  "automationsForm.seniorityEntitlement.employeeStartDate": "Anställdas startdatum",
  "automationsForm.seniorityEntitlement.endOfTheFiscalYear": "Slutet av räkenskapsåret",
  "billing.applyingCoupon": "Tillämpar kupong",
  "billing.applyingCouponAndResubscribing": "Tillämpar kupong & Återprenumererar",
  "billing.cancelSubscription": "Avbryt prenumeration",
  "billing.cancelTrial": "Din provperiod kommer att förbli aktiv till {stripeCurrentPeriodEnd}. För att fortsätta använda Vacation Tracker, vänligen ange din faktureringsinformation.",
  "billing.cancelTrialButton": "Avbryt provperiod",
  "billing.cancelUpdateInProgress": "Avbryter...",
  "billing.linkPaymentMethod": "Stripe-länk",
  "billing.microsoft.billedLabel": "Fakturerad",
  "billing.microsoft.billingCycle": "Nuvarande faktureringsperiod slutar den {date}",
  "billing.microsoft.billingCycleEnds": "Ditt abonnemang kommer att avslutas efter att faktureringsperioden är slut och du kommer inte längre att kunna använda Vacation Tracker.",
  "billing.microsoft.billingCycleLabel": "Faktureringsperiod",
  "billing.microsoft.buyLessLicensesConfirmModalContent": "Du har {currentNoOfLicenses} licenser. Du är på väg att ta bort {additionalLicences}. Är du säker?",
  "billing.microsoft.buyMoreLicensesConfirmModalContent": "Du har {currentNoOfLicenses} licenser. Du är på väg att köpa {additionalLicences} fler. Är du säker?",
  "billing.microsoft.canceledSubscriptionMessage": "Ditt abonnemang kommer att avslutas om ~15 minuter",
  "billing.microsoft.changePlanModalTitle": "Ändra nuvarande <strong>{plan}</strong> plan",
  "billing.microsoft.info": "Din betalningsinformation hanteras av Microsoft. Besök <adminCenterLink>Administratörscenter</adminCenterLink> för att uppdatera din {labelInfo}",
  "billing.microsoft.licensesSideActionLabel": "Visa historik",
  "billing.microsoft.licensesTitle": "Licenser",
  "billing.microsoft.pricingPlanLabel": "Prisplan",
  "billing.microsoft.switchPlanModal.confirm": "Vänligen bekräfta att du vill byta till denna plan.",
  "billing.paymentInformation": "Betalningsinformation",
  "billing.paymentMethod": "Betalningsmetod",
  "billing.pricingPlanPeriodAnnual": "Årlig",
  "billing.pricingPlanPeriodMonthly": "Månadsvis",
  "billing.pricingPlansText": "Detta avsnitt visar din abonnemangsplan. Du kan återaktivera den plan du hade tidigare eller ändra planen innan du återaktiverar.",
  "billing.pricingPlansTitle": "Prisplaner",
  "billing.promoCodeErrorDescription": "Kampanjkoden {code} är inte giltig.",
  "billing.resubscribeCTA": "Återaktivera",
  "billing.resubscribingUpdateInProgress": "Återaktiverar...",
  "billing.seats": "Platser",
  "billing.seats.buyLessSeatsConfirmModalContent": "Du har {currentNoOfSeats} platser. Du är på väg att ta bort {additionalSeats}.",
  "billing.seats.buyMoreSeatsConfirmModalContent": "Du har {currentNoOfSeats} platser. Du är på väg att köpa {additionalSeats} fler.",
  "billing.seats.canNotDowngrade": "Du kan inte nedgradera ditt antal platser ({currentNumberOfSeats}) utan att avaktivera en eller flera för närvarande aktiva användare.",
  "billing.seats.info": "Antalet platser du har köpt.",
  "billing.seats.loadingUpcomingPaymentInformation": "Laddar kommande betalningsinformation...",
  "billing.seats.manage": "Hantera platser",
  "billing.seats.noBillingChange": "Du är på väg att köpa {noOfSeats} {noOfSeats, plural, =1 {plats} other {platser}}. Din fakturering kommer inte att påverkas.",
  "billing.seats.warningOfProrateAdditional": "En beräknad proportionell avgift på {proratedAmount} (exklusive moms) kommer att tillämpas idag.",
  "billing.seats.warningOfSeatsAnnualProrateAdditionalNewBucket": "Att lägga till {noOfSeats} {noOfSeats, plural, =1 {plats} other {platser}} kommer att öka din nästa faktura till {amount} (exklusive moms) från och med {startDate}.",
  "billing.seats.warningOfSeatsProrateAdditionalNewBucketMonthly": "Att köpa {noOfSeats} {noOfSeats, plural, =1 {plats} other {platser}} kommer att påverka din fakturering. Din nästa faktura blir {amount} (exklusive moms) den {startDate}",
  "billing.seats.warningOfSeatsRemovingAnnualProrateAdditionalNewBucket": "Att ta bort {noOfSeats} {noOfSeats, plural, =1 {plats} other {platser}} kommer att minska din nästa faktura till {amount} (exklusive moms) från och med {startDate}.",
  "billing.seats.warningOfSeatsRemovingProrateAdditionalNewBucketMonthly": "Att ta bort {noOfSeats} {noOfSeats, plural, =1 {plats} other {platser}} kommer att påverka din fakturering. Din nästa faktura blir {amount} (exklusive moms) den {startDate}",
  "billing.subscriptionCanceled": "Abonnemang avbrutet",
  "billing.subscriptionExpired": "Abonnemang inte aktivt",
  "billing.subscriptionExpiredDescription": "Ditt abonnemang är avbrutet eller har löpt ut. Uppdateringar är inte möjliga för tillfället.",
  "billing.subscriptionExpiredText": "Ditt abonnemang har löpt ut. Återaktivera för att fortsätta använda Vacation Tracker. Om du har några problem eller frågor, kontakta vår {supportLink}.",
  "billing.SubscriptionPlan": "Abonnemangsplan",
  "billing.subscriptionUpdateInProgress": "Prenumerationsuppdatering pågår",
  "billing.subscriptionUpdateInProgressDescription": "Den föregående åtgärden pågår fortfarande. Vänligen försök igen om en minut.",
  "billing.updateBillingInfo": "Uppdatera faktureringsinformation",
  "billing.updatedFaild": "Uppdatering av faktureringsinformation misslyckades",
  "billing.updatedSuccessfully": "Faktureringsinformation har uppdaterats",
  "billing.updateInProgress": "Uppdaterar faktureringsinformation",
  "bot.announce.slack": "Hej <@{slackUserId}>\n\nJag heter Vacation Tracker. Jag hjälper dig att boka ledighet via Slack och håller alla informerade om kommande ledigheter i din organisation.\n\nSkriv `/vacation` var som helst i Slack för att komma igång eller kolla in min YouTube-handledning för att lära dig mer:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.announce.slack.info": "Hej <@{slackUserId}>, jag heter Vacation Tracker.",
  "bot.announce.slackCompany": "Hej team,\n\nJag heter Vacation Tracker Bot!\n\nJag låter dig boka din ledighet via Slack och håller alla informerade om teammedlemmarnas kommande ledigheter. Låt oss börja spåra lite ledighet!\n\nSkriv `/vacation` var som helst i Slack eller kolla in YouTube-handledningen för att lära dig mer:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.message.about.commandDescription": "Vacation Tracker förenklar din organisations process för att begära och boka ledighet. Teammedlemmar kan boka vilken typ av ledighet som helst, eller någon form av betald/obetald ledighet, utan att lämna Slack och utan att behöva komma ihåg ytterligare ett användarnamn eller lösenord.\n\nSkapa anpassade ledighetstyper och låt varje individ i din organisation ha kontroll över sin ledighet. Vacation Tracker ger varje teammedlem tillgång till Dashboarden (http://app.vacationtracker.io) där de kan begära ledighet, kontrollera sin nuvarande ledighetsstatus eller historik, och se schemalagda ledigheter för hela teamet.\n\nEnligt administratörens eller godkännarens inställningar kommer teammedlemmar att få dagliga/veckovisa meddelanden om kommande frånvaro. Ingen krångel och inga obehagliga överraskningar.\n\nVill du veta mer om Vacation Tracker? Vårt <https://vacationtracker.crisp.help/en/|Helpdesk> är ditt näst bästa alternativ efter <https://vacationtracker.io/request-a-demo/|demon>.\n\nOm dina frågor eller funderingar förblir obesvarade, kontakta oss när som helst. Att prata med våra kunder är den bästa delen av vår dag: hello@vacationtracker.io",
  "bot.message.accessExpired": "Åh nej! Din åtkomst till Vacation Tracker har gått ut. Kontakta din administratör för mer information.",
  "bot.message.accruedDaysInfoForApprover": "ℹ️ Denna ledighetsbegäran baseras på framtida intjänade lediga dagar, inte den nuvarande mängden. <@{name}> har {amount} dag(ar) kvar idag. De kommer att tjäna in {accrued} den {earningDate}.",
  "bot.message.accruedDaysInfoForUser": "ℹ️ Din ledighetsbegäran baseras på framtida intjänade lediga dagar, inte den nuvarande mängden. Du har {amount} dag(ar) kvar idag, och du kommer att tjäna in {accrued} den {earningDate}.",
  "bot.message.approveLeave": "Godkänn ledighet",
  "bot.message.approveLeaves.approvedRequest": "✅ Du har godkänt begäran. Ett meddelande kommer att skickas till <@{userSlackId}> för att informera dem.",
  "bot.message.approveLeaves.approvedRequest.firstLevel": "✅ Du har godkänt den första nivån av begäran, ingen ytterligare åtgärd krävs i detta skede. Ledighetsansökan har skickats vidare till den andra godkännandenivån.",
  "bot.message.approveLeaves.deniedRequest": "❌ Du har nekat begäran. Ett meddelande kommer att skickas till <@{userSlackId}> för att informera dem.",
  "bot.message.approver": "*Godkännare:*\n<@{approverSlackId}>",
  "bot.message.approver.autoApproved": "*Godkännare:*\nAutomatiskt godkänd",
  "bot.message.approveToil": "Godkänn TOIL",
  "bot.message.backToOptions": "Tillbaka till alternativ",
  "bot.message.blackoutPeriod": "*Avstängningsperiod*: {startDate} - {endDate} \n \n_{message}_",
  "bot.message.couldNotProcessRequest": "Oj då! Vi kunde inte behandla din begäran.",
  "bot.message.date": "*Datum*",
  "bot.message.dateEdited": "*Datum [Redigerat]*",
  "bot.message.dates": "*Datum*",
  "bot.message.datesEdited": "*Datum [Redigerade]*",
  "bot.message.deniedLeave": "Nekad ledighet",
  "bot.message.deniedLeaveDate": "*Datum*\n{leaveDate}",
  "bot.message.deniedLeaveDates": "*Datum*\n{leaveDate}",
  "bot.message.deniedLeaveLeaveType": "*Ledighetstyp:*\n{leaveTypeName}",
  "bot.message.deniedLeaveWithReason": "Hej, <@{userSlackId}> har skickat in följande ledighetsbegäran:",
  "bot.message.deniedToil": "Nekad TOIL",
  "bot.message.error.endDateCantBeInPast": "Slutdatumet kan inte vara före start, vänligen välj ett datum i framtiden",
  "bot.message.error.endTimeCantBeBeforeStartTime": "Det ser ut som att starttiden du har valt är efter sluttiden.*\n *Vänligen välj en starttid som är före sluttiden.",
  "bot.message.error.leaveRequestOverlap": "Datumen du har valt överlappar med en befintlig ledighet.*\n *Vänligen välj en annan tidsperiod.",
  "bot.message.error.negativeBallanceNotAllowed": "Negativt saldo är inte tillåtet för denna ledighetstyp.",
  "bot.message.error.oneHoureIntervalNotAllowed": "Det kortaste tidsintervallet du kan begära för denna ledighetstyp är 4 timmar (halv dag).",
  "bot.message.error.partTimeIsntAllowed": "Deltid är inte tillåtet",
  "bot.message.error.reasonRequired": "Anledningsfältet är obligatoriskt.",
  "bot.message.error.requestedMoreThanFullDay": "Du har begärt mer än en hel dags ledighet på en arbetsdag.*\n *Vänligen välj en annan dag.",
  "bot.message.error.startDateCantBeInPast": "Startdatumet kan inte vara i det förflutna, vänligen välj ett datum i framtiden",
  "bot.message.error.userCannotBeFound": "Vald användare kan inte hittas i din organisation.*\n *Vänligen kontakta din organisations administratör eller Vacation Trackers support för mer information.",
  "bot.message.error.userEndDateInFuture": "De begärda datumen är efter användarens anställnings slutdatum.*\n *Vänligen kontakta din organisations administratör eller Vacation Trackers support för mer information.",
  "bot.message.error.userStartDateInFuture": "De begärda datumen är före användarens anställnings startdatum. Vänligen kontakta din organisations administratör eller Vacation Trackers support för mer information.",
  "bot.message.error.zeroDaysLeaveRequest": "Du har valt en helgdag eller en arbetsbefriad dag.*\n *Vänligen välj ett giltigt datumintervall.",
  "bot.message.feedback.chooseAnOption": "Välj ett alternativ...",
  "bot.message.feedback.form": "Feedback-formulär",
  "bot.message.feedback.haveBeenSend": "Feedback har skickats",
  "bot.message.feedback.howDoYouLikeOurApp": "Hur tycker du om vår app?",
  "bot.message.feedback.info": "Ge oss feedback :heart:",
  "bot.message.feedback.itMakesMeCry": "Den får mig att gråta 😢",
  "bot.message.feedback.itsOk": "Den är okej 😐",
  "bot.message.feedback.likeIt": "Jag gillar den 😀",
  "bot.message.feedback.loveIt": "Jag älskar den 😍",
  "bot.message.feedback.message": "Feedback-meddelande",
  "bot.message.feedback.nah": "Nä 🙁",
  "bot.message.feedback.response": "Om din feedback kräver ett svar, vänligen ange din e-postadress så att vi kan kontakta dig.",
  "bot.message.feedback.thanks": "Tack för din feedback! Du kan alltid kontakta oss via support@vacationtracker.io.",
  "bot.message.feedback.youEmailAddress": "Din e-postadress",
  "bot.message.genericError": "Oj då! Ett oväntat fel uppstod. 😱 Om detta problem kvarstår, vänligen kontakta support och ge dem följande felkod: {requestId}.",
  "bot.message.goToTheMyProfileTab": "Gå till fliken Min profil",
  "bot.message.helpMessage": "Hej, \nMed Vacation Tracker kan du göra något av följande:\n- Skicka **Begär ledighet** i ett direktmeddelande för att begära ny ledighet med hjälp av Microsoft Teams-boten.\n- Skicka **Begär toil** i ett direktmeddelande för att begära ny TOIL med hjälp av Microsoft Teams-boten.\n- Besök fliken **Min profil** för att se din personliga information, såsom dina kommande ledigheter och helgdagar, samt din ledighetshistorik.\n- Fäst fliken **Översikt** i en av dina kanaler för att se teamöversikten, inklusive kommande ledigheter och helgdagar för ditt team.\n- Besök [**Vacation Tracker Web Dashboard ⧉**]({dashboardLink}/connect?platform=microsoft&redirect=/app/dashboard).\nFör mer hjälp och handledningar, besök vår [**Helpdesk ⧉**](https://vacationtracker.crisp.help/en/). Du kan också boka en personlig demo av Vacation Tracker via denna länk: [https://vacationtracker.io/request-a-demo](https://vacationtracker.io/request-a-demo).",
  "bot.message.holidays": "*Helgdagar:*",
  "bot.message.leaveDate": "*Datum:*\n{leaveDate}",
  "bot.message.leaveDateEdited": "*Datum [Redigerat]:*\n{leaveDate}",
  "bot.message.leaveDates": "*Datum:*\n{leaveDate}",
  "bot.message.leaveDatesEdited": "*Datum [Redigerade]:*\n{leaveDate}",
  "bot.message.leaveDuration1": "*{word} begärt för innevarande år:*\n{requestedThisYearNumber}",
  "bot.message.leaveDuration2": "*{word} kvar för innevarande år:*\n{currentYearNumber}",
  "bot.message.leaveDuration2Unlimited": "*{word} kvar för innevarande år:*\nObegränsat",
  "bot.message.leaveDuration3": "*{word} begärt för nästa år:*\n{requestedNextYearNumber}",
  "bot.message.leaveDuration4": "*{word} kvar för nästa år:*\n{nextYearNumber}",
  "bot.message.leaveDuration4Unlimited": "*{word} kvar för nästa år:*\nObegränsat",
  "bot.message.leaveRemaining": "Kvarvarande ledighet",
  "bot.message.leaveRemainingCurrent": "Kvarvarande ledighet (Innevarande år)",
  "bot.message.leaveRemainingDays": "Kvarvarande ledighetsdagar",
  "bot.message.leaveRemainingDaysCurrent": "Kvarvarande ledighetsdagar (Innevarande år)",
  "bot.message.leaveRemainingDaysNext": "Kvarvarande ledighetsdagar (Följande år)",
  "bot.message.leaveRemainingHours": "Kvarvarande ledighetsttimmar",
  "bot.message.leaveRemainingHoursNext": "Kvarvarande ledighetstimmar (Följande år)",
  "bot.message.leaveRemainingNext": "Kvarstående ledighet (Kommande år)",
  "bot.message.leaveRequest.FULL-DAY": "{leaveTypeName}",
  "bot.message.leaveRequest.HALF-DAY": "{leaveTypeName} (Halv dag)",
  "bot.message.leaveRequest.halfDay": "(Halv dag)",
  "bot.message.leaveRequest.hourly": "(Timvis)",
  "bot.message.leaveRequest.leaveTypeName": "{leaveTypeName}",
  "bot.message.leaveRequest.leaveTypeNamePartTime": "{leaveTypeName} (Timvis)",
  "bot.message.leaveRequest.ONE-HOUR": "{leaveTypeName} (Timvis)",
  "bot.message.leaveRequestCreated.error": "Ledighetsansökan kan inte skickas in",
  "bot.message.leaveRequestCreated.errorBadTime": "❌ Ledighetsansökan kan inte skickas in eftersom den är längre än arbetstiden, eller tidsintervallet är ogiltigt.",
  "bot.message.leaveRequestCreated.errorDateAfter": "❌ Ledighetsansökan kan inte skickas in eftersom startdatumet du har valt är efter slutdatumet. Se till att startdatumet är före slutdatumet och skicka in ansökan igen.",
  "bot.message.leaveRequestCreated.errorDateInThePast": "❌ Ledighetsansökan kan inte skickas in eftersom startdatumet du har valt är i det förflutna. Se till att datumen du väljer är i framtiden och skicka in ansökan igen.",
  "bot.message.leaveRequestCreated.errorDateNotSelected": "❌ Ledighetsansökan kan inte skickas in eftersom du inte har valt ett slutdatum, eller datumet är ogiltigt.",
  "bot.message.leaveRequestCreated.errorNoStartDate": "❌ Ledighetsansökan kan inte skickas in eftersom du inte har valt ett startdatum, eller datumet är ogiltigt.",
  "bot.message.leaveRequestCreated.errorNoStartTime": "❌ Ledighetsansökan kan inte skickas in eftersom du inte har valt en starttid, eller tiden är ogiltig.",
  "bot.message.leaveRequestCreated.errorReasonRequired": "❌ Ledighetsansökan kan inte skickas in, anledning krävs.",
  "bot.message.leaveRequested": "Begärd ledighet",
  "bot.message.leaveRequestedCurrent": "Begärd ledighet (Innevarande år)",
  "bot.message.leaveRequestedDays": "Begärda ledighetsdagar",
  "bot.message.leaveRequestedDaysCurrent": "Begärda ledighetsdagar (Innevarande år)",
  "bot.message.leaveRequestedDaysNext": "Begärda ledighetsdagar (Kommande år)",
  "bot.message.leaveRequestedHours": "Begärda lediga timmar",
  "bot.message.leaveRequestedHoursCurrent": "Begärda lediga timmar (Innevarande år)",
  "bot.message.leaveRequestedHoursNext": "Begärda lediga timmar (Kommande år)",
  "bot.message.leaveRequestedNext": "Begärd ledighet (Kommande år)",
  "bot.message.leaveRequestNext.endDate": "Slutdatum:",
  "bot.message.leaveRequestNext.info": "Toppen! 🐨 Välj tidsperioden och skicka in ansökan.",
  "bot.message.leaveRequestNext.isHalfDay": "{leaveTypeName} (Halv dag)",
  "bot.message.leaveRequestNext.leaveTime": "Ledighetstid:",
  "bot.message.leaveRequestNext.reasonOptional": "Anledning (Valfritt)",
  "bot.message.leaveRequestNext.reasonRequired": "Anledning (Obligatoriskt)",
  "bot.message.leaveRequestNext.startDate": "Startdatum:",
  "bot.message.leaveRequestNext.title": "Begär ledighet",
  "bot.message.leaves": "*Ledigheter:*",
  "bot.message.leavesHistory": "Din ledighetshistorik:",
  "bot.message.leavesHistory.leaveMessageFullDay": "- Ledighet (*{leaveTypeName}*) från {start} till {end} *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOn": "- Ledighet (*{leaveTypeName}*) den {start} *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOnPartDay": "- Ledighet (*{leaveTypeName}*) den {start} {timeRange} *[{leaveStatus}]*\n",
  "bot.message.leavesHistoryNot": "Hm, det verkar som att du inte har haft några ledigheter än.",
  "bot.message.leaveTypeName": "*Ledighetstyp:*\n{leaveTypeName}",
  "bot.message.leaveTypeWithHalfDay": "*Ledighetstyp:*\n{leaveTypeName} (halvdag)",
  "bot.message.manageLeaves.cancelRequest": "Avbryt begäran",
  "bot.message.manageLeaves.confirmCancellation": "Vänligen bekräfta att du vill avbryta din ledighetsbegäran",
  "bot.message.manageLeaves.summary": "Här är en sammanfattning av dina kommande ledigheter och väntande begäranden.",
  "bot.message.manageLeaves.summaryNoLeaves": "Du har inga kommande schemalagda ledigheter",
  "bot.message.manageLeaves.upcomingLeaves": "*Du har {upcomingLeaves} bekräftad begäran:*",
  "bot.message.manageLeaves.upcomingLeavesPlural": "*Du har {upcomingLeaves} bekräftade begäranden:*",
  "bot.message.manageLeaves.viewMoreOnDashboard": "Du har {remaining} fler ledighetsbegäranden. Se alla ledigheter på instrumentpanelen 👀",
  "bot.message.manageLeaves.waitingLeaves": "*Du har {waitingLeaves} väntande begäran:*",
  "bot.message.manageLeaves.waitingLeavesPlural": "*Du har {waitingLeaves} väntande begäranden:*",
  "bot.message.membersAway": "Frånvarande medlemmar",
  "bot.message.membersAway.holiday": "- *{holidayLocationName}* kommer att vara ledigt för *{holidayName}* den {date}.\n",
  "bot.message.membersAway.leaveMessage": "- *{user}* är ledig{leaveTypeName} idag {partDay}\n",
  "bot.message.membersAway.leaveMessage.fullDay": "- *{user}* är ledig{leaveTypeName} från {startDate} till {endDate}\n",
  "bot.message.membersAway.leaveMessageWasWillBe": "- *{user}* {verb} ledig{leaveTypeName} den {startDate} {partDay}\n",
  "bot.message.membersAway.leaveMessageWasWillBe.fullDay": "- *{user}* {verb} ledig{leaveTypeName} från {startDate} till {endDate}\n",
  "bot.message.membersAway.MEMBERS_AWAY_MONTH": "Teammedlemmar som är borta denna månad.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_MONTH": "Teammedlemmar som är borta nästa månad.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_WEEK": "Teammedlemmar som kommer att vara borta nästa vecka.",
  "bot.message.membersAway.MEMBERS_AWAY_TODAY": "Teammedlemmar som är borta idag.",
  "bot.message.membersAway.MEMBERS_AWAY_WEEK": "Teammedlemmar som är borta denna vecka.",
  "bot.message.membersAway.multiHoliday": "- *{holidayLocationName}* kommer att vara ledigt för *{holidayName}* från {start} till {end}.\n",
  "bot.message.membersAway.noLeaves": "Det finns för närvarande inga schemalagda ledigheter för denna tidsperiod i ditt team 😎.",
  "bot.message.memersAway.noLeaves": "Det finns för närvarande inga schemalagda ledigheter för denna tidsperiod i ditt team 😎.",
  "bot.message.menageLeaves": "Hantera ledigheter",
  "bot.message.moreOptions": "Hej <@{username}>!\nVad kan jag hjälpa dig med?",
  "bot.message.moreOptions.about": "Mer om mig :koala:",
  "bot.message.moreOptions.leaveHistoryTitle": "Ledighetshistorik",
  "bot.message.moreOptions.leavesAndHolidaysTitle": "Dina ledigheter och helgdagar:",
  "bot.message.moreOptions.membersAway": "Se vilka teammedlemmar som är borta:",
  "bot.message.moreOptions.menageLeavesTitle": "Hantera ledigheter",
  "bot.message.moreOptions.showHolidaysTitle": "Visa helgdagar",
  "bot.message.myStatus.currentYear": "_Innevarande år, fram till {yearEnd}_",
  "bot.message.myStatus.leaveTypeStatusEarned": "*{leaveTypeName}*\nÅterstående: {current}\nAnvända: {used}\nTotalt: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedDays": "*{leaveTypeName}*\nÅterstående dagar: {current}\nAnvända dagar: {used}\nTotala dagar: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedHours": "*{leaveTypeName}*\nÅterstående timmar: {current}\nAnvända timmar: {used}\nTotala timmar: {earned}",
  "bot.message.myStatus.leaveTypeStatusUnlimited": "*{leaveTypeName}*\nÅterstående dagar: Obegränsat\nAnvända dagar: {usedDays}\nTotala dagar: Obegränsat",
  "bot.message.myStatus.nextYear": "_Nästa år, från {startYear} till {endYear}_",
  "bot.message.myStatus.summary": "Här är en sammanfattning av dina ledigheter för innevarande och nästa år.",
  "bot.message.noLeavePolicies": "Det verkar som att din plats inte har några tilldelade ledighetspolicyer. 🤔 Kontakta din administratör för mer information.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYear": "Användaren begär {leaveDurationTotal} lediga dagar, vilket är {overLimitCurrentYear} dag över gränsen användaren har för innevarande år, och {overLimitNextYear} dag över gränsen för nästa år.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearDays": "Användaren begär {leaveDurationTotal} {leaveDurationTotal, plural, =1 {dag} other {dagar}}, vilket är {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {dag} other {dagar}} över gränsen användaren har för innevarande år, och {overLimitNextYear} {overLimitNextYear, plural, =1 {dag} other {dagar}} över gränsen för nästa år.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearHours": "Användaren begär {leaveDurationTotal} {leaveDurationTotal, plural, =1 {timme} other {timmar}}, vilket är {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {timme} other {timmar}} över gränsen användaren har för innevarande år, och {overLimitNextYear} {overLimitNextYear, plural, =1 {timme} other {timmar}} över gränsen för nästa år.",
  "bot.message.notification.overAllowedLimitForCurrentYear": "Användaren begär {leaveDurationCurrentYear} ledig dag, vilket är {overLimit} dag över den tillåtna gränsen denna användare har för innevarande år.",
  "bot.message.notification.overAllowedLimitForCurrentYearDays": "Användaren begär {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {dag} other {dagar}}, vilket är {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {dag} other {dagar}} över den tillåtna gränsen denna användare har för innevarande år.",
  "bot.message.notification.overAllowedLimitForCurrentYearHours": "Användaren begär {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {timme} other {timmar}}, vilket är {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {timme} other {timmar}} över den tillåtna gränsen denna användare har för innevarande år.",
  "bot.message.notification.overAllowedLimitForCurrentYearPlural": "Användaren begär {leaveDurationCurrentYear} lediga dagar, vilket är {overLimit} dag över den tillåtna gränsen denna användare har för innevarande år.",
  "bot.message.notification.overAllowedLimitForFollowingYear": "Användaren begär {leaveDurationNextYear} ledig dag, vilket är {overLimit} dag över den tillåtna gränsen denna användare har för nästa år.",
  "bot.message.notification.overAllowedLimitForFollowingYearDays": "Användaren begär {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {dag} other {dagar}}, vilket är {overLimitNextYear} {overLimitNextYear, plural, =1 {dag} other {dagar}} över den tillåtna gränsen denna användare har för nästa år.",
  "bot.message.notification.overAllowedLimitForFollowingYearHours": "Användaren begär {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {timme} other {timmar}}, vilket är {overLimitNextYear} {overLimitNextYear, plural, =1 {timme} other {timmar}} över den tillåtna gränsen denna användare har för nästa år.",
  "bot.message.notification.overAllowedLimitForFollowingYearPlural": "Användaren begär {leaveDurationNextYear} lediga dagar, vilket är {overLimit} dag över den tillåtna gränsen denna användare har för nästa år.",
  "bot.message.notSignedUpError": "Det verkar som att du inte är registrerad för Vacation Tracker. Vänligen navigera till [{link}]({utmLink}) och skapa ett konto först. Det tar mindre än en minut!",
  "bot.message.notSignedUpError.slack": "Det verkar som att du inte är registrerad för Vacation Tracker. Klicka på knappen nedan för att skapa ett konto. Det tar mindre än en minut!",
  "bot.message.on": "På",
  "bot.message.openTheWebDashboard": "Öppna webbinstrumentpanelen",
  "bot.message.partDay": "från {partDayStartHour} - {partDayEndHour}",
  "bot.message.probationPeriod": "*Prövotid*: \nDin prövotid varar till {date} \n \nAnledning: _{message}_",
  "bot.message.reminder": "Hej team! En vänlig påminnelse från Vacation Tracker-botten",
  "bot.message.reminderSnoozed": "Begäran om påminnelser snoozade",
  "bot.message.requestLeave": "Vänligen välj en av de tillgängliga ledighetstyperna från listan nedan, och välj start- och slutdatum för din ledighet. När du klickar på knappen Skicka begäran kommer din begäran att skickas för godkännande.",
  "bot.message.requestLeave.error": "*Fel: {errorMessage}*",
  "bot.message.requestLeave.errorAutomation": "{errorMessage}",
  "bot.message.requestLeave.initialHelp": "Jag är här för att hjälpa dig att skicka in en ledighetsbegäran. 🐨 Vänligen välj en ledighetstyp och klicka sedan på Nästa.",
  "bot.message.requestLeave.leaveDuration1": "Det är 1 dag ledigt.",
  "bot.message.requestLeave.leaveDurationHalfDay": "Det är en halv dag ledigt",
  "bot.message.requestLeave.leaveDurationYear1": "Det är {year1Value} ledighet.",
  "bot.message.requestLeave.leaveDurationYear1And2": "Det är {year1Value} ledighet för innevarande år och {year2Value} ledighet för nästa år.",
  "bot.message.requestLeave.leaveDurationYear1And2Days": "Det är {year1Value} {year1Value, plural, =1 {dag} other {dagar}} ledighet för innevarande år och {year2Value} {year2Value, plural, =1 {dag} other {dagar}} ledighet för nästa år.",
  "bot.message.requestLeave.leaveDurationYear1And2Hours": "Det är {year1Value} {year1Value, plural, =1 {timme} other {timmar}} ledighet för innevarande år och {year2Value} {year2Value, plural, =1 {timme} other {timmar}} ledighet för nästa år.",
  "bot.message.requestLeave.leaveDurationYear1Days": "Det är {year1Value} {year1Value, plural, =1 {dag} other {dagar}} ledighet.",
  "bot.message.requestLeave.leaveDurationYear1Hours": "Det är {year1Value} {year1Value, plural, =1 {timme} other {timmar}} ledighet.",
  "bot.message.requestLeave.leaveDurationYear1PluralYear2": "Det är {year1Days} dagar ledighet för innevarande år och {year2Days} dag ledighet för nästa år.",
  "bot.message.requestLeave.leaveDurationYear1Year2AllPlural": "Det är {year1Days} dagar ledighet för innevarande år och {year2Days} dagar ledighet för nästa år.",
  "bot.message.requestLeave.leaveDurationYear2": "Det är {year2Value} ledighet för nästa år.",
  "bot.message.requestLeave.leaveDurationYear2Days": "Det är {year2Value} {year2Value, plural, =1 {dag} other {dagar}} ledighet för nästa år.",
  "bot.message.requestLeave.leaveDurationYear2Hours": "Det är {year2Value} {year2Value, plural, =1 {timme} other {timmar}} ledighet för nästa år.",
  "bot.message.requestLeave.leaveDurationYear2Plural": "Det är {year2Days} dagar ledighet för nästa år.",
  "bot.message.requestLeave.leaveDurationYear2PluralYear1": "Det är {year1Days} dag ledighet för innevarande år och {year2Days} dagar ledighet för nästa år",
  "bot.message.requestLeave.selectCalendarStatus": "Välj kalenderstatus",
  "bot.message.requestLeave.selectCalendarStatus.info": "*Kalenderstatus*\nVisa i din kalender som",
  "bot.message.requestLeave.selectDate.from": "*Startdatum*\nVänligen välj ett startdatum",
  "bot.message.requestLeave.selectDate.info": "*Startdatum*\nVänligen välj ett startdatum",
  "bot.message.requestLeave.selectDate.oneDay": "*Datum*\nVänligen välj ett datum",
  "bot.message.requestLeave.selectDate.to": "*Slutdatum*\nVänligen välj ett slutdatum",
  "bot.message.requestLeave.selectLeaveType": "Välj ledighetstyp",
  "bot.message.requestLeave.selectLeaveType.info": "*Ledighetstyp*\nVänligen välj ledighetstyp",
  "bot.message.requestLeave.selectTimeFormat": "*FM/EM*",
  "bot.message.requestLeave.selectTimeRange": "Välj tidsintervall",
  "bot.message.requestLeave.selectTimeRange.end": "*Slutdatum*\nVänligen välj ett slutdatum",
  "bot.message.requestLeave.selectTimeRange.from": "*Starttid*",
  "bot.message.requestLeave.selectTimeRange.info": "*Tidsintervall*\n Vänligen välj tidsintervall",
  "bot.message.requestLeave.selectTimeRange.range": "*Tidsintervall*",
  "bot.message.requestLeave.selectTimeRange.to": "*Sluttid*",
  "bot.message.requestLeave.submitted.end": ":tada: *{message}*.\n Vi ses nästa gång!",
  "bot.message.requestLeave.submitted.letTeamKnow": "P.S. Låt mitt team veta om jag var till hjälp!",
  "bot.message.requestLeave.submitted.withApproval": "Datumen du begärt har skickats för godkännande till din chef",
  "bot.message.requestLeave.submitted.withoutApproval": "Datumen du begärt har automatiskt godkänts",
  "bot.message.requestLeaveModal.requestALeave": "Begär en ledighet",
  "bot.message.requestLeaveModal.requestingLeave": "Begär ledighet",
  "bot.message.requestLeaveModal.sendFeedback": "Skicka feedback",
  "bot.message.requestLeaveModal.sendRequest": "Skicka begäran",
  "bot.message.requestToil.initialHelp": "Jag är här för att hjälpa dig att skicka in en TOIL-begäran. 🐨 Vänligen välj en ledighetstyp och klicka sedan på Nästa.",
  "bot.message.selectADate": "Välj ett datum",
  "bot.message.selectSubstituteApprover": "*Välj ersättande godkännare*\nVälj en person som ska hantera dina inkommande ledighetsbegäranden medan du är borta.",
  "bot.message.showHolidays.holidaysHalfDay": "- *{holidayLocationName}* kommer att vara ledigt för *{holidayName}* den {date} (Halv dag).\n",
  "bot.message.showHolidays.noUpcomingHolidays": "Det finns inga kommande helgdagar i din organisation eller så har din administratör inte ställt in några helgdagar än.",
  "bot.message.showHolidays.one": "- *{holidayLocationName}* kommer att vara ledigt för *{holidayName}* från {startDate} till {endDate}.\n",
  "bot.message.showHolidays.two": "- *{holidayLocationName}* kommer att vara ledigt för *{holidayName}* den {date}.\n",
  "bot.message.showHolidays.upcomingHolidays": "Kommande helgdagar",
  "bot.message.slack.userChanged": "Användaren har ändrat denna begäran",
  "bot.message.slashCommand.helpCommand1": "*Här är lite mer information om mig:*",
  "bot.message.slashCommand.helpCommand2": "Betrodd av över 1000 organisationer världen över, *Vacation Tracker låter ditt team enkelt hålla koll på vem som är inne och ute från kontoret hela tiden.*",
  "bot.message.slashCommand.helpCommand3": "Lär dig mer om vår historia på vår <https://vacationtracker.io/about|Om oss-sida>.",
  "bot.message.slashCommand.helpCommand4": "För steg-för-steg-guider, besök vår <https://vacationtracker.crisp.help/en/|Helpdesk>.",
  "bot.message.slashCommand.helpCommand5": "För att logga in på onlinedashboarden, besök <{dashboardUrl}/connect?platform=slack>.",
  "bot.message.slashCommand.helpCommand6": "Och om du någonsin behöver oss längs vägen är vi bara ett mejl bort på hello@vacationtracker.io.",
  "bot.message.slashCommand.noPermissions": "Tyvärr är jag budbäraren av dåliga nyheter, men det verkar som att du inte har behörighet att använda Vacation Tracker. 😟 Vänligen kontakta din administratör.",
  "bot.message.slashCommand.title": "Hej <@{userId}>! Vill du ta lite ledigt? Låt mig hjälpa dig!",
  "bot.message.sorryLetsSpokePriveate": "Hmm, låt oss prata privat. 🤫",
  "bot.message.sorryYouAreNotActive": "Tyvärr, ditt konto är inaktivt",
  "bot.message.sorryYouAreNotExist": "Tyvärr, ditt konto hittades inte",
  "bot.message.submittedLeaveRequest": "Hej, <@{userSlackId}> har skickat in följande ledighetsansökan:",
  "bot.message.submittedLeaveRequestReminder": "Hej, bara en påminnelse om att <@{name}> har skickat in en ansökan som kräver din uppmärksamhet.",
  "bot.message.submittedLeaveRequestReminderExpiration": "⏰ Observera att denna ansökan kommer att löpa ut om {days} dag(ar). Detta är påminnelse {reminderNo} av 3.",
  "bot.message.substituteApproverPlaceholder": "🔍 Ingen",
  "bot.message.unableToRequestLeave": "Kan inte begära ledighet.",
  "bot.message.unknownCommand": "Jag är ledsen, jag förstår inte detta kommando.\nSkriv **help** för att se listan över tillgängliga kommandon.",
  "bot.microsoft.whatsNewInfoMessages": "Detta meddelande är ett tillkännagivande från **Vacation Tracker**. Du får det eftersom du är en administratör eller en godkännare.",
  "bot.notification.accruedDaysInfoForApprover": "ℹ️ Denna ledighetsansökan baseras på framtida intjänade lediga dagar, inte det nuvarande antalet. @{name} har {amount} dag(ar) kvar idag. De kommer att tjäna in {accrued} den {earningDate}.",
  "bot.notification.accruedDaysInfoForUser": "ℹ️ Din ledighetsansökan baseras på framtida intjänade lediga dagar, inte det nuvarande antalet. Du har {amount} dag(ar) kvar idag, och du kommer att tjäna in {accrued} den {earningDate}.",
  "bot.notification.adminToApprovers": "Du har utsetts till ny godkännare för {teamName}, eftersom den tidigare godkännarens konto har inaktiverats.",
  "bot.notification.approverCard.approved": "✅ @{approverName} har godkänt<firstLevelOfApproval> den första nivån av</firstLevelOfApproval> ansökan, ingen ytterligare åtgärd krävs<firstLevelOfApproval> i detta skede. Ledighetsansökan har skickats vidare till den andra godkännandenivån</firstLevelOfApproval>.",
  "bot.notification.approverCard.autoApproved": "Ansökan har automatiskt godkänts, ingen ytterligare åtgärd krävs.",
  "bot.notification.approverCard.cancelled": "⚠️ Det ser ut som att {requesterName} har ändrat sig. Ansökan har avbrutits.",
  "bot.notification.approverCard.cancelledLeave": "Hej, {requesterName} har avbrutit sin ledighet.",
  "bot.notification.approverCard.cancelledTitle": "⚠️ Denna ledighet har avbrutits av en godkännare.",
  "bot.notification.approverCard.denied": "❌ @{approverName} har avslagit ansökan, ingen ytterligare åtgärd krävs.",
  "bot.notification.approverCard.deniedEdited": "❌ @{approverName} har avslagit den redigerade ansökan, ingen ytterligare åtgärd krävs.",
  "bot.notification.approverCard.editedByApprover": "@{approverName} har redigerat en ledighetsansökan inlämnad av @{requesterName}",
  "bot.notification.approverCard.editedByApproverForUser": "@{approverName} har redigerat en ledighetsansökan för @{requesterName}.",
  "bot.notification.approverCard.info": "Lägg till en anledning för detta godkännande eller avslag",
  "bot.notification.approverCard.infoFirstLevel": "Du är den första godkännandenivån för denna ansökan.",
  "bot.notification.approverCard.infoSecondLevel": "Du är den andra godkännandenivån för denna begäran. Den har redan godkänts av @{approverName}.",
  "bot.notification.approverCard.notActive": "⚠️ Denna ledighetsansökan är inte längre aktiv.",
  "bot.notification.assignedAsApproverCard.infoMultilevel.level1": "Du har tilldelats som en första nivås godkännare för avdelningen {departmentName}. Klicka på knappen 'Godkännarguide' nedan för att lära dig vad godkännare kan göra.",
  "bot.notification.assignedAsApproverCard.infoMultilevel.level1and2": "Du har tilldelats som första och andra nivås godkännare för avdelningen {departmentName}. Klicka på knappen 'Godkännarguide' nedan för att lära dig vad godkännare kan göra.",
  "bot.notification.assignedAsApproverCard.infoMultilevel.level2": "Du har tilldelats som en andra nivås godkännare för avdelningen {departmentName}. Klicka på knappen 'Godkännarguide' nedan för att lära dig vad godkännare kan göra.",
  "bot.notification.assignedAsApproverCard.infoSinglelevel": "Du har tilldelats som godkännare för avdelningen {departmentName}. Klicka på knappen 'Godkännarguide' nedan för att lära dig vad godkännare kan göra.",
  "bot.notification.assignedAsApproverCard.openLeaveRequests": "Det finns {numberOfOpenLeaveRequests} öppna ledighets{numberOfOpenLeaveRequests, plural, =1 {ansökan} other {ansökningar}} som kräver din uppmärksamhet. Klicka på knappen 'Besök Dashboard' nedan för att granska dem. Oroa dig inte, vi kommer att skicka meddelanden om nya ledighetsansökningar så snart de lämnas in.",
  "bot.notification.assignedAsApproverCard.title": "Du har tilldelats som godkännare",
  "bot.notification.assignedAsApproverCard.tutorialButton": "Godkännarguide",
  "bot.notification.changeOutOfOfficeMessage": "Redigera mall",
  "bot.notification.daily.info": "Det är dags för din dagliga uppdatering! 🐨 Här är de kommande ledigheterna:",
  "bot.notification.daily.noLeaves.isNotForToday": "Det finns inga schemalagda ledigheter på {dayName}",
  "bot.notification.daily.noLeaves.isToday": "Det finns inga schemalagda ledigheter idag",
  "bot.notification.holiday.plural": "- **{locationNames}** platser kommer att vara borta för **{holidayName}** {holidayPeriodText}",
  "bot.notification.holiday.singular": "- **{locationNames}** plats kommer att vara borta för **{holidayName}** {holidayPeriodText}",
  "bot.notification.infoFirstLevel": "Du är den första godkännandenivån för denna begäran.",
  "bot.notification.leave.info.isInTheFuture": "kommer att vara ledig{leaveTypeText} {leavePeriodText}",
  "bot.notification.leave.info.isInThePast": "kommer att vara ledig{leaveTypeText} {leavePeriodText}",
  "bot.notification.leave.info.isToday": "är ledig{leaveTypeText} {leavePeriodText}",
  "bot.notification.leaveDates.partDay": "{start} från {partDayStartHour} - {partDayEndHour}",
  "bot.notification.leavePeriod.fullDay": "den {day}",
  "bot.notification.leavePeriod.halfDay": "den {day} (Halv dag)",
  "bot.notification.leavePeriod.moreDays": "från {start} till {end}",
  "bot.notification.leavePeriod.partDay": "den {start} från {partDayStartHour} - {partDayEndHour}",
  "bot.notification.leavePolicyInfo": "*Observera: denna användare eller denna ledighetstyp har en kvot på 0 inställd för den begärda ledigheten.*",
  "bot.notification.leaveRequestError.general": "Fel med en ledighetsansökan",
  "bot.notification.leaveRequestError.notApprover": "Godkännandeflödet för din avdelning har ändrats. Kontakta din administratör om du har några ytterligare frågor.",
  "bot.notification.leaveRequestError.notOpen": "Ledighetsansökan har {leaveRequestStatus}",
  "bot.notification.leaveRequestError.notOpenIsSameAppover": "Ledighetsansökan har {leaveRequestStatus}",
  "bot.notification.leaveRequestExpired": "Din begäran har löpt ut",
  "bot.notification.leaveRequestExpiredGeneral": "Begäran har löpt ut",
  "bot.notification.leaveTypeText.isPartDay": "({leaveTypeName} - Halv dag)",
  "bot.notification.oooDefaultMessage": "Hej,\n\nTack för ditt meddelande. Jag är för närvarande utanför kontoret och kan inte svara på e-post förrän {firstWorkingDay}.\n\nJag kommer att göra mitt bästa för att svara på ditt e-postmeddelande så snart jag återvänder.\n\nTack för din förståelse.\n\nMed vänliga hälsningar",
  "bot.notification.processingApproval": "Bearbetar godkännande...",
  "bot.notification.processingDenial": "Bearbetar avslag...",
  "bot.notification.processingResend": "Skickar om ledighetsansökan...",
  "bot.notification.processingSnooze": "Snoozar påminnelser...",
  "bot.notification.reconnectCalendar": "Återanslut",
  "bot.notification.reminderSent": "Påminnelse om ledighetsansökan skickad",
  "bot.notification.reminderSentToil": "Påminnelse om TOIL-ansökan skickad",
  "bot.notification.requesterCard.addedLeave": "Ledighet har skapats för dig",
  "bot.notification.requesterCard.addedLeaveBy": "Tillagd av {approverName}",
  "bot.notification.requesterCard.addedLeaveToil": "TOIL har skapats för dig",
  "bot.notification.requesterCard.approvedTitle": "Din ledighetsansökan har godkänts",
  "bot.notification.requesterCard.approvedTitleToil": "Din TOIL-ansökan har godkänts",
  "bot.notification.requesterCard.approverInfo": "Godkänd av {approverName}",
  "bot.notification.requesterCard.autoApprovedInfo": "Ansökan har automatiskt godkänts.",
  "bot.notification.requesterCard.blackoutPeriod": "Period: {startDate} - {endDate}",
  "bot.notification.requesterCard.blackoutPeriodReason": "Anledning: {message}",
  "bot.notification.requesterCard.blackoutPeriodTitle": "Avstängningsperiod",
  "bot.notification.requesterCard.cancelled": "⚠️ Avbruten",
  "bot.notification.requesterCard.cancelledEdited": "⚠️ Ändringar har gjorts i den ursprungliga begäran.",
  "bot.notification.requesterCard.cancelledEditedByRequester": "@{requesterName} har redigerat denna ledighetsansökan.",
  "bot.notification.requesterCard.cancelledTitle": "⚠️ Din ledighet har avbrutits av en godkännare.",
  "bot.notification.requesterCard.deniedEditedDescription": "Din redigerade ledighetsansökan har avslagits, men din ursprungliga ansökan är fortfarande aktiv.",
  "bot.notification.requesterCard.deniedEditedTitle": "Redigering av ledighetsansökan avslogs.",
  "bot.notification.requesterCard.deniedOpenDescription": "Både din ursprungliga ledighetsansökan och den redigerade ledighetsansökan har avslagits.",
  "bot.notification.requesterCard.deniedOpenTitle": "Ledighetsansökan avslogs",
  "bot.notification.requesterCard.deniedTitle": "Din ledighetsansökan avslogs",
  "bot.notification.requesterCard.edited": "@{requesterName} har redigerat sin tidigare godkända begäran och skickar in den på nytt för godkännande:",
  "bot.notification.requesterCard.editedOpen": "@{requesterName} har redigerat och skickat in sin ledighetsbegäran på nytt.",
  "bot.notification.requesterCard.maximumUsersAwayTitle": "Maximalt antal frånvarande användare",
  "bot.notification.requesterCard.moreDays": "❌ Du har begärt fler dagar än vad som finns tillgängligt.",
  "bot.notification.requesterCard.moreThanFullDay": "❌ Ledighetsbegäran kan inte skickas in eftersom du har begärt mer än en hel dags ledighet under en arbetsdag.",
  "bot.notification.requesterCard.noWorkingDays": "❌ Ledighetsbegäran kan inte skickas in eftersom du inte har inkluderat några arbetsdagar. Se till att du granskar ditt teams arbetsdagar innan du skickar in begäran.",
  "bot.notification.requesterCard.originalLeaveRequest": "Ursprunglig ledighetsbegäran:",
  "bot.notification.requesterCard.overlap": "❌ Ledighetsbegäran kan inte skickas in eftersom datumen du har begärt överlappar med datumen för en befintlig ledighetsbegäran.",
  "bot.notification.requesterCard.probationPeriod": "Din provanställningsperiod varar till {date}",
  "bot.notification.requesterCard.probationPeriodReason": "Anledning: {message}",
  "bot.notification.requesterCard.probationPeriodTitle": "Provanställningsperiod",
  "bot.notification.requesterCard.requestInfo": "Din begäran är på väg till godkännaren. Vi ses nästa gång! 🐨",
  "bot.notification.requesterCard.requestPossibleActions": "För att redigera eller avbryta begäran, besök Dashboarden",
  "bot.notification.requesterCardapp.tryAnotherDates": "Prova andra datum",
  "bot.notification.requesterInfo": "Hej, @{userName} har skickat in en begäran för dig att granska.",
  "bot.notification.requesterInfoAutoApproved": "Hej, @{userName} har skickat in en begäran.",
  "bot.notification.requesterInfoEdited": "@{userName} har redigerat och skickat in sin begäran på nytt.",
  "bot.notification.requesterInfoEditedByApprover": "@{approverName} har redigerat en begäran som skickats in av @{userName}",
  "bot.notification.requesterInfoEditedForUser": "Din begäran har redigerats.",
  "bot.notification.requesterInfoEditedForUserByApprover": "Godkännaren @{approverName} har gjort ändringar i din begäran.",
  "bot.notification.requesterInfoEditedOpen": "@{userName} har redigerat och skickat in sin begäran på nytt.",
  "bot.notification.setOutOfOffice": "Din {leaveTypeName} ledighet är planerad att börja om mindre än {days} {days, plural, =1 {dag} other {dagar}} den {leavePeriodStart}. Vill du ställa in ett automatiskt frånvaromeddelande som ska skickas under din bortavaro?",
  "bot.notification.setOutOfOfficeButton": "Ställ in frånvaromeddelande",
  "bot.notification.setOutOfOfficeFallbackText": "Ställ in frånvaromeddelande för din {leaveTypeName}",
  "bot.notification.slack.additional": "... och mer.\nYtterligare ledigheter kunde inte visas på grund av storleksbegränsning. Vänligen öppna Dashboard för en fullständig översikt.",
  "bot.notification.slack.approved": "✅ <@{approverName}> har godkänt<firstLevelOfApproval> den första nivån av</firstLevelOfApproval> ansökan, ingen ytterligare åtgärd krävs<firstLevelOfApproval> i detta skede. Ledighetsansökan har skickats vidare till den andra godkännandenivån</firstLevelOfApproval>.",
  "bot.notification.slack.approverChanged": "Hej, godkännaren <@{approverSlackId}> ändrade en ledighetsbegäran.",
  "bot.notification.slack.approverChangedDatesOfLeaveReuquest": "Godkännaren <@{approverSlackId}> har ändrat datumen för begäran",
  "bot.notification.slack.approverChangedLeaveReqest": "Hej, godkännaren <@{approverSlackId}> har ändrat följande ledighetsbegäran för <@{userSlackId}>:",
  "bot.notification.slack.approverChangedYour": "Godkännaren <@{approverSlackId}> ändrade din ledighetsbegäran.",
  "bot.notification.slack.approverHasDeniedThisRequest": "<@{approverSlackId}> har nekat denna begäran, ingen ytterligare åtgärd krävs",
  "bot.notification.slack.calendar": ":calendar: |  *{dayName}*  | :calendar: ",
  "bot.notification.slack.cancelled": "Hej, <@{userName}> har avbokat sin ledighet.",
  "bot.notification.slack.cancelledByApprover": "Din ledighet har avbokats av en godkännare",
  "bot.notification.slack.cancelledInfo": "Denna ledighet har avbokats av en godkännare",
  "bot.notification.slack.created": "En ledighet har skapats för dig av en godkännare",
  "bot.notification.slack.holiday.plural": "- *{locationNames}* platser kommer att vara lediga för *{holidayName}* {holidayPeriodText}",
  "bot.notification.slack.holiday.singular": "- *{locationNames}* plats kommer att vara ledig för *{holidayName}* {holidayPeriodText}",
  "bot.notification.slack.holidays": ":airplane: *Helgdagar: *",
  "bot.notification.slack.infoSecondLevel": "Du är den andra godkännandenivån för denna begäran. Den har redan godkänts av <@{approverName}>.",
  "bot.notification.slack.leave.info.isInTheFuture": "- *{userName}* kommer att vara ledig{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leave.info.isInThePast": "- *{userName}* var på ledighet{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leave.info.isToday": "- *{userName}* är på ledighet{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leaveRequestCreated": "Hej, <@{userName}> har skickat in en ledighetsansökan.",
  "bot.notification.slack.leaveRequestInfo": "Hej, <@{userName}> vänligen bekräfta informationen om ledighetsansökan.",
  "bot.notification.slack.leaveRequestInfoDateFrom": "Startdatum: {date}",
  "bot.notification.slack.leaveRequestInfoDateTo": "Slutdatum: {date}",
  "bot.notification.slack.leaveRequestInfoError": "Vi kunde inte föreslå ledighet från de angivna uppgifterna",
  "bot.notification.slack.leaveRequestInfoLeaveType": "Ledighetstyp: {leaveType}",
  "bot.notification.slack.leaveRequestInfoReason": "Anledning: {reason}",
  "bot.notification.slack.leaveRequestInfoToil": "Hej, <@{userName}> vänligen bekräfta TOIL-begäransinformationen.",
  "bot.notification.slack.leaveRequestSuggestionCancelled": "Avbruten ledighetsansökan ❌",
  "bot.notification.slack.leaveRequestSuggestionCancelledToil": "Avbruten TOIL-begäran ❌",
  "bot.notification.slack.leaves": ":loud_sound: *Ledigheter: *",
  "bot.notification.slack.reason": "*Anledning*\n{reason}",
  "bot.notification.slack.reasonEdited": "*Anledning [Redigerad] *\n{reason}",
  "bot.notification.slack.requestedBy": "*Begärd av:*\n<@{userSlackId}>",
  "bot.notification.slack.statusApproved": "*Status:*\n✅ Godkänd",
  "bot.notification.slack.statusAutoApproved": "*Status*\nAutomatiskt godkänd",
  "bot.notification.slack.statusDenied": "*Status*\n❌ Nekad",
  "bot.notification.slack.statusInfo": "👋 Vi kan nu ställa in din Slack-status automatiskt för denna ledighetstyp! Klicka på Aktivera-knappen för att låta oss uppdatera din status när din ledighet börjar. Vi rensar den när den slutar.",
  "bot.notification.slack.thisLeaveHasBeenCancelledByAppover": "Denna ledighet har avbrutits av en godkännare. Se nedan för mer information.",
  "bot.notification.slack.title": "Notifikation: \"_{title}_\"",
  "bot.notification.slack.userCancelledLeaveRequest": "Det verkar som att <@{userSlackId}> har ändrat sig. Ansökan har avbrutits.",
  "bot.notification.slack.warning": ":warning: *Varning: {infoMessage}*",
  "bot.notification.slack.yourLeaveHasBeenCancelledByAppover": "Din ledighet har avbrutits av en godkännare. Se nedan för mer information.",
  "bot.notification.slack.yourLeaveRequestApproved": "Din ansökan har godkänts",
  "bot.notification.slack.yourRequestHasBeenDenied": "Din ansökan har nekats",
  "bot.notification.snoozeOutOfOfficeButton": "Fråga mig inte igen",
  "bot.notification.snoozeOutOfOfficeMessage": "Vi kommer inte att fråga dig mer om att ställa in Frånvaromeddelande för {leaveTypeName}. Du kan alltid ändra denna inställning på Vacation Tracker Web Dashboard - Kontoinställningar",
  "bot.notification.submittedLeaveRequestReminder": "Hej, bara en påminnelse om att @{name} har skickat in en begäran som kräver din uppmärksamhet.",
  "bot.notification.substituteApproverDeleted": "Din roll som ersättande godkännare för perioden {startDate} - {endDate} har tagits bort.",
  "bot.notification.substituteApproverSelected": "Du har valts av {creatorName} som ersättande godkännare för avdelning(ar) {teamNames} på uppdrag av {approverName} under deras frånvaro. Som ersättande godkännare kommer du att ansvara för att granska och agera på ledighetsansökningar som skickas till {approverName} från {startDate} till {endDate}.",
  "bot.notification.substituteApproverUpdated": "Din period som ersättande godkännare har uppdaterats från {startDate} till {endDate}.",
  "bot.notification.title": "Notifikation: {title}",
  "bot.notification.weekly.current.info": "Hej!\n 🐨 Jag är här för att uppdatera dig om planerade ledigheter för den här veckan ({start} - {end})",
  "bot.notification.weekly.next.info": "Hej!\n 🐨 Jag är här för att uppdatera dig om planerade ledigheter för nästa vecka ({start} - {end})",
  "bot.notification.weekly.noHolidays": "Det finns inga helgdagar för veckan.",
  "bot.notification.weekly.noLeaves": "Det finns inga planerade ledigheter för veckan.",
  "bot.slack.whatsNewInfoMessages": "Detta meddelande är en annons från *Vacation Tracker*. Du får det eftersom du är en administratör eller en godkännare.",
  "bot.slackFuckedUpAgain": "Slack har fixat något igen, kontakta vår support för att åtgärda Slack-buggar.",
  "bot.somethingNewFromVacationTracker": "Något nytt från Vacation Tracker",
  "bot.welcomeMessage.furtherAssistance": "Känner du dig överväldigad av all denna information? Oroa dig inte! Du behöver inte memorera allt. Skriv bara 'Hjälp' i vår chatt, så skickar vår bot detta till dig. För ytterligare assistans, tveka inte att chatta med vårt kundsupportteam via vår hemsida eller besök vår Helpdesk.",
  "bot.welcomeMessage.goToMyProfile": "Gå till fliken Min profil",
  "bot.welcomeMessage.greeting": "Hälsningar {name}, jag är Vacation Tracker-boten! Jag är här för att hjälpa dig att schemalägga ledigheter via Microsoft Teams och hålla alla uppdaterade om vem som är på kontoret och vem som är ledig. Utan vidare omsvep, låt oss börja spåra dessa ledigheter!",
  "bot.welcomeMessage.happyVacationing": "Glad semester 🌴",
  "bot.welcomeMessage.howToAccessTabs": "Hur man kommer åt flikar",
  "bot.welcomeMessage.noCompany": "Hej, \n\n Tack för att du installerade Vacation Tracker-boten och fliken i Microsoft Teams. Som ett sista steg, gå till vår webbplats för att slutföra registreringen och konfigurera din organisations inställningar: [{dashboardLink}]({utmDashboardLink})",
  "bot.welcomeMessage.noCompanyInfo": "Om du har några frågor, tveka inte att starta en chatt med vårt kundsupportteam på vår webbplats eller besök vår hjälpcentral.",
  "bot.welcomeMessage.openWebDashboardInfo": "Samma information är också tillgänglig via vår webbpanel. Klicka bara på knappen 'Öppna webbpanel' nedan och logga in med ditt Microsoft-konto för att få tillgång till den.",
  "bot.welcomeMessage.requestLeaveInfo": "För att börja, skicka bara ett direktmeddelande till mig med 'Begär ledighet' eller titta på den här korta handledningen om Vacation Tracker:",
  "bot.welcomeMessage.restInfo": "Förutom boten kan du också skicka ledighetsansökningar, kontrollera dina ledighetskvoter, visa teamkalendrar och mer från Microsoft Teams genom att använda flikar:",
  "bot.welcomeMessage.title": "Hej från Vacation Tracker",
  "botUpdatePermissions.thanks": "Tack! Allt är inställt. Du kan stänga dashboarden.",
  "button.no": "Nej",
  "button.yes": "Ja",
  "calendar.all": "Alla",
  "calendar.calendar": "Kalender",
  "calendar.filterByLocation": "Filtrera efter plats:",
  "calendar.filterByTeam": "Filtrera efter avdelning:",
  "calendar.forAllUsers": "För alla användare",
  "calendar.getCalendarLink": "Hämta kalenderlänk",
  "calendar.halfdayTags": "Ledigheter och helgdagar kortare än en hel dag representeras av randiga block.",
  "calendar.halfdayTagsShort": "Ledigheter kortare än en dag",
  "calendar.labels": "Etikett(er)",
  "calendar.listView": "Listvy",
  "calendar.location": "Plats(er)",
  "calendar.monthView": "Månadsvy",
  "calendar.sync.calendarAlreadyConnected": "Denna kalender är redan ansluten",
  "calendar.sync.calendarNotFound": "Det gick inte att hitta kalendern. Den kan ha raderats eller är inte längre tillgänglig. Vänligen granska dina kalenderinställningar.",
  "calendar.sync.canNotUpdateSharedCalendar": "Du anslöt {calendarName} {provider}-kalendern. Det är inte tillåtet att ändra kalendern. För att ändra den anslutna kalendern, vänligen radera denna integration och skapa en ny.",
  "calendar.sync.confirmDelete": "Vänligen bekräfta att du vill radera kalendern <strong>{name}</strong>.",
  "calendar.sync.connect": "Anslut din {provider}-kalender",
  "calendar.sync.connectCalendar": "Anslut kalender",
  "calendar.sync.connectCalendarCompletePlanyOnly": "ENDAST KOMPLETT PLAN",
  "calendar.sync.connectCalendarIcalOptionLabel": "Valfri kalender (via iCal-länk)",
  "calendar.sync.connectCalendarOptionLabel": "{platform}-kalender",
  "calendar.sync.connectInfo": "Anslut ditt {provider}-konto och välj en delad kalender där alla godkända ledigheter kommer att läggas till och synkroniseras automatiskt.",
  "calendar.sync.createICalButton": "Skapa iCal-länk",
  "calendar.sync.createICalFiltersInfo": "Följande filter kommer att tillämpas på denna iCal-kalenderlänk:",
  "calendar.sync.createICalFiltersWarning": "Din kalender uppdaterar inte iCal-data i realtid. Fördröjningen kan vara upp till 24 timmar (beroende på din kalenderleverantör). För realtidsuppdateringar, anslut din Google Kalender eller Microsoft Outlook Kalender direkt.",
  "calendar.sync.createICalInfo": "iCal-kalenderlänken låter dig prenumerera och visa dina teams schemalagda ledigheter från Vacation Tracker i valfri kalender (inklusive Google Kalender, Microsoft Outlook Kalender, Apple Kalender och många fler).",
  "calendar.sync.createPushToSharedInfo": "Och skicka och synkronisera de filtrerade godkända ledigheterna till följande delade {provider}-kalender:",
  "calendar.sync.createSyncCalendarButton": "Skapa kalendersynkronisering",
  "calendar.sync.createSyncCalendarFiltersInfo": "Vacation Tracker kommer att tillämpa följande filter på alla godkända ledigheter:",
  "calendar.sync.creating": "Skapar kalendersynkronisering",
  "calendar.sync.creatingError": "Fel vid skapande av kalendersynkronisering",
  "calendar.sync.creatingSuccess": "Skapande av kalendersynkronisering lyckades",
  "calendar.sync.deletedCalendar": "Denna kalendersynkronisering har raderats",
  "calendar.sync.deleteTitle": "Radera kalender",
  "calendar.sync.deleting": "Raderar kalendersynkronisering",
  "calendar.sync.deletingError": "Fel vid radering av kalendersynkronisering",
  "calendar.sync.deletingSuccess": "Radering av kalendersynkronisering lyckades",
  "calendar.sync.emptyDataMessage": "Synkronisera och visa alla teamledigheter i din delade kalender. Du kan ansluta din Outlook-kalender eller Google Kalender, eller använda iCal för att synkronisera Vacation Tracker med någon annan kalendertyp.",
  "calendar.sync.emptyDataTitle": "Synkronisera ledigheter med din teamkalender",
  "calendar.sync.loginWithDifferentAccountInfo": "Ser du inte kalendern?",
  "calendar.sync.loginWithDifferentAccountLink": "Logga in med ett annat {provider}-konto",
  "calendar.sync.msLogin": "Hantering av kalendrar kräver en Microsoft Teams-token. Om du klickar på OK-knappen kommer vi att omdirigera dig till Microsofts inloggningssida. Beroende på dina webbläsarinställningar kan du redan vara inloggad och automatiskt omdirigerad tillbaka.",
  "calendar.sync.outlookAdminConsentButton": "Ge administratörsmedgivande",
  "calendar.sync.outlookAdminConsentInfo": "Infogning eller uppdatering av ledigheter i kalendern {calendarName} kräver administratörsmedgivande. Vi behöver ytterligare behörigheter eftersom du har anslutit en kalender som någon annan i din organisation har skapat eller om din organisation har specifika delningsregler för Microsoft Outlook-kalendrar.",
  "calendar.sync.outlookAdminConsentInquiry": "Är du administratör för ditt Microsoft 365-konto?",
  "calendar.sync.outlookAdminConsentLinkInfo": "Om du inte är administratör, vänligen kopiera och skicka följande länk till din Office 365-administratör för att begära nödvändiga behörigheter:",
  "calendar.sync.outlookAdminConsentLinkResyncInfo": "När administratören har beviljat ytterligare behörigheter, klicka här för att få en ny token och lägga till saknade händelser i kalendern {calendarName}:",
  "calendar.sync.outlookAdminConsentModalTitle": "Administratörsmedgivande krävs",
  "calendar.sync.outlookAdminConsentResyncButton": "Återanslut och synkronisera",
  "calendar.sync.reconnect": "Återanslut din {provider}-kalender",
  "calendar.sync.reconnectInfo": "Återanslut ditt {provider}-konto för att fortsätta automatisk synkronisering av ledigheter med din delade kalender.",
  "calendar.sync.reconnecting": "Återansluter kalendersynkronisering",
  "calendar.sync.reconnectingError": "Fel vid återanslutning av kalendersynkronisering",
  "calendar.sync.reconnectingSuccess": "Återanslutning av kalendersynkronisering lyckades",
  "calendar.sync.selectSharedCalendarPlaceholder": "Välj delad {provider}-kalender",
  "calendar.sync.stepByStep": "Steg-för-steg-instruktioner",
  "calendar.sync.title": "Synkronisera din teamkalender",
  "calendar.sync.tooltipInfo": "Alla dina synkroniserade kalendrar samlas på denna sida för enklare hantering. För att lära dig mer, vänligen se vår <helpDesk>Helpdesk-artikel.</helpDesk>",
  "calendar.sync.update": "Uppdatera din {provider}-kalender",
  "calendar.sync.updateSyncCalendarButton": "Uppdatera kalendersynkronisering",
  "calendar.sync.updating": "Uppdaterar kalendersynkronisering",
  "calendar.sync.updatingError": "Fel vid uppdatering av kalendersynkronisering",
  "calendar.sync.updatingSuccess": "Uppdatering av kalendersynkronisering lyckades",
  "calendar.syncButton": "Synkronisera med din kalender (Google, Outlook, etc.)",
  "calendar.syncModal.description": "Kopiera länken nedan och integrera den i din Google Kalender, Outlook-kalender eller någon annan kalender som stöder iCal-kalenderflöden.",
  "calendar.syncModal.filters": "Följande filter tillämpas på denna kalenderlänk:",
  "calendar.syncModal.helpdesk": "Besök vår <helpDesk>Helpdesk</helpDesk> för hjälp och steg-för-steg-instruktioner.",
  "calendar.team": "Avdelning(ar)",
  "calendar.timezone.info": "Tiden är konverterad till din tidszon",
  "calendar.week.monFri": "Mån-Fre",
  "calendar.week.sunThu": "Sön-Tors",
  "calendarListModal.description": "Vänligen välj en kalender från följande lista över tillgängliga kalendrar. Efter att du har anslutit en kalender kommer Vacation Tracker att lägga till, redigera eller avboka dina ledigheter i den valda kalendern.",
  "calendarListModal.descriptionGoogleFirstLine": "Vänligen välj en kalender från följande lista över tillgängliga kalendrar. ",
  "calendarListModal.descriptionOutlookFirstLine": "Du kan endast ansluta din standardkalender. ",
  "calendarListModal.descriptionSecondLine": "Efter att du har anslutit en kalender kommer Vacation Tracker att lägga till, redigera eller avboka dina ledigheter i den valda kalendern.",
  "calendarListModal.hiddenLeaveTypesDescription": "Läs mer om <helpdesk>hur man visar dolda ledighetstyper</helpdesk>.",
  "calendarListModal.reconnectDescription": "Återanslut din kalender för att fortsätta automatisk synkronisering av ledigheter med din anslutna kalender.",
  "calendarListModal.reconnectTitle": "Återanslut kalender",
  "calendarListModal.selectPlaceHolder": "Vänligen välj en kalender...",
  "calendarListModal.title": "Välj kalender",
  "channel_not_found": "Den valda kanalen har raderats eller är inte längre tillgänglig. Vänligen välj en annan kanal för att ta emot meddelanden.",
  "choiceAutomations.accessibleTo": "Tillgänglig för <approver>Godkännare </approver><and>och </and><admin>Administratör </admin><only>endast</only>",
  "choiceAutomations.activeSubscribedAddons": "Aktiva prenumererade tillägg",
  "choiceAutomations.activeSubscribedAutomations": "Aktiva prenumererade automatiseringar",
  "choiceAutomations.adpIntegration": "Ger en direkt anslutning till ditt löneverktyg, vilket säkerställer att lönedata är korrekt och uppdaterad på alla plattformar.",
  "choiceAutomations.automationPrice": "Pris: ${price}/användare/månad",
  "choiceAutomations.automationPriceInfo": "Du kan köpa och aktivera vilken som helst av automatiseringarna nedan för $0,50/användare/månad",
  "choiceAutomations.bambooHrIntegration": "Ger en direkt anslutning till ditt HR-verktyg, vilket håller data korrekt och konsekvent över system.",
  "choiceAutomations.blackoutPeriodInfo": "En avstängningsperiod är en specifik tidsram under vilken anställda inte tillåts ta ledigt från arbetet.",
  "choiceAutomations.cancelAtPeriodEnd": "Aktiv tills faktureringsperioden slutar",
  "choiceAutomations.cancelAtPeriodEndTooltip": "Du har avslutat prenumerationen på detta tillägg. Det kommer att förbli aktivt till slutet av den nuvarande faktureringsperioden och kommer därefter att avaktiveras.",
  "choiceAutomations.comingSoonScheduleCall": "Känner dig fri att dela detaljer om dina specifika behov gällande automatiseringen vi arbetar på.",
  "choiceAutomations.comingSoonScheduleCallTitle": "Kommer snart",
  "choiceAutomations.deacticate": "Avsluta prenumeration",
  "choiceAutomations.employmentLeaveRestrictionInfo": "Tidig anställningsledighetspolicy möjliggör säkerställandet av dedikerad inkörningstid under de första dagarna av anställningen genom begränsning av nya anställdas ledighetsförfrågningar.",
  "choiceAutomations.enitlementByRoleShortInfo": "Anställda tilldelas extra ledighetsdagar baserat på deras roll, som definieras av etikett(er).",
  "choiceAutomations.entitlementByRoleInfo": "Anställda tilldelas extra ledighetsdagar baserat på deras roll, som definieras av etikett(er). Till exempel kan du skapa en etikett kallad 'Chef' och tilldela den till alla chefer i din organisation. Sedan kan du skapa en automatisering som tilldelar 5 extra ledighetsdagar till alla anställda med etiketten 'Chef'. Du kan skapa flera automatiseringar för olika roller. Denna automatisering gäller från ditt nuvarande räkenskapsår. <link>Kontakta oss</link> om du behöver tillämpa denna automatisering på tidigare år.",
  "choiceAutomations.includedInYourPlan": "Ingår i din plan",
  "choiceAutomations.leaveDurationLimitInfo": "Anpassa ledighetslängd: Ange minsta och högsta antal dagar för önskad period.",
  "choiceAutomations.mySbscribedAddons": "Mina prenumererade tillägg",
  "choiceAutomations.notActivatedAddons": "Tillgängliga för prenumeration",
  "choiceAutomations.openApiInfo": "Möjliggör sömlös integration med externa system, vilket möjliggör automatiserad datasynkronisering och förbättrad funktionalitet",
  "choiceAutomations.reviewAndBuy": "Granska och köp",
  "choiceAutomations.selectAutomations": "Välj din föredragna automatisering",
  "choiceAutomations.seniorityEntitlementInfo": "Anställningstidsbaserad rättighet är ett system där anställda tjänar mer betald ledighet (PTO) baserat på deras anställningstid i företaget. För närvarande kan du skapa endast en anställningstidsbaserad rättighet per plats. Denna automatisering gäller från ditt nuvarande räkenskapsår. <link>Kontakta oss</link> om du behöver mer än en anställningstidsbaserad rättighet på en plats eller behöver tillämpa denna automatisering på tidigare år.",
  "choiceAutomations.seniorityEntitlementShortInfo": "Anställningstidsbaserad rättighet är ett system där anställda tjänar mer betald ledighet (PTO) baserat på deras anställningstid i företaget.",
  "choiceAutomations.teamLeaveBalancingInfo": "Hantera procentandel samtidig teamledighet.",
  "choiceAutomations.visibilityInfo": "Tillåter administratörer att anpassa synligheten av ledighetsinformation för vanliga användare, vilket förbättrar integritet och datarelevans.",
  "company.platformChanged": "Migrerar till en ny plattform",
  "company.platformChanged.confirmDescription": "Du är på väg att migrera till en ny plattform. Denna åtgärd kan inte ångras. Vänligen granska följande information innan du fortsätter.",
  "company.platformChanged.confirmDescription.unmappedUsers.deleted": "Vi har hittat {unmappedUsers} användare som inte finns i ditt {newPlatform}-konto. Dessa användare kommer att markeras som RADERADE när migreringen är klar.",
  "company.platformChanged.confirmDescription.unmappedUsers.unmatched": "Vi har hittat {unmappedUsers} användare som inte finns i ditt {newPlatform}-konto. Dessa användare kommer inte att kunna logga in på Vacation Tracker.",
  "company.platformChanged.confirmQuestion": "Är du säker på att du vill fortsätta?",
  "company.platformChanged.confirmTitle": "Bekräfta migrering",
  "company.platformChanged.description": "Vänligen matcha dina Vacation Tracker-användare med deras {newPlatform}-konton. Vi kommer automatiskt att matcha användare med samma e-postadresser.",
  "company.platformChanged.googleAccount": "Google-konto",
  "company.platformChanged.hideInactive": "Dölj inaktiva:",
  "company.platformChanged.markUnmappedAsDeleted": "Markera omatchade användare som raderade?",
  "company.platformChanged.microsoftAccount": "Microsoft-konto",
  "company.platformChanged.slackAccount": "Slack-konto",
  "company.platformChanged.title": "Migrerar från {oldPlatform} till {newPlatform}",
  "company.platformChanged.unmatched": "Hittades inte i {platform}",
  "company.platformChanged.vacationTrackerUser": "Vacation Tracker-användare",
  "company.platformChangedTenant.title": "Migrerar {newPlatform}-tenant",
  "component.filterSimple.filterBy": "Filtrera efter",
  "component.filterSimple.selectType": "Välj typ",
  "component.filterSimple.tooltipInfo": "Om du inte väljer några filter kommer exporten att inkludera alla användare.",
  "component.notificationDestination.label": "Skicka till",
  "component.notificationDestination.tooltip": "Observera att du kan skicka aviseringen till flera e-postadresser, men endast en kanal åt gången.",
  "component.password.timeToHack.days": "{duration} dagar",
  "component.password.timeToHack.hours": "{duration} timmar",
  "component.password.timeToHack.instantly": "Omedelbart",
  "component.password.timeToHack.label": "Tid hackare behöver för att knäcka detta lösenord:",
  "component.password.timeToHack.minutes": "{duration} minuter",
  "component.password.timeToHack.perfect": "Längre än en livstid",
  "component.password.timeToHack.seconds": "{duration} sekunder",
  "component.password.timeToHack.years": "{duration} år",
  "component.setPassword.enterOldPassword": "Ange gammalt lösenord",
  "component.setPassword.enterPassword": "Ange lösenord",
  "component.setPassword.tooltip.description.line1": "Informationen ovan visar den uppskattade maximala tiden hackare behöver för att med brute force knäcka ditt valda lösenord. Uppskattningen baseras på <link>denna artikels</link> <italic>\"Lösenordstabell för att knäcka en PBKDF2-hashfunktion med hjälp av en RTX 3090 GPU.\"</italic> tabell.",
  "component.setPassword.tooltip.description.line2": "Hur väljer man ett bra lösenord? Det finns många sätt att välja ett bra lösenord. Vi använder lösenordshanterare och återanvänder inte lösenord. Ta en titt på denna <link>roliga men korrekta guide</link> för mer inspiration.",
  "component.setPassword.tooltip.title": "Tid hackare behöver för att med brute force knäcka ditt lösenord",
  "component.setPassword.validation": "Lösenordet måste innehålla minst: 8 tecken, en gemen bokstav, en siffra och en symbol.",
  "components.accrualsCalculator.calculate": "Beräkna",
  "components.accrualsCalculator.calculateAccrualsDays": "Beräkna Intjäningsdagar",
  "components.accrualsCalculator.youWillEarnedInCurrentPeriod": "Du kommer att tjäna in <strong>{days}</strong> dagar under den nuvarande perioden på det datumet",
  "components.accrualsCalculator.youWillEarnedInNextPeriod": "Du kommer att tjäna in <strong>{days}</strong> dagar under nästa period på det datumet",
  "components.addHolidayForm.addHolidays": "Lägg till helgdag",
  "components.addHolidayForm.addHolidays.cancel": "Avbryt",
  "components.addHolidayForm.addHolidays.isHalfDayHoliday": "Halvdagshelgdag",
  "components.addHolidayForm.addHolidays.isHolidayMultiday": "Mer än en dag?",
  "components.addHolidayForm.addHolidays.saveHoliday": "Spara",
  "components.addHolidayForm.date.error": "Vänligen välj ett datum",
  "components.addHolidayForm.editHoliday": "Redigera helgdag",
  "components.addHolidayForm.editHolidays": "Redigera helgdagar",
  "components.addHolidayForm.endDate.error": "Vänligen välj slutdatum",
  "components.addHolidayForm.holidaysName": "Helgdagens namn",
  "components.addHolidayForm.holidaysName.error": "Vänligen ange ett namn på helgdagen",
  "components.addHolidayForm.startDate.error": "Vänligen välj startdatum",
  "components.announcementModal.announceToCompany": "Vill du informera ditt team om att ni använder Vacation Tracker?",
  "components.announcementModal.send": "Skicka meddelande",
  "components.automationBlackoutSummaryInfo.blacoutSummary": "{noOfUsers} användare <link>(Visa användare)</link> kommer inte att kunna ta {leaveTypes} ledighet,<endDate> mellan</endDate> {fromDate} <endDate>och {toDate}</endDate><recurring> varje år.</recurring>",
  "components.billing.email": "Faktureringse-post",
  "components.billing.emailPlaceholder": "Ange e-postadress.",
  "components.billing.emailsValidationRequired": "En e-postadress krävs för faktureringsändamål.",
  "components.billing.resubscribe": "Återaktivera prenumeration",
  "components.billingCouponCode.activeCouponInfo": "<percentOffCover>{off}%</percentOffCover><amountOffCover>US${off}</amountOffCover> rabatt <durationCover>{duration}</durationCover><durationInMonthsCover>{duration} månad</durationInMonthsCover>, från {start}",
  "components.billingCouponCode.activeCouponInfoTitle": "Aktiv kupongkod",
  "components.billingCouponCode.applyCode": "Använd kod",
  "components.billingCouponCode.applyCodeAndResubscribe": "Använd kod & återaktivera prenumeration",
  "components.billingCouponCode.couponCode": "Kupongkod",
  "components.billingCouponCode.text": "Har du en kupongkod? Ange den här för att tillämpa den på din prenumeration.",
  "components.billingCouponCode.title": "Kuponger",
  "components.billingEmails.emailPlaceholder": "Ange e-postadress.",
  "components.billingEmails.emails": "E-postadresser",
  "components.billingEmails.emailsValidationRequired": "En e-postadress krävs för faktureringsändamål.",
  "components.billingEmails.title": "Fakturerings-e-postadresser",
  "components.billingForm.address1": "Adressrad 1",
  "components.billingForm.address1ValidationRequired": "Adress krävs.",
  "components.billingForm.address2": "Adressrad 2",
  "components.billingForm.billingEmails": "Fakturerings-e-post",
  "components.billingForm.billingEmailsPlaceholder": "Ange e-postadress.",
  "components.billingForm.billingEmailsValidationRequired": "En e-postadress krävs för faktureringsändamål.",
  "components.billingForm.ccRequired": "Kortnummer krävs.",
  "components.billingForm.city": "Stad",
  "components.billingForm.cityValidationRequired": "Stad krävs.",
  "components.billingForm.companyName": "Företagsnamn",
  "components.billingForm.companyNameValidationRequired": "Företagsnamn krävs.",
  "components.billingForm.country": "Land",
  "components.billingForm.countryValidationRequired": "Land krävs.",
  "components.billingForm.couponCode": "Kupongkod",
  "components.billingForm.coupons": "Kuponger",
  "components.billingForm.description": "Vänligen ange din betalningsinformation i formuläret ovan för att betala din prenumeration med kreditkort.",
  "components.billingForm.invoiceDescription": "Om personen som ansvarar för att betala prenumerationen inte är en Slack- eller Microsoft Teams-användare kan vi skicka dem en faktura via e-post som kan betalas med kreditkort.",
  "components.billingForm.invoiceLabel": "Skicka e-postfakturor",
  "components.billingForm.invoiceTitle": "Betalning med e-postfaktura",
  "components.billingForm.paymentCC": "Kreditkort",
  "components.billingForm.paymentTitle": "Betalningsinformation",
  "components.billingForm.planTitle": "Faktureringsplan ",
  "components.billingForm.state": "Delstat",
  "components.billingForm.stateValidationRequired": "Delstat krävs.",
  "components.billingForm.title": "Faktureringsinformation ",
  "components.billingInfo.address": "Adress",
  "components.billingInfo.canceledDescription": "Du har avslutat din Vacation Tracker-prenumeration. Den kommer att förbli aktiv till {periodEnd} ",
  "components.billingInfo.canceledTrialDescription": "Din provperiod kommer att förbli aktiv till {periodEnd}, därefter kommer ditt Vacation Tracker-konto att avslutas. ",
  "components.billingInfo.companyName": "Företagsnamn",
  "components.billingInfo.email": "Faktureringse-post",
  "components.billingInfo.resubscribe": "Återuppta prenumeration",
  "components.billingInfo.text": "Din faktureringsinformation används för att behandla betalningar för din prenumeration. Du kan uppdatera din faktureringsinformation och lägga till din kreditkortsinformation här.",
  "components.billingInfo.title": "Faktureringsinformation ",
  "components.billingInvoice.description": "Du kan hantera betalningsmetoder och fakturor på vår kundportal. <link>Klicka här för att besöka kundportalen</link><creatingLink>Skapar länk till kundportalen</creatingLink>",
  "components.billingInvoice.title": "Fakturor",
  "components.cancelSubscriptionModal.anotherLeaveTrackingTool": "Vi har hittat ett annat verktyg för frånvarohantering",
  "components.cancelSubscriptionModal.anotherLeaveTrackingToolAdditionalInfo": "Kan du berätta vilket verktyg du byter till?",
  "components.cancelSubscriptionModal.cancelText": "Avbryt inte prenumerationen",
  "components.cancelSubscriptionModal.contactInfo": "För allt annat, vänligen kontakta vår support på {email}",
  "components.cancelSubscriptionModal.info": "Kan du berätta för oss varför du avslutar din Vacation Tracker-prenumeration?",
  "components.cancelSubscriptionModal.min20characters": "Minst 20 tecken",
  "components.cancelSubscriptionModal.missingFeatures": "Verktyget har inte de funktioner mitt team behöver",
  "components.cancelSubscriptionModal.missingFeaturesAdditionalInfo": "Kan du berätta med några få ord vilken funktion som saknas?",
  "components.cancelSubscriptionModal.moreInfo": "Vad kunde vi ha gjort för att behålla dig som kund?",
  "components.cancelSubscriptionModal.okText": "Avsluta nu",
  "components.cancelSubscriptionModal.option1": "Vi har bytt till en HR-programvara",
  "components.cancelSubscriptionModal.option2": "Vi har hittat ett annat verktyg för frånvarohantering",
  "components.cancelSubscriptionModal.option3": "Verktyget har inte de funktioner mitt team behöver",
  "components.cancelSubscriptionModal.option4": "Priset överstiger vår budget",
  "components.cancelSubscriptionModal.option5": "Annat",
  "components.cancelSubscriptionModal.other": "Annat",
  "components.cancelSubscriptionModal.otherAdditionalInfo": "Annat",
  "components.cancelSubscriptionModal.priceOverBudget": "Priset är över vår prisbudget",
  "components.cancelSubscriptionModal.priceOverBudgetAdditionalInfo": "Vad är din budget för ett sådant här verktyg?",
  "components.cancelSubscriptionModal.title": "Vi är ledsna att se dig gå...",
  "components.cancelSubscriptionModal.weAreMovingToPayroll": "Vi går över till ett lönesystem",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfo": "Kan du berätta vilket lönesystem ni byter till?",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfoIntegration": "Om vi hade en integration med detta lönesystem, skulle du överväga att stanna?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolution": "Vi har bytt till en HR-programvara",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfo": "Kan du berätta vilket HR-system ni byter till?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfoIntegration": "Om vi hade en integration med detta HR-system, skulle du överväga att stanna?",
  "components.cancelTrialSubscriptionModal.cancelText": "Fortsätt prova",
  "components.cancelTrialSubscriptionModal.info": "Förklara i en mening varför du vill avbryta din provperiod?",
  "components.cancelTrialSubscriptionModal.okText": "Avbryt provperiod",
  "components.cancelTrialSubscriptionModal.title": "Vi är ledsna att se dig gå...",
  "components.changeTeamForm.changeTeamWarning": "Välj den avdelning du vill flytta användaren till.",
  "components.changeTeamForm.changeUserTeam": "Ändra användarens avdelning",
  "components.changeTeamForm.selectTeam": "Välj avdelning",
  "components.changeTeamForm.selectTeamError": "Vänligen välj en avdelning.",
  "components.companyAlreadySignup.connectWith": " Anslut med {existsOnAnotherPlatform}",
  "components.companyAlreadySignup.info": "Vi har noterat att ditt företag redan har ett Vacation Tracker-konto. Om du menade att logga in, klicka här:",
  "components.companyAlreadySignup.signupWithMail": "Anslut med din jobbmejl",
  "components.companyAlreadySignup.title": "Ditt företag använder redan Vacation Tracker",
  "components.companyLogs.dateRangeLimit": "Loggar äldre än 3 månader är endast tillgängliga i den fullständiga planen.",
  "components.companyLogs.rangeLimit": "Datumintervallet kan vara högst ett år",
  "components.companySettings.contactEmailInfo": "Din nya kontaktmejladress för företaget är: {email}.",
  "components.comparePlatformsModal.forMoreInfoSeeOurHelpdesk": "För att lära dig mer, besök vår <helpDesk>Helpdesk</helpDesk>.",
  "components.comparePlatformsModal.info": "Oavsett vilken plattform du väljer kommer du att ha tillgång till alla huvudfunktioner i Vacation Tracker. Vissa har dock ytterligare fördelar som inte finns i andra. Se jämförelsetabellen nedan.",
  "components.comparePlatformsModal.table.comingSoon": "Kommer snart",
  "components.comparePlatformsModal.table.email": "E-post",
  "components.comparePlatformsModal.table.feature": "Funktion",
  "components.comparePlatformsModal.table.google": "Google",
  "components.comparePlatformsModal.table.importUsersFromGoogleWorkspace": "Importera användare från din Google Workspace",
  "components.comparePlatformsModal.table.importUsersFromMicrosoftTeams": "Importera användare från din Microsoft Active Directory",
  "components.comparePlatformsModal.table.importUsersFromSlack": "Importera användare från din Slack-arbetsyta",
  "components.comparePlatformsModal.table.inviteUsersViaEmail": "Bjud in användare via e-post",
  "components.comparePlatformsModal.table.manageLeaveTypes": "Hantera ledighetstyper, helgdagar och ledighetsförfrågningar",
  "components.comparePlatformsModal.table.microsoft": "Microsoft",
  "components.comparePlatformsModal.table.multiTeamLocation": "Konfigurera flera avdelningar och platser med olika ledighetspolicyer",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromMicrosoftTeams": "Skicka in och hantera ledighetsförfrågningar direkt från Microsoft Teams",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromSlack": "Skicka in och hantera ledighetsansökningar direkt från Slack",
  "components.comparePlatformsModal.table.slack": "Slack",
  "components.comparePlatformsModal.table.syncToGoogleCalendar": "Synkronisera Vacation Tracker med Google Kalender",
  "components.comparePlatformsModal.table.syncToOutlookCalendar": "Synkronisera Vacation Tracker med Outlook 365 Kalender",
  "components.comparePlatformsModal.title": "Vilken Vacation Tracker-integration passar mig bäst?",
  "components.configureImport.configureProratedLeaveTypesErrorMessage": "Du måste välja minst en ledighetstyp",
  "components.configureImport.defaultDepartmentTooltip": "Alla importerade användare kommer att läggas till i denna avdelning. När de väl är importerade kan du placera dem i en annan avdelning.",
  "components.configureImport.defaultLocationTooltip": "Alla importerade användare kommer att läggas till på denna plats. När de väl är importerade kan du placera dem på en annan plats.",
  "components.configureImport.guestUsers": "Vill du importera gästanvändare från flera kanaler?",
  "components.configureImport.guestUsersTooltip": "Om detta alternativ är aktiverat kommer gästanvändare från flera kanaler i din arbetsyta att importeras till Vacation Tracker.",
  "components.configureImport.proRateLabel": "Proportionera de nya användarnas kvot för i år?",
  "components.configureImport.proRateLeaveTypes": "Tillämpa proportionering på följande ledighetstyper",
  "components.configureImport.proRateLeaveTypesTooltip": "Vänligen lista de ledighetstyper för vilka du vill proportionera tillgängliga användarledighetskvot.",
  "components.configureImport.proRateTooltip": "Vänligen välj om du vill att vårt system ska beräkna de importerade användarnas tillgängliga ledighetskvot för det första året baserat på dina företagsinställningar och användarens startdatum.",
  "components.createLeaveType.assignToLocationManyInProgress": "Tilldelar ledighetspolicy till platser",
  "components.createLeaveType.assignToLocationManyInProgressCompleted": "Tilldelning av ledighetspolicy till platser slutförd",
  "components.createLeaveType.assignToLocationOneInProgress": "Tilldelar ledighetspolicy till plats",
  "components.createLeaveType.assignToLocationOneInProgressCompleted": "Tilldelning av ledighetspolicy till plats slutförd",
  "components.createLeaveType.createInProgress": "Skapar ledighetstypen {leaveTypeName}",
  "components.createLeaveTypeForm.assignAndConfigure": "Tilldela & Konfigurera",
  "components.createLeaveTypeForm.assignToLocations": "Tilldela till platser",
  "components.createLeaveTypeForm.calendarStatusBusy": "Upptagen",
  "components.createLeaveTypeForm.calendarStatusFree": "Ledig",
  "components.createLeaveTypeForm.calendarStatusLabel": "Visa denna ledighetstyp i din kalender som",
  "components.createLeaveTypeForm.calendarStatusLabelTooltipInfo": "Välj 'Upptagen' för fullständig frånvaro som semester, vilket signalerar otillgänglighet, eller 'Ledig' för distansarbetsdagar, vilket indikerar att du inte är på kontoret men tillgänglig.",
  "components.createLeaveTypeForm.continueCreatingLeaveType": "Ja, jag vill skapa ledighetstyp",
  "components.createLeaveTypeForm.createAndAssign": "Skapa och tilldela",
  "components.createLeaveTypeForm.createLeaveType": "Skapa ledighetstyp",
  "components.createLeaveTypeForm.justLastStep.assign": "Tilldela",
  "components.createLeaveTypeForm.justLastStep.title": "Tilldela ledighetspolicy",
  "components.createLeaveTypeForm.Locations": "Platser",
  "components.createLeaveTypeForm.locationsInfo": "Tilldela <strong>{leaveTypeName}</strong> till alla platser eller en specifik plats.",
  "components.createLeaveTypeForm.locationsPolicyHint": "För att redigera policyn (reglerna) för denna ledighetstyp, gå till den specifika {locations, plural, =1 {platsen} other {platserna}}:",
  "components.createLeaveTypeForm.selectLocation": "Välj plats",
  "components.createLeaveTypeForm.similarLeaveTypeChecking": "Kontrollerar befintliga ledighetstyper...",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAreYouSure": "Vill du fortfarande skapa en ny typ (<strong>{newLeaveTypeName}</strong>)?",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAssigningTip": "Du kan tilldela en ledighetstyp till flera platser och anpassa regler per plats (som kvoter, överförda dagar, intjänande, etc.). Inget behov av att skapa separata typer för varje plats!",
  "components.createLeaveTypeForm.similarLeaveTypeInfoRecommend": "Vi rekommenderar att använda en enda ledighetstyp över flera platser för att upprätthålla användarledighetsdata och konsistens.",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSetRules": "Ställ in de platsspecifika reglerna för:",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSimilarLeaveType": "En liknande ledighetstyp finns redan (<strong>{leaveTypeName}</strong>).",
  "components.createLeaveTypeForm.similarLeaveTypeTitle": "Liknande ledighetstyp",
  "components.createLeaveTypeForm.title": "Skapa ledighetstyp & tilldela ledighetspolicy",
  "components.denyWithReasonForm.deny": "Neka",
  "components.denyWithReasonForm.denyLeaveRequest": "Neka ledighetsansökan",
  "components.denyWithReasonForm.reason": "Lägg till en anledning",
  "components.denyWithReasonForm.reasonIsRequired": "Detta fält är obligatoriskt.",
  "components.downgradeAlert.cancelChangeToPlan": "Avbryt ändring till <strong>{nextPlan}</strong> plan",
  "components.downgradeAlert.cancelSwitchPlan": "Vill du avbryta den planerade ändringen till <strong>{nextPlan}</strong> {nextPeriod} plan?",
  "components.downgradeAlert.noIWantToSwitch": "Nej, jag vill byta till <strong>{nextPlan}</strong> {nextPeriod} plan",
  "components.downgradeAlert.yesIWantToStay": "Ja, jag vill stanna kvar på <strong>{currentPlan}</strong> {currentPeriod} plan",
  "components.entitlementByRole.additionalDays": "extra dag(ar)",
  "components.entitlementByRole.additionalHours": "extra timme(ar)",
  "components.entitlementByRole.applayToUsersWithLabel": "Tillämpa på användare med etikett",
  "components.entitlementByRole.applyToUsersWithLabel": "Tillämpa på användare med etikett",
  "components.entitlementByRole.completePlanOnly": "Denna funktion är exklusivt tillgänglig för användare som prenumererar på vår Kompletta plan.",
  "components.entitlementByRole.noLabels": "Det finns inga skapade etiketter eller alla används för andra Rättigheter efter roll-automatiseringar.",
  "components.entitlementByRole.startApplayingEnintlementByRoleFrom": "Börja tillämpa rättigheter efter roll från",
  "components.entitlementByRole.startApplyingEntitlementByRoleFrom": "Börja tillämpa rättigheter efter roll från",
  "components.fakeDoorMailLoginModal.discount": "Som en del av gruppen för tidig åtkomst får du också 50% rabatt på Vacation Tracker under de första 3 månaderna.",
  "components.fakeDoorMailLoginModal.info": "Vi arbetar för närvarande med att aktivera registrering via e-post. För att få tidig åtkomst, vänligen skriv under nedan och vi återkommer inom kort.",
  "components.fakeDoorMailLoginModal.no": "Nej, jag väntar.",
  "components.fakeDoorMailLoginModal.title": "Intresserad av tidig åtkomst? 🚀 ",
  "components.fakeDoorMailLoginModal.yes": "Ja, jag vill få tidig åtkomst.",
  "components.feedbackModal.button": "Ge oss feedback",
  "components.feedbackModal.emailPlaceholder": "Vad är din e-postadress?",
  "components.feedbackModal.feedbackForm": "Feedbackformulär",
  "components.feedbackModal.feedbackMessage": "Feedbackmeddelande",
  "components.feedbackModal.howDoYouLikeApp": "Hur tycker du om vår app?",
  "components.feedbackModal.howDoYouLikeAppPlaceholder": "Välj ett alternativ...",
  "components.feedbackModal.iLikeIt": "Jag gillar den",
  "components.feedbackModal.iLoveIt": "Jag älskar den",
  "components.feedbackModal.ItMakesMeCry": "Den får mig att gråta",
  "components.feedbackModal.itsOk": "Den är okej",
  "components.feedbackModal.messagePlaceholder": "Ditt feedbackmeddelande",
  "components.feedbackModal.Nah": "Nej",
  "components.feedbackModal.namePlaceholder": "Ange ditt namn",
  "components.feedbackModal.ratingRequired": "Vänligen välj ett av de angivna betygen",
  "components.feedbackModal.sendFeedback": "Skicka feedback",
  "components.feedbackModal.yourEmail": "Din e-postadress",
  "components.feedbackModal.yourEmailInfo": "Om din feedback kräver ett svar, vänligen ange din e-postadress så att vi kan kontakta dig.",
  "components.feedbackModal.yourName": "Ditt namn",
  "components.filterSimple.filterBy": "Filtrera efter",
  "components.filterSimple.selectType": "Välj typ",
  "components.filterSimple.tooltipInfo": "Om du inte väljer några filter kommer exporten att inkludera alla användare.",
  "components.generalSettings.aiBotHelperLabel": "Tillåt kommunikation med Vacation Tracker-botten",
  "components.generalSettings.aiBotHelperLabelDisable": "Inaktivera kommunikation med Vacation Tracker-botten",
  "components.generalSettings.aiBotHelperTooltip": "Meddelanden med botten är ett bekvämt och effektivt sätt för anställda att begära ledighet från arbetet. Anställda kan enkelt skicka en ledighetsförfrågan till botten och specificera detaljer som start- och slutdatum, antal begärda dagar och typ av ledighet.",
  "components.heatmap.clickForMore": "Klicka för mer information",
  "components.heatmap.holiday": "Helgdag",
  "components.heatmap.holidayDescription": "{location} är borta för {holiday}",
  "components.heatmap.loading": "laddar {day}",
  "components.heatmap.nextMonth": "Nästa månad",
  "components.heatmap.nonWorkingDay": "icke-arbetsdag",
  "components.heatmap.previousMonth": "Föregående månad",
  "components.heatmap.showLess": "Visa mindre",
  "components.heatmap.showMore": "Visa {amount} mer",
  "components.heatmap.today": "idag",
  "components.helpDrawer.adminProductTourInfo": "Som administratör har du den högsta behörighetsnivån i Vacation Tracker och kan anpassa alla organisationens inställningar genom administratörspanelen.\n\nDashboarden låter dig:\n- Begära ledighet eller lägga till ledighet för andra avdelningsmedlemmar.\n- Få en fullständig översikt genom Dashboard-hemsidan och kalendern.\n- Skapa och konfigurera flera avdelningar (Tilldela godkännare)\n- Ställa in aviseringar\n- Hantera användare (Aktiva, Inaktiva, Borttagna).\n- Skapa och hantera platser (Ledighetstyper, Helgdagar, Aviseringar)\n\nBotten låter dig:\n- Begära ledighet\n- Godkänna/Neka ledighetsförfrågningar\n- Ta emot dagliga/veckovisa aviseringar\n\nOm dina frågor eller funderingar förblir obesvarade, kontakta oss när som helst. Att prata med våra kunder är den bästa delen av vår dag:",
  "components.helpDrawer.adminProductTourTitle": "Din roll: Administratör",
  "components.helpDrawer.approverProductTourInfo": "Som godkännare har du den näst högsta behörighetsnivån i Vacation Tracker och kan godkänna eller neka ledighetsförfrågningar från användare i den avdelning du har tilldelats som godkännare för.\n\nDashboarden låter dig:\n- Begära ledighet eller lägga till ledighet för andra avdelningsmedlemmar.\n- Få en fullständig översikt genom Dashboard-hemsidan och kalendern.\n- Ställa in aviseringar\n- Visa din användarprofil.\n- Hantera förfrågningar (Godkänna/Neka förfrågningar, Visa historik).\n\nBotten låter dig:\n- Begära ledighet\n- Godkänna/Neka ledighetsförfrågningar\n- Ta emot dagliga/veckovisa aviseringar\n\nOm dina frågor eller funderingar förblir obesvarade, kontakta oss när som helst. Att prata med våra kunder är den bästa delen av vår dag:",
  "components.helpDrawer.approverProductTourTitle": "Din roll: Godkännare",
  "components.helpDrawer.google.adminProductTourInfo": "Som administratör har du den högsta behörighetsnivån i Vacation Tracker och kan anpassa alla organisationens inställningar genom administratörspanelen.\n\nDashboarden låter dig:\n- Begära ledighet eller lägga till ledighet för andra avdelningsmedlemmar.\n- Få en fullständig översikt genom Dashboard-hemsidan och kalendern.\n- Skapa och konfigurera flera avdelningar (Tilldela godkännare)\n- Ställa in aviseringar\n- Hantera användare (Aktiva, Inaktiva, Borttagna).\n- Skapa och hantera platser (Ledighetstyper, Helgdagar, Aviseringar)\n\nOm dina frågor eller funderingar förblir obesvarade, kontakta oss när som helst. Att prata med våra kunder är den bästa delen av vår dag:",
  "components.helpDrawer.google.approverProductTourInfo": "Som godkännare har du den näst högsta behörighetsnivån i Vacation Tracker och kan godkänna eller neka ledighetsförfrågningar från användare i den avdelning du har tilldelats som godkännare för.\n\nDashboarden låter dig:\n- Begära ledighet eller lägga till ledighet för andra avdelningsmedlemmar.\n- Få en fullständig översikt genom Dashboard-hemsidan och kalendern.\n- Ställa in aviseringar\n- Visa din användarprofil.\n- Hantera förfrågningar (Godkänna/Neka förfrågningar, Visa historik).\n\nOm dina frågor eller funderingar förblir obesvarade, kontakta oss när som helst. Att prata med våra kunder är den bästa delen av vår dag:",
  "components.helpDrawer.google.userProductTourInfo": "Som Vacation Tracker-användare kan du skicka in och hantera dina ledighetsförfrågningar via Dashboard, samt få en fullständig översikt över planerade frånvaron inom din organisation.\n\nDashboarden låter dig:\n- Begära ledighet\n- Få en fullständig översikt genom Dashboard-hemsidan och kalendern.\n- Visa din användarprofil.\n\nOm dina frågor eller funderingar förblir obesvarade, kontakta oss när som helst. Att prata med våra kunder är den bästa delen av vår dag:",
  "components.helpDrawer.helpDeskInfo": "Besök vår Helpdesk för att lära dig mer om vår produkt och få svar på några vanliga frågor.",
  "components.helpDrawer.learnMore": "Lär dig mer",
  "components.helpDrawer.needhelp": "Behöver du hjälp?",
  "components.helpDrawer.quickTourInfo": "Vi har satt ihop några korta steg som vi rekommenderar att du följer för att snabbt sätta upp din organisation.",
  "components.helpDrawer.quickTourTitle": "Snabbtur",
  "components.helpDrawer.supportInfo": "Om du har några frågor eller behöver hjälp, starta en chatt med vårt kundsupportteam eller maila oss på <link>hello@vacationtracker.io</link>",
  "components.helpDrawer.supportTitle": "Support",
  "components.helpDrawer.userProductTourInfo": "Som Vacation Tracker-användare kan du skicka in och hantera dina ledighetsförfrågningar via Dashboard eller botten, samt få en fullständig översikt över planerade frånvaron inom din organisation.\n\nDashboarden låter dig:\n- Begära ledighet\n- Få en fullständig översikt genom Dashboard-hemsidan och kalendern.\n- Visa din användarprofil.\n\nBotten låter dig:\n- Begära ledighet\n- Ta emot dagliga/veckovisa aviseringar\n\nOm dina frågor eller funderingar förblir obesvarade, kontakta oss när som helst. Att prata med våra kunder är den bästa delen av vår dag:",
  "components.helpDrawer.userProductTourTitle": "Din roll: Användare",
  "components.importHolidaysForm.backButton": "Tillbaka",
  "components.importHolidaysForm.selectCountry": "Välj land",
  "components.importHolidaysForm.selectHolidays": "Välj helgdagar",
  "components.importHolidaysForm.selectYear": "Välj år",
  "components.imports.bfDescription": "Initialt överfört är ledighetssaldot en användare har fört över från föregående år, då Vacation Tracker inte var en del av er organisation. Observera att du bara kan redigera det initialt överförda ledighetssaldot, medan den tillgängliga ledigheten du kan överföra de kommande åren kommer att beräknas av Vacation Tracker.",
  "components.imports.description": "Du kan importera en fil i Excel- eller CSV-format. Använd vår mall eller din egen fil och mappa de nödvändiga kolumnerna - inget behov av att modifiera stora filer om de är redo.",
  "components.imports.descriptionFieldAmount": "Obligatoriskt. Ange beloppet användaren har fört över från föregående år för den specifika ledighetstypen.",
  "components.imports.descriptionFieldApproverEmail": "Obligatoriskt.",
  "components.imports.descriptionFieldDepartment": "Valfritt. Fyll i för att uppdatera användarens avdelning.",
  "components.imports.descriptionFieldEmail": "Obligatoriskt.",
  "components.imports.descriptionFieldEmployeeId": "Valfritt, används för integration med andra system.",
  "components.imports.descriptionFieldEndDate": "Obligatoriskt. Ange slutdatumet för begäran. Vänligen följ korrekt datumformat (ÅÅÅÅ-MM-DD).",
  "components.imports.descriptionFieldHourFrom": "Starttiden för tim- eller halvdagsbegäran. Detta fält är valfritt och gäller endast för sådana fall.",
  "components.imports.descriptionFieldHourTo": "Sluttiden för tim- eller halvdagsbegäran. Detta är också valfritt och gäller endast i relevanta scenarier.",
  "components.imports.descriptionFieldIsHalfDay": "Ange om begäran gäller en halv dag. Detta fält är valfritt och gäller endast för begäran som är mindre än en hel dag.",
  "components.imports.descriptionFieldLeaveType": "Obligatoriskt. Ange det exakta namnet på ledighetstypen (t.ex. Semester, Sjukledighet, Obetald ledighet) som de angivna dagarna eller timmarna är kopplade till. Se till att ange det exakta namnet på ledighetstypen.",
  "components.imports.descriptionFieldLocation": "Valfritt. Fyll i för att uppdatera användarens plats.",
  "components.imports.descriptionFieldReason": "Anledning - Kan vara obligatoriskt eller valfritt beroende på dina inställningar för ledighetspolicy.",
  "components.imports.descriptionFieldReasonToil": "Obligatoriskt.",
  "components.imports.descriptionFieldStartDate": "Obligatoriskt. Ange startdatumet för begäran. Vänligen följ korrekt datumformat (ÅÅÅÅ-MM-DD).",
  "components.imports.descriptionFieldStatus": "Valfritt.",
  "components.imports.descriptionFieldUserEndDate": "Valfritt. Fyll i för att uppdatera användarens anställnings slutdatum.",
  "components.imports.descriptionFieldUserStartDate": "Valfritt. Fyll i för att uppdatera användarens anställnings startdatum.",
  "components.imports.descriptionImportDetails": "Uppdatera användarprofildata för flera användare samtidigt.",
  "components.imports.descriptionImportLeaves": "Importera godkända ledigheter för flera ledighetstyper och användare samtidigt.",
  "components.imports.descriptionImportQuotas": "Importera initialt överförda saldon för flera ledighetstyper och användare samtidigt.",
  "components.imports.descriptionImportToils": "Importera godkänd kompensationsledighet för flera ledighetstyper och användare samtidigt.",
  "components.imports.feedback": "Behöver du en annan importtyp? Låt oss veta vad du behöver!",
  "components.imports.fieldsDescriptions": "Du kan snabbt importera följande data:",
  "components.imports.intro": "Importera snabbt stora datamängder via Excel eller CSV för att spara tid och minska manuellt arbete.",
  "components.imports.titleImportDetails": "Användarprofildata",
  "components.imports.titleImportLeaves": "Ledigheter",
  "components.imports.titleImportQuotas": "Initialt överförda",
  "components.imports.titleImportToils": "Kompensationsledighet",
  "components.importUsersForm.button.importAllUsers": "Importera och aktivera alla {users} användare",
  "components.importUsersForm.button.importSelectedUsers": "Importera {noOfUsers} valda {noOfUsers, plural, =1 {användare} other {användare}}",
  "components.importUsersForm.button.importUsersAutmaticallyFromPlatform": "Vill du importera alla användare från {platform} automatiskt?",
  "components.importUsersForm.calculationStartYearInfo": "Vacation Tracker kommer att beräkna ledigheter från och med {year}",
  "components.importUsersForm.cancel": "Avbryt",
  "components.importUsersForm.chooseUsersPlaceholder": "Teammedlemmens namn",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOff": "Vacation Tracker kommer inte att automatiskt importera gästanvändare från flera kanaler från din arbetsyta. Du kan fortfarande importera dem manuellt vid behov.",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOn": "Vacation Tracker kommer att importera gästanvändare från flera kanaler från din arbetsyta automatiskt.",
  "components.importUsersForm.confirmImportUsersModalContentChangeLaterInfo": "Du kan ändra dessa inställningar senare i Vacation Tracker-instrumentpanelen",
  "components.importUsersForm.confirmImportUsersModalContentDepartmentAndLocationInfo": "Nya {noOfUsers, plural, =1 {användare} other {användare}} kommer att läggas till i avdelningen {chosenDepartment} och på platsen {chosenLocation}",
  "components.importUsersForm.confirmImportUsersModalContentNoOfUsers": "Importera {noOfUsers} {noOfUsers, plural, =1 {användare} other {användare}}",
  "components.importUsersForm.confirmImportUsersModalContentOpener": "Du valde:",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfo": "Proportionera användarnas kvot för följande ledighetstyper: {leaveTypes}",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfoTurnOff": "Användarnas kvoter kommer inte längre att beräknas proportionellt",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessage": "Skicka ett välkomst{channel, select, Email {mejl} other {meddelande}} {channel} till de(n) nya användaren/användarna",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessageTurnOff": "Vi kommer inte längre att skicka ett välkomstmeddelande till nya användare",
  "components.importUsersForm.confirmImportUsersModalTitle": "Bekräfta import av {noOfUsers} valda användare",
  "components.importUsersForm.consent.line1": "För att importera användare behöver du få administratörsmedgivande från din Microsoft Teams-administratör. För att få detta engångsmedgivande, skicka följande länk till din Microsoft Teams-administratör:",
  "components.importUsersForm.consent.line2": "När du har fått medgivandet, stäng och öppna denna modal igen, så kommer detta meddelande att försvinna.",
  "components.importUsersForm.consent.warning": "Vänligen stäng och öppna denna modal igen när du har fått medgivandet för att kunna importera alla användare.",
  "components.importUsersForm.googleAPIErrorMessage": "Det verkar vara ett problem med inställningarna i din Google-katalog. Kontakta din administratör och skicka dem följande fel:",
  "components.importUsersForm.importAllUsers": "Vill du importera alla användare?",
  "components.importUsersForm.importAllUsersExceedingLimt": "Du har ett stort antal användare i din katalog. Om du vill importera alla användare, kontakta vårt supportteam så kan vi aktivera importen. Alternativt kan du välja specifika användare att importera.",
  "components.importUsersForm.importAllUsersTooltip": "{noOfUsersToImport} {noOfUsersToImport, plural, =1 {användare} other {användare}} från {platform} kommer att importeras till Vacation Tracker. Detta kan påverka din fakturering.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimit": "Du har ett stort antal användare i din katalog. Om du vill importera användare automatiskt, kontakta vårt supportteam så kan vi aktivera importen.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimt": "Du har ett stort antal användare i din katalog. Om du vill importera användare automatiskt, kontakta vårt supportteam så kan vi aktivera importen.",
  "components.importUsersForm.importUsersAutomaticallyTooltip": "När en nyligen tillagd användare i din {platform} importeras till Vacation Tracker, kommer de att få ett kort meddelande som förklarar vad Vacation Tracker är och hur man navigerar i det.",
  "components.importUsersForm.importUsersTitle": "Importera användare",
  "components.importUsersForm.newCompanyNameInfo": "Nytt företagsnamn kommer att vara: {newName}",
  "components.importUsersForm.selectUsers": "Välj användare att importera:",
  "components.importUsersForm.selectUsersNext": "Konfigurera inställningar för valda användare",
  "components.importUsersForm.selectUsersStepName": "Välj användare",
  "components.importUsersForm.sendWelcomeMessageLabel": "Skicka ett välkomstmeddelande till de nya användarna?",
  "components.importUsersForm.trackingQuotaInfoDays": "Vacation Tracker kommer att spåra varaktigheten för alla ledigheter och kvoter i dagar.",
  "components.importUsersForm.trackingQuotaInfoHours": "Vacation Tracker kommer att spåra varaktigheten för alla ledigheter och kvoter i timmar.",
  "components.importUsersForm.userEndDateInfo": "Användare med ett slutdatum kommer inte att kunna skicka in ledighetsbegäran efter det datumet, och vi kommer automatiskt att avaktivera dem.",
  "components.importUsersForm.userEndDateTurnOffInfo": "Användare med ett slutdatum kommer att kunna skicka in ledighetsbegäran efter det datumet, och vi kommer inte automatiskt att avaktivera dem.",
  "components.importUsersForm.usersIsRequired": "Detta fält är obligatoriskt.",
  "components.importUsersForm.warningAllUsersImport": "Vacation Tracker kan automatiskt synkronisera, importera och radera användare från din {platform}.",
  "components.importUsersForm.warningNumberOfUsersToImport": "När du klickar på knappen \"Importera och aktivera alla användare\" kommer du att aktivera alla {users} användare från din katalog. Denna åtgärd kan påverka din fakturering.",
  "components.importUsersForm.warningOfUsersImport": "Import av {noOfUsers} valda {noOfUsers, plural, =1 {användare} other {användare}} kan påverka din fakturering.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNewBucket": "Att lägga till {noOfUsers} {noOfUsers, plural, =1 {användare} other {användare}} kommer att öka din nästa faktura till {amount} (exklusive moms) från och med {startDate}.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNoOfUsers": "En uppskattad proportionell avgift på {proratedAmount} (exklusive moms) kommer att tillämpas idag.",
  "components.importUsersForm.warningOfUsersImportNoChange": "Du är på väg att importera {noOfUsers} {noOfUsers, plural, =1 {användare} other {användare}}. Din fakturering kommer inte att påverkas.",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimit": "Du har nått din nuvarande platsgräns ({seats}). Vill du köpa fler?",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimitImportAll": "Du kan inte importera alla användare från din arbetsyta/katalog eftersom du då kommer att överskrida din nuvarande platsgräns ({seats}). Vill du köpa fler?",
  "components.importUsersForm.warningOfUsersImportProrateAdditionalNewBucketMonthly": "Import av {noOfUsers} {noOfUsers, plural, =1 {användare} other {användare}} kommer att påverka din fakturering. Din nästa faktura kommer att vara {amount} (exklusive moms) den {startDate}",
  "components.importUsersForm.warningOfUsersImportTrial": "Du är för närvarande i provperioden. Du kommer att debiteras {amount} (exklusive moms) den {startDate}",
  "components.importUsersForm.warningOfUsersImportTrialWithoutPaymentMethod": "Du är i en provperiod och du kommer inte att debiteras för någon ytterligare användarimport före {stripeCurrentPeriodEnd}. Lägg till din faktureringsinformation för att se kostnadsestimatet.",
  "components.insertHolidaysForm.back": "Tillbaka",
  "components.insertHolidaysForm.deselectAll": "Avmarkera alla",
  "components.insertHolidaysForm.findHolidays": "Hitta helgdagar",
  "components.insertHolidaysForm.insertHolidays": "Importera helgdagar",
  "components.insertHolidaysForm.pleaseChooseACountry": "Välj ett land",
  "components.insertHolidaysForm.saveHolidays": "Spara helgdagar",
  "components.insertHolidaysForm.selectAll": "Välj alla",
  "components.insertHolidaysForm.selectHolidays": "Välj helgdagar",
  "components.insertHolidaysForm.subtitle": "Ändringen kan påverka antalet semesterdagar för vissa användare. Dessa användare kommer att få dag(ar) tillagda till sin tillgängliga kvot för deras begärda ledighet. Är du säker på att du vill fortsätta?",
  "components.insertHolidaysForm.title": "Importering av helgdagar kommer att skriva över alla tidigare tillagda helgdagar.",
  "components.inviteAndManageUsersWithEmail.canNotInviteYourself": "Du kan inte bjuda in dig själv",
  "components.inviteAndManageUsersWithEmail.duplicateEmail": "E-postadressen är redan inmatad.",
  "components.inviteAndManageUsersWithEmail.header": "E-postinbjudningar",
  "components.inviteAndManageUsersWithEmail.userAlreadyExists": "Användare med angiven e-post finns redan",
  "components.inviteBotToPrivateChannel.descriptionOne": "För att lägga till Vacation Tracker i en privat kanal, vänligen skriv",
  "components.inviteBotToPrivateChannel.descriptionTwo": "i kanalen och uppdatera listan",
  "components.inviteBotToPrivateChannel.text": "Vill du välja en privat kanal?",
  "components.inviteBotToPrivateChannel.title": "Lägg till privat kanal",
  "components.leaveBox.approve": "Godkänn",
  "components.leaveBox.approvedBy": "Godkänd av",
  "components.leaveBox.approveLeaveText": "Vänligen bekräfta att du vill godkänna ledighetsbegäran.",
  "components.leaveBox.approveLeaveTitle": "Godkänn ledighetsbegäran",
  "components.leaveBox.autoApproved": "Automatiskt godkänd",
  "components.leaveBox.cancelLeave": "Avbryt ledighet",
  "components.leaveBox.cancelLeaveConfirm": "Är du säker på att du vill avbryta ledigheten?",
  "components.leaveBox.cancelLeaveTitle": "Avbryt ledighet",
  "components.leaveBox.columns.daysOut": "{value} {value, plural, =1 {dag} other {dagar}}",
  "components.leaveBox.columns.hoursOut": "{value} {value, plural, =1 {timme} other {timmar}}",
  "components.leaveBox.daysLeft": "Återstående ledighet:",
  "components.leaveBox.daysOut": "Det är {value} {value, plural, =1 {dag} other {dagar}} borta från kontoret.",
  "components.leaveBox.deny": "Avslå",
  "components.leaveBox.denyLeaveConfirm": "Vänligen bekräfta att du vill avslå ledighetsbegäran.",
  "components.leaveBox.denyLeaveTitle": "Avslå ledighetsbegäran",
  "components.leaveBox.denyWithReason": "Avslå med anledning",
  "components.leaveBox.edit": "Redigera",
  "components.leaveBox.formattedLeaveInfo": "Det är {leave} borta från kontoret.",
  "components.leaveBox.hoursOut": "Det är {value} {value, plural, =1 {timme} other {timmar}} borta från kontoret.",
  "components.leaveBox.leavesBoxHalfDay": "Kommer att vara borta för en {leaveTypeName} den {leaveStartDate} från {startHour} till {endHour}.",
  "components.leaveBox.leavesBoxHalfDayPending": "Begäran om {leaveTypeName} den {leaveStartDate} från {startHour} till {endHour}.",
  "components.leaveBox.leavesBoxHalfDayToday": "Är ledig idag från {startHour} till {endHour}.",
  "components.leaveBox.leavesBoxHalfDayTodayWithoutReason": "Är ledig idag från {startHour} till {endHour}.",
  "components.leaveBox.leavesBoxHalfDayWithoutReason": "Tar ledigt den {leaveStartDate} från {startHour} till {endHour}.",
  "components.leaveBox.leavesOffTodayInfo": "Är borta för en {leaveTypeName} från {leaveStartDate} till {leaveEndDate}. Båda datumen inkluderade.",
  "components.leaveBox.leavesOffTodayWithoutReasonInfo": "Är borta från {leaveStartDate} till {leaveEndDate}. Inklusive båda datumen.",
  "components.leaveBox.leavesPendingInfo": "Begäran om {leaveTypeName} från {leaveStartDate} till {leaveEndDate}. Båda datumen inkluderade.",
  "components.leaveBox.leavesPendingOneDay": "Begäran om {leaveTypeName} den {leaveStartDate}.",
  "components.leaveBox.leavesUpcomingOneDayInfo": "Kommer att vara borta för {leaveTypeName} den {leaveStartDate}.",
  "components.leaveBox.leavesUpcomingOneDayWithoutReasonInfo": "Kommer att vara borta den {leaveStartDate}.",
  "components.leaveBox.reason": "Anledning",
  "components.leaveBox.toilDuration": "Varaktighet: {leave}",
  "components.leaveForm.addLeave": "Lägg till ledighet",
  "components.leaveForm.addLeaveDescription": "Använd detta formulär om du lägger till ledighet för en annan användare, eller om du lägger till ledighet för dig själv som inte kräver godkännande.",
  "components.leaveForm.connectedCalendarTimezone": "Ansluten {calendarType} kalenders tidszon är {timezone}",
  "components.leaveForm.date": "Datum",
  "components.leaveForm.editLeave": "Redigera ledighet",
  "components.leaveForm.editLeaveDescription.adminAndApprover": "Du kan redigera datumen för denna ledighet. Om du vill ändra ledighetstypen, vänligen avbryt ledigheten och lägg till en ny.",
  "components.leaveForm.editLeaveDescription.user": "Du kan redigera datumen och anledningen för denna ledighet. Om du vill ändra ledighetstypen, vänligen avbryt ledigheten och skapa en ny begäran.",
  "components.leaveForm.endTime": "Sluttid",
  "components.leaveForm.halfDay": "{leaveTypeName} (Halv dag)",
  "components.leaveForm.hourly": "{leaveTypeName} (Timvis)",
  "components.leaveForm.leaveTypeNamePartTime": "{leaveTypeName} (Timvis)",
  "components.leaveForm.minutesMustMatch": "Vänligen välj ett giltigt tidsintervall - minuterna måste matcha",
  "components.leaveForm.pleaseInputReason": "Anledning krävs",
  "components.leaveForm.pleaseSelectEndDate": "Välj ett slutdatum",
  "components.leaveForm.pleaseSelectLeaveType": "Välj en ledighetstyp",
  "components.leaveForm.pleaseSelectRangeTime": "Välj ett tidsintervall",
  "components.leaveForm.pleaseSelectstartDate": "Välj ett startdatum",
  "components.leaveForm.pleaseSelectUser": "Välj en användare",
  "components.leaveForm.pleaseSelectValidRangeTime": "Välj ett giltigt tidsintervall",
  "components.leaveForm.rangeDate": "Start- och slutdatum",
  "components.leaveForm.reason": "Anledning",
  "components.leaveForm.request": "Begär",
  "components.leaveForm.requestLeave": "Begär ledighet",
  "components.leaveForm.requestLeaveDescription": "Använd detta formulär om din begäran behöver godkännas av någon annan än dig själv.",
  "components.leaveForm.selectSubstituteApprover": "Välj ersättare för godkännande",
  "components.leaveForm.selectSubstituteApproverInfoMessage": "Välj en person som ska hantera dina inkommande ledighetsbegäranden medan du är borta. Ersättaren kommer att ha begränsade rättigheter, eftersom de endast kommer att kunna godkänna eller avslå ledighetsbegäranden. Detta steg är valfritt.",
  "components.leaveForm.selectUser": "Välj en användare",
  "components.leaveForm.startTime": "Starttid",
  "components.leaveForm.time": "Tid",
  "components.leaveForm.user": "Välj en användare",
  "components.leavePolicyForm.accrualBiWeeklySummary": "Användare kommer att tjäna in {amount} ({quota}/26) dagar varannan {day}",
  "components.leavePolicyForm.accrualBiWeeklySummaryHours": "Användare kommer att tjäna in {amount} ({quota}/26) timmar varannan {day}",
  "components.leavePolicyForm.accrualCap": "Aktivera intjänandetak",
  "components.leavePolicyForm.accrualCapRate": "Begränsa intjänade dagar till",
  "components.leavePolicyForm.accrualCapRateTimes": "gånger den årliga upplupna räntan",
  "components.leavePolicyForm.accrualCapTooltip": "Upplupet tak begränsar hur mycket betald ledighet en anställd kan ackumulera under en specifik period. Det definieras som en multipel av den årliga upplupna räntan, inte ett exakt antal.",
  "components.leavePolicyForm.accrualCapWarning": "Upplupet tak för {leaveTypeName} nått. Upplupning av nya dagar kommer att återupptas när några befintliga dagar har använts.",
  "components.leavePolicyForm.accrualCapWarning2": "Denna användare har nått det upplupna taket för {leaveTypeName} och kommer inte att ackumulera fler dagar förrän en del av det nuvarande saldot används.",
  "components.leavePolicyForm.accrualDay": "Upplupningsdag",
  "components.leavePolicyForm.accrualDayBiWeekly": "Upplupning tilldelas på",
  "components.leavePolicyForm.accrualDisabledInfo": "Stäng av den obegränsade kvoten och sätt kvoten till ett nummer större än 0 för att aktivera upplupningar.",
  "components.leavePolicyForm.accrualMonthlySummary": "Användare kommer att ackumulera {amount} ({quota}/12) dagar den {date} varje månad",
  "components.leavePolicyForm.accrualMonthlySummaryHours": "Användare kommer att ackumulera {amount} ({quota}/12) timmar den {date} varje månad",
  "components.leavePolicyForm.accruals": "Upplupningar",
  "components.leavePolicyForm.accrualSemiMonthlySummary": "Användare kommer att ackumulera {amount} ({quota}/24) dagar den {firstDate} och {secondDate} varje månad",
  "components.leavePolicyForm.accrualSemiMonthlySummaryHours": "Användare kommer att ackumulera {amount} ({quota}/24) timmar den {firstDate} och {secondDate} varje månad",
  "components.leavePolicyForm.accrualType": "Upplupningstyp",
  "components.leavePolicyForm.accrualTypeError": "Obligatoriskt",
  "components.leavePolicyForm.accrualTypeInfo": "Upplupen ledighet ackumuleras gradvis under året baserat på företagets policy. Om ditt företag har upplupningar, välj den upplupningstyp som bestämts av din ledning.",
  "components.leavePolicyForm.accrualWeeklySummary": "Användare kommer att ackumulera {amount} ({quota}/52) dagar varje {day}",
  "components.leavePolicyForm.accrualWeeklySummaryHours": "Användare kommer att ackumulera {amount} ({quota}/52) timmar varje {day}",
  "components.leavePolicyForm.addTitle": "Tilldela ledighetspolicy",
  "components.leavePolicyForm.allDays": "Alla",
  "components.leavePolicyForm.allowAddAndEditLeaveInPast": "Redigera & begär ledighet i det förflutna",
  "components.leavePolicyForm.allowAddAndEditLeaveInPastInfo": "Välj om du vill tillåta vanliga användare att redigera eller begära ledighet i det förflutna.",
  "components.leavePolicyForm.allowAdvanceAccrualUsage": "Tillåt begäran om upplupningsbaserad ledighet i förväg",
  "components.leavePolicyForm.allowAdvanceAccrualUsageInfo": "Tillåt anställda att begära ledighet baserat på deras prognostiserade upplupning av lediga dagar, snarare än endast vad de har ackumulerat vid tidpunkten för begäran. Detta innebär att om en anställd vet att de kommer att ha tillräckligt med upplupna dagar när den begärda ledigheten inträffar, kan de lämna in begäran i förväg, även om de inte har tillräckligt med dagar vid tidpunkten för begäran.",
  "components.leavePolicyForm.approvalRequired": "Godkännande krävs",
  "components.leavePolicyForm.assignLeavePolicy": "Tilldela ledighetspolicy",
  "components.leavePolicyForm.back": "Tillbaka",
  "components.leavePolicyForm.BI-WEEKLY": "Varannan vecka",
  "components.leavePolicyForm.cancel": "Avbryt",
  "components.leavePolicyForm.copyLeavePolicy": "Kopiera policy från",
  "components.leavePolicyForm.copyLeavePolicyDivider": "Du kan använda en mall från en annan ledighet eller plats.",
  "components.leavePolicyForm.copyLeavePolicyInfo": "Kopiera ledighetspolicyinställningar från en tidigare inställd ledighetstyp. I rullgardinsmenyn väljer du den ledighetstyp du vill kopiera ledighetspolicyn från, och inställningarna kommer att konfigureras automatiskt. Om du vill göra detta manuellt kan du göra det genom att konfigurera inställningarna nedan.",
  "components.leavePolicyForm.createLeaveTypeFormAccrualsInfo1": " Upplupningar är inaktiva när en ledighetstyp skapas. För att aktivera den, anpassa ledighetspolicyn för en specifik plats.",
  "components.leavePolicyForm.createLeaveTypeFormToilInfo1": "TOIL (Time Off In Lieu) är inaktiv när en ledighetstyp skapas. För att aktivera den, anpassa ledighetspolicyn för en specifik plats.",
  "components.leavePolicyForm.currentAccrualPeriod": "Nuvarande upplupningsperiod",
  "components.leavePolicyForm.daysPerYear": "Årlig ledighetskvot",
  "components.leavePolicyForm.defaultLeavePolicy": "Kopiera inte, jag börjar en ny policy",
  "components.leavePolicyForm.editTitle": "Redigera {leavePolicyName} ledighetspolicy för {locationName}",
  "components.leavePolicyForm.futureBroughtForward": "Framtida överföring",
  "components.leavePolicyForm.halfDays": "Tillåt halvdagar",
  "components.leavePolicyForm.hasUnlimitedDays": "Obegränsad kvot",
  "components.leavePolicyForm.hideLeaveType": "Dölj ledighetstyp",
  "components.leavePolicyForm.hideLeaveTypeInfo": "Välj om du vill dölja denna ledighetstyp från kalendern och notifikationerna. Endast administratörer, godkännare och användaren som har begärt ledighetstypen kommer att kunna identifiera den i kalendern.",
  "components.leavePolicyForm.hourlyIncrementsTooltip": "Tillåt dina teammedlemmar att ansöka om timbaserad ledighet.",
  "components.leavePolicyForm.isActive": "Aktiv",
  "components.leavePolicyForm.leavePolicy": "Ledighetspolicy",
  "components.leavePolicyForm.limitedNumberOfDays": "Begränsad",
  "components.leavePolicyForm.maxRolloverDays": "Max antal överfördagar",
  "components.leavePolicyForm.maxRolloverDaysError": "Obligatoriskt",
  "components.leavePolicyForm.maxRolloverDaysInfo": "Välj antalet dagar som kan överföras till nästa kalenderår för denna ledighetstyp.",
  "components.leavePolicyForm.maxRolloverDaysInfoHours": "Välj antalet timmar som kan överföras till nästa kalenderår för denna ledighetstyp.",
  "components.leavePolicyForm.MONTHLY": "Månadsvis",
  "components.leavePolicyForm.negativeBalance": "Tillåt negativt saldo",
  "components.leavePolicyForm.negativeBalanceInfo": "Välj om du vill tillåta användare att överskrida den tillåtna ledighetskvoten du har satt för denna ledighetstyp. Om du inte vill tillåta detta alternativ, lämna knappen avmarkerad.",
  "components.leavePolicyForm.nextAccrualDay": "Nästa intjänande tilldelas den",
  "components.leavePolicyForm.noLeaveTypes": "Det finns inga tillgängliga ledighetstyper. För att lägga till en ny ledighetstyp med en anpassad ledighetspolicy, gå till fliken Ledighetstyper för att skapa en ny ledighetstyp och lägg sedan till den till platsen.",
  "components.leavePolicyForm.NONE": "Ingen",
  "components.leavePolicyForm.noRollover": "Inaktiverad",
  "components.leavePolicyForm.override.affectedUsers": "Påverkade användare: <strong>{totalUsers}</strong>",
  "components.leavePolicyForm.override.change": "Ändring: <unlimited>Obegränsad ledighetspolicy ändring</unlimited><customDays>Uppdatering av ledighetskvot</customDays>",
  "components.leavePolicyForm.override.customLeaveQuotaInfo": "Några av de påverkade användarna (<strong>{totalUsers}</strong>) har anpassade ledighetskvot(er). Vill du åsidosätta denna inställning?",
  "components.leavePolicyForm.override.leaveType": "Ledighetstyp: <strong>{name}</strong>",
  "components.leavePolicyForm.override.location": "Plats: <strong>{name}</strong>",
  "components.leavePolicyForm.override.note": "Obs: Denna ändring kommer att påverka alla användare utan en anpassad ledighetskvot, oavsett vilket alternativ du väljer.",
  "components.leavePolicyForm.override.overrideDisable": "Åsidosätt deras anpassade ledighetskvot",
  "components.leavePolicyForm.override.overrideEnable": "Behåll den befintliga ledighetskvoten",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivated": "Pausa intjäning när användaren är inaktiverad",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedInfo": "Aktivera denna funktion för att tillfälligt pausa intjäning av ledighetsdagar för alla användare som är inaktiverade. När användaren återaktiveras kommer deras intjäning av ledighetsdagar automatiskt att fortsätta, vilket säkerställer en korrekt spårning av deras ledighetsrättigheter",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedUserInfo": "Enligt din organisations policy är ledighetsintjäningen pausad medan denna användares konto är inaktivt. Vänligen kontakta din administratör för mer information.",
  "components.leavePolicyForm.previouesBroughForwardAndFutureOnce": "Tidigare överförda och framtida",
  "components.leavePolicyForm.reasonRequired": "Anledning krävs",
  "components.leavePolicyForm.required": "Obligatoriskt",
  "components.leavePolicyForm.rollover": "Max antal",
  "components.leavePolicyForm.rolloverDaysPerYear": "Max antal dagar",
  "components.leavePolicyForm.rolloverPerYearDays": "Max antal dagar",
  "components.leavePolicyForm.rolloverPerYearHours": "Max antal timmar",
  "components.leavePolicyForm.rolloverSettings": "Inställningar för överföring",
  "components.leavePolicyForm.rolloverType": "Överförd",
  "components.leavePolicyForm.save": "Spara",
  "components.leavePolicyForm.saveLeavePolicy": "Spara",
  "components.leavePolicyForm.selectLeaveType": "Välj ledighetstyp",
  "components.leavePolicyForm.SEMI-MONTHLY": "Halvmånadsvis",
  "components.leavePolicyForm.updateLeavePolicy": "Uppdatera",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChanges": "När vill du tillämpa dessa ändringar?",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChangesInfo": "Välj tidsramen för att tillämpa dina ledighetsändringar. Välj 'Framtida överföring' för att endast justera kommande ledighetsdatum, eller 'Tidigare överföring och framtida' för att ändra både tidigare och framtida ledighetsdatum.",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChanges": "När vill du tillämpa dessa ändringar?",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChangesInfo": "Välj tidsramen för att tillämpa dina ledighetsändringar. Välj 'Framtida överföring' för att endast justera kommande ledighetsdatum, eller 'Tidigare överföring och framtida' för att ändra både tidigare och framtida ledighetsdatum.",
  "components.leavePolicyForm.yearlyQuota": "Årlig ledighetskvot",
  "components.leavePolicyForm.yearlyQuotaHours": "Årlig ledighetskvot (Timmar)",
  "components.leavePolicyForm.yearlyQuotaInfo": "Detta fält representerar den grundläggande årliga ledighetskvoten. Du kommer att kunna justera individuella kvoter manuellt via användarprofiler eller automatisera justeringar över olika användare med vår automationsfunktion.",
  "components.leaveRequestAction.added": "Ledighet <forUser>för </forUser>{requestor} tillagd <byActionCreator>av {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.addToCalendar": "Lägg till i kalender",
  "components.leaveRequestAction.addToCalendarMoreInfo": "Mer information om funktionen \"Lägg till i kalender\"",
  "components.leaveRequestAction.approved": "{requestor} Ledighet godkänd <byActionCreator>av {actionCreator}</byActionCreator> ✅",
  "components.leaveRequestAction.approversLabel": "Godkännare: ",
  "components.leaveRequestAction.autoApproved": "{requestor} Ledighet har automatiskt godkänts",
  "components.leaveRequestAction.cancelled": "{requestor} Ledighet avbruten <byActionCreator>av {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.created": "Ledighet begärd <byActionCreator>av {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.deleted": "Ledighet raderad <byActionCreator>av {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.denied": "{requestor} Ledighet nekad <byActionCreator>av {actionCreator}</byActionCreator> ❌",
  "components.leaveRequestAction.edited": "{requestor} Ledighet redigerad <byActionCreator>av {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.editedApproved": "En planerad ledighet är redigerad <byActionCreator>av {actionCreator}</byActionCreator> ✅",
  "components.leaveRequestAction.editedAutoApproved": "Automatiskt godkänd redigerad ledighet <byActionCreator>av {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.editedDenied": "Redigerad ledighet nekad <byActionCreator>av {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.fullDayLeavePeriod": "{date}{endDate} ",
  "components.leaveRequestAction.leaveRemaining": "Kvarvarande ledighet: ",
  "components.leaveRequestAction.leaveRequested": "Begärd ledighet: ",
  "components.leaveRequestAction.leaveTypeLabel": "Ledighetstyp: ",
  "components.leaveRequestAction.partDayLeavePeriod": "{date} - från {partDayStartHour} till {partDayEndHour} ",
  "components.leaveRequestAction.periodLabel": "Datum: ",
  "components.leaveRequestAlertStatus.leaveRequestApprovedByYouInfo": "Du har godkänt begäran. En notifiering kommer att skickas till {name} för att meddela dem.",
  "components.leaveRequestAlertStatus.leaveRequestApprovedInfo": "Ledighetsbegäran har godkänts av {approverName}.",
  "components.leaveRequestAlertStatus.leaveRequestCancelledInfo": "Ledighetsbegäran har avbrutits.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedByYouInfo": "Du har nekat begäran. En notifiering kommer att skickas till {name} för att meddela dem.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedInfo": "Ledighetsbegäran har nekats av {approverName}.",
  "components.leaveRequestAlertStatus.leaveRequestExpiredInfo": "Begäran har löpt ut.",
  "components.leaveRequestAlertStatus.leaveRequestHasApprovedByYouInfo": "Ledighetsbegäran har godkänts.",
  "components.leaveRequestAlertStatus.leaveRequestHasDeniedByYouInfo": "Ledighetsbegäran har nekats.",
  "components.leavesColumns.approver": "Godkännare",
  "components.leavesColumns.autoApproved": "Automatiskt godkänd",
  "components.leavesColumns.cancelledBeforeReview": "Avbruten före granskning",
  "components.leavesColumns.dates": "Datum",
  "components.leavesColumns.days": "{value} dagar",
  "components.leavesColumns.denyReason": "Godkännarens anledning",
  "components.leavesColumns.duration": "Varaktighet",
  "components.leavesColumns.halfDayHoliday": "(Halvdags helgdag)",
  "components.leavesColumns.hours": "{value} timmar",
  "components.leavesColumns.leaveType": "Ledighetstyp",
  "components.leavesColumns.reason": "Begärandens anledning",
  "components.leavesColumns.reviewedby": "Granskad av",
  "components.leavesColumns.status": "Status",
  "components.leaveTypeForm.cancel": "Avbryt",
  "components.leaveTypeForm.color": "Färg",
  "components.leaveTypeForm.editLeaveTypeTitle": "Redigera {leaveTypeName} ledighetstyp",
  "components.leaveTypeForm.forbiddenCharacters": "Ledighetstyp namn får inte innehålla någon interpunktion eller specialtecken.",
  "components.leaveTypeForm.isActive": "Aktiv",
  "components.leaveTypeForm.maxLength": "Ledighetstyp namn är för långt.",
  "components.leaveTypeForm.name": "Namn",
  "components.leaveTypeForm.nameIsRequired": "Detta fält är obligatoriskt.",
  "components.leaveTypeForm.save": "Spara",
  "components.leaveTypeForm.setSlackStatus": "Ställ in Slack-status",
  "components.leaveTypeForm.setSlackStatusPlaceholder": "Vänligen ställ in Slack-status",
  "components.leaveTypeForm.slackStatus": "Slack-status",
  "components.leaveTypeForm.title": "Ledighetstyp",
  "components.leaveTypeForm.tooltipElement1": "<strong>Godkännande krävs</strong>: Detta alternativ kan aktiveras om en ledighet kräver en chefs godkännande.",
  "components.leaveTypeForm.tooltipElement2": "<strong>Tillåt negativt saldo</strong>: Detta alternativ tillåter användare att ansöka om ledighet även efter att de har använt upp sin kvot.",
  "components.leaveTypeForm.tooltipElement3": "<strong>Tillåt halvdagar</strong>: Detta alternativ kan aktiveras enligt din organisations behov.",
  "components.leaveTypeForm.tooltipFooter": "Kom ihåg att det är viktigt att notera att ledighetspolicyer definieras per plats. Du kan anpassa dessa policyer enligt din organisations behov och tilldela dem till lämpliga ledighetstyper.",
  "components.leaveTypeForm.tooltipHeader": "I Vacation Tracker hänvisar 'Ledighetstyper' till globala kategorier av ledigheter såsom betald ledighet, sjukdag, arbete hemifrån och så vidare. Å andra sidan är 'Ledighetspolicyer' specifika regler och inställningar som tilldelas varje ledighetstyp per plats. Dessa policyer kan inkludera olika alternativ såsom:",
  "components.legacyPlanAlert.warning": "Du använder för närvarande den äldre planen. Om du byter plan kommer du inte att kunna aktivera den igen. För mer information, kontakta vår <link>support</link>.",
  "components.locationForm.changeLocationWarning": "Välj den plats du vill överföra användaren till.",
  "components.locationForm.changeUserLocation": "Ändra användarens plats",
  "components.locationForm.days": "Dagar",
  "components.locationForm.daysAfterEmploymentDate": "dagar efter anställningsdatumet.",
  "components.locationForm.daysInfo": "Överföringen av oanvänd ledighet till följande kalenderår kommer att upphöra efter det antal dagar du har angett efter användarens anställningsdatum. Ledighetskvoten för varje ledighetstyp konfigureras via fliken Ledighetstyper i platsinställningarna, inklusive alternativet att aktivera eller inaktivera överföringen.",
  "components.locationForm.defaultLocation": "Gör denna plats till standard",
  "components.locationForm.firstDayOfWeek": "Veckan börjar på",
  "components.locationForm.fiscalYear": "Räkenskapsår",
  "components.locationForm.fiscalYearStart": "Räkenskapsårets start",
  "components.locationForm.name": "Namn",
  "components.locationForm.noRolloverExpiryDate": "Inget utgångsdatum för överförd ledighet",
  "components.locationForm.overrideUserSettings": "Åsidosätt användarinställningar",
  "components.locationForm.overrideUserSettingsInfo": "Arbetsveckans konfiguration kommer endast att tillämpas på användare som inte har anpassade inställningar i sin användarprofil. Om du vill tillämpa de nya arbetsveckeinställningarna på alla användare, inklusive de som har en anpassad arbetsvecka inställd, välj alternativet \"Åsidosätt användarinställningar\".",
  "components.locationForm.resetQuotas": "Återställning av ledighetssaldo baserat på",
  "components.locationForm.resetQuotasInfo": "Denna inställning avgör om ditt ledighetssaldo kommer att återställas baserat på räkenskapsåret (företagets räkenskapsår) eller baserat på arbetstagarens startdatum. Förutom kvoter kommer även din överförda policy att påverkas enligt denna inställning.",
  "components.locationForm.rolloverExpiryAfterDays": "Inget utgångsdatum för överförda dagar",
  "components.locationForm.rolloverExpiryAfterDaysInfo": "Varje år kommer användarnas överförda ledighet att upphöra så här många dagar efter deras anställningsdatum. Kvoterna för varje ledighetstyp konfigureras genom avsnittet Ledighetstyper för denna plats och var och en kan ställas in individuellt för att tillåta eller inte tillåta överföringar.",
  "components.locationForm.rolloverExpiryDay": "Inget utgångsdatum för överförda dagar",
  "components.locationForm.rolloverExpiryDayInfo": "Varje år kommer användarens överförda ledighet att upphöra på det datum du anger. Kvoterna för varje ledighetstyp konfigureras genom avsnittet Ledighetstyper för denna plats och var och en kan ställas in individuellt för att tillåta eller inte tillåta överföringar.",
  "components.locationForm.selectLocation": "Välj plats",
  "components.locationForm.selectLocationError": "Vänligen välj en plats.",
  "components.locationForm.settings.makeDefaultDescription": "Genom att göra denna plats till standard kommer alla nya teammedlemmar automatiskt att läggas till på denna plats.",
  "components.locationForm.settings.makeDefaultTitle": "Standardplats",
  "components.locationForm.settings.nameDescription": "Ange namnet på din plats och välj dess användare. Om du vill konfigurera platsen innan du lägger till användare kan du skapa platsen först och lägga till användare senare.",
  "components.locationForm.settings.nameDescription2": "Denna standardtidszon används i hela systemet. Till exempel för att korrekt visa ledighetsinformation i kalendern och för systemhändelser listade i loggarna.",
  "components.locationForm.settings.namePlaceholder": "Ange platsnamn",
  "components.locationForm.settings.nameTitle": "Platsinställningar",
  "components.locationForm.settings.quotasDescription": "Denna inställning avgör om ditt ledighetssaldo kommer att återställas baserat på räkenskapsåret (företagets räkenskapsår) eller baserat på arbetstagarens startdatum. Förutom kvoter kommer även din överförda policy att påverkas enligt denna inställning.",
  "components.locationForm.settings.quotasTitle": "Ledighetskvot och räkenskapsår",
  "components.locationForm.settings.workWeekDescription": "Vänligen välj arbetsdagarna i veckan för att säkerställa korrekt spårning av ledighetsdata.",
  "components.locationForm.settings.workWeekTitle": "Arbetsvecka och arbetsdag",
  "components.locationForm.users": "Användare",
  "components.locationForm.userYear": "Användarens anställningsdatum",
  "components.locationForm.warningInitialRollover": "Eventuella ändringar i platsinställningar kan påverka användarnas konfiguration och ledighetsinformation såsom ledighetskvot och beräkningar. \nObservera att om du har ett utgångsdatum för överförda dagar inställt på någon ledighetspolicy på denna plats kommer det att inaktiveras.",
  "components.locationForm.warningInitialRolloverFiscalYear": "Eventuella ändringar i platsinställningar kan påverka användarnas konfiguration och ledighetsinformation såsom ledighetskvot och beräkningar.",
  "components.locationForm.warningInitialRolloverResetQuotas": "Eventuella ändringar i platsinställningar kan påverka användarnas konfiguration och ledighetsinformation såsom ledighetskvot och beräkningar.",
  "components.locationForm.warningInitialRolloverUsers": "Att lägga till eller ta bort en användare kan påverka användarens konfiguration och ledighetsinformation (t.ex. de initiala överförda dagarna).",
  "components.locationForm.workWeek": "Arbetsvecka",
  "components.locationHolidays.addHolidays": "Lägg till en helgdag",
  "components.locationHolidays.deleteHolidaysConfirm": "Är du säker på att du vill radera helgdagen {name}?",
  "components.locationHolidays.deleteHolidaysTitle": "Radera helgdag",
  "components.locationHolidays.holidaysForLocation": "Helgdagar för platsen {locationName}",
  "components.locationHolidays.insertHolidays": "Importera helgdagar",
  "components.locationHolidays.overlap": "Denna helgdag överlappar med en befintlig",
  "components.locationLeavePolicies.activatingLeaveTypeInfo": "Genom att aktivera denna ledighetstyp tillåter du användarna att begära den specifika ledighetstypen. \n\nTidigare inställningar och data kommer att återställas.",
  "components.locationLeavePolicies.activatingLeaveTypeTitle": "Aktivera ledighetstyp",
  "components.locationLeavePolicies.allowHalfDays": "Tillåt halva dagar",
  "components.locationLeavePolicies.createNewLeavePolicy": "Tilldela ledighetspolicy",
  "components.locationLeavePolicies.createNewLeavePolicyInfo": "För att lägga till en anpassad ledighetstyp till en plats, gå till Tilldela ledighetspolicy. Välj den ledighetstyp du redan har skapat genom fliken Ledighetstyper och konfigurera inställningarna för ledighetspolicyn.",
  "components.locationLeavePolicies.daysPerYear": "Årlig ledighetskvot",
  "components.locationLeavePolicies.deactivateLeaveTypeInfo": "När du inaktiverar denna ledighetstyp kommer användarna på denna plats inte längre att kunna begära den specifika ledighetstypen. När du aktiverar den igen kommer ledighetstypen att återställas med sina tidigare inställningar och data. \n\nOm du vill inaktivera denna ledighetstyp för alla platser kan du göra det från fliken Ledighetstyper.",
  "components.locationLeavePolicies.deactivateLeaveTypeTitle": "Avaktivera ledighetstyp",
  "components.locationLeavePolicies.hasUnlimitedDays": "Obegränsat",
  "components.locationLeavePolicies.isApprovalRequired": "Godkännande krävs",
  "components.locationLeavePolicies.leaveTypeName": "Namn på ledighetstyp",
  "components.locationLeavePolicies.status": "Status",
  "components.locationLeavePolicy.createInProgress": "Skapar {name} ledighetspolicy",
  "components.locationLeavePolicy.successTitle": "Ledighetspolicyn är inställd",
  "components.locationLeavePolicy.updateInProgress": "Uppdaterar ledighetspolicyn",
  "components.locationLeaveTypesTag.hiddenLeaveType": "Dold ledighetstyp",
  "components.locationLeaveTypesTag.holidays": "Helgdagar",
  "components.logs.accrual": "Användaren har tjänat in {earned} {leaveTypeName} {earnedDayInGrammaticalNumber} (av {defaultDaysPerYear} {defaultDayInGrammaticalNumber} kvot)",
  "components.logs.accrualDays": "Användaren har tjänat in {earned} {leaveTypeName} {earned, plural, =1 {dag} other {dagar}} (av {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {dag} other {dagar}} kvot)",
  "components.logs.accrualDisplay": "Användaren har tjänat in {earned} {leaveTypeName} (av {defaultDaysPerYear} kvot)",
  "components.logs.accrualHours": "Användaren har tjänat in {earned} {leaveTypeName} {earned, plural, =1 {timme} other {timmar}} (av {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {timme} other {timmar}} kvot)",
  "components.logs.automationAddonsUpdatedPurchased": "{user} köpte tillägg: {automationType}",
  "components.logs.automationAddonsUpdatedUnsubscribed": "{user} avslutade prenumerationen på tillägg: {automationType}",
  "components.logs.billingCancelled": "Fakturering avbruten på {paymentProcessor}",
  "components.logs.billingUpdated": "Fakturering uppdaterad på {paymentProcessor}",
  "components.logs.blackoutPeriodCreated": "{user} skapade en avstängningsperiod <b>{name}</b>.<br></br>Aktiverad: {enabled}.<br></br>Återkommande: {recurring}.<br></br>Startdatum: {startDate}.<br></br>Slutdatum: {endDate}.<br></br>Ledighetstyper: {leaveTypes}.<br></br>Platser: {locations}.<br></br>Avdelningar: {teams}.<br></br>Etiketter: {labels}.<br></br>",
  "components.logs.blackoutPeriodDeleted": "{user} raderade en avstängningsperiod.",
  "components.logs.blackoutPeriodUpdated": "{user} uppdaterade en avstängningsperiod <b>{name}</b>.<br></br>Aktiverad: {enabled}.<br></br>Återkommande: {recurring}.<br></br>Startdatum: {startDate}.<br></br>Slutdatum: {endDate}.<br></br>Ledighetstyper: {leaveTypes}.<br></br>Platser: {locations}.<br></br>Avdelningar: {teams}.<br></br>Etiketter: {labels}.<br></br>",
  "components.logs.companyCreated": "Företag <b>{name}</b> har skapats",
  "components.logs.companySettingsUpdated": "<b>{user}</b> uppdaterade företagsinställningar.<br></br>Meddela nya användare: {announceNewUsers}.<br></br>Timbaserad ledighetsredovisning: {hourlyLeaveAccounting}.<br></br>Användarens slutdatum: {userEndDate}.<br></br>Beräkningsstart år: {calculationStartYear}<br></br>Importera användare automatiskt: {importUsersAutomatically}",
  "components.logs.deleted": "<Raderad>",
  "components.logs.earned": "Användaren tjänade in {total} dagar av {leaveTypeName} från övertidsarbete (TOIL) {period}. {showExpiration, plural, =1 {Dagar kommer att förfalla den } other {}}{expiration}",
  "components.logs.entitlementByRoleCreated": "{user} skapade en berättigande efter roll <b>{name}</b>.<br></br>Aktiverad: {enabled}.<br></br>Belopp: {amount} {units}.<br></br>Etikett: {label}.<br></br>Ledighetstyp: {leaveType}.<br></br>",
  "components.logs.entitlementByRoleDeleted": "{user} raderade ett berättigande efter roll.",
  "components.logs.entitlementByRoleUpdated": "{user} uppdaterade ett berättigande efter roll <b>{name}</b>.<br></br>Aktiverad: {enabled}.<br></br>Belopp: {amount} {units}.<br></br>Etikett: {label}.<br></br>Ledighetstyp: {leaveType}.<br></br>",
  "components.logs.failedToLoadLog": "Misslyckades med att ladda loggen",
  "components.logs.importLeaves": "<b>{user}</b> importerade {totalLeaves} {totalLeaves, plural, =1 {ledighet} other {ledigheter}}.",
  "components.logs.importUsers": "<b>{user}</b> importerade {totalUsers} {totalUsers, plural, =1 {användare} other {användare}}.",
  "components.logs.initialRolloverDeleted": "Initialt överförda dagar för ledighetstyp: {leaveTypeName} återställdes till 0 av <link>{creatorName}</link>",
  "components.logs.initialRolloverUpdatedDays": "Initialt överförda dagar för ledighetstyp: {leaveTypeName} ändrades till {amount} {amount, plural, =1 {dag} other {dagar}} av <link>{creatorName}</link>",
  "components.logs.initialRolloverUpdatedHours": "Initialt överförda timmar för ledighetstyp: {leaveTypeName} ändrades till {amount} {amount, plural, =1 {timme} other {timmar}} av <link>{creatorName}</link>",
  "components.logs.leaveDurationLimitCreated": "{user} skapade en ledighetslängdsbegränsning <b>{name}</b>.<br></br>Aktiverad: {enabled}.<br></br>Startdatum: {startDate}.<br></br>Slutdatum: {endDate}.<br></br>Minsta varaktighet: {minimumDuration}.<br></br>Maximal varaktighet: {maximumDuration}.<br></br>Återkommande: {recurring}.<br></br>Automatiskt avvisningssvar: {autoRejectResponse}.",
  "components.logs.leaveDurationLimitDeleted": "{user} raderade en ledighetslängdsbegränsning.",
  "components.logs.leaveDurationLimitUpdated": "{user} uppdaterade en ledighetslängdsbegränsning <b>{name}</b>.<br></br>Aktiverad: {enabled}.<br></br>Startdatum: {startDate}.<br></br>Slutdatum: {endDate}.<br></br>Minsta varaktighet: {minimumDuration}.<br></br>Maximal varaktighet: {maximumDuration}.<br></br>Återkommande: {recurring}.<br></br>Automatiskt avvisningssvar: {autoRejectResponse}.",
  "components.logs.leavePolicyDisabled": "{user} inaktiverade ledighetspolicy <b>{leaveTypeName}</b> på plats <b>{locationName}</b>",
  "components.logs.leavePolicyEnabled": "{user} aktiverade ledighetspolicy <b>{leaveTypeName}</b> på plats <b>{locationName}</b>.<br></br>Kvot: {daysPerYear} {units}.<br></br>Max överföring: {maxRolloverDays} {units}.<br></br>Ska överförda dagar förfalla: {enableRolloverExpiry}.<br></br>Överförda dagar förfaller den: {rolloverExpiryDate}.<br></br>Överförda dagar förfaller efter dagar: {rolloverExpiryAfterDays}.<br></br>Negativ överföring: {negativeBroughtForwardAllowed}<br></br>Godkännande krävs: {isApprovalRequired}.<br></br>Obegränsade dagar: {hasUnlimitedDays}.<br></br>Kortaste ledighetsintervall: {shortestLeaveInterval}.<br></br>Anledning krävs: {isReasonRequired}.<br></br>Intjänandetyp: {accrualType}.<br></br>Negativt saldo tillåtet: {negativeBallanceAllowed}.<br></br>Tillåt ledighet i det förflutna: {allowLeaveInPast}.<br></br>Intjänandetak: {accrualCapRate, plural, =0 {Inaktiverat} other {Aktiverat med takt {accrualCapRate}}}.<br></br>Dölj ledighetstyp: {hideLeaveType}.<br></br>TOIL aktiverad: {toil}.<br></br>TOIL kortaste intervall: {toilShortestInterval}.<br></br>TOIL alla användare: {toilRequestsAllowedForUsers}.<br></br>TOIL förfallodatum: {toilExpiration}.<br></br>TOIL förfallodatum: {toilExpirationDate}.<br></br>TOIL förfallomånader: {toilExpirationMonths}.",
  "components.logs.leaveTypeCreated": "{user} skapade ledighetstyp <b>{name}</b> och tilldelade den till plats(er): <b>{locations}</b>.<br></br>Färg: {color}.<br></br>Dagar per år: {daysPerYear}.<br></br>Max antal överförda dagar: {maxRolloverDays}.<br></br>Godkännande krävs: {isApprovalRequired}.<br></br>Obegränsade dagar: {hasUnlimitedDays}.<br></br>Kortaste ledighetsintervall: {shortestLeaveInterval}.<br></br>Anledning krävs: {isReasonRequired}.<br></br>Intjänandetyp: {accrualType}.<br></br>Negativt saldo tillåtet: {negativeBallanceAllowed}.<br></br>Tillåt att lägga till ledighet i det förflutna: {allowLeaveInPast}.<br></br>Dölj ledighetstyp: {hideLeaveType}",
  "components.logs.leaveTypeDeleted": "{user} raderade en ledighetstyp <b>{name}</b>",
  "components.logs.leaveTypeUpdated": "{user} uppdaterade ledighetstyp <b>{name}</b>.<br></br>Aktiv: {isActive}.<br></br>Färg: {color}",
  "components.logs.locationCreated": "{user} skapade en plats <b>{name}</b>.<br></br>Årets startmånad: {yearStartMonth}.<br></br>Årets startdag: {yearStartDay}.<br></br>Standardplats: {isDefault}.<br></br>Första dag i veckan: {firstDayOfWeek}.<br></br>Kvotåterställning baserad på: {resetQuotas}.<br></br>Överföringsmånad för utgång: {rolloverExpiryMonth}.<br></br>Överföringsdag för utgång: {rolloverExpiryDay}.<br></br>Överföring utgår efter dagar: {rolloverExpiryAfterDays}.<br></br>Tidszon: {timezone}.<br></br>Användare: {users}.<br></br>Dagar: {days}",
  "components.logs.locationDeleted": "{user} raderade plats",
  "components.logs.locationHolidaysAutoimportOFF": "<b>{user}</b> stängde av automatisk import av helgdagar för platsen <b>{location}</b>",
  "components.logs.locationHolidaysAutoimportON": "<b>{user}</b> aktiverade automatisk import av helgdagar för platsen <b>{location}</b><br></br>Helgdagar: {holidays}",
  "components.logs.locationUpdated": "{user} uppdaterade platsen <b>{name}</b>.<br></br>Årets startmånad: {yearStartMonth}.<br></br>Årets startdag: {yearStartDay}.<br></br>Standardplats: {isDefault}.<br></br>Första dag i veckan: {firstDayOfWeek}.<br></br>Återställ kvoter: {resetQuotas}.<br></br>Överföringsmånad för utgång: {rolloverExpiryMonth}.<br></br>Överföringsdag för utgång: {rolloverExpiryDay}.<br></br>Överföring utgår efter dagar: {rolloverExpiryAfterDays}.<br></br>Tidszon: {timezone}.<br></br>Användare: {users}",
  "components.logs.locationWorkWeekUpdated": "{user} uppdaterade arbetsveckan för platsen <b>{name}</b>.<br></br>Dagar: {days}.<br></br>Timmar: {hours}",
  "components.logs.locationYearHolidaysChanged": "<b>{user}</b> ändrade helgdag(ar) för år {year} på platsen <b>{name}</b>.<br></br>Helgdagar: {holidays}",
  "components.logs.locationYearRollover": "Årsövergång för plats skedde på platsen <b>{name}</b>",
  "components.logs.nonAccrualLeaveType": "Användaren fick {earned} <b>{leaveTypeName}</b> för {period}",
  "components.logs.nonAccrualLeaveTypeDays": "Användaren fick {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {dag} other {dagar}} för {period}",
  "components.logs.nonAccrualLeaveTypeHours": "Användaren fick {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {timme} other {timmar}} för {period}",
  "components.logs.notificationCreated": "Användare {user} skapade en notifikation <b>{name}</b>.<br></br>Frekvens: {frequency}.<br></br>Aktiverad: {enabled}.<br></br>Platser: {locations}.<br></br>Avdelningar: {teams}.<br></br>Etiketter: {labels}.<br></br>Första dag i veckan: {firstDayOfWeek}.<br></br>Tidszon: {timezone}.<br></br>Gruppera efter {groupBy}.<br></br>Skicka tom: {sendEmpty}.<br></br>Destinationer: {destinations}.<br></br>Minut: {minute}.<br></br>Timme: {hour}.<br></br>Dag: {day}.<br></br>Språk: {locale}.<br></br>Skicka på dagar: {sendOnDays}.<br></br>Aktuell vecka: {currentWeek}",
  "components.logs.notificationDeleted": "Användare {user} raderade notifikation",
  "components.logs.notificationDetailsDaily": "Användare {user} {action} notifikation <b>{name}</b>.<br></br>Frekvens: {frequency}.<br></br>Aktiverad: {enabled}.<br></br>Platser: {locations}.<br></br>Avdelningar: {teams}.<br></br>Etiketter: {labels}.<br></br>Tidszon: {timezone}.<br></br>Gruppera efter {groupBy}.<br></br>Skicka tom: {sendEmpty}.<br></br>Destinationer: {destinations}.<br></br>Minut: {minute}.<br></br>Timme: {hour}.<br></br>Språk: {locale}.<br></br>Skicka på dagar: {sendOnDays}.",
  "components.logs.notificationDetailsMonthly": "Användare {user} {action} notifikation <b>{name}</b>.<br></br>Frekvens: {frequency}.<br></br>Aktiverad: {enabled}.<br></br>Platser: {locations}.<br></br>Avdelningar: {teams}.<br></br>Etiketter: {labels}.<br></br>Första dag i veckan: {firstDayOfWeek}.<br></br>Tidszon: {timezone}.<br></br>Gruppera efter {groupBy}.<br></br>Skicka tom: {sendEmpty}.<br></br>Destinationer: {destinations}.<br></br>Minut: {minute}.<br></br>Timme: {hour}.<br></br>Dag: {day}.<br></br>Språk: {locale}.<br></br>Aktuell vecka: {currentWeek}",
  "components.logs.notificationUpdated": "Användare {user} uppdaterade en notifikation <b>{name}</b>.<br></br>Frekvens: {frequency}.<br></br>Aktiverad: {enabled}.<br></br>Platser: {locations}.<br></br>Avdelningar: {teams}.<br></br>Etiketter: {labels}.<br></br>Första dag i veckan: {firstDayOfWeek}.<br></br>Tidszon: {timezone}.<br></br>Gruppera efter {groupBy}.<br></br>Skicka tom: {sendEmpty}.<br></br>Destinationer: {destinations}.<br></br>Minut: {minute}.<br></br>Timme: {hour}.<br></br>Dag: {day}.<br></br>Språk: {locale}.<br></br>Skicka på dagar: {sendOnDays}.<br></br>Aktuell vecka: {currentWeek}",
  "components.logs.probationPeriodCreated": "{user} skapade en Prövotid <b>{name}</b>.<br></br>Aktiverad: {enabled}.<br></br>Längd (dagar): {lengthDays}.<br></br>Ledighetstyper: {leaveTypes}.<br></br>Platser: {locations}.<br></br>Avdelningar: {teams}.<br></br>Etiketter: {labels}.<br></br>",
  "components.logs.probationPeriodDeleted": "{user} raderade en Prövotid.",
  "components.logs.probationPeriodUpdated": "{user} uppdaterade en Prövotid <b>{name}</b>.<br></br>Aktiverad: {enabled}.<br></br>Längd (dagar): {lengthDays}.<br></br>Ledighetstyper: {leaveTypes}.<br></br>Platser: {locations}.<br></br>Avdelningar: {teams}.<br></br>Etiketter: {labels}.<br></br>",
  "components.logs.seniorityEntitlementCreated": "{user} skapade en Senioritetsberättigande <b>{name}</b>.<br></br>Aktiverad: {enabled}.<br></br>Proportionerlig: {prorated}.<br></br>Startdatum: {startDate}.<br></br> Ledighetstyp: {leaveType}.<br></br>Tilldelad: {entitlementPeriods}.<br></br>Platser: {locations}.<br></br>Avdelningar: {teams}.<br></br>Etiketter: {labels}.<br></br>",
  "components.logs.seniorityEntitlementDeleted": "{user} raderade ett Senioritetsberättigande.",
  "components.logs.seniorityEntitlementUpdated": "{user} uppdaterade Senioritetsberättigande <b>{name}</b>.<br></br>Aktiverad: {enabled}.<br></br>Proportionerlig: {prorated}.<br></br>Startdatum: {startDate}.<br></br> Ledighetstyp: {leaveType}.<br></br>Tilldelad: {entitlementPeriods}.<br></br>Platser: {locations}.<br></br>Avdelningar: {teams}.<br></br>Etiketter: {labels}.<br></br>",
  "components.logs.subscriptionCancelled": "<b>{user}</b> avbröt prenumeration.<br></br>Anledning: {reason}.<br></br>Kommentar: {comment}",
  "components.logs.subscriptionChanged": "Prenumeration ändrad.<br></br>Ny plan: {plan}.<br></br>Period: {period}.<br></br>Status: {status}.<br></br>Betalningsprocessor: {paymentProcessor}",
  "components.logs.substituteApproverCreated": "{substituteApproverName} sattes som Ersättningsgodkännare för {approverFor} av {creator} från och med {startDate} till {endDate}",
  "components.logs.substituteApproverDeleted": "{creator} tog bort {substituteApproverName} som Ersättningsgodkännare.",
  "components.logs.substituteApproverUpdated": "{creator} uppdaterade ersättningsgodkännare.<br></br>Ersättningsgodkännarens namn: {substituteApproverName}<br></br>Startdatum: {startDate}<br></br>Slutdatum: {endDate}<br></br>",
  "components.logs.teamApproverStatusChanged": "{user} satte godkännare {approvers} för team {team}",
  "components.logs.teamCreated": "{user} skapade en avdelning <b>{team}</b>.<br></br>Standardavdelning: {isDefault}.<br></br>Användare: {users}.<br></br>Godkännare: {approvers} <br></br>Överordnad avdelning: {parentDepartment}",
  "components.logs.teamDeleted": "{user} raderade team",
  "components.logs.teamMaximumUsersAwayCreated": "{user} skapade en Maximalt antal frånvarande användare <b>{name}</b> konfiguration.<br></br>Aktiverad: {enabled}.<br></br>Återkommande: {recurring}.<br></br>Max antal användare {maxUsersAwayType} tillåtna: {maxUsersAllowed}.<br></br>Startdatum: {startDate}.<br></br>Slutdatum: {endDate}.<br></br>Ledighetstyper: {leaveTypes}.<br></br>Platser: {locations}.<br></br>Avdelningar: {teams}.<br></br>Etiketter: {labels}.<br></br>",
  "components.logs.teamMaximumUsersAwayDeleted": "{user} raderade en Maximalt antal frånvarande användare-konfiguration.",
  "components.logs.teamMaximumUsersAwayUpdated": "{user} uppdaterade en Maximalt antal frånvarande användare <b>{name}</b> konfiguration.<br></br>Aktiverad: {enabled}.<br></br>Återkommande: {recurring}.<br></br>Max antal användare {maxUsersAwayType} tillåtna: {maxUsersAllowed}.<br></br>Startdatum: {startDate}.<br></br>Slutdatum: {endDate}.<br></br>Ledighetstyper: {leaveTypes}.<br></br>Platser: {locations}.<br></br>Avdelningar: {teams}.<br></br>Etiketter: {labels}.<br></br>",
  "components.logs.teamUpdated": "{user} uppdaterade en avdelning <b>{team}</b>.<br></br>Standardavdelning: {isDefault}.<br></br>Användare: {users}.<br></br>Godkännare: {approvers} <br></br>Överordnad avdelning: {parentDepartment}",
  "components.logs.userActivatedBy": "Användaren aktiverades av <link>{creatorName}</link>",
  "components.logs.userApproverStatus": "Användaren är <status></status> som godkännare för avdelningen <team>{teamName}</team> av <link>{creatorName}</link>",
  "components.logs.userChangedAvatar": "Användaren uppdaterade sin profilbild",
  "components.logs.userDeactivatedBy": "Användaren inaktiverades av <link>{creatorName}</link>",
  "components.logs.userDeletedBy": "Användaren raderades av <link>{creatorName}</link>",
  "components.logs.userEntitlementByRole": "Användaren beviljades {totalDays} {totalDays, plural, =1 {dag} other {dagar}} av {leaveTypeName} genom Berättigande efter Roll",
  "components.logs.userEntitlementByRoleDeleted": "Användarens Berättigande efter Roll-dagar för {leaveTypeName} togs bort på grund av borttagning eller radering av etiketten <b>{labelName}</b>.",
  "components.logs.userEntitlementByRoleOverride": "Användarens berättigandedagar för {leaveTypeName} har ändrats till {totalDays} {totalDays, plural, =1 {dag} other {dagar}} på grund av justeringar i Berättigande efter Roll",
  "components.logs.userInvitationAccepted": "Användaren accepterade inbjudan från <link>{creatorName}</link>",
  "components.logs.userLeaveTypeDeleteQuota": "Kvoten för ledighetstyp <b>{leaveTypeName}</b> återställdes till standardvärde av <link>{creatorName}</link>",
  "components.logs.userLeaveTypeUnlimitedDays": "obegränsat",
  "components.logs.userLeaveTypeUpdatedQuota": "<link>{creatorName}</link> har ändrat kvoten för denna användare för <b>{leaveTypeName}</b> till <quota></quota> dagar",
  "components.logs.userLeaveTypeUpdatedQuotaDays": "<link>{creatorName}</link> har ändrat kvoten för denna användare för <b>{leaveTypeName}</b> till <quota></quota> dagar",
  "components.logs.userLeaveTypeUpdatedQuotaHours": "<link>{creatorName}</link> har ändrat kvoten för denna användare för <b>{leaveTypeName}</b> till <quota></quota> timmar",
  "components.logs.userMovedToLocation": "Användaren flyttades till plats <locationLink>{location}</locationLink> av <link>{creatorName}</link>",
  "components.logs.userMovedToTeam": "Användaren flyttades till avdelning <teamLink>{team}</teamLink> av <link>{creatorName}</link>",
  "components.logs.userRolloverDays": "Överfört från föregående år: {leaveTypeName}, {value} dag{ar}",
  "components.logs.userRolloverDisplay": "Överfört från föregående år: {leaveTypeName}, {value}",
  "components.logs.userRolloverExpired": "<b>{total}</b> <b>{leaveTypeName}</b> dagar från föregående år har löpt ut",
  "components.logs.userRolloverExpiredDays": "Dagar från föregående år har löpt ut: <b>{leaveTypeName}</b> - {value} dag{ar}",
  "components.logs.userRolloverExpiredHours": "Dagar från föregående år har löpt ut: <b>{leaveTypeName}</b> - {value} timm{ar}",
  "components.logs.userRolloverHours": "Överfört från föregående år: <b>{leaveTypeName}</b>, {value} timm{ar}",
  "components.logs.userSeniorityEntitlement": "Användaren beviljades {totalDays} dag(ar) av {leaveTypeName} genom Anställningstidsberättigande",
  "components.logs.userSeniorityEntitlementOverride": "Användarens berättigandedagar för {leaveTypeName} har ändrats till {totalDays} dag(ar) på grund av justeringar i Anställningstidsberättigande",
  "components.logs.userUpdated": "Användare <name></name> <email></email> <startDate></startDate> <endDate></endDate> <userStatus></userStatus> av <link>{creatorName}</link>",
  "components.logs.userUpdatedChangeStatusToAdmin": "roll ändrad till Administratör",
  "components.logs.userUpdatedChangeStatusToUser": "roll ändrad till Användare",
  "components.logs.userUpdatedEmail": "e-post ändrad till {email}",
  "components.logs.userUpdatedEndDate": "slutdatum ändrat till {endDate}",
  "components.logs.userUpdatedName": "Namn ändrat till <wrappedName>{name}</wrappedName>",
  "components.logs.userUpdatedStartDate": "Startdatum ändrat till {startDate}",
  "components.logs.userWorkWeek": "Användarens arbetsvecka ändrades till {workDays} av <link>{creatorName}</link>",
  "components.logs.userWorkWeekDefaultLocation": "Användarens arbetsvecka återställdes till platsens standard av <link>{creatorName}</link>",
  "components.logs.visibilityAddonCreated": "{user} begränsade synligheten för företaget.<br></br>Aktiverad: {enabled}.<br></br> Synlighetsnivå: {visibilityLevel}.<br></br>",
  "components.logs.visibilityAddonUpdated": "{user} uppdaterade begränsad synlighet för företaget.<br></br>Aktiverad: {enabled}.<br></br> Synlighetsnivå: {visibilityLevel}.<br></br>",
  "components.manageAssignLicenses.assignLicenses": "Tilldela {numberOfToAssignLicenses} licenser",
  "components.manageAssignLicenses.avaliableToAssign": "Tillgängliga att tilldela: {avaliableLicenses}",
  "components.manageAssignLicenses.microsoftTeamsMessage": "Microsoft Teams-meddelande",
  "components.manageAssignLicenses.moreUserThenLicenses": "Du har tilldelat {users} fler licenser än tillgängligt",
  "components.manageAssignLicenses.placeholderBulkWelcomeMessage": "Välj massåtgärd",
  "components.manageAssignLicenses.placeholderSelectRole": "Välj roll",
  "components.manageAssignLicenses.roleTooltipInfo": "Tilldela användarroller i Vacation Tracker. För mer information, besök vår <helpDesk>Helpdesk</helpDesk>.",
  "components.manageAssignLicenses.sendWelcomeMessage": "Skicka välkomstmeddelande",
  "components.manageAssignLicenses.totalLicenses": "Totalt antal licenser: {numberOflicenses}",
  "components.maximumUsersAwaySummaryInfo.summary": "Denna policy begränsar antalet användare som kan vara lediga samtidigt till {noOfUsers} användare<multiUsers></multiUsers> <link>(Visa <isAbsolute> Filtrerade</isAbsolute> Användare<multiUsers></multiUsers>)</link> Denna gräns gäller <endDate> mellan</endDate> {fromDate} <endDate>och {toDate}</endDate><recurring> varje år</recurring>. När gränsen nås kommer alla nya ledighetsansökningar automatiskt att avvisas med det angivna meddelandet.",
  "components.modal.confirm": "Bekräfta",
  "components.modal.verify": "Verifiera",
  "components.notificationDestination.label": "Skicka till",
  "components.notificationDestination.tooltip": "Observera att du kan skicka aviseringen till flera e-postadresser, men endast en kanal åt gången.",
  "components.notificationForm.channel": "Kanal",
  "components.notificationForm.channelSlackInfo": "Aviseringar kan endast skickas till de kanaler du är en del av.",
  "components.notificationForm.daily": "Dagligen",
  "components.notificationForm.day": "Dag",
  "components.notificationForm.firstDayOfWeek": "Veckan börjar på",
  "components.notificationForm.refreshTheList": "Uppdatera listan",
  "components.notificationForm.team": "Microsoft Teams-team",
  "components.notificationForm.time": "Tid",
  "components.notificationForm.timezone": "Tidszon",
  "components.notificationForm.weekly": "Veckovis",
  "components.notificationSummaryInfo.notificationSummary": "Aviseringen kommer att skickas ut <to>till</to> <channelWrapper>{channelName} </channelWrapper> <and>och</and> <addressesWrapper>{numberOfEmailAddress} e-postadress<multiEmailAddress>er</multiEmailAddress></addressesWrapper> på {sendingDaysInfo} klockan {time}. Den kommer att inkludera ledighetsinformation för <danger>{noOfUsers} användare</danger> <link>(Visa användare)</link>",
  "components.onboarding.assignLicensesTitle": "<icon></icon> Tilldela licenser",
  "components.onboarding.installMSBotButton": "Installera boten",
  "components.onboarding.installMSBotDescription": "Installera Vacation Tracker Bot och flikar för att enkelt hantera ditt teams ledighet i Microsoft Teams.",
  "components.onboarding.installMSBotTitle": "<icon></icon> Slutför botinstallationen",
  "components.onboarding.inviteFriendsDescription": "Få ditt team ombord för korrekt och punktlig ledighetshantering. (Lägg till minst 3 teammedlemmar för att slutföra detta steg.)",
  "components.onboarding.inviteFriendsTitle": "<icon></icon> Bjud in dina teammedlemmar",
  "components.onboarding.joyride.admin.assignLeavePolicy.info1": "Som vi har nämnt tidigare skapas ledighetstyper för hela organisationen. Ledighetspolicyer är kopplade till platser. Varje ledighetstyp kan ställas in för varje plats genom att tilldela den en anpassad ledighetspolicy.",
  "components.onboarding.joyride.admin.assignLeavePolicy.info2": "När du har valt den ledighetstyp du vill lägga till på platsen kan du tilldela ledighetspolicyn. Du har möjlighet att kopiera inställningar från en befintlig ledighetspolicy eller skapa en helt ny ledighetspolicy med nya värden.",
  "components.onboarding.joyride.admin.assignLeavePolicy.title": "Tilldela ledighetspolicyer",
  "components.onboarding.joyride.admin.createLocation.info1": "Om du har teammedlemmar på olika platser har du möjlighet att skapa flera platser i Vacation Tracker och lägga till användare till motsvarande plats.",
  "components.onboarding.joyride.admin.createLocation.info2": "Varje plats har sina egna ledighetspolicyer som du måste tilldela till en viss ledighetstyp. Helgdagar är också kopplade till platser.",
  "components.onboarding.joyride.admin.createLocation.title": "Skapa en plats",
  "components.onboarding.joyride.admin.createNotifications.info1": "Nu när du har slutfört stegen som nämnts ovan är det dags att skapa aviseringar.",
  "components.onboarding.joyride.admin.createNotifications.title": "Skapa och redigera aviseringar",
  "components.onboarding.joyride.admin.createTeams.info1": "När du har slutfört Dashboard ger dig en överblick över dagens och planerade ledigheter inom din organisation. som nämnts ovan är det dags att skapa avdelningar.",
  "components.onboarding.joyride.admin.createTeams.info2": "När du skapar en avdelning måste du lägga till godkännare. Godkännare har den näst högsta behörighetsnivån i Vacation Tracker och kan godkänna/neka ledighetsförfrågningar som skickas av användare från den avdelning där de är godkännare.",
  "components.onboarding.joyride.admin.createTeams.title": "Skapa avdelningar och tilldela godkännare",
  "components.onboarding.joyride.admin.helpDesk.info": "Om du har några frågor eller behöver mer information, kolla in vår hjälpcentral eller starta en chatt med vårt kundsupportteam.",
  "components.onboarding.joyride.admin.helpDesk.title": "Hjälpcentral",
  "components.onboarding.joyride.admin.importHolidays.info1": "Helgdagar i Vacation Tracker ställs in för varje plats, och inte för hela organisationen.",
  "components.onboarding.joyride.admin.importHolidays.info2": "Du har möjlighet att importera helgdagar från vår lista med förinställda helgdagar för varje land, och möjligheten att importera en helgdag manuellt.",
  "components.onboarding.joyride.admin.importHolidays.title": "Importera helgdagar",
  "components.onboarding.joyride.admin.leaveTypesMenu.info1": "Vi rekommenderar alltid att du börjar din Vacation Tracker-resa genom att skapa ledighetstyper. Du kommer att tilldela dessa ledighetstyper senare när du skapar alla platser där ditt företag har anställda.",
  "components.onboarding.joyride.admin.leaveTypesMenu.info2": "Besök <link>Hjälpcentral</link> för en steg-för-steg-guide om hur du skapar och redigerar ledighetstyper.",
  "components.onboarding.joyride.admin.leaveTypesMenu.title": "Skapa ledighetstyper",
  "components.onboarding.joyride.admin.newLeaveType.info1": "Utöver standardledighetstypen (PTO) kan du skapa upp till 24 ytterligare ledighetstyper.",
  "components.onboarding.joyride.admin.start.title": "Lär dig om Vacation Tracker! 🏖️",
  "components.onboarding.joyride.admin.start1": "För att hjälpa dig komma igång har vi sammanställt några korta steg som vi rekommenderar att du följer för att snabbt konfigurera din organisation.",
  "components.onboarding.joyride.admin.start2": "Om du behöver vår hjälp längs vägen är vi bara ett e-postmeddelande bort på <link>hello@vacationtracker.io</link>",
  "components.onboarding.joyride.admin.teams.cta1": "Installera Microsoft Teams-bot",
  "components.onboarding.joyride.admin.teams.cta2": "Lär dig mer i vår Helpdesk",
  "components.onboarding.joyride.admin.teams.info1": "Du är klar med instrumentpanelen. Det sista steget är att installera Microsoft Teams-boten så att ditt team kan göra förfrågningar direkt i Microsoft Teams.",
  "components.onboarding.joyride.admin.teams.title": "Microsoft Teams",
  "components.onboarding.joyride.approver.dashboard.info1": "Denna sida är din kontrollcentral. Den ger dig en översikt över väntande ledigheter, vem som är ledig idag och kommande ledigheter för din organisation.",
  "components.onboarding.joyride.approver.leaveRequests.info1": "Som godkännare kan du \"Lägga till ledighet\" för alla medlemmar i det team du är godkännare för. Du kan också \"Begära ledighet\" för dig själv som kommer att skickas för godkännande till din godkännare.",
  "components.onboarding.joyride.approver.notifications.info1": "Denna sida ger en översikt över alla meddelanden som skickas i hela din organisation.",
  "components.onboarding.joyride.approver.notifications.info2": "Du kan skapa eller redigera meddelanden för användare i avdelningar där du är godkännare.",
  "components.onboarding.joyride.approver.notifications.info3": "Observera att du endast kan hantera de meddelanden som du har skapat.",
  "components.onboarding.joyride.approver.users.info1": "'Användare'-sidan ger dig en fullständig översikt över alla aktiva användare inom din avdelning tillsammans med skrivskyddad åtkomst till deras användarprofiler. Deras väntande förfrågningar finns tillgängliga på 'Dashboard'-sidan.",
  "components.onboarding.joyride.user.calendar.info1": "På denna sida kan du se historik och kommande ledigheter för din organisation i kalenderformat.",
  "components.onboarding.joyride.user.dashboard.info1": "Instrumentpanelen ger dig en översikt över dagens och schemalagda ledigheter inom din organisation.",
  "components.onboarding.joyride.user.holidays.info1": "Här kan du granska helgdagar inom din plats, så att du säkerställer att du vet vilka dagar du redan har ledigt.",
  "components.onboarding.joyride.user.myProfile.info1": "Här kan du se dina tillgängliga ledighetstyper, kvoter, arbetsdagar och din ledighetshistorik.",
  "components.onboarding.joyride.user.requestLeave.info1": "För att begära ledighet via Vacation Tracker Dashboard, välj en av de tillgängliga ledighetstyperna, lägg till tidsperioden och anledningen om nödvändigt, och skicka in begäran. När den är inskickad kommer den att skickas för godkännande till godkännaren.",
  "components.onboarding.joyride.user.start1": "För att hjälpa dig komma igång har vi sammanställt en lista med fem steg som vi rekommenderar att du följer för att snabbt konfigurera din organisation.",
  "components.onboarding.openNotificationsButton": "Öppna meddelanden",
  "components.onboarding.requestALeaveDescription": "Skicka in din första ledighetsbegäran i 3 enkla steg.",
  "components.onboarding.requestALeaveTitle": "<icon></icon> Begär en ledighet",
  "components.onboarding.setNotificationsDescription": "Välj hur ofta du vill bli informerad om ditt teams ledighet för att hålla dig uppdaterad.",
  "components.onboarding.setNotificationsTitle": "<icon></icon> Konfigurera meddelanden",
  "components.onboarding.skipOnboarding": "Hoppa över introduktion",
  "components.onboarding.syncTheCalendarDescription": "Integrera Vacation Tracker-kalendern med din kalender så att ditt teams ledighet är uppdaterad.",
  "components.onboarding.syncTheCalendarTitle": "<icon></icon> Synkronisera kalendrar",
  "components.onboarding.syncTheCalendarVideoForSyncingWithGoogleCalendar": "Video för synkronisering med Google Kalender",
  "components.onboarding.syncTheCalendarVideoForSyncingWithIcal": "Video för synkronisering med iCal",
  "components.onboarding.syncTheCalendarVideoForSyncingWithMicrosoftOutlookCalendar": "Video för synkronisering med MS Windows",
  "components.onboarding.title": "Checklista för introduktion <progressBar>{progressStatus}</progressBar>",
  "components.paymentInformation.expiryDate": "Utgångsdatum",
  "components.paymentInformation.labelCreditCard": "Kreditkort",
  "components.paymentInformation.title": "Betalningsinformation ",
  "components.platformSwitchModal.content": "Du måste först logga in med ditt {platform}-konto för att byta till {platform}-plattformen. Klicka på knappen nedan för att fortsätta. Efter inloggning och beviljande av nödvändiga behörigheter kommer vi att omdirigera till processen för användarmappning.",
  "components.platformSwitchModal.title": "Migration till {platform}-plattformen",
  "components.pricePlanCard.accruals": "Intjänad ledighet",
  "components.pricePlanCard.APISupport": "API-support",
  "components.pricePlanCard.calendarIntegrations": "Kalenderintegration",
  "components.pricePlanCard.contactUs": "Kontakta oss",
  "components.pricePlanCard.coreDepartmentsAndLocations": "Skapa upp till 3 platser och 10 avdelningar",
  "components.pricePlanCard.currentPlan": "Nuvarande plan",
  "components.pricePlanCard.customContract": "Anpassat kontrakt",
  "components.pricePlanCard.customizeLeaveTypes": "Anpassa ledighetstyper",
  "components.pricePlanCard.customLocationsDepartmentsLeaveTypes": "Obegränsade platser, avdelningar och anpassade ledighetstyper",
  "components.pricePlanCard.customOnboardingAndSetup": "Anpassad onboarding och konfiguration",
  "components.pricePlanCard.dedicatedSupport": "Dedikerad support",
  "components.pricePlanCard.disableDowngradeToCore": "Nedgradering till Core-planen kräver {noOfActions} {noOfActions, plural, =1 {åtgärd} other {åtgärder}}",
  "components.pricePlanCard.disableDowngradeToCoreDescription": "För att byta till Core-planen, vänligen gör följande justeringar:",
  "components.pricePlanCard.disableDowngradeToCoreReasonDepartments": "Core-planen tillåter maximalt 10 avdelningar. Vänligen ta bort eventuella extra avdelningar.",
  "components.pricePlanCard.disableDowngradeToCoreReasonLocations": "Core-planen tillåter maximalt 3 platser. Vänligen ta bort eventuella extra platser.",
  "components.pricePlanCard.disableDowngradeToCoreReasonSubdepartments": "I Core-planen kan avdelningar endast nästlas en nivå djupt. Du har för närvarande {total} underavdelningar. Vänligen ta bort några underavdelningar för att byta till Core-planen.",
  "components.pricePlanCard.disableDowngradeToCoreReasonWorkday": "Byte till Core-planen är inte möjligt när anpassade arbetstimmar används. Vänligen återställ anpassade arbetstimmar till standard (8) i platsinställningarna",
  "components.pricePlanCard.disableDowngradeToCoreTitle": "Plannedgradering. Justeringar krävs",
  "components.pricePlanCard.disableDowngradeToMultiLevelApproval": "Byte till Core-planen är inte möjligt medan flernivågodkännande är aktivt. För att nedgradera, vänligen byt till ennivågodkännanden i avdelningsinställningarna",
  "components.pricePlanCard.displayLeaveInformationInDaysHours": "Visa ledighetsinformation i dagar/timmar",
  "components.pricePlanCard.downgradePlan": "Planen kommer att nedgraderas nästa faktureringsperiod",
  "components.pricePlanCard.duringTheTrial": "Under provperioden kommer alla planändringar att genomföras omedelbart",
  "components.pricePlanCard.everythingInTheCompletePlan": "Allt i Complete-planen",
  "components.pricePlanCard.EverythingInTheCorePlan": "Allt i Core-planen",
  "components.pricePlanCard.exportsAndReports": "Export och rapporter",
  "components.pricePlanCard.forSeats": "för mindre än {users} platser",
  "components.pricePlanCard.forUsers": "för mindre än {users} användare",
  "components.pricePlanCard.hourlyLeaves": "Spåra ledighet i timmar",
  "components.pricePlanCard.HRFields": "HR-fält (kommer snart)",
  "components.pricePlanCard.labels": "Organisera användare med etiketter",
  "components.pricePlanCard.managePTO": "Hantera PTO-förfrågningar",
  "components.pricePlanCard.minimumPlanPrice": "minimum <price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>",
  "components.pricePlanCard.notifications": "Skicka PTO-notifieringar",
  "components.pricePlanCard.pricePerSeat": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>per plats/månad",
  "components.pricePlanCard.pricePerUser": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>per användare/månad",
  "components.pricePlanCard.reports": "Generera rapporter",
  "components.pricePlanCard.reportsSchedule": "Schemalägg rapporter",
  "components.pricePlanCard.requestHourlyLeaveIncrements": "Begär ledighet i timinkrement",
  "components.pricePlanCard.scheduleAConsultation": "Boka en konsultation",
  "components.pricePlanCard.scheduledReport": "Schemalagda rapporter",
  "components.pricePlanCard.selectPlan": "Välj plan",
  "components.pricePlanCard.sharedCalendars": "Delade kalendrar",
  "components.pricePlanCard.SSOIntegration": "Enterprise SSO-integration (Okta, etc.)",
  "components.pricePlanCard.startFreeTrial": "Starta din 14-dagars gratis provperiod",
  "components.pricePlanCard.substituteApprovers": "Ersättande godkännare",
  "components.pricePlanCard.support": "Prioriterad support",
  "components.pricePlanCard.switchPlan": "Byt <period> till {newPeriod} plan</period>",
  "components.pricePlanCard.totalPrice": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/månad för {totalUsers} aktiva användare",
  "components.pricePlanCard.totalPriceForLessThan25Users": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/månad för mindre än 25 användare",
  "components.pricePlanCard.totalPricePerSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/år",
  "components.pricePlanCard.totalPricePerUser": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/år",
  "components.pricePlanCard.totalPriceSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/månad för {totalUsers} platser",
  "components.pricePlanCard.unlimitedDepartmentsAndLocations": "Skapa obegränsat antal avdelningar och platser",
  "components.pricePlanCard.upgradePlan": "Planen kommer att aktiveras omedelbart och priset kommer att proportioneras",
  "components.pricePlanCard.workday": "Anpassningsbar arbetsdagslängd",
  "components.reports.companyLogs": "Företagets granskningsloggar",
  "components.reports.companyLogsDescription": "Historik över alla ändringar på företags-, plats-, avdelnings- och ledighetstyps-nivå för en specifik tidsperiod.",
  "components.reports.feedback": "Behöver du en annan rapport eller detaljnivå? Låt oss veta vad du behöver!",
  "components.reports.generate": "Generera och exportera",
  "components.reports.intro": "Skapa och exportera granskningsloggar, ledighetssaldon och ledighetshistorikrapporter på några minuter.",
  "components.reports.introOldOnes": "Gå till gamla rapporter",
  "components.reports.introPre": "Dina rapporter har blivit smartare! Förhandsgranska data direkt, filtrera med precision och anpassa dina kolumner—allt i det nya gränssnittet. Inte redo att byta? Din välbekanta rapportvy är bara ett klick bort.",
  "components.reports.scheduledReportsDescription": "Konfigurera och schemalägg rapporter som vi levererar till den valda e-postlistan på en vald dag och tid varje månad.",
  "components.requestAutomation.anythingElse": "Finns det något annat vi bör veta?",
  "components.requestAutomation.automationExtraCharge": "Skulle du använda detta tillägg om det medförde en extra kostnad?",
  "components.requestAutomation.automationFunctionalities": "Vilka funktioner eller uppgifter kommer tillägget att möjliggöra för dig?",
  "components.requestAutomation.automationShouldWeAdd": "Vilket tillägg bör vi lägga till?",
  "components.requestAutomation.submiteRequest": "Skicka förfrågan",
  "components.requestAutomation.suggestAnAutomation": "Föreslå ett tillägg",
  "components.resetQuotas.fiscalYear": "Räkenskapsår ({date})",
  "components.resetQuotas.userYear": "Användarens anställningsdatum",
  "components.selectChannelForm.addNewEmail": "Lägg till mottagare",
  "components.selectChannelForm.channel": "Kanal",
  "components.selectChannelForm.channelSlackInfo": "Notiser kan endast skickas till kanaler du är medlem i.",
  "components.selectChannelForm.email": "Mottagare",
  "components.selectChannelForm.errorNotification": "Notifieringsfel",
  "components.selectChannelForm.errorNotInTeams": "Åtkomst nekad. För att använda denna kanal måste du vara medlem i denna avdelning.",
  "components.selectChannelForm.notFound": "Din organisation registrerade sig med ett enda Microsoft Teams-team, och du är inte medlem i teamet med följande ID: {msTeamId}.\n\nDu kan gå med i detta team i din Microsoft Teams-applikation och försöka igen, eller kontakta vår support för att ändra din installationstyp.",
  "components.selectChannelForm.notFoundTitle": "Du är inte medlem i det valda Microsoft Teams-teamet",
  "components.selectChannelForm.team": "Team",
  "components.selectChannelFormMicrosoft.channelPlaceholder": "Vänligen välj en kanal",
  "components.selectChannelFormMicrosoft.errorNotInTeams": "Åtkomst nekad. För att använda denna kanal måste du vara medlem i denna avdelning.",
  "components.selectChannelFormMicrosoft.notFound": "Din organisation har registrerat sig med ett enda Microsoft Teams-team, och du är inte medlem i teamet med följande ID: {msTeamId}.\n\nDu kan gå med i detta team i din Microsoft Teams-applikation och försöka igen, eller kontakta vår support för att ändra din installationstyp.",
  "components.selectChannelFormMicrosoft.notFoundTitle": "Du är inte medlem i det valda Microsoft Teams-teamet",
  "components.selectChannelFormMicrosoft.teamPlaceholder": "Vänligen välj ett Team",
  "components.selectChannelFormSlack.errorNotification": "Notifieringsfel",
  "components.selectChannelFormSlack.missingSlackScopes": "Saknade Slack-behörigheter",
  "components.selectChannelFormSlack.privateChannel": "(privat kanal)",
  "components.selectChannelFormSlack.slackBotIdIsRequred": "Slack-fel: Slack Bot-ID krävs! Försök att uppdatera sidan!",
  "components.selectChannelFormSlack.slackError": "Slack-fel",
  "components.selectChannelFormSlack.toProccedPleaseLogin": "För att fortsätta, vänligen logga in på ditt Slack-konto",
  "components.selectChannelFormSlack.youCanAddTheBotOnlyOnTheChannels": "Du är inte medlem i {channelName}. För att skicka notifieringen till denna kanal måste du vara medlem i den.",
  "components.seniorityEntitlement.addDaysFrom": "Lägg till dagar från",
  "components.seniorityEntitlement.allFiledsAreRequired": "Alla fält är obligatoriska",
  "components.seniorityEntitlement.confirmDescription": "Att välja att börja beräkna anställningstidsbaserade rättigheter från anställningens startdatum kommer att justera användarnas kvoter retroaktivt från beräkningens startår och framåt.",
  "components.seniorityEntitlement.confirmTitle": "Bekräfta skapande av automatisering",
  "components.seniorityEntitlement.daysAfter": "dag(ar) efter",
  "components.seniorityEntitlement.disableEditInfo": "Du kan inte redigera automatiseringen för anställningstidsbaserade rättigheter. Om du behöver göra ändringar, radera denna automatisering och skapa en ny. <link>Kontakta oss</link> om du har några frågor eller behöver hjälp.",
  "components.seniorityEntitlement.hoursAfter": "timme/timmar efter",
  "components.seniorityEntitlement.noLocations": "Du har en automatisering som har tillämpats på alla användare, du kan inte ha flera automatiseringar för alla användare.",
  "components.seniorityEntitlement.notSortedYearInPeriodError": "År måste sorteras kronologiskt.",
  "components.seniorityEntitlement.prorateForTheFirstYear": "Vill du aktivera proportionell fördelning?",
  "components.seniorityEntitlement.prorateForTheFirstYearTooltip": "Om användarens anställningsdatum inte matchar räkenskapsårets startdatum kommer vi att proportionellt fördela anställningstidsbaserade dagar under året de tilldelas. Till exempel, om ditt räkenskapsår börjar den 1 januari, användaren började arbeta den 1 juli för 5 år sedan, och ditt företag ger 5 extra semesterdagar den 5 juli efter 5 års tjänst, kommer användaren att få 2,5 dagar den 1 juli på grund av den proportionella fördelningen. Denna användare kommer att få alla 5 anställningstidsbaserade dagar nästa räkenskapsår.",
  "components.seniorityEntitlement.sameYearError": "Du kan inte tilldela flera regler till samma antal år.",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFrom": "Börja tillämpa anställningstidsbaserade rättigheter från",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFromInfo": "Om du väljer att börja tillämpa anställningstidsbaserade rättigheter från anställningens startdatum kommer denna ändring att påverka användarens kvoter i det förflutna.",
  "components.seniorityEntitlement.summaryInfoPrefix": "Anställningstidsautomatisering tilldelar extra ledighet baserat på milstolpar. Till exempel: ",
  "components.seniorityEntitlement.summaryInfoSuffix": ", totalt {amount} <showDays>dagar</showDays><showHour>timme/timmar</showHour> anställningstidsbaserad ledighet.",
  "components.seniorityEntitlement.summeryInfoFirstRow": "{amount} <showDays>dagar</showDays><showHour>timme/timmar</showHour> läggs till vid {year} års anställning",
  "components.seniorityEntitlement.summeryInfoMoreDays": ", {amount} fler <showDays>dagar</showDays><showHour>timme/timmar</showHour> vid {year} års anställning",
  "components.seniorityEntitlement.yearsInTheCOmpany": "år i företaget",
  "components.setupMsBot.followDirectLink": "1) Klicka på <directLink>denna länk</directLink> för att hitta Vacation Tracker-applikationen i Microsoft Teams butiken.",
  "components.setupMsBot.inMSAppPreviewWindows": "2) I appförhandsvisningsfönstret, klicka på pilen bredvid \"Öppna\"-knappen och klicka på \"Lägg till i ett team\"",
  "components.setupMsBot.setUpInMS": "3) Du kommer att uppmanas att välja det team och den kanal där du vill installera Vacation Tracker. Du kommer också att få möjligheten att fästa Vacation Tracker som en flik för enklare och snabbare åtkomst!",
  "components.setupMsBot.stepByStep": "För detaljerade steg-för-steg-instruktioner, kolla in den här videon eller besök vår <helpDesk>Helpdesk</helpDesk>.",
  "components.setupMsBot.title": "Konfigurera Microsoft Teams-boten och flikarna",
  "components.signupWithAnotherPlatform.connectWith": " Registrera dig med {platform}",
  "components.signupWithAnotherPlatform.info": "Vill du ansluta med ditt {platform}-konto? Genom att göra det kommer du att kunna importera och synkronisera användare från din {importUsersPlatform} och hantera ledigheter direkt från din {platform}-applikation. För mer information, kolla in vår <helpDesk>Helpdesk</helpDesk>.",
  "components.signupWithAnotherPlatform.or": "Eller",
  "components.signupWithAnotherPlatform.title": "Det verkar som att du använder {platform}",
  "components.switchPlanModal.downgradeProbationPeriodWarning": "Prövotidstillägget är en betald funktion för Core-planen. Om du nedgraderar och inte avslutar prenumerationen på detta tillägg kommer det att debiteras med $0,50 per användare och månad.",
  "components.switchPlanModal.switch": "Byt",
  "components.switchPlanModal.switchCoreMonthlyToCompleteMonthly": "Den <strong>Kompletta</strong> månadsplanen kommer att aktiveras omedelbart och priset kommer att justeras proportionellt för den aktuella faktureringsperioden.<legacyPlan> Observera att du inte kommer att kunna byta tillbaka till den äldre planen.</legacyPlan>",
  "components.switchPlanModal.switchFromAnnuallyToMonthly": "Den <strong>{newPlan}</strong> månadsplanen kommer att aktiveras och faktureras efter slutet av den nuvarande årliga faktureringsperioden ({endDatePeriod}).<legacyPlan> Observera att du inte kommer att kunna byta tillbaka till den äldre planen.</legacyPlan>",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreAnnual": "Den <strong>Basic</strong> årsplanen kommer att aktiveras efter slutet av den nuvarande årliga faktureringsperioden ({endDatePeriod}), tills dess kan du fortsätta använda funktionerna i <strong>Komplett</strong>-planen",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreMonthly": "Den <strong>Basic</strong>-planen kommer att aktiveras efter slutet av den nuvarande årliga faktureringsperioden ({endDatePeriod}), tills dess kan du fortsätta använda funktionerna i <strong>Komplett</strong>-planen.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreAnnual": "Den <strong>Basic</strong> årsplanen kommer att aktiveras efter slutet av den nuvarande månatliga faktureringsperioden ({endDatePeriod}), tills dess kan du fortsätta använda funktionerna i <strong>Komplett</strong>-planen.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreMonthly": "Den <strong>Basic</strong> månadsplanen kommer att aktiveras efter slutet av den nuvarande faktureringsperioden ({endDatePeriod}), tills dess kan du fortsätta använda funktionerna i <strong>Komplett</strong>-planen.",
  "components.switchPlanModal.switchFromCoreAnnualToCompleteAnnual": "Den <strong>Kompletta</strong> årsplanen kommer att aktiveras omedelbart och du kommer att faktureras för den proportionella prisskillnaden fram till slutet av den nuvarande årliga faktureringsperioden.<legacyPlan> Observera att du inte kommer att kunna byta tillbaka till den äldre planen.</legacyPlan>",
  "components.switchPlanModal.switchFromCoreMonthlyToCompleteAnnual": "Den <strong>Kompletta</strong> årsplanen kommer att aktiveras omedelbart. Ditt årspris kommer att reduceras med det återstående beloppet på din nuvarande månadsfaktura.<legacyPlan> Observera att du inte kommer att kunna byta tillbaka till den äldre planen.</legacyPlan>",
  "components.switchPlanModal.switchFromMonthlyToAnnually": "Den <strong>{newPlan}</strong> årsplanen kommer att aktiveras omedelbart. Ditt årspris kommer att reduceras med det återstående beloppet på din nuvarande månadsfaktura.<legacyPlan> Observera att du inte kommer att kunna byta tillbaka till den äldre planen.</legacyPlan>",
  "components.switchPlanModal.title": "Byt till <strong>{plan}</strong>-plan",
  "components.switchPlanModal.unsubscribeProbationPeriodAddons": "Avsluta prenumeration på prövotidstillägg",
  "components.tables.accruedAmount": "Upplupen mängd",
  "components.tables.approver": "Godkännare",
  "components.tables.broughtForward": "Överfört",
  "components.tables.department": "Avdelning",
  "components.tables.duration": "Varaktighet",
  "components.tables.durationDays": "Dagar",
  "components.tables.durationHours": "Timmar",
  "components.tables.editColumns": "Redigera kolumner",
  "components.tables.editColumnsDesc": "Ordning på aktiva kolumner",
  "components.tables.email": "E-post",
  "components.tables.employeeId": "Anställnings-ID",
  "components.tables.endDate": "Slutdatum",
  "components.tables.endHour": "Sluttimme",
  "components.tables.endMinute": "Slutminut",
  "components.tables.expiredBroughtForward": "Utgånget överfört",
  "components.tables.id": "Id",
  "components.tables.initialBroughtForward": "Initialt överfört",
  "components.tables.isFullDay": "Är heldag",
  "components.tables.labels": "Etiketter",
  "components.tables.leaveType": "Ledighetstyp",
  "components.tables.location": "Plats",
  "components.tables.month": "{month}-{year}",
  "components.tables.reason": "Anledning",
  "components.tables.remainingAmount": "Återstående mängd",
  "components.tables.requestType": "Begärandetyp",
  "components.tables.scheduledAmount": "Schemalagd mängd",
  "components.tables.startDate": "Startdatum",
  "components.tables.startHour": "Starttimme",
  "components.tables.startMinute": "Startminut",
  "components.tables.status": "Status",
  "components.tables.toil": "TOIL",
  "components.tables.toilExpired": "TOIL utgången",
  "components.tables.usedAmount": "Använt belopp",
  "components.tables.user": "Användare",
  "components.tables.yearlyQuota": "Årlig kvot",
  "components.tables.yearlyQuotaBase": "Årlig baskvot",
  "components.tables.yearlyQuotaRoleBased": "Årlig rollbaserad kvot",
  "components.tables.yearlyQuotaSeniority": "Årlig anställningstidsbaserad kvot",
  "components.teamForm.approvers": "Godkännare",
  "components.teamForm.generalSettings": "Allmänna inställningar",
  "components.teamForm.isDefault": "Gör denna avdelning till standard",
  "components.teamForm.isDefaultExtra": "Genom att göra denna avdelning till standard kommer alla nya teammedlemmar automatiskt att läggas till i denna avdelning",
  "components.teamForm.name": "Namn",
  "components.teamForm.pleaseEnterTeamName": "Ange avdelningsnamn",
  "components.teamForm.users": "Användare",
  "components.toil.accrualsToilsTotal": "Totalt intjänade {hours, plural, =1 {timmar} other {dagar}} (TOIL + intjäning): {total}",
  "components.toil.added": "TOIL tillagd",
  "components.toil.addToil": "Lägg till TOIL",
  "components.toil.addToilProgress": "Lägger till TOIL",
  "components.toil.anotherAdd": "Lägg till ytterligare TOIL",
  "components.toil.anotherRequest": "Begär ytterligare TOIL",
  "components.toil.approveInProgress": "Godkänner TOIL-begäran",
  "components.toil.bot.message.editedToilRequest": "Hej, <@{userSlackId}> har redigerat följande TOIL-begäran:",
  "components.toil.bot.message.leaveTypeName": "*Ledighetstyp:*\nTOIL - {leaveTypeName}",
  "components.toil.bot.message.requestToil": "Begär TOIL",
  "components.toil.bot.message.requestToilSummary": "Vänligen initiera din begäran om kompensationsledighet genom att ange datumen då du utförde övertidsarbete och inkludera eventuella relevanta anmärkningar. Observera att begäran om kompensationsledighet endast kan göras för datum i det förflutna, inklusive idag.",
  "components.toil.bot.message.submittedToilRequest": "Hej, <@{userSlackId}> har skickat in följande TOIL-begäran:",
  "components.toil.bot.notification.slack.toilRequestAdded": "En TOIL-ledighet har skapats för dig av en godkännare",
  "components.toil.bot.notification.slack.toilRequestAddedSelf": "Du har lagt till en TOIL-ledighet",
  "components.toil.bot.notification.slack.toilRequestApproved": "✅ <@{approverName}> har godkänt begäran, ingen ytterligare åtgärd krävs.",
  "components.toil.bot.notification.slack.toilRequestApprovedTitle": "Din ledighetsbegäran har godkänts",
  "components.toil.bot.notification.slack.toilRequestCreated": "Hej, <@{userSlackId}> har skickat in en TOIL-begäran.",
  "components.toil.bot.notification.slack.toilRequestEdited": "Hej, <@{userSlackId}> har redigerat en TOIL-begäran.",
  "components.toil.cancelInProgress": "Avbryter TOIL-begäran",
  "components.toil.deleteInProgress": "Raderar TOIL",
  "components.toil.denied": "Din TOIL-begäran har nekats",
  "components.toil.deniedBy": "TOIL-begäran har nekats av {approverName}.",
  "components.toil.denyInProgress": "Nekar TOIL-begäran",
  "components.toil.durationLabel": "Kortaste varaktighet för kompensationsledighet",
  "components.toil.durationLabelTooltip": "Vänligen välj den kortaste varaktigheten som gäller för TOIL-begäran. Detta val bör återspegla den minsta mängden övertid som en användare kan logga.",
  "components.toil.earned": "Intjänad TOIL",
  "components.toil.edit": "Uppdatera TOIL",
  "components.toil.edited": "TOIL uppdaterad",
  "components.toil.editRequestToil": "Redigera begäran om kompensationsledighet",
  "components.toil.editToilProgress": "Redigerar kompensationsledighet",
  "components.toil.enableLabel": "Aktivera kompensationsledighet:",
  "components.toil.enableLabelTooltip": "TOIL är en praxis där anställda kompenseras för övertidsarbete genom att få motsvarande tid ledig från arbetet, istället för att få extra betalt för de extra arbetade timmarna.",
  "components.toil.expiration": "Utgång",
  "components.toil.expirationDuration": "{noOfMonths} {noOfMonths, plural, =1 {månad} other {månader}}",
  "components.toil.expirationDurationCalendar": "Slutet av kalenderåret",
  "components.toil.expirationLabel": "Ska kompensationsledighetsdagar förfalla?",
  "components.toil.expirationLabelTooltip": "Aktivera denna inställning om du vill att TOIL-dagar ska ha ett utgångsdatum, efter vilket de inte längre kan användas. Om du väljer 'Ja' kommer du att uppmanas att välja förfalloregler. Om 'Nej' väljs kommer TOIL-dagar inte att förfalla och kommer att förbli tillgängliga tills de används.",
  "components.toil.featurePlan": "TOIL-funktionen är endast tillgänglig för Complete-planen.",
  "components.toil.fullDayLeavePeriod": "{date}{endDate} ",
  "components.toil.halfDay": "Halv dags TOIL",
  "components.toil.hourly": "TOIL per timme",
  "components.toil.lapsed": "förfallen",
  "components.toil.locationNotHaveToil": "Ingen ledighetspolicy med TOIL är inställd för {me, plural, =1 {din} other {användarens}} plats",
  "components.toil.notification.added": "TOIL <forUser>för </forUser>{requestor} tillagd <byActionCreator>av {actionCreator}</byActionCreator>",
  "components.toil.notification.approved": "{requestor}s TOIL godkänd <byActionCreator>av {actionCreator}</byActionCreator> ✅",
  "components.toil.notification.cancelled": "{requestor}s TOIL avbruten <byActionCreator>av {actionCreator}</byActionCreator>",
  "components.toil.notification.created": "TOIL begärd <byActionCreator>av {actionCreator}</byActionCreator>",
  "components.toil.notification.deleted": "TOIL borttagen <byActionCreator>av {actionCreator}</byActionCreator>",
  "components.toil.notification.denied": "{requestor}s TOIL nekad <byActionCreator>av {actionCreator}</byActionCreator> ❌",
  "components.toil.notification.edited": "{requestor}s TOIL redigerad <byActionCreator>av {actionCreator}</byActionCreator>",
  "components.toil.notification.editedApproved": "En schemalagd TOIL är redigerad <byActionCreator>av {actionCreator}</byActionCreator> ✅",
  "components.toil.notification.editedDenied": "Redigerad TOIL nekad <byActionCreator>av {actionCreator}</byActionCreator>",
  "components.toil.partDayPeriod": "{date} - från {partDayStartHour} till {partDayEndHour} ",
  "components.toil.reasonDefault": "Extra timmar arbetade under den ovan angivna tidsperioden.",
  "components.toil.request": "TOIL-begäran",
  "components.toil.requested": "TOIL begärd",
  "components.toil.requestedOrAddedDaysHours": "{action, plural, =1 {Begärd} other {Tillagd}} {period}",
  "components.toil.requester": "Vem kan begära kompensationsledighet?",
  "components.toil.requesterAdmin": "Endast administratörer och godkännare",
  "components.toil.requesterAll": "Alla användare",
  "components.toil.requesterTooltip": "Välj 'Alla användare' för att tillåta varje anställd att lämna in sina egna TOIL-begäranden efter övertidsarbete. Välj 'Endast administratörer och godkännare' om du föredrar att centralisera TOIL-hanteringen och behålla striktare kontroll över dess godkännande och registrering.",
  "components.toil.requestNotFound": "TOIL-begäran hittades inte",
  "components.toil.requestToil": "Begär kompensationsledighet",
  "components.toil.requestToilProgress": "Begär kompensationsledighet",
  "components.toil.submitError": "TOIL-inlämningsfel",
  "components.toil.submitErrorDate": "TOIL-begäran kan inte skickas in eftersom startdatumet du valt är efter slutdatumet. Se till att startdatumet är före slutdatumet och skicka in begäran igen",
  "components.toil.submitErrorDateFuture": "Begärt TOIL-datum måste vara i det förflutna",
  "components.toil.submitErrorLongerThanWorkday": "Begärda TOIL-timmar är längre än arbetstiden",
  "components.toil.submitErrorNotOpen": "TOIL är inte öppen",
  "components.toil.submitErrorOverlap": "Begärda TOIL-dagar överlappar med befintlig TOIL",
  "components.toil.submitErrorPlan": "Denna funktion är exklusivt tillgänglig för användare som prenumererar på vår Complete-plan.",
  "components.toil.submitErrorPolicy": "Ledighetspolicyn för denna ledighetstyp tillåter inte TOIL",
  "components.toil.submitErrorPolicyDuration": "Användare med anpassade arbetstider får inte begära flerdagars TOIL",
  "components.toil.submitErrorPolicyLocation": "TOIL-alternativet är inte aktiverat för din plats. Du kan aktivera det på sidan för ledighetspolicyer eller kontakta din administratör för support.",
  "components.toil.submitErrorReasonNotSet": "TOIL-anledning krävs",
  "components.toil.submitErrorRole": "TOIL-begäran inte tillåten",
  "components.updateWorkweekForm.cancel": "Avbryt",
  "components.updateWorkweekForm.form.error": "Vänligen välj minst en arbetsdag",
  "components.updateWorkweekForm.title": "Uppdatera arbetsvecka",
  "components.updateWorkweekForm.update": "Uppdatera",
  "components.updateWorkweekForm.workWeek": "Arbetsvecka",
  "components.userBroughtForwardForm.customRolloverDays": "Dagar",
  "components.userBroughtForwardForm.leaveTypeEditInfo": "Du redigerar ledighetstypen \"{leaveTypeName}\" för perioden {dateRangeName}.",
  "components.userBroughtForwardForm.update": "Uppdatera",
  "components.userBroughtForwardForm.updateUserLeaveType": "Uppdatera överförda dagar för ledighetstyp {name}",
  "components.userLabes.addLabel": "Lägg till etikett",
  "components.userLabes.manageLabels": "Hantera etiketter",
  "components.userLabes.newLabel": "(ny etikett)",
  "components.userLabes.noLabelsFound": "Inga etiketter hittades",
  "components.userLeaveQuotas.accrualsTooltipTitle": "Intjänande är ackumuleringen eller den gradvisa ökningen av dina betalda ledighetstimmar under året.",
  "components.userLeaveQuotas.accrualsTooltipTitle-bi-weekly": "Ett varannan vecka {leaveType} intjänande sker varannan vecka",
  "components.userLeaveQuotas.accrualsTooltipTitle-monthly": "Ett månatligt {leaveType} intjänande sker en gång i månaden.",
  "components.userLeaveQuotas.accrualsTooltipTitle-semi-monthly": "Ett halvmånatligt {leaveType} intjänande sker två gånger i månaden.",
  "components.userLeaveQuotas.accrualsTooltipTitle-weekly": "Ett veckovist {leaveType} intjänande sker varje vecka",
  "components.userLeaveQuotas.accruedDays": "Intjänade dagar",
  "components.userLeaveQuotas.accruedHours": "Intjänade timmar",
  "components.userLeaveQuotas.accruedInfo1": "{userName} har tjänat in {earnedDays} dagar ({earnedDaysInHours} timmar) av {defaultDays} dagar ({defaultDaysInHours} timmar)",
  "components.userLeaveQuotas.accruedInfo1Days": "{userName} har tjänat in {earnedDays} av {defaultDays} dagar",
  "components.userLeaveQuotas.accruedInfo1Hours": "{userName} har tjänat in {earnedDaysInHours} av {defaultDaysInHours} timmar",
  "components.userLeaveQuotas.accruedInfo2": "{userName} kommer att tjäna in {toAccrueDays} dagar ({toAccrueHours} timmar) den {earnDate}",
  "components.userLeaveQuotas.accruedInfo2Days": "{userName} kommer att tjäna in {toAccrueDays} dagar den {earnDate}",
  "components.userLeaveQuotas.accruedInfo2Hours": "{userName} kommer att tjäna in {toAccrueHours} timmar den {earnDate}",
  "components.userLeaveQuotas.baseQuota": "Baskvot",
  "components.userLeaveQuotas.broughtForward": "Överfört",
  "components.userLeaveQuotas.broughtForwardDaysInfo": "<rolloverDays>Max {maxRolloverDays} dagar<br></br></rolloverDays><expiresDate>Går ut {date}</expiresDate>",
  "components.userLeaveQuotas.broughtForwardInfo": "Överfört representerar antalet oanvända dagar som överförts från föregående period.",
  "components.userLeaveQuotas.broughtForwardInfoDetailsAll": "För {leaveTypeName} på platsen {locationName} kan alla oanvända dagar överföras till nästa period.",
  "components.userLeaveQuotas.broughtForwardInfoDetailsDays": "För {leaveTypeName} på platsen {locationName} kan anställda överföra upp till {broughtForwardLimit} oanvända {broughtForwardLimit, plural, =1 {dag} other {dagar}}.",
  "components.userLeaveQuotas.broughtForwardInfoDetailsHours": "För {leaveTypeName} på platsen {locationName} kan anställda överföra upp till {broughtForwardLimit} oanvända {broughtForwardLimit, plural, =1 {timme} other {timmar}}.",
  "components.userLeaveQuotas.broughtForwardValue": "Överfört",
  "components.userLeaveQuotas.editInitialQuotaTitle": "Redigera initialt överfört antal dagar för {leaveTypeName}",
  "components.userLeaveQuotas.editInitialQuotaTooltip": "Initialt överfört låter dig manuellt ange anställdas startsaldon från ett tidigare spårnings- eller HR-system",
  "components.userLeaveQuotas.editQuotaButton": "Ändra",
  "components.userLeaveQuotas.expirationTooltip": "Dagar som den anställde överförde från föregående period men som inte användes före utgångsdatumet",
  "components.userLeaveQuotas.expiredDateInfo": "den {date}",
  "components.userLeaveQuotas.forMoreDetails": "För mer information, se",
  "components.userLeaveQuotas.initialBroughtForward": "Initialt överfört",
  "components.userLeaveQuotas.initialBroughtForwardInfo": "Initialt överfört är ledigsaldot som en användare har fört över från förra året, då Vacation Tracker inte var en del av er organisation. Observera att du bara kan redigera det initialt överförda ledigsaldot, medan den tillgängliga ledigheten du kan överföra de kommande åren kommer att beräknas av Vacation Tracker.",
  "components.userLeaveQuotas.leavePoliciesNotExistInfo": "Det finns för närvarande inga tillgängliga ledighetstyper. Vänligen tilldela ledighetspolicyer till platsen som denna användare tillhör.",
  "components.userLeaveQuotas.leaveTypes": "Ledighetstyper",
  "components.userLeaveQuotas.logsTab": "loggfliken.",
  "components.userLeaveQuotas.noLimit": "Du har en obegränsad kvot.",
  "components.userLeaveQuotas.ofDefaultDays": "av {value} dagar",
  "components.userLeaveQuotas.ofDefaultHours": "av {value} timmar",
  "components.userLeaveQuotas.overlapDaysContactSupport": "Överlappning av datum, vänligen kontakta supporten för mer information.",
  "components.userLeaveQuotas.paidDays": "Utbetalda dagar",
  "components.userLeaveQuotas.quota": "Kvot",
  "components.userLeaveQuotas.quotaInfo": "Kvot representerar antalet {leaveTypeName} dagar tillgängliga för den aktuella perioden. Detta antal inkluderar grundkvoten, kompensationsledighet och dagar tilldelade baserat på den anställdes roll eller tjänsteålder.",
  "components.userLeaveQuotas.quotaInfoSummary": "Kvot för den aktuella perioden:",
  "components.userLeaveQuotas.remaining": "Kvarstående",
  "components.userLeaveQuotas.remainingAfterRequest": "Kvarstående efter begäran",
  "components.userLeaveQuotas.remainingTooltip": "Kvarstående {leaveTypeName} dagar för den aktuella perioden. Totalt:",
  "components.userLeaveQuotas.remainingTooltipFooter": "Upp till {broughtForwardLimit} oanvända {period} kommer att överföras till nästa period",
  "components.userLeaveQuotas.remainingTooltipFooterAll": "Alla oanvända dagar och timmar kan överföras till nästa period.",
  "components.userLeaveQuotas.remainingTooltipFooterZero": "Inga oanvända dagar och timmar kan överföras till nästa period.",
  "components.userLeaveQuotas.requested": "Begärt",
  "components.userLeaveQuotas.saveQuotaButton": "Spara",
  "components.userLeaveQuotas.scheduled": "Schemalagt",
  "components.userLeaveQuotas.scheduledDays": "Schemalagda dagar",
  "components.userLeaveQuotas.sectionTitle": "Ledighetskvot",
  "components.userLeaveQuotas.selectPeriod": "Välj period:",
  "components.userLeaveQuotas.unlock": "Lås upp",
  "components.userLeaveQuotas.unlockBroughtForwardInfo": "För att låsa upp, gå till Inställningar → Platser → {locationName} → Visa ledighetspolicyer, och redigera {leaveTypeName} policyn för att tillåta överföring.",
  "components.userLeaveQuotas.used": "Använt",
  "components.userLeaveQuotas.usedAlready": "Redan använd",
  "components.userLeaveQuotas.usedDays": "Använda dagar",
  "components.userLeaveQuotas.usedTooltip": "Använda, planerade och utbetalda {leaveTypeName} dagar för den aktuella perioden. Totalt:",
  "components.userLeaveQuotas.userInactiveInfo": "Eftersom kontot nu är inaktivt kommer inga fler dagar att läggas till förrän det återaktiveras.",
  "components.userLeaveQuotasCompact.title": "Din ledighetsbalans",
  "components.userLeaveQuotasCompact.titleForApprover": "Ledighetsbalans",
  "components.userLeaveTypeForm.applyForFutureYears": "Vill du tillämpa grundkvoten för ledighet för framtida år?",
  "components.userLeaveTypeForm.cancel": "Avbryt",
  "components.userLeaveTypeForm.daysPerYear": "Vad är din nya ledighetskvot?",
  "components.userLeaveTypeForm.hasUnlimitedDays": "Vill du tillåta obegränsad ledighet?",
  "components.userLeaveTypeForm.leaveTypeEditInfo": "Du redigerar ledighetstypen \"{leaveTypeName}\" för perioden {dateRangeName}.",
  "components.userLeaveTypeForm.prorateInfo": "{user} kommer att ha {proratedQuota} ({preciseQuota} dagar, för att vara exakt) för det första året ({yearPeriod}) och {fullQuota} för alla år som börjar {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateInfoDays": "{user} kommer att ha {proratedQuota} ({preciseQuota} dagar, för att vara exakt) för det första året ({yearPeriod}) och {fullQuota} dagar för alla år som börjar {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateInfoHours": "{user} kommer att ha {proratedQuota} ({preciseQuota} timmar, för att vara exakt) för det första året ({yearPeriod}) och {fullQuota} timmar för alla år som börjar {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateLabel": "Proportionera för det första året baserat på användarens startdatum ({startDate})",
  "components.userLeaveTypeForm.quotaAdditionalRole": "Ställ in ytterligare dagar baserat på roll",
  "components.userLeaveTypeForm.quotaAdditionalRoleInfo": "Tilldela ytterligare ledighetsdagar till anställda beroende på deras arbetsroll. Detta fält låter dig anpassa ledighetsberättiganden för att återspegla ansvaret eller specifika krav för olika positioner inom organisationen.",
  "components.userLeaveTypeForm.quotaAdditionalSeniority": "Ställ in ytterligare dagar baserat på anställningstid",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfo": "Anställningstidsbaserade dagar läggs till av automatisering för anställningstidsberättigande. Du kan skriva över anställningstidsbaserade {leaveTypeName} dagar genom att ställa in värdet i detta fält.",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfoUpcomingYear": "Anställningstidsbaserade dagar läggs till av automatisering för anställningstidsberättigande. Du kan skriva över anställningstidsbaserade {leaveTypeName} dagar genom att ställa in värdet i detta fält. Detta fält är dock låst eftersom du inte kan redigera detta värde för framtida år innan dagar tilldelas.",
  "components.userLeaveTypeForm.quotaAdditionalSummary": "Du kommer att ställa in {leaveTypeName} ledighetskvoten för {user} för den valda perioden till {total} {hourlyLeaveAccounting, plural, =1 {timmar} other {dagar}}. Grundkvoten och de ytterligare dagarna baserade på anställningstidsberättigande och berättigande per roll ({total} {hourlyLeaveAccounting, plural, =1 {timmar} other {dagar}} totalt) kommer att tillämpas för {future, plural, =1 {den valda perioden och framtida perioder} other {den valda perioden}}. {prorateText}",
  "components.userLeaveTypeForm.quotaAdditionalSummaryUnlimited": "Du kommer att ställa in {leaveTypeName} ledighetskvoten för {user} för den valda perioden till Obegränsad.",
  "components.userLeaveTypeForm.quotaBase": "Ställ in grundkvoten för ledighet",
  "components.userLeaveTypeForm.quotaBaseTooltip": "Grundkvoten är användarens standardkvot, som exkluderar ytterligare dagar som erhållits baserat på deras anställningstid, roll, TOIL eller andra extra dagar. Du kan ändra grundkvoten för alla i en plats på sidan för ledighetspolicy eller använda detta fält för att ställa in en anpassad kvot för en vald användare.",
  "components.userLeaveTypeForm.required": "Detta fält är obligatoriskt.",
  "components.userLeaveTypeForm.update": "Uppdatera",
  "components.userLeaveTypeForm.updateUserLeaveType": "Uppdatera användarens kvoter för {name} ledighetstyp",
  "components.userLogsTab.ACCRUED_DAYS": "Intjänade dagar",
  "components.userLogsTab.ALL": "Alla",
  "components.userLogsTab.APPROVER_STATUS": "Rollförändring",
  "components.userLogsTab.filter": "Filter:",
  "components.userLogsTab.LOCATION_USER_MOVED": "Platsförändring",
  "components.userLogsTab.logsNotFound": "Inga loggar hittades för valt datumintervall.",
  "components.userLogsTab.ROLLOVER_EXPIRED": "Överförda dagar har löpt ut",
  "components.userLogsTab.selectFromTo": "Välj datumintervall:",
  "components.userLogsTab.TEAM_USER_MOVED": "Avdelningsförändring",
  "components.userLogsTab.TOIL_REQUEST_APPROVED": "TOIL",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE": "Berättigande per roll",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_DELETED": "Berättigande per roll raderat",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_OVERRIDE": "Berättigande per roll åsidosatt",
  "components.userLogsTab.USER_LEAVE_TYPES_DELETED": "Ledighetskvot återställd till standard",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_DELETED": "Initial överföring raderad",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_UPDATED": "Initial överföring uppdaterad",
  "components.userLogsTab.USER_LEAVE_TYPES_UPDATED": "Ledighetskvot uppdaterad",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT": "Senioritetsbehörighet",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT_OVERRIDE": "Åsidosättande av senioritetsbehörighet",
  "components.userLogsTab.USER_STATUS_CHANGED": "Statusändring",
  "components.userLogsTab.USER_UPDATED": "Användarinformation uppdaterad",
  "components.userLogsTab.USER_WORK_WEEK_DELETED": "Anpassad arbetsvecka raderad",
  "components.userLogsTab.USER_WORK_WEEK_UPDATED": "Arbetsvecka uppdaterad",
  "components.userLogsTab.YEAR_START": "Årets början",
  "components.userManageLabels.actions": "Åtgärder",
  "components.userManageLabels.color": "Färg",
  "components.userManageLabels.deleteLabel": "Är du säker på att du vill radera denna etikett?\n\nDetta kommer att radera etiketten för alla tilldelade användare.",
  "components.userManageLabels.findLabel": "Hitta etikett",
  "components.userManageLabels.isRequired": "{title} krävs",
  "components.userManageLabels.manageLabels": "Hantera etiketter",
  "components.userManageLabels.name": "Namn",
  "components.userManageLabels.userCount": "Antal användare",
  "components.userProfileTab.admin": "Administratör",
  "components.userProfileTab.approver": "Godkännare",
  "components.userProfileTab.approvers": "Godkännare:",
  "components.userProfileTab.approverSuffix": " (Godkännare)",
  "components.userProfileTab.customWorkWeekEditedBefore": "{userName}s arbetsvecka ändrades i den tidigare versionen av Vacation Tracker (före 1 januari 2021)",
  "components.userProfileTab.customWorkWeekLabel": "anpassad arbetsvecka",
  "components.userProfileTab.customWorkWeekTooltip": "{userName}s arbetsvecka redigerades av {adminName} den {timestamp}",
  "components.userProfileTab.employeeIdNotSet": "Ej inställd",
  "components.userProfileTab.employeeIdTooltip": "ID för anställd i ett externt system",
  "components.userProfileTab.employeeSince": "Anställd sedan:",
  "components.userProfileTab.endDate": "Slutdatum:",
  "components.userProfileTab.location": "Plats:",
  "components.userProfileTab.name": "Namn:",
  "components.userProfileTab.nameEdited": "Namnet har ändrats manuellt och kommer inte längre att synkroniseras automatiskt. Detta namn används endast inom Vacation Tracker.",
  "components.userProfileTab.role": "Roll:",
  "components.userProfileTab.status": "Status:",
  "components.userProfileTab.team": "Avdelning:",
  "components.userProfileTab.user": "Användare",
  "components.userProfileTab.userProfile": "Användarprofil",
  "components.userProfileTab.workingDays": "Arbetsdagar",
  "components.userTodayOff.cancelLeave": "Avbryt ledighet",
  "components.userTodayOff.editLeave": "Redigera ledighet",
  "components.userTodayOff.leaveInfo": "Ledighetsinformation",
  "components.userTodayOff.offFromTo": "Användaren har tagit {leaveTypeName} från {startDate} till {endDate}",
  "components.userTodayOff.offTodayFullDay": "Användaren har tagit {leaveTypeName} idag, {today}",
  "components.userTodayOff.offTodayPartDay": "Användaren har tagit {leaveTypeName} ledigt idag från {from} till {to}.",
  "components.userTodayOff.offTodayReason": "Anledning: {reason}",
  "components.userUpdateForm.active": "Aktiv",
  "components.userUpdateForm.admin": "Administratör",
  "components.userUpdateForm.approver": "Godkännare",
  "components.userUpdateForm.approverSuffix": " (Godkännare)",
  "components.userUpdateForm.cancel": "Avbryt",
  "components.userUpdateForm.employeeSince": "Anställd sedan",
  "components.userUpdateForm.location": "Plats",
  "components.userUpdateForm.name": "Namn",
  "components.userUpdateForm.pleaseSelectALocation": "Välj en plats",
  "components.userUpdateForm.pleaseSelectATeam": "Välj en avdelning",
  "components.userUpdateForm.pleaseSelectAUserRole": "Välj en användarroll",
  "components.userUpdateForm.pleaseSelectEmployeeSince": "Lägg till anställningsdatum",
  "components.userUpdateForm.pleaseSelectUserEndDate": "Lägg till användarens slutdatum",
  "components.userUpdateForm.role": "Roll",
  "components.userUpdateForm.team": "Avdelning",
  "components.userUpdateForm.update": "Uppdatera",
  "components.userUpdateForm.updateUser": "Uppdatera användare",
  "components.userUpdateForm.user": "Användare",
  "components.userUpdateForm.userEndDate": "Anställningens slutdatum",
  "components.userUpdateForm.warningInitialRolloverForChangeLocation": "Att ändra användarens plats kan påverka användarens konfiguration och ledighetsinformation (t.ex. de initiala överförda dagarna).",
  "components.userUpdateForm.warningInitialRolloverForChangeStartDate": "Att ändra en användares startdatum kan påverka användarens ledighetsinformation (t.ex. de initiala överförda dagarna).",
  "components.whatsNew.newMessage": "Nytt meddelande",
  "components.whatsNew.whatsNew": "Vad är nytt",
  "connect.alreadyHaveAnAccount": "Har du redan ett konto?",
  "connect.alreadyHaveAnAccountWithLink": "Har du redan ett konto? <b><link>Logga in</link></b>",
  "connect.backToLoginWithOtherPlatofrm": "Tillbaka för att logga in med annan plattform",
  "connect.companyExists.line1": "{companyName} är redan registrerat hos Vacation Tracker",
  "connect.companyExists.line2Other": "Använder du rätt e-postadress?",
  "connect.companyExists.line2Slack": "Använder du rätt inloggningsuppgifter?",
  "connect.companyExists.line3aSlack": "Din Slack-arbetsyta: {workspaceName}",
  "connect.companyExists.line3Other": "Din e-postadress: {email}",
  "connect.companyExists.line3Slack": "Din e-postadress: {email}",
  "connect.companyExists.line4Other": "Om denna e-postadress är korrekt är ditt konto inte aktiverat. Vänligen kontakta administratören för din Vacation Tracker-organisation:",
  "connect.companyExists.line4Slack": "Om dessa inloggningsuppgifter är korrekta är ditt konto inte aktiverat. Vänligen kontakta administratören för din Vacation Tracker-organisation:",
  "connect.companyExists.line5": "I annat fall, logga in med ett annat <capitalize>{platform}</capitalize>-konto eller logga in från ett inkognitofönster i din webbläsare.",
  "connect.companyExists.line6Microsoft": "Kontakta supporten för att skapa en ny avdelning i {companyName}",
  "connect.companyExists.lineEmailAddress": "Din e-postadress",
  "connect.companyExists.title": "Företaget finns redan",
  "connect.connect": "Anslut",
  "connect.connectWithWorkEmail": "Logga in med jobbmail",
  "connect.connectWithYourWorkEmail": "Logga in med din jobbmail",
  "connect.copyEmail": "Klicka för att kopiera e-post",
  "connect.copyTextEnd": "Trevlig semester! 🌴",
  "connect.createAnOrganization": "Skapa en organisation",
  "connect.description": "För att komma åt Vacation Tracker-instrumentpanelen, välj knappen \"Anslut med\" som motsvarar plattformen som ditt team använder.",
  "connect.dontHaveAnAccount": "Har du inget konto?",
  "connect.doYouWantToLoginInToOrganization": "Vill du logga in på {existingOrganizationName}?",
  "connect.forgotYourPassword": "Glömt ditt lösenord?",
  "connect.forgotYourPasswordWhatsYourEmail": "Vad är din e-postadress?",
  "connect.google.notWorkspaceUserDescription": "Det verkar som att du försöker registrera dig med ett personligt konto och inte ett Google Workspace-konto. För närvarande stöder Vacation Tracker endast Google Workspace-konton.",
  "connect.google.notWorkspaceUserTitle": "Ett Google Workspace-konto krävs",
  "connect.google.sharedPermissionsConsent": "Genom att klicka på knappen nedan ger du Vacation Tracker tillstånd att komma åt din Google-administratörs användarkataloger.",
  "connect.google.sharedPermissionsError": "Misslyckades med att dela Google-behörigheter, din länk har upphört att gälla.",
  "connect.google.sharedPermissionsRequired": "Otillräckliga behörigheter",
  "connect.google.sharedPermissionsRequiredDescription": "För att fortsätta med att importera användare behöver du få behörigheter från din Google-administratör. Vänligen dela denna länk med din administratör för att ge nödvändigt samtycke: ",
  "connect.google.sharedPermissionsSuccess": "Google-behörigheter har delats framgångsrikt!",
  "connect.google.sharedPermissionsSuccessRedirect": "Du kommer att omdirigeras till Vacation Trackers startsida om några sekunder.",
  "connect.howShouldIConnect": "Hur ska jag ansluta?",
  "connect.inputEmailPlaceholder": "Ange din arbets-e-post",
  "connect.inputPasswordPlaceholder": "Ange ditt lösenord",
  "connect.interactionRequiredMSErrorDescription1": "Det verkar som att ditt Microsoft-konto kräver ytterligare behörigheter. Vänligen klicka på Logga in-knappen nedan för att bevilja ytterligare behörigheter.",
  "connect.interactionRequiredMSErrorDescription2": "Om detta problem kvarstår, vänligen kontakta vår support och skicka dem följande kod:",
  "connect.interactionRequiredMSErrorTitle": "Microsoft kräver ytterligare behörigheter",
  "connect.login": "Logga in",
  "connect.meta.description": "Få tillgång till Vacation Tracker-instrumentpanelen och lås upp den fulla kraften i enkel ledighetshantering.",
  "connect.meta.title": "Registrera/Logga in - Vacation Tracker",
  "connect.meta.title.signin": "Logga in - Vacation Tracker",
  "connect.meta.title.signup": "Registrera - Vacation Tracker",
  "connect.or": "Eller",
  "connect.platformErrorDescription1": "Följande fel inträffade:",
  "connect.platformErrorDescription2": "Detta kan vara ett {platform}-fel. Men tveka inte att kontakta vår support för ytterligare hjälp.",
  "connect.platformErrorTitle": "Något gick fel",
  "connect.pleaseInputValidEmail": "Vänligen ange en giltig e-postadress",
  "connect.pleaseInputValidPassword": "Vänligen ange ett giltigt lösenord",
  "connect.pleaseLogIn": "Vänligen logga in",
  "connect.readMore": "För mer information, besök vår <helpdesk>Helpdesk</helpdesk> eller gå till vår <website>webbplats</website>.",
  "connect.signInWithEmail": "Logga in med e-post",
  "connect.signInWithGoogle": "Logga in med Google",
  "connect.signInWithGoogleToOrganization": "Logga in på Google Workspaces till {existingOrganizationName}",
  "connect.signInWithGoogleVerification": "Logga in med Google",
  "connect.signInWithMS": "Logga in med Microsoft Teams",
  "connect.signInWithMSToOrganization": "Logga in med Microsoft Teams till {existingOrganizationName}",
  "connect.signInWithSlack": "Logga in med Slack",
  "connect.signInWithSlackInstallBot": "Lägg till Vacation Tracker i Slack",
  "connect.signInWithSlackToOrganization": "Logga in med Slack till {existingOrganizationName}",
  "connect.signUp": "Registrera dig med din jobbmejl",
  "connect.signUpInfo": "Registrera dig för din kostnadsfria provperiod",
  "connect.signupNewOrganizationUsingNewPlatform": "Registrera dig som en ny organisation med ditt {selectedPlatform}-konto",
  "connect.signUpWithEmail": "Registrera dig med e-post",
  "connect.signUpWithGoogle": "Registrera dig med Google",
  "connect.signUpWithMicrosoft": "Registrera dig med Microsoft",
  "connect.signUpWithSlack": "Registrera dig med Slack",
  "connect.slack.usersLoginRequiredButton": "Bevilja Slack-behörigheter",
  "connect.slack.usersLoginRequiredDescription1": "Din Slack-bottoken har gått ut eller är inte längre giltig. Du kommer inte att kunna komma åt Vacation Tracker-boten eller ta emot aviseringar förrän du aktiverar en ny token.",
  "connect.slack.usersLoginRequiredDescription2": "För att aktivera en ny Slack-bottoken, klicka på OK.",
  "connect.slack.usersLoginRequiredDescription3": "För att hoppa över detta steg och fortsätta till den online-baserade instrumentpanelen, klicka på Hoppa över.",
  "connect.slack.usersLoginRequiredTitle": "Inloggning på ditt Slack-konto krävs",
  "connect.slackPostInstallation1": "Du har framgångsrikt installerat Vacation Tracker Slack-applikationen 🎉",
  "connect.slackPostInstallation2": "För att börja använda appen, vänligen logga in på Vacation Tracker Dashboard och slutför installationen.",
  "connect.slackPostInstallation3": "Vi kommer att ställa några enkla frågor. Det bör inte ta mer än några minuter. Blink of an eye and it's done (precis som din senaste semester).",
  "connect.slackPostInstallationError.generic": "Hej, vi behöver installera Vacation Tracker-boten först. Du kommer att kunna granska Vacation Tracker Slack-appens behörigheter och bevilja dem i nästa steg.",
  "connect.slackPostInstallationError.invalidCode": "Omdirigeringskoden är inte längre giltig. Det kan hända om koden har gått ut, redan använts, eller om du uppdaterade sidan innan vi slutförde inloggningsprocessen. Men oroa dig inte, låt oss skaffa en ny!",
  "connect.subscriptionExpired": "Hoppsan, det verkar som att ditt företags prenumeration på Vacation Tracker har gått ut! Vänligen kontakta en administratör för att återaktivera ditt företags prenumeration.",
  "connect.subscriptionExpiredTitle": "Prenumeration har gått ut",
  "connect.thisOrganizationUsePlatform": "Dock använder {existingOrganizationName} {existsOnAnotherPlatform}, så du behöver logga in med {existsOnAnotherPlatform} för att komma åt ditt konto",
  "connect.welcome": "Välkommen, {name}!",
  "connect.youAreAlreadyMember": "Du är redan medlem i organisationen {existingOrganizationName}",
  "connectCalendar.connect": "Anslut med",
  "connectCalendar.disconnect": "Koppla från",
  "connectCalendar.disconnectDescription": "Är du säker på att du vill koppla från kalendern? Vacation Tracker kommer inte längre att uppdatera den med dina framtida ledigheter",
  "connectCalendar.disconnectError": "Fel vid frånkoppling av kalender",
  "connectCalendar.disconnectErrorDescription": "Det uppstod ett fel när din kalender skulle kopplas från Vacation Tracker. Vänligen klicka på Ok för att försöka igen.",
  "connectCalendar.disconnectSuccess": "Du har framgångsrikt kopplat från {calendar}-kalendern.",
  "connectCalendar.disconnectTitle": "Koppla från {calendarPlatform}-kalender",
  "connectCalendar.error": "Fel vid anslutning av kalender",
  "connectCalendar.errorDescription": "Det uppstod ett fel när din kalender skulle anslutas till Vacation Tracker. Vänligen klicka på Ok för att försöka igen.",
  "connectCalendar.errorReconnectDescription": "Det uppstod ett fel när din kalender skulle återanslutas till Vacation Tracker. Vänligen klicka på Ok för att försöka igen.",
  "connectCalendar.reconnect": "Återanslut",
  "connectCalendar.reconnectAdminConsentSharedCalendar": "Administratörsmedgivande krävs för att lägga till eller uppdatera händelser i kalendern.",
  "connectCalendar.reconnectError": "Fel vid återanslutning av kalender",
  "connectCalendar.reconnectInfo": "För att säkerställa att din ledighet är synlig, vänligen återanslut till din kalender.",
  "connectCalendar.reconnectNotification": "Anslutningen till din kalender har upphört. Vänligen klicka här för att återansluta och säkerställa att din ledighet är synlig i din kalender.",
  "connectCalendar.reconnectNotificationSharedCalendar": "Din anslutning till den delade kalendern: {calendarName} har upphört. Vänligen klicka här för att återansluta och säkerställa att ledigheter är synliga i din kalender.",
  "connectCalendar.reconnectSuccess": "Du har framgångsrikt återanslutit {calendar} kalender.",
  "connectCalendar.success": "Du har framgångsrikt anslutit {calendar} kalender.",
  "connectedCalendar.eventDescription": "Tillagd av <link>Vacation Tracker</link>",
  "connectedCalendar.eventDescriptionShared": "Delad kalenderhändelse tillagd av <link>Vacation Tracker</link>",
  "containers.google.topbar.adminProductTourInfo": "Som administratör har du den högsta behörighetsnivån i Vacation Tracker och kan anpassa alla organisationens inställningar via administratörens instrumentpanel.\n\nInstrumentpanelen låter dig:\n- Begära ledighet eller lägga till ledighet för andra avdelningsmedlemmar.\n- Få en fullständig överblick via instrumentpanelens startsida och kalendern.\n- Skapa och konfigurera flera avdelningar (tilldela godkännare).\n- Ställa in meddelanden.\n- Hantera användare (aktiva, inaktiva, borttagna).\n- Skapa och hantera platser (ledighetstyper, helgdagar, meddelanden).\n\nOm dina frågor eller funderingar förblir obesvarade, kontakta oss när som helst. Att prata med våra kunder är den bästa delen av vår dag:",
  "containers.google.topbar.approverProductTourInfo": "Som godkännare har du den näst högsta behörighetsnivån i Vacation Tracker och kan godkänna eller neka ledighetsansökningar som skickats in av användare från den avdelning de tilldelats som godkännare för.\n\nInstrumentpanelen låter dig:\n- Begära ledighet eller lägga till ledighet för andra avdelningsmedlemmar.\n- Få en fullständig överblick via instrumentpanelens startsida och kalendern.\n- Ställa in meddelanden.\n- Visa din användarprofil.\n- Hantera ansökningar (godkänna/neka ansökningar, visa historik).\n\nOm dina frågor eller funderingar förblir obesvarade, kontakta oss när som helst. Att prata med våra kunder är den bästa delen av vår dag:",
  "containers.google.topbar.userProductTourInfo": "Som användare av Vacation Tracker kan du skicka in och hantera dina ledighetsansökningar via instrumentpanelen, samt få en fullständig överblick över planerade frånvaron inom din organisation.\n\nInstrumentpanelen låter dig:\n- Begära ledighet.\n- Få en fullständig överblick via instrumentpanelens startsida och kalendern.\n- Visa din användarprofil.\n\nOm dina frågor eller funderingar förblir obesvarade, kontakta oss när som helst. Att prata med våra kunder är den bästa delen av vår dag:",
  "containers.topbar.adminProductTourInfo": "Som administratör har du den högsta behörighetsnivån i Vacation Tracker och kan anpassa alla organisationens inställningar via administratörens instrumentpanel.\n\nInstrumentpanelen låter dig:\n- Begära ledighet eller lägga till ledighet för andra avdelningsmedlemmar.\n- Få en fullständig överblick via instrumentpanelens startsida och kalendern.\n- Skapa och konfigurera flera avdelningar (tilldela godkännare).\n- Ställa in meddelanden.\n- Hantera användare (aktiva, inaktiva, borttagna).\n- Skapa och hantera platser (ledighetstyper, helgdagar, meddelanden).\n\nBoten låter dig:\n- Begära ledighet.\n- Godkänna/neka ledighetsansökningar.\n- Ta emot dagliga/veckovisa meddelanden.\n\nOm dina frågor eller funderingar förblir obesvarade, kontakta oss när som helst. Att prata med våra kunder är den bästa delen av vår dag:",
  "containers.topbar.adminProductTourTitle": "Administratör",
  "containers.topbar.announcement": "Meddelande",
  "containers.topbar.approverProductTourInfo": "Som godkännare har du den näst högsta behörighetsnivån i Vacation Tracker och kan godkänna eller neka ledighetsansökningar som skickats in av användare från den avdelning de tilldelats som godkännare för.\n\nInstrumentpanelen låter dig:\n- Begära ledighet eller lägga till ledighet för andra avdelningsmedlemmar.\n- Få en fullständig överblick via instrumentpanelens startsida och kalendern.\n- Ställa in meddelanden.\n- Visa din användarprofil.\n- Hantera ansökningar (godkänna/neka ansökningar, visa historik).\n\nBoten låter dig:\n- Begära ledighet.\n- Godkänna/neka ledighetsansökningar.\n- Ta emot dagliga/veckovisa meddelanden.\n\nOm dina frågor eller funderingar förblir obesvarade, kontakta oss när som helst. Att prata med våra kunder är den bästa delen av vår dag:",
  "containers.topbar.approverProductTourTitle": "Godkännare",
  "containers.topbar.giveUsFeedback": "Ge oss feedback",
  "containers.topbar.help": "Hjälp",
  "containers.topbar.logout": "Logga ut",
  "containers.topbar.profile.info": "Du kan komma åt din profil, byta tema och starta snabbturen från denna nya meny.",
  "containers.topbar.setUpTheBot": "Konfigurera boten",
  "containers.topbar.userProductTourInfo": "Som användare av Vacation Tracker kan du skicka in och hantera dina ledighetsansökningar via instrumentpanelen eller boten, samt få en fullständig överblick över planerade frånvaron inom din organisation.\n\nInstrumentpanelen låter dig:\n- Begära ledighet.\n- Få en fullständig överblick via instrumentpanelens startsida och kalendern.\n- Visa din användarprofil.\n\nBoten låter dig:\n- Begära ledighet.\n- Ta emot dagliga/veckovisa meddelanden.\n\nOm dina frågor eller funderingar förblir obesvarade, kontakta oss när som helst. Att prata med våra kunder är den bästa delen av vår dag:",
  "containers.topbar.userProductTourTitle": "Användare",
  "containers.topbar.whatsNewUnread": "Vad är nytt - {unread} olästa",
  "Conversation not found.": "Den valda kanalen har tagits bort eller är inte längre tillgänglig. Vänligen välj en annan kanal för att ta emot Meddelanden.",
  "createCompany.signupError": "Något gick fel",
  "createCompany.steps.back": "Tillbaka",
  "createCompany.steps.cancel": "Avbryt",
  "createCompany.steps.companyForm": "Företagsuppgifter",
  "createCompany.steps.companyForm.agreeTerms": "Jag accepterar <aPP>Integritetspolicy</aPP>, <aTOS>Användarvillkor</aTOS> och <aDPA>DPA</aDPA>. Jag bekräftar också och samtycker till behandlingen av mina personuppgifter i enlighet med villkoren som beskrivs i integritetspolicyn",
  "createCompany.steps.companyForm.agreeToTerms.error": "Du måste godkänna våra användarvillkor och integritetspolicy för att fortsätta.",
  "createCompany.steps.companyForm.companyInfoSectionDescription": "Berätta om ditt företag och plats. Vi kommer att använda denna information för att skapa ditt företagskonto och din första plats. Du kan alltid lägga till fler platser senare.",
  "createCompany.steps.companyForm.companyLeaveSection": "Standard ledighetstyp",
  "createCompany.steps.companyForm.companyName": "Företagsnamn",
  "createCompany.steps.companyForm.companyNameInput.validation.required": "Detta fält är obligatoriskt.",
  "createCompany.steps.companyForm.contactEmail.validation.country": "Detta är ett obligatoriskt fält.",
  "createCompany.steps.companyForm.contactEmail.validation.required": "Nödvändig för viktig produktkommunikation.",
  "createCompany.steps.companyForm.contactEmail.validation.state": "Detta är ett obligatoriskt fält.",
  "createCompany.steps.companyForm.contactEmail.validation.type": "Vänligen ange en giltig e-postadress.",
  "createCompany.steps.companyForm.contactEmailExtra": "E-postadressen du anger kommer endast att användas för viktig produktkommunikation. Inget spam, vi lovar.",
  "createCompany.steps.companyForm.country": "Land",
  "createCompany.steps.companyForm.defaultPtoQuota": "Betald ledighet (PTO) kvot",
  "createCompany.steps.companyForm.defaultPtoQuota.validation": "Vänligen ange din standard PTO-kvot",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.maxDefaultPtoQuota": "Din standard PTO-kvot kan inte vara mer än 366 dagar",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.minDefaultPtoQuota": "Din standard PTO-kvot kan inte vara mindre än 0 dagar",
  "createCompany.steps.companyForm.defaultPtoQuotaExtra": "Du kan ändra detta senare i dina inställningar.",
  "createCompany.steps.companyForm.email": "Kontakt-e-post",
  "createCompany.steps.companyForm.location": "Platsinformation: ",
  "createCompany.steps.companyForm.state": "Stat",
  "createCompany.steps.companyForm.step1Title": "Företagsuppgifter",
  "createCompany.steps.companyForm.step2Title": "Välj användare",
  "createCompany.steps.companyForm.step3Title": "Välj plan",
  "createCompany.steps.companyForm.unlimitedLeavePolicy": "Obegränsad PTO",
  "createCompany.steps.companyForm.UserInfoSectionDescription": "Berätta för oss vem du är. Vi kommer att använda denna information för att skapa din användarprofil. Som standard tilldelas du rollen som administratör och godkännare. Du kan lägga till fler användare och ändra deras roller senare.",
  "createCompany.steps.inviteUsers.next": "Bjud in {noOfUsers} användare{postfix}",
  "createCompany.steps.inviteUsers.title": "Bjud in dina teammedlemmar",
  "createCompany.steps.inviteUsers.title.info": "Bjud in de användare du vill lägga till i din organisations Vacation Tracker-konto. Du kan alltid göra ändringar senare via instrumentpanelen.",
  "createCompany.steps.next": "Fortsätt",
  "createCompany.steps.selectPlan.noCC": "Inget kreditkort krävs för din 7-dagars provperiod",
  "createCompany.steps.selectPlan.title": "Låt oss börja!",
  "createCompany.steps.selectPlan.youCanSwitchPlanDuringTrial": "Du kan byta plan när som helst under provperioden",
  "createCompany.steps.selectUsers": "Importera användare",
  "createCompany.steps.selectUsers.deselectAll": "Avmarkera alla",
  "createCompany.steps.selectUsers.doneButton": "Importera {noOfUsers} användare{postfix}",
  "createCompany.steps.selectUsers.doneButtonTryItOut": "Starta din 14-dagars gratis provperiod",
  "createCompany.steps.selectUsers.loadingAll": "Laddar alla användare",
  "createCompany.steps.selectUsers.searchUsers": "Hitta Nemo...",
  "createCompany.steps.selectUsers.selectAll": "Välj alla",
  "createCompany.steps.selectUsers.title": "Välj dina teammedlemmar",
  "createCompany.steps.selectUsers.title.info": "Välj de användare du vill lägga till i din organisations Vacation Tracker-konto. Du kan alltid göra ändringar senare via instrumentpanelen. Användarna du väljer kommer inte att meddelas förrän du gör tillkännagivandet.",
  "dashboard.accountSettingsLanguageLabel": "Välj ditt föredragna språk",
  "dashboard.accountSettingsOutOfOfficeChangeModalInfo": "Vacation Tracker kommer att ställa in alla dina framtida frånvaromeddelanden för e-postadressen {email}.\n Om du har schemalagt ett frånvaromeddelande innan denna ändring kommer vi dock fortfarande att ställa in det för den gamla e-postadressen.",
  "dashboard.accountSettingsOutOfOfficeChangeModalTitle": "Ändra e-postadress för framtida frånvaromeddelanden",
  "dashboard.accountSettingsOutOfOfficeConnectedWith": "Ansluten med",
  "dashboard.accountSettingsOutOfOfficeTitle": "Konfigurera automatiskt frånvarosvar (OOO)",
  "dashboard.accountSettingsPageOOODescription": "När funktionen är aktiverad kommer Vacation Tracker att meddela dig två dagar innan din planerade ledighet och ge dig möjlighet att ställa in ett automatiskt frånvaromeddelande (OOO).",
  "dashboard.accountSettingsTimeDisplayLabel": "Välj ditt föredragna tidsvisningsformat",
  "dashboard.accountSettingsUIModeLabel": "Mörkt läge vs. Ljust läge",
  "dashboard.b2bSoftware": "Observera att detta är en B2B-programvara och därför kan vi inte ändra eller radera dina uppgifter på begäran. För mer information, vänligen kontakta din dedikerade chef. Om du behöver hjälp finns vårt supportteam tillgängligt på <mail>hello@vacationtracker.io</mail>",
  "dashboard.day": "dag",
  "dashboard.days": "Dag(ar)",
  "dashboard.daysPlural": "dagar",
  "dashboard.daysSmallCase": "dag(ar)",
  "dashboard.daysSmallCaseSingle": "d",
  "dashboard.googlePermissionInfo2": "- Åtkomst till dina Google Kalenderinställningar krävs för att hämta dina Google tidszonsinställningar.",
  "dashboard.googlePermissionInfo3": "- Åtkomst till dina Gmail-inställningar behövs för att ställa in ditt OOO-svar och definiera relevant datumintervall.",
  "dashboard.hour": "timme/timmar",
  "dashboard.hourOne": "timme",
  "dashboard.hours": "timmar",
  "dashboard.hourSingle": "h",
  "dashboard.minute": "minut",
  "dashboard.minuteAbbreviation": "m",
  "dashboard.minutes": "minuter",
  "dashboard.noLeaveRequest": "Denna ledighetsförfrågan är inte aktiv.",
  "dashboard.noOneIsOffToday": "Det finns inga planerade ledigheter för idag.",
  "dashboard.noOneIsTackingLeaves": "Det finns inga schemalagda ledigheter för tillfället.",
  "dashboard.noOpenLeavesRequests": "Det finns inga väntande förfrågningar.",
  "dashboard.noScheduledLeavesHolidays": "Inga schemalagda ledigheter eller helgdagar",
  "dashboard.openLeavesRequests": "Väntande förfrågningar",
  "dashboard.outlookPermissionInfo2": "- Offlineåtkomst: Detta är nödvändigt för att Vacation Tracker ska kunna ställa in ditt OOO-svar.",
  "dashboard.outlookPermissionInfo3": "- Outlook-brevlådeinställningar: Vi behöver åtkomst till dina Outlook-tidszonsinställningar för att säkerställa korrekt timing. Dessutom behöver vi tillstånd att ställa in ditt OOO-meddelande och definiera önskat datumintervall.",
  "dashboard.outOfOffice": "Ställ in OOO-meddelande",
  "dashboard.outOfOffice.requestIntegrationButton": "Begär en integration",
  "dashboard.outOfOfficeActiveLabel": "Frånvarande",
  "dashboard.outOfOfficeGoogleMailButton": "Google-e-post",
  "dashboard.outOfOfficeGoogleSigninModalTitle": "Google-kontoinloggning krävs",
  "dashboard.outOfOfficeMicrosoftSigninModalTitle": "Microsoft-kontoinloggning krävs",
  "dashboard.outOfOfficeNotActiveInfo": "Det verkar som att du ännu inte har aktiverat funktionen för automatiskt frånvaromeddelande i ditt konto. Detta verktyg låter dig ställa in automatiska e-postmeddelanden för specifika typer av ledighet, vilket säkerställer att dina kontakter vet när du är otillgänglig. Vill du aktivera denna funktion?",
  "dashboard.outOfOfficeOtherEmail": "Har du en annan e-postleverantör?",
  "dashboard.outOfOfficeOtherEmailInfo": "För närvarande stöder vi Gmail och Outlook. Vi strävar dock ständigt efter att förbättra vår programvara. Din återkoppling om vilken e-postleverantör du använder skulle vara till stor hjälp för att bestämma våra utvecklingsprioriteringar. Tills vi utökar vårt stöd till andra leverantörer kan du manuellt ställa in ditt frånvaromeddelande inom din e-posttjänst.",
  "dashboard.outOfOfficeOtherEmailPlaceholder": "Ange din leverantör t.ex. ProtonMail, Yahoo! Mail...",
  "dashboard.outOfOfficeOutlookButton": "Outlook",
  "dashboard.outOfOfficePageSettingsTitle": "VÄLJ DIN E-POSTLEVERANTÖR",
  "dashboard.outOfOfficePageTitle": "Frånvaro",
  "dashboard.outOfOfficeSet": "Ställ in OOO-meddelande",
  "dashboard.outOfOfficeSetError": "Det uppstod ett fel när ditt automatiska frånvarosvar skulle ställas in. Vänligen försök igen.",
  "dashboard.outOfOfficeSetInfo": "Här kan du ställa in ditt 'Frånvaro'-e-postsvar. När du är borta kommer detta meddelande att skickas automatiskt till personer som försöker nå dig via e-post. Om du vill ändra detta meddelande i framtiden kan det enkelt göras genom dina e-postinställningar.",
  "dashboard.outOfOfficeSetInfoBot": "Ditt 'Frånvaro'-e-postsvar är inställt. När du är borta kommer detta meddelande att skickas automatiskt till personer som försöker nå dig via e-post. Om du vill ändra detta meddelande i framtiden kan det enkelt göras genom dina e-postinställningar.",
  "dashboard.outOfOfficeSetSuccess": "Grattis — du har framgångsrikt ställt in ditt automatiska frånvaromeddelande. Det kommer att aktiveras när din ledighet börjar.",
  "dashboard.peopleOffToday": "Dagens ledigheter",
  "dashboard.permissionModalInfo": "För att Vacation Tracker automatiskt ska kunna ställa in ditt Frånvaro (OOO) svar behöver vi ytterligare behörigheter:",
  "dashboard.selectSnoozeLeaveTypesPlaceholder": "Välj ledighetstyper...",
  "dashboard.snoozedLeaveTypesLabel": "Avaktivera för ",
  "dashboard.upcomingDaysOff": "Planerade ledigheter ({total})",
  "dashboard.upcomingHolidays": "Kommande helgdagar",
  "departments.viewDepartment": "Visa avdelning",
  "disconnectCalendar.success": "Du har framgångsrikt kopplat bort kalendern.",
  "editLeave.editLeave": "Redigera ledighet",
  "editLeave.handleSubmitErrorTitle": "Fel vid redigering av ledighet",
  "editLeave.inProgress": "Redigerar ledighet",
  "editLeave.requestLeave": "Redigera ledighet",
  "editLeave.title": "Ledighet redigerad",
  "email.notification.accruedDaysInfoForApprover": "ℹ️ Denna ledighetsförfrågan baseras på framtida intjänade ledighetsdagar, inte det nuvarande antalet. {name} har {amount} dag(ar) kvar idag. De kommer att tjäna in {accrued} den {earningDate}.",
  "email.notification.accruedDaysInfoForUser": "ℹ️ Din ledighetsförfrågan baseras på framtida intjänade ledighetsdagar, inte det nuvarande antalet. Du har {amount} dag(ar) kvar idag, och du kommer att tjäna in {accrued} den {earningDate}.",
  "email.notification.holiday.plural": "<strong>{locationNames}</strong> platser kommer att vara lediga för <strong>{holidayName}</strong> {holidayPeriodText}",
  "email.notification.holiday.singular": "<strong>{locationNames}</strong> plats kommer att vara ledig för <strong>{holidayName}</strong> {holidayPeriodText}",
  "email.notification.title": "Ledighetsnotifiering",
  "error.ApproverNotFound": "Godkännare hittades inte",
  "error.auth.companyExists.line1": "Ditt konto är inte aktiverat. För att använda Vacation Tracker, vänligen kontakta administratören och be dem att aktivera ditt konto.",
  "error.auth.companyExists.line2": "Administratörer:",
  "error.auth.companyExists.line3": "Eller kontakta vår support ({support}) om du vill använda Vacation Tracker som en oberoende avdelning i samma företag.",
  "error.auth.companyExists.title": "Din organisation är redan registrerad",
  "error.automations.automationSubmitError": "Fel vid inskickning av automation",
  "error.automations.insufficientPrivileges.description": "Du har inte behörighet att utföra denna åtgärd. Vänligen kontakta din administratör.",
  "error.automations.insufficientPrivileges.title": "Behörighetsfel",
  "error.blackOutPeriod.isntCreated": "Avstängningsperiod kunde inte skapas",
  "error.blackOutPeriod.isntDeleted": "Avstängningsperiod kunde inte raderas",
  "error.blackOutPeriod.isntUpdated": "Avstängningsperiod kunde inte uppdateras",
  "error.blackOutPeriod.UserNotAdminOrApprover": "Användaren är varken administratör eller godkännare",
  "error.blackOutPeriod.UserNotFound": "Användaren hittades inte",
  "error.botTokenNotFound": "Bot-token hittades inte, vänligen logga in på vår dashboard och försök igen.",
  "error.CannotDuplicateName": "Namnet du angav används redan. Vänligen ange ett annat namn.",
  "error.channelNotFound": "Den valda kanalen har raderats eller är inte längre tillgänglig. Vänligen välj en annan kanal för att ta emot Notifieringar.",
  "error.codeDeliveryFailureException": "Leverans av kod misslyckades",
  "error.codeDeliveryFailureExceptionMessage": "Vi kan inte leverera en verifieringskod. Vänligen kontrollera att du angett en giltig e-postadress. Om din e-postadress är giltig, vänligen försök igen senare.",
  "error.codeMismatchException": "Ogiltig verifieringskod",
  "error.codeMismatchExceptionMessage": "Ogiltig verifieringskod angiven. Vänligen försök igen eller skicka e-post på nytt.",
  "error.companyExists": "Företaget finns redan",
  "error.CustomWorkday": "Det är omöjligt att nedgradera till en plan utan anpassade arbetstimmar. Vänligen ta bort anpassade arbetstimmar från inställningarna eller kontakta support.",
  "error.deletedLocation": "Den valda platsen har raderats eller är inte längre tillgänglig. Vänligen välj en annan plats för att ta emot meddelanden för.",
  "error.deletedTeam": "Den valda avdelningen har raderats eller är inte längre tillgänglig. Vänligen välj en annan avdelning för att ta emot meddelanden för.",
  "error.DepartmentWithChildrenDeleteForbidden": "Du kan inte radera en avdelning som har underavdelningar. Vänligen radera underavdelningarna först.",
  "error.EmailAlreadyExists": "En användare med denna e-postadress finns redan i Vacation Tracker.",
  "error.emailError": "Ogiltig e-postadress",
  "error.emailError.description": "Vänligen ange en giltig e-postadress",
  "error.endTimeCantBeBeforeStartTime": "Det ser ut som att starttiden du valt är efter sluttiden. Vänligen välj en starttid som är före sluttiden.",
  "error.entitlementByRole.isntCreated": "Berättigande per roll skapades inte",
  "error.entitlementByRole.isntDeleted": "Berättigande per roll raderades inte",
  "error.entitlementByRole.isntUpdated": "Berättigande per roll uppdaterades inte",
  "error.expiredCodeException": "Verifieringskoden har gått ut",
  "error.expiredCodeExceptionMessage": "Den angivna verifieringskoden har gått ut.",
  "error.fetchingConnectedCalendarInfo": "Det uppstod ett problem med att hämta din anslutna kalender. Vänligen försök igen. Om problemet kvarstår, kontakta vår kundsupport",
  "error.fetchingUserOutOfOfficeSettings": "Det uppstod ett problem med att hämta dina frånvaroinställningar. Vänligen försök igen. Om problemet kvarstår, kontakta vår kundsupport",
  "error.fieldIsRequired": "Detta fält är obligatoriskt.",
  "error.firstEarningDateMissing": "Första intjäningsdatum saknas",
  "error.generic": "Ett fel inträffade",
  "error.generic.description": "Vänligen kontakta support med följande meddelande:",
  "error.google.directoryApiDisabledP1": "Vacation Tracker behöver åtkomst till katalogdata för att importera nya användare.",
  "error.google.directoryApiDisabledP2": "Administratören för din Google Workspace kan tillåta katalogåtkomst genom att följa stegen från supportartikeln: ",
  "error.google.directoryApiDisabledP3": "Du kan registrera dig utan att aktivera denna funktion. Du kommer dock inte att kunna importera användare från din Google Workspace.",
  "error.google.directoryApiDisabledP4": "Om du behöver ytterligare hjälp, kontakta oss på hello@vacationtracker.io",
  "error.google.directoryApiDisabledTitle": "Kontaktdelning avstängd",
  "error.google.directoryApiPermissionDeniedP1": "Vacation Tracker behöver kunna lista användare från din Google Workspace-katalog. Klicka på knappen \"Logga in med Google\" och markera kryssrutan som visas i bilden nedan för att kunna registrera dig.",
  "error.google.directoryApiPermissionDeniedP2": "Vi kommer endast att lagra följande information för de användare du uttryckligen väljer att importera: namn, profilbild och e-postadress.",
  "error.google.directoryApiPermissionDeniedP3": "Om du inte ser kryssrutan när du klickar på knappen \"Logga in med Google\", klicka på knappen \"Fortsätt\" i Google-behörighetsmodalen för att fortsätta med registreringsprocessen.",
  "error.google.directoryApiPermissionDeniedTitle": "Bevilja saknade behörigheter för att fortsätta",
  "error.google.loginRequiredTitle": "Inloggning till ditt Google-konto krävs",
  "error.google.usersLoginRequiredDescription1": "Hantering av användare kräver Google-omfattningar. Om du klickar på OK-knappen kommer vi att omdirigera dig till Googles inloggningssida. Beroende på dina webbläsarinställningar kan du redan vara inloggad och automatiskt omdirigeras tillbaka.",
  "error.google.usersLoginRequiredDescription2": "Efter inloggning på ditt Google-konto kommer vi att ta dig tillbaka till denna sida så att du kan hantera användarna.",
  "error.goToRequestLeaveTab": "Fel vid övergång till fliken Begär ledighet",
  "error.holidayDateInvalid": "Ogiltigt helgdatum {date} för {year} ",
  "error.incorrectPassword": "Felaktigt lösenord",
  "error.incorrectPasswordMessage": "Lösenordet du angav är felaktigt eller har gått ut. Vänligen försök igen (se till att Caps Lock är avstängd). Glömt lösenord?",
  "error.invalidFileType": "Ogiltig filtyp",
  "error.invalidFileType.description": "Du laddade upp en ogiltig filtyp. Vänligen ladda upp en JPEG-, PNG- eller GIF-bild.",
  "error.invalidPasswordException": "Ogiltigt lösenord",
  "error.invalidPasswordExceptionMessage": "Ange ett giltigt lösenord med minst 8 tecken.",
  "error.leaveDurationLimit.isntCreated": "Det gick inte att skapa gräns för ledighetens varaktighet",
  "error.leaveDurationLimit.isntDeleted": "Det gick inte att ta bort gräns för ledighetens varaktighet",
  "error.leaveDurationLimit.isntUpdated": "Det gick inte att uppdatera gräns för ledighetens varaktighet",
  "error.leavePolicyMissing": "Ledighetspolicy saknas",
  "error.leaveRequestDifferentWorkingHours": "Du begär ledighet över dagar med olika arbetstider.",
  "error.leaveRequestNotEditable": "Du har inte behörighet att redigera denna ledighetsförfrågan.",
  "error.leaveRequestOverlap": "Datumen du har valt överlappar med en befintlig ledighet. Vänligen välj en annan tidsperiod.",
  "error.leaveRequestsDataError.title": "Det går inte att ladda data",
  "error.leaveRequestsLoadingError.description": "Dina ledigheter finns, men vi kunde inte ladda dem på grund av ett tekniskt problem på vår sida. Försök att ladda om sidan. Om problemet kvarstår, kontakta vår <link>Kundsupport</link>.",
  "error.leaveRequestsLoadingError.title": "Det går inte att ladda ledigheter",
  "error.leaveRequestStatusNotApproved": "Ledighetsförfrågans status: inte godkänd.",
  "error.leaveRequestStatusNotOpen": "Ledighetsförfrågans status: inte öppen",
  "error.leaveSubmitError": "Fel vid inlämning av ledighet",
  "error.leaveTypeMissing": "Ledighetstyp saknas",
  "error.leaveTypeOrPolicyInactive": "Vald ledighetstyp är inaktiv",
  "error.LocationLimitReached": "Vi beklagar, men du kan inte nedgradera till CORE-planen eftersom du för närvarande överskrider gränsen på {limitNumber} platser. Vänligen justera dina platser för att möta CORE-planens kriterier eller kontakta support för ytterligare vägledning.",
  "error.maxmimumUsersAway.isntCreated": "Konfiguration för maximalt antal frånvarande användare skapades inte",
  "error.maxmimumUsersAway.isntDeleted": "Konfiguration för maximalt antal frånvarande användare togs inte bort",
  "error.maxmimumUsersAway.isntUpdated": "Konfiguration för maximalt antal frånvarande användare uppdaterades inte",
  "error.microsoft.channelNotFound": "Du är inte medlem i det valda Microsoft Teams-teamet",
  "error.microsoft.logInWithDifferentAccount": "Logga in med ett annat konto",
  "error.microsoft.microsoftPermissionsNotGranted": "Microsoft-behörigheter har inte beviljats",
  "error.microsoft.notificationsLoginRequiredTitle": "Inloggning på ditt Microsoft-konto krävs",
  "error.microsoft.notMemberOfTeam": "Din organisation registrerade sig med ett enda Microsoft Teams-team, och du är inte medlem i teamet med följande ID: {msTeamId}.\n\nDu kan gå med i detta team i din Microsoft Teams-applikation och försöka igen, eller kontakta vår support för att ändra din installationstyp.",
  "error.microsoft.wrongAccount": "Fel Microsoft-konto",
  "error.minutesMustMatch": "Vänligen välj ett giltigt tidsintervall - minuterna måste matcha",
  "error.missingSlackBotToken": "Åtgärden har bearbetats, men vi saknar bot-token för att meddela användaren. Vänligen logga ut och logga in igen för att få bot-token.",
  "error.missingSlackBotTokenError": "Vi kan inte bearbeta förfrågan eftersom du saknar bot-token. Vänligen logga ut och logga in igen för att få bot-token, och försök sedan igen.",
  "error.mustBeApproverForTeam": "Du har inte behörighet att utföra denna åtgärd, eftersom du måste vara en Godkännare för denna avdelning för att göra det.",
  "error.MustBeApproverForTeam": "Du har inte behörighet att utföra denna åtgärd, eftersom du måste vara en Godkännare för denna avdelning för att göra det.",
  "error.mustBeCreatorOrApprover": "Måste vara skapare eller godkännare för att ändra status på ledighetsförfrågan.",
  "error.negativeBallanceNotAllowed": "Negativt saldo är inte tillåtet för denna ledighetstyp.",
  "error.notificationGeneral": "Notifieringsfel",
  "error.oneHoureIntervalNotAllowed": "Det kortaste tidsintervallet du kan begära för denna ledighetstyp är 4 timmar (halvdag).",
  "error.openApi.isntCreated": "Open API-nyckel skapades inte",
  "error.openApi.isntDeleted": "Open API-nyckel togs inte bort",
  "error.partTimeIsntAllowed": "Deltid är inte tillåtet",
  "error.passwordResetLimitExceededException": "Gräns överskriden",
  "error.passwordResetLimitExceededExceptionMessage": "Gränsen för försök att ändra lösenord har överskridits. Vänligen försök igen om 15 minuter.",
  "error.paymentFailed": "Betalningen misslyckades",
  "error.paymentNotProcessed": "Betalningen misslyckades, vänligen försök med ett annat kort.",
  "error.probationPeriod.isntCreated": "Provanställningsperioden skapades inte",
  "error.probationPeriod.isntDeleted": "Provanställningsperioden raderades inte",
  "error.probationPeriod.isntUpdated": "Provanställningsperioden uppdaterades inte",
  "error.PromoCodeDoesntExist": "Kampanjkoden {promoCode} existerar inte",
  "error.PromoCodeIsntValid": "Kampanjkoden {promoCode} är inte giltig",
  "error.reasonRequired": "Anledningsfältet är obligatoriskt.",
  "error.requestedMoreThanFullDay": "Du har begärt mer än en hel dag ledighet under en arbetsdag. Vänligen välj en annan dag.",
  "error.samePasswordException": "Lösenordet ändrades inte",
  "error.samePasswordExceptionMessage": "Det nya lösenordet är samma som det gamla. Vänligen ange ett annat lösenord om du vill ändra det.",
  "error.saveFailed": "Sparandet misslyckades",
  "error.seniorityEntitlement.isntCreated": "Anställningstidsbaserad förmån skapades inte",
  "error.seniorityEntitlement.isntDeleted": "Anställningstidsbaserad förmån raderades inte",
  "error.seniorityEntitlement.isntUpdated": "Anställningstidsbaserad förmån uppdaterades inte",
  "error.signin.inactiveUser": "Användaren är inaktiv",
  "error.signup.alreadySignedUp.descriptionLine1": "Du är redan registrerad med följande e-postadress: <text>{ email }</text>. Vänligen logga in.",
  "error.signup.alreadySignedUp.descriptionLine2": "Om du har glömt ditt lösenord, klicka på knappen nedan för att återställa det.",
  "error.signup.alreadySignedUp.title": "Du är redan registrerad",
  "error.signup.alreadySignedUpInactive.descriptionLine1": "Du är redan registrerad med följande e-postadress: <text>{ email }</text>.",
  "error.signup.alreadySignedUpInactive.descriptionLine2": "Dock är ditt konto inaktivt. Vänligen kontakta vår support för mer information och skicka dem följande info:",
  "error.slack.allowConversationWithSlackBotDescription": "Denna funktion kräver ytterligare behörighet från Slack som tillåter Vacation Tracker-boten för Slack att ta emot direktmeddelanden. Vår bot kommer att kunna läsa meddelanden som du och andra användare från din Slack Workspace skickar till den, men den kommer inte att ha behörighet att läsa dina kanaler, grupper eller direktmeddelanden.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine1": "Vacation Tracker-boten stöder ett slash-kommando och genväg för ledighetsförfrågningar. Utöver detta kan du aktivera konversation på mänskligt språk i Web Dashboard (och välja att använda LLM).",
  "error.slack.allowConversationWithSlackBotLlmWarningLine2": "Att aktivera detta kan ibland leda till att boten föreslår felaktiga datum eller typer av ledighet. Det är viktigt att dubbelkolla dess rekommendationer innan du gör en ledighetsförfrågan.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine3": "Varken Vacation Tracker eller dess tredje parter använder din information för att träna stora språkmodeller.",
  "error.slack.allowConversationWithSlackBotLlmWarningTitle": "Ansvarsfriskrivning för stor språkmodell (LLM)",
  "error.slack.allowConversationWithSlackBotTitle": "Tillåt konversation med Vacation Tracker-boten",
  "error.slack.logInWithDifferentAccount": "Logga in med ett annat konto",
  "error.slack.usersLoginRequiredTitle": "Inloggning på ditt Slack-konto krävs",
  "error.slack.wrongAccount": "Fel Slack-konto",
  "error.somethingWentWrong": "Åh nej! 😕 Något gick fel. ",
  "error.startDateCantBeInPast": "Startdatumet kan inte vara i det förflutna, vänligen välj ett datum i framtiden",
  "error.subscriptionExpired": "Hoppsan, det verkar som att ditt företags prenumeration på Vacation Tracker har gått ut! Vänligen kontakta en administratör för att återaktivera företagets prenumeration.",
  "error.subscriptionExpiredTitle": "Prenumerationen har gått ut",
  "error.team.delete": "Avdelningen kan inte raderas",
  "error.TeamLimitReached": "Ditt nuvarande antal avdelningar överstiger gränsen som tillåts i CORE-planen. För att nedgradera, minska antalet avdelningar till {limitNumber} eller färre, eller kontakta vår support för hjälp.",
  "error.token.button": "Logga in med {platform}",
  "error.token.description": "Din {platform}-token har gått ut. Vänligen klicka på knappen nedan för att få en ny token",
  "error.token.permissionDenied": "I Google-inloggningsprompten klickade du på Fortsätt utan att tillåta nödvändiga autentiseringsomfång. Vänligen klicka på Välj alla eller välj lämpliga behörigheter (Se, redigera, dela och permanent radera alla kalendrar du kan komma åt med Google Kalender och Se, redigera, skapa eller ändra dina e-postinställningar och filter i Gmail) och klicka sedan på Fortsätt.",
  "error.userEndDateInFuture": "De begärda datumen är efter användarens anställningsdatum. Vänligen kontakta din organisations administratör eller Vacation Tracker-support för mer information.",
  "error.UserNotAdminOrApprover": "Användaren är inte administratör eller godkännare",
  "error.userNotFound": "Användaren hittades inte",
  "error.UserNotFound": "Användare hittades inte",
  "error.UserNotFoundApprover": "Godkännare hittades inte",
  "error.userSignup.invitationAccepted.description": "Inbjudningskoden har redan accepterats, var god logga in",
  "error.userSignup.invitationAccepted.title": "Inbjudningskod redan accepterad",
  "error.userSignup.invitationExpired.description": "Inbjudningskoden har gått ut, be din administratör att skicka en ny inbjudan",
  "error.userSignup.invitationExpired.title": "Inbjudningskoden har gått ut",
  "error.userSignup.invitationNotFound.description": "Vänligen kontrollera att din inbjudningslänk är korrekt eller kontakta din administratör för att skicka en ny inbjudan.",
  "error.userSignup.invitationNotFound.title": "Inbjudan hittades inte",
  "error.userStartDateInFuture": "De begärda datumen är före användarens anställningsstartdatum. Kontakta din organisations administratör eller Vacation Tracker support för mer information.",
  "error.userUpdated": "Fel vid uppdatering av användare",
  "error.zeroDaysLeaveRequest": "Du har valt en helgdag eller en arbetsdag. Vänligen välj ett giltigt datumintervall.",
  "errors.microsoft.permissions.microsoftPermissionsNotGrantedDescription": "Det verkar som att du avbröt eller stängde sidan \"Logga in med Microsoft\" innan du beviljade de nödvändiga behörigheterna. Klicka på knappen nedan för att logga in med Microsoft och slutföra Vacation Tracker-installationen.\n\nOm du inte har behörighet att logga in med Microsoft, kontakta din organisations Microsoft 365-administratör.",
  "errors.microsoft.subscriptionExpiredDescription": "Det verkar som att din prenumeration har gått ut. Kontakta administratören för din Vacation Tracker-organisation för mer information.\n\nKontakta vår support om du behöver ändra administratören eller har andra frågor.",
  "errors.microsoft.wrongMicrosoftAccountDescription": "Det verkar som att du loggade in med ett annat Microsoft-konto. Du köpte licenser med följande e-postadress:\n\n{email}.\n\nLogga in med det konto du använde för att köpa licenserna för att slutföra installationen.",
  "errors.slack.wrongSlackAccountDescription": "Det verkar som att du loggade in med ett annat Slack-konto.",
  "events.logs.AUTOMATION": "Automatiseringar",
  "events.logs.BILLING": "Fakturering",
  "events.logs.BULK_ACTIONS": "Importera användare",
  "events.logs.COMPANY": "Företag",
  "events.logs.LABEL": "Etikett",
  "events.logs.LEAVE_POLICY": "Ledighetspolicy",
  "events.logs.LEAVE_TYPE": "Ledighetstyp",
  "events.logs.LOCATION": "Plats",
  "events.logs.LOCATION_YEAR_ROLLOVER": "Plats årsövergång",
  "events.logs.NOTIFICATION": "Notifieringar",
  "events.logs.REPORT": "Rapport",
  "events.logs.TEAM": "Avdelning",
  "events.logs.TEAM_POLICY": "Teampolicy",
  "export.approved": "Godkänd",
  "export.currentYear": "Innevarande år",
  "export.denied": "Nekad",
  "export.downloadAllRows": "Ladda ner alla {total} rader i rapporten som:",
  "export.expired": "Utgången",
  "export.export": "Exportera",
  "export.exportPeriod": "Exportperiod",
  "export.fileType": "Exportformat",
  "export.fileTypeCsv": "CSV",
  "export.fileTypeExcelXlsx": "Excel",
  "export.labels": "Etiketter",
  "export.lastMonth": "Förra månaden",
  "export.leaveBalanceMonthlyReport": "Månadsrapport för ledighetsbalans",
  "export.leaveBalanceReport": "Rapport för ledighetsbalans",
  "export.leaveBalanceReportCustomDaysInfo": "Observera att rapporten endast kommer att visa använda dagar eller timmar om du väljer en anpassad period. Du kommer inte att kunna exportera den totala eller tillgängliga kvoten om du inte väljer ett av de fördefinierade alternativen.",
  "export.leaveBalanceReportDescription": "Mängden berättigad och tagen ledighet per ledighetstyp för varje anställd under en specifik tidsperiod.",
  "export.leaveHistoryReport": "Rapport för ledighetsansökningar",
  "export.leaveHistoryReportDescription": "Historik över alla ledighetsansökningar (godkända, nekade, utgångna) per ledighetstyp för varje anställd under en specifik tidsperiod.",
  "export.locations": "Platser",
  "export.monthlyLeaveBalanceReport": "Månadsrapport för ledighetsbalans",
  "export.monthlyLeaveBalanceReportDescription": "Mängden tagen (eller planerad) ledighet per ledighetstyp för varje anställd för den aktuella och de följande 11 månaderna.",
  "export.nextYear": "Nästa år",
  "export.previousYear": "Föregående år",
  "export.rangeDate": "Start- och slutdatum",
  "export.reportType": "Rapporttyp",
  "export.required": "Detta fält är obligatoriskt.",
  "export.status": "Status",
  "export.teams": "Avdelningar",
  "externalConnect.faildLogin": "Din engångstoken har löpt ut eller har redan använts. Vi kommer att omdirigera dig till målsidan om några sekunder, men du kan behöva logga in igen.\n\nOm du har bråttom, klicka på knappen nedan.",
  "externalConnect.parseError": "Oj då! 😕 Något gick fel.\n\nDu kommer att omdirigeras till inloggningssidan om 10 sekunder.",
  "externalConnect.pleaseWait": "Vänligen vänta, din dator är ingen superman!",
  "form.confirmPasswordInfo": "Vänligen bekräfta ditt lösenord!",
  "form.department.approverDescription": "Tilldela godkännare för denna avdelning. Godkännare kommer att ansvara för att hantera ledighetsansökningar som skickas in av avdelningens medlemmar. En godkännare kan tillhöra en annan avdelning, och en enskild avdelning kan ha flera godkännare. Godkännandeflödet kan konfigureras som: Ennivå, där godkännande från endast en person behövs, eller Flernivå (tillgängligt i Complete-planen), med sekventiella godkännanden. På varje nivå skickas meddelanden till alla godkännare på den nivån. Godkännande av vilken godkännare som helst flyttar ansökan till nästa nivå, och processen fortsätter tills den är helt godkänd",
  "form.department.approvers": "Godkännare",
  "form.department.approversFirstLevel": "Godkännare på första nivån",
  "form.department.approversSecondLevel": "Godkännare på andra nivån",
  "form.department.approverTitle": "Godkännandeflöde",
  "form.department.apprverFlowType": "Typ av godkännandeflöde",
  "form.department.apprverFlowType.multiLevel": "Flernivågodkännande",
  "form.department.apprverFlowType.singleLevel": "Ennivågodkännande",
  "form.department.generalDescriptionCreate": "Ange namnet på din avdelning och välj dess användare och godkännare. Om du vill konfigurera avdelningen innan du lägger till användare kan du skapa avdelningen först och lägga till användare senare, men att lägga till minst en godkännare krävs.",
  "form.department.generalDescriptionEdit": "Ange namnet på din avdelning och välj dess medlemmar. Vid behov kan du konfigurera avdelningen först och lägga till användare senare.",
  "form.department.generalTitle": "Avdelningsinställningar",
  "form.department.setDefaultDescription": "Standardavdelning",
  "form.department.setDefaultTitle": "Standardavdelning",
  "form.department.subdepartmentDescription": "Du kan nästla avdelningar för att skapa en organisationsträdsliknande struktur. Om detta är en underavdelning kan du ställa in dess överordnade avdelning i detta avsnitt.",
  "form.department.subdepartmentTitle": "Inställningar för underavdelning",
  "form.general.companySettingsDescription": "Ange namnet på ditt företag och den primära kontaktadressen. E-postadressen du anger kommer endast att användas för viktig produktkommunikation. Inget spam, vi lovar.",
  "form.general.companySettingsTitle": "Företagsinställningar",
  "form.general.configurationDescription": "Detta avsnitt låter dig finjustera specifika aspekter av ledighetshantering för ditt företag. Aktivera slutdatum för användare för att automatiskt avaktivera anställda med ett slutdatum, vilket förhindrar dem från att skicka in ledighetsansökningar efter deras avgång. Välj att spåra ledighetskvoten i dagar eller timmar, enligt företagets policyer. Definiera dessutom startåret för ledighetshantering. Som standard börjar ledighetsberäkningar från året du registrerade dig, men du kan justera detta för att inkludera historiska data från andra system eller kalkylblad.",
  "form.general.configurationTitle": "Konfiguration",
  "form.general.migrationDescription": "Använder du Slack, Microsoft Teams eller Google Workspace? Du kan byta från e-post- och lösenordsbaserad autentisering till en plattformsspecifik integration. Att byta till en av våra integrationer skulle förenkla inloggnings- och användarinbjudningsprocessen (importera användare från din användarkatalog) och ge dig ytterligare integrationer, såsom Microsoft Teams-flikar och Microsoft Teams- eller Slack-bottar.",
  "form.general.migrationFeatureDescription": "Detta kommer att starta migreringsprocessen. Du kommer att omdirigeras till en plattform där du kan ansluta ditt konto.",
  "form.general.migrationFeatureTitle": "Starta migrering till en annan plattform",
  "form.general.migrationTitle": "Byt till en annan plattform",
  "form.inputRequired": "Detta fält är obligatoriskt",
  "form.inputSize": "Fältet måste ha {len} siffror",
  "form.nameRequired": "Namnet är obligatoriskt",
  "form.passwordDoNotMatch": "De två lösenorden du angav matchar inte!",
  "form.userNameRequired": "Vänligen ange ditt namn",
  "form.validEmailRequired": "Vänligen ange en giltig e-postadress.",
  "general.announceNewUsers": "Meddela nya användare",
  "general.announceNewUsersInfo": "Skicka meddelande om Vacation Tracker till nya Slack/Teams/Google Workspaces-användare",
  "general.announceNewUsersInfoTooltip": "När denna ruta är ikryssad kommer nya användare som läggs till i din Slack/Teams/GW-organisation att få ett kort meddelande från Vacation Tracker som förklarar hur det fungerar. Om rutan inte är ikryssad kommer nya användare inte att få något meddelande från Vacation Tracker.",
  "general.announceNewUsersTooltip": "Varje gång du lägger till en ny användare i din {platform} kommer de att få ett kort meddelande från Vacation Tracker som förklarar hur det fungerar. Om du väljer alternativet 'Skicka inte' kommer nya användare inte att få något meddelande från Vacation Tracker.",
  "general.calculationStartYear": "Vacation Tracker spårar dina ledigheter från",
  "general.calculationStartYearTooltipLine1": "Du behöver bara ändra denna inställning om du vill importera historiska ledigheter från ett annat system eller kalkylblad och inkludera dessa ledigheter i Vacation Trackers beräkningar.",
  "general.calculationStartYearTooltipLine2": "Vacation Tracker beräknar ledigheter från det år du registrerade dig. Det betyder att vi automatiskt kommer att beräkna återstående och överförda dagar enligt dina inställningar, och du kommer att kunna ställa in överförda dagar från ditt tidigare system för varje användare på deras profilsidor. Om du vill att Vacation Tracker ska beräkna ledigheter för tidigare år kan du ändra detta datum. Tveka inte att <support>kontakta vår support</support> för mer information.",
  "general.clickToSelectPlatform": "Klicka för att välja plattform",
  "general.companyId": "Företags-ID:",
  "general.companyName": "Företagsnamn",
  "general.confirmModalTitle": "Bekräfta uppdateringar av företagsinställningar",
  "general.connectSync": "Anslut synkronisering",
  "general.connectToAnotherPlatform": "Byt till en annan plattform",
  "general.connectWith": "Byt till {platform}",
  "general.connectWithHelpdeskInfo": "Osäker på vilken plattform du ska välja? Denna <link>artikel</link> kan hjälpa.",
  "general.days": "Dagar",
  "general.deleteAccount": "Om du vill ändra eller radera dina uppgifter, vänligen kontakta vårt supportteam på <mail>hello@vacationtracker.io</mail>",
  "general.enableUserEndDate": "Aktivera slutdatum för användare",
  "general.enableUserEndDateInfo": "När aktiverad kommer användare med ett slutdatum inte att kunna skicka in ledighetsförfrågningar efter det datumet, och vi kommer automatiskt att avaktivera dem.",
  "general.enableUserEndDateTooltip": "När användarens slutdatum är aktiverat kommer användare inte att kunna skicka in ledighetsförfrågningar för tidsperioden efter deras slutdatum. De kommer automatiskt att avaktiveras och inte kunna logga in på Vacation Tracker.",
  "general.googleConsent": "Google-inloggning",
  "general.googleConsentInfo": "Google-medgivande",
  "general.googleMorePermission": "Google zeza sa imenima ako hocete da budete sigurni da imate puno ime i prezime treba nam jos permission-a, kliknite ovde da nam date jos permission-a",
  "general.hourlyLeaveAccounting": "Ställ in ledighetskvoter i",
  "general.hourlyLeaveAccountingTooltip": "När aktiverad spårar detta alternativ varaktigheten av alla ledigheter och kvoter i timmar. Endast tillgängligt för Complete-planen.",
  "general.hours": "Timmar",
  "general.importUsersAutomatically": "Importera användare automatiskt",
  "general.importUsersAutomaticallyInfo": "Med automatisk användarimport aktiverad kommer vi automatiskt att importera och aktivera alla nya vanliga användare från {platform}.",
  "general.importUsersAutomaticallyInfoTooltip": "Till exempel, när du bjuder in en ny användare till din arbetsyta kommer den användaren att vara tillgänglig i Vacation Tracker några minuter efter inbjudan. Om du vill aktivera en flerkanalsgästanvändare måste du bjuda in dem manuellt.",
  "general.importUsersAutomaticallyTurnOffInfo": "Vi kommer inte längre automatiskt importera och aktivera alla nya vanliga användare från {platform}.",
  "general.importUsersConfigSection": "Konfiguration för användarimport",
  "general.importUsersConfigSectionDescription": "Detta avsnitt låter dig aktivera automatisk användarimport från din användarkatalog och konfigurera importinställningar, såsom meddelandetext, standardavdelning och standardplats för nya användare.",
  "general.pleaseEnterCompanyName": "Vänligen ange företagsnamnet",
  "general.pleaseEnterContactEmail": "Vänligen ange företagets e-postadress",
  "general.switchTenant": "Byt {platform}-tenant",
  "general.syncGoogleUsers": "Synkronisera Google-användare",
  "general.syncGoogleUsersInfo": "Synkronisera Google-användare automatiskt radera, uppdatera och lägga till användare",
  "general.updateInProgress": "Uppdaterar företagsinställningar",
  "general.updateSuccessTitle": "Företagsinställningar uppdaterade",
  "general.usefulLinks": "<healthcheck>Hälsokontroll</healthcheck>, <helpdesk>Kundtjänst</helpdesk>",
  "general.usefulLinksTitle": "Användbara länkar: ",
  "holidays.filterByType": "Filtrera efter helgdagstyp",
  "holidays.holidaysName": "Helgdagsnamn",
  "holidays.holidaysTab": "Helgdagar för {year}",
  "holidays.holidaysType": "Helgdagstyp",
  "holidays.importHolidays": "Importera helgdagar",
  "holidays.importHolidaysAutomaticallyQuestion": "Vill du importera valda helgdagar för kommande år automatiskt?",
  "holidays.importHolidaysAutomaticallyTooltip": "Vacation Tracker kommer automatiskt att justera dagar för allmänna helgdagar som inte infaller på samma datum varje år, såsom Thanksgiving, påsk etc.",
  "holidays.importHolidaysAutomaticallyTooltipForMissingHolidays": "Tyvärr stöds inte denna funktion för närvarande för detta land. Vi arbetar på att utöka våra tjänster, så håll utkik efter uppdateringar.",
  "holidays.importHolidaysAutomaticallyTurnedOff": "Automatisk import av helgdagar avstängd",
  "holidays.importHolidaysAutomaticallyTurnedOn": "Automatisk import av helgdagar påslagen",
  "holidays.importHolidaysCount": "Importera {count} valda helgdagar",
  "holidays.importHolidaysFor": "Importera helgdagar för <b>{year}</b> för <b>{countryState}</b>",
  "holidays.importHolidaysSummary": "Du valde följande helgdagar för <b>{year}</b> för <b>{countryState}</b>",
  "holidays.pastHolidays": "Tidigare helgdagar",
  "holidays.totalSelectedHolidays": "Du valde {total} helgdag(ar)",
  "holidays.totalSelectedHolidaysTip": "({total} inte synliga på grund av det tillämpade filtret)",
  "holidays.typeBank": "Bank",
  "holidays.typeObservance": "Högtid",
  "holidays.typeOptional": "Valfri",
  "holidays.typePublic": "Allmän",
  "holidays.typeSchool": "Skola",
  "importLeaves.importFail": "Importen misslyckades med följande felmeddelande:",
  "importLeaves.importSuccess": "Du har skickat in data för import. Kontrollera om några minuter för att se om datan har importerats framgångsrikt.",
  "importUsers.allUsersImported.description": "Det verkar som att du redan har importerat alla användare från din katalog! Du bör se alla användare på sidan Användare. Om du inte ser någon, ta en titt på fliken Inaktiva. Kontakta gärna vår support om du har några frågor eller behöver hjälp.",
  "importUsers.allUsersImported.goToUsersPageButton": "Gå till sidan Användare",
  "importUsers.allUsersImported.title": "Alla användare redan importerade!",
  "importUsers.form.backButton": "Tillbaka till sidan Användare",
  "importUsers.form.searchGoogleUsersPlaceholder": "Sök användare i din Google Workspace",
  "importUsers.form.searchMicrosoftUsersPlaceholder": "Skriv minst ett tecken för att börja söka efter användare i din Microsoft 365 Active Directory",
  "importUsers.form.searchSlackUsersPlaceholder": "Sök användare i din Slack Workspace",
  "importUsers.form.searchUsersNotFound": "Användare hittades inte eller har redan importerats",
  "importUsers.form.searchUsersPlaceholder": "Sök användare i din Microsoft 365 Active Directory",
  "importUsers.form.selectedUsersLabel": "Valda användare ({selectedUsers})",
  "importUsers.form.showSelectedUsersAsNumber": "Valda användare",
  "importUsers.statistics.imported": "Användare importerade till Vacation Tracker",
  "importUsers.statistics.total": "Totalt antal användare i din {directoryName}",
  "leaveRequest.halfDay": "Halv dag",
  "leaveRequest.hourly": "Per timme",
  "leaveRequest.sent": "Ledighetsansökan skickad",
  "leaveRequest.simpleQuota.addAnotherLeave": "Lägg till en ny ledighet",
  "leaveRequest.simpleQuota.info": "Här är ditt ledighetssaldo för {leaveTypeName} för {start} - {end}.",
  "leaveRequest.simpleQuota.requestAnotherLeave": "Begär en ny ledighet",
  "leaveRequest.simpleQuota.summaryInfo": "Vi skickade följande förfrågan för godkännande till din chef",
  "leaveRequests.addedCurrentYear": "Tillagt för innevarande år",
  "leaveRequests.addeddNextYear": "Tillagt för nästa år",
  "leaveRequests.cancelThisAction": "Avbryt denna åtgärd",
  "leaveRequests.dates": "Datum{plural}",
  "leaveRequests.daysRemainingForCurrentYear": "Återstående dagar för innevarande år",
  "leaveRequests.daysRemainingForNextYear": "Återstående dagar för nästa år",
  "leaveRequests.daysRequestedForCurrentYear": "Begärda dagar för innevarande år",
  "leaveRequests.daysRequestedForNextYear": "Begärda dagar för nästa år",
  "leaveRequests.denyWithReason": "Neka med anledning",
  "leaveRequests.hoursRemainingForCurrentYear": "Återstående timmar för innevarande år",
  "leaveRequests.hoursRemainingForNextYear": "Återstående timmar för nästa år",
  "leaveRequests.hoursRequestedForCurrentYear": "Begärda timmar för innevarande år",
  "leaveRequests.hoursRequestedForNextYear": "Begärda timmar för nästa år",
  "leaveRequests.leaveRequestInfoTitle": "<strong>{name}</strong> har skickat in följande ledighetsansökan:",
  "leaveRequests.leaveRequestInfoTitleToil": "<strong>{name}</strong> har skickat in följande TOIL-begäran:",
  "leaveRequests.leaveTypeName": "Ledighetstyp",
  "leaveRequests.pending": "Väntande begäran",
  "leaveRequests.reason": "Anledning",
  "leaveRequests.reasonForDenying": "Anledning till nekande",
  "leaveRequests.remainingCurrentYear": "Återstående för innevarande år",
  "leaveRequests.remainingNextYear": "Återstående för nästa år",
  "leaveRequests.requestedCurrentYear": "Begärt för innevarande år",
  "leaveRequests.requestedNextYear": "Begärt för nästa år",
  "leaveRequests.requestSummary": "Sammanfattning av begäran",
  "leaveRequests.wrongAction": "Denna åtgärd är okänd för oss...🤔 Vänligen försök med en annan! Du kommer att omdirigeras till Dashboarden om 7 sekunder.",
  "leaveRequests.wrongLeaveRequestId": "Hoppsan! Denna ledighetsansökan kunde inte hittas! 🔎 Du kommer att omdirigeras till Dashboarden om 7 sekunder.",
  "leaveRequestStatus.approved": "godkänd",
  "leaveRequestStatus.APPROVED": "godkänd",
  "leaveRequestStatus.cancelled": "avbruten",
  "leaveRequestStatus.CANCELLED": "avbruten",
  "leaveRequestStatus.deleted": "borttagen",
  "leaveRequestStatus.DELETED": "borttagen",
  "leaveRequestStatus.denied": "nekad",
  "leaveRequestStatus.DENIED": "nekad",
  "leaveRequestStatus.open": "öppen",
  "leaveRequestStatus.OPEN": "öppen",
  "leaveTypes.activatingLeaveType": "Aktivera ledighetstyp",
  "leaveTypes.activatingLeaveTypeInfo": "Genom att aktivera denna ledighetstyp återställer du dess tidigare placering i Platser, samt dess tidigare inställningar och data.",
  "leaveTypes.activatingLeaveTypeTitle": "Aktivera ledighetstyp",
  "leaveTypes.addLeaveTypeInfo": "Skapa en anpassad ledighetstyp genom att lägga till ett namn, en färg för enklare navigering och status (Aktiv/Inaktiv). Oroa dig inte, du kan redigera alla inställningar för ledighetstypen senare. \n\nFör att tilldela en anpassad ledighetspolicy till en ledighetstyp, gå till Platser -> Tilldela ledighetspolicy.",
  "leaveTypes.addTypeToLocationButton": "Lägg till",
  "leaveTypes.assignedToLocationsColumn": "Tilldelad till platser",
  "leaveTypes.assignedToLocationsColumnWarning": "Denna ledighetstyp är inte tilldelad till någon plats.",
  "leaveTypes.color": "Färg",
  "leaveTypes.createInProgress": "Skapar ledighetstypen {leaveTypeName}",
  "leaveTypes.createNewLeaveType": "Skapa en ledighetstyp",
  "leaveTypes.deactivateLeaveType": "Inaktivera ledighetstyp",
  "leaveTypes.deactivateLeaveTypeInfo": "Genom att inaktivera denna ledighetstyp tar du bort den från alla platser som för närvarande använder den. När du aktiverar ledighetstypen igen kommer den att visas på samma platser med befintliga inställningar och data.",
  "leaveTypes.deactivateLeaveTypeTitle": "Inaktivera ledighetstyp",
  "leaveTypes.deleteInProgress": "Tar bort ledighetstypen {leaveTypeName}",
  "leaveTypes.deleteLeaveType": "Ta bort ledighetstyp",
  "leaveTypes.deleteLeaveTypeConfirm": "Är du säker på att du vill ta bort ledighetstypen {name}?",
  "leaveTypes.deleteLeaveTypeTitle": "Ta bort ledighetstyp",
  "leaveTypes.editLeaveType": "Redigera ledighetstyp",
  "leaveTypes.isActive": "Aktiv",
  "leaveTypes.sort": "Sortera",
  "leaveTypes.typeName": "Namn",
  "leaveTypes.updateInProgress": "Uppdaterar ledighetstypen {leaveTypeName}",
  "location.createInProgress": "Skapar platsen {locationName}",
  "location.deleteInProgress": "Tar bort platsen {locationName}",
  "location.editLocation": "Redigera plats",
  "location.fiscalYearStart": "Räkenskapsårets början",
  "location.general": "Allmänt",
  "location.hasNoUsers": "Platsen har inga användare.",
  "location.holidays": "Helgdagar",
  "location.holidays.autoimportTitle": "Inställningar för automatisk import av helgdagar har ändrats",
  "location.holidays.brokenHolidays": "Denna helgdag kan inte läggas till, vänligen kontakta support för att lösa problemet",
  "location.holidays.successTitle": "Helgdagar har uppdaterats framgångsrikt",
  "location.holidays.updateInProgress": "Uppdaterar helgdagar",
  "location.holidaysUpdated": "Helgdagar uppdaterade",
  "location.leavePolicies": "Ledighetspolicyer",
  "location.leavePoliciesNotExistInfo": "Det finns för närvarande inga tillgängliga ledighetspolicyer. Vänligen tilldela en ledighetspolice till platsen.",
  "location.name": "Namn",
  "location.never": "Aldrig",
  "location.resetQuotas": "Återställning av ledighetskvot baserad på",
  "location.resetQuotasInfo": "Denna inställning avgör om din årliga ledighetsbalans återställs baserat på räkenskapsåret (företagets räkenskapsår) eller baserat på anställningsdatumet. Förutom kvoter kommer även din överföringspolicy att påverkas enligt denna inställning.",
  "location.rolloverExpiryAfterDays": "Utgång för överförda dagar",
  "location.rolloverExpiryAfterHours": "Utgång för överförda timmar",
  "location.rolloverExpiryDay": "Ska överförda dagar förfalla?",
  "location.rolloverExpiryHour": "Ska överförda timmar förfalla?",
  "location.rolloverExpiryOnDate": "Överförda dagar förfaller den",
  "location.rolloverExpiryOnDate.extra": "Dagar förfaller i slutet av dagen.",
  "location.rolloverExpiryOnDateHours": "Överförda timmar förfaller den",
  "location.rolloverExpiryOnDateHours.extra": "Timmar förfaller i slutet av dagen.",
  "location.startYear": "Räkenskapsårets början",
  "location.startYearInfo": "En organisations räkenskapsår är ett år som räknas för skatte- eller bokföringsändamål. Återställningsdatum för semesterperioder beror på organisationens räkenskapsårs början och slut.",
  "location.timezone": "Tidszon",
  "location.timezoneDefault": "(GMT+00:00) GMT (ingen sommartid)",
  "location.updateInProgress": "Uppdaterar platsen {locationName}",
  "location.users": "Användare",
  "location.workWeek": "Arbetsvecka",
  "locationForm.assignLeaveTypesInfo": "När du har skapat en plats, tilldela en ledighetspolice till platsen för att möjliggöra för användare att begära ledighet. \nFör att tilldela en ledighetspolice, gå till Plats -> Ledighetspolicyer -> Tilldela ledighetspolice.",
  "locationForm.createdSuccessfully": "Vänligen ge oss en stund, vi skapar din plats.",
  "locationForm.createLocation": "Skapa plats",
  "locationForm.editLocation": "Redigera plats",
  "locationForm.locationLimitReached": "Hoppsan! Det verkar som att du har nått gränsen på {limitNumber} platser för din nuvarande plan. För att hantera eller uppgradera din plan, besök <billingPage>faktureringssidan</billingPage>.",
  "locationForm.updateLocation": "Uppdatera plats",
  "locations.automaticallyGeneratedName": "Vi genererade automatiskt denna plats för dig genom att gruppera följande team: <t>{team}</t>.",
  "locations.automaticallyTransferredLeavePoliciesAndHolidays": "Ledighetspolicyer och helgdagar överförs automatiskt för dig, så du behöver <b>{not}</b> återskapa dem.",
  "locations.created": "Skapad",
  "locations.createNewLocation": "Skapa en plats",
  "locations.default": "Standard",
  "locations.defaultLocation": "Standardplats",
  "locations.defaultLocationDescription": "Alla nya användare kommer automatiskt att läggas till på denna plats.",
  "locations.deleteLocation": "Ta bort plats",
  "locations.deleteLocation.disabled": "Du kan inte ta bort standardplatsen",
  "locations.deleteLocationConfirm": "Vänligen bekräfta att du vill ta bort platsen {name}.",
  "locations.deleteLocationConfirmInitialRollover": "Att ta bort en plats kan påverka användarnas konfiguration och ledighetsinformation (t.ex. de initiala överförda dagarna).",
  "locations.deleteLocationTitle": "Ta bort plats {name}",
  "locations.editLocation": "Redigera",
  "locations.hasNoUsers": "Inga",
  "locations.holidays": "Helgdagar",
  "locations.leavePolicies": "Ledighetspolicyer",
  "locations.leavePolicies.quotaDays": "{value} dagar",
  "locations.leavePolicies.quotaHours": "{value} timmar",
  "locations.leavePolicies.yearlyQuota": "Årlig ledighetskvot",
  "locations.locationLimitReached": "Du använder för närvarande alla {limitNumber} av dina tillgängliga platser i CORE-planen. För att hantera eller uppgradera din plan, besök <billingPage>Faktureringssidan</billingPage>.",
  "locations.name": "Namn",
  "locations.pleaseRenameLocation": "Vänligen byt namn på denna plats!",
  "locations.pleaseRenameLocationName": "Vänligen byt namn på automatiskt genererade platsnamn *",
  "locations.resetQuotas": "Återställning av ledighetskvot baserad på",
  "locations.selectLocation": "Välj plats",
  "locations.unableToNameThisLocation": "Vårt system kunde dock inte namnge denna plats korrekt.",
  "locations.users": "Användare",
  "locations.viewHolidays": "Visa helgdagar",
  "locations.viewLeavePolicies": "Visa ledighetspolicyer",
  "locations.viewLocation": "Visa",
  "locations.weAutomaticallyCreatedLocations": "Vi har automatiskt skapat platser för din organisation genom att gruppera dina team efter helgdagar. Alla ledighetspolicyer har överförts, du behöver bara byta namn på dina platser.",
  "manageMicrosoftLicenses.assignLicencesSuccessTitle": "Licenserna har tilldelats framgångsrikt.",
  "manageMicrosoftLicenses.weAreAssignLicenses": "Vi tilldelar licenserna.",
  "microsoft.adminConsentErrorButton": "Få åtkomst",
  "microsoft.adminConsentErrorDescription1": "Det verkar som att du inte är administratör för din Microsoft Teams-organisation. Att ställa in aviseringar kräver administratörsgodkännande. Vänligen skicka följande länk till administratören för din organisation för att få administratörsgodkännande: ",
  "microsoft.adminConsentErrorDescription2": "När du får ett administratörsgodkännande, klicka på knappen nedan för att få åtkomst till aviseringar. Om du är en administratör, besök samma länk, ge administratörsgodkännande, och du kommer att kunna ställa in aviseringarna.",
  "microsoft.adminConsentErrorTitle": "Microsoft Teams administratörsgodkännande krävs.",
  "microsoft.createCompanyTeamLoginAdminConsentError1": "Det verkar som att du inte är administratör för din Microsoft Teams-organisation. Att importera teammedlemmar från ett enskilt Microsoft Teams-team kräver administratörsgodkännande. Vänligen skicka följande länk till administratören för din organisation för att få administratörsgodkännande: ",
  "microsoft.createCompanyTeamLoginAdminConsentError2": "När du får administratörsgodkännande kan du fortsätta med registreringsprocessen. Om du är administratör, besök samma länk, ge administratörsgodkännande, och du kommer att kunna fortsätta med registreringen.",
  "microsoft.createCompanyTeamLoginRequiredDescription1": "Vi behöver ytterligare Microsoft Teams-behörighet för att lista användare från teamet du valde. Om du klickar på OK-knappen kommer vi att omdirigera dig till Microsoft-inloggningssidan. Beroende på dina webbläsarinställningar kan du redan vara inloggad och automatiskt omdirigerad tillbaka.",
  "microsoft.createCompanyTeamLoginRequiredDescription2": "Efter inloggning på ditt Microsoft-konto tar vi dig tillbaka till denna sida så att du kan välja användare att importera. Att klicka på Avbryt-knappen kommer fortfarande att låta dig registrera dig med hela din organisation.",
  "microsoft.createCompanyTeamLoginRequiredTitle": "Ytterligare Microsoft Teams-behörighet krävs.",
  "microsoft.manageLicenses.loginMicrosoft": "Logga in med ditt Microsoft 365-konto för att tilldela licenser.",
  "microsoft.manageLicenses.loginMicrosoftInfo": "Genom att logga in med ditt konto kommer användare i din Microsoft Active Directory att listas. Du kommer att kunna tilldela köpta licenser och valfritt meddela importerade användare.",
  "microsoft.notificationsLoginRequiredDescription1": "Hantering av aviseringar kräver en Microsoft Teams-token. Om du klickar på OK-knappen kommer vi att omdirigera dig till Microsoft-inloggningssidan. Beroende på dina webbläsarinställningar kan du redan vara inloggad och automatiskt omdirigerad tillbaka.",
  "microsoft.notificationsLoginRequiredDescription2": "Efter inloggning på ditt Microsoft-konto tar vi dig tillbaka till denna sida så att du kan hantera aviseringarna. Att klicka på Avbryt-knappen tar dig tillbaka till den senaste sidan du besökte på vår instrumentpanel.",
  "microsoft.notificationsLoginRequiredTitle": "Inloggning på ditt Microsoft-konto krävs",
  "microsoft.universalLoginRequiredDescription": "Det verkar som att din token har gått ut. Vänligen logga in på ditt Microsoft Teams-konto för att fortsätta.",
  "microsoft.universalLoginRequiredTitle": "Token har gått ut",
  "microsoft.usersLoginRequiredDescription1": "Hantering av användare kräver en Microsoft Teams-token. Om du klickar på OK-knappen kommer vi att omdirigera dig till Microsoft-inloggningssidan. Beroende på dina webbläsarinställningar kan du redan vara inloggad och automatiskt omdirigerad tillbaka.",
  "microsoft.usersLoginRequiredDescription2": "Efter att du har loggat in på ditt Microsoft-konto tar vi dig tillbaka till den här sidan så att du kan hantera användarna.",
  "microsoft.usersLoginRequiredTitle": "Inloggning på ditt Microsoft-konto krävs",
  "microsoftSass.createCompany.alertError.companyAlreadyExistsDescription": "Ditt företag är redan registrerat, men du är inte aktiv. Använder du rätt e-postadress?\nDin e-postadress: {email}\nOm denna e-postadress är korrekt är ditt konto inte aktiverat. Vänligen kontakta administratören för din Vacation Tracker-organisation:\n{admins}\nEller kontakta vår support om du vill registrera dig som ett separat oberoende team i samma tenant.\nOm din e-postadress inte är korrekt, vänligen logga in med ett annat Microsoft-konto eller logga in från ett inkognitofönster i din webbläsare.",
  "microsoftSass.createCompany.alertError.errorTitle": "Fel",
  "microsoftSass.createCompany.alertError.goToAdminCenter": "Gå till Administratörscenter",
  "microsoftSass.createCompany.alertError.goToConnectPage": "Gå till Anslutningssidan",
  "microsoftSass.createCompany.alertError.logInWithPlatform": "Logga in med {platform}",
  "microsoftSass.createCompany.alertError.resubscribedDescription": "Du har framgångsrikt återprenumererat som företag - {companyName}.\n\nVänligen klicka på knappen för att omdirigeras till inloggningssidan.",
  "microsoftSass.createCompany.alertError.resubscribedTitle": "Du har återprenumererat framgångsrikt",
  "microsoftSass.createCompany.alertError.tokenExpiredDescription": "Din köptoken har gått ut. Vänligen gå till Microsoft Teams Administratörscenter och klicka på knappen \"Konfigurera konto\" för att fortsätta.",
  "microsoftSass.createCompany.alertError.tokenExpiredTitle": "Köptoken har gått ut",
  "microsoftSass.createCompany.alertError.tokenInvalidDescription": "Din Microsoft-köptoken är ogiltig. Om du köpte Vacation Tracker-planen via Microsoft Teams App Store, vänligen gå till Microsoft Teams Administratörscenter och klicka på knappen \"Konfigurera konto\" för att fortsätta. Annars, gå till vår anslutningssida för att logga in eller skapa ett konto.",
  "microsoftSass.createCompany.alertError.tokenInvalidTitle": "Ogiltig köptoken",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountDescription": "Du har redan ett konto. Ditt företag ({companyName}) registrerade sig med {platform}. Klicka på knappen nedan för att logga in med ditt {platform}-konto.\n\nOroa dig inte. Vi har avbrutit ditt köp och du kommer inte att debiteras för licenserna du just köpte. Den gamla prenumerationen för ditt företag är fortfarande aktiv. Du kan besöka Microsoft Administratörscenter för att verifiera att din nya prenumeration är avbruten.\n\nOm du vill köpa en annan prenumeration avsiktligt, vänligen kontakta vår support.",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountTitle": "Du har redan ett konto",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicenses": "TILLDELA KÖPTA LICENSER",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicensesInfo": "Låt oss tilldela licenserna du köpte! Du har {totalLicenses} licenser. Tilldela dem till användare som kommer att kunna använda Vacation Tracker.",
  "microsoftSass.createCompany.assignLicenses.buyMoreLicensesLater": "Du kan köpa fler licenser senare på Användarsidan i Vacation Tracker-dashboarden.",
  "microsoftSass.createCompany.assignLicenses.loginMicrosoftDontWorry": "Oroa dig inte! Vi kommer inte att importera eller meddela någon utan ditt godkännande.",
  "microsoftSass.createCompany.completed": "Slutfört",
  "microsoftSass.createCompany.createCompanySetupInfo": "Låt oss ställa in kontakt-e-postadressen och standardinställningarna för din Vacation Tracker-organisation.",
  "microsoftSass.createCompany.createCompanyTitle": "SLUTFÖR FÖRETAGSINSTÄLLNINGAR",
  "microsoftSass.createCompany.finishFristStep": "Vänligen slutför företagsinställningarna för att fortsätta.",
  "microsoftSass.createCompany.finishSecondStep": "Vänligen tilldela licenser för att fortsätta.",
  "microsoftSass.createCompany.finishSetup": "Slutför inställningar",
  "microsoftSass.createCompany.help": "Om du behöver hjälp, besök vår <helpdesk>Helpdesk</helpdesk> eller <support>kontakta vår support</support>.",
  "microsoftSass.createCompany.importAllUsers": "Tilldelad till alla användare",
  "microsoftSass.createCompany.letsCompleteSetup": "Låt oss slutföra inställningarna, tilldela licenser till dina användare och installera Vacation Tracker-integrationen för Microsoft Teams.",
  "microsoftSass.createCompany.numberOfLicensesAssigned": "{assignedLicenses} licenser tilldelade",
  "microsoftSass.createCompany.setupMsBot.areYouAdmin": "Är du administratör för din Microsoft Teams-integration",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLogin": "Det verkar som att du inte beviljade de ytterligare två Microsoft Teams-behörigheterna som vi behöver för att konfigurera boten och kanaltabbar. Utan detta steg kommer vi inte att kunna skicka ett välkomstmeddelande till dina valda teammedlemmar, och du kommer inte att kunna använda Vacation Tracker-appen från din Microsoft Teams-applikation.",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLoginSkip": "Du kan hoppa över detta steg och installera boten och tabbar manuellt. Se vår Helpdesk för en steg-för-steg-guide.",
  "microsoftSass.createCompany.setupMsBot.installBotAndTabs": "Installera bot och tabbar",
  "microsoftSass.createCompany.setupMsBot.installManuallyBotAdnTabs": "Jag kommer att installera bot och tabbar manuellt",
  "microsoftSass.createCompany.setupMsBot.microsoftTeamsLogin": "Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.morePermissionInfo": "Vi behöver ytterligare behörighet för att installera Vacation Tracker-chattboten och tabbar i ditt Microsoft Teams. Vi kommer att omdirigera dig till Microsoft-behörighetsskärmen när du klickar på Fortsätt-knappen nedan.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyDescription": "Vänligen installera Vacation Tracker Microsoft Teams-boten och tabbar manuellt genom att följa stegen som beskrivs i <helpdesk>denna Helpdesk-artikel</helpdesk>. Du bör inte behöva några administratörsbehörigheter för den manuella installationen. Du bör inte behöva någon administratörshjälp för den manuella installationen. Om du behöver hjälp, kontakta <support>vår support</support>.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyTitle": "Du behöver installera Microsoft-integrationen manuellt",
  "microsoftSass.createCompany.setupMsBot.notAdmin.weNeedAdminConsent": "Vi behöver administratörssamtycke för att konfigurera boten och tabbarna. Vi kan inte skicka välkomstmeddelanden förrän du installerar boten.",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabs": "Välj Microsoft Teams-kanal där du vill installera tabbar",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabsPlaceholder": "Välj Microsoft Teams-kanal",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBot": "Välj Microsoft Teams-team där du vill installera botten",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBotPlaceholder": "Välj Microsoft Teams-team",
  "microsoftSass.createCompany.setupMsBotAndTabs": "KONFIGURERA VACATION TRACKER-BOT OCH FLIKAR",
  "microsoftSass.createCompany.setupMsBotAndTabsInfo": "Låt oss installera Vacation Tracker-botten och flikar för Microsoft Teams!",
  "microsoftSass.createCompany.stepAssignLicenses": "2. Tilldela licenser",
  "microsoftSass.createCompany.stepCreateComapny": "1. Slutför företagskonfigurationen",
  "microsoftSass.createCompany.stepSetupBotAndTabs": "3. Konfigurera Microsoft Teams-botten och flikar",
  "microsoftSass.createCompany.welcomeMessage": "Välkommen till Vacation Tracker! Tack för att du köpte <bold>{totalLicenses} licenser</bold>.",
  "microsoftSass.createCompany.welcomeTitle": "LÅTS KONFIGURERA VACATION TRACKER",
  "microsoftSubscription.adminInitialContent": "Din prenumeration har gått ut. Gå till Microsoft AppSource-butiken för att återaktivera prenumerationen. Om du har några frågor eller behöver hjälp, kontakta vår support.",
  "microsoftSubscription.goToAppSource": "Köp en ny prenumeration",
  "modal.dontHaveAnAccount.accountNotFound": "Konto hittades inte",
  "modal.dontHaveAnAccount.asCompany": "Som ett företag",
  "modal.dontHaveAnAccount.asCompanyDescription": "Du är en administratör eller företagsägare som vill prova Vacation Tracker.",
  "modal.dontHaveAnAccount.asEmployee": "Som en anställd",
  "modal.dontHaveAnAccount.asEmployeeDescription": "Ditt företag använder Vacation Tracker, men du har inte tillgång till det.",
  "modal.dontHaveAnAccount.employeeSignUp": "Anställd registrering",
  "modal.dontHaveAnAccount.employeeSignupDescription": "Om ditt företag redan använder Vacation Tracker behöver du inte registrera dig! Kontakta din administratör för att aktivera dig, så får du full tillgång till Vacation Tracker-instrumentpanelen och integrationerna.",
  "modal.dontHaveAnAccount.employeeSignupSupport": "Om du behöver hjälp eller är osäker på vem din administratör är, tveka inte att kontakta vår support.",
  "modal.dontHaveAnAccount.introParagraph": "Det verkar som att du inte har ett konto. Hur vill du använda Vacation Tracker?",
  "modal.forgotPassword.step2Intro": "Vi har skickat en kod till din e-post. Vänligen ange den nedan och ställ in ett nytt lösenord. Om du inte har fått ett e-postmeddelande inom 5 minuter, kontrollera din skräppost och se om du angav rätt e-postadress.",
  "myProfile.cancel": "Avbryt",
  "myProfile.cancelLeave": "Avbryt",
  "myProfile.cancelLeaveConfirmTitle": "Avbryt ledighet",
  "myProfile.dontHaveAnyHistory": "{name} har inte tagit någon ledighet än.",
  "myProfile.edit.title": "Redigera min profil",
  "myProfile.editLeave": "Redigera ledighet",
  "myProfile.editLeaveWord": "Redigera",
  "myProfile.general": "Allmänt",
  "myProfile.history": "Historik",
  "myProfile.historyLeaves": "Historik",
  "myProfile.leaveHistory": "Ledighetshistorik",
  "myProfile.leaveTypes": "Ledighetstyper",
  "myProfile.noUpcomingLeaves": "Inga planerade ledigheter",
  "myProfile.openMyProfile": "Visa min profil",
  "myProfile.upcomingLeaves": "Planerade ledigheter",
  "myProfile.userWorkWeek": "Arbetsvecka",
  "not_in_channel": "Den valda kanalen har raderats eller är inte längre tillgänglig. Vänligen välj en annan kanal för att ta emot aviseringar på.",
  "notification.codeResent": "Verifieringskod skickad igen",
  "notification.codeResentMessage": "Kontrollera din e-post",
  "notification.passwordReset": "Lösenordet har återställts",
  "notification.passwordResetMessage": "Du kan nu logga in med ditt nya lösenord.",
  "notifications.add": "Ny notifikation",
  "notifications.cantOpenNoptification": "Denna notifikation skapades av en annan användare och du kan inte redigera den.",
  "notifications.createDailyRequestSubmitted": "Ställer in dagliga notifikationer",
  "notifications.createWeeklyRequestSubmitted": "Ställer in veckovisa notifikationer",
  "notifications.deleteAutomationConfirm": "Bekräfta att du vill radera automatiseringen <strong>{name}</strong>.",
  "notifications.deleteAutomationTitle": "Radera automatisering",
  "notifications.deleteDailyRequestSubmitted": "Raderar dagliga notifikationer",
  "notifications.deleteEntitlementByRoleAutomationConfirm": "Är du säker på att du vill radera denna automatisering för berättigande efter roll? Du kan inte ångra denna åtgärd. Dock kommer raderingen av automatiseringen inte påverka redan tilldelade {leaveTypeName} dagar.",
  "notifications.deleteNotificationConfirm": "Bekräfta att du vill radera notifikationen <strong>{name}</strong>.",
  "notifications.deleteNotificationOk": "Radera",
  "notifications.deleteNotificationTitle": "Radera notifikation",
  "notifications.deleteSeniorityEntitlementAutomationConfirm": "Är du säker på att du vill radera denna automatisering för senioritetsbetitlande? Du kan inte ångra denna åtgärd. Dock kommer raderingen av automatiseringen inte påverka redan tilldelade {leaveTypeName} dagar.",
  "notifications.deleteWeeklyRequestSubmitted": "Raderar veckovisa notifikationer",
  "notifications.emptyViewButton": "Skapa en daglig eller veckovis notifikation",
  "notifications.emptyViewMessage": "genom att skicka automatiserade notifikationer om kommande ledigheter",
  "notifications.emptyViewTitle": "Öka synligheten och håll ditt team informerat",
  "notifications.emptyViewVideoLink": "För mer information, besök vår <link>Helpdesk</link> eller se videon nedan:",
  "notifications.error": "Om detta problem kvarstår, vänligen kontakta support och ge dem följande felkod: {correlationId}.",
  "notifications.frequency": "Frekvens",
  "notifications.handleSubmitErrorTitle": "Notifikationsfel",
  "notifications.microsoftTokenMissing": "Vi kan inte ställa in notifikationer eftersom du saknar Microsoft-token. Logga ut och logga in igen för att få Microsoft-token och försök sedan igen.",
  "notifications.notificationInfo": "Om du vill ställa in notifikationer för en specifik avdelning, gå till sidan Avdelningar och redigera den avdelning du vill ställa in notifikationer för.",
  "notifications.notificationSendNow": "Din notifikation är på väg! 🚀",
  "notifications.notificationSendNowFailed": "Leverans misslyckades",
  "notifications.notificationSendNowFailedInfo": "Åh nej! 😕 Något gick fel. ",
  "notifications.notificationTitle": "Notifikationer",
  "notifications.passwordChangedDescription": "Du har ändrat ditt lösenord, vänligen logga in",
  "notifications.passwordChangedTitle": "Lösenord ändrat",
  "notifications.sendNotificationNowCofirmModalDesc": "Notifikationen <strong>{name}</strong> är inte aktiv. Bekräfta att du vill skicka den nu ändå.",
  "notifications.sendNotificationNowCofirmModalTitle": "Skicka notifikation",
  "notifications.sendNotificationNowOk": "Skicka",
  "notifications.table.actions": "Åtgärder",
  "notifications.table.active": "Aktiv",
  "notifications.table.DAILY": "Dagligen",
  "notifications.table.frequency": "Frekvens",
  "notifications.table.name": "Namn",
  "notifications.table.owner": "Ägare",
  "notifications.table.scheduledTime": "Schemalagd tid",
  "notifications.table.sendNotificationNow": "Skicka nu",
  "notifications.table.WEEKLY": "Veckovis",
  "notifications.title": "Aviseringar",
  "notifications.tooltipInfo": "Alla dina aviseringar samlas på denna sida för enklare hantering. För att lära dig mer, vänligen se vår <helpDesk>Helpdesk-artikel.</helpDesk>",
  "notifications.update": "Uppdatera avisering",
  "notifications.updateAutomationConfirm": "Att inaktivera en automatisering avbryter inte prenumerationen. Om du vill avsluta prenumerationen måste du gå till listan över automatiseringar genom att klicka på \"Skapa automatisering\" och sedan klicka på \"Avsluta prenumeration\"-knappen",
  "notifications.updateAutomationTitle": "Uppdatera automatisering",
  "notifications.updateNotification": "Uppdatera",
  "notifications.weekStartsOn": "Veckan börjar på",
  "notificationsForm.advancedFilter.tooltipInfo": "Om inga filter är valda kommer aviseringen att inkludera alla användare.",
  "notificationsForm.createInProgress": "Skapar aviseringen {notificationName}",
  "notificationsForm.currentWeek": "Nuvarande vecka",
  "notificationsForm.DAILY": "Dagligen",
  "notificationsForm.deleteFailed": "Borttagning misslyckades",
  "notificationsForm.deleteInProgress": "Tar bort aviseringen {notificationName}",
  "notificationsForm.frequency": "Frekvens",
  "notificationsForm.groupBy": "Gruppera ledighet efter",
  "notificationsForm.groupBy.tooltip": "Ledigheter kan visas enligt dina preferenser. De kan sorteras efter användarnas plats, avdelning eller alfabetiskt (Ingen).",
  "notificationsForm.locale": "Språk",
  "notificationsForm.LOCATION": "Plats",
  "notificationsForm.name": "Aviseringsnamn",
  "notificationsForm.newNotificationTitle": "Ny avisering",
  "notificationsForm.nextWeek": "Nästa vecka",
  "notificationsForm.NONE": "Ingen",
  "notificationsForm.periodCovered": "Täckt tidsperiod",
  "notificationsForm.saveFailed": "Sparande misslyckades",
  "notificationsForm.selectChannel": "Välj kanal",
  "notificationsForm.sendEmpty": "Om inga kommande ledigheter",
  "notificationsForm.sendEmptyNo": "Nej, skicka inte aviseringen",
  "notificationsForm.sendEmptyYes": "Ja, skicka aviseringen",
  "notificationsForm.sendOnDays": "Skicka på",
  "notificationsForm.sendOnDaysInfo": "Avisering kommer att skickas ut på den/de valda dagen/dagarna.",
  "notificationsForm.submit": "Spara avisering",
  "notificationsForm.TEAM": "Avdelning",
  "notificationsForm.time": "Tid",
  "notificationsForm.timezone": "Tidszon",
  "notificationsForm.update": "Uppdatera avisering",
  "notificationsForm.updateInProgress": "Uppdaterar aviseringen {notificationName}",
  "notificationsForm.updateNotificationTitle": "Uppdatera avisering",
  "notificationsForm.WEEKLY": "Veckovis",
  "notificationsForm.weekStartsOn": "Veckan börjar på",
  "overlapInfo.moreUsersAreOnLeaves": "ℹ️ Det verkar som att {overlapperNames} kommer att vara lediga under samma tidsperiod.",
  "overlapInfo.youCanSafelyAcceptTheRequest": "Du kan tryggt godkänna begäran, ingen annan från {teamName} är ledig under denna tidsperiod.",
  "overlapInfo.youCanSafelyAcceptTheRequestAutoApproved": "Ingen annan från {teamName} är ledig under denna tidsperiod.",
  "page404.message": "Sidan du letar efter tar lite välförtjänt ledighet.",
  "page404.title": "Sidan är på semester.",
  "quickTour.admin.buttonTitle": "Snabbtur",
  "quickTour.buttons.back": "Tillbaka",
  "quickTour.buttons.close": "Stäng",
  "quickTour.buttons.last": "Stäng",
  "quickTour.buttons.next": "Nästa",
  "quickTour.buttons.skip": "Hoppa över",
  "registration.flowB.progress": "Steg {current} av {total}",
  "registration.flowB.step1.list1": "<b>Gratis 7-dagars prov</b>, alla funktioner ingår",
  "registration.flowB.step1.list2": "Inget kreditkort krävs under provperioden",
  "registration.flowB.step1.list3": "Integrera med ditt samarbetsverktyg",
  "registration.flowB.step1.submitButton": "Kom igång",
  "registration.flowB.step1.subtitle": "Vad är din företagsmail?",
  "registration.flowB.step1.title": "Anslut dig till tusentals team världen över som effektiviserar semesterhantering med Vacation Tracker.",
  "registration.flowB.step2.submitButton": "Skapa konto",
  "registration.flowB.step2.subtitle": "Välkommen till Vacation Tracker",
  "registration.flowB.step3.continueWithEmailPlatform": "Fortsätt med jobbmail",
  "registration.flowB.step3.continueWithPlatform": "Fortsätt med {platform}",
  "registration.flowB.step3.list1": "Hantera ledighetsansökningar",
  "registration.flowB.step3.list10": "och mer!",
  "registration.flowB.step3.list2": "Skicka ledighetsnotiser",
  "registration.flowB.step3.list3": "Anpassa ledighetstyper",
  "registration.flowB.step3.list4": "Skapa platser och avdelningar",
  "registration.flowB.step3.list5": "Generera rapporter",
  "registration.flowB.step3.list6": "Ställ in ledighetsupptjäning",
  "registration.flowB.step3.list7": "Gruppera teammedlemmar med etiketter",
  "registration.flowB.step3.list8": "Schemalägg rapporter",
  "registration.flowB.step3.list9": "Begär ledighet per timme",
  "registration.flowB.step3.notice": "Vi kommer inte att kommunicera med ditt team på något sätt förrän du är redo.",
  "registration.flowB.step3.submitButton": "ETT SISTA STEG",
  "registration.flowB.step3.subtitle": "Vilket verktyg vill du integrera med, {name}?",
  "registration.flowB.step3.title": "7 dagars fullständig provplan",
  "registration.flowB.step3.title2": "Du kommer att få en <b>gratis provperiod</b> av vår <b>Fullständiga plan</b>, som inkluderar:",
  "registration.flowB.testimonial1.profession": "Producent",
  "registration.flowB.testimonial1.title": "\"Enkelheten med vilken alla övergick till att använda Vacation Tracker utan att ställa en miljon frågor var imponerande!\"",
  "registration.flowB.testimonial2.profession": "VD",
  "registration.flowB.testimonial2.title": "\"Mycket enkelt att installera, gör det superenkelt att begära och godkänna lediga dagar och teamet är mycket lyhört och tar feedbacken på allvar.\"",
  "registration.flowB.testimonial3.profession": "Chef för administrativa tjänster",
  "registration.flowB.testimonial3.title": "\"Jag kan nu fokusera på min personal och många andra saker - Vacation Tracker gav mig tillbaka minst 10 timmar i veckan plus många timmar tillbaka till våra chefer och anställda eftersom allt är elektroniskt.\"",
  "requestLeave.handleSubmitErrorTitle": "Fel vid inlämning av ledighet",
  "requestLeave.inProgress": "Begär ledighet",
  "requestLeave.requestLeave": "Begär ledighet",
  "requestLeave.title": "Ledighet begärd",
  "scheduledReportForm.addNewEmail": "Lägg till mottagare",
  "scheduledReportForm.approved": "Godkänd",
  "scheduledReportForm.createAScheduledReport": "Ny rapport",
  "scheduledReportForm.createInProgress": "Skapar den schemalagda rapporten {scheduledReportName}",
  "scheduledReportForm.deleteFailed": "Borttagning misslyckades",
  "scheduledReportForm.deleteInProgress": "Tar bort den schemalagda rapporten {scheduledReportName}",
  "scheduledReportForm.deleteReport": "Ta bort rapport",
  "scheduledReportForm.deleteScheduledReport": "Ta bort schemalagd rapport",
  "scheduledReportForm.deleteScheduledReportConfirm": "Vänligen bekräfta att du vill ta bort den schemalagda rapporten {name}.",
  "scheduledReportForm.deleteScheduledReportTitle": "Ta bort schemalagd rapport {name}",
  "scheduledReportForm.denied": "Nekad",
  "scheduledReportForm.emails": "Mottagare",
  "scheduledReportForm.lastMonth": "Förra månaden",
  "scheduledReportForm.leaveBalanceReport": "Rapport över ledighetssaldo",
  "scheduledReportForm.leaveHistoryReport": "Rapport över ledighetsförfrågningar",
  "scheduledReportForm.leaveStatus": "Ledighetsstatus",
  "scheduledReportForm.locations": "Plats(er)",
  "scheduledReportForm.monthlyLeaveBalanceReport": "Månadsvis rapport över ledighetssaldo",
  "scheduledReportForm.period": "Rapporteringsperiod",
  "scheduledReportForm.periodInfo": "Förra månaden ",
  "scheduledReportForm.periodInfoTootltip": "Rapporten är för närvarande begränsad till föregående månad. För fler alternativ, vänligen maila hello@vacationtracker.io så ser vi till att notera din förfrågan för möjliga framtida förbättringar.",
  "scheduledReportForm.pleaseInputEmailOrDeleteThisField": "Vänligen ange en giltig e-postadress eller ta bort detta fält.",
  "scheduledReportForm.pleaseInputValidEmail": "Vänligen ange en giltig e-postadress.",
  "scheduledReportForm.reportName": "Namn",
  "scheduledReportForm.reportSummary": "Rapportsammanfattning",
  "scheduledReportForm.reportSummaryInfo": "Rapporteringsperiod: {from} - {to}\nRapporttyp: {reportType}\nNästa rapport kommer att skickas den {dateReport} kl. {timeReport}",
  "scheduledReportForm.reportSummaryInfoMonthlyBalance": "Rapporteringsperiod: Nästa 12 månader. \nRapporttyp: {reportType}\nNästa rapport kommer att skickas den {dateReport} kl. {timeReport}",
  "scheduledReportForm.reportType": "Rapporttyp",
  "scheduledReportForm.required": "Detta fält är obligatoriskt.",
  "scheduledReportForm.saveFailed": "Sparande misslyckades",
  "scheduledReportForm.saveReport": "Schemalägg rapport",
  "scheduledReportForm.sendDayOfMonth": "Leveransdag",
  "scheduledReportForm.sendDayOfMonthInfo": "Konfigurera dina rapportleveranspreferenser genom att ange den exakta dagen då rapporten ska skickas till de tilldelade mottagarna.",
  "scheduledReportForm.sendHourAndMinute": "Leveranstid",
  "scheduledReportForm.sendHourAndMinuteInfo": "Konfigurera dina rapportleveranspreferenser genom att ange den exakta tiden på dagen då rapporten ska skickas till de tilldelade mottagarna.",
  "scheduledReportForm.sendReportFailed": "Leverans misslyckades",
  "scheduledReportForm.sendReportFailedInfo": "Åh nej! 😕 Något gick fel. ",
  "scheduledReportForm.sendReportNow": "Skicka nu",
  "scheduledReportForm.sendReportSuccessfully": "Din rapport är på väg! 🚀",
  "scheduledReportForm.teams": "Avdelning(ar)",
  "scheduledReportForm.timezone": "Tidszon",
  "scheduledReportForm.updateAScheduledReport": "Uppdatera rapport",
  "scheduledReportForm.updateInProgress": "Uppdaterar den schemalagda rapporten {scheduledReportName}",
  "scheduledReportForm.updateReport": "Uppdatera rapport",
  "scheduledReports.add": "Ny rapport",
  "scheduledReports.allLocations": "Alla platser",
  "scheduledReports.allTeams": "Alla avdelningar",
  "scheduledReports.createScheduledReports": "Ny rapport",
  "scheduledReports.deleteFailed": "Radering misslyckades",
  "scheduledReports.deleteInProgress": "Raderar den schemalagda rapporten {scheduledReportName}",
  "scheduledReports.deleteReportConfirm": "Radera rapporten {name}?",
  "scheduledReports.deleteReportOkText": "Radera",
  "scheduledReports.deleteReportTitle": "Bekräfta radering",
  "scheduledReports.deliveryDate": "Leveransdatum",
  "scheduledReports.edit": "Redigera",
  "scheduledReports.lastMonth": "Förra månaden",
  "scheduledReports.leaveBalanceReport": "Rapport över semestersaldo",
  "scheduledReports.leaveHistoryReport": "Rapport över semesterförfrågningar",
  "scheduledReports.locations": "Plats(er)",
  "scheduledReports.monthlyLeaveBalanceReport": "Månadsrapport över semestersaldo",
  "scheduledReports.name": "Namn",
  "scheduledReports.period": "Rapporteringsperiod",
  "scheduledReports.receivers": "Mottagare",
  "scheduledReports.reportSentSuccessfully": "Din rapport är på väg! 🚀",
  "scheduledReports.reportType": "Rapporttyp",
  "scheduledReports.sendReportFailed": "Leverans misslyckades",
  "scheduledReports.sendReportFailedInfo": "Åh nej! 😕 Något gick fel. ",
  "scheduledReports.sendReportNow": "Skicka nu",
  "scheduledReports.sendReportSuccessfully": "Din rapport är på väg! 🚀",
  "scheduledReports.teams": "Avdelning(ar)",
  "sidebar.addLeave": "Lägg till ledighet",
  "sidebar.addons": "Tillägg",
  "sidebar.billing": "Fakturering",
  "sidebar.calendar": "Kalender",
  "sidebar.changelog": "Ändringslogg",
  "sidebar.dashboard": "Instrumentpanel",
  "sidebar.export": "Exportera",
  "sidebar.faq": "Hjälpcenter",
  "sidebar.general": "Allmänt",
  "sidebar.holidays": "Helgdagar",
  "sidebar.imports": "Importer",
  "sidebar.leaves": "Ledighetsförfrågningar",
  "sidebar.leaveTypes": "Ledighetstyper",
  "sidebar.location": "Plats",
  "sidebar.locations": "Platser",
  "sidebar.main": "Huvudmeny",
  "sidebar.myProfile": "Min profil",
  "sidebar.notifications": "Aviseringar",
  "sidebar.onboarding": "Introduktion",
  "sidebar.reports": "Rapporter",
  "sidebar.requestLeave": "Begär ledighet",
  "sidebar.scheduledReports": "Schemalagda rapporter",
  "sidebar.settings": "Inställningar",
  "sidebar.teams": "Avdelningar",
  "sidebar.user": "Användare",
  "sidebar.users": "Användare",
  "signInAsUser.codeIsRequired": "En kod krävs",
  "signInAsUser.email": "Ange din e-postadress",
  "signInAsUser.emailIsRequired": "En giltig e-postadress krävs",
  "signInAsUser.enterTheCode": "Ange koden",
  "signInAsUser.userId": "Ange användar-ID",
  "signInAsUser.userIdIsRequired": "Ett användar-ID krävs",
  "slack.notificationsLoginRequiredDescription1": "Hantering av aviseringar kräver Slack-behörigheter. Om du klickar på OK-knappen omdirigerar vi dig till Slacks inloggningssida. Beroende på dina webbläsarinställningar kan du redan vara inloggad och automatiskt omdirigeras tillbaka.",
  "slack.notificationsLoginRequiredDescription2": "Efter att du loggat in på ditt Slack-konto tar vi dig tillbaka till denna sida så att du kan hantera aviseringarna. Om du klickar på Avbryt-knappen kommer du att tas tillbaka till den senaste sidan du besökte på vår instrumentpanel.",
  "slack.notificationsLoginRequiredTitle": "Inloggning på ditt Slack-konto krävs",
  "slack.usersLoginRequiredDescription1": "Hantering av användare kräver Slack-behörigheter. Om du klickar på OK-knappen omdirigerar vi dig till Slacks inloggningssida. Beroende på dina webbläsarinställningar kan du redan vara inloggad och automatiskt omdirigeras tillbaka.",
  "slack.usersLoginRequiredDescription2": "Efter att du loggat in på ditt Slack-konto tar vi dig tillbaka till denna sida så att du kan hantera användarna.",
  "slack.usersLoginRequiredTitle": "Inloggning på ditt Slack-konto krävs",
  "stripe.dynamicMessage": "{message}",
  "subscription.annual.info": "Du kommer att aktivera {plan} {period}-planen - ${price}",
  "subscription.hidePlansButton": "Dölj planer",
  "subscription.logout": "Logga ut",
  "subscription.monthly.info": "Du kommer att aktivera {plan} {period}-planen - ${usersPrice} ({noOfActiveUsers} användare x {dollarValue})",
  "subscription.monthly.infoUnderUserPLanLimit": "Du kommer att aktivera {plan} {period}-planen - ${usersPrice}",
  "subscription.showPlansButton": "Visa planer",
  "subscription.stripeCustomerPortalLink": "Klicka här för att besöka kundportalen",
  "subscription.subscribe": "Prenumerera",
  "subscription.title": "Prenumeration",
  "subscription.updateInProgress": "Försöker genomföra betalning",
  "subscriptions.switchPlanTitle": "Byt till <strong>{plan}</strong>-planen",
  "surveyAboutUs.description": "Kan du berätta hur du hörde talas om oss? Vi skulle gärna veta mer om din resa till Vacation Tracker. Detta är valfritt, men det skulle hjälpa oss mycket. Tack!",
  "surveyAboutUs.OptionQuestion1-1": "Google-sökning",
  "surveyAboutUs.OptionQuestion1-1-1": "Vad sökte du efter på Google (t.ex. \"semesterplanering\")?",
  "surveyAboutUs.OptionQuestion1-2": "App Store (Slack - App-katalog, MS Teams - App Source, etc...)",
  "surveyAboutUs.OptionQuestion1-3": "Youtube",
  "surveyAboutUs.OptionQuestion1-4": "Facebook",
  "surveyAboutUs.OptionQuestion1-5": "Jag frågade en AI (t.ex. ChatGPT, Claude, Copilot, etc...)",
  "surveyAboutUs.OptionQuestion1-5-1": "Det är bra! Vilken?",
  "surveyAboutUs.OptionQuestion1-5-1-1": "ChatGPT, Claude eller Gemini",
  "surveyAboutUs.OptionQuestion1-5-1-2": "Perplexity",
  "surveyAboutUs.OptionQuestion1-5-1-3": "Copilot",
  "surveyAboutUs.OptionQuestion1-5-1-4": "Annan",
  "surveyAboutUs.OptionQuestion1-6": "Rekommendation",
  "surveyAboutUs.OptionQuestion1-6-1": "Det är bra! Vem rekommenderade oss?",
  "surveyAboutUs.OptionQuestion1-6-1-1": "En kollega eller vän",
  "surveyAboutUs.OptionQuestion1-6-1-2": "Ett annat företag föreslog det",
  "surveyAboutUs.OptionQuestion1-6-1-3": "Jag har använt det tidigare",
  "surveyAboutUs.OptionQuestion1-7": "Annat",
  "surveyAboutUs.Question1": "Hur fick du reda på Vacation Tracker?",
  "surveyAboutUs.textarea": "Vänligen specificera",
  "surveyAboutUs.title": "Hur fick du reda på Vacation Tracker?",
  "team.approvers": "Godkännare",
  "team.createInProgress": "Skapar avdelningen {teamName}",
  "team.editTeam": "Redigera avdelning",
  "team.hasNoUsers": "Avdelningen har inga användare.",
  "team.name": "Namn",
  "team.team": "Avdelning",
  "team.updateInProgress": "Uppdaterar avdelningen {teamName}",
  "team.users": "Användare",
  "teamForm.createTeam": "Skapa avdelning",
  "teamForm.createTeamError": "Åh nej! 😕 Något gick fel. ",
  "teamForm.editTeam": "Redigera avdelning",
  "teamForm.name": "Namn",
  "teamForm.pleaseEnterTeamName": "Ange ett avdelningsnamn",
  "teamForm.setAsSubdepartment": "Välj överordnad avdelning",
  "teamForm.teamLimitReached": "Hoppsan! Du har nått gränsen på {limitNumber} avdelningar i din nuvarande plan. För att hantera eller uppgradera din plan, besök <billingPage>faktureringssidan</billingPage>.",
  "teamForm.updateTeam": "Uppdatera avdelning",
  "teams.approvers": "Godkännare",
  "teams.created": "Skapad",
  "teams.createNewTeam": "Skapa ny avdelning",
  "teams.defaultTeam": "Alla nya användare kommer automatiskt att läggas till i denna avdelning.",
  "teams.deleteInProgress": "Raderar avdelningen {teamName}",
  "teams.deleteTeam": "Radera",
  "teams.deleteTeamConfirm": "Är du säker på att du vill radera avdelningen {name}?",
  "teams.deleteTeamTitle": "Radera avdelning",
  "teams.editTeam": "Redigera avdelning",
  "teams.handleDeleteError": "Raderingsfel",
  "teams.handleDeleteErrorTitle": "Fel vid radering av avdelning",
  "teams.name": "Namn",
  "teams.removeUserInfo": "Du kan inte ta bort användaren från standardavdelningen. Om du vill flytta användaren till en annan avdelning kan du redigera den andra avdelningen och lägga till användaren där.",
  "teams.teamsLimitReached": "Du använder för närvarande alla {limitNumber} avdelningsplatser som är tillgängliga i CORE-planen. För att hantera eller uppgradera din plan, besök <billingPage>faktureringssidan</billingPage>.",
  "teams.users": "Användare",
  "teams.viewTeam": "Visa avdelning",
  "user.accrualsCalculator": "Beräkna",
  "user.accrualsCalculatorInfo": "Hur många dagar kommer jag att ha intjänat vid ett specifikt datum?",
  "user.addLeave": "Lägg till ledighet",
  "user.approve": "Godkänn",
  "user.approveInProgress": "Godkänner ledighetsansökan",
  "user.approveLeaveText": "Vänligen bekräfta att du vill godkänna ledighetsansökan.",
  "user.approveLeaveTitle": "Godkänn ledighetsansökan",
  "user.approveSuccessTitle": "Ledighetsansökan godkänd",
  "user.bulkChangeStatusInProgress": "Uppdaterar användarnas statusar.",
  "user.bulkChangeStatusSuccessTitle": "Användarnas statusar har uppdaterats.",
  "user.cancelLeave": "Avbryt ledighet",
  "user.cancelLeave.buttonLink": "Avbryt",
  "user.cancelLeaveConfirmTitle": "Avbryt ledighet",
  "user.cancelLeaveRequestInProgress": "Avbryter ledighetsansökan",
  "user.cancelLeaveRequestTitle": "Ledighetsansökan är avbruten",
  "user.currentLeaves": "Aktuella ledigheter",
  "user.deleteLeave": "Radera ledighet",
  "user.deleteLeave.buttonLabel": "Radera",
  "user.deleteLeaveConfirmText": "Vänligen bekräfta att du vill radera ledigheten.",
  "user.deleteLeaveConfirmTitle": "Radera ledighet",
  "user.deleteLeaveInProgress": "Raderar ledighet",
  "user.deleteLeaveSuccessTitle": "Ledigheten är raderad",
  "user.deleteToil": "Radera kompensationsledighet",
  "user.deleteToilConfirmText": "Vänligen bekräfta att du vill radera kompensationsledigheten.",
  "user.deny": "Neka",
  "user.denyInProgress": "Nekar ledighetsansökan",
  "user.denyLeaveConfirm": "Vänligen bekräfta att du vill neka ledighetsansökan.",
  "user.denyLeaveTitle": "Neka ledighetsansökan",
  "user.denySuccessTitle": "Ledighetsansökan nekad",
  "user.denyWithReason": "Neka med anledning",
  "user.dontHaveAnyHistory": "{name} har inte tagit någon ledighet än.",
  "user.editLeave": "Redigera ledighet",
  "user.editLeave.buttonLabel": "Redigera",
  "user.editLeaveWord": "Redigera",
  "user.editUser": "Redigera",
  "user.editWorkWeek": "Redigera",
  "user.employeeEndDate": "Anställningens slutdatum",
  "user.employeeId": "Anställnings-ID",
  "user.employeeSince": "Anställd sedan",
  "user.general": "Allmänt",
  "user.history": "Historik",
  "user.historyLeaves": "Historik",
  "user.invitationDeletedTitle": "Inbjudan raderad",
  "user.invitationSuccessTitle": "Inbjudningar skickade",
  "user.leaves": "Ledigheter",
  "user.leaveTypes": "Ledighetstyper",
  "user.location": "Plats",
  "user.location.moveInProgress": "Flyttar användare till platsen {name}",
  "user.location.moveSuccessTitle": "Användaren är flyttad till platsen {name}",
  "user.logs": "Loggar",
  "user.noUpcomingLeaves": "Inga planerade ledigheter",
  "user.pendingLeaves": "Väntande ledighetsansökningar",
  "user.probationPeriodDescription": "Användaren är i en provanställningsperiod under <probationPeriodName></probationPeriodName>-policyn för <leaveTypeNames></leaveTypeNames>, som varar i <lengthDays></lengthDays> dag(ar), fram till <date></date>.",
  "user.profile": "Profil",
  "user.resend": "Skicka om förfrågan",
  "user.resending": "Skickar om ledighetsansökan...",
  "user.resent": "Förfrågan skickad igen",
  "user.role": "Roll",
  "user.signUp": "Registrera dig",
  "user.signup.intro": "{invitedByName} har bjudit in dig att gå med i deras Vacation Tracker-organisation. Vänligen registrera dig nedan för att acceptera inbjudan och få tillgång till Vacation Tracker-dashboarden.",
  "user.signup.introHelpdesk": "Om du har några frågor eller behöver hjälp, kontakta vår support eller besök <helpdesk>Helpdesk</helpdesk>.",
  "user.status": "Status",
  "user.status.updateInProgress": "Uppdaterar användarstatus.",
  "user.status.updateSuccessTitle": "Användarstatus har uppdaterats.",
  "user.statusReason": "Lägg till anledning",
  "user.team.moveInProgress": "Flyttar användare till {name}-avdelningen",
  "user.team.moveSuccessTitle": "Användaren har flyttats till {name}-avdelningen",
  "user.upcomingLeaves": "Planerade ledigheter",
  "user.updateInProgress": "Uppdaterar användare",
  "user.updateSuccessTitle": "Användare uppdaterad",
  "user.userWorkWeek": "Användarens arbetsvecka",
  "user.workWeek.updateInProgress": "Uppdaterar arbetsvecka",
  "user.workWeek.updateSuccessTitle": "Arbetsveckan är uppdaterad",
  "users.aboutToSync": "Genom att synkronisera alla befintliga användare, synkroniserar du användardata från samarbetsplattformen som används av ditt team till deras Vacation Tracker-profil (t.ex. namn, profilbild etc.)",
  "users.activateUsers": "Aktivera",
  "users.activeUser": "Aktivera",
  "users.activeUsers": "Aktiva",
  "users.addEmailUsers": "Lägg till e-postanvändare",
  "users.addUsers": "Lägg till användare",
  "users.assignLicenses": "Tilldela licenser",
  "users.cancel": "Avbryt",
  "users.changeUserLocation": "Ändra plats",
  "users.changeUserTeam": "Ändra avdelning",
  "users.createUsers": "Skapa användare",
  "users.createUsersDescription": "Skapa nya användare innan du bjuder in dem att gå med. Du kan tilldela deras avdelning och plats nu, så att allt är klart när de accepterar.",
  "users.createUsersDescriptionShort": "Skapa och konfigurera användare utan att behöva vänta på att inbjudan ska accepteras.",
  "users.deactivate": "Inaktivera",
  "users.deactivateUser": "Inaktivera",
  "users.deactivateUserConfirm": "Är du säker på att du vill inaktivera {name}?",
  "users.deactivateUsers": "Inaktivera",
  "users.deactivateUserTitle": "Inaktivera",
  "users.deletedUsers": "Raderade",
  "users.deleteInvite": "Radera inbjudan",
  "users.deleteInviteConfirm": "Är du säker på att du vill radera inbjudan som skickats till {email}?",
  "users.deleteUser": "Radera användare",
  "users.deleteUserUserConfirm": "Är du säker på att du vill radera {name}?",
  "users.employmentDate": "Användarens anställningsdatum",
  "users.endDate": "Slutdatum",
  "users.import.description": "Importen kan ta lite tid. Om du inte ser nya användare i listan, vänligen uppdatera sidan efter 10-15 sekunder.",
  "users.importedSuccessfully": "Användare har importerats framgångsrikt",
  "users.importMany.InProgress": "Importerar användare",
  "users.importMany.successTitle": "Användare har importerats framgångsrikt",
  "users.importNewUsers": "Lägg till användare",
  "users.importOne.InProgress": "Importerar en ny användare",
  "users.importOne.successTitle": "Användare har importerats framgångsrikt",
  "users.importUsers": "Importera användare",
  "users.inactiveUsers": "Inaktiva",
  "users.invitations": "Inbjudningar",
  "users.inviteUserConfirm": "Skicka inbjudan till {name}?",
  "users.inviteUsers": "Bjud in användare",
  "users.inviteUsersDescription": "Skicka inbjudningar till användaren du skapade. Oombedda användare läggs till automatiskt i listan.",
  "users.inviteUsersDescriptionShort": "Bjud in användare som redan har skapats att gå med i din organisation.",
  "users.location": "Plats",
  "users.name": "Namn",
  "users.next": "Nästa",
  "users.prev": "Föregående",
  "users.reinvite": "Bjud in igen",
  "users.reInviteConfirm": "Är du säker på att du vill bjuda in {email} igen?",
  "users.role": "Roll",
  "users.search": "Sök användare",
  "users.sendInvitationsInLanguage": "Skicka inbjudningar på",
  "users.startDate": "Startdatum",
  "users.syncExistingUsers": "Synkronisera befintliga användare",
  "users.syncExistingUsers.InProgress": "Synkroniserar befintliga användare",
  "users.syncExistingUsers.successTitle": "Befintliga användare har synkroniserats framgångsrikt.",
  "users.syncWithGoogleConsent": "Synkronisera med Google-samtycke",
  "users.syncWithoutGoogleConsent": "Synkronisera utan Google-samtycke",
  "users.team": "Avdelning",
  "users.uninvited": "Ej inbjudna",
  "users.updateQuota.InProgress": "Uppdatering av användarkvot pågår",
  "users.updateQuota.successTitle": "Användarkvoten har uppdaterats framgångsrikt",
  "users.updateRolloverQuota.successTitle": "Initial överförd kvot har uppdaterats framgångsrikt.",
  "users.viewUser": "Visa användare",
  "utomations.maximumUsersAway.maximumUsersAwayDeleted": "Policy för maximalt antal frånvarande användare har raderats",
  "wallchart.range.next4Weeks": "Nästa 4 veckor",
  "wallchart.range.next5Weeks": "Nästa 5 veckor",
  "wallchart.range.nextMonth": "Nästa månad",
  "wallchart.range.previousMonth": "Föregående månad",
  "wallchart.range.thisMonth": "Denna månad"
}
