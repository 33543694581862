import React, { useState } from 'react'
import { Modal, Button, Typography, Spin } from 'antd'
import { capitalize } from 'lodash'
import IntlMessages from '../../util/IntlMessages'
import { allPlatforms } from '../../constants'
import {
  SlackOutlined,
  WindowsFilled,
  GoogleOutlined,
  MailOutlined
} from '@ant-design/icons'
import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft'
import { SlackAuth } from '../../services/auth/slack/slack'
import { GoogleAuth } from '../../services/auth/google/google'
import { useHistory } from 'react-router'
import * as logger from '../../services/logger'

const { Paragraph } = Typography

interface Props {
  visible: boolean
  onCancel: () => void
  toPlatform: typeof allPlatforms[number]
  fromPlatform: typeof allPlatforms[number]
}

const iconPadding = {
  paddingRight: '5px',
}

const platformIconMap = {
  slack: <SlackOutlined style={iconPadding}/>,
  microsoft: <WindowsFilled style={iconPadding}/>,
  google: <GoogleOutlined style={iconPadding}/>,
  email: <MailOutlined style={iconPadding}/>,
}

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID as string)
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID as string)
const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID as string)

export const MigrationFeatureModal: React.FC<Props> = ({ visible, onCancel, toPlatform, fromPlatform }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const connectWithPlatform = (platform: Props['toPlatform']) => {
    if (platform === 'slack') {
      connectWithSlack()
      logger.info('connectWithSlack clicked')
    } else if (platform === 'microsoft') {
      connectWithMicrosoft()
      logger.info('connectWithMicrosoft clicked')
    } else if (platform === 'google') {
      connectWithGoogle()
      logger.info('connectWithGoogle clicked')
    }
  }

  const connectWithMicrosoft = (interactionRequired = true) => {
    setIsLoading(true)
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all'], interactionRequired)
      .then(([tokens, msUser, tenantId]) => {
        try {
          msAuth.setTokens(tokens)
          history.push({
            pathname: '/app/migration',
            state: { newPlatform: toPlatform, oldPlatform: fromPlatform, msUser, tenantId },
          })
        } catch (error) {
          msAuth.setTokens(tokens)
        }
      })
      .catch(e => console.log('error', e))
  }
  
  const connectWithSlack = () => {
    slackAuth.signin(['chat:write', 'commands', 'team:read', 'users:read', 'users:read.email'],
      ['channels:write', 'groups:write', 'users:read', 'users:read.email', 'team:read', 'channels:read', 'groups:read', 'users.profile:write'])
      .then((response) => {
        slackAuth.setUserToken(response.authed_user.access_token)
        slackAuth.setBotToken(response.access_token)
        history.push({
          pathname: '/app/migration',
          state: {
            newPlatform: toPlatform, 
            oldPlatform: fromPlatform,
            slackOrgTeamId: response.enterprise?.id,
            organizationId: response.team.id,
            slackBotId: response.bot_user_id,
            slackTokenUser: response.authed_user.access_token,
            slackTokenBot: response.access_token,
          },
        })
      })
      .catch((error) => {
        logger.error('Failed to authenticate with Slack:', error)
        setIsLoading(false)
      })
  }

  const connectWithGoogle = async () => {
    setIsLoading(true)
    try {
      await googleAuth.signin(true)
      const tokens = {
        accessToken: sessionStorage.getItem('googleAccessToken'),
        refreshToken: sessionStorage.getItem('googleRefreshToken'),
      }
      const userInfo = googleAuth.getSignedInUser()
      console.log('userInfo', userInfo)
      console.log('tokens', tokens)
      
      history.push({
        pathname: '/app/migration',
        state: {
          newPlatform: toPlatform,
          oldPlatform: fromPlatform,
          googleDomain: userInfo.hd,
          googleAccessToken: tokens.accessToken,
          googleRefreshToken: tokens.refreshToken,
        },
      })
    } catch (error) {
      logger.error('Failed to authenticate with Google:', error)
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={<IntlMessages id="components.platformSwitchModal.title" values={{ platform: capitalize(toPlatform) }} />}
      open={visible}
      footer={null}
      onCancel={onCancel}
    >

      <Paragraph><IntlMessages id="components.platformSwitchModal.content" values={{ platform: capitalize(toPlatform) }} /></Paragraph>


      <Button type="primary" icon={platformIconMap[toPlatform]} onClick={() => connectWithPlatform(toPlatform)}>
        <IntlMessages id="error.token.button" values={{ platform: capitalize(toPlatform) }} />
      </Button>

      {isLoading && <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
        <Spin />
      </div>}
    </Modal>
  )
}

export default MigrationFeatureModal
