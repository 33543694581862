import React, { useState, useEffect, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { App, Tabs, Card, Empty, Button, Typography, TabsProps } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { isEmpty, isEqual, isNumber, sortBy, uniqBy } from 'lodash'
import dayjs from 'dayjs'
import { FetchData, useManualQuery } from 'graphql-hooks'
import * as Sentry from '@sentry/react'
import qs from 'qs'

import Api from '@vacationtracker/shared/services/api'
import { dayjsIterator } from '../../util/dayjs-iterator'
import {
  getLeavesByDateAndStatus,
  getPendingForApproverById,
  getHeatmapData
} from '../../graphql/custom-queries'
import * as logger from '../../services/logger'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { useShouldEnableFeatures } from '../../store/use-should-enable-features'
import { selectLeaveRequestActionEventSlice, setLeaveRequestActionEvent } from '../../store/leave-request-action-event-slice'
import { selectToilRequestActionEventSlice } from '../../store/toil-request-action-event-slice'
import { notificationStore } from '../../context/notificationsContext/store'
import { filterData } from './filter-data'
import { openSupportChat } from '../../util/open-support-chat'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { selectLocaleSlice } from '../../store/locale-slice'

import IntlMessages from '../../util/IntlMessages'
import DenyWithReasonForm from '../../components/deny-with-reason-form'
import HeatmapChart from '../../components/heatmap-chart'
import { getNumberOfDaysInMonth } from '../../components/heatmap-chart/helpers'
import CircularProgress from '../../components/circular-progress'
import ZohoPopUpSurvey, { SURVEY_TRIAL_EXTEND, ZohoSurveysEnum } from '../../components/zoho-pop-up-survey'
import { HeatmapEventType, IHeatmapHoliday } from '../../components/heatmap-chart/types'
import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import LeavesBox from '@vacationtracker/shared/components/leaves-box'
import { isToilLeave } from '@vacationtracker/shared/functions/is-toil-leave-request'
import { DEFAULT_WORKING_HOURS_IN_DAY } from '@vacationtracker/shared/data/app-parameters'

import { ILocationShort } from '@vacationtracker/shared/types/location'
import { IFilter } from '@vacationtracker/shared/types/filter'
import { ITeamShort } from '@vacationtracker/shared/types/team'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import {
  IGetLeavesByDateAndStatus,
  IGetLeavesByDateAndStatusResponse,
  IGetPendingForApproverByIdData,
  IGetUserIdsForApproverTo,
  IHeatmapLeaveRequests
} from '../../types/custom-queries'
import { IHeatMapLeaves, IDashboardLeaves, IApprover, DASHBOARD_SURVEY_STORAGE_KEY } from '../../types/dashboard'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { IDashboardLeaveRequest, IGetLeaveRequestsByDateResponse, LeaveRequestStatus } from '@vacationtracker/shared/types/leave-request'
import { ActiveTabEnum, HeatmapData, HeatmapDataResponse, ILabel } from './types'
import { IToilRequest } from '@vacationtracker/shared/types/toil-request'
import { FrontendUrls } from '../../types/urls'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { calculatePartDayHours } from '@vacationtracker/shared/functions/get-days-or-hours'

const SCHEDULED_LEAVES_LIMIT = 500

const { Paragraph } = Typography

async function loadHeatmapData(getHeatmapDataQuery: FetchData<HeatmapDataResponse, object, object>): Promise<HeatmapData> {
  const abortController = new AbortController()
  const signal = abortController.signal

  try {
    const heatmapData = await getHeatmapDataQuery({
      fetchOptionsOverrides: {
        signal,
      },
    })

    const holidays: IHeatmapHoliday[] = []
    const locations = heatmapData.data?.locations || []

    if (locations.length > 0) {
      locations.forEach((location) => {
        location.holidays.forEach((holidayYear) => {
          holidays.push(
            ...holidayYear.holidays
              .map(holiday => ({
                ...holiday,
                type: HeatmapEventType.holiday,
                locationName: location.name,
                locationId: location.id,
              })) as IHeatmapHoliday[]
          )
        })
      })
    }

    return {
      holidays,
      locations: locations.map(location => ({ id: location.id, name: location.name })),
      departments: heatmapData.data?.departments || [],
      labels: heatmapData.data?.labels || [],
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('Fetch aborted')
    } else {
      console.error('Error loading heatmap data:', error)
    }
    return {
      holidays: [],
      locations: [],
      departments: [],
      labels: [],
    }
  }
}

async function loadUpcomingLeaves(
  startDate: Date,
  endDate: Date,
  limit = 0,
  nextToken = 'NONE',
  status: LeaveRequestStatus = 'APPROVED',
  getLeavesByDateAndStatusQuery: FetchData<IGetLeavesByDateAndStatus, IGetLeavesByDateAndStatusResponse, object>
): Promise<IGetLeaveRequestsByDateResponse> {
  const response = await getLeavesByDateAndStatusQuery({ variables: {
    dateStart: dayjs(startDate).format('YYYY-MM-DD'),
    dateEnd: dayjs(endDate).format('YYYY-MM-DD'),
    status,
    limit,
    nextToken,
  }})

  // This one is a bit weird, but it happens while dashboard is loading.
  // The error causes the dashboard to stuck in loading state, and show a misleading error message.
  // This is a workaround to prevent the error from being thrown.
  if (
    response.error &&
    response.error.fetchError &&
    response.error.fetchError.toString() === 'Error: fetchData should not be called after hook unmounted'
  ) {
    return {
      leaveRequests: [],
    }
  }

  if (response.error || !response.data || !response.data.getLeaveRequestByDate) {
    throw new Error(JSON.stringify(response))
  }
  return response.data?.getLeaveRequestByDate
}

const DashboardPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { message, notification } = App.useApp()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { locale } = useAppSelector(selectLocaleSlice)
  const { leaveRequestActionEvent } = useAppSelector(selectLeaveRequestActionEventSlice)
  const { toilRequestActionEvent } = useAppSelector(selectToilRequestActionEventSlice)
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const isDashboardMounted = useRef(false)
  const aiAssistedOnboardingEnabled = useFeatureFlagEnabled(FeatureFlagEnum.aiAssistedOnboarding)

  const [openLeavesRequests, setOpenLeavesRequests] = useState<(IDashboardLeaveRequest & IToilRequest)[]>([])
  const [filteredOpenLeavesRequests, setFilteredOpenLeavesRequests] = useState<IDashboardLeaves[]>([])
  const [loadOpenLeavesRequests, setLoadOpenLeavesRequests] = useState(true)
  const [shouldLoadDashboardData, setShouldLoadDashboardData] = useState(true)
  const [upcomingLeaves, setUpcomingLeaves] = useState<IDashboardLeaveRequest[]>([])
  const [filteredUpcomingLeaves, setFilteredUpcomingLeaves] = useState<IDashboardLeaves[]>([])
  const [heatMapLeaves, setHeatMapLeaves] = useState<IHeatMapLeaves[]>([])
  const [selectedLeaveRequest, setLeaveRequest] = useState<IDashboardLeaves | {}>({})
  const [visibleDenyModal, setVisibleDenyModal] = useState(false)
  const [teams, setTeams] = useState<ITeamShort[]>([])
  const [locations, setLocations] = useState<ILocationShort[]>([])
  const [labels, setLabels] = useState<ILabel[]>([])
  const [heatmapCalendarHolidays, setHeatmapCalendarHolidays] = useState<IHeatmapHoliday[]>([])
  const [heatmapLoading, setHeatmapLoading] = useState(true)
  const [approverToUsers] = useState<IGetUserIdsForApproverTo[]>(authUser.approverTo)
  const [isLoadingDashboardData, setIsLoadingDashboardData] = useState(false)
  const [filter, setFilter] = useState<IFilter>({
    locationIds: [],
    teamIds: [],
    labelIds: [],
  })

  const today = new Date()
  const [selectedMonth, setSelectedMonth] = useState(today.getMonth())
  const [selectedYear, setSelectedYear] = useState(today.getFullYear())
  const [leaveRequestsNextPaginationToken, setLeaveRequestsNextPaginationToken] = useState<string | undefined>('')
  const [showZohoSurvey, setShowZohoSurvey] = useState(false)
  const [heatmapLeaveRequests, setHeatmapLeaveRequests] = useState<IHeatmapLeaveRequests[]>([])
  const shouldEnableFeatures = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  const [ getHeatmapDataQuery ] = useManualQuery<HeatmapDataResponse>(getHeatmapData)
  const [ getLeavesByDateAndStatusQuery ] = useManualQuery<IGetLeavesByDateAndStatus, IGetLeavesByDateAndStatusResponse>(getLeavesByDateAndStatus)
  const [ getPendingForApproverByIdQuery ] = useManualQuery<IGetPendingForApproverByIdData>(getPendingForApproverById)

  const onHeatmapPreviousMonth = () => {
    if (selectedMonth === 0) {
      setSelectedMonth(11)
      setSelectedYear(selectedYear - 1)
    } else {
      setSelectedMonth(selectedMonth - 1)
    }
    setHeatmapLoading(true)
  }

  const onHeatmapNextMonth = () => {
    if (selectedMonth === 11) {
      setSelectedMonth(0)
      setSelectedYear(selectedYear + 1)
    } else {
      setSelectedMonth(selectedMonth + 1)
    }
    setHeatmapLoading(true)
  }

  useEffect(() => {
    // This is (probably stupid) workaround to prevent the "Warning: Can't perform a React state update on an unmounted component."
    isDashboardMounted.current = true
    return () => {
      isDashboardMounted.current = false
    }
  }, [])

  useEffect(() => {
    handleFilter(filter)
    loadDashboardData(selectedYear, selectedMonth)
  }, [filter])

  useEffect(() => {
    if (queryParams.showSurvey === 'true' && !authCompany?.billing?.couponCodes?.includes(SURVEY_TRIAL_EXTEND)) {
      setShowZohoSurvey(true)
    }
  }, [location.search, authCompany])

  useEffect(() => {
    if (loadOpenLeavesRequests) {
      getOpenLeaves(getPendingForApproverByIdQuery)
    }
  }, [loadOpenLeavesRequests])


  useEffect(() => {
    if (isLoadingDashboardData) {
      loadDashboardData(selectedYear, selectedMonth)
    }
  }, [selectedYear, selectedMonth])

  useEffect(() => {
    const newFiltered = filterData(filter, upcomingLeaves) as IDashboardLeaves[]
    setFilteredUpcomingLeaves(newFiltered.sort((a, b) => a.startDate < b.startDate ? -1 : 1))
  }, [upcomingLeaves])

  useEffect(() => {
    if (leaveRequestActionEvent || toilRequestActionEvent) {
      if (!loadOpenLeavesRequests) getOpenLeaves(getPendingForApproverByIdQuery)
      if (isLoadingDashboardData) loadDashboardData(selectedYear, selectedMonth)
      dispatch(setLeaveRequestActionEvent(null))
    }
  }, [leaveRequestActionEvent, toilRequestActionEvent])

  useEffect(() => {
    if (heatmapLeaveRequests) {
      prepareLeavesForHeatMap(selectedYear, selectedMonth)
    }
  }, [heatmapLeaveRequests, selectedYear, selectedMonth])

  useEffect(() => {
    logger.debug('enable aiAssistedOnboardingEnabled IF', {
      onboardingQueryParam: queryParams?.onboarding === 'true',
      aiAssistedOnboardingEnabled,
      typeofAiAssistedOnboardingEnabled: typeof aiAssistedOnboardingEnabled,
      apiKeySet: Boolean(process.env.REACT_APP_PUBLIC_POSTHOG_KEY),
    })
    // If the REACT_APP_PUBLIC_POSTHOG_KEY is not set aiAssistedOnboardingEnabled will always be undefined
    if (queryParams?.onboarding === 'true' && (typeof aiAssistedOnboardingEnabled !== 'undefined' || !process.env.REACT_APP_PUBLIC_POSTHOG_KEY)) {
      history.location.search.replace('onboarding=true', '')
      if (aiAssistedOnboardingEnabled) {
        history.push(`${FrontendUrls.aiAssistant}/onboarding`)
      } else {
        history.push(`${FrontendUrls.dashboard}?tour=true`)
      }
    }
  }, [aiAssistedOnboardingEnabled])

  const getOpenLeaves = async (getPendingForApproverByIdQuery: FetchData<IGetPendingForApproverByIdData, {
    approverId: string
  }, object>) => {
    try {
      const response = await getPendingForApproverByIdQuery()
      if (!isDashboardMounted.current) {
        return
      }
      if (!response.data || response.error) throw response.error
      const toilRequest: IToilRequest[] = response.data.getPendingToilForApprover.map(toil => {
        let workingDays = dayjs(toil.endDate).diff(dayjs(toil.startDate), 'day') || 1
        if (toil.partDay) {
          const toilHours = calculatePartDayHours(toil.partDay)
          if (toilHours > 0) {
            workingDays = toilHours / DEFAULT_WORKING_HOURS_IN_DAY
          }
        }
        if (isNumber(toil.partDayEndHour) && isNumber(toil.partDayStartHour)) {
          const workingHours = toil.partDayEndHour - toil.partDayStartHour
          if (workingHours > 0) {
            workingDays = workingHours / DEFAULT_WORKING_HOURS_IN_DAY
          }
        }
        return {
          ...toil,
          workingDays,
        }
      })
      const requests = sortBy([
        ...toilRequest,
        ...response.data.getPendingForApprover,
      ], ['startDate'])
      setOpenLeavesRequests(requests as (IDashboardLeaveRequest & IToilRequest)[])
      setFilteredOpenLeavesRequests(filterData(filter, requests))

      setLoadOpenLeavesRequests(false)
    } catch (err) {
      logger.error('error fetching open leaves', err)
    }
  }

  const loadMoreUpcomingLeaves = async (
    isInitial = false,
    now = today,
    year = today.getFullYear(),
    month = today.getMonth(),
    nextToken?: string
  ) => {
    const todaysDate = now
    const endOf6stMonth = dayjs(`${year}-${month}-15`).add(24, 'month').endOf('month').toDate()
    try {
      const response = await loadUpcomingLeaves(todaysDate, endOf6stMonth, SCHEDULED_LEAVES_LIMIT, nextToken, 'APPROVED', getLeavesByDateAndStatusQuery)
      if (!isDashboardMounted.current) {
        return
      }
      setLeaveRequestsNextPaginationToken(response.nextToken)

      const newUpcomingLeaves = response.leaveRequests.filter(leave => dayjs().isBefore(dayjs(leave.startDate)))

      if (isInitial) {
        setUpcomingLeaves(newUpcomingLeaves)
      } else {
        const allUpcomingLeaves = uniqBy([ ...upcomingLeaves, ...newUpcomingLeaves ], 'id')
        setUpcomingLeaves(allUpcomingLeaves)
      }
    } catch (err) {
      Sentry.captureException(err?.error || err)

      notification.error({
        key: 'calendar-loading-error',
        message: formatMessage({ id: 'error.leaveRequestsLoadingError.title' }),
        description: (<>
          <Paragraph>{
            formatMessage({ id: 'error.leaveRequestsLoadingError.description' }, {
              link: (...chunks) => <a onClick={() => openSupportChat()}>{chunks}</a>,
            })
          }</Paragraph>
        </>),
        btn: (<Button onClick={() => {
          window.location.reload()
        }}>
          { formatMessage({ id: 'app.reload'} )}
        </Button>),
        duration: 0,
      })
    }
  }

  async function loadHeatMapLeaves (
    dateStart: string,
    dateEnd: string,
    status: LeaveRequestStatus,
    limit = 0,
    nextToken = 'NONE'
  ): Promise<string | undefined> {
    const response = await loadUpcomingLeaves(dayjs(dateStart).toDate(), dayjs(dateEnd).toDate(), limit, nextToken, status, getLeavesByDateAndStatusQuery)
    if (!isDashboardMounted.current) {
      return
    }
    setHeatmapLeaveRequests(leaves => uniqBy([...leaves, ...response.leaveRequests as []], 'id'))
    if (response.nextToken) {
      return await loadHeatMapLeaves(dateStart, dateEnd, status, limit, response.nextToken)
    }
  }

  const prepareLeavesForHeatMap = (year = today.getFullYear(), month = today.getMonth()) => {
    const monthStart = dayjs().year(year).month(month).startOf('month').format('YYYY-MM-DD')
    const monthEnd = dayjs().year(year).month(month).endOf('month').format('YYYY-MM-DD')
    const numberOfDays = getNumberOfDaysInMonth(year, month)
    let leaves: IHeatMapLeaves[] = Array(numberOfDays).fill(Object.assign({
      value: 0,
      leaves: [],
    }))
    if (heatmapLeaveRequests.length > 0) {
      heatmapLeaveRequests.forEach((leave) => {
        for (const d of dayjsIterator(leave.startDate, leave.endDate)) {
          const dayOfTheMonth = dayjs(d).get('date')
          const match = leaves.filter(item => item.formatDate === dayjs(d).format('YYYY-MM-DD'))
          const parsedDate = dayjs(d).format('YYYY-MM-DD')

          const filtered = filterData(filter, [leave])

          if (!filtered.length) {
            return
          }

          if (match.length > 0) {
            leaves = leaves.map((item: IHeatMapLeaves) => {
              return {
                ...item,
                value: item.formatDate === parsedDate ? item.value + 1 : item.value,
                leaves: item.formatDate === parsedDate ? [...item.leaves, leave] : item.leaves,
              }
            })
          } else if (dayjs(d).isBetween(monthStart, monthEnd, 'day', '[]')) {
            leaves[dayOfTheMonth - 1] = {
              formatDate: dayjs(d).format('YYYY-MM-DD'),
              date: dayjs(d).unix(),
              value: 1,
              leaves: [leave],
            }
          }
        }
      })
    }
    setHeatMapLeaves(leaves)
  }

  const loadDashboardData = async (year = today.getFullYear(), month = today.getMonth()) => {
    const monthStart = dayjs().year(year).month(month).startOf('month').format('YYYY-MM-DD')
    const monthEnd = dayjs().year(year).month(month).endOf('month').format('YYYY-MM-DD')
    try {
      const heatmapData = await loadHeatmapData(getHeatmapDataQuery)
      await loadHeatMapLeaves(monthStart, monthEnd, 'APPROVED', SCHEDULED_LEAVES_LIMIT, 'NONE')
      await loadMoreUpcomingLeaves(true)
      if (!isDashboardMounted.current) {
        return
      }
      setLocations(heatmapData.locations.map(l => ({name: l.name, id: l.id})))
      setTeams(heatmapData.departments)
      setLabels(heatmapData.labels)

      const heatmapHolidays = heatmapData.holidays
      const holidays: IHeatmapHoliday[] = heatmapHolidays.filter(holiday => {
        const holidayYear = holiday.date.slice(0, 4)
        const holidayMonth = holiday.date.slice(5, 7)
        return (
          Number(holidayYear) === year
          && Number(holidayMonth) === month + 1
          && (
            filter.locationIds.length === 0
            || filter.locationIds.length > 0 && filter.locationIds.includes(holiday.locationId)
          )
        )
      })

      setHeatmapCalendarHolidays(holidays)

      setHeatmapLoading(false)
      setShouldLoadDashboardData(false)
      setIsLoadingDashboardData(true)
    } catch (err) {
      const error = err?.error || err
      if(error?.errors) {
        error.errors = JSON.stringify(error?.errors)
      }
      Sentry.captureException(error)
      setIsLoadingDashboardData(false)

      notification.error({
        key: 'calendar-loading-error',
        message: formatMessage({ id: 'error.leaveRequestsLoadingError.title' }),
        description: (<>
          <Paragraph>{
            formatMessage({ id: 'error.leaveRequestsLoadingError.description' }, {
              link: (...chunks) => <a onClick={() => openSupportChat()}>{chunks}</a>,
            })
          }</Paragraph>
        </>),
        btn: (<Button onClick={() => {
          window.location.reload()
        }}>
          { formatMessage({ id: 'app.reload'} )}
        </Button>),
        duration: 0,
      })
    }
  }

  const onUpdateLeaveRequest = async (leave, leaveStatus, statusReason?: string) => {
    const upcomingLeaves: IDashboardLeaveRequest[] = []
    try {
      let response
      if (isToilLeave(leave.id)) {
        response = await Api.post('/core/event', {
          eventType: leaveStatus ? 'TOIL_REQUEST_APPROVED' : 'TOIL_REQUEST_DENIED',
          eventGroup: 'USER_TOIL_REQUEST',
          userId: leave.user.id,
          toilRequestId: leave.id,
          statusReason,
        })
      } else {
        response = await Api.post('/core/event', {
          eventType: 'LEAVE_REQUEST_STATUS_CHANGED',
          eventGroup: 'USER_LEAVE_REQUEST',
          userId: leave.user.id,
          leaveRequestId: leave.id,
          statusReason,
          leaveStatus: leaveStatus ? 'APPROVED' : 'DENIED',
        })
      }

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'app.updatedInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })

      setActionNotifications([
        ...actionNotifications,
        response.correlationId,
      ])

      setVisibleDenyModal(false)
      if (leaveStatus) {
        const leaveData: IDashboardLeaveRequest = {
          ...leave,
          approver: {
            id: authUser.id,
            name: authUser.name,
          },
        }
        upcomingLeaves.push(leaveData)
        setUpcomingLeaves(upcomingLeaves)
        setFilteredUpcomingLeaves(upcomingLeaves)
      }
      setOpenLeavesRequests(openLeavesRequests.filter((item) => item.id !== leave.id))
    } catch (error) {
      message.error(error)
    }
  }

  const onCancelLeave = async (leave) => {
    try {
      const response = await Api.post('/core/event', {
        eventType: 'LEAVE_REQUEST_CANCELLED',
        eventGroup: 'USER_LEAVE_REQUEST',
        leaveRequestId: leave.id,
        userId: leave.user.id,
      })

      const stateData = upcomingLeaves.filter(upcomingLeave => upcomingLeave.id !== leave.id)
      setUpcomingLeaves(stateData)
      setFilteredUpcomingLeaves(stateData)
      setHeatmapLeaveRequests(leaves => uniqBy(leaves.filter((item) => item.id !== leave.id), 'id'))

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'user.cancelLeaveRequestInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })

      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      logger.warning('cancel leave error', error)
      message.error(error)
    }
  }

  const [activeTab] = useState(() => {
    if (authUser.role === 'User') {
      return ActiveTabEnum.peopleOffToday
    }
    return ActiveTabEnum.openLeavesRequests
  })

  const handleFilter = (filterArg: IFilter) => {
    if(!isEqual(filterArg, filter)) {
      setFilter(filterArg)
    }

    setFilteredOpenLeavesRequests(filterData(filterArg, openLeavesRequests))
    setFilteredUpcomingLeaves(filterData(filterArg, upcomingLeaves))
  }

  const dashboardTabItems: TabsProps['items'] = [
    // UPCOMING DAYS OFF TAB
    {
      key: ActiveTabEnum.upcomingDaysOff,
      label: <>
        <IntlMessages id="app.scheduledLeaves" />
        {isLoadingDashboardData && ` (${filteredUpcomingLeaves.length}${leaveRequestsNextPaginationToken && filteredUpcomingLeaves.length >= 2 ? '+' : ''})`}
      </>,
      children: <>
        {filteredUpcomingLeaves.length > 0 ?
          filteredUpcomingLeaves.map(leave => {
            return (<LeavesBox
              leaveRequestId={leave.id}
              userId={leave.user.id}
              userImage={leave.user.imageUrl}
              userName={leave.user.name}
              daysList={leave.daysList}
              leaveTypeName={leave.leavePolicy.leaveType.name}
              startDate={leave.startDate}
              endDate={leave.endDate}
              leaveTypeColor={leave.leavePolicy.leaveType.color}
              leaveStatus="APPROVED"
              type="UPCOMING"
              role={authUser.role}
              reason={leave.reason}
              partDayStartHour={leave.partDayStartHour}
              partDayEndHour={leave.partDayEndHour}
              partDay={leave.partDay}
              approverName={typeof leave?.approver === 'object' ? (leave?.approver as IApprover)?.name : ''}
              autoApproved={leave.autoApproved}
              workingDays={leave.workingDays}
              isPartDay={leave.isPartDay}
              key={leave.id}
              isHiddenLeaveType={leave.leavePolicy.hideLeaveType}
              handleDenyWithReason={() => { setVisibleDenyModal(!visibleDenyModal); setLeaveRequest(leave) }}
              onUpdateLeaveRequest={() => { onUpdateLeaveRequest(leave, false) }}
              onCancelLeave={() => { onCancelLeave(leave) }}
              approverToUsers={approverToUsers}
              hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
              locale={locale.locale}
              hourFormat={authUser.hourFormat}
              isToil={isToilLeave(leave.id)}
              approvedBySubstituteApprover={leave.approvedBySubstituteApprover}
            />)
          }) :
          <Empty
            description={formatMessage({ id: 'dashboard.noOneIsTackingLeaves' })}
            image={<img src={require('../../assets/images/empty.png')} />}
            imageStyle={{ width: '180px', height: '180px', margin: '0 auto' }}
          />
        }
        {leaveRequestsNextPaginationToken &&
          <Button onClick={() => {loadMoreUpcomingLeaves(false, new Date(), new Date().getFullYear(), new Date().getMonth(), leaveRequestsNextPaginationToken)}}>
            <IntlMessages id="app.showMore" />
          </Button>
        }
      </>,
    },
  ]

  // PREPEND THE OPEN LEAVE REQUESTS TAB FOR ADMINS AND APPROVERS
  if (authUser.role !== 'User' || (authUser.role === 'User' && openLeavesRequests.length > 0)) {
    dashboardTabItems.unshift({
      key: ActiveTabEnum.openLeavesRequests,
      label: <><IntlMessages id="dashboard.openLeavesRequests" /> {`(${filteredOpenLeavesRequests.length})`}</>,
      children: filteredOpenLeavesRequests.length > 0 ?
        filteredOpenLeavesRequests.map(leave => {
          return (<LeavesBox
            leaveRequestId={leave.id}
            userId={leave.user.id}
            userImage={leave.user.imageUrl}
            userName={leave.user.name}
            daysList={leave.daysList}
            leaveTypeName={leave.leavePolicy.leaveType.name}
            startDate={leave.startDate}
            endDate={leave.endDate}
            leaveTypeColor={leave.leavePolicy.leaveType.color}
            leaveStatus="OPEN"
            type="REQUEST"
            workingDays={leave.workingDays}
            isPartDay={leave.isPartDay}
            role={authUser.role}
            partDayStartHour={leave.partDayStartHour}
            partDayEndHour={leave.partDayEndHour}
            partDay={leave.partDay}
            approvedLevel={leave.approvedLevel}
            approver={(Array.isArray(leave.approver) ? leave.approver[0] : leave.approver) as IApprover | undefined}
            // We need to append the approved level to the key to force re-render of the component when the first level approves the request
            key={`${leave.id}-${leave.approvedLevel}`}
            isHiddenLeaveType={leave.leavePolicy.hideLeaveType}
            reason={leave.reason}
            handleDenyWithReason={() => { setVisibleDenyModal(!visibleDenyModal); setLeaveRequest(leave) }}
            onUpdateLeaveRequest={(type) => { onUpdateLeaveRequest(leave, type) }}
            editLeaves={true}
            approverToUsers={approverToUsers}
            isEdited={leave.isEdited}
            hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
            locale={locale.locale}
            hourFormat={authUser.hourFormat}
            isToil={isToilLeave(leave.id)}
          />)
        }) :
        <Empty
          description={formatMessage({ id: 'dashboard.noOpenLeavesRequests' })}
          image={<img src={require('../../assets/images/empty.png')} />}
          imageStyle={{ width: '200px', height: '200px', margin: '0 auto' }}
        />,
    })
  }

  return (
    <>
      {/* TODO: add check if there are no leaves disable or do not display filter */}
      <div className="dashboard-filters dashboard-width">
        <FilterAdvanced
          page='dashboard'
          data={{
            Locations: locations,
            Departments: teams,
            Labels: labels,
          }}
          onChangeFilter={handleFilter}
          showLabels={shouldEnableFeatures}
          currentUserId={authUser.id}
        />
      </div>

      <Card className="heatmap dashboard-width">
        {(shouldLoadDashboardData) ?
          <CircularProgress /> :
          <HeatmapChart
            leaves={heatMapLeaves}
            onPreviousMonth={onHeatmapPreviousMonth}
            onNextMonth={onHeatmapNextMonth}
            month={selectedMonth}
            year={selectedYear}
            workingDays={authUser.workWeekType === 'LOCATION' ? authUser.location.workWeek : authUser.workWeek}
            holidays={heatmapCalendarHolidays}
            isHeatmapLoading={heatmapLoading}
            role={authUser.role}
            onCancelLeave={onCancelLeave}
            approverToUsers={approverToUsers}
            hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
            locale={locale.locale}
          />
        }
      </Card>
      <Card className="dashboard-leaves-info dashboard-width">
        {(shouldLoadDashboardData) ?
          <CircularProgress /> :
          <Tabs defaultActiveKey={activeTab} items={dashboardTabItems} />
        }
      </Card>
      {visibleDenyModal && !isEmpty(selectedLeaveRequest) &&
        <DenyWithReasonForm
          visibleModal={visibleDenyModal}
          leaveRequest={selectedLeaveRequest as IDashboardLeaves}
          handleCancel={() => (setVisibleDenyModal(false))}
          onSave={(data) => (onUpdateLeaveRequest(data.leaveRequest, false, data.statusReason))}
        />
      }
      {authUser.role === 'Admin' && authCompany?.trialPeriod === 'in_trial' && authCompany.billing?.paymentProcessor === 'microsoft-billing'
        ? <ZohoPopUpSurvey
          surveyId={ZohoSurveysEnum.trialSurvey}
          timeOffsetMinutes={720} // 12h
          remindMeLaterTimeOffset={2880} //48h
          surveyLocalStorageKey={DASHBOARD_SURVEY_STORAGE_KEY}
        />
        : <ZohoPopUpSurvey
          surveyId={ZohoSurveysEnum.trialExtendSurvey}
          timeOffsetMinutes={720}
          remindMeLaterTimeOffset={2880}
          surveyLocalStorageKey={DASHBOARD_SURVEY_STORAGE_KEY}
          showZohoSurvey={showZohoSurvey}
          setShowZohoSurvey={setShowZohoSurvey}
        />
      }
    </>
  )
}

export default DashboardPage
