{
    "accountSettings.connectYourPersonalCalendar": "Connect your personal calendar",
    "accountSettings.connectYourPersonalCalendar.description": "Connect your personal calendar to leaves automatically. When you request a leave, you can set if you want the requested leave to show as available or busy in your calendar. Visit our <helpdesk>Helpdesk</helpdesk> for more information.",
    "actionNotifications.companyAnnouncement.success": "Announcement sent",
    "actionNotifications.leave.editedRequestDenied": "Leave request edit denied.",
    "actionNotifications.leave.editedRequestDeniedDescription": "Your edited leave request has been denied, however, your original request remains active.",
    "actionNotifications.leave.requestApproved": "Leave Request is approved",
    "actionNotifications.leave.requestDenied": "Leave request has been denied.",
    "actionNotifications.leaveType.created": "The {leaveTypeName} leave type is created",
    "actionNotifications.leaveType.deleted": "The leave type is deleted",
    "actionNotifications.leaveType.updated": "The {leaveTypeName} leave type is updated",
    "actionNotifications.location.createdTitle": "The {locationName} location is created",
    "actionNotifications.location.deletedTitle": "The location is deleted",
    "actionNotifications.location.updatedTitle": "The {locationName} location is updated",
    "actionNotifications.location.view": "View location",
    "actionNotifications.notification.created": "Notification is created",
    "actionNotifications.notification.deleted": "Notification is deleted",
    "actionNotifications.notification.updated": "Notification is updated",
    "actionNotifications.outOfOfficeSetting.success": "Your out of office setting has been updated",
    "actionNotifications.scheduledReport.createdTitle": "The {scheduledReportName} scheduled report is created",
    "actionNotifications.scheduledReport.deletedTitle": "The scheduled report is deleted",
    "actionNotifications.scheduledReport.updatedTitle": "The {scheduledReportName} scheduled report is updated",
    "actionNotifications.scheduledReport.viewScheduledReport": "View scheduled report",
    "actionNotifications.team.createdTitle": "The {teamName} department is created",
    "actionNotifications.team.deletedTitle": "The department is deleted",
    "actionNotifications.team.updatedTitle": "The {teamName} department is updated",
    "actionNotifications.team.view": "View department",
    "addLeave.addLeave": "Add Leave",
    "addLeave.handleSubmitErrorTitle": "Leave Submit Error",
    "addLeave.inProgress": "Adding leave",
    "addLeave.noApproverUsers": "If you are not a part of a department, you won't be able to add leave for yourself. To request leave even if you're not a part of a department you have approval rights for, open \"Request leave\" form, and submit a leave request.",
    "addLeave.title": "Leave added",
    "addons.openApi.apiKeyName": "API Key Name",
    "addons.openApi.apiKeyNamePlaceholder": "API key name",
    "addons.openApi.apiKeySecret": "API Key Secret",
    "addons.openApi.apiKeysInfo": "Generate and manage your API keys.",
    "addons.openApi.apiKeysTitle": "API Keys",
    "addons.openApi.buyAppiAddon": "Buy API add-on",
    "addons.openApi.buyOpenApiInfoPrice": "API add-on is a paid add-on that costs ${price}/month/active user. Buying it will increase your monthly bill by ${totalPaid} for your {userCount} active {userCount, plural, =1 {user} other {users}} (we'll give you the precise estimate when you click the \"Buy\" button).",
    "addons.openApi.buyOpenApiInfoPrice2line": "After purchasing the add-on, you or any other administrator in your organization can generate and manage your API key.",
    "addons.openApi.buyOpenApiTitle": "Buy API add-on to generate API keys",
    "addons.openApi.createInProgress": "Create API Key in progress",
    "addons.openApi.createInProgressCompleted": "Create API Key completed",
    "addons.openApi.createNewApiKey": "Create New API Key",
    "addons.openApi.currentMonthUsage": "Current Month Usage",
    "addons.openApi.documentation": "API Documentation",
    "addons.openApi.documentationInfo": "The API documentation describes how our API works and what methods are currently available.",
    "addons.openApi.dontHaveApiKeys": "You do not have API keys, click the button below to create one",
    "addons.openApi.dpenDocumentation": "Open Documentation",
    "addons.openApi.feedback": "If you have feedback or need data our API does not expose, <link>click here to let us know</link>.",
    "addons.openApi.limitRequestPerMonth": "You can send up to 30000 requests per month",
    "addons.openApi.limitRequestPerSecound": "Requests are limited to 10 per second with a 50 requests/second burst mode",
    "addons.openApi.notificationDeleteConfirm": "Please confirm you want to delete API Key <strong>{name}</strong>.",
    "addons.openApi.notificationDeleteTitle": "Delete API Key",
    "addons.openApi.openApiDeleted": "Open API Key deleted",
    "addons.openApi.quotasAndRateLimitsInfo": "Here are our API quotas and rate limits per API key:",
    "addons.openApi.quotasAndRateLimitsTitle": "Quotas and rate limits",
    "addons.openApiInfo": "Our API add-on allows you to connect Vacation Tracker to other applications programmatically. Currently, our API allows you read-only access to your users, their leaves, locations, departments, leave types, and labels.",
    "addonsPaymentStatus.activateForFree": "Activate for free",
    "addonsPaymentStatus.activeUntilBillingPeriodEnds": "Active until billing period ends",
    "addonsPaymentStatus.canceled": "Canceled",
    "addonsPaymentStatus.grantPeriod": "Grant period",
    "addonsPaymentStatus.paymentActive": "Payment active",
    "ai.assistant.completedConversationTooltip": "This conversation is completed. You can't send new messages, but you can see the existing conversation.",
    "ai.assistant.defaultMessage": "What can I help you with?",
    "ai.assistant.disclaimer": "AI Assistant is in Beta. It can make mistakes. In case of errors or confusing responses, please <support>contact our support</support>.",
    "ai.assistant.inputLockedTooltip": "This is an early release of our AI Assistant. Currently, it only helps you with onboarding. However, soon you'll be able to start new conversations and talk to the Vacation Tracker AI Assistant. Thanks for your patience!",
    "ai.assistant.lastMessageTimestampTooltip": "The last message was sent on: {date}",
    "ai.assisted.onboarding.companyLogUserNameDecorator": "(through AI Assistant)",
    "ai.assisted.onboarding.finalAssistantMessage": "Your onboarding process is complete. Vacation Tracker will now set your company account with this configuration. Remember, you can always make changes to your setup later if needed.",
    "ai.assisted.onboarding.initialAssistantMessage": "🎯 Pre-filled for you:\n\nLocations ({locations}), \nDepartments ({departments}), and \nLeave Types ({leaveTypes}).\n\nQuick question: Does everyone in your company work in this location?",
    "ai.assisted.onboarding.initialUserMessage": "👋 Hey there! Welcome to Vacation Tracker.\n\nI’m here to make your setup <b>quick and easy</b>. Let me help you get started in minutes or feel free to handle it manually in Settings.",
    "ai.assisted.onboarding.noTopics": "Hey, it seems that you just found an Easter egg! 🐣 We are working on something new that should save you a lot of time, but it's not yet ready. So, please, let's keep this as our little secret. Thanks!",
    "ai.assisted.onboarding.openSetupNotification": "See what is done so far",
    "ai.assisted.onboarding.openSetupNotificationFetchingSetup": "Thank you for providing all the necessary information. I'm now starting to create your Vacation Tracker setup. You'll start seeing live updates anytime now...",
    "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigned": "{leaveTypeName} leave type assigned to {locationName}",
    "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigning": "Assigning {leaveTypeName} leave type to {locationName}",
    "ai.assisted.onboarding.openSetupNotificationLeavePolicySuccessAction": "{entityName} {entity} has been successfully {action}",
    "ai.assisted.onboarding.openSetupNotificationNoData": "Can't display setup data",
    "ai.assisted.onboarding.openSetupNotificationNoDataSubtitle": "I can't show the setup data right now. This can happen because of a short network interruption or because the system still hasn't finished processing data. Please reload this page or check the settings in the Settings section of the menu.",
    "ai.assisted.onboarding.openSetupNotificationTitle": "Onboarding Setup Status",
    "ai.assisted.onboarding.sendMessageError": "It seams that AI Assistant's response is taking too long. Please reload.",
    "ai.assisted.onboarding.summary.intro": "You are all setup now. You can now open the dashboard and start using the app.",
    "ai.assisted.onboarding.summary.introSummary": "Here's the summary of your setup:",
    "ai.assisted.onboarding.summary.leavePoliciesInfo": "Leave policies are leave types configurations specific to a location. To see the created leave policies, go to the locations page, select location, and then open the leave policies tab.",
    "ai.assisted.onboarding.summary.view.departments": "View Departments",
    "ai.assisted.onboarding.summary.view.leaveTypes": "View Leave Types",
    "ai.assisted.onboarding.summary.view.locations": "View Locations",
    "app.12h": "12h",
    "app.24h": "24h",
    "app.aboutMe": "About me:",
    "app.accountPreferences": "View Preferences",
    "app.accountSettings": "Account Settings",
    "app.accruals": "Accruals",
    "app.actions": "Actions",
    "app.activate": "Activate",
    "app.active": "Active",
    "app.add": "Add",
    "app.addAvatar": "Add Avatar",
    "app.addLeave": "Add Leave",
    "app.addNewEmail": "Add Recipient",
    "app.addons": "Add-ons",
    "app.addToGoogleCalendar": "Google Calendar",
    "app.addToMicrosoft365Calendar": "Microsoft 365 Calendar",
    "app.AIAssistant": "AI Assistant",
    "app.all": "All",
    "app.allDay": "All Day",
    "app.allowHalfDays": "Allow Half-Days",
    "app.allowHourlyIncrements": "Allow Hourly Increments",
    "app.amount": "Amount",
    "app.and": "and",
    "app.announcementsHaveBeenPaused": "Your announcements have been paused for 2 months.",
    "app.apply": "Apply",
    "app.applyTo": "Apply to",
    "app.approvalReason": "Approval reason",
    "app.approvalRequired": "Approval Required",
    "app.approve": "Approve",
    "app.approved": "Approved",
    "app.approvedByFristLevel": "You are the second level of approval for this request. It has already been approved by {approverName}.",
    "app.approveLeaveText": "Please confirm you want to approve the leave request.",
    "app.approveLeaveTitle": "Approve Leave Request",
    "app.april": "April",
    "app.areYouSure": "Are you sure?",
    "app.askMeLater": "Ask me later",
    "app.assignLeavePolicy": "Assign Leave Policy",
    "app.august": "August",
    "app.autoApproved": "Auto approved",
    "app.autoDeleted": "<Auto Deleted>",
    "app.autoImport": "<Auto Import>",
    "app.automations": "Automations",
    "app.avatar": "Avatar",
    "app.back": "Back",
    "app.backToMenu": "Back to menu",
    "app.bi-weekly": "Biweekly",
    "app.billing.periodAnnual": "Annual",
    "app.billingStatus": "Billing Status",
    "app.billingStatus.ACTIVE": "Payment active",
    "app.billingStatus.CANCEL_AT_PERIOD_END": "Active until billing period ends",
    "app.billingStatus.CANCELED": "Payment canceled",
    "app.billingStatus.GRANT_PERIOD": "Grant period",
    "app.broughtForward": "Brought Forward",
    "app.by": "by",
    "app.calendar": "Calendar",
    "app.cancel": "Cancel",
    "app.cancelLeave": "Cancel Leave",
    "app.cancelLeaveConfirmText": "Are you sure you want to cancel this scheduled leave?",
    "app.cancelled": "Cancelled",
    "app.change": "Change",
    "app.changePassword": "Change password",
    "app.clearFilters": "Clear Filters",
    "app.close": "Close",
    "app.code": "Code",
    "app.color": "Color",
    "app.coming-soon": "(coming-soon)",
    "app.comingSoon": "Coming soon",
    "app.company": "Company",
    "app.companyLogs": "Company Logs",
    "app.companyNotExist": "Oops! It seems that you did not complete the signup with Vacation Tracker. Please visit: app.vacationtracker.io to complete the signup.",
    "app.companyNotExist.description1": "You have now added the Vacation Tracker bot and tab to your Microsoft Teams workspace.",
    "app.companyNotExist.description2": "Please go to our website to complete your registration and configure your organizations settings.",
    "app.companyNotExist.getStarted": "Complete Signup",
    "app.completed": "Completed",
    "app.completeRegistration": "Complete Registration",
    "app.configuration.moreInfo": "For more information, visit our help desk: <link>https://vacationtracker.crisp.help/en/</link>",
    "app.configuration.option.overview": "Overview",
    "app.configuration.options.info": "Select the tab type: ",
    "app.configuration.welcome": "Welcome to Vacation Tracker! This tab allows you to easily request leave without leaving Microsoft Teams, manage your leaves, check on your leave statuses, see who is out of the office and see upcoming holidays for your team.",
    "app.configure": "Configure",
    "app.confirm": "Confirm",
    "app.confirmNewPassword": "Confirm New Password",
    "app.confirmPassword": "Confirm Password",
    "app.consentRequired": "Microsoft Teams Admin Consent Required. It seems that you are not an admin of your Microsoft Teams organization. In order to access the Vacation Tracker tab, you'll need admin consent. Please send the following link to the admin of your organization to get the admin consent: {link}",
    "app.consentRequired.title": "Microsoft Teams Admin Consent Required",
    "app.contactSupport": "Contact support",
    "app.continue": "Continue",
    "app.copyright": "Copyright VacationTracker",
    "app.correlationIdError": "An error occurred. Please contact our customer support at hello@vacationtracker.io with the following ID: {correlationId}",
    "app.country": "Country",
    "app.create": "Create",
    "app.created": "Created",
    "app.createdAt": "Created",
    "app.createdBy": "Created by",
    "app.createdSuccessfully": "Request submitted",
    "app.creating": "Creating",
    "app.creditCard": "Credit card",
    "app.currentYear": "Current Year",
    "app.customDays": "Custom Days",
    "app.customWorkHours": "Custom Work Hours",
    "app.daily": "Daily",
    "app.dashboard": "Dashboard",
    "app.dateFormat": "Date Format",
    "app.dateRange": "Start and End Date",
    "app.day": "day",
    "app.days": "days",
    "app.december": "December",
    "app.delete": "Delete",
    "app.deleted": "Deleted",
    "app.deleteFailed": "Delete faild",
    "app.deleteUser": "Delete User",
    "app.deleting": "Deleting",
    "app.deliveryFailed": "Failed Delivery",
    "app.denialReason": "Denial reason",
    "app.denied": "Denied",
    "app.deny": "Deny",
    "app.denyLeaveRequest": "Deny Leave Request",
    "app.denyLeaveRequestText": "Please confirm you want to deny the leave request.",
    "app.denyWithReason": "Deny with Reason",
    "app.department": "Department",
    "app.departments": "Departments",
    "app.departmentsSub": "Sub-departments",
    "app.deselectAll": "Deselect All",
    "app.disable": "Disable",
    "app.display": "Display",
    "app.displayDaysHoursTooltip.days": "That's {value} days",
    "app.displayDaysHoursTooltip.hours": "That's {value} hours",
    "app.documentation": "Documentation",
    "app.done": "Done",
    "app.dontSend": "Don't send",
    "app.downgradeWithoutUnsubscribe": "Downgrade without unsubscribing",
    "app.downgradeWithSubscribe": "Downgrade and unsubscribe",
    "app.download": "Download",
    "app.downloadProgressReport": "Creating your file, this may take a moment...",
    "app.downloadReady": "Your file is ready. Click here to download it.",
    "app.downloadTemplate": "Download Template",
    "app.downloadTemplateAs": "Download template as",
    "app.edit": "Edit",
    "app.edited": "Edited",
    "app.editLeave": "Edit Leave",
    "app.email": "Email",
    "app.emailAddress": "Email Address",
    "app.emailDeleted": "Email deleted",
    "app.employeeEndDate": "Employee End Date",
    "app.enable": "Enable",
    "app.enabled": "Enabled",
    "app.enterWorkEmail": "Enter your work email",
    "app.error": "Error",
    "app.every": "every",
    "app.expired": "Expired",
    "app.expires": "Expires",
    "app.export": "Export",
    "app.export.comingSoon": "Our team is polishing this feature. You should expect it soon.",
    "app.feature.access": "Available exclusively in the Complete plan.",
    "app.february": "February",
    "app.firstAndLastName": "First and Last Name",
    "app.friday": "Friday",
    "app.friday.short": "Fri",
    "app.from": "From",
    "app.full-day": "Full Day",
    "app.full-name": "Full Name",
    "app.general": "General",
    "app.goodAfternoon": "Good afternoon",
    "app.goodEvening": "Good evening",
    "app.goodMorning": "Good morning",
    "app.gotIt": "Got it",
    "app.half-day": "Half day",
    "app.halfDay": "Half-Day",
    "app.halfDayInParenthesis": " (Half-Day)",
    "app.happyVacationing": "Happy Vacationing 🌴",
    "app.helpdesk": "Helpdesk",
    "app.holidays": "Holidays",
    "app.home": "Home",
    "app.hour": "hour",
    "app.hours": "hours",
    "app.import": "Import",
    "app.importFile": "Start Import",
    "app.importFormat": "Import Format",
    "app.importFormatTitle": "Select Template Format",
    "app.importLeaves.alerts.confirmClose.bodyText": "Are you sure? Your current information will not be saved.",
    "app.importLeaves.alerts.confirmClose.cancelButtonTitle": "Cancel",
    "app.importLeaves.alerts.confirmClose.exitButtonTitle": "Exit",
    "app.importLeaves.alerts.confirmClose.headerTitle": "Exit import flow",
    "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyText": "There are still some rows that contain errors. Rows with errors will be ignored when submitting.",
    "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyTextSubmitForbidden": "There are still some rows containing errors.Please remove invalid rows or fix errors and try again.",
    "app.importLeaves.alerts.confirmClose.submitIncomplete.cancelButtonTitle": "Cancel",
    "app.importLeaves.alerts.confirmClose.submitIncomplete.finishButtonTitle": "Submit",
    "app.importLeaves.alerts.confirmClose.submitIncomplete.headerTitle": "Errors Detected",
    "app.importLeaves.alerts.toast": "Error",
    "app.importLeaves.alerts.unmatchedRequiredFields.bodyText": "There are required columns that are not matched or ignored. You must match all required columns in order to continue.",
    "app.importLeaves.alerts.unmatchedRequiredFields.cancelButtonTitle": "Cancel",
    "app.importLeaves.alerts.unmatchedRequiredFields.continueButtonTitle": "Continue",
    "app.importLeaves.alerts.unmatchedRequiredFields.headerTitle": "Not all columns matched",
    "app.importLeaves.alerts.unmatchedRequiredFields.listTitle": "Required columns not matched:",
    "app.importLeaves.downloadTemplateFileTooltip": "Here, you can download our import template file, which serves as an example for filling in the data you want to import. When creating your import file, please ensure that it contains a header column for the system to recognize your data correctly.<br></br><br></br>Check that your data matches the template to ensure a successful import. For additional instructions, feel free to reach out to our support team. ",
    "app.importLeaves.dropzone.activeDropzoneTitle": "Drop file here",
    "app.importLeaves.dropzone.buttonTitle": "Select file",
    "app.importLeaves.dropzone.errorToastDescription": "Upload .xlsx, .xls or .csv file",
    "app.importLeaves.dropzone.loadingTitle": "Processing...",
    "app.importLeaves.dropzone.title": "Upload .xlsx, .xls or .csv file",
    "app.importLeaves.errors.atLeastOne": "Please ensure that you add at least one field.",
    "app.importLeaves.errors.dateFromAfterDateTo": "Date From is after Date To",
    "app.importLeaves.errors.dateFromInFuture": "TOIL date cannot be in the future",
    "app.importLeaves.errors.dateFromNotValid": "Date From is not valid",
    "app.importLeaves.errors.dateFromRequired": "Date From is required",
    "app.importLeaves.errors.dateToNotValid": "Date To is not valid",
    "app.importLeaves.errors.dateToRequired": "Date To is required",
    "app.importLeaves.errors.departmentNotFound": "Department not found",
    "app.importLeaves.errors.endDateNotEnabled": "User end date is disabled in company settings",
    "app.importLeaves.errors.halfDay": "Leave is greater than one day",
    "app.importLeaves.errors.hourFromAfterHourTo": "Hour From is after Hour To",
    "app.importLeaves.errors.hourFromNotValid": "Hour From is not valid, enter a value between 0 and 24",
    "app.importLeaves.errors.hourFromRequired": "Hour From is required",
    "app.importLeaves.errors.hourRange": "Selected hours exceed this user's workday length ({hours} hours). Please adjust your selection.",
    "app.importLeaves.errors.hourToNotValid": "Hour To is not valid, enter a value between 0 and 24",
    "app.importLeaves.errors.hourToRequired": "Hour To is required",
    "app.importLeaves.errors.leavePolicyError": "Leave policy allows only full day",
    "app.importLeaves.errors.leavePolicyToilError": "Leave policy does not have TOIL enabled",
    "app.importLeaves.errors.leaveTypeNotFound": "Leave type not found",
    "app.importLeaves.errors.leaveTypeNotInUserLocation": "Leave type not in user location",
    "app.importLeaves.errors.locationNotFound": "Location not found",
    "app.importLeaves.errors.startDateRequired": "Start date is required",
    "app.importLeaves.errors.toilAlreadyExist": "TOIL already exists in this period",
    "app.importLeaves.helpdesk": "For detailed guidance on importing your files, please consult the step-by-step instructions available on <helpdesk> the help desk page.</helpdesk>",
    "app.importLeaves.importUsersDataWarning": "This import will only apply to active users.",
    "app.importLeaves.leaveStatusWarning": "Leave status will be set to \"Approved\" for all leaves you import.",
    "app.importLeaves.manifestDescription": "(You will have a chance to rename or remove columns in next steps)",
    "app.importLeaves.manifestTitle": "Data that we expect:",
    "app.importLeaves.matchColumnsStep.duplicateColumnWarningDescription": "Columns cannot duplicate",
    "app.importLeaves.matchColumnsStep.duplicateColumnWarningTitle": "Another column unselected",
    "app.importLeaves.matchColumnsStep.ignoredColumnText": "Column ignored",
    "app.importLeaves.matchColumnsStep.matchDropdownTitle": "Match",
    "app.importLeaves.matchColumnsStep.nextButtonTitle": "Next",
    "app.importLeaves.matchColumnsStep.selectPlaceholder": "Select column...",
    "app.importLeaves.matchColumnsStep.subSelectPlaceholder": "Select...",
    "app.importLeaves.matchColumnsStep.templateTitle": "Will become",
    "app.importLeaves.matchColumnsStep.title": "Match Columns",
    "app.importLeaves.matchColumnsStep.unmatched": "Unmatched",
    "app.importLeaves.matchColumnsStep.userTableTitle": "Your table",
    "app.importLeaves.maxRecordsExceeded": "Too many records. Up to {maxRecords} allowed",
    "app.importLeaves.nextButtonTitle": "Next",
    "app.importLeaves.selectHeaderStep.title": "Select header row",
    "app.importLeaves.selectSheet.title": "Select the sheet to use",
    "app.importLeaves.validationStep.discardButtonTitle": "Discard selected rows",
    "app.importLeaves.validationStep.filterSwitchTitle": "Show only rows with errors",
    "app.importLeaves.validationStep.nextButtonTitle": "Confirm",
    "app.importLeaves.validationStep.noRowsMessage": "No data found",
    "app.importLeaves.validationStep.noRowsMessageWhenFiltered": "No data containing errors",
    "app.importLeaves.validationStep.title": "Validate data",
    "app.imports": "Imports",
    "app.importType": "Import Type",
    "app.integrations": "Integrations",
    "app.invite": "Invite",
    "app.january": "January",
    "app.july": "July",
    "app.june": "June",
    "app.label": "Label",
    "app.labels": "Labels",
    "app.lastMonth": "Last month",
    "app.learnMore": "Learn more",
    "app.leave": "Leave",
    "app.leaveBalanceReport": "Leave Balance Report",
    "app.leaveCanceled": "Leave Canceled",
    "app.leaveHistory": "Leave History",
    "app.leaveHistoryReport": "Leave Request Report",
    "app.leavePolicies": "Leave Policies",
    "app.leaveRequestCalendarStatusLabel": "Show in your calendar as ",
    "app.leaveRequestCreated": "Leave Request Created",
    "app.leaveRequests": "Leave Requests",
    "app.leaveType": "Leave Type",
    "app.leaveTypes": "Leave Types",
    "app.link": "Link",
    "app.load": "Load",
    "app.loading": "Loading",
    "app.loadMore": "Load more",
    "app.location": "Location",
    "app.locations": "Locations",
    "app.login": "Log in",
    "app.logInWithMicrosoft": "Log in with Microsoft",
    "app.logout": "Logout",
    "app.logoutNow": "Logout Now",
    "app.logs": "Logs",
    "app.manageLabels": "Manage Labels",
    "app.march": "March",
    "app.may": "May",
    "app.maybe": "Maybe",
    "app.message": "message",
    "app.meta.description": "Loved by 1,000+ companies worldwide, Vacation Tracker makes it notoriously easy to manage your PTO. Available for Slack, Microsoft Teams, and Google Workspace.",
    "app.meta.title": "Vacation Tracker - Remote-friendly leave tracking solution",
    "app.mfa": "Multi-factor authentication",
    "app.mfaCode": "Code",
    "app.mfaCodeErrorGeneric": "Failed to validate code",
    "app.mfaCodeErrorMissmatch": "Code mismatch",
    "app.mfaCodeErrorNetwork": "NetworkError",
    "app.mfaCodePlaceholder": "Please type in the code displayed in your authenticator app",
    "app.mfaDisableAnswerNo": "No, keep MFA",
    "app.mfaDisableAnswerYes": "Yes, disable MFA",
    "app.mfaDisableError": "Failed to disable MFA. Please try again later.",
    "app.mfaDisableSuccess": "You have successfully disabled MFA.",
    "app.mfaDisableWarning": "Are you sure you want to disable MFA?",
    "app.mfaDisableWarningTitle": "Disable Multi-factor authentication",
    "app.mfaEnableError": "Failed to enable MFA. Please try again later.",
    "app.mfaEnableSuccess": "You have successfully set up MFA.",
    "app.mfaEnterCode": "Enter code",
    "app.mfaLabel": "Enable MFA",
    "app.mfaLoginFailed": "Login Failed",
    "app.mfaSecretKey": "Or enter the secret key manually:",
    "app.mfaSubTitle": "Scan the following QR code in your MFA application (i.e., Authy or Google Authenticator):",
    "app.mfaTitle": "Enable Multi-factor authentication",
    "app.mfaTooltip": "Once you have added Vacation Tracker to your MFA application, wait for it to generate the code and enter it below:",
    "app.microsoft.manageLicenses": "Manage licenses",
    "app.microsoftTeams": "Microsoft Teams",
    "app.monday": "Monday",
    "app.monday.short": "Mon",
    "app.monthly": "Monthly",
    "app.monthlyLeaveBalanceReport": "Monthly Leave Balance Report",
    "app.moreDetails": "More Details",
    "app.myProfile": "My Profile",
    "app.myStatus": "My status",
    "app.name": "Name",
    "app.negativeBroughtForward": "Enable Negative Brought Forward",
    "app.negativeBroughtForwardInfo": "If set to Yes, a negative balance will be carried over into the new leave period, reducing the employee's remaining leave entitlement accordingly.",
    "app.newPassword": "New Password",
    "app.next": "Next",
    "app.nextInvoice": " Your next invoice will be {amount} (excluding VAT) on {startDate}",
    "app.nextMonth": "Next month",
    "app.nextYear": "Next Year",
    "app.no": "No",
    "app.noLeaves": "No leaves",
    "app.none": "None",
    "app.nonWorkingDay": "Non working day",
    "app.notDone": "Not Done",
    "app.note": "Note",
    "app.notificationError": "Oh no! 😕 Something went wrong. ",
    "app.notifications": "Notifications",
    "app.notSet": "Not set",
    "app.notValidEmail": "Please enter a valid email address.",
    "app.november": "November",
    "app.october": "October",
    "app.of": "of",
    "app.ofThat": "of that",
    "app.ok": "Ok",
    "app.oldPassword": "Old Password",
    "app.one-hour": "1 hour",
    "app.openCalendar": "Open Calendar",
    "app.openDashboard": "Open dashboard",
    "app.openWebDashboard": "Open Web Dashboard",
    "app.or": "Or",
    "app.password": "Password",
    "app.passwordAndSecurity": "Password and Security",
    "app.pastDue": "Due to an unsuccessful payment your access to Vacation Tracker will be closed soon. If you wish to continue using Vacation Tracker, please <a>enter a valid card</a>.",
    "app.pending": "Pending",
    "app.pendingLeaveRequests": "Pending Leave Requests",
    "app.pleaseSelect": "Please select",
    "app.prev": "Prev",
    "app.previous": "Previous",
    "app.proceed": "Proceed",
    "app.processingRequest": "Processing request...",
    "app.profilePicture": "Profile Photo",
    "app.reason": "Reason",
    "app.recipients": "Recipients",
    "app.recommended": "recommended",
    "app.reconnect": "Reconnect",
    "app.reload": "Reload",
    "app.removed": "removed",
    "app.rename": "Rename",
    "app.reportType": "Report type",
    "app.requestLeave": "Request Leave",
    "app.requestToil": "Request TOIL",
    "app.requestType": "Request Type",
    "app.required": "Required",
    "app.resendCode": "Resend code",
    "app.resent": "Resent",
    "app.reset": "Reset",
    "app.resetPassword": "Reset password",
    "app.resultsLeaves": "Total found: {total} {total, plural, =1 {leave request} other {leave requests}}",
    "app.resultsUsers": "Total found: {total} {total, plural, =1 {user} other {users}}",
    "app.reviewAndConfirm": "Review & Confirm",
    "app.role.administrator": "Administrator",
    "app.role.approver": "Approver",
    "app.role.approvers": "Approvers",
    "app.role.approversLevelFrist": "Level 1",
    "app.role.approversLevelSecound": "Level 2",
    "app.saturday": "Saturday",
    "app.saturday.short": "Sat",
    "app.save": "Save",
    "app.scheduledLeaves": "Scheduled Leaves",
    "app.scheduledReports": "Scheduled Reports",
    "app.seeInCalendar": "🗓️ See in Calendar",
    "app.seeMoreOptions": "See more options",
    "app.select": "Select",
    "app.selectAll": "Select All",
    "app.selected": "Selected",
    "app.semi-monthly": "Semimonthly",
    "app.send": "Send",
    "app.sendInvites": "Send Invites",
    "app.sendNow": "Send Now",
    "app.september": "September",
    "app.set": "set",
    "app.setOn": "set on",
    "app.shortestLeaveDuration": "Shortest Leave Duration",
    "app.show": "Show",
    "app.showLess": "Show less",
    "app.showMore": "Show more",
    "app.skip": "Skip",
    "app.slack": "Slack",
    "app.snooze": "Snooze",
    "app.snoozeForMonths": "Snooze for 2 months",
    "app.startFreeTrial": "Start Your 7-Day Free Trial",
    "app.state": "State",
    "app.status": "Status",
    "app.submit": "Submit",
    "app.substituteApprover": "Substitute Approver",
    "app.substituteApproverSet": "You were set as a Substitute Approver.",
    "app.sunday": "Sunday",
    "app.sunday.short": "Sun",
    "app.switch": "Switch",
    "app.sync": "Sync",
    "app.takeQuickTour": "Take a quick tour",
    "app.text": "Text",
    "app.thisMonth": "This month",
    "app.thisWeek": "This week",
    "app.thursday": "Thursday",
    "app.thursday.short": "Thu",
    "app.time": "Time",
    "app.timeOffInLieu": "Time off in lieu",
    "app.timeOffInLieuTooltip": "Want to edit Time of in Lieu? <helpDesk>Learn how to do that</helpDesk>",
    "app.timezone": "Time zone",
    "app.to": "To",
    "app.today": "Today",
    "app.toil": "TOIL",
    "app.trialExpired": "The trial version expires in {days} {daysInfo}. If you like us, please <a>subscribe</a> to continue using Vacation Tracker after the trial period ends.",
    "app.trialExtend": "Do you need a trial extension?",
    "app.trialPeriod": "Trial Period",
    "app.tryAgain": "Try again",
    "app.tryLateOrContactSupport": "Something went wrong. Please try later or contact our customer support at hello@vacationtracker.io with the following ID: {code}",
    "app.tuesday": "Tuesday",
    "app.tuesday.short": "Tue",
    "app.type": "Type",
    "app.unknown": "<Unknown>",
    "app.unlimited": "Unlimited",
    "app.update": "Update",
    "app.updated": "updated",
    "app.updatedInProgress": "Processing Response",
    "app.updatedSuccessfully": "Request submitted.",
    "app.updating": "Updating",
    "app.upload": "Upload",
    "app.user": "User",
    "app.users": "Users",
    "app.users.role": "Role",
    "app.userStatus": "User Status",
    "app.via": "via",
    "app.view": "View",
    "app.viewAll": "View All",
    "app.visitHelpdesk": "Visit Helpdesk",
    "app.wallchart": "Wallchart",
    "app.was": "was",
    "app.watchVideo": "Watch video",
    "app.wednesday": "Wednesday",
    "app.wednesday.short": "Wed",
    "app.weekly": "Weekly",
    "app.willBe": "will be",
    "app.workday": "Length of Workday",
    "app.workdayWarning": "Half-day leave option is only available for an 8-hour workday. Changing the standard workday from 8 hours will disable this option.",
    "app.workweek": "Workweek",
    "app.year": "Year",
    "app.years": "Years",
    "app.yes": "Yes",
    "automation.feedback.thankYou": "Thank you for sharing your thoughts with us. Your input fuels our constant growth.",
    "automations.activateInProgress": "In Progress - Activation",
    "automations.activateInProgressDescription": "Your request to activate the automation is currently in progress. We are working on enabling this feature for you.",
    "automations.add": "Create automation",
    "automations.addAddonsDescirptionForAdmin": "Proceed with purchasing the selected Add-on? The amount will be prorated at ${prorateAmount}, with your next invoice totaling ${nextInvoiceAmount}. Click 'Confirm' to finalize. ",
    "automations.addAddonsDescirptionForAdminInTrial": "This add-on will be billed on your next invoice: ${total} based on {userCount} users at ${addonsPrice} per add-on. During the trial, you can activate and deactivate it freely without charges if no add-ons are active at the end.",
    "automations.addAddonsDescirptionForApprover": "Only Admin-level users have the capability to purchase or unsubscribe from Add-ons. If adjustments to your Add-on subscriptions are needed, please contact your administrator. ",
    "automations.addFreeAddonsDescirptionForAdmin": "This add-on is included at no additional cost under your current plan. You can freely activate and deactivate it during your current plan subscription without any extra charges.",
    "automations.addpnSuccessfullyAdded": "Completed - Activation",
    "automations.addpnSuccessfullyAddedDescription": "The automation has been successfully activated. You can now use all its features and functionalities.",
    "automations.addpnSuccessfullyDeactivated": "Completed - Deactivation",
    "automations.addpnSuccessfullyDeactivatedDescription": "The automation has been successfully deactivated. It will no longer be active in your system.",
    "automations.ADP_INTEGRATION": "ADP Integration",
    "automations.anythingMissing": "Anything missing?",
    "automations.automationBlackoutPeriodDeleted": "Blackout period deleted",
    "automations.automationEntitlementByRoleDeleted": "Entitlement by role deleted",
    "automations.automationProbationPeriodDeleted": "Probation period deleted",
    "automations.automationSeniorityEntitlementDeleted": "Seniority entitlement deleted",
    "automations.automationType": "Automation type",
    "automations.autoRejectResponse": "Auto-reject response",
    "automations.BAMBOOHR_INTEGRATION": "BambooHR Integration",
    "automations.BLACKOUT_PERIOD": "Blackout Period",
    "automations.blackoutPeriod.autoRejectReasonDefault": "During our current high-volume period, our entire team is essential to maintain operations. Unfortunately, we can't approve time off as it would affect our business goals and client commitments.",
    "automations.blackoutPeriod.createInProgress": "Create blackout period in progress",
    "automations.blackoutPeriod.createInProgressCompleted": "Create blackout period completed",
    "automations.blackoutPeriod.updateInProgress": "Update blackout period in progress",
    "automations.blackoutPeriod.updateInProgressCompleted": "Update blackout period completed",
    "automations.blackoutPeriodFromTo": "Blackout period {fromDate} <endDate>to {toDate}</endDate>",
    "automations.cantOpenAutomations": "This Automation was created by another user and you cannot edit it.",
    "automations.createAutomation": "Create automation",
    "automations.createPolicy": "Create policy",
    "automations.datePeriod": "{fromDate} <endDate>and {toDate}</endDate><recurring> each year</recurring>",
    "automations.deactivateInPgoress": "In Progress - Deactivation",
    "automations.deactivateInPgoressDescription": "Your request to deactivate the automation is now being processed. Please wait as we update your settings",
    "automations.deactivateVisibilityWarn": "Disabling an automation will not cancel the subscription. If you want to turn off the subscription, you need to click on the \"Unsubscribe\" button.",
    "automations.deleteInProgress": "Deleting the {automationName} automation",
    "automations.departmentVisibilityLevelInfo": "Users will see leave information only within their own department. Approvers will see leave information within their own department and any department they are approvers for, even if they are not members. Administrators will always see everything.",
    "automations.discoveryMoreAddons": "Discover More Add-ons",
    "automations.EARLY_EMPLOYMENT_LEAVE_RESTRICTION": "Early Employment Leave Restriction",
    "automations.emptyViewButton": "See available add-ons",
    "automations.emptyViewMessage": "Add PTO days based on seniority, create blackout periods, automate the approval or denying process, and many more with our automations, add-ons and integrations.",
    "automations.emptyViewTitle": "Automate your time-consuming tasks",
    "automations.ENTITLEMENT_BY_ROLE": "Entitlement By Role",
    "automations.entitlementByRole.createInProgress": "Create entitlement by role in progress",
    "automations.entitlementByRole.createInProgressCompleted": "Create entitlement by role completed",
    "automations.entitlementByRole.updateInProgress": "Update entitlement by role in progress",
    "automations.entitlementByRole.updateInProgressCompleted": "Update entitlement by role completed",
    "automations.filterDescription": "You can choose whether you'd like to apply this automation universally across all users or customize it based on specific departments, locations, or labels.",
    "automations.LEAVE_DURATION_LIMIT": "Leave Duration Limit",
    "automations.leaveDurationLimit.autoRejectDescription": "The system will automatically enforce these limits when users submit leave requests, providing a clear explanation if their request does not comply with the set rules.",
    "automations.leaveDurationLimit.autoRejectResponsePlaceholder": "Your leave request could not be approved as it does not comply with the defined leave duration policy. Please review the leave duration requirements and adjust your request accordingly. For further assistance, contact your administrator.",
    "automations.leaveDurationLimit.createInProgress": "Creating Leave Duration Limit policy",
    "automations.leaveDurationLimit.createInProgressCompleted": "Leave Duration Limit created",
    "automations.leaveDurationLimit.deleted": "Leave Duration Limit deleted",
    "automations.leaveDurationLimit.duration": "Min {minimumDuration, select, null {Not set} undefined {Not set} other {{minimumDuration} {units}}} / Max {maximumDuration, select, null {Not set} undefined {Not set} other {{maximumDuration} {units}}}",
    "automations.leaveDurationLimit.durationDescription": "Enter the desired range of leave durations (in days or hours) to ensure leave requests align with company policies and operational requirements.",
    "automations.leaveDurationLimit.policyDescription": "This section allows you to define the minimum and / or maximum leave duration limits for each policy.",
    "automations.leaveDurationLimit.summary": "This policy enforces the minimum and maximum leave duration limits set for {leaveTypes} leave types. These limits apply from {startDate} to {endDate}. This rule is applied to {allUsers, plural, =1 {specific locations / departments / labels} other {all users}}. Leave requests that fall outside this range will be automaically rejected with the specified message.",
    "automations.leaveDurationLimit.updateInProgress": "Updating Leave Duration Limit policy",
    "automations.leaveDurationLimit.updateInProgressCompleted": "Leave Duration Limit updated",
    "automations.leaveDurationLimitEmptyViewTitle": "Set and enforce minimum and maximum leave duration limits for users within a department, location, or any group defined by a label.",
    "automations.leaveTypesDescription": "You can also select one or more leave types to which you'd like to apply this automation.",
    "automations.MAXIMUM_USERS_AWAY": "Maximum Users Away",
    "automations.maximumUsersAway.autoRejectDescription": "Users will receive an automatic message explaining why their leave request was denied.",
    "automations.maximumUsersAway.createInProgress": "Creating Maximum Users Away policy",
    "automations.maximumUsersAway.createInProgressCompleted": "New Maximum Users Away policy created",
    "automations.maximumUsersAway.definePolicyDescription": "This section allows you to set a policy that limits the number of users who can be on leave at the same time.",
    "automations.maximumUsersAway.maxUsersDescription": "Enter the maximum number of users allowed to take leave simultaneously, and we'll ensure the system automatically restricts requests that exceed this limit.",
    "automations.maximumUsersAway.updateInProgress": "Updating Maximum Users Away policy",
    "automations.maximumUsersAway.updateInProgressCompleted": "Maximum Users Away policy update completed",
    "automations.maximumUsersAwayEmptyViewTitle": "Control the percentage of users who can be on leave simultaneously within a department, location, or any group defined by a label.",
    "automations.maxUsersAway.percentage": "{value}%",
    "automations.maxUsersAway.plural": "{value} users",
    "automations.maxUsersAway.singular": "{value} user",
    "automations.maxUsersAwayType": "Limit by",
    "automations.maxUsersAwayTypeAbsolute": "Number",
    "automations.maxUsersAwayTypePercentage": "Percentage",
    "automations.maxUsersAwayValue": "Maximum users away",
    "automations.message": "Message",
    "automations.messagePlaceholder": "Your message",
    "automations.myAddons": "My Add-ons",
    "automations.OPEN_API": "Open API",
    "automations.OPEN_API_DOCUMENTATION": "Open API Documentation",
    "automations.period": "Period",
    "automations.PROBATION_PERIOD": "Probation Period",
    "automations.probationPeriod.autoRejectReasonDefault": "Your leave application has been auto-rejected because you are in your early employment phase, a period when leave is not allowed. This policy helps new employees fully immerse and adapt to their new roles and team dynamics.",
    "automations.probationPeriod.createInProgress": "Create probation period in progress",
    "automations.probationPeriod.createInProgressCompleted": "Create probation period completed",
    "automations.probationPeriod.lengthDays": "Length (days)",
    "automations.probationPeriod.name": "Probation Policy Name",
    "automations.probationPeriod.namePlaceholder": "Enter probation policy name",
    "automations.probationPeriod.settings.applyToMDescription": "You can chose whether you'd' like to apply this automation universally across all users | or customize it based on specific departments, locations, or labels.",
    "automations.probationPeriod.settings.applyToMDescriptionTwo": "You can also select one or more leave types to which you'd like to apply this automation.",
    "automations.probationPeriod.settings.lengthDaysDescription": "Enter the number of days for which new employees will be restricted from taking specified types of leave, counting from their employment start date.",
    "automations.probationPeriod.settings.nameDescription": "This section allows you to set a policy that restricts new hires from taking leave during their initial employment period.",
    "automations.probationPeriod.settings.nameDescriptionThree": "Ensure users receive an automatic message explaining why their leave request was denied.",
    "automations.probationPeriod.settings.nameDescriptionTwo": "Enter the number of days, and we'll calculate the probation period based on each user's employment date.",
    "automations.probationPeriod.settings.nameTitle": "Define Policy",
    "automations.probationPeriod.updateInProgress": "Update probation period in progress",
    "automations.probationPeriod.updateInProgressCompleted": "Update probation period completed",
    "automations.probationPeriodMessages": "Your probation period lasts until {date}",
    "automations.removeAddons": "Remove add-ons",
    "automations.removeAddonsDescirptionForAdmin": "Are you sure you want to unsubscribe from this Add-on?  You will retain access to all of its features until the end of your current billing period. After that, it will be deactivated, affecting your leave management setup accordingly.",
    "automations.removeAddonsDescirptionForApprover": "Only Admin-level users have the ability to purchase or unsubscribe from Add-ons. If adjustments to your Add-on subscriptions are needed, please contact your administrator.",
    "automations.requestAnAutomation": "Request an Add-on",
    "automations.requestAutomationTitle": "Can't find an Add-on you need?",
    "automations.reSubscribeAddons": "Re-subscribe Add-on",
    "automations.reSubscribeAddonsDescirptionForAdmin": "Are you sure you want to resubscribe to this Add-on? You will be billed at ${addonsPrice} per user per month starting from your next invoice. The first month's charge will be prorated based on the number of days the add-on is used.",
    "automations.reSubscribeAddonsDescirptionForAdminInTrial": "Are you sure you want to resubscribe to this Add-on? It will remain free until the end of the trial period. After that, a charge of ${addonsPrice} per user per month will apply.",
    "automations.reSubscribeInfo": "Renew your add-on subscription and continue using our advanced features. Click on Resubscribe to proceed.",
    "automations.SENIORITY_ENTITLEMENT": "Seniority Entitlement",
    "automations.seniorityEntitlement.createInProgress": "Create seniority entitlement in progress",
    "automations.seniorityEntitlement.createInProgressCompleted": "Create seniority entitlement completed",
    "automations.seniorityEntitlement.periodsViewTableDays": "{amount} {amount, plural, =1 {day} other {days}} after {years} years",
    "automations.seniorityEntitlement.periodsViewTableHours": "{amount} {amount, plural, =1 {hour} other {hours}} after {years} years",
    "automations.seniorityEntitlement.updateInProgress": "Update seniority entitlement in progress",
    "automations.seniorityEntitlement.updateInProgressCompleted": "Update seniority entitlement completed",
    "automations.showCaneledAddons": "Show canceled Add-ons",
    "automations.stepByStepHelpDesk": "For detailed guidance on setting up your <automationName></automationName> Automation, please consult the step-by-step instructions available on <link>the help desk page</link>.",
    "automations.subscripteToAddons": "Subscribe to an Add-on",
    "automations.TEAM_LEAVE_BALANCING": "Maximum Users Away",
    "automations.unsubscribeInfo": "You are currently subscribed to this add-on at ${addonsPrice} per user. If you wish to unsubscribe, click the unsubscribe button on the right.",
    "automations.unsubscripteAddons": "Unsubscribe from Add-on?",
    "automations.update": "Update automations",
    "automations.VISIBILITY": "Visibility Manager",
    "automations.visibility.createInProgress": "Limiting visibility in progress",
    "automations.visibility.createInProgressCompleted": "Limiting visibility completed",
    "automations.visibility.updateInProgress": "Updating visibility add-on",
    "automations.visibility.updateInProgressCompleted": "Updating visibility add-on completed",
    "automations.visibilityForm.DEPARTMENT": "Their department",
    "automations.visibilityForm.limitVisibility": "Limit visibility for users",
    "automations.visibilityForm.visibilityLevel": "Users can see",
    "automations.visibilityForm.visibilityTypeValidationRequired": "You must select at least one visibility type",
    "automations.visibilityInfo.settings.nameTitle": "Visibility Settings",
    "automations.visibilityManagerNotificationsInfo": "Shared Calendars and Notifications created before the visibility settings change must be manually deleted if they do not comply with the new rules.",
    "automationsForm.advancedFilter.tooltipInfo": "You can filter the users to whom this automation will be applied based on departments, locations, or labels.",
    "automationsForm.applyToAllUsers": "Apply to all users?",
    "automationsForm.applyToAllUsersTooltip": "If set to Yes, the leave limit settings will apply across the entire organization. If set to No, you can specify the limit for particular departments, locations, or groups for more targeted management.",
    "automationsForm.autoRejectReason": "Auto-Reject Response",
    "automationsForm.autoRejectReasonTooltip": "Provide the user with an automatic response as to why their leave request has been rejected during this time.",
    "automationsForm.autoRejectResponseRequired": "Auto-reject response is required",
    "automationsForm.blackoutPeriodShortText": "A period of time when employees are prohibited from taking leave",
    "automationsForm.maximumLeaveDuration": "Maximum leave duration {units}",
    "automationsForm.maxmimumUsersAway.autoRejectResponsePlaceholder": "Regrettably, your leave request has been declined. We've reached our maximum allowed team leave capacity for this period, as per our Team Leaves Percentage Limit policy.",
    "automationsForm.maxmimumUsersAway.maxUsersAwayTypeTooltip": "Choose how to define the maximum allowed absences. Select 'Percentage' to set the percentage of users who can be absent at the same time, or choose 'Number' to specify the exact number of users allowed to be absent simultaneously.",
    "automationsForm.maxmimumUsersAway.maxUsersAwayValueAbsoluteError": "You entered {entered} users. The maximum number of users cannot exceed {max}. Please enter a lower value.",
    "automationsForm.maxmimumUsersAway.maxUsersAwayValuePercentageError": "You entered {entered}%. The percentage cannot exceed 90%. Please enter a lower value.",
    "automationsForm.maxmimumUsersAway.nameRequired": "The name is required",
    "automationsForm.maxUsersAwayValueRequired": "Maximum users away value is required",
    "automationsForm.minimumLeaveDuration": "Minimum leave duration {units}",
    "automationsForm.period": "Period",
    "automationsForm.periodRequired": "Period is required",
    "automationsForm.policy.name": "Enter policy name",
    "automationsForm.recurring": "Recur annually",
    "automationsForm.recurringTooltip": "Enable this option for the leave limit settings to automatically renew each year, ensuring consistent application of these parameters annually without manual reconfiguration.",
    "automationsForm.seniorityEntitlement.applyToAllUsersTooltip": "Do you want to apply this Seniority entitlement to all users in your company or users in a specific location? If you have different Seniority entitlement rules in different locations, you must create multiple Seniority entitlement automation rules, one for each location.",
    "automationsForm.seniorityEntitlement.beginningOfTheFiscalYear": "Beginning of the fiscal year",
    "automationsForm.seniorityEntitlement.employeeStartDate": "Employee's Start Date",
    "automationsForm.seniorityEntitlement.endOfTheFiscalYear": "End of the fiscal year",
    "billing.applyingCoupon": "Applying coupon",
    "billing.applyingCouponAndResubscribing": "Applying coupon & Re-subscribing",
    "billing.cancelSubscription": "Cancel Subscription",
    "billing.cancelTrial": "Your trial period will remain active until {stripeCurrentPeriodEnd}. To continue using Vacation Tracker, please enter your billing information.",
    "billing.cancelTrialButton": "Cancel Trial",
    "billing.cancelUpdateInProgress": "Canceling...",
    "billing.linkPaymentMethod": "Stripe Link",
    "billing.microsoft.billedLabel": "Billed",
    "billing.microsoft.billingCycle": "Current billing cycle ends on {date}",
    "billing.microsoft.billingCycleEnds": "Your subscription will be canceled after the billing cycle ends and you will no longer be able to use Vacation Tracker.",
    "billing.microsoft.billingCycleLabel": "Billing cycle",
    "billing.microsoft.buyLessLicensesConfirmModalContent": "You have {currentNoOfLicenses} licenses. You are about to remove {additionalLicences}. Are you sure?",
    "billing.microsoft.buyMoreLicensesConfirmModalContent": "You have {currentNoOfLicenses} licenses. You are about to buy {additionalLicences} more. Are you sure?",
    "billing.microsoft.canceledSubscriptionMessage": "You subscription will be canceled in ~15 minutes",
    "billing.microsoft.changePlanModalTitle": "Change current <strong>{plan}</strong> plan",
    "billing.microsoft.info": "Your payment information is managed by Microsoft. Visit <adminCenterLink>Admin Center</adminCenterLink> to update your {labelInfo}",
    "billing.microsoft.licensesSideActionLabel": "View history",
    "billing.microsoft.licensesTitle": "Licenses",
    "billing.microsoft.pricingPlanLabel": "Pricing plan",
    "billing.microsoft.switchPlanModal.confirm": "Please confirm that you want to switch to this plan.",
    "billing.paymentInformation": "Payment information",
    "billing.paymentMethod": "Payment Method",
    "billing.pricingPlanPeriodAnnual": "Annual",
    "billing.pricingPlanPeriodMonthly": "Monthly",
    "billing.pricingPlansText": "This section displays your subscription plan. You can resubscribe to the plan you previously had or change the plan before resubscribing.",
    "billing.pricingPlansTitle": "Pricing Plans",
    "billing.promoCodeErrorDescription": "The promo code {code} is not valid.",
    "billing.resubscribeCTA": "Re-subscribe",
    "billing.resubscribingUpdateInProgress": "Resubscribing...",
    "billing.seats": "Seats",
    "billing.seats.buyLessSeatsConfirmModalContent": "You have {currentNoOfSeats} seats. You are about to remove {additionalSeats}.",
    "billing.seats.buyMoreSeatsConfirmModalContent": "You have {currentNoOfSeats} seats. You are about to buy {additionalSeats} more.",
    "billing.seats.canNotDowngrade": "You can't downgrade your number of seats ({currentNumberOfSeats}) withot deactivating one or more currently active users.",
    "billing.seats.info": "The number of seats you have bought.",
    "billing.seats.loadingUpcomingPaymentInformation": "Loading upcoming payment information...",
    "billing.seats.manage": "Manage seats",
    "billing.seats.noBillingChange": "You are about to buy {noOfSeats} {noOfSeats, plural, =1 {seat} other {seats}}. Your billing will not be affected.",
    "billing.seats.warningOfProrateAdditional": "An estimated prorated charge of {proratedAmount} (excluding VAT) will be applied today.",
    "billing.seats.warningOfSeatsAnnualProrateAdditionalNewBucket": "Adding {noOfSeats} {noOfSeats, plural, =1 {seat} other {seats}} will increase your next bill to {amount} (excluding VAT) starting {startDate}.",
    "billing.seats.warningOfSeatsProrateAdditionalNewBucketMonthly": "Buying {noOfSeats} {noOfSeats, plural, =1 {seat} other {seats}} will affect your billing. Your next invoice will be {amount} (excluding VAT) on {startDate}",
    "billing.seats.warningOfSeatsRemovingAnnualProrateAdditionalNewBucket": "Removing {noOfSeats} {noOfSeats, plural, =1 {seat} other {seats}} will decrease your next bill to {amount} (excluding VAT) starting {startDate}.",
    "billing.seats.warningOfSeatsRemovingProrateAdditionalNewBucketMonthly": "Removing {noOfSeats} {noOfSeats, plural, =1 {seat} other {seats}} will affect your billing. Your next invoice will be {amount} (excluding VAT) on {startDate}",
    "billing.subscriptionCanceled": "Subscription canceled",
    "billing.subscriptionExpired": "Subscription not active",
    "billing.subscriptionExpiredDescription": "Your subscription is canceled or expired. Updates are not possible at this time.",
    "billing.subscriptionExpiredText": "Your subscription expired. Resubscribe to continue using Vacation Tracker. If you have any issues or questions, contact our {supportLink}.",
    "billing.SubscriptionPlan": "Subscription plan",
    "billing.subscriptionUpdateInProgress": "Subscription update in progress",
    "billing.subscriptionUpdateInProgressDescription": "The previous operation is still in progress. Please try again in a minute.",
    "billing.updateBillingInfo": "Update Billing Info",
    "billing.updatedFaild": "Billing information update failed",
    "billing.updatedSuccessfully": "Billing info is updated",
    "billing.updateInProgress": "Updating billing info",
    "bot.announce.slack": "Hey <@{slackUserId}>\n\nMy name is Vacation Tracker. I will help you book leaves through Slack and will also keep everyone informed of upcoming leaves in your organization.\n\nType `/vacation` anywhere in Slack to get started or check out my YouTube tutorial to learn more:\nhttps://youtu.be/9e6J1AmfdwM",
    "bot.announce.slack.info": "Hey <@{slackUserId}>, my name is Vacation Tracker.",
    "bot.announce.slackCompany": "Hey Team,\n\nMy name is Vacation Tracker Bot!\n\nI let you book your leaves through Slack and also keep everyone informed of their team members' upcoming leaves. Let's start tracking some leaves!\n\nType `/vacation` anywhere in Slack or check out YouTube tutorial to learn more:\nhttps://youtu.be/9e6J1AmfdwM",
    "bot.message.about.commandDescription": "Vacation Tracker simplifies your organization's request and booking process for leaves. Team members can book any Leave Type, or any kind of paid/unpaid day off, without ever leaving Slack, and without having to remember another login or password.\n\nSet up custom Leave Types, and let every individual in your organization be in control of their time off.Vacation Tracker provides each and every team member with access to the Dashboard(http://app.vacationtracker.io) where they can request leave, check their current leave status or history, and see scheduled leaves for their entire team.\n\nAccording to the Administrator's or Approver's settings, Team Members will receive daily/weekly notifications about upcoming absences. No fuss and no unpleasant surprises.\n\nWant to know more about Vacation Tracker? Our <https://vacationtracker.crisp.help/en/|Helpdesk> is your second best bet after the <https://vacationtracker.io/request-a-demo/|demo>.\n\nIf your questions or concerns remain unanswered, reach out to us at any time.Speaking to our customers is the best part of our day: hello@vacationtracker.io",
    "bot.message.accessExpired": "Oh no! Your access to Vacation Tracker has expired. Please contact your administrator for more information.",
    "bot.message.accruedDaysInfoForApprover": "ℹ️ This leave request is based on future accrued days off, not the current amount. <@{name}> has {amount} day(s) remaining today. They will accrue {accrued} on {earningDate}.",
    "bot.message.accruedDaysInfoForUser": "ℹ️ Your leave request is based on future accrued days off, not the current amount. You have {amount} day(s) remaining today, and you'll accrue {accrued} on {earningDate}.",
    "bot.message.approveLeave": "Approve Leave",
    "bot.message.approveLeaves.approvedRequest": "✅ You have approved the request. A notification will be sent to <@{userSlackId}> to let them know.",
    "bot.message.approveLeaves.approvedRequest.firstLevel": "✅ You have approved the first level of the request, no further action is required at this stage. The leave request has been forwarded to the second level of approval.",
    "bot.message.approveLeaves.deniedRequest": "❌ You have denied the request. A notification will be sent to <@{userSlackId}> to let them know.",
    "bot.message.approver": "*Approver:*\n<@{approverSlackId}>",
    "bot.message.approver.autoApproved": "*Approver:*\nAuto approved",
    "bot.message.approveToil": "Approve TOIL",
    "bot.message.backToOptions": "Back to options",
    "bot.message.blackoutPeriod": "*Blackout period*: {startDate} - {endDate} \n \n_{message}_",
    "bot.message.couldNotProcessRequest": "Uh oh! We couldn't process your request.",
    "bot.message.date": "*Date*",
    "bot.message.dateEdited": "*Date [Edited]*",
    "bot.message.dates": "*Dates*",
    "bot.message.datesEdited": "*Dates [Edited]*",
    "bot.message.deniedLeave": "Denied Leave",
    "bot.message.deniedLeaveDate": "*Date*\n{leaveDate}",
    "bot.message.deniedLeaveDates": "*Dates*\n{leaveDate}",
    "bot.message.deniedLeaveLeaveType": "*Leave type:*\n{leaveTypeName}",
    "bot.message.deniedLeaveWithReason": "Hello, <@{userSlackId}> has submitted the following leave request:",
    "bot.message.deniedToil": "Denied TOIL",
    "bot.message.error.endDateCantBeInPast": "End date can't be before start, please select the date in the future",
    "bot.message.error.endTimeCantBeBeforeStartTime": "Looks like the start hour you've selected is after the end hour.*\n *Please select a start hour that's before the end hour.",
    "bot.message.error.leaveRequestOverlap": "The dates you've chosen overlap with an existing leave.*\n *Please select a different time period.",
    "bot.message.error.negativeBallanceNotAllowed": "Negative balance is not allowed for this leave type.",
    "bot.message.error.oneHoureIntervalNotAllowed": "The shortest time interval you can request for this leave type is 4 hours (half-day).",
    "bot.message.error.partTimeIsntAllowed": "Part time isn't allowed",
    "bot.message.error.reasonRequired": "Reason field is required.",
    "bot.message.error.requestedMoreThanFullDay": "You have requested more than one full day of leaves in one workday.*\n *Please select a different day.",
    "bot.message.error.startDateCantBeInPast": "Start date can't be in the past, please select the date in the future",
    "bot.message.error.userCannotBeFound": "Selected user cannot be found in your organization.*\n *Please contact your organizations administrator or Vacation Tracker support for more information.",
    "bot.message.error.userEndDateInFuture": "The requested dates are after the users employment end date.*\n *Please contact your organizations administrator or Vacation Tracker support for more information.",
    "bot.message.error.userStartDateInFuture": "The requested dates are before the users employment start date. Please contact your organizations administrator or Vacation Tracker support for more information.",
    "bot.message.error.zeroDaysLeaveRequest": "You have selected a holiday or a non-working day.*\n *Please select a valid date range.",
    "bot.message.feedback.chooseAnOption": "Choose an option...",
    "bot.message.feedback.form": "Feedback form",
    "bot.message.feedback.haveBeenSend": "Feedback has been send",
    "bot.message.feedback.howDoYouLikeOurApp": "How do you like our app?",
    "bot.message.feedback.info": "Give us feedback :heart:",
    "bot.message.feedback.itMakesMeCry": "It makes me cry 😢",
    "bot.message.feedback.itsOk": "It's ok 😐",
    "bot.message.feedback.likeIt": "I like it 😀",
    "bot.message.feedback.loveIt": "I love it 😍",
    "bot.message.feedback.message": "Feedback message",
    "bot.message.feedback.nah": "Nah 🙁",
    "bot.message.feedback.response": "If your feedback requires a response, please enter your email so we can contact you.",
    "bot.message.feedback.thanks": "Thanks for the feedback! You can always get in touch with us using support@vacationtracker.io.",
    "bot.message.feedback.youEmailAddress": "Your email address",
    "bot.message.genericError": "Uh oh! An unexpected error occurred. 😱 If this problem persists, please contact support and give them the following error code: {requestId}.",
    "bot.message.goToTheMyProfileTab": "Go to the My Profile tab",
    "bot.message.helpMessage": "Hello, \nWith Vacation Tracker, you can do one of the following actions:\n- Send **Request leave** in a direct message to request a new leave using the Microsoft Teams bot.\n- Send **Request toil** in a direct message to request a new TOIL using the Microsoft Teams bot.\n- Visit **My Profile** tab to see your personal information, such as your upcoming leaves and holidays, and your leave history.\n- Pin the **Overview** tab to one of your channels to see the team overview, including upcoming leaves and holidays for your team.\n- Visit the [**Vacation Tracker Web Dashboard ⧉**]({dashboardLink}/connect?platform=microsoft&redirect=/app/dashboard).\nFor more help and tutorials, visit our [**Helpdesk ⧉**](https://vacationtracker.crisp.help/en/). You can also schedule a personalized demo of Vacation Tracker at this link: [https://vacationtracker.io/request-a-demo](https://vacationtracker.io/request-a-demo).",
    "bot.message.holidays": "*Holidays:*",
    "bot.message.leaveDate": "*Date:*\n{leaveDate}",
    "bot.message.leaveDateEdited": "*Date [Edited]:*\n{leaveDate}",
    "bot.message.leaveDates": "*Dates:*\n{leaveDate}",
    "bot.message.leaveDatesEdited": "*Dates [Edited]:*\n{leaveDate}",
    "bot.message.leaveDuration1": "*{word} requested for current year:*\n{requestedThisYearNumber}",
    "bot.message.leaveDuration2": "*{word} remaining for current year:*\n{currentYearNumber}",
    "bot.message.leaveDuration2Unlimited": "*{word} remaining for current year:*\nUnlimited",
    "bot.message.leaveDuration3": "*{word} requested for next year:*\n{requestedNextYearNumber}",
    "bot.message.leaveDuration4": "*{word} remaining for next year:*\n{nextYearNumber}",
    "bot.message.leaveDuration4Unlimited": "*{word} remaining for next year:*\nUnlimited",
    "bot.message.leaveRemaining": "Leave Remaining",
    "bot.message.leaveRemainingCurrent": "Leave Remaining (Current Year)",
    "bot.message.leaveRemainingDays": "Leave Days Remaining",
    "bot.message.leaveRemainingDaysCurrent": "Leave Days Remaining (Current Year)",
    "bot.message.leaveRemainingDaysNext": "Leave Days Remaining (Following Year)",
    "bot.message.leaveRemainingHours": "Leave Hours Remaining",
    "bot.message.leaveRemainingHoursNext": "Leave Hours Remaining (Following Year)",
    "bot.message.leaveRemainingNext": "Leave Remaining (Following Year)",
    "bot.message.leaveRequest.FULL-DAY": "{leaveTypeName}",
    "bot.message.leaveRequest.HALF-DAY": "{leaveTypeName} (Half Day)",
    "bot.message.leaveRequest.halfDay": "(Half-Day)",
    "bot.message.leaveRequest.hourly": "(Hourly)",
    "bot.message.leaveRequest.leaveTypeName": "{leaveTypeName}",
    "bot.message.leaveRequest.leaveTypeNamePartTime": "{leaveTypeName} (Hourly)",
    "bot.message.leaveRequest.ONE-HOUR": "{leaveTypeName} (Hourly)",
    "bot.message.leaveRequestCreated.error": "Leave request cannot be submitted",
    "bot.message.leaveRequestCreated.errorBadTime": "❌ Leave request cannot be submitted because it's longer than working hours, or the time interval is invalid.",
    "bot.message.leaveRequestCreated.errorDateAfter": "❌ Leave request cannot be submitted because the start date you've selected is after the end date. Make sure the start date is before the end date, and submit the request again.",
    "bot.message.leaveRequestCreated.errorDateInThePast": "❌ Leave request cannot be submitted because the start date you've selected is in the past. Make sure the dates you select are in the future, and submit the request again.",
    "bot.message.leaveRequestCreated.errorDateNotSelected": "❌ Leave request cannot be submitted because you haven't selected the end date, or the date is invalid.",
    "bot.message.leaveRequestCreated.errorNoStartDate": "❌ Leave request cannot be submitted because you haven't selected the start date, or the date is invalid.",
    "bot.message.leaveRequestCreated.errorNoStartTime": "❌ Leave request cannot be submitted because you haven't selected the start time, or the time is invalid.",
    "bot.message.leaveRequestCreated.errorReasonRequired": "❌ Leave request cannot be submitted, reason is required.",
    "bot.message.leaveRequested": "Leave Requested",
    "bot.message.leaveRequestedCurrent": "Leave Requested (Current Year)",
    "bot.message.leaveRequestedDays": "Leave Days Requested",
    "bot.message.leaveRequestedDaysCurrent": "Leave Days Requested (Current Year)",
    "bot.message.leaveRequestedDaysNext": "Leave Days Requested (Following Year)",
    "bot.message.leaveRequestedHours": "Leave Hours Requested",
    "bot.message.leaveRequestedHoursCurrent": "Leave Hours Requested (Current Year)",
    "bot.message.leaveRequestedHoursNext": "Leave Hours Requested (Following Year)",
    "bot.message.leaveRequestedNext": "Leave Requested (Following Year)",
    "bot.message.leaveRequestNext.endDate": "End Date:",
    "bot.message.leaveRequestNext.info": "Great! 🐨 Select the time period, and submit the request.",
    "bot.message.leaveRequestNext.isHalfDay": "{leaveTypeName} (Half Day)",
    "bot.message.leaveRequestNext.leaveTime": "Leave Time:",
    "bot.message.leaveRequestNext.reasonOptional": "Reason (Optional)",
    "bot.message.leaveRequestNext.reasonRequired": "Reason (Required)",
    "bot.message.leaveRequestNext.startDate": "Start Date:",
    "bot.message.leaveRequestNext.title": "Request leave",
    "bot.message.leaves": "*Leaves:*",
    "bot.message.leavesHistory": "Your leaves history:",
    "bot.message.leavesHistory.leaveMessageFullDay": "- Leave (*{leaveTypeName}*) from {start} to {end} *[{leaveStatus}]*\n",
    "bot.message.leavesHistory.leaveMessageOn": "- Leave (*{leaveTypeName}*) on {start} *[{leaveStatus}]*\n",
    "bot.message.leavesHistory.leaveMessageOnPartDay": "- Leave (*{leaveTypeName}*) on {start} {timeRange} *[{leaveStatus}]*\n",
    "bot.message.leavesHistoryNot": "Hm, it seems that you haven't had any leaves yet.",
    "bot.message.leaveTypeName": "*Leave type:*\n{leaveTypeName}",
    "bot.message.leaveTypeWithHalfDay": "*Leave type:*\n{leaveTypeName} (half-day)",
    "bot.message.manageLeaves.cancelRequest": "Cancel Request",
    "bot.message.manageLeaves.confirmCancellation": "Please confirm you want to cancel your leave request",
    "bot.message.manageLeaves.summary": "Here's the summary of your upcoming leaves and pending requests.",
    "bot.message.manageLeaves.summaryNoLeaves": "You have no upcoming scheduled leaves",
    "bot.message.manageLeaves.upcomingLeaves": "*You have {upcomingLeaves} confirmed request:*",
    "bot.message.manageLeaves.upcomingLeavesPlural": "*You have {upcomingLeaves} confirmed requests:*",
    "bot.message.manageLeaves.viewMoreOnDashboard": "You have {remaining} more leave requests. View all leaves on the dashboard 👀",
    "bot.message.manageLeaves.waitingLeaves": "*You have {waitingLeaves} pending request:*",
    "bot.message.manageLeaves.waitingLeavesPlural": "*You have {waitingLeaves} pending requests:*",
    "bot.message.membersAway": "Members away",
    "bot.message.membersAway.holiday": "- *{holidayLocationName}* will be off for *{holidayName}* on {date}.\n",
    "bot.message.membersAway.leaveMessage": "- *{user}* is on leave{leaveTypeName} today {partDay}\n",
    "bot.message.membersAway.leaveMessage.fullDay": "- *{user}* is on leave{leaveTypeName} from {startDate} to {endDate}\n",
    "bot.message.membersAway.leaveMessageWasWillBe": "- *{user}* {verb} on leave{leaveTypeName} on {startDate} {partDay}\n",
    "bot.message.membersAway.leaveMessageWasWillBe.fullDay": "- *{user}* {verb} on leave{leaveTypeName} from {startDate} to {endDate}\n",
    "bot.message.membersAway.MEMBERS_AWAY_MONTH": "Team members who are away this month.",
    "bot.message.membersAway.MEMBERS_AWAY_NEXT_MONTH": "Team members who are away next month.",
    "bot.message.membersAway.MEMBERS_AWAY_NEXT_WEEK": "Team members who will be away next week.",
    "bot.message.membersAway.MEMBERS_AWAY_TODAY": "Team members who are away today.",
    "bot.message.membersAway.MEMBERS_AWAY_WEEK": "Team members who are away this week.",
    "bot.message.membersAway.multiHoliday": "- *{holidayLocationName}* will be off for *{holidayName}* from {start} to {end}.\n",
    "bot.message.membersAway.noLeaves": "There are currently no scheduled leaves for this time period in your team 😎.",
    "bot.message.memersAway.noLeaves": "There are currently no scheduled leaves for this time period in your team 😎.",
    "bot.message.menageLeaves": "Manage Leaves",
    "bot.message.moreOptions": "Hi <@{username}>!\nWhat can I help you with?",
    "bot.message.moreOptions.about": "More about me :koala:",
    "bot.message.moreOptions.leaveHistoryTitle": "Leave History",
    "bot.message.moreOptions.leavesAndHolidaysTitle": "Your leaves and holidays:",
    "bot.message.moreOptions.membersAway": "See which team members are away:",
    "bot.message.moreOptions.menageLeavesTitle": "Manage Leaves",
    "bot.message.moreOptions.showHolidaysTitle": "Show Holidays",
    "bot.message.myStatus.currentYear": "_Current year, until {yearEnd}_",
    "bot.message.myStatus.leaveTypeStatusEarned": "*{leaveTypeName}*\nRemaining: {current}\nUsed: {used}\nTotal: {earned}",
    "bot.message.myStatus.leaveTypeStatusEarnedDays": "*{leaveTypeName}*\nRemaining days: {current}\nUsed days: {used}\nTotal days: {earned}",
    "bot.message.myStatus.leaveTypeStatusEarnedHours": "*{leaveTypeName}*\nRemaining hours: {current}\nUsed hours: {used}\nTotal hours: {earned}",
    "bot.message.myStatus.leaveTypeStatusUnlimited": "*{leaveTypeName}*\nRemaining days: Unlimited\nUsed days: {usedDays}\nTotal days: Unlimited",
    "bot.message.myStatus.nextYear": "_Next year, from {startYear} until {endYear}_",
    "bot.message.myStatus.summary": "Here's the summary of your leaves for the current and the next year.",
    "bot.message.noLeavePolicies": "It's seems your Location has no leave policies assigned. 🤔 Contact your Administrator for more information. ",
    "bot.message.notification.overAllowedLimitForCurrentAndFollowingYear": "The user is requesting {leaveDurationTotal} leave days, which is {overLimitCurrentYear} day over the limit the user has for the current year, and {overLimitNextYear} day over the limit for the following year.",
    "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearDays": "The user is requesting {leaveDurationTotal} {leaveDurationTotal, plural, =1 {day} other {days}}, which is {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {day} other {days}} over the limit the user has for the current year, and {overLimitNextYear} {overLimitNextYear, plural, =1 {day} other {days}} over the limit for the following year.",
    "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearHours": "The user is requesting {leaveDurationTotal} {leaveDurationTotal, plural, =1 {hour} other {hours}}, which is {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {hour} other {hours}} over the limit the user has for the current year, and {overLimitNextYear} {overLimitNextYear, plural, =1 {hour} other {hours}} over the limit for the following year.",
    "bot.message.notification.overAllowedLimitForCurrentYear": "The user is requesting {leaveDurationCurrentYear} leave day, which is {overLimit} day over the allowed limit this user has for the current year.",
    "bot.message.notification.overAllowedLimitForCurrentYearDays": "The user is requesting {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {day} other {days}}, which is {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {day} other {days}} over the allowed limit this user has for the current year.",
    "bot.message.notification.overAllowedLimitForCurrentYearHours": "The user is requesting {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {hour} other {hours}}, which is {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {hour} other {hours}} over the allowed limit this user has for the current year.",
    "bot.message.notification.overAllowedLimitForCurrentYearPlural": "The user is requesting {leaveDurationCurrentYear} leave days, which is {overLimit} day over the allowed limit this user has for the current year.",
    "bot.message.notification.overAllowedLimitForFollowingYear": "The user is requesting {leaveDurationNextYear} leave day, which is {overLimit} day over the allowed limit this user has for the next year.",
    "bot.message.notification.overAllowedLimitForFollowingYearDays": "The user is requesting {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {day} other {days}}, which is {overLimitNextYear} {overLimitNextYear, plural, =1 {day} other {days}} over the allowed limit this user has for the next year.",
    "bot.message.notification.overAllowedLimitForFollowingYearHours": "The user is requesting {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {hour} other {hours}}, which is {overLimitNextYear} {overLimitNextYear, plural, =1 {hour} other {hours}} over the allowed limit this user has for the next year.",
    "bot.message.notification.overAllowedLimitForFollowingYearPlural": "The user is requesting {leaveDurationNextYear} leave days, which is {overLimit} day over the allowed limit this user has for the next year.",
    "bot.message.notSignedUpError": "It seems that you are not signed up for Vacation Tracker. Please navigate to [{link}]({utmLink}) and create an account first. It'll take less than a minute!",
    "bot.message.notSignedUpError.slack": "It seems that you are not signed up for Vacation Tracker. Please click the button below to create an account. It'll take less than a minute!",
    "bot.message.on": "On",
    "bot.message.openTheWebDashboard": "Open the Web Dashboard",
    "bot.message.partDay": "from {partDayStartHour} - {partDayEndHour}",
    "bot.message.probationPeriod": "*Probation period*: \nYour probation period lasts until {date} \n \nReason: _{message}_",
    "bot.message.reminder": "Hey team! A friendly reminder from Vacation Tracker bot",
    "bot.message.reminderSnoozed": "Request reminders snoozed",
    "bot.message.requestLeave": "Please select one of the available leave types from the list below, and select the start and end dates of your leave. When you click the Send request button, your request will be sent for approval.",
    "bot.message.requestLeave.error": "*Error: {errorMessage}*",
    "bot.message.requestLeave.errorAutomation": "{errorMessage}",
    "bot.message.requestLeave.initialHelp": "I'm here to help you submit a leave request. 🐨 Please, select a Leave Type, then click Next.",
    "bot.message.requestLeave.leaveDuration1": "That`s 1 day of leave.",
    "bot.message.requestLeave.leaveDurationHalfDay": "That's a half-day of leave",
    "bot.message.requestLeave.leaveDurationYear1": "That's {year1Value} of leave.",
    "bot.message.requestLeave.leaveDurationYear1And2": "That's {year1Value} of leave for the current year and {year2Value} of leave for the next year.",
    "bot.message.requestLeave.leaveDurationYear1And2Days": "That's {year1Value} {year1Value, plural, =1 {day} other {days}} of leave for the current year and {year2Value} {year2Value, plural, =1 {day} other {days}} of leave for the next year.",
    "bot.message.requestLeave.leaveDurationYear1And2Hours": "That's {year1Value} {year1Value, plural, =1 {hour} other {hours}} of leave for the current year and {year2Value} {year2Value, plural, =1 {hour} other {hours}} of leave for the next year.",
    "bot.message.requestLeave.leaveDurationYear1Days": "That's {year1Value} {year1Value, plural, =1 {day} other {days}} of leave.",
    "bot.message.requestLeave.leaveDurationYear1Hours": "That's {year1Value} {year1Value, plural, =1 {hour} other {hours}} of leave.",
    "bot.message.requestLeave.leaveDurationYear1PluralYear2": "That's {year1Days} days of leave for the current year and {year2Days} day of leave for the next year.",
    "bot.message.requestLeave.leaveDurationYear1Year2AllPlural": "That's {year1Days} days of leave for the current year and {year2Days} days of leave for the next year.",
    "bot.message.requestLeave.leaveDurationYear2": "That's {year2Value} of leave for the next year.",
    "bot.message.requestLeave.leaveDurationYear2Days": "That's {year2Value} {year2Value, plural, =1 {day} other {days}} of leave for the next year.",
    "bot.message.requestLeave.leaveDurationYear2Hours": "That's {year2Value} {year2Value, plural, =1 {hour} other {hours}} of leave for the next year.",
    "bot.message.requestLeave.leaveDurationYear2Plural": "That's {year2Days} days of leave for the next year.",
    "bot.message.requestLeave.leaveDurationYear2PluralYear1": "That's {year1Days} day of leave for the current year and {year2Days} days of leave for the next year",
    "bot.message.requestLeave.selectCalendarStatus": "Select calendar status",
    "bot.message.requestLeave.selectCalendarStatus.info": "*Calendar status*\nShow in your calendar as",
    "bot.message.requestLeave.selectDate.from": "*Start date*\nPlease select a start date",
    "bot.message.requestLeave.selectDate.info": "*Start date*\nPlease select a start date",
    "bot.message.requestLeave.selectDate.oneDay": "*Date*\nPlease select a date",
    "bot.message.requestLeave.selectDate.to": "*End date*\nPlease select an end date",
    "bot.message.requestLeave.selectLeaveType": "Select leave type",
    "bot.message.requestLeave.selectLeaveType.info": "*Leave type*\nPlease select leave type",
    "bot.message.requestLeave.selectTimeFormat": "*AM/PM*",
    "bot.message.requestLeave.selectTimeRange": "Select time range",
    "bot.message.requestLeave.selectTimeRange.end": "*End date*\nPlease select an end date",
    "bot.message.requestLeave.selectTimeRange.from": "*Start Hour*",
    "bot.message.requestLeave.selectTimeRange.info": "*Time range*\n Please select the time range",
    "bot.message.requestLeave.selectTimeRange.range": "*Time Range*",
    "bot.message.requestLeave.selectTimeRange.to": "*End Hour*",
    "bot.message.requestLeave.submitted.end": ":tada: *{message}*.\n Until next time!",
    "bot.message.requestLeave.submitted.letTeamKnow": "P.S. Let my team know if I was helpful!",
    "bot.message.requestLeave.submitted.withApproval": "The dates you requested have been sent for approval to your manager",
    "bot.message.requestLeave.submitted.withoutApproval": "The dates you've requested have been automatically approved",
    "bot.message.requestLeaveModal.requestALeave": "Request a leave",
    "bot.message.requestLeaveModal.requestingLeave": "Requesting leave",
    "bot.message.requestLeaveModal.sendFeedback": "Send feedback",
    "bot.message.requestLeaveModal.sendRequest": "Send request",
    "bot.message.requestToil.initialHelp": "I'm here to help you submit a TOIL request. 🐨 Please, select a Leave Type, then click Next.",
    "bot.message.selectADate": "Select a date",
    "bot.message.selectSubstituteApprover": "*Select Substitute Approver*\nSelect a person to manage your incoming leave requests while you are away.",
    "bot.message.showHolidays.holidaysHalfDay": "- *{holidayLocationName}* will be off for *{holidayName}* on {date} (Half-Day).\n",
    "bot.message.showHolidays.noUpcomingHolidays": "There are no upcoming holidays in your organization or your administrator has not set any holidays yet.",
    "bot.message.showHolidays.one": "- *{holidayLocationName}* will be off for *{holidayName}* from {startDate} to {endDate}.\n",
    "bot.message.showHolidays.two": "- *{holidayLocationName}* will be off for *{holidayName}* on {date}.\n",
    "bot.message.showHolidays.upcomingHolidays": "Upcoming holidays",
    "bot.message.slack.userChanged": "The user has changed this request",
    "bot.message.slashCommand.helpCommand1": "*Here's a little bit more information about me:*",
    "bot.message.slashCommand.helpCommand2": "Trusted by 1000+ organizations around the world, *Vacation Tracker allows your team to effortlessly track who's in and out of the office at all times.*",
    "bot.message.slashCommand.helpCommand3": "Learn more about our story on our <https://vacationtracker.io/about|About page>.",
    "bot.message.slashCommand.helpCommand4": "For step-by-step guides, visit our <https://vacationtracker.crisp.help/en/|Helpdesk>.",
    "bot.message.slashCommand.helpCommand5": "To log in to the online Dashboard, visit <{dashboardUrl}/connect?platform=slack>.",
    "bot.message.slashCommand.helpCommand6": "And if you ever need us along the way, we're just one email away at hello@vacationtracker.io.",
    "bot.message.slashCommand.noPermissions": "Sorry to be the bearer of bad news, but it seems you don't have permission to access Vacation Tracker. 😟 Please contact your Administrator.",
    "bot.message.slashCommand.title": "Hi <@{userId}>! Looking to take some time off? Let me help!",
    "bot.message.sorryLetsSpokePriveate": "Hmm, let's speak in private. 🤫",
    "bot.message.sorryYouAreNotActive": "Sorry, your account is inactive",
    "bot.message.sorryYouAreNotExist": "Sorry, your account was not found",
    "bot.message.submittedLeaveRequest": "Hello, <@{userSlackId}> has submitted the following leave request:",
    "bot.message.submittedLeaveRequestReminder": "Hello, just a reminder that <@{name}> has submitted a request that requires your attention.",
    "bot.message.submittedLeaveRequestReminderExpiration": "⏰ Please note, this request will expire in {days} day(s). This is reminder {reminderNo} of 3.",
    "bot.message.substituteApproverPlaceholder": "🔍 None",
    "bot.message.unableToRequestLeave": "Unable to request leave.",
    "bot.message.unknownCommand": "I am sorry, I don't understand this command.\nType **help** to see the list of available commands.",
    "bot.microsoft.whatsNewInfoMessages": "This is message is an announcement from **Vacation Tracker**. You are receiving it because you are an administrator or an approver.",
    "bot.notification.accruedDaysInfoForApprover": "ℹ️ This leave request is based on future accrued days off, not the current amount. @{name} has {amount} day(s) remaining today. They will accrue {accrued} on {earningDate}.",
    "bot.notification.accruedDaysInfoForUser": "ℹ️ Your leave request is based on future accrued days off, not the current amount. You have {amount} day(s) remaining today, and you'll accrue {accrued} on {earningDate}.",
    "bot.notification.adminToApprovers": "You have been appointed as the new Approver for {teamName}, as the previous Approver`s account has been deactivated.",
    "bot.notification.approverCard.approved": "✅ @{approverName} has approved<firstLevelOfApproval> the first level of</firstLevelOfApproval> the request, no further action is required<firstLevelOfApproval> at this stage. The leave request has been forwarded to the second level of approval</firstLevelOfApproval>.",
    "bot.notification.approverCard.autoApproved": "The request has been automatically approved, no further action is required. ",
    "bot.notification.approverCard.cancelled": "⚠️ Looks like {requesterName} has changed their mind. The request has been cancelled.",
    "bot.notification.approverCard.cancelledLeave": "Hello, {requesterName} has cancelled their leave.",
    "bot.notification.approverCard.cancelledTitle": "⚠️ This leave has been cancelled by an approver.",
    "bot.notification.approverCard.denied": "❌ @{approverName} has denied the request, no further action is required.",
    "bot.notification.approverCard.deniedEdited": "❌ @{approverName} has denied the edited request, no further action is required.",
    "bot.notification.approverCard.editedByApprover": "@{approverName} has edited a leave request submitted by @{requesterName}",
    "bot.notification.approverCard.editedByApproverForUser": "@{approverName} has edited a leave request for @{requesterName}.",
    "bot.notification.approverCard.info": "Add a reason for this approval or denial",
    "bot.notification.approverCard.infoSecondLevel": "You are the second level of approval for this request. It has already been approved by @{approverName}<approvedBySubstituteApprover> (Substitute Approver)</approvedBySubstituteApprover>.",
    "bot.notification.approverCard.notActive": "⚠️ This leave request is no longer active.",
    "bot.notification.assignedAsApproverCard.infoMultilevel.level1": "You have been assigned as a first level approver for the {departmentName} department. Click the 'Approver Guide' button below to learn what approvers can do.",
    "bot.notification.assignedAsApproverCard.infoMultilevel.level1and2": "You have been assigned as first and second level approver for the {departmentName} department. Click the 'Approver Guide' button below to learn what approvers can do.",
    "bot.notification.assignedAsApproverCard.infoMultilevel.level2": "You have been assigned as a second level approver for the {departmentName} department. Click the 'Approver Guide' button below to learn what approvers can do.",
    "bot.notification.assignedAsApproverCard.infoSinglelevel": "You have been assigned as an approver for the {departmentName} department. Click the 'Approver Guide' button below to learn what approvers can do.",
    "bot.notification.assignedAsApproverCard.openLeaveRequests": "There are {numberOfOpenLeaveRequests} open leave {numberOfOpenLeaveRequests, plural, =1 {request} other {requests}} that require your attention. Click the 'Open Dashboard' button below to review them. Don't worry, we'll send notification for new leave requests as soon as they are submitted.",
    "bot.notification.assignedAsApproverCard.title": "You have been assigned as an approver",
    "bot.notification.assignedAsApproverCard.tutorialButton": "Approver Guide",
    "bot.notification.changeOutOfOfficeMessage": "Edit template",
    "bot.notification.daily.info": "It's time for your daily update! 🐨 Here are the upcoming leaves:",
    "bot.notification.daily.noLeaves.isNotForToday": "There are no scheduled leaves on {dayName}",
    "bot.notification.daily.noLeaves.isToday": "There are no scheduled leaves today",
    "bot.notification.holiday.plural": "- **{locationNames}** locations will be away for **{holidayName}** {holidayPeriodText}",
    "bot.notification.holiday.singular": "- **{locationNames}** location will be away for **{holidayName}** {holidayPeriodText}",
    "bot.notification.infoFirstLevel": "You are the first level of approval for this request.",
    "bot.notification.leave.info.isInTheFuture": "will be on leave{leaveTypeText} {leavePeriodText}",
    "bot.notification.leave.info.isInThePast": "will be on leave{leaveTypeText} {leavePeriodText}",
    "bot.notification.leave.info.isToday": "is on leave{leaveTypeText} {leavePeriodText}",
    "bot.notification.leaveDates.partDay": "{start} from {partDayStartHour} - {partDayEndHour}",
    "bot.notification.leavePeriod.fullDay": "on {day}",
    "bot.notification.leavePeriod.halfDay": "on {day} (Half-Day)",
    "bot.notification.leavePeriod.moreDays": "from {start} through {end}",
    "bot.notification.leavePeriod.partDay": "on {start} from {partDayStartHour} - {partDayEndHour}",
    "bot.notification.leavePolicyInfo": "*Please note: this user or this leave type has a 0 quota set for the requested leave.*",
    "bot.notification.leaveRequestError.general": "Error with a leave request",
    "bot.notification.leaveRequestError.notApprover": "The approval flow for your department has been changed. Please contact your admin if you have any additional questions.",
    "bot.notification.leaveRequestError.notOpen": "Leave request has been {leaveRequestStatus}",
    "bot.notification.leaveRequestError.notOpenIsSameAppover": "Leave Request has been {leaveRequestStatus}",
    "bot.notification.leaveRequestExpired": "Your request has expired",
    "bot.notification.leaveRequestExpiredGeneral": "Request has expired",
    "bot.notification.leaveTypeText.isPartDay": "({leaveTypeName} - Half-Day)",
    "bot.notification.oooDefaultMessage": "Hello,\n\nThank you for your message. I am currently out of the office and unavailable to respond to emails until {firstWorkingDay}.\n\nI will do my best to respond to your email promptly upon my return.\n\nThank you for your understanding.\n\nBest regards",
    "bot.notification.processingApproval": "Processing approval...",
    "bot.notification.processingDenial": "Processing denial...",
    "bot.notification.processingResend": "Resending leave request...",
    "bot.notification.processingSnooze": "Snoozing reminders...",
    "bot.notification.reconnectCalendar": "Reconnect",
    "bot.notification.reminderSent": "Leave request reminder sent",
    "bot.notification.reminderSentToil": "TOIL request reminder sent",
    "bot.notification.requesterCard.addedLeave": "Leave was created for you",
    "bot.notification.requesterCard.addedLeaveBy": "Added by {approverName}",
    "bot.notification.requesterCard.addedLeaveToil": "TOIL was created for you",
    "bot.notification.requesterCard.approvedTitle": "Your Leave Request was Approved",
    "bot.notification.requesterCard.approvedTitleToil": "Your TOIL Request was Approved",
    "bot.notification.requesterCard.approverInfo": "Approved by {approverName}",
    "bot.notification.requesterCard.autoApprovedInfo": "The request has been automatically approved.",
    "bot.notification.requesterCard.blackoutPeriod": "Period: {startDate} - {endDate}",
    "bot.notification.requesterCard.blackoutPeriodReason": "Reason: {message}",
    "bot.notification.requesterCard.blackoutPeriodTitle": "Blackout Period",
    "bot.notification.requesterCard.cancelled": "⚠️ Cancelled",
    "bot.notification.requesterCard.cancelledEdited": "⚠️ Changes have been made to the original request.",
    "bot.notification.requesterCard.cancelledEditedByRequester": "@{requesterName} has edited this leave request.",
    "bot.notification.requesterCard.cancelledTitle": "⚠️ Your leave has been cancelled by an approver.",
    "bot.notification.requesterCard.deniedEditedDescription": "Your edited leave request has been denied, however, your original request remains active.",
    "bot.notification.requesterCard.deniedEditedTitle": "Leave request edit denied.",
    "bot.notification.requesterCard.deniedOpenDescription": "Both your original leave request and the edited leave request have been denied.",
    "bot.notification.requesterCard.deniedOpenTitle": "Leave request denied",
    "bot.notification.requesterCard.deniedTitle": "Your Leave Request was Denied",
    "bot.notification.requesterCard.edited": "@{requesterName} has edited their previously approved request and is re-submitting for approval:",
    "bot.notification.requesterCard.editedOpen": "@{requesterName} has edited and re-submitted their leave request.",
    "bot.notification.requesterCard.maximumUsersAwayTitle": "Maximum users away",
    "bot.notification.requesterCard.moreDays": "❌ You requested more days than available.",
    "bot.notification.requesterCard.moreThanFullDay": "❌ Leave request cannot be submitted because you have requested more than one full day of leaves in one workday.",
    "bot.notification.requesterCard.noWorkingDays": "❌ Leave request cannot be submitted because you haven't included any working days. Make sure you review your team's working days before submitting the request.",
    "bot.notification.requesterCard.originalLeaveRequest": "Original leave request:",
    "bot.notification.requesterCard.overlap": "❌ Leave request cannot be submitted because the dates you have requested overlap with the dates of an existing leave request.",
    "bot.notification.requesterCard.probationPeriod": "Your probation period lasts until {date}",
    "bot.notification.requesterCard.probationPeriodReason": "Reason: {message}",
    "bot.notification.requesterCard.probationPeriodTitle": "Probation Period",
    "bot.notification.requesterCard.requestInfo": "Your request is on its way to the approver. Until next time! 🐨",
    "bot.notification.requesterCard.requestPossibleActions": "To edit or cancel the request, visit Dashboard",
    "bot.notification.requesterCardapp.tryAnotherDates": "Try another dates",
    "bot.notification.requesterInfo": "Hello, @{userName} has submitted a request for you to review.",
    "bot.notification.requesterInfoAutoApproved": "Hello, @{userName} has submitted a request.",
    "bot.notification.requesterInfoEdited": "@{userName} has edited and re-submitted their request.",
    "bot.notification.requesterInfoEditedByApprover": "@{approverName} has edited a request submitted by @{userName}",
    "bot.notification.requesterInfoEditedForUser": "Your request has been edited.",
    "bot.notification.requesterInfoEditedForUserByApprover": "Approver @{approverName} has made changes to your request.",
    "bot.notification.requesterInfoEditedOpen": "@{userName} has edited and re-submitted their request.",
    "bot.notification.setOutOfOffice": "Your {leaveTypeName} leave is scheduled to begin in less than {days} {days, plural, =1 {day} other {days}} on {leavePeriodStart}. Would you like to set an automatic out-of-office message to be sent during your time away?",
    "bot.notification.setOutOfOfficeButton": "Set OOO message",
    "bot.notification.setOutOfOfficeFallbackText": "Set Out of office for your {leaveTypeName}",
    "bot.notification.slack.additional": "... and more.\nAdditional leaves could not be displayed due to size limit. Please open Dashboard for a complete overview.",
    "bot.notification.slack.approved": "✅ <@{approverName}> has approved<firstLevelOfApproval> the first level of</firstLevelOfApproval> the request, no further action is required<firstLevelOfApproval> at this stage. The leave request has been forwarded to the second level of approval</firstLevelOfApproval>.",
    "bot.notification.slack.approverChanged": "Hello, approver <@{approverSlackId}> changed a leave request.",
    "bot.notification.slack.approverChangedDatesOfLeaveReuquest": "The approver <@{approverSlackId}> has changed the dates of the request",
    "bot.notification.slack.approverChangedLeaveReqest": "Hello, approver <@{approverSlackId}> has changed the following leave request for <@{userSlackId}>:",
    "bot.notification.slack.approverChangedYour": "Approver <@{approverSlackId}> changed your leave request.",
    "bot.notification.slack.approverHasDeniedThisRequest": "<@{approverSlackId}> has denied this request, no further action is required",
    "bot.notification.slack.calendar": ":calendar: |  *{dayName}*  | :calendar: ",
    "bot.notification.slack.cancelled": "Hello, <@{userName}> has cancelled their leave.",
    "bot.notification.slack.cancelledByApprover": "Your leave has been cancelled by an approver",
    "bot.notification.slack.cancelledInfo": "This leave has been cancelled by an approver",
    "bot.notification.slack.created": "A leave was created for you by an approver",
    "bot.notification.slack.holiday.plural": "- *{locationNames}* locations will be away for *{holidayName}* {holidayPeriodText}",
    "bot.notification.slack.holiday.singular": "- *{locationNames}* location will be away for *{holidayName}* {holidayPeriodText}",
    "bot.notification.slack.holidays": ":airplane: *Holidays: *",
    "bot.notification.slack.infoSecondLevel": "You are the second level of approval for this request. It has already been approved by <@{approverName}><approvedBySubstituteApprover> (Substitute Approver)</approvedBySubstituteApprover>.",
    "bot.notification.slack.leave.info.isInTheFuture": "- *{userName}* will be on leave{leaveTypeText} {leavePeriodText}",
    "bot.notification.slack.leave.info.isInThePast": "- *{userName}* was on leave{leaveTypeText} {leavePeriodText}",
    "bot.notification.slack.leave.info.isToday": "- *{userName}* is on leave{leaveTypeText} {leavePeriodText}",
    "bot.notification.slack.leaveRequestCreated": "Hello, <@{userName}> has submitted a leave request.",
    "bot.notification.slack.leaveRequestInfo": "Hello, <@{userName}> please confirm leave request info.",
    "bot.notification.slack.leaveRequestInfoDateFrom": "Start date: {date}",
    "bot.notification.slack.leaveRequestInfoDateTo": "End date: {date}",
    "bot.notification.slack.leaveRequestInfoError": "We couldn't suggest leave from the provided data",
    "bot.notification.slack.leaveRequestInfoLeaveType": "Leave type: {leaveType}",
    "bot.notification.slack.leaveRequestInfoReason": "Reason: {reason}",
    "bot.notification.slack.leaveRequestInfoToil": "Hello, <@{userName}> please confirm TOIL request info.",
    "bot.notification.slack.leaveRequestSuggestionCancelled": "Canceled leave request form ❌",
    "bot.notification.slack.leaveRequestSuggestionCancelledToil": "Canceled TOIL request form ❌",
    "bot.notification.slack.leaves": ":loud_sound: *Leaves: *",
    "bot.notification.slack.reason": "*Reason*\n{reason}",
    "bot.notification.slack.reasonEdited": "*Reason [Edited] *\n{reason}",
    "bot.notification.slack.requestedBy": "*Requested by:*\n<@{userSlackId}>",
    "bot.notification.slack.statusApproved": "*Status:*\n✅ Approved",
    "bot.notification.slack.statusAutoApproved": "*Status*\nAuto approved",
    "bot.notification.slack.statusDenied": "*Status*\n❌ Denied",
    "bot.notification.slack.statusInfo": "👋 We can now set your Slack status automatically for this leave type! Click the Enable button to allow us to update your status when your leave starts. We`ll clear it when it ends.",
    "bot.notification.slack.thisLeaveHasBeenCancelledByAppover": "This leave has been cancelled by an approver. See below for further information.",
    "bot.notification.slack.title": "Notification: \"_{title}_\"",
    "bot.notification.slack.userCancelledLeaveRequest": "Looks like <@{userSlackId}> has changed their mind. The request has been cancelled.",
    "bot.notification.slack.warning": ":warning: *Warning: {infoMessage}*",
    "bot.notification.slack.yourLeaveHasBeenCancelledByAppover": "Your leave has been cancelled by an approver. See below for further information.",
    "bot.notification.slack.yourLeaveRequestApproved": "Your request has been approved",
    "bot.notification.slack.yourRequestHasBeenDenied": "Your request has been denied",
    "bot.notification.snoozeOutOfOfficeButton": "Don't ask me again",
    "bot.notification.snoozeOutOfOfficeMessage": "We will not ask you any more to set Out of office for {leaveTypeName}. You can always change this setting on the Vacation Tracker Web Dashboard - Account Settings page",
    "bot.notification.submittedLeaveRequestReminder": "Hello, just a reminder that @{name} has submitted a request that requires your attention.",
    "bot.notification.substituteApproverDeleted": "Your role as Substitute Approver for dates {startDate} - {endDate} has been removed.",
    "bot.notification.substituteApproverSelected": "You have been selected by {creatorName} as a substitute approver for department(s) {teamNames} on behalf of {approverName} during their absence. As the substitute approver, you will be responsible for reviewing and acting on leave requests submitted to {approverName} from {startDate} to {endDate}.",
    "bot.notification.substituteApproverUpdated": "Your period as a Substitute Approver is updated from {startDate} to {endDate}.",
    "bot.notification.title": "Notification: {title}",
    "bot.notification.weekly.current.info": "Hello!\n 🐨 I'm here to update you on scheduled leaves for the current week ({start} - {end})",
    "bot.notification.weekly.next.info": "Hello!\n 🐨 I'm here to update you on scheduled leaves for the next week ({start} - {end})",
    "bot.notification.weekly.noHolidays": "There are no holidays for the week.",
    "bot.notification.weekly.noLeaves": "There are no scheduled leaves for the week.",
    "bot.slack.whatsNewInfoMessages": "This is message is an announcement from *Vacation Tracker*. You are receiving it because you are an administrator or an approver.",
    "bot.slackFuckedUpAgain": "Slack has been fixing something again, please contact our support to fix Slack bugs.",
    "bot.somethingNewFromVacationTracker": "Something new from Vacation Tracker",
    "bot.welcomeMessage.furtherAssistance": "Feeling overwhelmed with all this information? Don't be! You don't need to memorize all of it. Just type in 'Help' on our chat, and our bot will send this to you. For further assistance, feel free to chat with our Customer Support team through our website or visit our Helpdesk.",
    "bot.welcomeMessage.goToMyProfile": "Go to My Profile tab",
    "bot.welcomeMessage.greeting": "Greetings {name}, I am the Vacation Tracker Bot! I'm here to help you schedule leaves through Microsoft Teams, as well as keep everyone updated on who's in and out of the office. Without further ado, let's start tracking those leaves!",
    "bot.welcomeMessage.happyVacationing": "Happy Vacationing 🌴",
    "bot.welcomeMessage.howToAccessTabs": "How to access Tabs",
    "bot.welcomeMessage.noCompany": "Hello, \n\n Thank you for installing the Vacation Tracker bot and tab in Microsoft Teams. As the last step, please go to our website to complete the registration and to configure your organization's settings: [{dashboardLink}]({utmDashboardLink})",
    "bot.welcomeMessage.noCompanyInfo": "If you have any questions, feel free to start a chat with our customer support team on our website or visit our help desk.",
    "bot.welcomeMessage.openWebDashboardInfo": "The same information is also accessible via our web dashboard. Just click on the 'Open Web Dashboard' button below and log in with your Microsoft account to access it.",
    "bot.welcomeMessage.requestLeaveInfo": "To begin, simply send me a direct message with 'Request leave' or watch this short tutorial on Vacation Tracker:",
    "bot.welcomeMessage.restInfo": "Aside from the bot, you can also send leave requests, check your leave quotas, view team calendars, and more from Microsoft Teams by using tabs:",
    "bot.welcomeMessage.title": "Hello from Vacation Tracker",
    "botUpdatePermissions.thanks": "Thanks! Everything is set up. You can close the dashboard.",
    "button.no": "No",
    "button.yes": "Yes",
    "calendar.all": "All",
    "calendar.calendar": "Calendar",
    "calendar.filterByLocation": "Filter by Location:",
    "calendar.filterByTeam": "Filter by Department:",
    "calendar.forAllUsers": "For all users",
    "calendar.getCalendarLink": "Get calendar link",
    "calendar.halfdayTags": "Leaves and Holidays shorter than a full day are represented by striped blocks.",
    "calendar.halfdayTagsShort": "Leaves shorter than a day",
    "calendar.labels": "Label(s)",
    "calendar.listView": "List view",
    "calendar.location": "Location(s)",
    "calendar.monthView": "Month view",
    "calendar.sync.calendarAlreadyConnected": "This calendar is already connected",
    "calendar.sync.calendarNotFound": "Unable to find the calendar. It may have been deleted or is no longer available. Please review your calendar settings.",
    "calendar.sync.canNotUpdateSharedCalendar": "You connected the {calendarName} {provider} calendar. Changing the calendar is not allowed. To change the connected calendar, please delete this integration and create a new one.",
    "calendar.sync.confirmDelete": "Please confirm you want to delete <strong>{name}</strong> calendar.",
    "calendar.sync.connect": "Connect your {provider} Calendar",
    "calendar.sync.connectCalendar": "Connect Calendar",
    "calendar.sync.connectCalendarCompletePlanyOnly": "COMPLETE PLAN ONLY",
    "calendar.sync.connectCalendarIcalOptionLabel": "Any calendar (via iCal link)",
    "calendar.sync.connectCalendarOptionLabel": "{platform} calendar",
    "calendar.sync.connectInfo": "Connect your {provider} account and select a shared calendar where all approved leaves will be pushed and synchronized automatically.",
    "calendar.sync.createICalButton": "Create iCal link",
    "calendar.sync.createICalFiltersInfo": "The following filters will be applied to this iCal calendar link:",
    "calendar.sync.createICalFiltersWarning": "Your calendar does not refresh iCal data in real time. The delay can be up to 24 hours (depending on your calendar provider). For real-time updates, connect your Google Calendar or Microsoft Outlook Calendar directly.",
    "calendar.sync.createICalInfo": "The iCal calendar link allows you to subscribe and view your teams' scheduled leaves from Vacation Tracker in any calendar (including Google Calendar, Microsoft Outlook Calendar, Apple Calendar, and many more).",
    "calendar.sync.createPushToSharedInfo": "And push and synchronize the filtered approved leaves to the following shared {provider} calendar:",
    "calendar.sync.createSyncCalendarButton": "Create calendar sync",
    "calendar.sync.createSyncCalendarFiltersInfo": "Vacation Tracker will apply the following filters to all approved leaves:",
    "calendar.sync.creating": "Creating calendar sync",
    "calendar.sync.creatingError": "Error creating calendar sync",
    "calendar.sync.creatingSuccess": "Creating calendar sync succeeded",
    "calendar.sync.deletedCalendar": "This calendar sync has been deleted",
    "calendar.sync.deleteTitle": "Delete calendar",
    "calendar.sync.deleting": "Deleting calendar sync",
    "calendar.sync.deletingError": "Error deleting calendar sync",
    "calendar.sync.deletingSuccess": "Deleting calendar sync succeeded",
    "calendar.sync.emptyDataMessage": "Sync and view all team leaves in your shared calendar. You can connect your Outlook calendar or Google Calendar, or use iCal to sync Vacation Tracker with any other calendar type.",
    "calendar.sync.emptyDataTitle": "Sync leaves with your team calendar",
    "calendar.sync.loginWithDifferentAccountInfo": "Don't see the calendar?",
    "calendar.sync.loginWithDifferentAccountLink": "Login with another {provider} account",
    "calendar.sync.msLogin": "Managing calendars require a Microsoft Teams' token. If you click the OK button, we'll redirect you to the Microsoft login page. Depending on your browser settings, you might be already logged in and automatically redirected back.",
    "calendar.sync.outlookAdminConsentButton": "Grant Admin Consent",
    "calendar.sync.outlookAdminConsentInfo": "Inserting or updating leaves in the {calendarName} calendar requires admin consent. We need additional permissions because you connected a calendar that someone else from your organization created or if your organization has some specific Microsoft Outlook Calendar sharing rules.",
    "calendar.sync.outlookAdminConsentInquiry": "Are you an administrator of your Microsoft 365 account?",
    "calendar.sync.outlookAdminConsentLinkInfo": "If you are not an administartor, please copy and send the following link to your Office 365 administrator to request the necessary permissions:",
    "calendar.sync.outlookAdminConsentLinkResyncInfo": "Once the administrator grants additional permissions, click here to get a new token and add missing events to the {calendarName} calendar:",
    "calendar.sync.outlookAdminConsentModalTitle": "Admin Consent Required",
    "calendar.sync.outlookAdminConsentResyncButton": "Reconnect and Sync",
    "calendar.sync.reconnect": "Reconnect your {provider} Calendar",
    "calendar.sync.reconnectInfo": "Reconnect your {provider} account to continue automatic leaves synchronization with your shared calendar.",
    "calendar.sync.reconnecting": "Reconnecting calendar sync",
    "calendar.sync.reconnectingError": "Error reconnecting calendar sync",
    "calendar.sync.reconnectingSuccess": "Reconnecting calendar sync succeeded",
    "calendar.sync.selectSharedCalendarPlaceholder": "Select shared {provider} calendar",
    "calendar.sync.stepByStep": "Step-by-step instructions",
    "calendar.sync.title": "Sync your team calendar",
    "calendar.sync.tooltipInfo": "All of your Synced Calendars come together on this page for easier management. To learn more, please consult our <helpDesk>Helpdesk article.</helpDesk>",
    "calendar.sync.update": "Update your {provider} Calendar",
    "calendar.sync.updateSyncCalendarButton": "Update calendar sync",
    "calendar.sync.updating": "Updating calendar sync",
    "calendar.sync.updatingError": "Error updating calendar sync",
    "calendar.sync.updatingSuccess": "Updating calendar sync succeeded",
    "calendar.syncButton": "Sync with your calendar (Google, Outlook, etc.)",
    "calendar.syncModal.description": "Copy the link below and integrate it in your Google Calendar, Outlook Calendar or any other calendar that supports iCal calendar feeds.",
    "calendar.syncModal.filters": "The following filters are applied to this calendar link:",
    "calendar.syncModal.helpdesk": "Visit our <helpDesk>Helpdesk</helpDesk> for help and step-by-step instructions.",
    "calendar.team": "Department(s)",
    "calendar.timezone.info": "Time is converted to your Time Zone",
    "calendar.week.monFri": "Mon-Fri",
    "calendar.week.sunThu": "Sun-Thu",
    "calendarListModal.description": "Please, select calendar from the following list of available calendars. After you connect a calendar, Vacation Tracker will add, edit or cancel your leaves into chosen calendar.",
    "calendarListModal.descriptionGoogleFirstLine": "Please, select calendar from the following list of available calendars. ",
    "calendarListModal.descriptionOutlookFirstLine": "You can only connect your default calendar. ",
    "calendarListModal.descriptionSecondLine": "After you connect a calendar, Vacation Tracker will add, edit or cancel your leaves into chosen calendar.",
    "calendarListModal.hiddenLeaveTypesDescription": "Learn more about <helpdesk>displaying hidden leave types</helpdesk>.",
    "calendarListModal.reconnectDescription": "Reconnect your calendar to continue automatic leaves synchronization with your connected calendar.",
    "calendarListModal.reconnectTitle": "Reconnect Calendar",
    "calendarListModal.selectPlaceHolder": "Please, select a calendar...",
    "calendarListModal.title": "Select Calendar",
    "channel_not_found": "The selected channel has been deleted or is no longer available. Please select another channel to receive Notifications on.",
    "choiceAutomations.accessibleTo": "Accessible to <approver>Approver </approver><and>and </and><admin>Administrator </admin><only>only</only>",
    "choiceAutomations.activeSubscribedAddons": "Active subscribed addons",
    "choiceAutomations.activeSubscribedAutomations": "Active subscribed Automations",
    "choiceAutomations.adpIntegration": "Provides a direct connection to your payroll tool, ensuring payroll data is accurate and up-to-date across all platforms.",
    "choiceAutomations.automationPrice": "Price: ${price}/user/month",
    "choiceAutomations.automationPriceInfo": "You can purchase and activate any of the automations below for $0.50/user/month",
    "choiceAutomations.bambooHrIntegration": "Provides a direct connection to your HR tool, keeping data accurate and consistent across systems.",
    "choiceAutomations.blackoutPeriodInfo": "A blackout period is a specified timeframe during which employees are not allowed to take leave from work.",
    "choiceAutomations.cancelAtPeriodEnd": "Active until billing period ends",
    "choiceAutomations.cancelAtPeriodEndTooltip": "You have unsubscribed from this add-on. It will remain active until the end of the current billing period and will be deactivated thereafter.",
    "choiceAutomations.comingSoonScheduleCall": "Feel free to share details about your specific needs regarding the automation we're working on.",
    "choiceAutomations.comingSoonScheduleCallTitle": "Coming soon",
    "choiceAutomations.deacticate": "Unsubscribe",
    "choiceAutomations.employmentLeaveRestrictionInfo": "Early employment leave policy allows ensuring dedicated settling-in time during the initial days of employment by new employee leave request restriction.",
    "choiceAutomations.enitlementByRoleShortInfo": "Employees are awarded additional leave days based on their role, as defined by label(s).",
    "choiceAutomations.entitlementByRoleInfo": "Employees are awarded additional leave days based on their role, as defined by label(s). For example, you can create a label called 'Manager' and assign it to all managers in your organization. Then, you can create an automation that awards 5 additional leave days to all employees with the 'Manager' label. You can create multiple automations for different roles. This automation is valid from your current fiscal year. <link>Contact us</link> if you need to apply this automation to past years.",
    "choiceAutomations.includedInYourPlan": "Included in your plan",
    "choiceAutomations.leaveDurationLimitInfo": "Customise Leave Duration: Set minimum and maximum number of days for desired period.",
    "choiceAutomations.mySbscribedAddons": "My subscribed Add-ons",
    "choiceAutomations.notActivatedAddons": "Available for subscription",
    "choiceAutomations.openApiInfo": "Provides seamless integration with external systems, enabling automated data synchronization and enhanced functionality",
    "choiceAutomations.reviewAndBuy": "Review and buy",
    "choiceAutomations.selectAutomations": "Select your preferred Automation",
    "choiceAutomations.seniorityEntitlementInfo": "Seniority entitlement is a system where employees earn more paid time off (PTO) based on their length of service with a company. Currently, you can create only one Seniority entitlement per location. This automation is valid from your current fiscal year. <link>Contact us</link> if you need more than one Seniority entitlement in a location or need to apply this automation to past years.",
    "choiceAutomations.seniorityEntitlementShortInfo": "Seniority entitlement is a system where employees earn more paid time off (PTO) based on their length of service with a company.",
    "choiceAutomations.teamLeaveBalancingInfo": "Manage concurrent team leave percentage.",
    "choiceAutomations.visibilityInfo": "Allows administrators to customize leave information visibility for regular users, enhancing privacy and data relevance.",
    "company.platformChanged": "Migrating to a new platform",
    "company.platformChanged.confirmDescription": "You are about to migrate to a new platform. This action is irreversible. Please review the following information before proceeding.",
    "company.platformChanged.confirmDescription.unmappedUsers.deleted": "We have found {unmappedUsers} user(s) that are not found in your {newPlatform} account. These users will be marked as DELETED when migration is complete.",
    "company.platformChanged.confirmDescription.unmappedUsers.unmatched": "We have found {unmappedUsers} user(s) that are not found in your {newPlatform} account. These users will not be able to login to Vacation Tracker.",
    "company.platformChanged.confirmQuestion": "Are you sure you want to proceed?",
    "company.platformChanged.confirmTitle": "Confirm migration",
    "company.platformChanged.description": "Please match your Vacation Tracker users to their {newPlatform} accounts. We will match users with same emails automatically.",
    "company.platformChanged.googleAccount": "Google Account",
    "company.platformChanged.hideInactive": "Hide inactive:",
    "company.platformChanged.markUnmappedAsDeleted": "Mark unmatched users as deleted?",
    "company.platformChanged.microsoftAccount": "Microsoft Account",
    "company.platformChanged.slackAccount": "Slack Account",
    "company.platformChanged.title": "Migrating from {oldPlatform} to {newPlatform}",
    "company.platformChanged.unmatched": "Not found in {platform}",
    "company.platformChanged.vacationTrackerUser": "Vacation Tracker User",
    "company.platformChangedTenant.title": "Migrating {newPlatform} tenant",
    "component.filterSimple.filterBy": "Filter by",
    "component.filterSimple.selectType": "Select type",
    "component.filterSimple.tooltipInfo": "If you do not select any filters, the export will include all users.",
    "component.notificationDestination.label": "Send to",
    "component.notificationDestination.tooltip": "Note that you can send the Notification to multiple emails, but only one channel at a time.",
    "component.password.timeToHack.days": "{duration} days",
    "component.password.timeToHack.hours": "{duration} hours",
    "component.password.timeToHack.instantly": "Instantly",
    "component.password.timeToHack.label": "Time hackers need to hack this password:",
    "component.password.timeToHack.minutes": "{duration} minutes",
    "component.password.timeToHack.perfect": "Longer than a lifetime",
    "component.password.timeToHack.seconds": "{duration} seconds",
    "component.password.timeToHack.years": "{duration} years",
    "component.setPassword.enterOldPassword": "Enter old password",
    "component.setPassword.enterPassword": "Enter password",
    "component.setPassword.tooltip.description.line1": "The information above shows the estimated maximum time hackers needed to brute force crack your selected password. The estimation is based on <link>this article's</link> <italic>\"Password table for cracking a PBKDF2 hash function using an RTX 3090 GPU.\"</italic> table.",
    "component.setPassword.tooltip.description.line2": "How to choose a good password? There are many ways to select a good password. We use password managers, and we do not reuse passwords. Take a look at this <link>funny but correct guide</link> for more inspiration.",
    "component.setPassword.tooltip.title": "Time hackers need to brute force crack your password",
    "component.setPassword.validation": "The password must contain at least: 8 characters, one lowercase letter, one number and one symbol.",
    "components.accrualsCalculator.calculate": "Calculate",
    "components.accrualsCalculator.calculateAccrualsDays": "Calculate Accruals Days",
    "components.accrualsCalculator.youWillEarnedInCurrentPeriod": "You will earn <strong>{days}</strong> days in the current period on that date",
    "components.accrualsCalculator.youWillEarnedInNextPeriod": "You will earn <strong>{days}</strong> days in the next period on that date",
    "components.addHolidayForm.addHolidays": "Add Holiday",
    "components.addHolidayForm.addHolidays.cancel": "Cancel",
    "components.addHolidayForm.addHolidays.isHalfDayHoliday": "Half Day Holiday",
    "components.addHolidayForm.addHolidays.isHolidayMultiday": "More Than One Day?",
    "components.addHolidayForm.addHolidays.saveHoliday": "Save",
    "components.addHolidayForm.date.error": "Please select a date",
    "components.addHolidayForm.editHoliday": "Edit holiday",
    "components.addHolidayForm.editHolidays": "Edit holidays",
    "components.addHolidayForm.endDate.error": "Please select end date",
    "components.addHolidayForm.holidaysName": "Holiday name",
    "components.addHolidayForm.holidaysName.error": "Please enter a holiday name",
    "components.addHolidayForm.startDate.error": "Please select start date",
    "components.announcementModal.announceToCompany": "Do you want to let your team know that you are using Vacation Tracker?",
    "components.announcementModal.send": "Send Announcement",
    "components.automationBlackoutSummaryInfo.blacoutSummary": "{noOfUsers} user<multiUsers>s</multiUsers> <link>(View User<multiUsers>s</multiUsers>)</link> wont't be able to take {leaveTypes} leaves,<endDate> between</endDate> {fromDate} <endDate>and {toDate}</endDate><recurring> each year.</recurring>",
    "components.billing.email": "Billing Email",
    "components.billing.emailPlaceholder": "Enter email address.",
    "components.billing.emailsValidationRequired": "An email is required for billing purposes.",
    "components.billing.resubscribe": "Re-subscribe",
    "components.billingCouponCode.activeCouponInfo": "<percentOffCover>{off}%</percentOffCover><amountOffCover>US${off}</amountOffCover> off <durationCover>{duration}</durationCover><durationInMonthsCover>{duration} month</durationInMonthsCover>, from {start}",
    "components.billingCouponCode.activeCouponInfoTitle": "Active Coupon Code",
    "components.billingCouponCode.applyCode": "Apply code",
    "components.billingCouponCode.applyCodeAndResubscribe": "Apply code & Re-subscribe",
    "components.billingCouponCode.couponCode": "Coupon Code",
    "components.billingCouponCode.text": "Do you have a coupon code? Enter it here to apply it to your subscription.",
    "components.billingCouponCode.title": "Coupons",
    "components.billingEmails.emailPlaceholder": "Enter email address.",
    "components.billingEmails.emails": "Emails",
    "components.billingEmails.emailsValidationRequired": "An email is required for billing purposes.",
    "components.billingEmails.title": "Billing Emails",
    "components.billingForm.address1": "Address line 1",
    "components.billingForm.address1ValidationRequired": "Address is required.",
    "components.billingForm.address2": "Address line 2",
    "components.billingForm.billingEmails": "Billing Email",
    "components.billingForm.billingEmailsPlaceholder": "Enter email address.",
    "components.billingForm.billingEmailsValidationRequired": "An email is required for billing purposes.",
    "components.billingForm.ccRequired": "Card number is required.",
    "components.billingForm.city": "City",
    "components.billingForm.cityValidationRequired": "City is required.",
    "components.billingForm.companyName": "Company Name",
    "components.billingForm.companyNameValidationRequired": "Company Name is required.",
    "components.billingForm.country": "Country",
    "components.billingForm.countryValidationRequired": "Country is required.",
    "components.billingForm.couponCode": "Coupon Code",
    "components.billingForm.coupons": "Coupons",
    "components.billingForm.description": "Please enter your payment information in the form above to pay your subscription with a credit card.",
    "components.billingForm.invoiceDescription": "If the person in charge of paying the subscription is not a Slack or Microsoft Teams user, we can email them an invoice which can be paid using a credit card.",
    "components.billingForm.invoiceLabel": "Send email invoices",
    "components.billingForm.invoiceTitle": "Payment with emailed invoice",
    "components.billingForm.paymentCC": "Credit Card",
    "components.billingForm.paymentTitle": "Payment information",
    "components.billingForm.planTitle": "Billing plan ",
    "components.billingForm.state": "State",
    "components.billingForm.stateValidationRequired": "State is required.",
    "components.billingForm.title": "Billing information ",
    "components.billingInfo.address": "Address",
    "components.billingInfo.canceledDescription": "You have canceled your Vacation Tracker subscription. It will remain active until {periodEnd} ",
    "components.billingInfo.canceledTrialDescription": "Your trial period will remain active until {periodEnd}, after that your Vacation Tracker account will be canceled. ",
    "components.billingInfo.companyName": "Company Name",
    "components.billingInfo.email": "Billing Email",
    "components.billingInfo.resubscribe": "Re-subscribe",
    "components.billingInfo.text": "Your billing information is used to process payments for your subscription. You can update your billing information and add your credit card information here.",
    "components.billingInfo.title": "Billing information ",
    "components.billingInvoice.description": "You can manage payment methods and invoices on our customer portal. <link>Click here to visit the customer portal</link><creatingLink>Creating the customer portal link</creatingLink>",
    "components.billingInvoice.title": "Invoices",
    "components.cancelSubscriptionModal.anotherLeaveTrackingTool": "We have found another leave tracking tool",
    "components.cancelSubscriptionModal.anotherLeaveTrackingToolAdditionalInfo": "Can you please tell us which tool you are switching to?",
    "components.cancelSubscriptionModal.cancelText": "Don't cancel subscription",
    "components.cancelSubscriptionModal.contactInfo": "For everything else please contact our support at {email}",
    "components.cancelSubscriptionModal.info": "Can you please let us know what is the reason you are canceling your Vacation Tracker subscription?",
    "components.cancelSubscriptionModal.min20characters": "Minimum 20 characters",
    "components.cancelSubscriptionModal.missingFeatures": "The tool does not have the features my team needs",
    "components.cancelSubscriptionModal.missingFeaturesAdditionalInfo": "Can you tell us in a few words what feature is missing?",
    "components.cancelSubscriptionModal.moreInfo": "What could we have done to keep you as a customer?",
    "components.cancelSubscriptionModal.okText": "Cancel Now",
    "components.cancelSubscriptionModal.option1": "We have switched to an HR software",
    "components.cancelSubscriptionModal.option2": "We have found another leave tracking tool",
    "components.cancelSubscriptionModal.option3": "The tool does not have the features my team needs",
    "components.cancelSubscriptionModal.option4": "The price is above our price budget",
    "components.cancelSubscriptionModal.option5": "Other",
    "components.cancelSubscriptionModal.other": "Other",
    "components.cancelSubscriptionModal.otherAdditionalInfo": "Other",
    "components.cancelSubscriptionModal.priceOverBudget": "The price is above our price budget",
    "components.cancelSubscriptionModal.priceOverBudgetAdditionalInfo": "What is your budget for a tool like this?",
    "components.cancelSubscriptionModal.title": "We are sorry to see you go...",
    "components.cancelSubscriptionModal.weAreMovingToPayroll": "We are moving to a Payroll system",
    "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfo": "Can you please tell us which Payroll system you are switching to?",
    "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfoIntegration": "If we had an integration with this Payroll system, would you consider staying?",
    "components.cancelSubscriptionModal.weSiwtchedToAnHrSolution": "We have switched to an HR software",
    "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfo": "Can you please tell us which HR system you are switching to?",
    "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfoIntegration": "If we had an integration with this HR system, would you consider staying?",
    "components.cancelTrialSubscriptionModal.cancelText": "Continue trial",
    "components.cancelTrialSubscriptionModal.info": "Please explain in one sentence why do you want to cancel your trial?",
    "components.cancelTrialSubscriptionModal.okText": "Cancel trial",
    "components.cancelTrialSubscriptionModal.title": "We are sorry to see you go...",
    "components.changeTeamForm.changeTeamWarning": "Select the Department you want to transfer the User to.",
    "components.changeTeamForm.changeUserTeam": "Change User's Department",
    "components.changeTeamForm.selectTeam": "Select Department",
    "components.changeTeamForm.selectTeamError": "Please select a department.",
    "components.companyAlreadySignup.connectWith": " Connect with {existsOnAnotherPlatform}",
    "components.companyAlreadySignup.info": "We've noticed that your company has an existing Vacation Tracker account. If you meant to log in, simply click here:",
    "components.companyAlreadySignup.signupWithMail": "Connect with your work email",
    "components.companyAlreadySignup.title": "Your company is already using Vacation Tracker",
    "components.companyLogs.dateRangeLimit": "Logs older than 3 months are available only in complete plan.",
    "components.companyLogs.rangeLimit": "Date range can be one year at most",
    "components.companySettings.contactEmailInfo": "Your new company contact email address is: {email}.",
    "components.comparePlatformsModal.forMoreInfoSeeOurHelpdesk": "To learn more, visit our <helpDesk>Helpdesk</helpDesk>.",
    "components.comparePlatformsModal.info": "Regardless of the platform you choose, you will have access to all the major features of Vacation Tracker. Some, however, have additional benefits that are not available in others. Consult the comparison table below.",
    "components.comparePlatformsModal.table.comingSoon": "Coming soon",
    "components.comparePlatformsModal.table.email": "Email",
    "components.comparePlatformsModal.table.feature": "Feature",
    "components.comparePlatformsModal.table.google": "Google",
    "components.comparePlatformsModal.table.importUsersFromGoogleWorkspace": "Import Users from your Google Workspace",
    "components.comparePlatformsModal.table.importUsersFromMicrosoftTeams": "Import Users from your Microsoft Active Directory",
    "components.comparePlatformsModal.table.importUsersFromSlack": "Import Users from your Slack workspace",
    "components.comparePlatformsModal.table.inviteUsersViaEmail": "Invite Users via email",
    "components.comparePlatformsModal.table.manageLeaveTypes": "Manage Leave Types, Holidays and Leave Requests",
    "components.comparePlatformsModal.table.microsoft": "Microsoft",
    "components.comparePlatformsModal.table.multiTeamLocation": "Configure multiple Departments and Locations with different Leave Policies",
    "components.comparePlatformsModal.table.requestAndManageLeaveFromMicrosoftTeams": "Submit and manage Leave Requests directly from Microsoft Teams",
    "components.comparePlatformsModal.table.requestAndManageLeaveFromSlack": "Submit and manage Leave Requests directly from Slack",
    "components.comparePlatformsModal.table.slack": "Slack",
    "components.comparePlatformsModal.table.syncToGoogleCalendar": "Sync Vacation Tracker with Google Calendar",
    "components.comparePlatformsModal.table.syncToOutlookCalendar": "Sync Vacation Tracker with Outlook 365 Calendar",
    "components.comparePlatformsModal.title": "Which Vacation Tracker integration is right for me?",
    "components.configureImport.configureProratedLeaveTypesErrorMessage": "You need to select at least one leave type",
    "components.configureImport.defaultDepartmentTooltip": "All imported users will be added to this department. Once imported, you can place them in a different department.",
    "components.configureImport.defaultLocationTooltip": "All imported users will be added to this location. Once imported, you can place them in a different location.",
    "components.configureImport.guestUsers": "Do you want to import multichannel guest users?",
    "components.configureImport.guestUsersTooltip": "If this option is turned on multichannel guest users from your workspace will be imported into the Vacation Tracker.",
    "components.configureImport.proRateLabel": "Prorate the new users' quota for this year?",
    "components.configureImport.proRateLeaveTypes": "Apply prorating to the following leave types",
    "components.configureImport.proRateLeaveTypesTooltip": "Please list the leave types for which you'd like to prorate available user leave quotas.",
    "components.configureImport.proRateTooltip": "Please select whether you want our system to calculate the imported users' available leave quota for the initial year based on your company settings and the user start date.",
    "components.createLeaveType.assignToLocationManyInProgress": "Assigning leave policy to locations",
    "components.createLeaveType.assignToLocationManyInProgressCompleted": "Assigning leave policy to locations completed",
    "components.createLeaveType.assignToLocationOneInProgress": "Assigning leave policy to location",
    "components.createLeaveType.assignToLocationOneInProgressCompleted": "Assigning leave policy to location completed",
    "components.createLeaveType.createInProgress": "Creating the {leaveTypeName} leave type",
    "components.createLeaveTypeForm.assignAndConfigure": "Assign & Configure",
    "components.createLeaveTypeForm.assignToLocations": "Assign to Locations",
    "components.createLeaveTypeForm.calendarStatusBusy": "Busy",
    "components.createLeaveTypeForm.calendarStatusFree": "Free",
    "components.createLeaveTypeForm.calendarStatusLabel": "Show this leave type in your calendar as",
    "components.createLeaveTypeForm.calendarStatusLabelTooltipInfo": "Choose 'Busy' for complete absence like vacation, signalling unavailability, or 'Free' for remote work days, indicating you're not in the office but available.",
    "components.createLeaveTypeForm.continueCreatingLeaveType": "Yes, I want to create leave type",
    "components.createLeaveTypeForm.createAndAssign": "Create And Assign",
    "components.createLeaveTypeForm.createLeaveType": "Create Leave Type",
    "components.createLeaveTypeForm.justLastStep.assign": "Assign",
    "components.createLeaveTypeForm.justLastStep.title": "Assign Leave Policy",
    "components.createLeaveTypeForm.Locations": "Locations",
    "components.createLeaveTypeForm.locationsInfo": "Assign <strong>{leaveTypeName}</strong> to all locations or a specific location.",
    "components.createLeaveTypeForm.locationsPolicyHint": "To edit the policy (rules) for this leave type, please go to the specific {locations, plural, =1 {Location} other {Locations}}:",
    "components.createLeaveTypeForm.selectLocation": "Select location",
    "components.createLeaveTypeForm.similarLeaveTypeChecking": "Checking for existing leave types...",
    "components.createLeaveTypeForm.similarLeaveTypeInfoAreYouSure": "Do you still want to create a new type (<strong>{newLeaveTypeName}</strong>)?",
    "components.createLeaveTypeForm.similarLeaveTypeInfoAssigningTip": "You can assign a leave type to multiple locations and customize rules per location (such as quotas, brought forward, accruals, etc.). No need to create separate types for each place!",
    "components.createLeaveTypeForm.similarLeaveTypeInfoRecommend": "We recommend using a single leave type across multiple locations to maintain user leave data and consistency.",
    "components.createLeaveTypeForm.similarLeaveTypeInfoSetRules": "Set the location-specific rules for:",
    "components.createLeaveTypeForm.similarLeaveTypeInfoSimilarLeaveType": "A similar leave type already exists (<strong>{leaveTypeName}</strong>).",
    "components.createLeaveTypeForm.similarLeaveTypeTitle": "Similar Leave Type",
    "components.createLeaveTypeForm.title": "Create Leave Type & Assign Leave Policy",
    "components.denyWithReasonForm.deny": "Deny",
    "components.denyWithReasonForm.denyLeaveRequest": "Deny Leave Request",
    "components.denyWithReasonForm.reason": "Add a Reason",
    "components.denyWithReasonForm.reasonIsRequired": "This field is required.",
    "components.downgradeAlert.cancelChangeToPlan": "Cancel change to <strong>{nextPlan}</strong> plan",
    "components.downgradeAlert.cancelSwitchPlan": "Do you want to cancel the scheduled change to the <strong>{nextPlan}</strong> {nextPeriod} plan?",
    "components.downgradeAlert.noIWantToSwitch": "No, I want to switch to the <strong>{nextPlan}</strong> {nextPeriod} plan",
    "components.downgradeAlert.yesIWantToStay": "Yes, I want to stay on the <strong>{currentPlan}</strong> {currentPeriod} plan",
    "components.entitlementByRole.additionalDays": "additional day(s)",
    "components.entitlementByRole.additionalHours": "additional hour(s)",
    "components.entitlementByRole.applayToUsersWithLabel": "Applay to users with label",
    "components.entitlementByRole.applyToUsersWithLabel": "Apply to users with label",
    "components.entitlementByRole.completePlanOnly": "This feature is exclusively accessible to users subscribed to our Complete plan.",
    "components.entitlementByRole.noLabels": "There are no created labels or all are utilized for other Entitlement By Role automations.",
    "components.entitlementByRole.startApplayingEnintlementByRoleFrom": "Start applaying enintlement by role from",
    "components.entitlementByRole.startApplyingEntitlementByRoleFrom": "Start applying entitlement by role from",
    "components.fakeDoorMailLoginModal.discount": "As a part of the early access group, you'll also get 50% off Vacation Tracker for the first 3 months.",
    "components.fakeDoorMailLoginModal.info": "We're currently working on enabling email signup. To get early access, please sign below and we will be in touch shortly.",
    "components.fakeDoorMailLoginModal.no": "No, I'll wait.",
    "components.fakeDoorMailLoginModal.title": "Interested in early access? 🚀 ",
    "components.fakeDoorMailLoginModal.yes": "Yes, I want to get early access.",
    "components.feedbackModal.button": "Give us feedback",
    "components.feedbackModal.emailPlaceholder": "What's you email address?",
    "components.feedbackModal.feedbackForm": "Feedback form",
    "components.feedbackModal.feedbackMessage": "Feedback message",
    "components.feedbackModal.howDoYouLikeApp": "How do you like our app?",
    "components.feedbackModal.howDoYouLikeAppPlaceholder": "Choose an option...",
    "components.feedbackModal.iLikeIt": "I like it",
    "components.feedbackModal.iLoveIt": "I love it",
    "components.feedbackModal.ItMakesMeCry": "It makes me cry",
    "components.feedbackModal.itsOk": "It's ok",
    "components.feedbackModal.messagePlaceholder": "Your feedback message",
    "components.feedbackModal.Nah": "Nah",
    "components.feedbackModal.namePlaceholder": "Enter your name",
    "components.feedbackModal.ratingRequired": "Please, select one of the given ratings",
    "components.feedbackModal.sendFeedback": "Send feedback",
    "components.feedbackModal.yourEmail": "Your email address",
    "components.feedbackModal.yourEmailInfo": "If your feedback requires a response, please enter your email so we can contact you.",
    "components.feedbackModal.yourName": "Your name",
    "components.filterSimple.filterBy": "Filter by",
    "components.filterSimple.selectType": "Select type",
    "components.filterSimple.tooltipInfo": "If you do not select any filters, the export will include all users.",
    "components.generalSettings.aiBotHelperLabel": "Allow communication with the Vacation Tracker Bot",
    "components.generalSettings.aiBotHelperLabelDisable": "Disable communication with the Vacation Tracker Bot",
    "components.generalSettings.aiBotHelperTooltip": "Messaging with bot is a convenient and efficient way for employees to request time off from work. Employees can simply send a leave request message to the bot, specifying details such as the start and end dates, the number of days requested, and the type of leave.",
    "components.heatmap.clickForMore": "Click for more info",
    "components.heatmap.holiday": "Holiday",
    "components.heatmap.holidayDescription": "{location} location is away for {holiday}",
    "components.heatmap.loading": "loading {day}",
    "components.heatmap.nextMonth": "Next Month",
    "components.heatmap.nonWorkingDay": "non working day",
    "components.heatmap.previousMonth": "Previous Month",
    "components.heatmap.showLess": "Show less",
    "components.heatmap.showMore": "Show {amount} more",
    "components.heatmap.today": "today",
    "components.helpDrawer.adminProductTourInfo": "As an Administrator you have the highest level of permissions in Vacation Tracker, and can customize all the organization's settings through the Administrator Dashboard.\n\nThe Dashboard allows you to: \n- Request Leave, or add Leave for other department members. \n- Have a full overview through the Dashboard Homepage, and the Calendar. \n- Create and configure multiple departments (Assign Approvers) \n- Set Notifications  \n- Manage Users (Active, Inactive, Deleted).\n- Create and manage Locations (Leave Types, Holidays, Notifications)\n\nThe Bot allows you to: \n- Request Leave \n- Approve/Deny Leave Requests\n- Receive Daily/Weekly Notifications\n\nIf your questions or concerns remain unanswered, reach out to us at any time. Speaking to our customers is the best part of our day: ",
    "components.helpDrawer.adminProductTourTitle": "Your role: Administrator",
    "components.helpDrawer.approverProductTourInfo": "As an Approver, you have the second highest level of permissions in Vacation Tracker, and can approve or deny leave requests submitted by Users from the department they've been assigned as an Approver for.\n\nThe Dashboard allows you to:\n- Request Leave, or add Leave for other department members.\n- Have a full overview through the Dashboard Homepage, and the Calendar. \n- Set Notifications \n- View your User Profile.\n- Manage Requests (Approve/Deny Requests, View History).\n\nThe Bot allows you to:\n- Request Leave\n- Approve/Deny Leave Requests\n- Receive Daily/Weekly Notifications\n\nIf your questions or concerns remain unanswered, reach out to us at any time. Speaking to our customers is the best part of our day: ",
    "components.helpDrawer.approverProductTourTitle": "Your role: Approver",
    "components.helpDrawer.google.adminProductTourInfo": "As an Administrator you have the highest level of permissions in Vacation Tracker, and can customize all the organization's settings through the Administrator Dashboard.\n\nThe Dashboard allows you to: \n- Request Leave, or add Leave for other department members. \n- Have a full overview through the Dashboard Homepage, and the Calendar. \n- Create and configure multiple departments (Assign Approvers)  \n- Set Notifications \n- Manage Users (Active, Inactive, Deleted).\n- Create and manage Locations (Leave Types, Holidays, Notifications)\n\nIf your questions or concerns remain unanswered, reach out to us at any time. Speaking to our customers is the best part of our day: ",
    "components.helpDrawer.google.approverProductTourInfo": "As an Approver, you have the second highest level of permissions in Vacation Tracker, and can approve or deny leave requests submitted by Users from the department they've been assigned as an Approver for.\n\nThe Dashboard allows you to:\n- Request Leave, or add Leave for other department members.\n- Have a full overview through the Dashboard Homepage, and the Calendar. \n- Set Notifications \n- View your User Profile.\n- Manage Requests (Approve/Deny Requests, View History).\n\nIf your questions or concerns remain unanswered, reach out to us at any time. Speaking to our customers is the best part of our day: ​​​​​​​​",
    "components.helpDrawer.google.userProductTourInfo": "As a Vacation Tracker User, you can submit and manage your Leave Requests through the Dashboard, as well as have a full overview of planned absences within your organization.\n\nThe Dashboard allows you to:\n- Request Leave\n- Have a full overview through the Dashboard Homepage, and the Calendar.\n- View your User Profile.\n\nIf your questions or concerns remain unanswered, reach out to us at any time. Speaking to our customers is the best part of our day: ​​​​​​​​",
    "components.helpDrawer.helpDeskInfo": "Visit our Helpdesk to learn more about our product and get the answer to some of the common questions.",
    "components.helpDrawer.learnMore": "Learn more",
    "components.helpDrawer.needhelp": "Need help?",
    "components.helpDrawer.quickTourInfo": "We've put together a few short steps we recommend you follow to set up your organization quickly.",
    "components.helpDrawer.quickTourTitle": "Quick tour",
    "components.helpDrawer.supportInfo": "If you have any questions or need assistance, start a chat with our customer support team or email us at <link>hello@vacationtracker.io</link>",
    "components.helpDrawer.supportTitle": "Support",
    "components.helpDrawer.userProductTourInfo": "As a Vacation Tracker User, you can submit and manage your Leave Requests through the Dashboard or the Bot, as well as have a full overview of planned absences within your organization.\n\nThe Dashboard allows you to:\n- Request Leave\n- Have a full overview through the Dashboard Homepage, and the Calendar.\n- View your User Profile.\n\nThe Bot allows you to:\n- Request Leave \n- Receive Daily/Weekly Notifications\n\nIf your questions or concerns remain unanswered, reach out to us at any time. Speaking to our customers is the best part of our day: ",
    "components.helpDrawer.userProductTourTitle": "Your role: User",
    "components.importHolidaysForm.backButton": "Back",
    "components.importHolidaysForm.selectCountry": "Select country",
    "components.importHolidaysForm.selectHolidays": "Select holidays",
    "components.importHolidaysForm.selectYear": "Select year",
    "components.imports.bfDescription": "Initial Brought Forward is the leave balance a User has carried over (rolled over) from last year, in which Vacation Tracker wasn't a part of your organization. Note that you can only edit the Initial Brought Forward leave balance, while the available leave you can bring forward in the following years will be calculated by Vacation Tracker.",
    "components.imports.description": "You can import a file in Excel or CSV format. Use our template or your own file and map the required columns—no need to modify large files if they're ready.",
    "components.imports.descriptionFieldAmount": "Mandatory. Enter the amount the user has carried over from the previous year for that specific leave type.",
    "components.imports.descriptionFieldApproverEmail": "Mandatory.",
    "components.imports.descriptionFieldDepartment": "Optional. Fill out to update User Department.",
    "components.imports.descriptionFieldEmail": "Mandatory.",
    "components.imports.descriptionFieldEmployeeId": "Optional, used for integration with other systems.",
    "components.imports.descriptionFieldEndDate": "Mandatory. Enter the end date of the request. Please follow the correct date format (YYYY-MM-DD).",
    "components.imports.descriptionFieldHourFrom": "The starting time for hourly or half-day request. This field is optional and applies only to such cases.",
    "components.imports.descriptionFieldHourTo": "The ending time for hourly or half-day request. This is also optional and only applies in relevant scenarios.",
    "components.imports.descriptionFieldIsHalfDay": "Specify if the request is for a half-day. This field is optional and applies only for requests less than one full day.",
    "components.imports.descriptionFieldLeaveType": "Mandatory. Enter the precise name of the leave type (e.g., Vacation, Sick Leave, Unpaid Leave) to which the entered days or hours are associated. Make sure to input the exact leave type name.",
    "components.imports.descriptionFieldLocation": "Optional. Fill out to update User Location.",
    "components.imports.descriptionFieldReason": "Reason - Can be mandatory or optional depending on your leave policy settings..",
    "components.imports.descriptionFieldReasonToil": "Mandatory.",
    "components.imports.descriptionFieldStartDate": "Mandatory. Enter the start date of the request. Please follow the correct date format (YYYY-MM-DD).",
    "components.imports.descriptionFieldStatus": "Optional.",
    "components.imports.descriptionFieldUserEndDate": "Optional. Fill out to update the user's employment end date.",
    "components.imports.descriptionFieldUserStartDate": "Optional. Fill out to update the user's employment start date.",
    "components.imports.descriptionImportDetails": "Update user Profile data for multiple users simultaneously.",
    "components.imports.descriptionImportLeaves": "Import approved leaves for multiple leave types and users simultaneously.",
    "components.imports.descriptionImportQuotas": "Import Initial Brought Forward balance for multiple leave types and users simultaneously.",
    "components.imports.descriptionImportToils": "Import approved Time Off in Lieu for multiple leave types and users simultaneously.",
    "components.imports.feedback": "Do you need another import type? Let us know what you need!",
    "components.imports.fieldsDescriptions": "You can quickly import the following data:",
    "components.imports.intro": "Quickly import large datasets via Excel or CSV to save time and reduce manual effort.",
    "components.imports.titleImportDetails": "User Profile Data",
    "components.imports.titleImportLeaves": "Leaves",
    "components.imports.titleImportQuotas": "Initial Brought Forward",
    "components.imports.titleImportToils": "Time Off in Lieu",
    "components.importUsersForm.button.importAllUsers": "Import and Activate All {users} Users",
    "components.importUsersForm.button.importSelectedUsers": "Import {noOfUsers} Selected {noOfUsers, plural, =1 {User} other {Users}}",
    "components.importUsersForm.button.importUsersAutmaticallyFromPlatform": "Do you want to import all users from {platform} automatically?",
    "components.importUsersForm.calculationStartYearInfo": "Vacation Tracker will calculate leaves starting with {year}",
    "components.importUsersForm.cancel": "Cancel",
    "components.importUsersForm.chooseUsersPlaceholder": "Team Member Name",
    "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOff": "Vacation Tracker will not import multichannel guest users from your workspace automatically. You can still import them manually if needed.",
    "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOn": "Vacation Tracker will import multichannel guest users from your workspace automatically. ",
    "components.importUsersForm.confirmImportUsersModalContentChangeLaterInfo": "You can change these settings later in the Vacation Tracker dashboard",
    "components.importUsersForm.confirmImportUsersModalContentDepartmentAndLocationInfo": "New {noOfUsers, plural, =1 {user} other {users}} will be added to the {chosenDepartment} department and in {chosenLocation} location",
    "components.importUsersForm.confirmImportUsersModalContentNoOfUsers": "Import {noOfUsers} {noOfUsers, plural, =1 {user} other {users}}",
    "components.importUsersForm.confirmImportUsersModalContentOpener": "You selected:",
    "components.importUsersForm.confirmImportUsersModalContentProrateInfo": "Prorate users' quota for the following leave types: {leaveTypes}",
    "components.importUsersForm.confirmImportUsersModalContentProrateInfoTurnOff": "User's quotas will no longer be prorated",
    "components.importUsersForm.confirmImportUsersModalContentWelcomeMessage": "Send a welcome {channel, select, Email {} other {message}} {channel} to the new user(s)",
    "components.importUsersForm.confirmImportUsersModalContentWelcomeMessageTurnOff": "We'll no longer be sending a welcome message to the new users",
    "components.importUsersForm.confirmImportUsersModalTitle": "Confirm importing {noOfUsers} selected users",
    "components.importUsersForm.consent.line1": "To import users, you'll need to get admin consent from your Microsoft Teams administrator. To get this one time consent, please send the following link to your Microsoft Teams administrator:",
    "components.importUsersForm.consent.line2": "Once you get the consent, close and reopen this modal, and this message will disappear.",
    "components.importUsersForm.consent.warning": "Please close and reopen this modal once you get the consent to be able to import all users.",
    "components.importUsersForm.googleAPIErrorMessage": "There seem to be the problem with the settings in your Google directory settings. Please, contact your administartor and send them the following error:",
    "components.importUsersForm.importAllUsers": "Do you want to import all users?",
    "components.importUsersForm.importAllUsersExceedingLimt": "You have a large number of users in your directory. If you want to import all users, please contact our Support team and we can enable the import. Alternatively, you can select specific users to import.",
    "components.importUsersForm.importAllUsersTooltip": "{noOfUsersToImport} {noOfUsersToImport, plural, =1 {user} other {users}} from {platform} will be imported to Vacation Tracker. This might affect you billing.",
    "components.importUsersForm.importUsersAutomaticallyExceedingLimit": "You have a large number of users in your directory. If you want to import users automatically, please contact our Support team and we can enable the import.",
    "components.importUsersForm.importUsersAutomaticallyExceedingLimt": "You have a large number of users in your directory. If you want to import users automatically, please contact our Support team and we can enable the import.",
    "components.importUsersForm.importUsersAutomaticallyTooltip": "Once a newly added user to your {platform} is imported to Vacation Tracker, they will receive a brief message explaining what Vacation Tracker is and how to navigate it.",
    "components.importUsersForm.importUsersTitle": "Import Users",
    "components.importUsersForm.newCompanyNameInfo": "New company name will be: {newName}",
    "components.importUsersForm.selectUsers": "Select users to import:",
    "components.importUsersForm.selectUsersNext": "Configure settings for selected users",
    "components.importUsersForm.selectUsersStepName": "Select Users",
    "components.importUsersForm.sendWelcomeMessageLabel": "Send a welcome message to the new users?",
    "components.importUsersForm.trackingQuotaInfoDays": "Vacation Tracker will track the duration of all leaves and quotas in days.",
    "components.importUsersForm.trackingQuotaInfoHours": "Vacation Tracker will track the duration of all leaves and quotas in hours.",
    "components.importUsersForm.userEndDateInfo": "Users with an end date won't be able to submit leave requests past that date, and we'll automatically deactivate them.",
    "components.importUsersForm.userEndDateTurnOffInfo": "Users with an end date will be able to submit leave requests past that date, and we'll not automatically deactivate them.",
    "components.importUsersForm.usersIsRequired": "This field is required.",
    "components.importUsersForm.warningAllUsersImport": "Vacation Tracker can automatically sync, import and delete users from your {platform}.",
    "components.importUsersForm.warningNumberOfUsersToImport": "When you click the \"Import and Activate All Users\" button, you will activate all {users} users from your directory. This action can affect your billing.",
    "components.importUsersForm.warningOfUsersImport": "Importing {noOfUsers} selected {noOfUsers, plural, =1 {user} other {users}} might affect your billing.",
    "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNewBucket": "Adding {noOfUsers} {noOfUsers, plural, =1 {user} other {users}} will increase your next bill to {amount} (excluding VAT) starting {startDate}.",
    "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNoOfUsers": "An estimated prorated charge of {proratedAmount} (excluding VAT) will be applied today.",
    "components.importUsersForm.warningOfUsersImportNoChange": "You are about to import {noOfUsers} {noOfUsers, plural, =1 {user} other {users}}. Your billing will not be affected.",
    "components.importUsersForm.warningOfUsersImportOverSeatsLimit": "You have reached your current seats limit ({seats}). Do you want to buy more? ",
    "components.importUsersForm.warningOfUsersImportOverSeatsLimitImportAll": "You can't import all users from your workspace/directory because you will then pass your current seats limit ({seats}). Do you want to buy more? ",
    "components.importUsersForm.warningOfUsersImportProrateAdditionalNewBucketMonthly": "Importing {noOfUsers} {noOfUsers, plural, =1 {user} other {users}} will affect your billing. Your next invoice will be {amount} (excluding VAT) on {startDate}",
    "components.importUsersForm.warningOfUsersImportTrial": "You are currently in trial mode. You will be charged {amount} (excluding VAT) on {startDate}",
    "components.importUsersForm.warningOfUsersImportTrialWithoutPaymentMethod": "You are in a trial period and you won't be charged for any additional users import before {stripeCurrentPeriodEnd}. Add your billing info to see the charge estimate.",
    "components.insertHolidaysForm.back": "Back",
    "components.insertHolidaysForm.deselectAll": "Deselect All",
    "components.insertHolidaysForm.findHolidays": "Find Holidays",
    "components.insertHolidaysForm.insertHolidays": "Import Holidays",
    "components.insertHolidaysForm.pleaseChooseACountry": "Choose a Country",
    "components.insertHolidaysForm.saveHolidays": "Save Holidays",
    "components.insertHolidaysForm.selectAll": "Select All",
    "components.insertHolidaysForm.selectHolidays": "Select holidays",
    "components.insertHolidaysForm.subtitle": "The change may impact the number of leave days for some users. These users will have day(s) added to their available quota for their requested leave. Are you sure you want to proceed?",
    "components.insertHolidaysForm.title": "Importing Holidays will overwrite any previously added Holidays.",
    "components.inviteAndManageUsersWithEmail.canNotInviteYourself": "You can not invite yourself",
    "components.inviteAndManageUsersWithEmail.duplicateEmail": "The email address is already entered.",
    "components.inviteAndManageUsersWithEmail.header": "Email invites",
    "components.inviteAndManageUsersWithEmail.userAlreadyExists": "User with provided email already exists",
    "components.inviteBotToPrivateChannel.descriptionOne": "To add Vacation Tracker to a private channel, please type",
    "components.inviteBotToPrivateChannel.descriptionTwo": "in the channel and refresh the list",
    "components.inviteBotToPrivateChannel.text": "Do you want to select a private channel?",
    "components.inviteBotToPrivateChannel.title": "Add private channel",
    "components.leaveBox.approve": "Approve",
    "components.leaveBox.approvedBy": "Approved By",
    "components.leaveBox.approveLeaveText": "Please confirm you want to approve the leave request.",
    "components.leaveBox.approveLeaveTitle": "Approve Leave Request",
    "components.leaveBox.autoApproved": "Auto approved",
    "components.leaveBox.cancelLeave": "Cancel Leave",
    "components.leaveBox.cancelLeaveConfirm": "Are you sure you want to cancel leave?",
    "components.leaveBox.cancelLeaveTitle": "Cancel Leave",
    "components.leaveBox.columns.daysOut": "{value} {value, plural, =1 {day} other {days}}",
    "components.leaveBox.columns.hoursOut": "{value} {value, plural, =1 {hour} other {hours}}",
    "components.leaveBox.daysLeft": "Leave Remaining:",
    "components.leaveBox.daysOut": "That's {value} {value, plural, =1 {day} other {days}} out of the office.",
    "components.leaveBox.deny": "Deny",
    "components.leaveBox.denyLeaveConfirm": "Please confirm you want to deny the leave request.",
    "components.leaveBox.denyLeaveTitle": "Deny Leave Request",
    "components.leaveBox.denyWithReason": "Deny with Reason",
    "components.leaveBox.edit": "Edit",
    "components.leaveBox.formattedLeaveInfo": "That's {leave} out of the office.",
    "components.leaveBox.hoursOut": "That's {value} {value, plural, =1 {hour} other {hours}} out of the office.",
    "components.leaveBox.leavesBoxHalfDay": "Will be away for a {leaveTypeName} on {leaveStartDate} from {startHour} to {endHour}.",
    "components.leaveBox.leavesBoxHalfDayPending": "Request for {leaveTypeName} on {leaveStartDate} from {startHour} to {endHour}.",
    "components.leaveBox.leavesBoxHalfDayToday": "Is off today from {startHour} to {endHour}.",
    "components.leaveBox.leavesBoxHalfDayTodayWithoutReason": "Is off today from {startHour} to {endHour}.",
    "components.leaveBox.leavesBoxHalfDayWithoutReason": "Is taking a leave on {leaveStartDate} from {startHour} to {endHour}.",
    "components.leaveBox.leavesOffTodayInfo": "Is away for a {leaveTypeName} from {leaveStartDate} to {leaveEndDate}. Both dates included.",
    "components.leaveBox.leavesOffTodayWithoutReasonInfo": "Is away from {leaveStartDate} to {leaveEndDate}. Including both dates.",
    "components.leaveBox.leavesPendingInfo": "Request for {leaveTypeName} from {leaveStartDate} to {leaveEndDate}. Both dates included.",
    "components.leaveBox.leavesPendingOneDay": "Request for {leaveTypeName} on {leaveStartDate}.",
    "components.leaveBox.leavesUpcomingOneDayInfo": "Will be away for a {leaveTypeName} on {leaveStartDate}.",
    "components.leaveBox.leavesUpcomingOneDayWithoutReasonInfo": "Will be away on {leaveStartDate}.",
    "components.leaveBox.reason": "Reason",
    "components.leaveBox.toilDuration": "Duration: {leave}",
    "components.leaveForm.addLeave": "Add Leave",
    "components.leaveForm.addLeaveDescription": "Use this form if you are adding leave for another user, or if you're adding leave for yourself that does not require approval.",
    "components.leaveForm.connectedCalendarTimezone": "Connected {calendarType} calendar timezone is {timezone}",
    "components.leaveForm.date": "Date",
    "components.leaveForm.editLeave": "Edit Leave",
    "components.leaveForm.editLeaveDescription.adminAndApprover": "You are able to edit the dates for this leave. If you would like to edit the leave type, please cancel the leave and add a new one.",
    "components.leaveForm.editLeaveDescription.user": "You are able to edit the dates and the reason for this leave. If you would like to edit the leave type, please cancel the leave and create a new request.",
    "components.leaveForm.endTime": "End Time",
    "components.leaveForm.halfDay": "{leaveTypeName} (Half-Day)",
    "components.leaveForm.hourly": "{leaveTypeName} (Hourly)",
    "components.leaveForm.leaveTypeNamePartTime": "{leaveTypeName} (Hourly)",
    "components.leaveForm.minutesMustMatch": "Please select a valid time range - minutes must match",
    "components.leaveForm.pleaseInputReason": "Reason is required",
    "components.leaveForm.pleaseSelectEndDate": "Select an End Date",
    "components.leaveForm.pleaseSelectLeaveType": "Select a Leave Type",
    "components.leaveForm.pleaseSelectRangeTime": "Select a Time Range",
    "components.leaveForm.pleaseSelectstartDate": "Select a Start Date",
    "components.leaveForm.pleaseSelectUser": "Select a User",
    "components.leaveForm.pleaseSelectValidRangeTime": "Select a Valid Time Range",
    "components.leaveForm.rangeDate": "Start and End Date",
    "components.leaveForm.reason": "Reason",
    "components.leaveForm.request": "Request",
    "components.leaveForm.requestLeave": "Request Leave",
    "components.leaveForm.requestLeaveDescription": "Use this form if your request needs to be approved by someone other than yourself.",
    "components.leaveForm.selectSubstituteApprover": "Select Substitute Approver",
    "components.leaveForm.selectSubstituteApproverInfoMessage": "Select a person to manage your incoming leave requests while you are away. The Substitute Approver will have limited rights, as they will only be able to approve or deny leave requests. This step is optional.",
    "components.leaveForm.selectUser": "Select a User",
    "components.leaveForm.startTime": "Start Time",
    "components.leaveForm.time": "Time",
    "components.leaveForm.user": "Select a User",
    "components.leavePolicyForm.accrualBiWeeklySummary": "Users will accrue {amount} ({quota}/26) days every second {day}",
    "components.leavePolicyForm.accrualBiWeeklySummaryHours": "Users will accrue {amount} ({quota}/26) hours every second {day}",
    "components.leavePolicyForm.accrualCap": "Enable Accrual Cap",
    "components.leavePolicyForm.accrualCapRate": "Cap earned days to",
    "components.leavePolicyForm.accrualCapRateTimes": "times the annual accrual rate",
    "components.leavePolicyForm.accrualCapTooltip": "Accrual Cap limits how much PTO an employee can accrue during a specific period. It is defined as a multiply of the annual accrual rate, not a precise number.",
    "components.leavePolicyForm.accrualCapWarning": "Accrual Cap for {leaveTypeName} reached. Accrual of new days will resume once some existing days are used.",
    "components.leavePolicyForm.accrualCapWarning2": "This user has reached the {leaveTypeName} accrual cap and won't accumulate more days until using some of the current balance.",
    "components.leavePolicyForm.accrualDay": "Accrual Day",
    "components.leavePolicyForm.accrualDayBiWeekly": "Accrual Awarded on",
    "components.leavePolicyForm.accrualDisabledInfo": "Turn off the unlimited quota and set the quota to any number larger than 0 to enable accruals.",
    "components.leavePolicyForm.accrualMonthlySummary": "Users will accrue {amount} ({quota}/12) days on the {date} of a month",
    "components.leavePolicyForm.accrualMonthlySummaryHours": "Users will accrue {amount} ({quota}/12) hours on the {date} of a month",
    "components.leavePolicyForm.accruals": "Accruals",
    "components.leavePolicyForm.accrualSemiMonthlySummary": "Users will accrue {amount} ({quota}/24) days on the {firstDate} and the {secondDate} of a month",
    "components.leavePolicyForm.accrualSemiMonthlySummaryHours": "Users will accrue {amount} ({quota}/24) hours on the {firstDate} and the {secondDate} of a month",
    "components.leavePolicyForm.accrualType": "Accrual Type",
    "components.leavePolicyForm.accrualTypeError": "Required",
    "components.leavePolicyForm.accrualTypeInfo": "Accrued Leave accumulates gradually during the year based on the company's policy. If your company has Accruals, select the Accrual Type determined by your management.",
    "components.leavePolicyForm.accrualWeeklySummary": "Users will accrue {amount} ({quota}/52) days every {day}",
    "components.leavePolicyForm.accrualWeeklySummaryHours": "Users will accrue {amount} ({quota}/52) hours every {day}",
    "components.leavePolicyForm.addTitle": "Assign Leave Policy",
    "components.leavePolicyForm.allDays": "All",
    "components.leavePolicyForm.allowAddAndEditLeaveInPast": "Edit & Request Leaves In The Past",
    "components.leavePolicyForm.allowAddAndEditLeaveInPastInfo": "Choose whether you want to allow regular users to edit or request leaves in the past.",
    "components.leavePolicyForm.allowAdvanceAccrualUsage": "Allow Requesting Accrual-based Leave In Advance",
    "components.leavePolicyForm.allowAdvanceAccrualUsageInfo": "Allow employees to request leave based on their projected accrual of days off, rather than only what they have accumulated at the time of the request. This means that if an employee knows they will have enough accrued days by the time the requested leave occurs, they can submit the request in advance, even if they don't have enough days at the time of making the request.",
    "components.leavePolicyForm.approvalRequired": "Approval Required",
    "components.leavePolicyForm.assignLeavePolicy": "Assign Leave Policy",
    "components.leavePolicyForm.back": "Back",
    "components.leavePolicyForm.BI-WEEKLY": "Biweekly",
    "components.leavePolicyForm.cancel": "Cancel",
    "components.leavePolicyForm.copyLeavePolicy": "Copy Policy From",
    "components.leavePolicyForm.copyLeavePolicyDivider": "You can use a template from another leave or location.",
    "components.leavePolicyForm.copyLeavePolicyInfo": "Copy Leave Policy settings from a previously set Leave Type. In the dropdown menu, choose the Leave Type you want to copy the Leave Policy from, and the settings will be automatically configured. If you wish to do this manually, you can do so by configuring the settings below.",
    "components.leavePolicyForm.createLeaveTypeFormAccrualsInfo1": " Accruals are inactive when creating a leave type. To activate it, customize the leave policy for a specific location.",
    "components.leavePolicyForm.createLeaveTypeFormToilInfo1": "TOIL (Time Off In Lieu) is inactive when creating a leave type. To activate it, customize the leave policy for a specific location.",
    "components.leavePolicyForm.currentAccrualPeriod": "Current Accrual Period",
    "components.leavePolicyForm.daysPerYear": "Yearly Leave Quota",
    "components.leavePolicyForm.defaultLeavePolicy": "Don't copy, I'll start a new policy",
    "components.leavePolicyForm.editTitle": "Edit {leavePolicyName} Leave Policy for {locationName}",
    "components.leavePolicyForm.futureBroughtForward": "Future brought forward",
    "components.leavePolicyForm.halfDays": "Allow Half-Days",
    "components.leavePolicyForm.hasUnlimitedDays": "Unlimited Quota",
    "components.leavePolicyForm.hideLeaveType": "Hide Leave Type",
    "components.leavePolicyForm.hideLeaveTypeInfo": "Choose whether you want to hide this Leave Type from the Calendar and Notifications. Only Administrators, Approvers, and the User who has requested the Leave Type will be able to identify it on the Calendar.",
    "components.leavePolicyForm.hourlyIncrementsTooltip": "Allow your team members to apply for hour-based leave.",
    "components.leavePolicyForm.isActive": "Active",
    "components.leavePolicyForm.leavePolicy": "Leave Policy",
    "components.leavePolicyForm.limitedNumberOfDays": "Limited",
    "components.leavePolicyForm.maxRolloverDays": "Max Brought Forward Days",
    "components.leavePolicyForm.maxRolloverDaysError": "Required",
    "components.leavePolicyForm.maxRolloverDaysInfo": "Select the number of days that can be rolled over into the following calendar year for this Leave Type.",
    "components.leavePolicyForm.maxRolloverDaysInfoHours": "Select the number of hours that can be rolled over into the following calendar year for this Leave Type.",
    "components.leavePolicyForm.MONTHLY": "Monthly",
    "components.leavePolicyForm.negativeBalance": "Allow Negative Balance",
    "components.leavePolicyForm.negativeBalanceInfo": "Choose whether you want to authorize the Users to go over the allowed Leave Quota you've set for this Leave Type. If you do not want to allow this option, please leave the button unchecked. ",
    "components.leavePolicyForm.nextAccrualDay": "Next Accrual Awarded On",
    "components.leavePolicyForm.noLeaveTypes": "There are no available Leave Types. In order to add a new Leave Type with a custom Leave Policy, please go to Leave Types tab to create a new Leave Type, and then add it to the Location.",
    "components.leavePolicyForm.NONE": "None",
    "components.leavePolicyForm.noRollover": "Disabled",
    "components.leavePolicyForm.override.affectedUsers": "Affected users: <strong>{totalUsers}</strong>",
    "components.leavePolicyForm.override.change": "Change: <unlimited>Unlimited leave policy change</unlimited><customDays>Leave quota update</customDays>",
    "components.leavePolicyForm.override.customLeaveQuotaInfo": "Some of the affected Users (<strong>{totalUsers}</strong>) have custom leave quota(s). Do you want to override this setting?",
    "components.leavePolicyForm.override.leaveType": "Leave type: <strong>{name}</strong>",
    "components.leavePolicyForm.override.location": "Location: <strong>{name}</strong>",
    "components.leavePolicyForm.override.note": "Note: This change will affect all users without a custom leave quota, no matter what option you choose. ",
    "components.leavePolicyForm.override.overrideDisable": "Override their custom leave quota",
    "components.leavePolicyForm.override.overrideEnable": "Keep the existing leave quota",
    "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivated": "Pause Accruals When User Is Deactivated",
    "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedInfo": "Activate this feature to temporarily suspend leave day accruals for any user who is deactivated. Once the user is reactivated, their accrual of leave days will automatically continue, ensuring accurate tracking of their leave entitlements",
    "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedUserInfo": "According to your organization's policies, leave accrual is paused while this user's account is inactive. Please contact your administrator for further details.",
    "components.leavePolicyForm.previouesBroughForwardAndFutureOnce": "Previous brought forward and future ones",
    "components.leavePolicyForm.reasonRequired": "Reason Required",
    "components.leavePolicyForm.required": "Required",
    "components.leavePolicyForm.rollover": "Max number",
    "components.leavePolicyForm.rolloverDaysPerYear": "Max number of days",
    "components.leavePolicyForm.rolloverPerYearDays": "Max Number Of Days",
    "components.leavePolicyForm.rolloverPerYearHours": "Max Number Of Hours",
    "components.leavePolicyForm.rolloverSettings": "Brought Forward Settings",
    "components.leavePolicyForm.rolloverType": "Brought forward",
    "components.leavePolicyForm.save": "Save",
    "components.leavePolicyForm.saveLeavePolicy": "Save",
    "components.leavePolicyForm.selectLeaveType": "Select Leave Type",
    "components.leavePolicyForm.SEMI-MONTHLY": "Semimonthly",
    "components.leavePolicyForm.updateLeavePolicy": "Update",
    "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChanges": "When Would You Like To Apply These Changes?",
    "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChangesInfo": "Select the time frame for applying your leave changes. Choose 'Future brought forward' to adjust upcoming leave dates only, or 'Previous brought forward and future ones' to modify both past and future leave dates.",
    "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChanges": "When Would You Like To Apply These Changes?",
    "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChangesInfo": "Select the time frame for applying your leave changes. Choose 'Future brought forward' to adjust upcoming leave dates only, or 'Previous brought forward and future ones' to modify both past and future leave dates.",
    "components.leavePolicyForm.yearlyQuota": "Yearly Leave Quota",
    "components.leavePolicyForm.yearlyQuotaHours": "Yearly Leave Quota (Hours)",
    "components.leavePolicyForm.yearlyQuotaInfo": "This field represents the base yearly leave quota. You will be able to adjust individual quotas manually via user profiles or automate adjustments across different users with our automations feature.",
    "components.leaveRequestAction.added": "Leave <forUser>for </forUser>{requestor} added <byActionCreator>by {actionCreator}</byActionCreator>",
    "components.leaveRequestAction.addToCalendar": "Add to a Calendar",
    "components.leaveRequestAction.addToCalendarMoreInfo": "More info about the \"Add to a Calendar\" feature",
    "components.leaveRequestAction.approved": "{requestor} Leave approved <byActionCreator>by {actionCreator}</byActionCreator> ✅",
    "components.leaveRequestAction.approversLabel": "Approvers: ",
    "components.leaveRequestAction.autoApproved": "{requestor} Leave has been auto-approved",
    "components.leaveRequestAction.cancelled": "{requestor} Leave cancelled <byActionCreator>by {actionCreator}</byActionCreator>",
    "components.leaveRequestAction.created": "Leave requested <byActionCreator>by {actionCreator}</byActionCreator>",
    "components.leaveRequestAction.deleted": "Leave deleted <byActionCreator>by {actionCreator}</byActionCreator>",
    "components.leaveRequestAction.denied": "{requestor} Leave denied <byActionCreator>by {actionCreator}</byActionCreator> ❌",
    "components.leaveRequestAction.edited": "{requestor} Leave edited <byActionCreator>by {actionCreator}</byActionCreator>",
    "components.leaveRequestAction.editedApproved": "A scheduled leave is edited <byActionCreator>by {actionCreator}</byActionCreator> ✅",
    "components.leaveRequestAction.editedAutoApproved": "Auto approved edited leave <byActionCreator>by {actionCreator}</byActionCreator>",
    "components.leaveRequestAction.editedDenied": "Edited Leave denied <byActionCreator>by {actionCreator}</byActionCreator>",
    "components.leaveRequestAction.fullDayLeavePeriod": "{date}{endDate} ",
    "components.leaveRequestAction.leaveRemaining": "Leave Remaining: ",
    "components.leaveRequestAction.leaveRequested": "Leave Requested: ",
    "components.leaveRequestAction.leaveTypeLabel": "Leave Type: ",
    "components.leaveRequestAction.partDayLeavePeriod": "{date} - from {partDayStartHour} to {partDayEndHour} ",
    "components.leaveRequestAction.periodLabel": "Date: ",
    "components.leaveRequestAlertStatus.leaveRequestApprovedByYouInfo": "You have approved the request. A notification has been sent to {name} to inform them.",
    "components.leaveRequestAlertStatus.leaveRequestApprovedInfo": "Leave request has been approved by {approverName}.",
    "components.leaveRequestAlertStatus.leaveRequestCancelledInfo": "The leave request has been canceled.",
    "components.leaveRequestAlertStatus.leaveRequestDeniedByYouInfo": "You have denied the request. A notification has been sent to {name} to inform them.",
    "components.leaveRequestAlertStatus.leaveRequestDeniedInfo": "Leave request has been denied by {approverName}.",
    "components.leaveRequestAlertStatus.leaveRequestExpiredInfo": "The request has expired.",
    "components.leaveRequestAlertStatus.leaveRequestHasApprovedByFirstLevelYouInfo": "{approverName} has approved the first level of this request. No further action is required at this point. The leave request has been forwarded to the second-level approver(s) for final approval.",
    "components.leaveRequestAlertStatus.leaveRequestHasApprovedByYouInfo": "The leave request has been approved.",
    "components.leaveRequestAlertStatus.leaveRequestHasApprovedByYouSecondLevelInfo": "{approverName} has approved the final level of this request. The leave request has been successfully approved. A notification has been sent to {name} to inform them.",
    "components.leaveRequestAlertStatus.leaveRequestHasDeniedByYouInfo": "The leave request has been denied.",
    "components.leavesColumns.approver": "Approver",
    "components.leavesColumns.autoApproved": "Auto approved",
    "components.leavesColumns.cancelledBeforeReview": "Cancelled before review",
    "components.leavesColumns.dates": "Date(s)",
    "components.leavesColumns.days": "{value} days",
    "components.leavesColumns.denyReason": "Approver's Reason",
    "components.leavesColumns.duration": "Duration",
    "components.leavesColumns.halfDayHoliday": "(Half-Day Holiday)",
    "components.leavesColumns.hours": "{value} hours",
    "components.leavesColumns.leaveType": "Leave Type",
    "components.leavesColumns.reason": "Requester's Reason",
    "components.leavesColumns.reviewedby": "Reviewed by",
    "components.leavesColumns.status": "Status",
    "components.leaveTypeForm.cancel": "Cancel",
    "components.leaveTypeForm.color": "Color",
    "components.leaveTypeForm.editLeaveTypeTitle": "Edit {leaveTypeName} Leave Type",
    "components.leaveTypeForm.forbiddenCharacters": "Leave Type name can't contain any punctuation or special characters.",
    "components.leaveTypeForm.isActive": "Active",
    "components.leaveTypeForm.maxLength": "Leave Type name is too long.",
    "components.leaveTypeForm.name": "Name",
    "components.leaveTypeForm.nameIsRequired": "This field is required.",
    "components.leaveTypeForm.save": "Save",
    "components.leaveTypeForm.setSlackStatus": "Set Slack Status",
    "components.leaveTypeForm.setSlackStatusPlaceholder": "Please set the Slack status",
    "components.leaveTypeForm.slackStatus": "Slack Status",
    "components.leaveTypeForm.title": "Leave Type",
    "components.leaveTypeForm.tooltipElement1": "<strong>Approval Required</strong>: This option can be turned on if a leave requires a manager's approval.",
    "components.leaveTypeForm.tooltipElement2": "<strong>Allow Negative Balance</strong>: This option allows users to apply for leave even after they have used up their quota.",
    "components.leaveTypeForm.tooltipElement3": "<strong>Allow Half-Days</strong>: This option can be activated as per your organization's needs.",
    "components.leaveTypeForm.tooltipFooter": "Remember, it's important to note that Leave Policies are defined per location. You can customize these policies according to your organization's needs and assign them to the appropriate leave types.",
    "components.leaveTypeForm.tooltipHeader": "In Vacation Tracker, 'Leave Types' refer to global categories of leaves such as Paid Time Off, Sick Day, Working from Home, and so forth. On the other hand, 'Leave Policies' are specific rules and settings that are assigned to each Leave Type per location. These policies can include various options such as:",
    "components.legacyPlanAlert.warning": "You are currently using the legacy plan. If you change plans, you won't be able to activate it again. For more information please contact our <link>support</link>.",
    "components.locationForm.changeLocationWarning": "Select the Location you want to transfer the User to.",
    "components.locationForm.changeUserLocation": "Change User's Location",
    "components.locationForm.days": "Days",
    "components.locationForm.daysAfterEmploymentDate": "days after the employment date.",
    "components.locationForm.daysInfo": "The rollover of unused leave to the following calendar year will expire after the number of days you've set following the User's Employment Date. Leave Quota for each Leave Type is configured through the Leave Types tab on Location Settings, including the option to enable or disable the rollover.",
    "components.locationForm.defaultLocation": "Make This Location Default",
    "components.locationForm.firstDayOfWeek": "Week Starts On",
    "components.locationForm.fiscalYear": "Accounting Year",
    "components.locationForm.fiscalYearStart": "Fiscal Year Start",
    "components.locationForm.name": "Name",
    "components.locationForm.noRolloverExpiryDate": "No Brought Forward Expiry Date",
    "components.locationForm.overrideUserSettings": "Override User Settings",
    "components.locationForm.overrideUserSettingsInfo": "Workweek configuration will be applied only to the Users who do not have custom Settings on their User Profile. If you want to apply the new Workweek settings to all Users, including those who have custom Workweek set, select the \"Override User Settings\" option.",
    "components.locationForm.resetQuotas": "Leave Quota Reset Based On",
    "components.locationForm.resetQuotasInfo": "This setting will determine if your leave balance will be reset based on the accounting year (company's fiscal year) or based on the employee's start date. Besides quotas, your roll-over policy will also be affected according to this setting.",
    "components.locationForm.rolloverExpiryAfterDays": "No Brought Forward Expiry Date",
    "components.locationForm.rolloverExpiryAfterDaysInfo": "Each year, the users rolled over leaves will expire this many days after their employment date. The quotas for each leave type are configured through the Leave Types section for this location and each can be set individually to allow or not allow roll overs.",
    "components.locationForm.rolloverExpiryDay": "No Brought Forward Expiry Date",
    "components.locationForm.rolloverExpiryDayInfo": "Each year, the user's rolled over leaves will expire on the date you set. The quotas for each leave type are configured through the Leave Types section for this location and each can be set individually to allow or not allow roll overs.",
    "components.locationForm.selectLocation": "Select Location",
    "components.locationForm.selectLocationError": "Please select a location.",
    "components.locationForm.settings.makeDefaultDescription": "By making this Location the default one, all new team members will be automatically added to this Location.",
    "components.locationForm.settings.makeDefaultTitle": "Default Location",
    "components.locationForm.settings.nameDescription": "Set the name of your location and select its users. If you want to configure the location before adding users, you can create the location first and add users later.",
    "components.locationForm.settings.nameDescription2": "This default time zone is used throughout the system. For example for accurately displaying leave information in the calendar and for the system events listed in the Logs.",
    "components.locationForm.settings.namePlaceholder": "Enter location name",
    "components.locationForm.settings.nameTitle": "Location Settings",
    "components.locationForm.settings.quotasDescription": "This setting will determine if your leave balance will be reset based on the accounting year (company's fiscal year) or based on the employee's start date. Besides quotas, your roll-over policy will also be affected according to this setting.",
    "components.locationForm.settings.quotasTitle": "Leave Quota and Fiscal Year",
    "components.locationForm.settings.workWeekDescription": "Please select the working days of the week to ensure accurate tracking of leave data.",
    "components.locationForm.settings.workWeekTitle": "Workweek and Workday",
    "components.locationForm.users": "Users",
    "components.locationForm.userYear": "User Employment Date",
    "components.locationForm.warningInitialRollover": "Any changes to location settings might impact the users' configuration and leave information such as leave quota and calculations. \nPlease note that if you have a brought forward expiry date set on any leave policy in this location, it will be disabled.",
    "components.locationForm.warningInitialRolloverFiscalYear": "Any changes to location settings might impact the users' configuration and leave information such as leave quota and calculations.",
    "components.locationForm.warningInitialRolloverResetQuotas": "Any changes to location settings might impact the users' configuration and leave information such as leave quota and calculations.",
    "components.locationForm.warningInitialRolloverUsers": "Adding or removing a user might impact the user's configuration and leave information (e.g. the initial brought forward days).",
    "components.locationForm.workWeek": "Workweek",
    "components.locationHolidays.addHolidays": "Add a Holiday",
    "components.locationHolidays.deleteHolidaysConfirm": "Are you sure you want to delete holiday {name}?",
    "components.locationHolidays.deleteHolidaysTitle": "Delete Holiday",
    "components.locationHolidays.holidaysForLocation": "Holidays for {locationName} location",
    "components.locationHolidays.insertHolidays": "Import Holidays",
    "components.locationHolidays.overlap": "This holiday overlaps with an existing one",
    "components.locationLeavePolicies.activatingLeaveTypeInfo": "By activating this Leave Type, you're allowing the Users to request the specific Leave Type. \n\nPrevious settings and data will be restored.",
    "components.locationLeavePolicies.activatingLeaveTypeTitle": "Activate Leave Type",
    "components.locationLeavePolicies.allowHalfDays": "Allow Half-Days",
    "components.locationLeavePolicies.createNewLeavePolicy": "Assign Leave Policy",
    "components.locationLeavePolicies.createNewLeavePolicyInfo": "To add a custom Leave Type to a Location, go to Assign Leave Policy. Select the Leave Type you've already created through the Leave Types tab, and configure Leave Policy settings. ",
    "components.locationLeavePolicies.daysPerYear": "Yearly Leave Quota",
    "components.locationLeavePolicies.deactivateLeaveTypeInfo": "Once you deactivate this Leave Type, the Users on this Location will no longer be allowed to request the specific Leave Type. When you activate it again, the Leave Type will be restored with it's previous settings and data. \n\nIf you want to deactivate this Leave Type for all Locations, you can do so from the Leave Types tab.",
    "components.locationLeavePolicies.deactivateLeaveTypeTitle": "Deactivate Leave Type",
    "components.locationLeavePolicies.hasUnlimitedDays": "Unlimited",
    "components.locationLeavePolicies.isApprovalRequired": "Approval Required",
    "components.locationLeavePolicies.leaveTypeName": "Leave Type Name",
    "components.locationLeavePolicies.status": "Status",
    "components.locationLeavePolicy.createInProgress": "Creating the {name} leave policy",
    "components.locationLeavePolicy.successTitle": "The leave policy is set",
    "components.locationLeavePolicy.updateInProgress": "Updating the leave policy",
    "components.locationLeaveTypesTag.hiddenLeaveType": "Hidden Leave Type",
    "components.locationLeaveTypesTag.holidays": "Holidays",
    "components.logs.accrual": "User accrued {earned} {leaveTypeName} {earnedDayInGrammaticalNumber} (out of {defaultDaysPerYear} {defaultDayInGrammaticalNumber} quota)",
    "components.logs.accrualDays": "User accrued {earned} {leaveTypeName} {earned, plural, =1 {day} other {days}} (out of {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {day} other {days}} quota)",
    "components.logs.accrualDisplay": "User accrued {earned} {leaveTypeName} (out of {defaultDaysPerYear} quota)",
    "components.logs.accrualHours": "User accrued {earned} {leaveTypeName} {earned, plural, =1 {hour} other {hours}} (out of {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {hour} other {hours}} quota)",
    "components.logs.automationAddonsUpdatedPurchased": "{user} purchased Add-On: {automationType}",
    "components.logs.automationAddonsUpdatedUnsubscribed": "{user} unsubscribed from Add-On: {automationType}",
    "components.logs.billingCancelled": "Billing cancelled on {paymentProcessor}",
    "components.logs.billingUpdated": "Billing updated on {paymentProcessor}",
    "components.logs.blackoutPeriodCreated": "{user} created a Blackout Period <b>{name}</b>.<br></br>Enabled: {enabled}.<br></br>Recurring: {recurring}.<br></br>Start Date: {startDate}.<br></br>End Date: {endDate}.<br></br>Leave Types: {leaveTypes}.<br></br>Locations: {locations}.<br></br>Departments: {teams}.<br></br>Labels: {labels}.<br></br>",
    "components.logs.blackoutPeriodDeleted": "{user} deleted a Blackout Period.",
    "components.logs.blackoutPeriodUpdated": "{user} updated a Blackout Period <b>{name}</b>.<br></br>Enabled: {enabled}.<br></br>Recurring: {recurring}.<br></br>Start Date: {startDate}.<br></br>End Date: {endDate}.<br></br>Leave Types: {leaveTypes}.<br></br>Locations: {locations}.<br></br>Departments: {teams}.<br></br>Labels: {labels}.<br></br>",
    "components.logs.companyCreated": "Company <b>{name}</b> is created",
    "components.logs.companySettingsUpdated": "<b>{user}</b> updated company settings.<br></br>Announce new users: {announceNewUsers}.<br></br>Hourly leave accounting: {hourlyLeaveAccounting}.<br></br>User end date: {userEndDate}.<br></br>Calculation Start Year: {calculationStartYear}<br></br>Import users automatically: {importUsersAutomatically}",
    "components.logs.deleted": "<Deleted>",
    "components.logs.earned": "User earned {total} days of {leaveTypeName} from overtime work (TOIL) {period}. {showExpiration, plural, =1 {Days will expire on } other {}}{expiration}",
    "components.logs.entitlementByRoleCreated": "{user} created a Entitlement By Role <b>{name}</b>.<br></br>Enabled: {enabled}.<br></br>Amount: {amount} {units}.<br></br>Label: {label}.<br></br>Leave Type: {leaveType}.<br></br>",
    "components.logs.entitlementByRoleDeleted": "{user} deleted a Entitlement By Role.",
    "components.logs.entitlementByRoleUpdated": "{user} updated a Entitlement By Role <b>{name}</b>.<br></br>Enabled: {enabled}.<br></br>Amount: {amount} {units}.<br></br>Label: {label}.<br></br>Leave Type: {leaveType}.<br></br>",
    "components.logs.failedToLoadLog": "Log failed to load",
    "components.logs.importLeaves": "<b>{user}</b> imported {totalLeaves} {totalLeaves, plural, =1 {leave} other {leaves}}.",
    "components.logs.importUsers": "<b>{user}</b> imported {totalUsers} {totalUsers, plural, =1 {user} other {users}}.",
    "components.logs.initialRolloverDeleted": "Initial Brought Forwarded for Leave Type: {leaveTypeName} reset to 0 by <link>{creatorName}</link>",
    "components.logs.initialRolloverUpdatedDays": "Initial Brought Forwarded for Leave Type: {leaveTypeName} changed to {amount} {amount, plural, =1 {day} other {days}} by <link>{creatorName}</link>",
    "components.logs.initialRolloverUpdatedHours": "Initial Brought Forwarded for Leave Type: {leaveTypeName} changed to {amount} {amount, plural, =1 {hour} other {hours}} by <link>{creatorName}</link>",
    "components.logs.leaveDurationLimitCreated": "{user} created a Leave Duration Limit <b>{name}</b>.<br></br>Enabled: {enabled}.<br></br>Start date: {startDate}.<br></br>End date: {endDate}.<br></br>Minimum duration: {minimumDuration}.<br></br>Maximum duration: {maximumDuration}.<br></br>Recurring: {recurring}.<br></br>Auto reject response: {autoRejectResponse}.",
    "components.logs.leaveDurationLimitDeleted": "{user} deleted a Leave Duration Limit.",
    "components.logs.leaveDurationLimitUpdated": "{user} updated a Leave Duration Limit <b>{name}</b>.<br></br>Enabled: {enabled}.<br></br>Start date: {startDate}.<br></br>End date: {endDate}.<br></br>Minimum duration: {minimumDuration}.<br></br>Maximum duration: {maximumDuration}.<br></br>Recurring: {recurring}.<br></br>Auto reject response: {autoRejectResponse}.",
    "components.logs.leavePolicyDisabled": "{user} disabled leave policy <b>{leaveTypeName}</b> on location <b>{locationName}</b>",
    "components.logs.leavePolicyEnabled": "{user} enabled leave policy <b>{leaveTypeName}</b> on location <b>{locationName}</b>.<br></br>Quota: {daysPerYear} {units}.<br></br>Max rollover: {maxRolloverDays} {units}.<br></br>Should rollover days expire: {enableRolloverExpiry}.<br></br>Rollover days expire on: {rolloverExpiryDate}.<br></br>Rollover days expire after days: {rolloverExpiryAfterDays}.<br></br>Negative brought forward: {negativeBroughtForwardAllowed}<br></br>Approval required: {isApprovalRequired}.<br></br>Unlimited days: {hasUnlimitedDays}.<br></br>Shortest leave interval: {shortestLeaveInterval}.<br></br>Reason required: {isReasonRequired}.<br></br>Accrual type: {accrualType}.<br></br>Negative ballance allowed: {negativeBallanceAllowed}.<br></br>Allow leave in past: {allowLeaveInPast}.<br></br>Accrual cap: {accrualCapRate, plural, =0 {Disabled} other {Enabled with rate {accrualCapRate}}}.<br></br>Hide leave type: {hideLeaveType}.<br></br>Toil enabled: {toil}.<br></br>Toil shortest interval: {toilShortestInterval}.<br></br>Toil all users: {toilRequestsAllowedForUsers}.<br></br>Toil expiration: {toilExpiration}.<br></br>Toil expiration date: {toilExpirationDate}.<br></br>Toil expiration months: {toilExpirationMonths}.",
    "components.logs.leaveTypeCreated": "{user} created leave type <b>{name}</b> and assigned on location(s): <b>{locations}</b>.<br></br>Color: {color}.<br></br>Days per year: {daysPerYear}.<br></br>Max number of rollover days: {maxRolloverDays}.<br></br>Approval required: {isApprovalRequired}.<br></br>Unlimited days: {hasUnlimitedDays}.<br></br>Shortest leave interval: {shortestLeaveInterval}.<br></br>Reason required: {isReasonRequired}.<br></br>Accrual type: {accrualType}.<br></br>Negative ballance allowed: {negativeBallanceAllowed}.<br></br>Allow addding leaves in the past: {allowLeaveInPast}.<br></br>Hide leave type: {hideLeaveType}",
    "components.logs.leaveTypeDeleted": "{user} deleted a leave type <b>{name}</b>",
    "components.logs.leaveTypeUpdated": "{user} updated leave type <b>{name}</b>.<br></br>Active: {isActive}.<br></br>Color: {color}",
    "components.logs.locationCreated": "{user} created a location <b>{name}</b>.<br></br>Year start month: {yearStartMonth}.<br></br>Year start day: {yearStartDay}.<br></br>Default location: {isDefault}.<br></br>First day of week: {firstDayOfWeek}.<br></br>Quota Reset Based On: {resetQuotas}.<br></br>Rollover expiry month: {rolloverExpiryMonth}.<br></br>Rollover expiry day: {rolloverExpiryDay}.<br></br>Rollover expiry after days: {rolloverExpiryAfterDays}.<br></br>Timezone: {timezone}.<br></br>Users: {users}.<br></br>Days: {days}",
    "components.logs.locationDeleted": "{user} deleted location",
    "components.logs.locationHolidaysAutoimportOFF": "<b>{user}</b> turned off auto import holidays for location <b>{location}</b>",
    "components.logs.locationHolidaysAutoimportON": "<b>{user}</b> turned on auto import holidays for location <b>{location}</b><br></br>Holidays: {holidays}",
    "components.logs.locationUpdated": "{user} updated location <b>{name}</b>.<br></br>Year start month: {yearStartMonth}.<br></br>Year start day: {yearStartDay}.<br></br>Default location: {isDefault}.<br></br>First day of week: {firstDayOfWeek}.<br></br>Reset quotas: {resetQuotas}.<br></br>Rollover expiry month: {rolloverExpiryMonth}.<br></br>Rollover expiry day: {rolloverExpiryDay}.<br></br>Rollover expiry after days: {rolloverExpiryAfterDays}.<br></br>Timezone: {timezone}.<br></br>Users: {users}",
    "components.logs.locationWorkWeekUpdated": "{user} updated work week on location <b>{name}</b>.<br></br>Days: {days}.<br></br>Hours: {hours}",
    "components.logs.locationYearHolidaysChanged": "<b>{user}</b> changed holiday(s) for year {year} on location <b>{name}</b>.<br></br>Holidays: {holidays}",
    "components.logs.locationYearRollover": "Location year rollover happened on location <b>{name}</b>",
    "components.logs.nonAccrualLeaveType": "User received {earned} <b>{leaveTypeName}</b> for {period}",
    "components.logs.nonAccrualLeaveTypeDays": "User received {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {day} other {days}} for {period}",
    "components.logs.nonAccrualLeaveTypeHours": "User received {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {hour} other {hours}} for {period}",
    "components.logs.notificationCreated": "User {user} created a notification <b>{name}</b>.<br></br>Frequency: {frequency}.<br></br>Enabled: {enabled}.<br></br>Locations: {locations}.<br></br>Departments: {teams}.<br></br>Labels: {labels}.<br></br>First day of week: {firstDayOfWeek}.<br></br>Timezone: {timezone}.<br></br>Group by {groupBy}.<br></br>Send empty: {sendEmpty}.<br></br>Destinations: {destinations}.<br></br>Minute: {minute}.<br></br>Hour: {hour}.<br></br>Day: {day}.<br></br>Language: {locale}.<br></br>Send on days: {sendOnDays}.<br></br>Current week: {currentWeek}",
    "components.logs.notificationDeleted": "User {user} deleted notification",
    "components.logs.notificationDetailsDaily": "User {user} {action} notification <b>{name}</b>.<br></br>Frequency: {frequency}.<br></br>Enabled: {enabled}.<br></br>Locations: {locations}.<br></br>Departments: {teams}.<br></br>Labels: {labels}.<br></br>Timezone: {timezone}.<br></br>Group by {groupBy}.<br></br>Send empty: {sendEmpty}.<br></br>Destinations: {destinations}.<br></br>Minute: {minute}.<br></br>Hour: {hour}.<br></br>Language: {locale}.<br></br>Send on days: {sendOnDays}.",
    "components.logs.notificationDetailsMonthly": "User {user} {action} notification <b>{name}</b>.<br></br>Frequency: {frequency}.<br></br>Enabled: {enabled}.<br></br>Locations: {locations}.<br></br>Departments: {teams}.<br></br>Labels: {labels}.<br></br>First day of week: {firstDayOfWeek}.<br></br>Timezone: {timezone}.<br></br>Group by {groupBy}.<br></br>Send empty: {sendEmpty}.<br></br>Destinations: {destinations}.<br></br>Minute: {minute}.<br></br>Hour: {hour}.<br></br>Day: {day}.<br></br>Language: {locale}.<br></br>Current week: {currentWeek}",
    "components.logs.notificationUpdated": "User {user} updated a notification <b>{name}</b>.<br></br>Frequency: {frequency}.<br></br>Enabled: {enabled}.<br></br>Locations: {locations}.<br></br>Departments: {teams}.<br></br>Labels: {labels}.<br></br>First day of week: {firstDayOfWeek}.<br></br>Timezone: {timezone}.<br></br>Group by {groupBy}.<br></br>Send empty: {sendEmpty}.<br></br>Destinations: {destinations}.<br></br>Minute: {minute}.<br></br>Hour: {hour}.<br></br>Day: {day}.<br></br>Language: {locale}.<br></br>Send on days: {sendOnDays}.<br></br>Current week: {currentWeek}",
    "components.logs.probationPeriodCreated": "{user} created a Probation Period <b>{name}</b>.<br></br>Enabled: {enabled}.<br></br>Length (days): {lengthDays}.<br></br>Leave Types: {leaveTypes}.<br></br>Locations: {locations}.<br></br>Departments: {teams}.<br></br>Labels: {labels}.<br></br>",
    "components.logs.probationPeriodDeleted": "{user} deleted a Probation Period.",
    "components.logs.probationPeriodUpdated": "{user} updated a Probation Period <b>{name}</b>.<br></br>Enabled: {enabled}.<br></br>Length (days): {lengthDays}.<br></br>Leave Types: {leaveTypes}.<br></br>Locations: {locations}.<br></br>Departments: {teams}.<br></br>Labels: {labels}.<br></br>",
    "components.logs.seniorityEntitlementCreated": "{user} created a Seniority Entitlement <b>{name}</b>.<br></br>Enabled: {enabled}.<br></br>Prorated: {prorated}.<br></br>Start Date: {startDate}.<br></br> LeaveType: {leaveType}.<br></br>Awarded: {entitlementPeriods}.<br></br>Locations: {locations}.<br></br>Departments: {teams}.<br></br>Labels: {labels}.<br></br>",
    "components.logs.seniorityEntitlementDeleted": "{user} deleted a Seniority Entitlement.",
    "components.logs.seniorityEntitlementUpdated": "{user} updated Seniority Entitlement <b>{name}</b>.<br></br>Enabled: {enabled}.<br></br>Prorated: {prorated}.<br></br>Start Date: {startDate}.<br></br> LeaveType: {leaveType}.<br></br>Awarded: {entitlementPeriods}.<br></br>Locations: {locations}.<br></br>Departments: {teams}.<br></br>Labels: {labels}.<br></br>",
    "components.logs.subscriptionCancelled": "<b>{user}</b> cancelled subscription.<br></br>Reason: {reason}.<br></br>Comment: {comment}",
    "components.logs.subscriptionChanged": "Subscription changed.<br></br>New plan: {plan}.<br></br>Period: {period}.<br></br>Status: {status}.<br></br>Payment processor: {paymentProcessor}",
    "components.logs.substituteApproverCreated": "{substituteApproverName} was set as a Substitute Approver for {approverFor} by {creator} starting from {startDate} to {endDate}",
    "components.logs.substituteApproverDeleted": "{creator} removed {substituteApproverName} as a Substitute Approver.",
    "components.logs.substituteApproverUpdated": "{creator} updated substitute approver.<br></br>Substitute Approver name: {substituteApproverName}<br></br>Start date: {startDate}<br></br>End date: {endDate}<br></br>",
    "components.logs.teamApproverStatusChanged": "{user} set approvers {approvers} for team {team}",
    "components.logs.teamCreated": "{user} created a department <b>{team}</b>.<br></br>Default department: {isDefault}.<br></br>Users: {users}.<br></br>Approver(s): {approvers} <br></br>Parent department: {parentDepartment}",
    "components.logs.teamDeleted": "{user} deleted team",
    "components.logs.teamMaximumUsersAwayCreated": "{user} created a Maximum Users Away <b>{name}</b> configuration.<br></br>Enabled: {enabled}.<br></br>Recurring: {recurring}.<br></br>Max users {maxUsersAwayType} allowed: {maxUsersAllowed}.<br></br>Start Date: {startDate}.<br></br>End Date: {endDate}.<br></br>Leave Types: {leaveTypes}.<br></br>Locations: {locations}.<br></br>Departments: {teams}.<br></br>Labels: {labels}.<br></br>",
    "components.logs.teamMaximumUsersAwayDeleted": "{user} deleted a Maximum Users Away configuration.",
    "components.logs.teamMaximumUsersAwayUpdated": "{user} updated a Maximum Users Away <b>{name}</b> configuration.<br></br>Enabled: {enabled}.<br></br>Recurring: {recurring}.<br></br>Max users {maxUsersAwayType} allowed: {maxUsersAllowed}.<br></br>Start Date: {startDate}.<br></br>End Date: {endDate}.<br></br>Leave Types: {leaveTypes}.<br></br>Locations: {locations}.<br></br>Departments: {teams}.<br></br>Labels: {labels}.<br></br>",
    "components.logs.teamUpdated": "{user} updated a department <b>{team}</b>.<br></br>Default department: {isDefault}.<br></br>Users: {users}.<br></br>Approver(s): {approvers} <br></br>Parent department: {parentDepartment}",
    "components.logs.userActivatedBy": "User was activated by <link>{creatorName}</link>",
    "components.logs.userApproverStatus": "User is <status></status> as an approver for <team>{teamName}</team> department by <link>{creatorName}</link>",
    "components.logs.userChangedAvatar": "User updated their profile picture",
    "components.logs.userDeactivatedBy": "User was deactivated by <link>{creatorName}</link>",
    "components.logs.userDeletedBy": "User was deleted by <link>{creatorName}</link>",
    "components.logs.userEntitlementByRole": "User was granted {totalDays} {totalDays, plural, =1 {day} other {days}} of {leaveTypeName} through Entitlement by Role",
    "components.logs.userEntitlementByRoleDeleted": "User's Entitlement by Role days for {leaveTypeName} were removed due to the removal or deletion of the <b>{labelName}</b> label.",
    "components.logs.userEntitlementByRoleOverride": "User's entitlement days for {leaveTypeName} have been overridden to {totalDays} {totalDays, plural, =1 {day} other {days}} due to Entitlement by Role adjustments",
    "components.logs.userInvitationAccepted": "User accepted invitation by <link>{creatorName}</link>",
    "components.logs.userLeaveTypeDeleteQuota": "Quota for Leave type <b>{leaveTypeName}</b> was reset to default value by <link>{creatorName}</link>",
    "components.logs.userLeaveTypeUnlimitedDays": "unlimited",
    "components.logs.userLeaveTypeUpdatedQuota": "<link>{creatorName}</link> has changed the quota for this user for <b>{leaveTypeName}</b> to <quota></quota> days",
    "components.logs.userLeaveTypeUpdatedQuotaDays": "<link>{creatorName}</link> has changed the quota for this user for <b>{leaveTypeName}</b> to <quota></quota> days",
    "components.logs.userLeaveTypeUpdatedQuotaHours": "<link>{creatorName}</link> has changed the quota for this user for <b>{leaveTypeName}</b> to <quota></quota> hours",
    "components.logs.userMovedToLocation": "User was moved to location <locationLink>{location}</locationLink> by <link>{creatorName}</link>",
    "components.logs.userMovedToTeam": "User was moved to department <teamLink>{team}</teamLink> by <link>{creatorName}</link>",
    "components.logs.userRolloverDays": "Brought Forward from previous year: {leaveTypeName}, {value} day{s}",
    "components.logs.userRolloverDisplay": "Brought Forward from previous year: {leaveTypeName}, {value}",
    "components.logs.userRolloverExpired": "<b>{total}</b> <b>{leaveTypeName}</b> days from previous year have expired",
    "components.logs.userRolloverExpiredDays": "Days from previous year have expired: <b>{leaveTypeName}</b> - {value} day{s}",
    "components.logs.userRolloverExpiredHours": "Days from previous year have expired: <b>{leaveTypeName}</b> - {value} hour{s}",
    "components.logs.userRolloverHours": "Brought Forward from previous year: <b>{leaveTypeName}</b>, {value} hour{s}",
    "components.logs.userSeniorityEntitlement": "User was granted {totalDays} day(s) of {leaveTypeName} through Seniority Entitlement",
    "components.logs.userSeniorityEntitlementOverride": "User's entitlement days for {leaveTypeName} have been overridden to {totalDays} day(s) due to Seniority Entitlement adjustments",
    "components.logs.userUpdated": "User <name></name> <email></email> <startDate></startDate> <endDate></endDate> <userStatus></userStatus> by <link>{creatorName}</link>",
    "components.logs.userUpdatedChangeStatusToAdmin": "role changed to Administrator",
    "components.logs.userUpdatedChangeStatusToUser": "role changed to User",
    "components.logs.userUpdatedEmail": "email changed to {email}",
    "components.logs.userUpdatedEndDate": "end date changed to {endDate}",
    "components.logs.userUpdatedName": "Name changed to <wrappedName>{name}</wrappedName>",
    "components.logs.userUpdatedStartDate": "Start date changed to {startDate}",
    "components.logs.userWorkWeek": "User work week was changed to {workDays} by <link>{creatorName}</link>",
    "components.logs.userWorkWeekDefaultLocation": "User work week was reset to location default by <link>{creatorName}</link>",
    "components.logs.visibilityAddonCreated": "{user} limited visibility for company.<br></br>Enabled: {enabled}.<br></br> Visibility level: {visibilityLevel}.<br></br>",
    "components.logs.visibilityAddonUpdated": "{user} updated limited visibility for company.<br></br>Enabled: {enabled}.<br></br> Visibility level: {visibilityLevel}.<br></br>",
    "components.manageAssignLicenses.assignLicenses": "Assign {numberOfToAssignLicenses} licenses",
    "components.manageAssignLicenses.avaliableToAssign": "Avaliable to assign: {avaliableLicenses}",
    "components.manageAssignLicenses.microsoftTeamsMessage": "Microsoft Teams Message",
    "components.manageAssignLicenses.moreUserThenLicenses": "You have assigned {users} licenses more than available",
    "components.manageAssignLicenses.placeholderBulkWelcomeMessage": "Select bulk action",
    "components.manageAssignLicenses.placeholderSelectRole": "Select role",
    "components.manageAssignLicenses.roleTooltipInfo": "Assign user roles in Vacation Tracker. For more info, visit our <helpDesk>Helpdesk</helpDesk>.",
    "components.manageAssignLicenses.sendWelcomeMessage": "Send welcome message",
    "components.manageAssignLicenses.totalLicenses": "Total licenses: {numberOflicenses}",
    "components.maximumUsersAwaySummaryInfo.summary": "This policy limits the number of users who can be on leave simultaneously to {noOfUsers} user<multiUsers>s</multiUsers> <link>(View <isAbsolute> Filtered</isAbsolute> User<multiUsers>s</multiUsers>)</link> This limit applies <endDate> between</endDate> {fromDate} <endDate>and {toDate}</endDate><recurring> each year</recurring>. When the limit is reached, any new leave requests will be automatically rejected with the specified message.",
    "components.modal.confirm": "Confirm",
    "components.modal.verify": "Verify",
    "components.notificationDestination.label": "Send to",
    "components.notificationDestination.tooltip": "Note that you can send the Notification to multiple emails, but only one channel at a time.",
    "components.notificationForm.channel": "Channel",
    "components.notificationForm.channelSlackInfo": "Notifications can be sent only to the channels you're a part of.",
    "components.notificationForm.daily": "Daily",
    "components.notificationForm.day": "Day",
    "components.notificationForm.firstDayOfWeek": "Week Starts On",
    "components.notificationForm.refreshTheList": "Refresh the list",
    "components.notificationForm.team": "Microsoft Teams team",
    "components.notificationForm.time": "Time",
    "components.notificationForm.timezone": "Time zone",
    "components.notificationForm.weekly": "Weekly",
    "components.notificationSummaryInfo.notificationSummary": "The Notification will be sent out <to>to</to> <channelWrapper>{channelName} </channelWrapper> <and>and</and> <addressesWrapper>{numberOfEmailAddress} email address<multiEmailAddress>es</multiEmailAddress></addressesWrapper> on {sendingDaysInfo} at {time} o'clock. It will include leave information of <danger>{noOfUsers} User<multiUsers>s</multiUsers></danger> <link>(View User<multiUsers>s</multiUsers>)</link>",
    "components.onboarding.assignLicensesTitle": "<icon></icon> Assign Licenses",
    "components.onboarding.installMSBotButton": "Install the bot",
    "components.onboarding.installMSBotDescription": "Install the Vacation Tracker Bot and Tabs to easily manage your team’s leave in Microsoft Teams.",
    "components.onboarding.installMSBotTitle": "<icon></icon> Complete Bot Setup",
    "components.onboarding.inviteFriendsDescription": "Get your team on board for accurate and timely leave management. (Add at least 3 team members to complete this step.)",
    "components.onboarding.inviteFriendsTitle": "<icon></icon> Invite Your Team Members",
    "components.onboarding.joyride.admin.assignLeavePolicy.info1": "As we’ve mentioned before, Leave Types are created for the entire Organization. Leave Policies are tied to Locations. Each Leave Type can be set for each Location by assigning it a custom Leave Policy.",
    "components.onboarding.joyride.admin.assignLeavePolicy.info2": "Once you select the Leave Type you want to add to the Location, you can assign the Leave Policy. You have the option to copy Settings from an existing Leave Policy, or create a completely new Leave Policy with new values.",
    "components.onboarding.joyride.admin.assignLeavePolicy.title": "Assign Leave Policies",
    "components.onboarding.joyride.admin.createLocation.info1": "If you have team members in different locations, you have the option to create multiple Locations in Vacation Tracker and add Users to the corresponding Location.",
    "components.onboarding.joyride.admin.createLocation.info2": "Each Location has its own Leave Policies which you need to assign to a certain Leave Type. Holidays are also tied to Locations.",
    "components.onboarding.joyride.admin.createLocation.title": "Create a Location",
    "components.onboarding.joyride.admin.createNotifications.info1": "Now that you've completed the steps mentioned above, it's time to create Notifications.",
    "components.onboarding.joyride.admin.createNotifications.title": "Create and Edit Notifications",
    "components.onboarding.joyride.admin.createTeams.info1": "Once you’ve completed the The Dashboard gives you an overview of today’s and scheduled leaves within your organization. mentioned above, it’s time to create Departments.",
    "components.onboarding.joyride.admin.createTeams.info2": "When creating a Department, you’ll have to add the Approvers. Approvers have the second highest level of permissions in Vacation Tracker, and can approve/deny leave requests sent by Users from the Department in which they are the Approver.",
    "components.onboarding.joyride.admin.createTeams.title": "Create Departments and Assign Approvers",
    "components.onboarding.joyride.admin.helpDesk.info": "If you have any questions or need more information, check out our Help Desk or start a chat with our customer support team.",
    "components.onboarding.joyride.admin.helpDesk.title": "Help Desk",
    "components.onboarding.joyride.admin.importHolidays.info1": "Holidays in Vacation Tracker are set for each Location, and not for the entire Organization.",
    "components.onboarding.joyride.admin.importHolidays.info2": "You have the option to Import Holidays from our list of preset Holidays for each country, and the option to import a Holiday manually.",
    "components.onboarding.joyride.admin.importHolidays.title": "Import Holidays",
    "components.onboarding.joyride.admin.leaveTypesMenu.info1": "We always recommend you start your Vacation Tracker journey by creating Leave Types. You will be assigning these Leave Types later when you create all the locations where your company has employees.",
    "components.onboarding.joyride.admin.leaveTypesMenu.info2": "Visit <link>Helpdesk</link> for a step by step guide on how to create and edit Leave Types.",
    "components.onboarding.joyride.admin.leaveTypesMenu.title": "Create Leave Types",
    "components.onboarding.joyride.admin.newLeaveType.info1": "In addition to the Default Leave Type (PTO), you can create up to 24 more Leave Types.",
    "components.onboarding.joyride.admin.start.title": "Learn about Vacation Tracker! 🏖️",
    "components.onboarding.joyride.admin.start1": "To help you get started, we've put together a few short steps we recommend you follow to quickly set up your organization.",
    "components.onboarding.joyride.admin.start2": "If you need our help along the way, we’re just one email away at <link>hello@vacationtracker.io</link>",
    "components.onboarding.joyride.admin.teams.cta1": "Install Microsoft Teams bot",
    "components.onboarding.joyride.admin.teams.cta2": "Learn more in our Helpdesk",
    "components.onboarding.joyride.admin.teams.info1": "You're all set on the dashboard. The last step is to install the Microsoft Teams bot so your team can make requests directly in Microsoft Teams.",
    "components.onboarding.joyride.admin.teams.title": "Microsoft Teams",
    "components.onboarding.joyride.approver.dashboard.info1": "This page is your mission control. It gives you an overview of pending leaves, who’s off today and upcoming leaves for your organization.",
    "components.onboarding.joyride.approver.leaveRequests.info1": "As an Approver, you can \"Add Leave\" for any member of the team you are an Approver on. You can also \"Request Leave\" for yourself which will be sent for approval to your  Approver.",
    "components.onboarding.joyride.approver.notifications.info1": "This page offers an overview of all the Notifications being sent across your organization.",
    "components.onboarding.joyride.approver.notifications.info2": "You can create or edit Notifications for Users of Departments you're an Approver of.",
    "components.onboarding.joyride.approver.notifications.info3": "Note that you can only manage the Notifications created by you.",
    "components.onboarding.joyride.approver.users.info1": "'Users' page gives you a full overview of all active users within your Department along with read-only access to their User Profiles. Their pending requests are available on the 'Dashboard' page.",
    "components.onboarding.joyride.user.calendar.info1": "On this page, you can see the history and upcoming leaves for your organization in a calendar format.",
    "components.onboarding.joyride.user.dashboard.info1": "The Dashboard gives you an overview of today’s and scheduled leaves within your organization.",
    "components.onboarding.joyride.user.holidays.info1": "Here you can review Holidays within your Location, so you make sure you know which days you already have off.",
    "components.onboarding.joyride.user.myProfile.info1": "Here you can see your available leave types, quotas, workdays, and your leave history.",
    "components.onboarding.joyride.user.requestLeave.info1": "To request Leave through the Vacation Tracker Dashboard, select one of the available leave types, add the time period and reason if necessary, and submit the request. Once submitted, it will be sent for approval to the Approver.",
    "components.onboarding.joyride.user.start1": "To help you get started, we've put together a list of five steps that we recommend you follow to quickly set up your organization.",
    "components.onboarding.openNotificationsButton": "Open notifications",
    "components.onboarding.requestALeaveDescription": "Submit your first leave request in 3 simple steps.",
    "components.onboarding.requestALeaveTitle": "<icon></icon> Request a Leave",
    "components.onboarding.setNotificationsDescription": "Choose how often you would like to be notified about your team’s leave to stay informed.",
    "components.onboarding.setNotificationsTitle": "<icon></icon> Set Up Notifications",
    "components.onboarding.skipOnboarding": "Skip onboarding",
    "components.onboarding.syncTheCalendarDescription": "Integrate the Vacation Tracker calendar with your calendar so your team’s leave is up-to-date.",
    "components.onboarding.syncTheCalendarTitle": "<icon></icon> Sync Calendars",
    "components.onboarding.syncTheCalendarVideoForSyncingWithGoogleCalendar": "Video for syncing with Google Calendar",
    "components.onboarding.syncTheCalendarVideoForSyncingWithIcal": "Video for syncing with iCal",
    "components.onboarding.syncTheCalendarVideoForSyncingWithMicrosoftOutlookCalendar": "Video for syncing with MS Windows",
    "components.onboarding.title": "Onboarding checklist <progressBar>{progressStatus}</progressBar>",
    "components.paymentInformation.expiryDate": "Expiry Date",
    "components.paymentInformation.labelCreditCard": "Credit card",
    "components.paymentInformation.title": "Payment information ",
    "components.platformSwitchModal.content": "You must first log in with your {platform} account to switch to the {platform} platform. Please click the button below to continue. After logging in and granting necessary permissions, we'll redirect to the user mapping process.",
    "components.platformSwitchModal.title": "Migration to {platform} platform",
    "components.pricePlanCard.accruals": "Accrue Leave",
    "components.pricePlanCard.APISupport": "API support",
    "components.pricePlanCard.calendarIntegrations": "Calendar Integration",
    "components.pricePlanCard.contactUs": "Contact us",
    "components.pricePlanCard.coreDepartmentsAndLocations": "Create up to 3 Locations and 10 Departments",
    "components.pricePlanCard.currentPlan": "Current Plan",
    "components.pricePlanCard.customContract": "Custom contract",
    "components.pricePlanCard.customizeLeaveTypes": "Customize Leave Types",
    "components.pricePlanCard.customLocationsDepartmentsLeaveTypes": "Unlimited Locations, Departments and Custom Leave Types",
    "components.pricePlanCard.customOnboardingAndSetup": "Custom onboarding and setup",
    "components.pricePlanCard.dedicatedSupport": "Dedicated Support",
    "components.pricePlanCard.disableDowngradeToCore": "Downgrading to the Core plan requires {noOfActions} {noOfActions, plural, =1 {action} other {actions}}",
    "components.pricePlanCard.disableDowngradeToCoreDescription": "To switch to the Core plan, please make the following adjustments:",
    "components.pricePlanCard.disableDowngradeToCoreReasonDepartments": "The Core plan allows a maximum of 10 departments. Please remove any additional departments.",
    "components.pricePlanCard.disableDowngradeToCoreReasonLocations": "The Core plan allows a maximum of 3 locations. Please remove any additional locations.",
    "components.pricePlanCard.disableDowngradeToCoreReasonSubdepartments": "On the Core plan, departments can only be nested one level deep. You currently have {total} sub-departments. Please delete some sub-departments to switch to the Core plan.",
    "components.pricePlanCard.disableDowngradeToCoreReasonWorkday": "Switching to the Core plan is not possible while using custom work hours. Please reset custom work hours to the default (8) in the locations settings",
    "components.pricePlanCard.disableDowngradeToCoreTitle": "Plan downgrade. Adjustments needed",
    "components.pricePlanCard.disableDowngradeToMultiLevelApproval": "Switching to the Core plan is not possible while Multi-level approval flow is active. To downgrade, please switch to Single-level approvals in the Departments settings",
    "components.pricePlanCard.displayLeaveInformationInDaysHours": "Display Leave Information in Days/Hours",
    "components.pricePlanCard.downgradePlan": "The plan will be downgraded the next billing cycle",
    "components.pricePlanCard.duringTheTrial": "During the trial period any plan change will be implemented immediately",
    "components.pricePlanCard.everythingInTheCompletePlan": "Everything in the Complete plan",
    "components.pricePlanCard.EverythingInTheCorePlan": "Everything in the Core plan",
    "components.pricePlanCard.exportsAndReports": "Exports and Reports",
    "components.pricePlanCard.forSeats": "for less than {users} seats",
    "components.pricePlanCard.forUsers": "for less than {users} users",
    "components.pricePlanCard.hourlyLeaves": "Track Leave in Hours",
    "components.pricePlanCard.HRFields": "HR Fields (coming soon)",
    "components.pricePlanCard.labels": "Organize Users by Labels",
    "components.pricePlanCard.managePTO": "Manage PTO requests",
    "components.pricePlanCard.minimumPlanPrice": "minimum <price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>",
    "components.pricePlanCard.notifications": "Send PTO Notifications",
    "components.pricePlanCard.pricePerSeat": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>per seat/month",
    "components.pricePlanCard.pricePerUser": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>per user/month",
    "components.pricePlanCard.reports": "Generate Reports",
    "components.pricePlanCard.reportsSchedule": "Schedule Reports",
    "components.pricePlanCard.requestHourlyLeaveIncrements": "Request Hourly Leave Increments",
    "components.pricePlanCard.scheduleAConsultation": "Schedule A Consultation",
    "components.pricePlanCard.scheduledReport": "Scheduled Reports",
    "components.pricePlanCard.selectPlan": "Select Plan",
    "components.pricePlanCard.sharedCalendars": "Shared Calendars",
    "components.pricePlanCard.SSOIntegration": "Enterprise SSO Integration (Okta, etc.)",
    "components.pricePlanCard.startFreeTrial": "Start Your 14-Day Free Trial",
    "components.pricePlanCard.substituteApprovers": "Substitute Approvers",
    "components.pricePlanCard.support": "Priority Support",
    "components.pricePlanCard.switchPlan": "Switch <period> To {newPeriod} Plan</period>",
    "components.pricePlanCard.totalPrice": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/month for {totalUsers} active users",
    "components.pricePlanCard.totalPriceForLessThan25Users": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/month for less than 25 users",
    "components.pricePlanCard.totalPricePerSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/year",
    "components.pricePlanCard.totalPricePerUser": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/year",
    "components.pricePlanCard.totalPriceSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/month for {totalUsers} seats",
    "components.pricePlanCard.unlimitedDepartmentsAndLocations": "Create Unlimited Departments and Locations",
    "components.pricePlanCard.upgradePlan": "The plan will be activated immediately and the price will be prorated",
    "components.pricePlanCard.workday": "Customizable Workday Length",
    "components.reports.companyLogs": "Company Audit Logs",
    "components.reports.companyLogsDescription": "History of all changes on the company, location, department and leave type level for a specific time period.",
    "components.reports.feedback": "Do you need another report or a granularity level? Let us know what you need!",
    "components.reports.generate": "Generate and export",
    "components.reports.intro": "Create and export audit logs, leave balance, and leave history reports in minutes.",
    "components.reports.introOldOnes": "Go to Old Reports",
    "components.reports.introPre": "Your Reports just got smarter! Preview data instantly, filter with precision, and customize your columns—all in the new interface. Not ready to switch? Your familiar Reports view is just one click away.",
    "components.reports.scheduledReportsDescription": "Configure and schedule reports that we'll deliver to the selected list of emails on a selected day and time each month.",
    "components.requestAutomation.anythingElse": "Anything else we should know?",
    "components.requestAutomation.automationExtraCharge": "Would you use this Add-on if it came at an extra charge?",
    "components.requestAutomation.automationFunctionalities": "What functionalities or tasks will the Add-on enable you to perform?",
    "components.requestAutomation.automationShouldWeAdd": "Which Add-on should we add?",
    "components.requestAutomation.submiteRequest": "Submit Request",
    "components.requestAutomation.suggestAnAutomation": "Suggest an Add-on",
    "components.resetQuotas.fiscalYear": "Accounting Year ({date})",
    "components.resetQuotas.userYear": "User Employment Date",
    "components.selectChannelForm.addNewEmail": "Add Recipient",
    "components.selectChannelForm.channel": "Channel",
    "components.selectChannelForm.channelSlackInfo": "Notifications can be sent only to the channels you're a part of.",
    "components.selectChannelForm.email": "Recipients",
    "components.selectChannelForm.errorNotification": "Notification Error",
    "components.selectChannelForm.errorNotInTeams": "Access denied. In order to use this channel, you must be a member of this department.",
    "components.selectChannelForm.notFound": "Your organization signed up with a single Microsoft Teams team, and you are not the member of the team with the following ID: {msTeamId}.\n\nYou can join this team on your Microsoft Teams application and try again, or contact our support to change your installation type.",
    "components.selectChannelForm.notFoundTitle": "You are not a member the selected Microsoft Teams team",
    "components.selectChannelForm.team": "Team",
    "components.selectChannelFormMicrosoft.channelPlaceholder": "Please select a Channel",
    "components.selectChannelFormMicrosoft.errorNotInTeams": "Access denied. In order to use this channel, you must be a member of this department.",
    "components.selectChannelFormMicrosoft.notFound": "Your organization signed up with a single Microsoft Teams team, and you are not the member of the team with the following ID: {msTeamId}.\n\nYou can join this team on your Microsoft Teams application and try again, or contact our support to change your installation type.",
    "components.selectChannelFormMicrosoft.notFoundTitle": "You are not a member the selected Microsoft Teams team",
    "components.selectChannelFormMicrosoft.teamPlaceholder": "Please select a Team",
    "components.selectChannelFormSlack.errorNotification": "Notification Error",
    "components.selectChannelFormSlack.missingSlackScopes": "Missing Slack Scopes",
    "components.selectChannelFormSlack.privateChannel": "(private channel)",
    "components.selectChannelFormSlack.slackBotIdIsRequred": "Slack error: Slack Bot Id is required! Try refresh page!",
    "components.selectChannelFormSlack.slackError": "Slack error",
    "components.selectChannelFormSlack.toProccedPleaseLogin": "To proceed, please login to your Slack account",
    "components.selectChannelFormSlack.youCanAddTheBotOnlyOnTheChannels": "You are not a member of {channelName}. To send the notification to this channel, you need to be a member of it.",
    "components.seniorityEntitlement.addDaysFrom": "Add days from",
    "components.seniorityEntitlement.allFiledsAreRequired": "All fields are required",
    "components.seniorityEntitlement.confirmDescription": "Opting to start calculating seniority entitlement from the employee's start date will adjust user quotas retrospectively from the calculation start year onwards.",
    "components.seniorityEntitlement.confirmTitle": "Confirm automation creation",
    "components.seniorityEntitlement.daysAfter": "day(s) after",
    "components.seniorityEntitlement.disableEditInfo": "You can't edit the Seniority entitlement automation. If you need to make changes, delete this automation and create a new one. <link>Contact us</link> if you have any questions or need help.",
    "components.seniorityEntitlement.hoursAfter": "hour(s) after",
    "components.seniorityEntitlement.noLocations": "You have an automation that has been applied to all users, you can't have multiple automations for all users.",
    "components.seniorityEntitlement.notSortedYearInPeriodError": "Years must be sorted chronologically.",
    "components.seniorityEntitlement.prorateForTheFirstYear": "Do you want to enable prorate?",
    "components.seniorityEntitlement.prorateForTheFirstYearTooltip": "If the user's employee start date does not match the fiscal year start date, we'll prorate seniority-based days in the year they are awarded. For example, if your fiscal year starts on January 1st, the user started working on July 1st 5 years ago, and your company gives 5 additional PTO days on July 5th after 5 years of service, the user will receive 2.5 days on July 1st because of the prorate. This user will receive all 5 seniority-based days in the next fiscal year.",
    "components.seniorityEntitlement.sameYearError": "You cannot assign multiple rules to the same number of years.",
    "components.seniorityEntitlement.startApplyingSeniorityEntitlementFrom": "Start applying seniority entitlement from",
    "components.seniorityEntitlement.startApplyingSeniorityEntitlementFromInfo": "If you choose to start applying seniority entitlement from the employee start date, this change will affect the user's quotas in the past.",
    "components.seniorityEntitlement.summaryInfoPrefix": "Seniority Automation awards additional leave in a milestone-based approach. For example: ",
    "components.seniorityEntitlement.summaryInfoSuffix": ", totalling {amount} <showDays>days</showDays><showHour>hour(s)</showHour> of seniority leave.",
    "components.seniorityEntitlement.summeryInfoFirstRow": "{amount} <showDays>days</showDays><showHour>hour(s)</showHour> are added at the {year} year mark",
    "components.seniorityEntitlement.summeryInfoMoreDays": ", {amount} more <showDays>days</showDays><showHour>hour(s)</showHour> at the {year} year mark",
    "components.seniorityEntitlement.yearsInTheCOmpany": "years in the company",
    "components.setupMsBot.followDirectLink": "1) Click on <directLink>this link</directLink> to find the Vacation Tracker application in the Microsoft Teams store.",
    "components.setupMsBot.inMSAppPreviewWindows": "2) In the app preview window, click the arrow next to the \"Open\" button and click \"Add to a team\"",
    "components.setupMsBot.setUpInMS": "3) You will be prompted to select the team and channel you would like to install Vacation Tracker in. You will also be given the option to pin Vacation Tracker as a tab for easier and faster access!",
    "components.setupMsBot.stepByStep": "For detailed step-by-step instructions, check out this video or visit our <helpDesk>Helpdesk</helpDesk>.",
    "components.setupMsBot.title": "Set up the Microsoft Teams bot and tabs",
    "components.signupWithAnotherPlatform.connectWith": " Sign up with {platform}",
    "components.signupWithAnotherPlatform.info": "Would you like to connect with your {platform} account? By doing so, you'll be able to import and sync users from your {importUsersPlatform} and manage leaves directly from your {platform} application. For more information, check out our <helpDesk>Helpdesk</helpDesk>.",
    "components.signupWithAnotherPlatform.or": "Or",
    "components.signupWithAnotherPlatform.title": "It appears that you're using  {platform}",
    "components.switchPlanModal.downgradeProbationPeriodWarning": "The Probation Period Add-On is a paid feature for the Core plan. If you downgrade and do not unsubscribe from this Add-On, it will be charged at $0.50 per user per month.",
    "components.switchPlanModal.switch": "Switch",
    "components.switchPlanModal.switchCoreMonthlyToCompleteMonthly": "The <strong>Complete</strong> monthly plan will be activated immediately and the price will be prorated for the current billing cycle.<legacyPlan> Please note that you will not be able to switch back to the legacy plan.</legacyPlan>",
    "components.switchPlanModal.switchFromAnnuallyToMonthly": "The <strong>{newPlan}</strong> monthly plan will be activated and billed after the end of the current annual billing cycle ({endDatePeriod}.).<legacyPlan> Please note that you will not be able to switch back to the legacy plan.</legacyPlan>",
    "components.switchPlanModal.switchFromCompleteAnnualToCoreAnnual": "The <strong>Core</strong> annual plan will be activated after the end of the current annual billing cycle ({endDatePeriod}.), until then you can continue to use features of the <strong>Complete</strong> plan",
    "components.switchPlanModal.switchFromCompleteAnnualToCoreMonthly": "The <strong>Core</strong> plan will be activated after the end of the current annual billing cycle ({endDatePeriod}.), until then you can continue to use features of the <strong>Complete</strong> plan.",
    "components.switchPlanModal.switchFromCompleteMonthlyToCoreAnnual": "The <strong>Core</strong> annual plan will be activated after the end of the current monthly billing cycle ({endDatePeriod}.), until then you can continue to use features of the <strong>Complete</strong> plan.",
    "components.switchPlanModal.switchFromCompleteMonthlyToCoreMonthly": "The <strong>Core</strong> monthly plan will be activated after the end of the current billing cycle ({endDatePeriod}.), until then you can continue to use features of the <strong>Complete</strong> plan.",
    "components.switchPlanModal.switchFromCoreAnnualToCompleteAnnual": "The <strong>Complete</strong> annual plan will be activated immediately and you will be billed for the prorated difference in price until the end of the current annual billing cycle.<legacyPlan> Please note that you will not be able to switch back to the legacy plan.</legacyPlan>",
    "components.switchPlanModal.switchFromCoreMonthlyToCompleteAnnual": "The <strong>Complete</strong> annual plan will be activated immediately. Your annual price will be reduced by the remaining amount on your current monthly invoice.<legacyPlan> Please note that you will not be able to switch back to the legacy plan.</legacyPlan>",
    "components.switchPlanModal.switchFromMonthlyToAnnually": "The <strong>{newPlan}</strong> annual plan will be activated immediately. Your annual price will be reduced by the remaining amount on your current monthly invoice.<legacyPlan> Please note that you will not be able to switch back to the legacy plan.</legacyPlan>",
    "components.switchPlanModal.title": "Switch to <strong>{plan}</strong> plan",
    "components.switchPlanModal.unsubscribeProbationPeriodAddons": "Unsubscribe from Probation period",
    "components.tables.accruedAmount": "Accrued amount",
    "components.tables.approver": "Approver",
    "components.tables.broughtForward": "Brought forward",
    "components.tables.department": "Department",
    "components.tables.duration": "Duration",
    "components.tables.durationDays": "Days",
    "components.tables.durationHours": "Hours",
    "components.tables.editColumns": "Edit columns",
    "components.tables.editColumnsDesc": "Active columns order",
    "components.tables.email": "Email",
    "components.tables.employeeId": "Employee ID",
    "components.tables.endDate": "End Date",
    "components.tables.endHour": "End Hour",
    "components.tables.endMinute": "End Minute",
    "components.tables.expiredBroughtForward": "Expired brought forward",
    "components.tables.id": "Id",
    "components.tables.initialBroughtForward": "Inital brought forward",
    "components.tables.isFullDay": "Is Full Day",
    "components.tables.labels": "Labels",
    "components.tables.leaveType": "Leave Type",
    "components.tables.location": "Location",
    "components.tables.month": "{month}-{year}",
    "components.tables.reason": "Reason",
    "components.tables.remainingAmount": "Remaining amount",
    "components.tables.requestType": "Request Type",
    "components.tables.scheduledAmount": "Scheduled amount",
    "components.tables.startDate": "Start Date",
    "components.tables.startHour": "Start Hour",
    "components.tables.startMinute": "Start Minute",
    "components.tables.status": "Status",
    "components.tables.toil": "TOIL",
    "components.tables.toilExpired": "TOIL expired",
    "components.tables.usedAmount": "Used amount",
    "components.tables.user": "User",
    "components.tables.yearlyQuota": "Yearly Quota",
    "components.tables.yearlyQuotaBase": "Yearly Quota Base",
    "components.tables.yearlyQuotaRoleBased": "Yearly Quota Role Based",
    "components.tables.yearlyQuotaSeniority": "Yearly Quota Seniority",
    "components.teamForm.approvers": "Approver(s)",
    "components.teamForm.generalSettings": "General Settings",
    "components.teamForm.isDefault": "Make This Department Default",
    "components.teamForm.isDefaultExtra": "By making this Department the default one, all new team members will be automatically added to this Department",
    "components.teamForm.name": "Name",
    "components.teamForm.pleaseEnterTeamName": "Enter department name",
    "components.teamForm.users": "Users",
    "components.toil.accrualsToilsTotal": "Total earned {hours, plural, =1 {hours} other {days}} (TOIL + accrual): {total}",
    "components.toil.added": "TOIL Added",
    "components.toil.addToil": "Add TOIL",
    "components.toil.addToilProgress": "Adding TOIL",
    "components.toil.anotherAdd": "Add another TOIL",
    "components.toil.anotherRequest": "Request another TOIL",
    "components.toil.approveInProgress": "Approving TOIL request",
    "components.toil.bot.message.editedToilRequest": "Hello, <@{userSlackId}> has edited the following TOIL request:",
    "components.toil.bot.message.leaveTypeName": "*Leave type:*\nTOIL - {leaveTypeName}",
    "components.toil.bot.message.requestToil": "Request TOIL",
    "components.toil.bot.message.requestToilSummary": "Please initiate your Time Off in Lieu request by specifying the dates on which you performed overtime work and include any relevant remarks. Note that Time Off In Lieu requests can only be made for dates in the past, including today.",
    "components.toil.bot.message.submittedToilRequest": "Hello, <@{userSlackId}> has submitted the following TOIL request:",
    "components.toil.bot.notification.slack.toilRequestAdded": "A TOIL leave was created for you by an approver",
    "components.toil.bot.notification.slack.toilRequestAddedSelf": "You have added a TOIL leave",
    "components.toil.bot.notification.slack.toilRequestApproved": "✅ <@{approverName}> has approved the request, no further action is required.",
    "components.toil.bot.notification.slack.toilRequestApprovedTitle": "Your Leave Request was Approved",
    "components.toil.bot.notification.slack.toilRequestCreated": "Hello, <@{userSlackId}> has submitted a TOIL request.",
    "components.toil.bot.notification.slack.toilRequestEdited": "Hello, <@{userSlackId}> has edited a TOIL request.",
    "components.toil.cancelInProgress": "Canceling the TOIL request",
    "components.toil.deleteInProgress": "Deleting TOIL",
    "components.toil.denied": "Your TOIL Request was Denied",
    "components.toil.deniedBy": "TOIL request has been denied by {approverName}.",
    "components.toil.denyInProgress": "Denying TOIL request",
    "components.toil.durationLabel": "Shortest Time Off In Lieu Duration",
    "components.toil.durationLabelTooltip": "Please select the shortest duration that applies to the TOIL request. This selection should reflect the minimum amount of overtime that a user can log.",
    "components.toil.earned": "TOIL earned",
    "components.toil.edit": "Update TOIL",
    "components.toil.edited": "TOIL udated",
    "components.toil.editRequestToil": "Edit Request Time Off In Lieu",
    "components.toil.editToilProgress": "Editing Time Off In Lieu",
    "components.toil.enableLabel": "Enable Time Off In Lieu:",
    "components.toil.enableLabelTooltip": "TOIL is a practice where employees are compensated for working overtime by receiving equivalent time off from work, instead of being paid extra for the additional hours worked.",
    "components.toil.expiration": "Expiration",
    "components.toil.expirationDuration": "{noOfMonths} {noOfMonths, plural, =1 {month} other {months}}",
    "components.toil.expirationDurationCalendar": "End of calendar year",
    "components.toil.expirationLabel": "Should Time Off In Lieu days expire?",
    "components.toil.expirationLabelTooltip": "Enable this setting if you want TOIL days to have an expiration date, after which they can no longer be used. Selecting 'Yes' will prompt you to choose expiry rules. If 'No' is selected, TOIL days will not expire and will remain available until used.",
    "components.toil.featurePlan": "TOIL feature is available only for the Complete plan.",
    "components.toil.fullDayLeavePeriod": "{date}{endDate} ",
    "components.toil.halfDay": "Half day TOIL",
    "components.toil.hourly": "Hourly TOIL",
    "components.toil.lapsed": "lapsed",
    "components.toil.locationNotHaveToil": "No leave policy with TOIL is set up for {me, plural, =1 {your} other {the user's}} location",
    "components.toil.notification.added": "TOIL <forUser>for </forUser>{requestor} added <byActionCreator>by {actionCreator}</byActionCreator>",
    "components.toil.notification.approved": "{requestor} TOIL approved <byActionCreator>by {actionCreator}</byActionCreator> ✅",
    "components.toil.notification.cancelled": "{requestor} TOIL cancelled <byActionCreator>by {actionCreator}</byActionCreator>",
    "components.toil.notification.created": "TOIL requested <byActionCreator>by {actionCreator}</byActionCreator>",
    "components.toil.notification.deleted": "TOIL deleted <byActionCreator>by {actionCreator}</byActionCreator>",
    "components.toil.notification.denied": "{requestor} TOIL denied <byActionCreator>by {actionCreator}</byActionCreator> ❌",
    "components.toil.notification.edited": "{requestor} TOIL edited <byActionCreator>by {actionCreator}</byActionCreator>",
    "components.toil.notification.editedApproved": "A scheduled TOIL is edited <byActionCreator>by {actionCreator}</byActionCreator> ✅",
    "components.toil.notification.editedDenied": "Edited TOIL denied <byActionCreator>by {actionCreator}</byActionCreator>",
    "components.toil.partDayPeriod": "{date} - from {partDayStartHour} to {partDayEndHour} ",
    "components.toil.reasonDefault": "Additional hours worked during the above specified time period.",
    "components.toil.request": "TOIL request",
    "components.toil.requested": "TOIL requested",
    "components.toil.requestedOrAddedDaysHours": "{action, plural, =1 {Requested} other {Added}} {period}",
    "components.toil.requester": "Who Can Request Time Off In Lieu?",
    "components.toil.requesterAdmin": "Only Admins and Approvers",
    "components.toil.requesterAll": "All Users",
    "components.toil.requesterTooltip": "Choose 'All Users' to allow every employee to submit their own TOIL requests after working overtime. Opt for 'Only Admins and Approvers' if you prefer to centralize TOIL management and maintain tighter control over its approval and recording.",
    "components.toil.requestNotFound": "TOIL request not found",
    "components.toil.requestToil": "Request Time Off In Lieu",
    "components.toil.requestToilProgress": "Requesting Time Off In Lieu",
    "components.toil.submitError": "TOIL Submit Error",
    "components.toil.submitErrorDate": "The TOIL request cannot be submitted because the start date you've selected is after the end date. Make sure the start date is before the end date, and submit the request again",
    "components.toil.submitErrorDateFuture": "Requested TOIL date must be in the past",
    "components.toil.submitErrorLongerThanWorkday": "Requested TOIL hours is longer than working hours",
    "components.toil.submitErrorNotOpen": "TOIL is not open",
    "components.toil.submitErrorOverlap": "Requested TOIL days are overlapping with existing TOIL",
    "components.toil.submitErrorPlan": "This feature is exclusively accessible to users subscribed to our Complete plan.",
    "components.toil.submitErrorPolicy": "Leave policy for this leave type doesn't allow TOIL",
    "components.toil.submitErrorPolicyDuration": "Users with custom working hours are not allowed to request multiday TOIL",
    "components.toil.submitErrorPolicyLocation": "The TOIL option is not enabled for your location. You can enable it on the leave policies page or contact your administrator for support.",
    "components.toil.submitErrorReasonNotSet": "TOIL reason is requried",
    "components.toil.submitErrorRole": "TOIL request not allowed",
    "components.updateWorkweekForm.cancel": "Cancel",
    "components.updateWorkweekForm.form.error": "Please select at least one working day",
    "components.updateWorkweekForm.title": "Update Workweek",
    "components.updateWorkweekForm.update": "Update",
    "components.updateWorkweekForm.workWeek": "Workweek",
    "components.userBroughtForwardForm.customRolloverDays": "Days",
    "components.userBroughtForwardForm.leaveTypeEditInfo": "You are editing the \"{leaveTypeName}\" leave type for the {dateRangeName} period.",
    "components.userBroughtForwardForm.update": "Update",
    "components.userBroughtForwardForm.updateUserLeaveType": "Update a brought forward days for leave type {name}",
    "components.userLabes.addLabel": "Add Label",
    "components.userLabes.manageLabels": "Manage Labels",
    "components.userLabes.newLabel": "(new label)",
    "components.userLabes.noLabelsFound": "No Labels Found",
    "components.userLeaveQuotas.accrualsTooltipTitle": "Accrual is the accumulation or gradual increase of your paid time off hours during the year.",
    "components.userLeaveQuotas.accrualsTooltipTitle-bi-weekly": "A biweekly {leaveType} accrual occurs every two weeks",
    "components.userLeaveQuotas.accrualsTooltipTitle-monthly": "A monthly {leaveType} accrual occurs once a month.",
    "components.userLeaveQuotas.accrualsTooltipTitle-semi-monthly": "A semimonthly {leaveType} accrual occurs twice a month.",
    "components.userLeaveQuotas.accrualsTooltipTitle-weekly": "A weekly {leaveType} accrual occurs every week",
    "components.userLeaveQuotas.accruedDays": "Accrued Days",
    "components.userLeaveQuotas.accruedHours": "Accrued Hours",
    "components.userLeaveQuotas.accruedInfo1": "{userName} accrued {earnedDays} days ({earnedDaysInHours} hours) of {defaultDays} days ({defaultDaysInHours} hours)",
    "components.userLeaveQuotas.accruedInfo1Days": "{userName} accrued {earnedDays} out of {defaultDays} days",
    "components.userLeaveQuotas.accruedInfo1Hours": "{userName} accrued {earnedDaysInHours} out of {defaultDaysInHours} hours",
    "components.userLeaveQuotas.accruedInfo2": "{userName} will accrue {toAccrueDays} days ({toAccrueHours} hours) on {earnDate}",
    "components.userLeaveQuotas.accruedInfo2Days": "{userName} will accrue {toAccrueDays} days on {earnDate}",
    "components.userLeaveQuotas.accruedInfo2Hours": "{userName} will accrue {toAccrueHours} hours on {earnDate}",
    "components.userLeaveQuotas.baseQuota": "Base quota",
    "components.userLeaveQuotas.broughtForward": "Brought Forward",
    "components.userLeaveQuotas.broughtForwardDaysInfo": "<rolloverDays>Max {maxRolloverDays} days<br></br></rolloverDays><expiresDate>Expires {date}</expiresDate>",
    "components.userLeaveQuotas.broughtForwardInfo": "Brought Forward represents the number of unused days rolled over from the previous period.",
    "components.userLeaveQuotas.broughtForwardInfoDetailsAll": "For {leaveTypeName} in the {locationName} location all unused days can be carried over to the next period.",
    "components.userLeaveQuotas.broughtForwardInfoDetailsDays": "For {leaveTypeName} in the {locationName} location employees can brought forward up to {broughtForwardLimit} unused {broughtForwardLimit, plural, =1 {day} other {days}}.",
    "components.userLeaveQuotas.broughtForwardInfoDetailsHours": "For {leaveTypeName} in the {locationName} location employees can brought forward up to {broughtForwardLimit} unused {broughtForwardLimit, plural, =1 {hour} other {hours}}.",
    "components.userLeaveQuotas.broughtForwardValue": "Brought Forward",
    "components.userLeaveQuotas.editInitialQuotaTitle": "Edit Initial Brought Forward days for {leaveTypeName}",
    "components.userLeaveQuotas.editInitialQuotaTooltip": "Initial Brought Forward allows you to manually enter employees' starting balances from a previous tracking or HR system",
    "components.userLeaveQuotas.editQuotaButton": "Change",
    "components.userLeaveQuotas.expirationTooltip": "Days that employee brought forward from the previous period but that were not used before the expiration date",
    "components.userLeaveQuotas.expiredDateInfo": "on {date}",
    "components.userLeaveQuotas.forMoreDetails": "For more details, see",
    "components.userLeaveQuotas.initialBroughtForward": "Initial Brought Forward",
    "components.userLeaveQuotas.initialBroughtForwardInfo": "Initial Brought Forward is the leave balance a User has carried over (rolled over) from last year, in which Vacation Tracker wasn't a part of your organization. Note that you can only edit the Initial Brought Forward leave balance, while the available leave you can bring forward in the following years will be calculated by Vacation Tracker.",
    "components.userLeaveQuotas.leavePoliciesNotExistInfo": "There are currently no available Leave Types. Please assign Leave Policies to the Location this user belongs to.",
    "components.userLeaveQuotas.leaveTypes": "Leave Types",
    "components.userLeaveQuotas.logsTab": "logs tab.",
    "components.userLeaveQuotas.noLimit": "You have an unlimited quota.",
    "components.userLeaveQuotas.ofDefaultDays": "of {value} days",
    "components.userLeaveQuotas.ofDefaultHours": "of {value} hours",
    "components.userLeaveQuotas.overlapDaysContactSupport": "Overlap of dates, please contact Support for more information.",
    "components.userLeaveQuotas.paidDays": "Paid out days",
    "components.userLeaveQuotas.quota": "Quota",
    "components.userLeaveQuotas.quotaInfo": "Quota represents the number of {leaveTypeName} days available for the current period. This number includes the base quota, time off in lieu, and days awarded based on the employee's role or seniority.",
    "components.userLeaveQuotas.quotaInfoSummary": "Quota for the current period:",
    "components.userLeaveQuotas.remaining": "Remaining",
    "components.userLeaveQuotas.remainingAfterRequest": "Remaining after request",
    "components.userLeaveQuotas.remainingTooltip": "Remaining {leaveTypeName} days for the current period. Total:",
    "components.userLeaveQuotas.remainingTooltipFooter": "Up to {broughtForwardLimit} unused {period} will be rolled over to the next period",
    "components.userLeaveQuotas.remainingTooltipFooterAll": "All unused days and hours can be carried over to the next period.",
    "components.userLeaveQuotas.remainingTooltipFooterZero": "No unused days and hours can be carried over to the next period.",
    "components.userLeaveQuotas.requested": "Requested",
    "components.userLeaveQuotas.saveQuotaButton": "Save",
    "components.userLeaveQuotas.scheduled": "Scheduled",
    "components.userLeaveQuotas.scheduledDays": "Scheduled days",
    "components.userLeaveQuotas.sectionTitle": "Leave quotas",
    "components.userLeaveQuotas.selectPeriod": "Select Period:",
    "components.userLeaveQuotas.unlock": "Unlock",
    "components.userLeaveQuotas.unlockBroughtForwardInfo": "To unlock, go to Settings → Locations → {locationName} → View Leave Policies, and edit the {leaveTypeName} policy to allow Brought Forward.",
    "components.userLeaveQuotas.used": "Used",
    "components.userLeaveQuotas.usedAlready": "Already used",
    "components.userLeaveQuotas.usedDays": "Used days",
    "components.userLeaveQuotas.usedTooltip": "Used, scheduled and paid out {leaveTypeName} days for the current period. Total:",
    "components.userLeaveQuotas.userInactiveInfo": "As the account is now inactive, no further days will be added until reactivation.",
    "components.userLeaveQuotasCompact.title": "Your leave balance",
    "components.userLeaveQuotasCompact.titleForApprover": "Leave balance",
    "components.userLeaveTypeForm.applyForFutureYears": "Do you want to apply base leave quota for the future years?",
    "components.userLeaveTypeForm.cancel": "Cancel",
    "components.userLeaveTypeForm.daysPerYear": "What's your new leave quota?",
    "components.userLeaveTypeForm.hasUnlimitedDays": "Do you want to allow Unlimited Leaves?",
    "components.userLeaveTypeForm.leaveTypeEditInfo": "You are editing the \"{leaveTypeName}\" leave type for the {dateRangeName} period.",
    "components.userLeaveTypeForm.prorateInfo": "{user} will have {proratedQuota} ({preciseQuota} days, to be precise) for the initial year ({yearPeriod}) and {fullQuota} for all years starting at {startOfNextYear}.",
    "components.userLeaveTypeForm.prorateInfoDays": "{user} will have {proratedQuota} ({preciseQuota} days, to be precise) for the initial year ({yearPeriod}) and {fullQuota} days for all years starting at {startOfNextYear}.",
    "components.userLeaveTypeForm.prorateInfoHours": "{user} will have {proratedQuota} ({preciseQuota} hours, to be precise) for the initial year ({yearPeriod}) and {fullQuota} hours for all years starting at {startOfNextYear}.",
    "components.userLeaveTypeForm.prorateLabel": "Prorate for the initial year based on the user's start date ({startDate})",
    "components.userLeaveTypeForm.quotaAdditionalRole": "Set the additional days based on role",
    "components.userLeaveTypeForm.quotaAdditionalRoleInfo": "Allocate additional leave days to employees depending on their job role. This field lets you tailor leave entitlements to reflect the responsibilities or specific requirements of different positions within the organisation.",
    "components.userLeaveTypeForm.quotaAdditionalSeniority": "Set the additional days based on seniority entitlement",
    "components.userLeaveTypeForm.quotaAdditionalSeniorityInfo": "Seniority-based days are added by Seniority entitlement automation. You can overwrite seniority-based {leaveTypeName} days by setting the value in this field.",
    "components.userLeaveTypeForm.quotaAdditionalSeniorityInfoUpcomingYear": "Seniority-based days are added by Seniority entitlement automation. You can overwrite seniority-based {leaveTypeName} days by setting the value in this field. However, this field is locked because you can't edit this value for future years before days are awarded.",
    "components.userLeaveTypeForm.quotaAdditionalSummary": "You'll set the {leaveTypeName} leave quota for {user} for the selected period to {total} {hourlyLeaveAccounting, plural, =1 {hours} other {days}}. The base quota and the additional days based on seniority entitlement and entitlement by role ({total} {hourlyLeaveAccounting, plural, =1 {hours} other {days}} in total) will be applied for the {future, plural, =1 {selected period and future periods} other {selected period}}. {prorateText}",
    "components.userLeaveTypeForm.quotaAdditionalSummaryUnlimited": "You'll set the {leaveTypeName} leave quota for {user} for the selected period to be Unlimited.",
    "components.userLeaveTypeForm.quotaBase": "Set the base leave quota",
    "components.userLeaveTypeForm.quotaBaseTooltip": "The base quota is the user's default quota, which excludes additional days received based on their seniority, role, TOIL, or any other extra days. You can change the base quota for everyone in one location on the leave policy page or use this field to set a custom quota for a selected user.",
    "components.userLeaveTypeForm.required": "This field is required.",
    "components.userLeaveTypeForm.update": "Update",
    "components.userLeaveTypeForm.updateUserLeaveType": "Update user's quotas for {name} leave type",
    "components.userLogsTab.ACCRUED_DAYS": "Accrued days",
    "components.userLogsTab.ALL": "All",
    "components.userLogsTab.APPROVER_STATUS": "Role change",
    "components.userLogsTab.filter": "Filter:",
    "components.userLogsTab.LOCATION_USER_MOVED": "Location change",
    "components.userLogsTab.logsNotFound": "Logs not found for selected date range.",
    "components.userLogsTab.ROLLOVER_EXPIRED": "Brought Forward days expired",
    "components.userLogsTab.selectFromTo": "Select date range:",
    "components.userLogsTab.TEAM_USER_MOVED": "Department change",
    "components.userLogsTab.TOIL_REQUEST_APPROVED": "TOIL",
    "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE": "Entitlement by role",
    "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_DELETED": "Entitlement by role deleted",
    "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_OVERRIDE": "Entitlement by role override",
    "components.userLogsTab.USER_LEAVE_TYPES_DELETED": "Leave quota reset to default",
    "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_DELETED": "Initial brought forward deleted",
    "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_UPDATED": "Initial brought forward updated",
    "components.userLogsTab.USER_LEAVE_TYPES_UPDATED": "Leave quota updated",
    "components.userLogsTab.USER_SENIORITY_ENTITLEMENT": "Seniority entitlement",
    "components.userLogsTab.USER_SENIORITY_ENTITLEMENT_OVERRIDE": "Seniority entitlement override",
    "components.userLogsTab.USER_STATUS_CHANGED": "Status change",
    "components.userLogsTab.USER_UPDATED": "User information update",
    "components.userLogsTab.USER_WORK_WEEK_DELETED": "Custom work week deleted",
    "components.userLogsTab.USER_WORK_WEEK_UPDATED": "Work week updated",
    "components.userLogsTab.YEAR_START": "Start of year",
    "components.userManageLabels.actions": "Actions",
    "components.userManageLabels.color": "Color",
    "components.userManageLabels.deleteLabel": "Are you sure you want to delete this label?\n\nThis will delete the label for all assigned users.",
    "components.userManageLabels.findLabel": "Find Label",
    "components.userManageLabels.isRequired": "{title} is required",
    "components.userManageLabels.manageLabels": "Manage Labels",
    "components.userManageLabels.name": "Name",
    "components.userManageLabels.userCount": "User Count",
    "components.userProfileTab.admin": "Administrator",
    "components.userProfileTab.approver": "Approver",
    "components.userProfileTab.approvers": "Approvers:",
    "components.userProfileTab.approverSuffix": " (Approver)",
    "components.userProfileTab.customWorkWeekEditedBefore": "{userName}'s workweek was modified in the previous version of Vacation Tracker (before January 1st, 2021)",
    "components.userProfileTab.customWorkWeekLabel": "custom workweek",
    "components.userProfileTab.customWorkWeekTooltip": "{userName}'s workweek was edited by {adminName} on {timestamp}",
    "components.userProfileTab.employeeIdNotSet": "Not set",
    "components.userProfileTab.employeeIdTooltip": "ID of the employee in an external system",
    "components.userProfileTab.employeeSince": "Employee since:",
    "components.userProfileTab.endDate": "End Date:",
    "components.userProfileTab.location": "Location:",
    "components.userProfileTab.name": "Name:",
    "components.userProfileTab.nameEdited": "The name is changed manually and it will not be automatically synced anymore. This name is used only inside Vacation Tracker.",
    "components.userProfileTab.role": "Role:",
    "components.userProfileTab.status": "Status:",
    "components.userProfileTab.team": "Department:",
    "components.userProfileTab.user": "User",
    "components.userProfileTab.userProfile": "User Profile",
    "components.userProfileTab.workingDays": "Working Days",
    "components.userTodayOff.cancelLeave": "Cancel leave",
    "components.userTodayOff.editLeave": "Edit leave",
    "components.userTodayOff.leaveInfo": "Leave info",
    "components.userTodayOff.offFromTo": "User has taken {leaveTypeName} from {startDate} to {endDate}",
    "components.userTodayOff.offTodayFullDay": "User has taken {leaveTypeName} today, {today}",
    "components.userTodayOff.offTodayPartDay": "User has taken {leaveTypeName} off today from {from} to {to}.",
    "components.userTodayOff.offTodayReason": "Reason: {reason}",
    "components.userUpdateForm.active": "Active",
    "components.userUpdateForm.admin": "Administrator",
    "components.userUpdateForm.approver": "Approver",
    "components.userUpdateForm.approverSuffix": " (Approver)",
    "components.userUpdateForm.cancel": "Cancel",
    "components.userUpdateForm.employeeSince": "Employee Since",
    "components.userUpdateForm.location": "Location",
    "components.userUpdateForm.name": "Name",
    "components.userUpdateForm.pleaseSelectALocation": "Select a location",
    "components.userUpdateForm.pleaseSelectATeam": "Select a department",
    "components.userUpdateForm.pleaseSelectAUserRole": "Select a User Role",
    "components.userUpdateForm.pleaseSelectEmployeeSince": "Add Employee Since Date",
    "components.userUpdateForm.pleaseSelectUserEndDate": "Add User End Date",
    "components.userUpdateForm.role": "Role",
    "components.userUpdateForm.team": "Department",
    "components.userUpdateForm.update": "Update",
    "components.userUpdateForm.updateUser": "Update User",
    "components.userUpdateForm.user": "User",
    "components.userUpdateForm.userEndDate": "Employee End Date",
    "components.userUpdateForm.warningInitialRolloverForChangeLocation": "Changing the user's location might impact the user's configuration and leave information (e.g. the initial brought forward days).",
    "components.userUpdateForm.warningInitialRolloverForChangeStartDate": "Changing a user's start date might impact the user's leave information (e.g. the Initial Brought Forward days).",
    "components.whatsNew.newMessage": "New message",
    "components.whatsNew.whatsNew": "What`s new",
    "connect.alreadyHaveAnAccount": "Already have an account?",
    "connect.alreadyHaveAnAccountWithLink": "Already have an account? <b><link>Log in</link></b>",
    "connect.backToLoginWithOtherPlatofrm": "Back to log in with other platform",
    "connect.companyExists.line1": "{companyName} is already registered with Vacation Tracker",
    "connect.companyExists.line2Other": "Are you using the right email?",
    "connect.companyExists.line2Slack": "Are you using the right credentials?",
    "connect.companyExists.line3aSlack": "Your Slack Workspace: {workspaceName}",
    "connect.companyExists.line3Other": "Your email address: {email}",
    "connect.companyExists.line3Slack": "Your email address: {email}",
    "connect.companyExists.line4Other": "If this email is correct your account is not activated. Please contact the administrator of your Vacation Tracker organization:",
    "connect.companyExists.line4Slack": "If these credentials are correct your account is not activated. Please contact the administrator of your Vacation Tracker organization:",
    "connect.companyExists.line5": "Otherwise, please log in with a different <capitalize>{platform}</capitalize> account or log in from an incognito window in your browser.",
    "connect.companyExists.line6Microsoft": "Contact Support to create a new department in {companyName}",
    "connect.companyExists.lineEmailAddress": "Your email address",
    "connect.companyExists.title": "Company already exists",
    "connect.connect": "Connect",
    "connect.connectWithWorkEmail": "Log in with work email",
    "connect.connectWithYourWorkEmail": "Log in using your work email",
    "connect.copyEmail": "Click to copy email",
    "connect.copyTextEnd": "Happy Vacationing! 🌴",
    "connect.createAnOrganization": "Create an organization",
    "connect.description": "To access the Vacation Tracker Dashboard, select the \"Connect with\" button that corresponds to the platform used by your team.",
    "connect.dontHaveAnAccount": "Don't have an account?",
    "connect.doYouWantToLoginInToOrganization": "Do you want to login in to {existingOrganizationName}?",
    "connect.forgotYourPassword": "Forgot your password?",
    "connect.forgotYourPasswordWhatsYourEmail": "What's your email?",
    "connect.google.notWorkspaceUserDescription": "It seems that you are trying to sign up with a personal account and not a Google Workspace account. At the moment, Vacation Tracker supports only Google Workspace accounts.",
    "connect.google.notWorkspaceUserTitle": "A Google Workspace account is required",
    "connect.google.sharedPermissionsConsent": "By clicking the button below, you are granting Vacation Tracker permission to access your Google admin users directory.",
    "connect.google.sharedPermissionsError": "Failed to share google permissions, your link has expired.",
    "connect.google.sharedPermissionsRequired": "Insufficient permissions",
    "connect.google.sharedPermissionsRequiredDescription": "To proceed with importing users, you'll need to obtain permissions from your Google administrator. Please share this link with your admin to grant the necessary consent: ",
    "connect.google.sharedPermissionsSuccess": "Successfully shared google permissions!",
    "connect.google.sharedPermissionsSuccessRedirect": "You will be redirected to the Vacation Tracker home page in a few seconds.",
    "connect.howShouldIConnect": "How should I connect?",
    "connect.inputEmailPlaceholder": "Enter your working email",
    "connect.inputPasswordPlaceholder": "Enter your password",
    "connect.interactionRequiredMSErrorDescription1": "It seems that your Microsoft account requires additional permissions. Please click the Log in button below to grant additional permissions.",
    "connect.interactionRequiredMSErrorDescription2": "If this problem persists, please contact our support and send them the following code:",
    "connect.interactionRequiredMSErrorTitle": "Microsoft requires additional permissions",
    "connect.login": "Login",
    "connect.meta.description": "Access the Vacation Tracker Dashboard, and unlock the full power of effortless leave management.",
    "connect.meta.title": "Signup/Login - Vacation Tracker",
    "connect.meta.title.signin": "Sign In - Vacation Tracker",
    "connect.meta.title.signup": "Signup - Vacation Tracker",
    "connect.or": "Or",
    "connect.platformErrorDescription1": "The following error occurred:",
    "connect.platformErrorDescription2": "This could be a {platform} error. However, feel free to contact our support for further assistance.",
    "connect.platformErrorTitle": "Something went wrong",
    "connect.pleaseInputValidEmail": "Please enter a valid email",
    "connect.pleaseInputValidPassword": "Please enter a valid password",
    "connect.pleaseLogIn": "Please log in",
    "connect.readMore": "For more information, visit our <helpdesk>Helpdesk</helpdesk> or go to our <website>website</website>.",
    "connect.signInWithEmail": "Log in with email",
    "connect.signInWithGoogle": "Log in with Google",
    "connect.signInWithGoogleToOrganization": "Login to Google Workspaces to {existingOrganizationName}",
    "connect.signInWithGoogleVerification": "Sign in with Google",
    "connect.signInWithMS": "Log in with Microsoft Teams",
    "connect.signInWithMSToOrganization": "Login to with Microsoft Teams to {existingOrganizationName}",
    "connect.signInWithSlack": "Log in with Slack",
    "connect.signInWithSlackInstallBot": "Add Vacation Tracker to Slack",
    "connect.signInWithSlackToOrganization": "Login in with Slack to {existingOrganizationName}",
    "connect.signUp": "Sign up using your work email",
    "connect.signUpInfo": "Sign up for your free trial",
    "connect.signupNewOrganizationUsingNewPlatform": "Sign up as a new organization using your {selectedPlatform} account",
    "connect.signUpWithEmail": "Sign up with email",
    "connect.signUpWithGoogle": "Sign up with Google",
    "connect.signUpWithMicrosoft": "Sign up with Microsoft",
    "connect.signUpWithSlack": "Sign up with Slack",
    "connect.slack.usersLoginRequiredButton": "Grant Slack permissions",
    "connect.slack.usersLoginRequiredDescription1": "You Slack bot token has expired or is no longer valid. You won't be able to access the Vacation Tracker bot, or receive Notifications until you activate a new token.",
    "connect.slack.usersLoginRequiredDescription2": "To activate a new Slack bot token, please click OK.",
    "connect.slack.usersLoginRequiredDescription3": "To skip this step, and proceed to the online Dashboard, click Skip.",
    "connect.slack.usersLoginRequiredTitle": "Login to your Slack account is required",
    "connect.slackPostInstallation1": "You successfully installed the Vacation Tracker Slack application 🎉",
    "connect.slackPostInstallation2": "To start using the app, please log in to the Vacation Tracker Dashboard and complete the setup.",
    "connect.slackPostInstallation3": "We'll ask you a few simple questions. It should take no more than a few minutes. Blink of an eye and it's done (just like your last vacation).",
    "connect.slackPostInstallationError.generic": "Hey, we need to install the Vacation Tracker bot first. You'll be able to review the Vacation Tracker Slack app permissions and grant them in the next step.",
    "connect.slackPostInstallationError.invalidCode": "The redirect code is no longer valid. It can happen if the code expired, was already used, or you refreshed the page before we finished the login process. But don't worry, let's get a new one!",
    "connect.subscriptionExpired": "Oops, it seems that your company's subscription to Vacation Tracker has expired! Please contact an administrator to re-activate your company's subscription.",
    "connect.subscriptionExpiredTitle": "Subscripton Expired",
    "connect.thisOrganizationUsePlatform": "However, {existingOrganizationName} uses {existsOnAnotherPlatform}, so you'll need to log in with {existsOnAnotherPlatform} to access your account",
    "connect.welcome": "Welcome, {name}!",
    "connect.youAreAlreadyMember": "You are already a member of the {existingOrganizationName} organization",
    "connectCalendar.connect": "Connect with",
    "connectCalendar.disconnect": "Disconnect",
    "connectCalendar.disconnectDescription": "Are you sure you want to disconnect calendar? Vacation Tracker will not update it with your future leaves anymore",
    "connectCalendar.disconnectError": "Disconnecting calendar error",
    "connectCalendar.disconnectErrorDescription": "There was an error while trying to disconnect you calendar with the Vacation Tracker. Please, click Ok to try again.",
    "connectCalendar.disconnectSuccess": "You have successfully disconnected {calendar} calendar.",
    "connectCalendar.disconnectTitle": "Disconnect {calendarPlatform} Calendar",
    "connectCalendar.error": "Connecting calendar error",
    "connectCalendar.errorDescription": "There was an error while trying to connect you calendar with the Vacation Tracker. Please, click Ok to try again.",
    "connectCalendar.errorReconnectDescription": "There was an error while trying to reconnect you calendar with the Vacation Tracker. Please, click Ok to try again.",
    "connectCalendar.reconnect": "Reconnect",
    "connectCalendar.reconnectAdminConsentSharedCalendar": "Admin consent is required to insert or update events in the calendar.",
    "connectCalendar.reconnectError": "Reconnecting calendar error",
    "connectCalendar.reconnectInfo": "To ensure your leave is visible, please reconnect to your calendar.",
    "connectCalendar.reconnectNotification": "The connection to your calendar has expired. Please click here to reconnect and ensure your leave is visible in your calendar.",
    "connectCalendar.reconnectNotificationSharedCalendar": "Your connection to the shared calendar: {calendarName} has expired. Please click here to reconnect and ensure that leaves are visible in your calendar.",
    "connectCalendar.reconnectSuccess": "You have successfully reconnected {calendar} calendar.",
    "connectCalendar.success": "You have successfully connected {calendar} calendar.",
    "connectedCalendar.eventDescription": "Added by <link>Vacation Tracker</link>",
    "connectedCalendar.eventDescriptionShared": "Shared calendar event added by <link>Vacation Tracker</link>",
    "containers.google.topbar.adminProductTourInfo": "As an Administrator you have the highest level of permissions in Vacation Tracker, and can customize all the organization's settings through the Administrator Dashboard.\n\nThe Dashboard allows you to: \n- Request Leave, or add Leave for other department members. \n- Have a full overview through the Dashboard Homepage, and the Calendar. \n- Create and configure multiple departments (Assign Approvers)  \n- Set Notifications \n- Manage Users (Active, Inactive, Deleted).\n- Create and manage Locations (Leave Types, Holidays, Notifications)\n\nIf your questions or concerns remain unanswered, reach out to us at any time. Speaking to our customers is the best part of our day: ",
    "containers.google.topbar.approverProductTourInfo": "As an Approver, you have the second highest level of permissions in Vacation Tracker, and can approve or deny leave requests submitted by Users from the department they've been assigned as an Approver for.\n\nThe Dashboard allows you to:\n- Request Leave, or add Leave for other department members.\n- Have a full overview through the Dashboard Homepage, and the Calendar. \n- Set Notifications \n- View your User Profile.\n- Manage Requests (Approve/Deny Requests, View History).\n\nIf your questions or concerns remain unanswered, reach out to us at any time. Speaking to our customers is the best part of our day: ​​​​​​​​",
    "containers.google.topbar.userProductTourInfo": "As a Vacation Tracker User, you can submit and manage your Leave Requests through the Dashboard, as well as have a full overview of planned absences within your organization.\n\nThe Dashboard allows you to:\n- Request Leave\n- Have a full overview through the Dashboard Homepage, and the Calendar.\n- View your User Profile.\n\nIf your questions or concerns remain unanswered, reach out to us at any time. Speaking to our customers is the best part of our day: ​​​​​​​​",
    "containers.topbar.adminProductTourInfo": "As an Administrator you have the highest level of permissions in Vacation Tracker, and can customize all the organization's settings through the Administrator Dashboard.\n\nThe Dashboard allows you to: \n- Request Leave, or add Leave for other department members. \n- Have a full overview through the Dashboard Homepage, and the Calendar. \n- Create and configure multiple departments (Assign Approvers) \n- Set Notifications  \n- Manage Users (Active, Inactive, Deleted).\n- Create and manage Locations (Leave Types, Holidays, Notifications)\n\nThe Bot allows you to: \n- Request Leave \n- Approve/Deny Leave Requests\n- Receive Daily/Weekly Notifications\n\nIf your questions or concerns remain unanswered, reach out to us at any time. Speaking to our customers is the best part of our day: ",
    "containers.topbar.adminProductTourTitle": "Administrator",
    "containers.topbar.announcement": "Announcement",
    "containers.topbar.approverProductTourInfo": "As an Approver, you have the second highest level of permissions in Vacation Tracker, and can approve or deny leave requests submitted by Users from the department they've been assigned as an Approver for.\n\nThe Dashboard allows you to:\n- Request Leave, or add Leave for other department members.\n- Have a full overview through the Dashboard Homepage, and the Calendar. \n- Set Notifications \n- View your User Profile.\n- Manage Requests (Approve/Deny Requests, View History).\n\nThe Bot allows you to:\n- Request Leave\n- Approve/Deny Leave Requests\n- Receive Daily/Weekly Notifications\n\nIf your questions or concerns remain unanswered, reach out to us at any time. Speaking to our customers is the best part of our day: ",
    "containers.topbar.approverProductTourTitle": "Approver",
    "containers.topbar.giveUsFeedback": "Give us feedback",
    "containers.topbar.help": "Help",
    "containers.topbar.logout": "Logout",
    "containers.topbar.profile.info": "You can access your profile, switch the theme, and start the quick tour from this new menu.",
    "containers.topbar.setUpTheBot": "Set up the bot",
    "containers.topbar.userProductTourInfo": "As a Vacation Tracker User, you can submit and manage your Leave Requests through the Dashboard or the Bot, as well as have a full overview of planned absences within your organization.\n\nThe Dashboard allows you to:\n- Request Leave\n- Have a full overview through the Dashboard Homepage, and the Calendar.\n- View your User Profile.\n\nThe Bot allows you to:\n- Request Leave \n- Receive Daily/Weekly Notifications\n\nIf your questions or concerns remain unanswered, reach out to us at any time. Speaking to our customers is the best part of our day: ",
    "containers.topbar.userProductTourTitle": "User",
    "containers.topbar.whatsNewUnread": "What`s new - {unread} unread",
    "Conversation not found.": "The selected channel has been deleted or is no longer available. Please select another channel to receive Notifications on. ",
    "createCompany.signupError": "Something went wrong",
    "createCompany.steps.back": "Back",
    "createCompany.steps.cancel": "Cancel",
    "createCompany.steps.companyForm": "Company Details",
    "createCompany.steps.companyForm.agreeTerms": "I accept <aPP>Privacy Policy</aPP>, <aTOS>Terms Of Service</aTOS> and <aDPA>DPA</aDPA>. I also acknowledge and agree to the processing of my personal data in compliance with the terms outlined in the Privacy Policy",
    "createCompany.steps.companyForm.agreeToTerms.error": "You must agree to our Terms of Service and Privacy Policy to continue.",
    "createCompany.steps.companyForm.companyInfoSectionDescription": "Tell us about your company and location. We'll use this information to create your company account and your first location. You can always add more locations later.",
    "createCompany.steps.companyForm.companyLeaveSection": "Default Leave Type",
    "createCompany.steps.companyForm.companyName": "Company Name",
    "createCompany.steps.companyForm.companyNameInput.validation.required": "This is a required field.",
    "createCompany.steps.companyForm.contactEmail.validation.country": "This is a required field.",
    "createCompany.steps.companyForm.contactEmail.validation.required": "Necessary for important product communication.",
    "createCompany.steps.companyForm.contactEmail.validation.state": "This is a required field.",
    "createCompany.steps.companyForm.contactEmail.validation.type": "Please enter a valid email address.",
    "createCompany.steps.companyForm.contactEmailExtra": "The email address you provide will be used for important product communication only. No spam, we promise.",
    "createCompany.steps.companyForm.country": "Country",
    "createCompany.steps.companyForm.defaultPtoQuota": "Paid Time Off (PTO) Quota",
    "createCompany.steps.companyForm.defaultPtoQuota.validation": "Please enter your default PTO quota",
    "createCompany.steps.companyForm.defaultPtoQuota.validation.maxDefaultPtoQuota": "Your default PTO quota can't be more than 366 days",
    "createCompany.steps.companyForm.defaultPtoQuota.validation.minDefaultPtoQuota": "Your default PTO quota can't be less than 0 days",
    "createCompany.steps.companyForm.defaultPtoQuotaExtra": "You can change this later in your Settings.",
    "createCompany.steps.companyForm.email": "Contact Email",
    "createCompany.steps.companyForm.location": "Location info: ",
    "createCompany.steps.companyForm.state": "State",
    "createCompany.steps.companyForm.step1Title": "Company Details",
    "createCompany.steps.companyForm.step2Title": "Select Users",
    "createCompany.steps.companyForm.step3Title": "Select Plan",
    "createCompany.steps.companyForm.unlimitedLeavePolicy": "Unlimited PTO",
    "createCompany.steps.companyForm.UserInfoSectionDescription": "Tell us who you are. We'll use this information to create your user profile. By default, you'll be assigned the role of an administrator and approver. You can add more users and change their roles later.",
    "createCompany.steps.inviteUsers.next": "Invite {noOfUsers} User{postfix}",
    "createCompany.steps.inviteUsers.title": "Invite your team members",
    "createCompany.steps.inviteUsers.title.info": "Invite the users you want to add to your organization's Vacation Tracker account. You can always make changes later through the dashboard.",
    "createCompany.steps.next": "Continue",
    "createCompany.steps.selectPlan.noCC": "No credit card required for your 7-day trial",
    "createCompany.steps.selectPlan.title": "Let's get started!",
    "createCompany.steps.selectPlan.youCanSwitchPlanDuringTrial": "You can switch plans anytime during the trial",
    "createCompany.steps.selectUsers": "Import Users",
    "createCompany.steps.selectUsers.deselectAll": "Deselect All",
    "createCompany.steps.selectUsers.doneButton": "Import {noOfUsers} User{postfix}",
    "createCompany.steps.selectUsers.doneButtonTryItOut": "Start Your 14-Day Free Trial",
    "createCompany.steps.selectUsers.loadingAll": "Loading All Users",
    "createCompany.steps.selectUsers.searchUsers": "Find Nemo...",
    "createCompany.steps.selectUsers.selectAll": "Select All",
    "createCompany.steps.selectUsers.title": "Choose your team members",
    "createCompany.steps.selectUsers.title.info": "Select the users you want to add to your organization's Vacation Tracker account. You can always make changes later through the dashboard. The users you select won't be notified until you make the announcement.",
    "dashboard.accountSettingsLanguageLabel": "Choose your preferred language",
    "dashboard.accountSettingsOutOfOfficeChangeModalInfo": "Vacation Tracker will set all your future out-of-office messages for the {email} email.\n However, if you scheduled an out-of-office message before this change, we'll still set it for the old email.",
    "dashboard.accountSettingsOutOfOfficeChangeModalTitle": "Change email address for future out-of-office messages",
    "dashboard.accountSettingsOutOfOfficeConnectedWith": "Connected with",
    "dashboard.accountSettingsOutOfOfficeTitle": "Configure Automatic Out-of-Office (OOO) Reply",
    "dashboard.accountSettingsPageOOODescription": "Once enabled, Vacation Tracker will notify you two days before your planned leave and present you with the opportunity to set up an automated Out of Office (OOO) reply message.",
    "dashboard.accountSettingsTimeDisplayLabel": "Choose your preferred time display format",
    "dashboard.accountSettingsUIModeLabel": "Dark Mode Vs. Light Mode",
    "dashboard.b2bSoftware": "Please note that this is a B2B software and therefore we are unable to change or delete your data upon request. For more information, please contact your dedicated manager. Should you require any assistance, our support team is available at <mail>hello@vacationtracker.io</mail>",
    "dashboard.day": "day",
    "dashboard.days": "Day(s)",
    "dashboard.daysPlural": "days",
    "dashboard.daysSmallCase": "day(s)",
    "dashboard.daysSmallCaseSingle": "d",
    "dashboard.googlePermissionInfo2": "- Access to your Google Calendar Settings is required to obtain your Google timezone settings.",
    "dashboard.googlePermissionInfo3": "- Access to your Gmail Settings is needed to set up your OOO reply and define the relevant date range.",
    "dashboard.hour": "hour(s)",
    "dashboard.hourOne": "hour",
    "dashboard.hours": "hours",
    "dashboard.hourSingle": "h",
    "dashboard.minute": "minute",
    "dashboard.minuteAbbreviation": "m",
    "dashboard.minutes": "minutes",
    "dashboard.noLeaveRequest": "This leave request is not active.",
    "dashboard.noOneIsOffToday": "There are no planned leaves for today.",
    "dashboard.noOneIsTackingLeaves": "There are no scheduled leaves at the moment.",
    "dashboard.noOpenLeavesRequests": "There are no pending requests.",
    "dashboard.noScheduledLeavesHolidays": "No scheduled leaves or holidays",
    "dashboard.openLeavesRequests": "Pending Requests",
    "dashboard.outlookPermissionInfo2": "- Offline access: This is necessary for Vacation Tracker to set up your OOO reply.",
    "dashboard.outlookPermissionInfo3": "- Outlook Mailbox settings: We need access to your Outlook timezone settings to ensure accurate timing. Additionally, we require permission to set your OOO message and define the desired date range.",
    "dashboard.outOfOffice": "Set OOO Message",
    "dashboard.outOfOffice.requestIntegrationButton": "Request an integration",
    "dashboard.outOfOfficeActiveLabel": "Out of Office",
    "dashboard.outOfOfficeGoogleMailButton": "Google email",
    "dashboard.outOfOfficeGoogleSigninModalTitle": "Google Account Sign-in Required",
    "dashboard.outOfOfficeMicrosoftSigninModalTitle": "Microsoft Account Sign-in Required",
    "dashboard.outOfOfficeNotActiveInfo": "It seems that you haven't yet enabled the Automatic Out-of-Office Email Reply feature in your account. This tool allows you to set up automated emails for specific types of leave, ensuring your contacts are aware when you're unavailable. Would you like to turn on this feature?",
    "dashboard.outOfOfficeOtherEmail": "Do you have a different email provider?",
    "dashboard.outOfOfficeOtherEmailInfo": "At this moment, we support Gmail and Outlook. However, we're constantly striving to improve our software. Your feedback on the email provider you're using would be greatly beneficial in helping us determine our development priorities. Until we extend our support to other providers, you can manually establish your Out-of-Office email reply within your email service.",
    "dashboard.outOfOfficeOtherEmailPlaceholder": "Enter your provider i.e. ProtonMail, Yahoo! Mail...",
    "dashboard.outOfOfficeOutlookButton": "Outlook",
    "dashboard.outOfOfficePageSettingsTitle": "CHOOSE YOUR EMAIL PROVIDER",
    "dashboard.outOfOfficePageTitle": "Out of Office",
    "dashboard.outOfOfficeSet": "Set OOO Message",
    "dashboard.outOfOfficeSetError": "There was an error with setting your Out of Office automatic reply. Please try again.",
    "dashboard.outOfOfficeSetInfo": "This is where you can set your 'Out of Office' email reply. While you're away, this message will be automatically sent to individuals who attempt to reach you via email. Should you decide to modify this message in the future, it can easily be done through your email settings.",
    "dashboard.outOfOfficeSetInfoBot": "Your 'Out of Office' email reply is set. While you're away, this message will be automatically sent to individuals who attempt to reach you via email. Should you decide to modify this message in the future, it can easily be done through your email settings.",
    "dashboard.outOfOfficeSetSuccess": "Congratulations — you have successfully set your automatic out-of-office message. It will be active when your leave starts.",
    "dashboard.peopleOffToday": "Today's Leaves",
    "dashboard.permissionModalInfo": "For Vacation Tracker to automatically set your Out-of-Office (OOO) reply, we need further permissions:",
    "dashboard.selectSnoozeLeaveTypesPlaceholder": "Select leave types...",
    "dashboard.snoozedLeaveTypesLabel": "Disactivate for ",
    "dashboard.upcomingDaysOff": "Scheduled Leaves ({total})",
    "dashboard.upcomingHolidays": "Upcoming holidays",
    "departments.viewDepartment": "View Department",
    "disconnectCalendar.success": "You have successfully disconnected calendar.",
    "editLeave.editLeave": "Edit Leave",
    "editLeave.handleSubmitErrorTitle": "Leave Edit Error",
    "editLeave.inProgress": "Editing leave",
    "editLeave.requestLeave": "Edit Leave",
    "editLeave.title": "Leave edited",
    "email.notification.accruedDaysInfoForApprover": "ℹ️ This leave request is based on future accrued days off, not the current amount. {name} has {amount} day(s) remaining today. They will accrue {accrued} on {earningDate}.",
    "email.notification.accruedDaysInfoForUser": "ℹ️ Your leave request is based on future accrued days off, not the current amount. You have {amount} day(s) remaining today, and you'll accrue {accrued} on {earningDate}.",
    "email.notification.holiday.plural": "<strong>{locationNames}</strong> locations will be away for <strong>{holidayName}</strong> {holidayPeriodText}",
    "email.notification.holiday.singular": "<strong>{locationNames}</strong> location will be away for <strong>{holidayName}</strong> {holidayPeriodText}",
    "email.notification.title": "Leave Notification",
    "error.ApproverNotFound": "Approver not found",
    "error.auth.companyExists.line1": "Your account is not activated. To use Vacation Tracker, please contact the administrator and ask them to activate your account.",
    "error.auth.companyExists.line2": "Administrators:",
    "error.auth.companyExists.line3": "Or, contact our support ({support}) if you want to use Vacation Tracker as an independent department in the same company.",
    "error.auth.companyExists.title": "Your organization is already signed up",
    "error.automations.automationSubmitError": "Automation Submmit Error",
    "error.automations.insufficientPrivileges.description": "You don't have permission to perform this action. Please contact your administrator.",
    "error.automations.insufficientPrivileges.title": "Permission error",
    "error.blackOutPeriod.isntCreated": "Blackout period isn't created",
    "error.blackOutPeriod.isntDeleted": "Blackout period isn't deleted",
    "error.blackOutPeriod.isntUpdated": "Blackout period isn't updated",
    "error.blackOutPeriod.UserNotAdminOrApprover": "User not admin or approver",
    "error.blackOutPeriod.UserNotFound": "User not found",
    "error.botTokenNotFound": "Bot token not found, please login to our dashbaord and try again.",
    "error.CannotDuplicateName": "The name you entered is already in use. Please enter a different name.",
    "error.channelNotFound": "The selected channel has been deleted or is no longer available. Please select another channel to receive Notifications on.",
    "error.codeDeliveryFailureException": "Code delivery failure",
    "error.codeDeliveryFailureExceptionMessage": "We are not able to deliver a verification code. Please verify that you provided a valid email address. If your email address is valid, please try again later.",
    "error.codeMismatchException": "Invalid verification code",
    "error.codeMismatchExceptionMessage": "Invalid verification code provided. Please try again or resend email.",
    "error.companyExists": "Company already exists",
    "error.CustomWorkday": "Downgrade to a plan without custom work hours is impossible. Please remove custom work hours from the settings or contact support.",
    "error.deletedLocation": "The selected Location has been deleted or is no longer available. Please select another Location(s) to receive Notifications for.",
    "error.deletedTeam": "The selected Department has been deleted or is no longer available. Please select another Department(s) to receive Notifications for.",
    "error.DepartmentWithChildrenDeleteForbidden": "You cannot delete a department that has subdepartments. Please delete the subdepartments first.",
    "error.EmailAlreadyExists": "User with this email already exists in Vacation Tracker.",
    "error.emailError": "Invalid email",
    "error.emailError.description": "Please provide a valid email address",
    "error.endTimeCantBeBeforeStartTime": "Looks like the start hour you've selected is after the end hour. Please select a start hour that's before the end hour.",
    "error.entitlementByRole.isntCreated": "Entitlement by role isn't created",
    "error.entitlementByRole.isntDeleted": "Entitlement by role isn't deleted",
    "error.entitlementByRole.isntUpdated": "Entitlement by role isn't updated",
    "error.expiredCodeException": "Verification code expired",
    "error.expiredCodeExceptionMessage": "Provided verification code expired.",
    "error.fetchingConnectedCalendarInfo": "There was a problem with fetching your connected caledar. Please, try again. If this problem persists, please contact our customer support",
    "error.fetchingUserOutOfOfficeSettings": "There was a problem with fetching your out of office settings. Please, try again. If this problem persists, please contact our customer support",
    "error.fieldIsRequired": "This field is required.",
    "error.firstEarningDateMissing": "Frist earning date missing",
    "error.generic": "An error occured",
    "error.generic.description": "Please contact support with the following message:",
    "error.google.directoryApiDisabledP1": "Vacation Tracker needs access to Directory data to import new Users.",
    "error.google.directoryApiDisabledP2": "The Administrator of your Google Workspace can allow directory access by following the steps from the support article: ",
    "error.google.directoryApiDisabledP3": "You can sign up without enabling this functionality. However, you will not be able to import users from your Google Workspace.",
    "error.google.directoryApiDisabledP4": "If you need additional help, reach out to us at hello@vacationtracker.io",
    "error.google.directoryApiDisabledTitle": "Contact Sharing Turned Off",
    "error.google.directoryApiPermissionDeniedP1": "Vacation Tracker needs to be able to list users from your Google Workspace directory. Please click the \"Sign in with Google\" button and check the checkbox as shown in the image below to be able to sign up.",
    "error.google.directoryApiPermissionDeniedP2": "We'll store only the following information for the users you explicitly select to import: name, profile picture, and email.",
    "error.google.directoryApiPermissionDeniedP3": "If you don't see the checkbox when you click the \"Sign in with Google\" button, please click the \"Continue\" button in the Google permissions modal to proceed with the signup process.",
    "error.google.directoryApiPermissionDeniedTitle": "Grant missing permissions to continue",
    "error.google.loginRequiredTitle": "Login to your Google account is required",
    "error.google.usersLoginRequiredDescription1": "Managing users require Google scopes. If you click the OK button, we'll redirect you to the Google login page. Depending on your browser settings, you might be already logged in and automatically redirected back.",
    "error.google.usersLoginRequiredDescription2": "After logging in to your Google account, we will take you back to this page so that you can manage the users.",
    "error.goToRequestLeaveTab": "Request Leave Tab transition error",
    "error.holidayDateInvalid": "Invalid holiday date {date} for {year} ",
    "error.incorrectPassword": "Incorrect password",
    "error.incorrectPasswordMessage": "The password you entered is incorrect or expired. Please try again (make sure your caps lock is off). Forgot your password?",
    "error.invalidFileType": "Invalid file type",
    "error.invalidFileType.description": "You uploaded an invalid file type. Please upload JPEG, PNG or GIF image.",
    "error.invalidPasswordException": "Invalid password",
    "error.invalidPasswordExceptionMessage": "Please provide a valid password with 8 characters or more.",
    "error.leaveDurationLimit.isntCreated": "Failed to create leave duration limit",
    "error.leaveDurationLimit.isntDeleted": "Failed to delete leave duration limit",
    "error.leaveDurationLimit.isntUpdated": "Failed to update leave duration limit",
    "error.leavePolicyMissing": "Leave Policy Missing",
    "error.leaveRequestDifferentWorkingHours": "You are requesting leave across days with different work hours.",
    "error.leaveRequestNotEditable": "You are not allowed to edit this leave request.",
    "error.leaveRequestOverlap": "The dates you've chosen overlap with an existing leave. Please select a different time period.",
    "error.leaveRequestsDataError.title": "Unable to load data",
    "error.leaveRequestsLoadingError.description": "Your leaves exist, but we could not load them due to a technical issue on our end. Please try reloading the page. If the problem keeps happening, contact our <link>Customer Support</link>.",
    "error.leaveRequestsLoadingError.title": "Unable to load leaves",
    "error.leaveRequestStatusNotApproved": "Leave request status: not approved.",
    "error.leaveRequestStatusNotOpen": "Leave request status: not open",
    "error.leaveSubmitError": "Leave Submit Error",
    "error.leaveTypeMissing": "Leave Type Missing",
    "error.leaveTypeOrPolicyInactive": "Selected leave type is inactive",
    "error.LocationLimitReached": "We're sorry, but you cannot downgrade to the CORE plan as you currently exceed the limit of {limitNumber} locations. Please adjust your locations to meet the CORE plan criteria or contact support for further guidance.",
    "error.maxmimumUsersAway.isntCreated": "Maximum Users Away configuration isn't created",
    "error.maxmimumUsersAway.isntDeleted": "Maximum Users Away configuration isn't deleted",
    "error.maxmimumUsersAway.isntUpdated": "Maximum Users Away configuration isn't updated",
    "error.microsoft.channelNotFound": "You are not a member the selected Microsoft Teams team",
    "error.microsoft.logInWithDifferentAccount": "Log in with different account",
    "error.microsoft.microsoftPermissionsNotGranted": "Microsoft permissions not granted",
    "error.microsoft.notificationsLoginRequiredTitle": "Login to your Microsoft account is required",
    "error.microsoft.notMemberOfTeam": "Your organization signed up with a single Microsoft Teams team, and you are not the member of the team with the following ID: {msTeamId}.\n\nYou can join this team on your Microsoft Teams application and try again, or contact our support to change your installation type.",
    "error.microsoft.wrongAccount": "Wrong Microsoft account",
    "error.minutesMustMatch": "Please select a valid time range - minutes must match",
    "error.missingSlackBotToken": "The action has been processed, however, we're missing the bot token to notify the user. Please log out, and log in again to gain the bot token. ",
    "error.missingSlackBotTokenError": "We're unable to process the request because you are missing the bot token. Please log out, and log in again to receive the bot token, and try again.",
    "error.MustBeApproverForTeam": "You are not allowed to perform this action, as you must be an Approver for this department to do so.",
    "error.mustBeCreatorOrApprover": "Must be creator or approver to change status of leave request.",
    "error.negativeBallanceNotAllowed": "Negative balance is not allowed for this leave type.",
    "error.notificationGeneral": "Notification Error",
    "error.oneHoureIntervalNotAllowed": "The shortest time interval you can request for this leave type is 4 hours (half-day).",
    "error.openApi.isntCreated": "Open API Key isn't created",
    "error.openApi.isntDeleted": "Open API Key isn't deleted",
    "error.partTimeIsntAllowed": "Part time isn't allowed",
    "error.passwordResetLimitExceededException": "Limit exceeded",
    "error.passwordResetLimitExceededExceptionMessage": "Password change attempt limit exceeded. Please try again in 15 minutes.",
    "error.paymentFailed": "Payment failed",
    "error.paymentNotProcessed": "Payment failed, please try with a different card.",
    "error.probationPeriod.isntCreated": "Probation period isn't created",
    "error.probationPeriod.isntDeleted": "Probation period isn't deleted",
    "error.probationPeriod.isntUpdated": "Probation period isn't updated",
    "error.PromoCodeDoesntExist": "Promo code {promoCode} doesn't exist",
    "error.PromoCodeIsntValid": "Promo code {promoCode} isn't valid",
    "error.reasonRequired": "Reason field is required.",
    "error.requestedMoreThanFullDay": "You have requested more than one full day of leaves in one workday. Please select a different day.",
    "error.samePasswordException": "Password not changed",
    "error.samePasswordExceptionMessage": "The new password is the same as the old one. Please enter a different password if you want to change it.",
    "error.saveFailed": "Save failed",
    "error.seniorityEntitlement.isntCreated": "Seniority entitlement isn't created",
    "error.seniorityEntitlement.isntDeleted": "Seniority entitlement isn't deleted",
    "error.seniorityEntitlement.isntUpdated": "Seniority entitlement isn't updated",
    "error.signin.inactiveUser": "User Inactive",
    "error.signup.alreadySignedUp.descriptionLine1": "You are already signed up with the following email address: <text>{ email }</text>. Please log in.",
    "error.signup.alreadySignedUp.descriptionLine2": "In case you forgot your password, click the button below to reset it.",
    "error.signup.alreadySignedUp.title": "You are already signed up",
    "error.signup.alreadySignedUpInactive.descriptionLine1": "You are already signed up with the following email address: <text>{ email }</text>.",
    "error.signup.alreadySignedUpInactive.descriptionLine2": "However, your account is inactive. Please contact our support for more information and send them the following info:",
    "error.slack.allowConversationWithSlackBotDescription": "This feature requires an additional Slack permission that allows the Vacation Tracker bot for Slack to receive direct messages. Our bot will be able to read the messages you and other users from your Slack Workspace send to it, but it'll not have permission to read your channels, groups, or direct messages.",
    "error.slack.allowConversationWithSlackBotLlmWarningLine1": "Vacation Tracker's bot supports a slash command and shortcut for leave requests. In addition to this, you can enable human language conversation in the Web Dashboard (and opt-in for LLM usage).",
    "error.slack.allowConversationWithSlackBotLlmWarningLine2": "Turning this on might cause the bot to occasionally suggest incorrect dates or types of leave. It's important to double-check its recommendations before making a leave request.",
    "error.slack.allowConversationWithSlackBotLlmWarningLine3": "Neither Vacation Tracker nor its third parties use your information to train Large Language Models.",
    "error.slack.allowConversationWithSlackBotLlmWarningTitle": "Large Language Model (LLM) Disclaimer",
    "error.slack.allowConversationWithSlackBotTitle": "Allow conversation with the Vacation Tracker bot",
    "error.slack.logInWithDifferentAccount": "Log in with different account",
    "error.slack.usersLoginRequiredTitle": "Login to your Slack account is required",
    "error.slack.wrongAccount": "Wrong Slack account",
    "error.somethingWentWrong": "Oh no! 😕 Something went wrong. ",
    "error.startDateCantBeInPast": "Start date can't be in the past, please select the date in the future",
    "error.subscriptionExpired": "Oops, it seems that your company's subscription to Vacation Tracker has expired! Please contact an administrator to re-activate your company's subscription.",
    "error.subscriptionExpiredTitle": "Subscription expired",
    "error.team.delete": "Department cannot be deleted",
    "error.TeamLimitReached": "Your current number of departments exceeds the limit allowed in the CORE plan. To downgrade, please reduce your departments to {limitNumber} or fewer, or contact our support for assistance.",
    "error.token.button": "Login with {platform}",
    "error.token.description": "Your {platform} token expired. Please, click on the button below to get a new token",
    "error.token.permissionDenied": "On the Google Login prompt you've clicked on Continue without allowing required authentication scopes. Please, click on the Select all or select adequate permissions (See, edit, share, and permanently delete all the calendars you can access using Google Calendar and See, edit, create, or change your email settings and filters in Gmail) and then click on Continue.",
    "error.userEndDateInFuture": "The requested dates are after the users employment end date. Please contact your organizations administrator or Vacation Tracker support for more information.",
    "error.UserNotAdminOrApprover": "User not admin or approver",
    "error.userNotFound": "User not found",
    "error.UserNotFound": "User not found",
    "error.UserNotFoundApprover": "Approver not found",
    "error.userSignup.invitationAccepted.description": "Invitation code already accepted, please log in",
    "error.userSignup.invitationAccepted.title": "Invitation code already accepted",
    "error.userSignup.invitationExpired.description": "Invitation code expired, please ask your administrator to resend the invitation",
    "error.userSignup.invitationExpired.title": "Invitation code expired",
    "error.userSignup.invitationNotFound.description": "Please verify that your invitation link is correct or contact your administrator to re-send the invitation.",
    "error.userSignup.invitationNotFound.title": "Invitation not found",
    "error.userStartDateInFuture": "The requested dates are before the users employment start date. Please contact your organizations administrator or Vacation Tracker support for more information.",
    "error.userUpdated": "Error updating user",
    "error.zeroDaysLeaveRequest": "You have selected a holiday or a non-working day. Please select a valid date range.",
    "errors.microsoft.permissions.microsoftPermissionsNotGrantedDescription": "It seems that you cancelled or closed the \"Log in with Microsoft\" page before granting the required permissions. Please click the button below to log in with Microsoft and finish the Vacation Tracker setup.\n\nIf you do not have permission to log in with Microsoft, please contact your organization's Microsoft 365 administrator.",
    "errors.microsoft.subscriptionExpiredDescription": "It seems that your subscription expired. Please get in touch with the administrator of your Vacation Tracker organization for more details.\n\nContact our support if you need to change the administrator or have any other questions.",
    "errors.microsoft.wrongMicrosoftAccountDescription": "It seems that you logged in with a different Microsoft account. You purchased licenses using the following email address:\n\n{email}.\n\nPlease log in with the account you used to purchase the licenses to finish the setup.",
    "errors.slack.wrongSlackAccountDescription": "It seems that you logged in with a different Slack account.",
    "events.logs.AUTOMATION": "Automations",
    "events.logs.BILLING": "Billing",
    "events.logs.BULK_ACTIONS": "Import users",
    "events.logs.COMPANY": "Company",
    "events.logs.LABEL": "Label",
    "events.logs.LEAVE_POLICY": "Leave policy",
    "events.logs.LEAVE_TYPE": "Leave type",
    "events.logs.LOCATION": "Location",
    "events.logs.LOCATION_YEAR_ROLLOVER": "Location year rollover",
    "events.logs.NOTIFICATION": "Notifications",
    "events.logs.REPORT": "Report",
    "events.logs.TEAM": "Department",
    "events.logs.TEAM_POLICY": "Team policy",
    "export.approved": "Approved",
    "export.currentYear": "Current year",
    "export.denied": "Denied",
    "export.downloadAllRows": "Downoad all {total} rows of the report as:",
    "export.expired": "Expired",
    "export.export": "Export",
    "export.exportPeriod": "Export period",
    "export.fileType": "Export format",
    "export.fileTypeCsv": "CSV",
    "export.fileTypeExcelXlsx": "Excel",
    "export.labels": "Labels",
    "export.lastMonth": "Last month",
    "export.leaveBalanceMonthlyReport": "Monthly Leave Balance Report",
    "export.leaveBalanceReport": "Leave Balance Report",
    "export.leaveBalanceReportCustomDaysInfo": "Please note that the report will only display the used days or hours if you select a custom period. You will not be able to export the total or available quota unless you choose one of the predefined options.",
    "export.leaveBalanceReportDescription": "The amount of entitled and taken leaves per Leave Type for each employee for a specific time period.",
    "export.leaveHistoryReport": "Leave Request Report",
    "export.leaveHistoryReportDescription": "History of all leave requests (approved, denied, expired) per Leave Type for each employee for a specific time period.",
    "export.locations": "Locations",
    "export.monthlyLeaveBalanceReport": "Monthly Leave Balance Report",
    "export.monthlyLeaveBalanceReportDescription": "The amount of taken (or scheduled) leaves per Leave Type for each employee for the current and the following 11 months. ",
    "export.nextYear": "Next year",
    "export.previousYear": "Previous year",
    "export.rangeDate": "Start and End Date",
    "export.reportType": "Report type",
    "export.required": "This field is required.",
    "export.status": "Status",
    "export.teams": "Departments",
    "externalConnect.faildLogin": "Your one-time token has expired or has already been used. We'll redirect you to the destination page in a few seconds, but you might need to log in again.\n\nIf you are in a rush, click the button below.",
    "externalConnect.parseError": "Oh no! 😕 Something went wrong.\n\nYou'll be redirected to the login page in 10 seconds.",
    "externalConnect.pleaseWait": "Please wait, Your PC is not a superman!",
    "form.confirmPasswordInfo": "Please confirm your password!",
    "form.department.approverDescription": "Assign approver(s) for this department. Approver(s) will be responsible for managing leave requests submitted by department members. An approver can belong to another department, and a single department can have multiple approvers. Approval flow can be configured as: Single-level, where approval from only one person is needed, or Multi-level (available on the Complete Plan), with sequential approvals. At each level, notifications are sent to all approvers of that level. Approval by any one approver moves the request to the next level, and the process continues until fully approved",
    "form.department.approvers": "Approver(s)",
    "form.department.approversFirstLevel": "First Level Approver(s)",
    "form.department.approversSecondLevel": "Second Level Approver(s)",
    "form.department.approverTitle": "Approval Flow",
    "form.department.apprverFlowType": "Approval Flow Type",
    "form.department.apprverFlowType.multiLevel": "Multi-level Approval",
    "form.department.apprverFlowType.singleLevel": "Single-level Approval",
    "form.department.generalDescriptionCreate": "Set the name of your department and select its users and approvers. If you want to configure the department before adding users, you can create the department first and add users later, but adding at least one approver is required.",
    "form.department.generalDescriptionEdit": "Set the name of your department and select its members. If needed, you can configure the department first and add users later.",
    "form.department.generalTitle": "Department Settings",
    "form.department.setDefaultDescription": "Default department",
    "form.department.setDefaultTitle": "Default department",
    "form.department.subdepartmentDescription": "You can nest departments to create a organization tree like structure. If this is a subdepartment, you can set its parent department in this section.",
    "form.department.subdepartmentTitle": "Subdepartment Settings",
    "form.general.companySettingsDescription": "Set the name of your company and the primary contact email. The email address you provide will be used for important product communication only. No spam, we promise.",
    "form.general.companySettingsTitle": "Company settings",
    "form.general.configurationDescription": "This section allows you to fine-tune specific aspects of leave tracking for your company. Enable user end dates to automatically deactivate employees with an end date, preventing them from submitting leave requests beyond their departure. Choose to track leave quotas in days or hours, according to your company's policies. Additionally, define the start year for leave tracking. By default, leave calculations start from the year you signed up, but you can adjust this to include historical data from other systems or spreadsheets.",
    "form.general.configurationTitle": "Configuration",
    "form.general.migrationDescription": "Are you using Slack, Microsoft Teams, or Google Workspace? You can switch from email and password-based authentication to a platform-specific integration. Switching to one of our integrations would simplify the login and user invitation process (import users from your user directory) and give you additional integrations, such as Microsoft Teams tabs and Microsoft Teams or Slack bots.",
    "form.general.migrationFeatureDescription": "This will start the migration process. You will be redirected to a platform where you can connect your account.",
    "form.general.migrationFeatureTitle": "Start migration to another platform",
    "form.general.migrationTitle": "Switch to another platform",
    "form.inputRequired": "This field is required",
    "form.inputSize": "Field must have {len} digits",
    "form.nameRequired": "The name is required",
    "form.passwordDoNotMatch": "The two passwords that you entered do not match!",
    "form.userNameRequired": "Please enter your name",
    "form.validEmailRequired": "Please enter a valid email address.",
    "general.announceNewUsers": "Announce to new users",
    "general.announceNewUsersInfo": "Send announcement message about Vacation Tracker to new Slack/Teams/Google Workspaces users",
    "general.announceNewUsersInfoTooltip": "When this box is checked, whenever you add a new user to your Slack/Teams/GW organization, they will receive a short message from Vacation Tracker explaining how it works. If this box is not checked, then new users will not receive any announcement message from Vacation Tracker.",
    "general.announceNewUsersTooltip": "Whenever you add a new user to your {platform}, they will receive a short message from Vacation Tracker explaining how it works. If you choose Don't send option, then new users will not receive any announcement message from Vacation Tracker.",
    "general.calculationStartYear": "Vacation Tracker tracks your leaves from",
    "general.calculationStartYearTooltipLine1": "You only need to change this setting if you want to import historical leaves from another system or spreadsheets and include these leaves in the Vacation Tracker's calculations.",
    "general.calculationStartYearTooltipLine2": "Vacation Tracker calculates leaves from the year you signed up. That means we'll automatically calculate the remaining and brought forward days according to your settings, and you'll be able to set brought forward from your previous system for each user on their profile pages. However, if you want Vacation Tracker to calculate leaves for past years, you can change this date. Feel free to <support>contact our support</support> for more information.",
    "general.clickToSelectPlatform": "Click to select platform",
    "general.companyId": "Company ID:",
    "general.companyName": "Company Name",
    "general.confirmModalTitle": "Confirm Company Settings Updates",
    "general.connectSync": "Connect sync",
    "general.connectToAnotherPlatform": "Switch to another platform",
    "general.connectWith": "Switch to {platform}",
    "general.connectWithHelpdeskInfo": "Not sure what platform to choose? This <link>article</link> might help.",
    "general.days": "Days",
    "general.deleteAccount": "If you wish to modify or delete your data, please reach to our support team at <mail>hello@vacationtracker.io</mail>",
    "general.enableUserEndDate": "Enable user end date",
    "general.enableUserEndDateInfo": "When activated, users with an end date won't be able to submit leave requests past that date, and we'll automatically deactivate them.",
    "general.enableUserEndDateTooltip": "When User End Date is activated, Users will not be able to submit leave requests for the time period past their end date. They will be automatically deactivated and unable to log in to Vacation Tracker.",
    "general.googleConsent": "Google login",
    "general.googleConsentInfo": "Google consent",
    "general.googleMorePermission": "Google zeza sa imenima ako hocete da budete sigurni da imate puno ime i prezime treba nam jos permission-a, kliknite ovde da nam date jos permission-a",
    "general.hourlyLeaveAccounting": "Set Leave Quotas in",
    "general.hourlyLeaveAccountingTooltip": "When activated, this option tracks the duration of all leaves and quotas in hours. Available only for the Complete plan.",
    "general.hours": "Hours",
    "general.importUsersAutomatically": "Import users automatically",
    "general.importUsersAutomaticallyInfo": "With import users automatically turned on, we'll automatically import and activate all new regular users from {platform}.",
    "general.importUsersAutomaticallyInfoTooltip": "For example, when you invite a new user to your workspace, that user will be available in Vacation Tracker a few minutes after the invitation. If you want to activate a multichannel guest user, you'll need to invite them manually.",
    "general.importUsersAutomaticallyTurnOffInfo": "We'll no longer automatically import and activate all new regular users from {platform}.",
    "general.importUsersConfigSection": "Import Users Configuration",
    "general.importUsersConfigSectionDescription": "This section allows to enable automatic user import from your user directory and configure the user import settings, such as the announcement message, the default department, and the default location for new users.",
    "general.pleaseEnterCompanyName": "Please enter the company name",
    "general.pleaseEnterContactEmail": "Please enter the company email",
    "general.switchTenant": "Change {platform} tenant",
    "general.syncGoogleUsers": "Sync google users",
    "general.syncGoogleUsersInfo": "Sync google users automatsko brisanje, update i dodavanje usera",
    "general.updateInProgress": "Updating company settings",
    "general.updateSuccessTitle": "Company settings updated",
    "general.usefulLinks": "<healthcheck>Health Check</healthcheck>, <helpdesk>Helpdesk</helpdesk>",
    "general.usefulLinksTitle": "Useful links: ",
    "holidays.filterByType": "Filter by holiday type",
    "holidays.holidaysName": "Holiday name",
    "holidays.holidaysTab": "Holidays for {year}",
    "holidays.holidaysType": "Holiday Type",
    "holidays.importHolidays": "Import Holidays",
    "holidays.importHolidaysAutomaticallyQuestion": "Do you want to import selected holidays for the future years automatically?",
    "holidays.importHolidaysAutomaticallyTooltip": "Vacation Tracker will automatically adjust days for the public holidays that don't fall on the same date every year, such as Thanksgiving, Easter etc.",
    "holidays.importHolidaysAutomaticallyTooltipForMissingHolidays": "Unfortunately, this feature is not currently supported for this country. We are working to expand our services, so please stay tuned for updates.",
    "holidays.importHolidaysAutomaticallyTurnedOff": "Auto import holidays turned off",
    "holidays.importHolidaysAutomaticallyTurnedOn": "Auto import holidays turned on",
    "holidays.importHolidaysCount": "Import {count} selected holidays",
    "holidays.importHolidaysFor": "Import holidays for <b>{year}</b> for <b>{countryState}</b>",
    "holidays.importHolidaysSummary": "You selected the following holidays for <b>{year}</b> for <b>{countryState}</b>",
    "holidays.pastHolidays": "Past holidays",
    "holidays.totalSelectedHolidays": "You selected {total} holiday(s)",
    "holidays.totalSelectedHolidaysTip": "({total} not visible because of the applied filter)",
    "holidays.typeBank": "Bank",
    "holidays.typeObservance": "Observance",
    "holidays.typeOptional": "Optional",
    "holidays.typePublic": "Public",
    "holidays.typeSchool": "School",
    "importLeaves.importFail": "Import failed with the following error message:",
    "importLeaves.importSuccess": "You have submitted data for import. Check in a few minutes to see if the data has been imported successfully.",
    "importUsers.allUsersImported.description": "It seems that you already imported all users from your directory! You should see all users on the Users page. If you don't see someone, take a look at the Inactive tab. Feel free to contact our support if you have any questions or need assistance.",
    "importUsers.allUsersImported.goToUsersPageButton": "Go to Users page",
    "importUsers.allUsersImported.title": "All users already imported!",
    "importUsers.form.backButton": "Back to Users page",
    "importUsers.form.searchGoogleUsersPlaceholder": "Search users in your Google Workspace",
    "importUsers.form.searchMicrosoftUsersPlaceholder": "Type at least one character to start searching users in your Microsoft 365 Active Directory",
    "importUsers.form.searchSlackUsersPlaceholder": "Search users in your Slack Workspace",
    "importUsers.form.searchUsersNotFound": "User not found or already imported",
    "importUsers.form.searchUsersPlaceholder": "Search users in your Microsoft 365 Active Directory",
    "importUsers.form.selectedUsersLabel": "Selected Users ({selectedUsers})",
    "importUsers.form.showSelectedUsersAsNumber": "Selected Users",
    "importUsers.statistics.imported": "Users imported to Vacation Tracker",
    "importUsers.statistics.total": "Total users in your {directoryName}",
    "leaveRequest.halfDay": "Half Day",
    "leaveRequest.hourly": "Hourly",
    "leaveRequest.sent": "Leave request sent",
    "leaveRequest.simpleQuota.addAnotherLeave": "Add another leave",
    "leaveRequest.simpleQuota.info": "Here is your leave balance for {leaveTypeName} for {start} - {end}.",
    "leaveRequest.simpleQuota.requestAnotherLeave": "Request another leave",
    "leaveRequest.simpleQuota.summaryInfo": "We sent the following request for approval to your manager",
    "leaveRequests.addedCurrentYear": "Added for current year",
    "leaveRequests.addeddNextYear": "Added for next year",
    "leaveRequests.cancelThisAction": "Cancel this action",
    "leaveRequests.dates": "Date{plural}",
    "leaveRequests.daysRemainingForCurrentYear": "Days remaining for current year",
    "leaveRequests.daysRemainingForNextYear": "Days remaining for next year",
    "leaveRequests.daysRequestedForCurrentYear": "Days requested for current year",
    "leaveRequests.daysRequestedForNextYear": "Days requested for next year",
    "leaveRequests.denyWithReason": "Deny with reason",
    "leaveRequests.hoursRemainingForCurrentYear": "Hours remaining for current year",
    "leaveRequests.hoursRemainingForNextYear": "Hours remaining for next year",
    "leaveRequests.hoursRequestedForCurrentYear": "Hours requested for current year",
    "leaveRequests.hoursRequestedForNextYear": "Hours requested for next year",
    "leaveRequests.leaveRequestInfoTitle": "<strong>{name}</strong> has submitted the following leave request:",
    "leaveRequests.leaveRequestInfoTitleToil": "<strong>{name}</strong> has submitted the following TOIL request:",
    "leaveRequests.leaveTypeName": "Leave Type",
    "leaveRequests.pending": "Pending request",
    "leaveRequests.reason": "Reason",
    "leaveRequests.reasonForDenying": "Reason for denying",
    "leaveRequests.remainingCurrentYear": "Remaining for current year",
    "leaveRequests.remainingNextYear": "Remaining for next year",
    "leaveRequests.requestedCurrentYear": "Requested for current year",
    "leaveRequests.requestedNextYear": "Requested for next year",
    "leaveRequests.requestSummary": "Request summary",
    "leaveRequests.wrongAction": "This action is unknown to us...🤔 Please try a different one! You'll be redirected to the Dashboard in 7 seconds. ",
    "leaveRequests.wrongLeaveRequestId": "Oops! This leave request is nowhere to be found! 🔎 You'll be redirected to the Dashboard in 7 seconds.",
    "leaveRequestStatus.approved": "approved",
    "leaveRequestStatus.APPROVED": "approved",
    "leaveRequestStatus.cancelled": "cancelled",
    "leaveRequestStatus.CANCELLED": "cancelled",
    "leaveRequestStatus.deleted": "deleted",
    "leaveRequestStatus.DELETED": "deleted",
    "leaveRequestStatus.denied": "denied",
    "leaveRequestStatus.DENIED": "denied",
    "leaveRequestStatus.open": "open",
    "leaveRequestStatus.OPEN": "open",
    "leaveTypes.activatingLeaveType": "Activate Leave Type",
    "leaveTypes.activatingLeaveTypeInfo": "By activating this Leave Type, you are restoring it's previous placement in Locations, as well as it's past settings and data.",
    "leaveTypes.activatingLeaveTypeTitle": "Activate Leave Type",
    "leaveTypes.addLeaveTypeInfo": "Create a custom Leave Type by adding a name, color for easier navigation, and status (Active/Inactive). Don't worry, you can edit all Leave Type settings later on. \n\nTo assign a custom Leave Policy to a Leave Type, visit Locations -> Assign Leave Policy.",
    "leaveTypes.addTypeToLocationButton": "Add",
    "leaveTypes.assignedToLocationsColumn": "Assigned to Locations",
    "leaveTypes.assignedToLocationsColumnWarning": "This leave type is not assigned to any location.",
    "leaveTypes.color": "Color",
    "leaveTypes.createInProgress": "Creating the {leaveTypeName} leave type",
    "leaveTypes.createNewLeaveType": "Create a Leave Type",
    "leaveTypes.deactivateLeaveType": "Deactivate Leave Type",
    "leaveTypes.deactivateLeaveTypeInfo": "By deactivating this Leave Type, you are removing it from all Locations which are currently using it. When you activate the Leave Type again, it will appear in the same Locations with the existing settings and data.",
    "leaveTypes.deactivateLeaveTypeTitle": "Deactivate Leave Type",
    "leaveTypes.deleteInProgress": "Deleting the {leaveTypeName} leave type",
    "leaveTypes.deleteLeaveType": "Delete Leave Type",
    "leaveTypes.deleteLeaveTypeConfirm": "Are you sure want to delete {name} leave type?",
    "leaveTypes.deleteLeaveTypeTitle": "Delete Leave Type",
    "leaveTypes.editLeaveType": "Edit Leave Type",
    "leaveTypes.isActive": "Active",
    "leaveTypes.sort": "Sort",
    "leaveTypes.typeName": "Name",
    "leaveTypes.updateInProgress": "Updating the {leaveTypeName} leave type",
    "location.createInProgress": "Creating the {locationName} location",
    "location.deleteInProgress": "Deleting the {locationName} location",
    "location.editLocation": "Edit location",
    "location.fiscalYearStart": "Fiscal Year Start",
    "location.general": "General",
    "location.hasNoUsers": "Location has no users.",
    "location.holidays": "Holidays",
    "location.holidays.autoimportTitle": "Holidays autoimport settings changed",
    "location.holidays.brokenHolidays": "This holiday cannot be added, please contact support to resolve the issue",
    "location.holidays.successTitle": "Holidays are successfully updated",
    "location.holidays.updateInProgress": "Updating holidays",
    "location.holidaysUpdated": "Holidays updated",
    "location.leavePolicies": "Leave Policies",
    "location.leavePoliciesNotExistInfo": "There are currently no available Leave Policies. Please assign a Leave Policy to the Location.",
    "location.name": "Name",
    "location.never": "Never",
    "location.resetQuotas": "Leave Quota Reset Based On",
    "location.resetQuotasInfo": "This setting will determine if your yearly leave balance will reset based on the accounting year (company's fiscal year) or based on the employee's start date. Besides quotas, your roll-over policy will also be affected according to this setting.",
    "location.rolloverExpiryAfterDays": "Brought Forward Days Expiry",
    "location.rolloverExpiryAfterHours": "Brought Forward Hours Expiry",
    "location.rolloverExpiryDay": "Should Brought Forward Days Expire?",
    "location.rolloverExpiryHour": "Should Brought Forward Hours Expire?",
    "location.rolloverExpiryOnDate": "Brought Forward Days Expire On",
    "location.rolloverExpiryOnDate.extra": "Days will expire at the end of the day.",
    "location.rolloverExpiryOnDateHours": "Brought Forward Hours Expire On",
    "location.rolloverExpiryOnDateHours.extra": "Hours will expire at the end of the day.",
    "location.startYear": "Fiscal Year Start",
    "location.startYearInfo": "An organization's Fiscal Year is a year as reckoned for taxing or accounting purposes. Vacation period reset dates depend on the organization's fiscal year start and end.",
    "location.timezone": "Time zone",
    "location.timezoneDefault": "(GMT+00:00) GMT (no daylight saving)",
    "location.updateInProgress": "Updating the {locationName} location",
    "location.users": "User(s)",
    "location.workWeek": "Workweek",
    "locationForm.assignLeaveTypesInfo": "Once you've created a Location, assign a Leave Policy to the Location, in order to enable Users to request Leave. \nTo assign a Leave Policy, go to Location -> Leave Policies -> Assign Leave Policy.",
    "locationForm.createdSuccessfully": "Please give us a moment, we're creating your Location.",
    "locationForm.createLocation": "Create Location",
    "locationForm.editLocation": "Edit Location",
    "locationForm.locationLimitReached": "Oops! It looks like you've hit the limit of {limitNumber} locations for your current plan. To manage or upgrade your plan, please visit the <billingPage>Billing page</billingPage>.",
    "locationForm.updateLocation": "Update Location",
    "locations.automaticallyGeneratedName": "We automatically generated this location for you by grouping the following teams: <t>{team}</t>.",
    "locations.automaticallyTransferredLeavePoliciesAndHolidays": "Leave policies and holidays are automatically transferred for you, so you do <b>{not}</b> need to recreate them.",
    "locations.created": "Created",
    "locations.createNewLocation": "Create a Location",
    "locations.default": "Default",
    "locations.defaultLocation": "Default Location",
    "locations.defaultLocationDescription": "All new users will automatically be added to this location.",
    "locations.deleteLocation": "Delete Location",
    "locations.deleteLocation.disabled": "You can't delete the default location",
    "locations.deleteLocationConfirm": "Please confirm you want to delete the {name} location.",
    "locations.deleteLocationConfirmInitialRollover": "Deleting a location might impact the users' configuration and leave information (e.g. the initial brought forward days).",
    "locations.deleteLocationTitle": "Delete {name} Location",
    "locations.editLocation": "Edit",
    "locations.hasNoUsers": "None",
    "locations.holidays": "Holidays",
    "locations.leavePolicies": "Leave Policies",
    "locations.leavePolicies.quotaDays": "{value} days",
    "locations.leavePolicies.quotaHours": "{value} hours",
    "locations.leavePolicies.yearlyQuota": "Yearly Leave Quota",
    "locations.locationLimitReached": "You are currently using all {limitNumber} of your location slots available in the CORE plan. To manage or upgrade your plan, please visit the <billingPage>Billing page</billingPage>.",
    "locations.name": "Name",
    "locations.pleaseRenameLocation": "Please rename this location!",
    "locations.pleaseRenameLocationName": "Please rename automatically generated location names *",
    "locations.resetQuotas": "Leave Quota Reset Based On",
    "locations.selectLocation": "Select location",
    "locations.unableToNameThisLocation": "However, our system was unable to name this location properly.",
    "locations.users": "User(s)",
    "locations.viewHolidays": "View Holidays",
    "locations.viewLeavePolicies": "View Leave Policies",
    "locations.viewLocation": "View",
    "locations.weAutomaticallyCreatedLocations": "We automatically created locations for your organization by grouping your teams by holidays. All leave policies are transferred, you just need to rename your locations.",
    "manageMicrosoftLicenses.assignLicencesSuccessTitle": "The licenses have been assigned successfully.",
    "manageMicrosoftLicenses.weAreAssignLicenses": "We're assigning the licenses.",
    "microsoft.adminConsentErrorButton": "Get access",
    "microsoft.adminConsentErrorDescription1": "It seems that you are not an administrator of your Microsoft Teams organization. Setting up the notifications require administrator consent. Please send the following link to the administrator of your organization to get the administrator consent: ",
    "microsoft.adminConsentErrorDescription2": "When you get an administrator consent, click the button below to get an access to notifications. If you are an administrator, visit the same link, give the administrator consent, and you'll be able to set up the notifications.",
    "microsoft.adminConsentErrorTitle": "Microsoft Teams Administrator Consent Required.",
    "microsoft.createCompanyTeamLoginAdminConsentError1": "It seems that you are not an administrator of your Microsoft Teams organization. Importing team members from a single Microsoft Teams team require administrator consent. Please send the following link to the administrator of your organization to get the administrator consent: ",
    "microsoft.createCompanyTeamLoginAdminConsentError2": "When you get administrator consent, you may continue with the sign-up process. If you are an administrator, please visit the same link, give the administrator consent, and you will be able to continue with the sign-up.",
    "microsoft.createCompanyTeamLoginRequiredDescription1": "We need additional Microsoft Teams' permission to list users from the team you selected. If you click the OK button, we'll redirect you to the Microsoft login page. Depending on your browser settings, you might be already logged in and automatically redirected back.",
    "microsoft.createCompanyTeamLoginRequiredDescription2": "After logging in to your Microsoft account, we will take you back to this page so that you can select users to import. Clicking the Cancel button will still allow you to sign up with your whole organization.",
    "microsoft.createCompanyTeamLoginRequiredTitle": "Microsoft Teams Additional Permission Required.",
    "microsoft.manageLicenses.loginMicrosoft": "Log in with your Microsoft 365 account to assign licenses.",
    "microsoft.manageLicenses.loginMicrosoftInfo": "Logging in with your account will list users in your Microsoft Active Directory. You'll be able to assign purchased licenses and, optionally, notify imported users.",
    "microsoft.notificationsLoginRequiredDescription1": "Managing notifications require a Microsoft Teams' token. If you click the OK button, we'll redirect you to the Microsoft login page. Depending on your browser settings, you might be already logged in and automatically redirected back.",
    "microsoft.notificationsLoginRequiredDescription2": "After logging in to your Microsoft account, we will take you back to this page so that you can manage the notifications. Clicking the Cancel button will take you back to the last page you visited on our dashboard.",
    "microsoft.notificationsLoginRequiredTitle": "Login to your Microsoft account is required",
    "microsoft.universalLoginRequiredDescription": "It seems that your token expired. Please login to your Microsoft Teams account to proceed.",
    "microsoft.universalLoginRequiredTitle": "Token expired",
    "microsoft.usersLoginRequiredDescription1": "Managing users require a Microsoft Teams' token. If you click the OK button, we'll redirect you to the Microsoft login page. Depending on your browser settings, you might be already logged in and automatically redirected back.",
    "microsoft.usersLoginRequiredDescription2": "After logging in to your Microsoft account, we will take you back to this page so that you can manage the users.",
    "microsoft.usersLoginRequiredTitle": "Login to your Microsoft account is required",
    "microsoftSass.createCompany.alertError.companyAlreadyExistsDescription": "Your company is already signed up, but you are not active. Are you using the correct email?\nYour email address: {email}\nIf this email is correct, your account is not activated. Please contact the administrator of your Vacation Tracker organization:\n{admins}\nOr contact our support if you want to sign up as a separate independent team in the same tenant.\nIf your email is not the correct one, please log in with a different Microsoft account or log in from an incognito window in your browser.",
    "microsoftSass.createCompany.alertError.errorTitle": "Error",
    "microsoftSass.createCompany.alertError.goToAdminCenter": "Go to Admin Center",
    "microsoftSass.createCompany.alertError.goToConnectPage": "Go to Connect page",
    "microsoftSass.createCompany.alertError.logInWithPlatform": "Log in with {platform}",
    "microsoftSass.createCompany.alertError.resubscribedDescription": "You were successfully resubscribed as company - {companyName}.\n\nPlease click the button to be reditected to sign-in page.",
    "microsoftSass.createCompany.alertError.resubscribedTitle": "You have resubscribed successfully",
    "microsoftSass.createCompany.alertError.tokenExpiredDescription": "Your purchase token expired. Please go to the Microsoft Teams Admin Center and click the \"Setup account\" button to continue.",
    "microsoftSass.createCompany.alertError.tokenExpiredTitle": "Purchase token expired",
    "microsoftSass.createCompany.alertError.tokenInvalidDescription": "Your Microsoft purchase token is invalid. If you purchased the Vacation Tracker plan through Microsoft Teams App Store, please go to the Microsoft Teams Admin Center and click the \"Setup account\" button to continue. Otherwise, go to our connect in page to log in or create an account.",
    "microsoftSass.createCompany.alertError.tokenInvalidTitle": "Purchase token invalid",
    "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountDescription": "You already have an account. Your company ({companyName}) signed up using {platform}. Click the button below to log in using your {platform} account.\n\nDon't worry. We cancelled your purchase, and you will not be charged for the licenses you just bought. The old subscription for your company is still active. You can visit the Microsoft Admin Center to verify that your new subscription is cancelled.\n\nIf you want to buy another subscription intentionally, please contact our support.",
    "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountTitle": "You already have an account",
    "microsoftSass.createCompany.assignLicenses.assignPurchasedLicenses": "ASSIGN PURCHASED LICENSES",
    "microsoftSass.createCompany.assignLicenses.assignPurchasedLicensesInfo": "Let's assign the licenses you purchased! You have {totalLicenses} licenses. Assign them to users that will be able to use Vacation Tracker.",
    "microsoftSass.createCompany.assignLicenses.buyMoreLicensesLater": "You can purchase more licenses later in the Users page of the Vacation Tracker dashboard.",
    "microsoftSass.createCompany.assignLicenses.loginMicrosoftDontWorry": "Don't worry! We'll not import or notify anyone without your approval.",
    "microsoftSass.createCompany.completed": "Completed",
    "microsoftSass.createCompany.createCompanySetupInfo": "Let's set the contact email and the default settings for your Vacation Tracker organization.",
    "microsoftSass.createCompany.createCompanyTitle": "COMPLETE COMPANY SETUP",
    "microsoftSass.createCompany.finishFristStep": "Please complete the company setup to proceed.",
    "microsoftSass.createCompany.finishSecondStep": "Please assign licenses to proceed.",
    "microsoftSass.createCompany.finishSetup": "Finish Set up",
    "microsoftSass.createCompany.help": "If you need assistance, visit our <helpdesk>Helpdesk</helpdesk> or <support>contact our support</support>.",
    "microsoftSass.createCompany.importAllUsers": "Assigned to all users",
    "microsoftSass.createCompany.letsCompleteSetup": "Let's complete the setup, assign licenses to your users, and install the Vacation Tracker integration for Microsoft Teams.",
    "microsoftSass.createCompany.numberOfLicensesAssigned": "{assignedLicenses} licenses assigned",
    "microsoftSass.createCompany.setupMsBot.areYouAdmin": "Are you the admin of your Microsoft Teams integration",
    "microsoftSass.createCompany.setupMsBot.errorExtedScopeLogin": "It seems that you didn't grant the additional two Microsoft Teams permissions that we need to set up the bot and channel tabs. Without this step, we'll not be able to send a welcome message to your selected team members, and you'll not be able to use the Vacation Tracker app from your Microsoft Teams application.",
    "microsoftSass.createCompany.setupMsBot.errorExtedScopeLoginSkip": "You can skip this step and install the bot and tabs manually. See our Helpdesk for the step-by-step guide.",
    "microsoftSass.createCompany.setupMsBot.installBotAndTabs": "Install bot and tabs",
    "microsoftSass.createCompany.setupMsBot.installManuallyBotAdnTabs": "I will install bot and tabs manually",
    "microsoftSass.createCompany.setupMsBot.microsoftTeamsLogin": "Microsoft Teams",
    "microsoftSass.createCompany.setupMsBot.morePermissionInfo": "We need additional permission to install the Vacation Tracker chatbot and tabs in your Microsoft Teams. We'll redirect you to the Microsoft permissions screen when you click the Proceed button below.",
    "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyDescription": "Please install the Vacation Tracker Microsoft Teams bot and tabs manually by following the steps described in <helpdesk>this Helpdesk article</helpdesk>. You should not need any admin privileges for the manual setup. You should not need any admin assistance for the manual set up. If you need assistance, contact <support>our support</support>.",
    "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyTitle": "You need to install the Microsoft integration manually",
    "microsoftSass.createCompany.setupMsBot.notAdmin.weNeedAdminConsent": "We need admin consent to set up the bot and tabs. We can't send welcome messages until you install the bot.",
    "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabs": "Select Microsoft Teams channel where you want to install tabs",
    "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabsPlaceholder": "Select Microsoft Teams channel",
    "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBot": "Select Microsoft Teams team where you want to install the bot",
    "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBotPlaceholder": "Select Microsoft Teams team",
    "microsoftSass.createCompany.setupMsBotAndTabs": "SET UP VACATION TRACKER BOT AND TABS",
    "microsoftSass.createCompany.setupMsBotAndTabsInfo": "Let's install the Vacation Tracker bot and tabs for Microsoft Teams!",
    "microsoftSass.createCompany.stepAssignLicenses": "2. Assign licenses",
    "microsoftSass.createCompany.stepCreateComapny": "1. Complete the company setup",
    "microsoftSass.createCompany.stepSetupBotAndTabs": "3. Set up the Microsoft Teams bot and tabs",
    "microsoftSass.createCompany.welcomeMessage": "Welcome to Vacation Tracker! Thank you for purchasing <bold>{totalLicenses} licenses</bold>.",
    "microsoftSass.createCompany.welcomeTitle": "LET'S SET UP VACATION TRACKER",
    "microsoftSubscription.adminInitialContent": "Your subscription expired. Please go to the Microsoft AppSource store to reactivate the subscription. If you have any questions or you need help, contact our support.",
    "microsoftSubscription.goToAppSource": "Purchase a new subscription",
    "modal.dontHaveAnAccount.accountNotFound": "Account not found",
    "modal.dontHaveAnAccount.asCompany": "As a company",
    "modal.dontHaveAnAccount.asCompanyDescription": "You are an administrator or business owner who wants to try Vacation Tracker.",
    "modal.dontHaveAnAccount.asEmployee": "As an employee",
    "modal.dontHaveAnAccount.asEmployeeDescription": "Your company uses Vacation Tracker, but you do not have access to it.",
    "modal.dontHaveAnAccount.employeeSignUp": "Employee sign up",
    "modal.dontHaveAnAccount.employeeSignupDescription": "If your company is already using Vacation Tracker, you do not need to sign up! Contact your administrator to activate you, and you'll have full access to the Vacation Tracker dashboard and integrations.",
    "modal.dontHaveAnAccount.employeeSignupSupport": "If you need assistance or you are not sure who your administrator is, feel free to contact our support.",
    "modal.dontHaveAnAccount.introParagraph": "It seems that you do not have an account. How do you want to use Vacation Tracker?",
    "modal.forgotPassword.step2Intro": "We sent a code to your email. Please enter it below and set a new password. If you haven't received an email in 5 minutes, check your spam and check if you entered the correct email address.",
    "myProfile.cancel": "Cancel",
    "myProfile.cancelLeave": "Cancel",
    "myProfile.cancelLeaveConfirmTitle": "Cancel Leave",
    "myProfile.dontHaveAnyHistory": "{name} hasn't taken any leave yet.",
    "myProfile.edit.title": "Edit My Profile",
    "myProfile.editLeave": "Edit Leave",
    "myProfile.editLeaveWord": "Edit",
    "myProfile.general": "General",
    "myProfile.history": "History",
    "myProfile.historyLeaves": "History",
    "myProfile.leaveHistory": "Leave History",
    "myProfile.leaveTypes": "Leave Types",
    "myProfile.noUpcomingLeaves": "No Scheduled Leaves",
    "myProfile.openMyProfile": "View My Profile",
    "myProfile.upcomingLeaves": "Scheduled Leaves",
    "myProfile.userWorkWeek": "Workweek",
    "not_in_channel": "The selected channel has been deleted or is no longer available. Please select another channel to receive Notifications on.",
    "notification.codeResent": "Verification code resent",
    "notification.codeResentMessage": "Please check your email",
    "notification.passwordReset": "Password successfully reset",
    "notification.passwordResetMessage": "You can now log in using your new password.",
    "notifications.add": "New Notification",
    "notifications.cantOpenNoptification": "This Notification was created by another user and you cannot edit it.",
    "notifications.createDailyRequestSubmitted": "Setting daily notifications",
    "notifications.createWeeklyRequestSubmitted": "Setting weekly notifications",
    "notifications.deleteAutomationConfirm": "Please confirm you want to delete <strong>{name}</strong> automation.",
    "notifications.deleteAutomationTitle": "Delete automation",
    "notifications.deleteDailyRequestSubmitted": "Deleting daily notifications",
    "notifications.deleteEntitlementByRoleAutomationConfirm": "Are you sure you want to delete this Entitlement by role automation? You can't undo this action. However, deleting the automation will not affect already awarded {leaveTypeName} days.",
    "notifications.deleteNotificationConfirm": "Please confirm you want to delete <strong>{name}</strong> notification.",
    "notifications.deleteNotificationOk": "Delete",
    "notifications.deleteNotificationTitle": "Delete notification",
    "notifications.deleteSeniorityEntitlementAutomationConfirm": "Are you sure you want to delete this Seniority entitlement automation? You can't undo this action. However, deleting the automation will not affect already awarded {leaveTypeName} days.",
    "notifications.deleteWeeklyRequestSubmitted": "Deleting weekly notifications",
    "notifications.emptyViewButton": "Create a daily or weekly notification",
    "notifications.emptyViewMessage": "by sending automated notifications about upcoming leaves",
    "notifications.emptyViewTitle": "Increase visibility and keep your team informed",
    "notifications.emptyViewVideoLink": "For more info, visit our <link>Helpdesk</link> or see the video below:",
    "notifications.error": "If this problem persists, please contact support and give them the following error code: {correlationId}.",
    "notifications.frequency": "Frequency",
    "notifications.handleSubmitErrorTitle": "Notification Error",
    "notifications.microsoftTokenMissing": "We're unable to set notifications because you are missing the Microsoft token. Please log out, and log in again to receive the Microsoft token, and try again.",
    "notifications.notificationInfo": "If you would like to set up Notifications for a specific department, please go to the Departments page and edit the department you want to set the Notifications for.",
    "notifications.notificationSendNow": "Your notification is on the way! 🚀",
    "notifications.notificationSendNowFailed": "Failed Delivery",
    "notifications.notificationSendNowFailedInfo": "Oh no! 😕 Something went wrong. ",
    "notifications.notificationTitle": "Notifications",
    "notifications.passwordChangedDescription": "You successfully changed your password, please login",
    "notifications.passwordChangedTitle": "Password changed",
    "notifications.sendNotificationNowCofirmModalDesc": "Notification <strong>{name}</strong> is not active. Please confirm you want to send it now anyway.",
    "notifications.sendNotificationNowCofirmModalTitle": "Send notification",
    "notifications.sendNotificationNowOk": "Send",
    "notifications.table.actions": "Actions",
    "notifications.table.active": "Active",
    "notifications.table.DAILY": "Daily",
    "notifications.table.frequency": "Frequency",
    "notifications.table.name": "Name",
    "notifications.table.owner": "Owner",
    "notifications.table.scheduledTime": "Scheduled Time",
    "notifications.table.sendNotificationNow": "Send Now",
    "notifications.table.WEEKLY": "Weekly",
    "notifications.title": "Notifications",
    "notifications.tooltipInfo": "All of your Notifications come together on this page for easier management. To learn more, please consult our <helpDesk>Helpdesk article.</helpDesk>",
    "notifications.update": "Update Notification",
    "notifications.updateAutomationConfirm": "Disabling an automation will not cancel the subscription. If you want to turn off the subscription, you need to access the list of automations by clicking on \"Create Automation\" and then click on the \"Unsubscribe\" button",
    "notifications.updateAutomationTitle": "Update automation",
    "notifications.updateNotification": "Update",
    "notifications.weekStartsOn": "Week Starts on",
    "notificationsForm.advancedFilter.tooltipInfo": "If no Filters are selected, the Notification will include all Users.",
    "notificationsForm.createInProgress": "Creating the {notificationName} notification",
    "notificationsForm.currentWeek": "Current week",
    "notificationsForm.DAILY": "Daily",
    "notificationsForm.deleteFailed": "Delete faild",
    "notificationsForm.deleteInProgress": "Deleting the {notificationName} notification",
    "notificationsForm.frequency": "Frequency",
    "notificationsForm.groupBy": "Group leaves by",
    "notificationsForm.groupBy.tooltip": "Leaves can be displayed according to your preferences. They can be sorted by the Users' Location, Department or Alphabetically (None).",
    "notificationsForm.locale": "Language",
    "notificationsForm.LOCATION": "Location",
    "notificationsForm.name": "Notification Name",
    "notificationsForm.newNotificationTitle": "New Notification",
    "notificationsForm.nextWeek": "Next week",
    "notificationsForm.NONE": "None",
    "notificationsForm.periodCovered": "Covered Time Period",
    "notificationsForm.saveFailed": "Save failed",
    "notificationsForm.selectChannel": "Select Channel",
    "notificationsForm.sendEmpty": "If No Upcoming Leaves",
    "notificationsForm.sendEmptyNo": "No, don't send the notification",
    "notificationsForm.sendEmptyYes": "Yes, send the notification",
    "notificationsForm.sendOnDays": "Send on",
    "notificationsForm.sendOnDaysInfo": "Notification will be sent out on the day(s) selected.",
    "notificationsForm.submit": "Save Notification",
    "notificationsForm.TEAM": "Department",
    "notificationsForm.time": "Time",
    "notificationsForm.timezone": "Time zone",
    "notificationsForm.update": "Update Notification",
    "notificationsForm.updateInProgress": "Updating the {notificationName} notification",
    "notificationsForm.updateNotificationTitle": "Update Notification",
    "notificationsForm.WEEKLY": "Weekly",
    "notificationsForm.weekStartsOn": "Week Starts on",
    "overlapInfo.moreUsersAreOnLeaves": "ℹ️ Seems like {overlapperNames} will be on leave during the same time period.",
    "overlapInfo.youCanSafelyAcceptTheRequest": "You can safely accept the request, no one else from {teamName} is on leave during this time period.",
    "overlapInfo.youCanSafelyAcceptTheRequestAutoApproved": "No one else from {teamName} is on leave during this time period.",
    "page404.message": "The page you are looking for is taking some well-deserved time off.",
    "page404.title": "Page on vacation.",
    "quickTour.admin.buttonTitle": "Quick tour",
    "quickTour.buttons.back": "Back",
    "quickTour.buttons.close": "Close",
    "quickTour.buttons.last": "Close",
    "quickTour.buttons.next": "Next",
    "quickTour.buttons.skip": "Skip",
    "registration.flowB.progress": "Step {current} of {total}",
    "registration.flowB.step1.list1": "<b>Free 7 day trial</b>, all features included",
    "registration.flowB.step1.list2": "No credit card required during trial",
    "registration.flowB.step1.list3": "Integrate with your collaboration tool",
    "registration.flowB.step1.submitButton": "Get Started",
    "registration.flowB.step1.subtitle": "What’s your company email address?",
    "registration.flowB.step1.title": "Join the thousands of teams worldwide who streamline PTO tracking with Vacation Tracker.",
    "registration.flowB.step2.submitButton": "Create Account",
    "registration.flowB.step2.subtitle": "Welcome to Vacation Tracker",
    "registration.flowB.step3.continueWithEmailPlatform": "Continue with work e-mail",
    "registration.flowB.step3.continueWithPlatform": "Continue with {platform}",
    "registration.flowB.step3.list1": "Manage Leave Requests",
    "registration.flowB.step3.list10": "and more! ",
    "registration.flowB.step3.list2": "Send Leave Notifications",
    "registration.flowB.step3.list3": "Customize Leave Types",
    "registration.flowB.step3.list4": "Create Locations & Departments",
    "registration.flowB.step3.list5": "Generate Reports",
    "registration.flowB.step3.list6": "Set Leave Accruals",
    "registration.flowB.step3.list7": "Group Team Members by Labels",
    "registration.flowB.step3.list8": "Schedule Reports",
    "registration.flowB.step3.list9": "Request Hourly Leave",
    "registration.flowB.step3.notice": "We won't communicate with your team in any way until you're ready.",
    "registration.flowB.step3.submitButton": "ONE LAST STEP",
    "registration.flowB.step3.subtitle": "Which tool would you like to integrate with, {name}?",
    "registration.flowB.step3.title": "7 day complete plan trial",
    "registration.flowB.step3.title2": "You will receive a <b>free trial</b> of our <b>Complete plan</b>, which includes:",
    "registration.flowB.testimonial1.profession": "Producer",
    "registration.flowB.testimonial1.title": "\"The ease with which everyone transitioned across to using Vacation Tracker without asking a million questions was impressive!\"",
    "registration.flowB.testimonial2.profession": "CEO",
    "registration.flowB.testimonial2.title": "\"Very simple to setup, makes it super simple to request and approve days off and the team is very responsive and takes the feedback seriously.\"",
    "registration.flowB.testimonial3.profession": "Director of Administrative Services",
    "registration.flowB.testimonial3.title": "\"I can now focus on my staff and numerous other things - Vacation Tracker gave me back at least 10 hours a week plus numerous hours back to our managers & employees because it's all electronic.\"",
    "requestLeave.handleSubmitErrorTitle": "Leave Submit Error",
    "requestLeave.inProgress": "Requesting leave",
    "requestLeave.requestLeave": "Request Leave",
    "requestLeave.title": "Leave requested",
    "scheduledReportForm.addNewEmail": "Add Recipient",
    "scheduledReportForm.approved": "Approved",
    "scheduledReportForm.createAScheduledReport": "New Report",
    "scheduledReportForm.createInProgress": "Creating the {scheduledReportName} scheduled report",
    "scheduledReportForm.deleteFailed": "Delete faild",
    "scheduledReportForm.deleteInProgress": "Deleting the {scheduledReportName} scheduled report",
    "scheduledReportForm.deleteReport": "Delete Report",
    "scheduledReportForm.deleteScheduledReport": "Delete scheduled report",
    "scheduledReportForm.deleteScheduledReportConfirm": "Please confirm you want to delete the {name} scheduled report.",
    "scheduledReportForm.deleteScheduledReportTitle": "Delete {name} scheduled report",
    "scheduledReportForm.denied": "Denied",
    "scheduledReportForm.emails": "Recipients",
    "scheduledReportForm.lastMonth": "Last month",
    "scheduledReportForm.leaveBalanceReport": "Leave Balance Report",
    "scheduledReportForm.leaveHistoryReport": "Leave Request Report",
    "scheduledReportForm.leaveStatus": "Leave Status",
    "scheduledReportForm.locations": "Location(s)",
    "scheduledReportForm.monthlyLeaveBalanceReport": "Monthly Leave Balance Report",
    "scheduledReportForm.period": "Reporting Period",
    "scheduledReportForm.periodInfo": "Last Month ",
    "scheduledReportForm.periodInfoTootltip": "The report is currently limited to the previous month. For more options, please email hello@vacationtracker.io and we’ll make sure to note down your request for possible future improvements.",
    "scheduledReportForm.pleaseInputEmailOrDeleteThisField": "Please input valid email or delete this field.",
    "scheduledReportForm.pleaseInputValidEmail": "Please input valid email.",
    "scheduledReportForm.reportName": "Name",
    "scheduledReportForm.reportSummary": "Report Summary",
    "scheduledReportForm.reportSummaryInfo": "Reporting Period: {from} - {to}\nReport Type: {reportType}\nNext report will be sent on {dateReport} at {timeReport}",
    "scheduledReportForm.reportSummaryInfoMonthlyBalance": "Reporting Period: Next 12 months. \nReport Type: {reportType}\nNext report will be sent on {dateReport} at {timeReport}",
    "scheduledReportForm.reportType": "Report Type",
    "scheduledReportForm.required": "This field is required.",
    "scheduledReportForm.saveFailed": "Save failed",
    "scheduledReportForm.saveReport": "Schedule Report",
    "scheduledReportForm.sendDayOfMonth": "Delivery Date/Day",
    "scheduledReportForm.sendDayOfMonthInfo": "Configure your report delivery preferences, by specifying the exact day when the report will be sent to the assigned recipients.",
    "scheduledReportForm.sendHourAndMinute": "Delivery Time",
    "scheduledReportForm.sendHourAndMinuteInfo": "Configure your report delivery preferences, specifying the exact time of the day when the report will be sent to the assigned recipients.",
    "scheduledReportForm.sendReportFailed": "Failed Delivery",
    "scheduledReportForm.sendReportFailedInfo": "Oh no! 😕 Something went wrong. ",
    "scheduledReportForm.sendReportNow": "Send Now",
    "scheduledReportForm.sendReportSuccessfully": "Your report is on the way! 🚀",
    "scheduledReportForm.teams": "Department(s)",
    "scheduledReportForm.timezone": "Time Zone",
    "scheduledReportForm.updateAScheduledReport": "Update Report",
    "scheduledReportForm.updateInProgress": "Updating the {scheduledReportName} scheduled report",
    "scheduledReportForm.updateReport": "Update Report",
    "scheduledReports.add": "New Report",
    "scheduledReports.allLocations": "All Locations",
    "scheduledReports.allTeams": "All Departments",
    "scheduledReports.createScheduledReports": "New Report",
    "scheduledReports.deleteFailed": "Delete faild",
    "scheduledReports.deleteInProgress": "Deleting the {scheduledReportName} scheduled report",
    "scheduledReports.deleteReportConfirm": "Delete the report {name}?",
    "scheduledReports.deleteReportOkText": "Delete",
    "scheduledReports.deleteReportTitle": "Confirm Deletion",
    "scheduledReports.deliveryDate": "Delivery Date",
    "scheduledReports.edit": "Edit",
    "scheduledReports.lastMonth": "Last month",
    "scheduledReports.leaveBalanceReport": "Leave Balance Report",
    "scheduledReports.leaveHistoryReport": "Leave Request Report",
    "scheduledReports.locations": "Location(s)",
    "scheduledReports.monthlyLeaveBalanceReport": "Monthly Leave Balance Report",
    "scheduledReports.name": "Name",
    "scheduledReports.period": "Reporting Period",
    "scheduledReports.receivers": "Recipients",
    "scheduledReports.reportSentSuccessfully": "Your report is on the way! 🚀",
    "scheduledReports.reportType": "Report Type",
    "scheduledReports.sendReportFailed": "Failed Delivery",
    "scheduledReports.sendReportFailedInfo": "Oh no! 😕 Something went wrong. ",
    "scheduledReports.sendReportNow": "Send Now",
    "scheduledReports.sendReportSuccessfully": "Your report is on the way! 🚀",
    "scheduledReports.teams": "Department(s)",
    "sidebar.addLeave": "Add Leave",
    "sidebar.addons": "Add-ons",
    "sidebar.billing": "Billing",
    "sidebar.calendar": "Calendar",
    "sidebar.changelog": "Changelog",
    "sidebar.dashboard": "Dashboard",
    "sidebar.export": "Export",
    "sidebar.faq": "Helpdesk",
    "sidebar.general": "General",
    "sidebar.holidays": "Holidays",
    "sidebar.imports": "Imports",
    "sidebar.leaves": "Leave Requests",
    "sidebar.leaveTypes": "Leave Types",
    "sidebar.location": "Location",
    "sidebar.locations": "Locations",
    "sidebar.main": "Main",
    "sidebar.myProfile": "My Profile",
    "sidebar.notifications": "Notifications",
    "sidebar.onboarding": "Onboarding",
    "sidebar.reports": "Reports",
    "sidebar.requestLeave": "Request Leave",
    "sidebar.scheduledReports": "Scheduled Reports",
    "sidebar.settings": "Settings",
    "sidebar.teams": "Departments",
    "sidebar.user": "User",
    "sidebar.users": "Users",
    "signInAsUser.codeIsRequired": "A code is required",
    "signInAsUser.email": "Enter your email address",
    "signInAsUser.emailIsRequired": "A valid email address is required",
    "signInAsUser.enterTheCode": "Enter the code",
    "signInAsUser.userId": "Enter user ID",
    "signInAsUser.userIdIsRequired": "An user ID is required",
    "slack.notificationsLoginRequiredDescription1": "Managing notifications require Slack scopes. If you click the OK button, we'll redirect you to the Slack login page. Depending on your browser settings, you might be already logged in and automatically redirected back.",
    "slack.notificationsLoginRequiredDescription2": "After logging in to your Slack account, we will take you back to this page so that you can manage the notifications. Clicking the Cancel button will take you back to the last page you visited on our dashboard.",
    "slack.notificationsLoginRequiredTitle": "Login to your Slack account is required",
    "slack.usersLoginRequiredDescription1": "Managing users require Slack scopes. If you click the OK button, we'll redirect you to the Slack login page. Depending on your browser settings, you might be already logged in and automatically redirected back.",
    "slack.usersLoginRequiredDescription2": "After logging in to your Slack account, we will take you back to this page so that you can manage the users.",
    "slack.usersLoginRequiredTitle": "Login to your Slack account is required",
    "stripe.dynamicMessage": "{message}",
    "subscription.annual.info": "You will activate the {plan} {period} Plan - ${price}",
    "subscription.hidePlansButton": "Hide plans",
    "subscription.logout": "Logout",
    "subscription.monthly.info": "You will activate the {plan} {period} Plan - ${usersPrice} ({noOfActiveUsers} users x {dollarValue})",
    "subscription.monthly.infoUnderUserPLanLimit": "You will activate the {plan} {period} Plan - ${usersPrice}",
    "subscription.showPlansButton": "Show plans",
    "subscription.stripeCustomerPortalLink": "Click here to visit the customer portal",
    "subscription.subscribe": "Subscribe",
    "subscription.title": "Subscription",
    "subscription.updateInProgress": "Attempting payment",
    "subscriptions.switchPlanTitle": "Switch to <strong>{plan}</strong> plan",
    "surveyAboutUs.description": "Can you tell us how did you hear about us? We'd love to know more about your journey to Vacation Tracker. This is optional, but it will help us a lot. Thanks!",
    "surveyAboutUs.OptionQuestion1-1": "Google Search",
    "surveyAboutUs.OptionQuestion1-1-1": "What did you search for on Google (e.g. \"vacation tracking\")?",
    "surveyAboutUs.OptionQuestion1-2": "App Store (Slack - App Directory, MS Teams - App Source, etc...)",
    "surveyAboutUs.OptionQuestion1-3": "Youtube",
    "surveyAboutUs.OptionQuestion1-4": "Facebook",
    "surveyAboutUs.OptionQuestion1-5": "I asked an AI (e.g. ChatGPT, Claud, Copilot, etc...)",
    "surveyAboutUs.OptionQuestion1-5-1": "Thats great! Which one?",
    "surveyAboutUs.OptionQuestion1-5-1-1": "ChatGPT, Claude or Gemini",
    "surveyAboutUs.OptionQuestion1-5-1-2": "Perplexity",
    "surveyAboutUs.OptionQuestion1-5-1-3": "Copilot",
    "surveyAboutUs.OptionQuestion1-5-1-4": "Other",
    "surveyAboutUs.OptionQuestion1-6": "Recommendation",
    "surveyAboutUs.OptionQuestion1-6-1": "Thats great! Who recommended us?",
    "surveyAboutUs.OptionQuestion1-6-1-1": "A colleague or friend",
    "surveyAboutUs.OptionQuestion1-6-1-2": "Another company suggested it",
    "surveyAboutUs.OptionQuestion1-6-1-3": "I have used it in the past",
    "surveyAboutUs.OptionQuestion1-7": "Other",
    "surveyAboutUs.Question1": "How did you find out about Vacation Tracker?",
    "surveyAboutUs.textarea": "Please specify",
    "surveyAboutUs.title": "How did you find out about Vacation Tracker?",
    "team.approvers": "Approver(s)",
    "team.createInProgress": "Creating the {teamName} department",
    "team.editTeam": "Edit Department",
    "team.hasNoUsers": "Department has no users.",
    "team.name": "Name",
    "team.team": "Department",
    "team.updateInProgress": "Updating the {teamName} department",
    "team.users": "User(s)",
    "teamForm.createTeam": "Create Department",
    "teamForm.createTeamError": "Oh no! 😕 Something went wrong. ",
    "teamForm.editTeam": "Edit Department",
    "teamForm.name": "Name",
    "teamForm.pleaseEnterTeamName": "Enter a department name",
    "teamForm.setAsSubdepartment": "Choose Parent Department",
    "teamForm.teamLimitReached": "Oops! You've reached the {limitNumber} department limit of your current plan. To manage or upgrade your plan, please visit the <billingPage>Billing page</billingPage>.",
    "teamForm.updateTeam": "Update Department",
    "teams.approvers": "Approver(s)",
    "teams.created": "Created",
    "teams.createNewTeam": "Create New Department",
    "teams.defaultTeam": "All new users will automatically be added to this department.",
    "teams.deleteInProgress": "Deleting the {teamName} department",
    "teams.deleteTeam": "Delete",
    "teams.deleteTeamConfirm": "Are you sure you want to delete the {name} department?",
    "teams.deleteTeamTitle": "Delete Department",
    "teams.editTeam": "Edit Department",
    "teams.handleDeleteError": "Delete error",
    "teams.handleDeleteErrorTitle": "Delete department error",
    "teams.name": "Name",
    "teams.removeUserInfo": "You cannot remove the user from the default department, if you want to move the user to some other department, you can edit other department and add user there.",
    "teams.teamsLimitReached": "You are currently using all {limitNumber} of your department slots available in the CORE plan. To manage or upgrade your plan, please visit the <billingPage>Billing page</billingPage>.",
    "teams.users": "User(s)",
    "teams.viewTeam": "View Department",
    "user.accrualsCalculator": "Calculate",
    "user.accrualsCalculatorInfo": "How many days will I have accrued by a specific date?",
    "user.addLeave": "Add Leave",
    "user.approve": "Approve",
    "user.approveInProgress": "Approving leave request",
    "user.approveLeaveText": "Please confirm you want to approve the leave request.",
    "user.approveLeaveTitle": "Approve Leave Request",
    "user.approveSuccessTitle": "Leave request approved",
    "user.bulkChangeStatusInProgress": "Updating Users' statuses.",
    "user.bulkChangeStatusSuccessTitle": "Users' statuses have been updated.",
    "user.cancelLeave": "Cancel Leave",
    "user.cancelLeave.buttonLink": "Cancel",
    "user.cancelLeaveConfirmTitle": "Cancel Leave",
    "user.cancelLeaveRequestInProgress": "Canceling the leave request",
    "user.cancelLeaveRequestTitle": "Leave request is canceled",
    "user.currentLeaves": "Current Leaves",
    "user.deleteLeave": "Delete Leave",
    "user.deleteLeave.buttonLabel": "Delete",
    "user.deleteLeaveConfirmText": "Please confirm you want to delete leave.",
    "user.deleteLeaveConfirmTitle": "Delete Leave",
    "user.deleteLeaveInProgress": "Deleting leave",
    "user.deleteLeaveSuccessTitle": "Leave is deleted",
    "user.deleteToil": "Delete TOIL",
    "user.deleteToilConfirmText": "Please confirm you want to delete TOIL.",
    "user.deny": "Deny",
    "user.denyInProgress": "Denying leave request",
    "user.denyLeaveConfirm": "Please confirm you want to deny the leave request.",
    "user.denyLeaveTitle": "Deny Leave Request",
    "user.denySuccessTitle": "Leave request denied",
    "user.denyWithReason": "Deny with Reason",
    "user.dontHaveAnyHistory": "{name} has not taken any leave yet.",
    "user.editLeave": "Edit Leave",
    "user.editLeave.buttonLabel": "Edit",
    "user.editLeaveWord": "Edit",
    "user.editUser": "Edit",
    "user.editWorkWeek": "Edit",
    "user.employeeEndDate": "Employee End Date",
    "user.employeeId": "Employee ID",
    "user.employeeSince": "Employee Since",
    "user.general": "General",
    "user.history": "History",
    "user.historyLeaves": "History",
    "user.invitationDeletedTitle": "Invitation deleted",
    "user.invitationSuccessTitle": "Invitations sent",
    "user.leaves": "Leaves",
    "user.leaveTypes": "Leave Types",
    "user.location": "Location",
    "user.location.moveInProgress": "Moving user to the {name} location",
    "user.location.moveSuccessTitle": "User is moved to the {name} location",
    "user.logs": "Logs",
    "user.noUpcomingLeaves": "No Scheduled Leaves",
    "user.pendingLeaves": "Pending Leave Requests",
    "user.probationPeriodDescription": "The user is on a probation period under the <probationPeriodName></probationPeriodName> policy for <leaveTypeNames></leaveTypeNames>, lasting <lengthDays></lengthDays> day(s), until <date></date>.",
    "user.profile": "Profile",
    "user.resend": "Re-send request",
    "user.resending": "Resending Leave Request...",
    "user.resent": "Request re-sent",
    "user.role": "Role",
    "user.signUp": "Sign Up",
    "user.signup.intro": "{invitedByName} invited you to join their Vacation Tracker organization. Please sign up below to accept the invitation and access the Vacation Tracker dashboard.",
    "user.signup.introHelpdesk": "If you have any questions or need assistance, contact our support or visit <helpdesk>the Helpdesk</helpdesk>.",
    "user.status": "Status",
    "user.status.updateInProgress": "Updating User status.",
    "user.status.updateSuccessTitle": "User status has been updated.",
    "user.statusReason": "Add Reason",
    "user.team.moveInProgress": "Moving user to the {name} department",
    "user.team.moveSuccessTitle": "User is moved to the {name} department",
    "user.upcomingLeaves": "Scheduled Leaves",
    "user.updateInProgress": "Updating user",
    "user.updateSuccessTitle": "User updated",
    "user.userWorkWeek": "User Workweek",
    "user.workWeek.updateInProgress": "Updating workweek",
    "user.workWeek.updateSuccessTitle": "Workweek is updated",
    "users.aboutToSync": "By syncing all existing users, you are synchronizing the user's data from the collaboration platform used by your team to their Vacation Tracker profile (ex. Name, Profile Image etc.)",
    "users.activateUsers": "Activate",
    "users.activeUser": "Activate",
    "users.activeUsers": "Active",
    "users.addEmailUsers": "Add Email Users",
    "users.addUsers": "Add Users",
    "users.assignLicenses": "Assign Licenses",
    "users.cancel": "Cancel",
    "users.changeUserLocation": "Change Location",
    "users.changeUserTeam": "Change Department",
    "users.createUsers": "Create Users",
    "users.createUsersDescription": "Create new users before inviting them to join. You can assign their department and location now, so everything is ready when they accept.",
    "users.createUsersDescriptionShort": "Create and configure users without having to wait for the invitation to be accepted.",
    "users.deactivate": "Deactivate",
    "users.deactivateUser": "Deactivate",
    "users.deactivateUserConfirm": "Are you sure want to deactivate {name}?",
    "users.deactivateUsers": "Deactivate",
    "users.deactivateUserTitle": "Deactivate",
    "users.deletedUsers": "Deleted",
    "users.deleteInvite": "Delete invite",
    "users.deleteInviteConfirm": "Are you sure want to delete the invite sent to {email}?",
    "users.deleteUser": "Delete User",
    "users.deleteUserUserConfirm": "Are you sure want to delete {name}?",
    "users.employmentDate": "User Employment Date",
    "users.endDate": "End Date",
    "users.import.description": "The import can take some time, in case you don't see new users in the list, please refresh this page after 10-15 seconds.",
    "users.importedSuccessfully": "Users successfully imported",
    "users.importMany.InProgress": "Importing users",
    "users.importMany.successTitle": "Users imported successfully",
    "users.importNewUsers": "Add Users",
    "users.importOne.InProgress": "Importing a new user",
    "users.importOne.successTitle": "User imported successfully",
    "users.importUsers": "Import Users",
    "users.inactiveUsers": "Inactive",
    "users.invitations": "Invitations",
    "users.inviteUserConfirm": "Send invite to {name}?",
    "users.inviteUsers": "Invite Users",
    "users.inviteUsersDescription": "Send invites to the user you created. Uninvited users are added to the list automatically.",
    "users.inviteUsersDescriptionShort": "Invite Users that have already been created to join your organization.",
    "users.location": "Location",
    "users.name": "Name",
    "users.next": "Next",
    "users.prev": "Prev",
    "users.reinvite": "Re-invite",
    "users.reInviteConfirm": "Are you sure you want to re-invite {email}?",
    "users.role": "Role",
    "users.search": "Search users",
    "users.sendInvitationsInLanguage": "Send invitations in",
    "users.startDate": "Start Date",
    "users.syncExistingUsers": "Sync Existing Users",
    "users.syncExistingUsers.InProgress": "Syncing existing users",
    "users.syncExistingUsers.successTitle": "Existing Users have been synced successfully.",
    "users.syncWithGoogleConsent": "Sync with Google consent",
    "users.syncWithoutGoogleConsent": "Sync without Google consent",
    "users.team": "Department",
    "users.uninvited": "Not Invited",
    "users.updateQuota.InProgress": "Updating user quota in progress",
    "users.updateQuota.successTitle": "User quota have been updated successfully",
    "users.updateRolloverQuota.successTitle": "Initial Brought Forward quota has been updated successfully.",
    "users.viewUser": "View User",
    "utomations.maximumUsersAway.maximumUsersAwayDeleted": "Maximum Users Away policy deleted",
    "V": "Approver Guide",
    "wallchart.range.next4Weeks": "Next 4 weeks",
    "wallchart.range.next5Weeks": "Next 5 weeks",
    "wallchart.range.nextMonth": "Next month",
    "wallchart.range.previousMonth": "Previous month",
    "wallchart.range.thisMonth": "This month"
}
